import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs } from 'react-bootstrap';
import InvoicesTab from './InvoicesTab';
import ServicesAlert from './ServicesAlert';
import ServicesTab from './ServicesTab';

const InvoicesContainer = () => {
  const [activeKey, setActiveKey] = useState('invoices');
  const [selectedServiceIds, setSelectedServiceIds] = useState([]);

  const handleServiceSelect = (serviceId, selected) => {
    setSelectedServiceIds((ids) => {
      if (selected) return [...ids, serviceId];

      return ids.filter(id => id !== serviceId);
    });
  };

  return (
    <div className="card">
      <div className="card-body large-tabs">
        {activeKey === 'services' ? <ServicesAlert /> : null}
        <Tabs activeKey={activeKey} onSelect={setActiveKey}>
          <Tab eventKey="invoices" title="Invoices">
            <InvoicesTab selectedServiceIds={selectedServiceIds} />
          </Tab>
          <Tab eventKey="services" title="Services">
            <ServicesTab selectedIds={selectedServiceIds} onSelect={handleServiceSelect} />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

InvoicesContainer.propTypes = {};

const mapStateToProps = () => ({});

export default connect(
  mapStateToProps,
)(InvoicesContainer);
