import { fetchRecord, fetchRecords } from 'redux-json-api-module';
import { groupBy } from '../../helpers/array';
import { PARENT_TYPE_MAP } from '../../helpers/types';

export const FETCH_TASKS_SUCCESS = 'intimely/partsTasks/FETCH_TASKS_SUCCESS';
export const SET_FILTER = 'intimely/partsTasks/SET_FILTER';

const INITIAL_STATE = {
  communityIdMap: {},
  filter: 'all',
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_TASKS_SUCCESS:
      return { ...state, communityIdMap: action.communityIdMap };

    case SET_FILTER:
      return { ...state, filter: action.filter };

    default:
      return state;
  }
}

export function fetchTasks() {
  return dispatch => Promise.all([
    dispatch(fetchRecords('project_service_listings/parts', {
      include: 'community,unit,user,project,features,features.item,purchase_requests',
      sort: 'started_at',
      page: { size: 250 },
    })),
    dispatch(fetchRecords('work_order_task_listings/parts', {
      include: 'unit,community,user,work_order,work_order_features,work_order_features.item,purchase_requests',
      sort: 'started_at',
      page: { size: 250 },
    })),
  ])
    .then((responses) => {
      if (responses.some(r => r.error)) return responses.find(r => r.error).error;

      return dispatch({
        type: FETCH_TASKS_SUCCESS,
        communityIdMap: groupBy(
          responses.map(resp => resp.payload.data.data).flat(),
          'relationships.community.data.id',
        ),
      });
    });
}

export const FILTERS = {
  all: 'View All',
  project_service_listings: 'Make Ready\'s',
  work_order_task_listings: 'Work Orders',
};

export const setFilter = filter => ({
  type: SET_FILTER,
  filter,
});

// Determine if parts needed
//
// Checks for an open purchase request,
// then verifies that the associated task
// is not complete
export const fetchPartsNeeded = () => (
  dispatch => dispatch(fetchRecords('purchase_requests', {
    page: { size: 1 },
    filter: { scope: 'parts_needed' },
  })).then(async (resp) => {
    const prs = resp.payload.data?.data ?? [];
    const pr = prs[0];
    if (!pr) return false;
    if (!pr.relationships.feature.data) return false;

    const featureRsp = await dispatch(fetchRecord(
      pr.relationships.feature.data.type,
      pr.relationships.feature.data.id,
    ));
    const feature = featureRsp.payload.data.data;

    const taskRsp = await dispatch(fetchRecord(
      feature.relationships[PARENT_TYPE_MAP[feature.type]].data.type,
      feature.relationships[PARENT_TYPE_MAP[feature.type]].data.id,
    ));
    const task = taskRsp.payload.data.data;

    return task.attributes.aasm_state !== 'completed';
  })
);
