import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord, getRelationship } from 'redux-json-api-module';
import { idType } from '../../helpers/propTypes';
import DirectMessageChatLink from './DirectMessageChatLink';
import { getOtherDMUser } from './helpers';
import SectionHeader from '../../components/SectionHeader';

const DirectMessageChats = ({
  directMessageChatIds,
  userId,
  threadsList,
  onShowUnread,
  onHideUnread,
  header,
}) => {
  if (directMessageChatIds.length === 0) return null;

  return (
    <>
      <SectionHeader title={header} className="mt-2 mb-1 pb-1" />
      <div id="direct-message-chats">
        {directMessageChatIds.map(id => (
          <DirectMessageChatLink
            directMessageChatId={id}
            userId={userId}
            key={id}
            threadsList={threadsList}
            onShowUnread={onShowUnread}
            onHideUnread={onHideUnread}
          />
        ))}
      </div>
    </>
  );
};

DirectMessageChats.propTypes = {
  directMessageChatIds: PropTypes.arrayOf(idType).isRequired,
  userId: idType.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const directMessageChatIds = state.Chat.activeDirectMessageChatIds.filter((id) => {
    const dmc = getRecord(state.Api, { type: 'direct_message_chats', id });
    const mt = getRelationship(state.Api, dmc.relationships.message_thread);

    // hide chats with no messages
    if (mt.relationships.last_message.data === null) {
      return false;
    }

    const { otherUserId } = getOtherDMUser(dmc, ownProps.userId, state.Api);
    const user = getRecord(state.Api, { type: 'users', id: otherUserId });

    if (ownProps.appRole === 'maintenance') {
      return user?.attributes?.app_role === 'maintenance';
    }

    return user?.attributes?.app_role !== 'maintenance';
  });

  return {
    directMessageChatIds,
  };
};

export default connect(mapStateToProps)(DirectMessageChats);
