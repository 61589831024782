import { parseEmoji } from '../helpers';

const EmojiAutoParser = {
  parse: (characters) => {
    let replacement = null;
    characters.forEach((character, index) => {
      if (index < characters.length - 2 && characters[index + 2].char === ' ') {
        const string = character.char + characters[index + 1].char;
        const emojiString = parseEmoji(string, 2);
        if (emojiString !== string) {
          replacement = [{
            index,
            char: emojiString,
          }];
        }
      }
    });

    if (replacement) {
      characters.splice(replacement[0].index, 2, ...replacement);
    }

    return characters;
  },
};

export default EmojiAutoParser;
