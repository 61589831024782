import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord } from 'redux-json-api-module';
import Badge from '../../components/Badge';
import BasicNavLink from '../../components/ServiceDetails/BasicNavLink';
import { idType } from '../../helpers/propTypes';
import ActiveArrow from '../../components/ServiceDetails/ActiveArrow';
import { setActiveTask } from '../../redux/modules/navigation';
import colors from '../../helpers/colors';
import RipplesPurple from '../../components/Animations/RipplesPurple';

function isActive(activeTaskId, taskId) {
  if (!activeTaskId || !taskId) return false;

  return activeTaskId.toString() === taskId.toString();
}

const TaskNavLink = ({
  taskId,
  taskType,
  planType,
  planId,
  setActiveTask,
  activeTaskId,
  serviceBadge,
  partsNeeded,
}) => {
  const active = isActive(activeTaskId, taskId);

  return (
    <BasicNavLink
      id={`task-link-${taskType}-${taskId}`}
      key={taskId}
      onClick={() => {
        setActiveTask({
          activeTaskType: taskType,
          activeTaskId: taskId,
          activePlanType: planType,
          activePlanId: planId,
        });
      }}
      active={active}
      textColor={serviceBadge?.text_color}
      backgroundColor={serviceBadge?.background_color}
      defaultStyles={{}}
      activeStyles={{}}
    >
      <div className="row" style={{ height: '40px' }}>
        <div className="col-9 mb-2">
          <div>
            {serviceBadge ? (
              <h4 style={{ marginTop: '15px', color: colors.black }}>
                {partsNeeded ? (
                  <div className="position-absolute" style={{ left: '-15px', top: '18px' }}>
                    <RipplesPurple />
                  </div>
                ) : null}
                <Badge {...serviceBadge} />
              </h4>
            ) : (
              <h5 style={{ marginTop: '15px', color: colors.black }}>
                Team Chat
              </h5>
            )}
          </div>
        </div>
        <div className="col-3 pl-0 my-auto">
          <ActiveArrow active={active} />
        </div>
      </div>

    </BasicNavLink>
  );
};

TaskNavLink.propTypes = {
  taskId: idType.isRequired,
  taskType: PropTypes.string.isRequired,
  setActiveTask: PropTypes.func.isRequired,
  planType: PropTypes.string.isRequired,
  planId: idType.isRequired,

  // connected
  activeTaskId: idType,
  partsNeeded: PropTypes.bool,
  serviceBadge: PropTypes.object,
};

TaskNavLink.defaultProps = {
  activeTaskId: null,
  partsNeeded: false,
  serviceBadge: null,
};

const mapStateToProps = (state, ownProps) => {
  const task = getRecord(state.Api, {
    type: ownProps.taskType,
    id: ownProps.taskId,
  });

  return {
    activeTaskId: state.Navigation.activeTaskId,
    partsNeeded: task?.attributes?.parts_badge ? true : false,
    serviceBadge: task?.attributes?.service_badge,
  };
};

const mapDispatchToProps = {
  setActiveTask,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskNavLink);
