import React from 'react';
import { connect } from 'react-redux';
import { getRecord } from 'redux-json-api-module';
import ServiceTabs from './ServiceTabs';
import TaskNavLink from '../../containers/ChatsContainer/TaskNavLink';
import { SERVICE_FORM_MAP } from '../../helpers/types';

const onInputFocus = () => undefined;

const ServiceDetails = (
  {
    aasmState,
    planId,
    userId,
    activeTaskId,
    activeTaskType,
    team,
    tasks,
    taskType,
    planType,
    inspectionTitle,
  },
) => (
  <div className="vtabs w-100" style={{ tableLayout: 'fixed' }}>
    <ul className="nav nav-tabs tabs-vertical" role="tablist">
      {tasks.map((task, i) => (
        <TaskNavLink
          key={task.id}
          userId={userId}
          taskId={task.id}
          taskType={taskType}
          index={i}
          planId={planId}
          planType={planType}
        />
      ))}
      {aasmState === 'completed' ? (
        <TaskNavLink
          userId={userId}
          taskId="chat"
          taskType="chat"
          planId={planId}
          planType={planType}
        />
      ) : null}
    </ul>

    <div className="tab-content pt-0 pb-0">
      <ServiceTabs
        planId={planId}
        planType={planType}
        taskId={activeTaskId}
        taskType={activeTaskType}
        onInputFocus={onInputFocus}
        team={team}
        userId={userId}
        inspectionTitle={inspectionTitle}
        ServiceForm={SERVICE_FORM_MAP[planType]}
      />
    </div>
  </div>
);

const mapStateToProps = (state, ownProps) => ({
  aasmState: getRecord(state.Api, { type: ownProps.planType, id: ownProps.planId })
    ?.attributes?.aasm_state,
});

export default connect(mapStateToProps)(ServiceDetails);
