import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Badge from '../../../components/Badge';
import { completedTaskSort } from '../../../helpers/sorts';
import { CHILD_LISTING_TYPE_MAP, RELATIONSHIP_MAP } from '../../../helpers/types';

const CompletedServices = ({ tasks }) => (
  tasks.map(task => (
    <Badge
      key={task.id}
      {...task.attributes.service_badge}
      style={{
        marginRight: '5px',
        marginBottom: '5px',
      }}
    />
  ))
);

const mapStateToProps = (state, ownProps) => {
  const planType = ownProps.plan.type;

  const tasks = Object.values(state.Api[CHILD_LISTING_TYPE_MAP[planType]] || {})
    .filter(t => t.attributes.aasm_state === 'completed' && t.relationships[RELATIONSHIP_MAP[planType]].data.id === ownProps.plan.id)
    .sort(completedTaskSort);

  return {
    tasks,
  };
};

CompletedServices.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default connect(mapStateToProps)(CompletedServices);
