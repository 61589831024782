import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';
import UnitUploaderContainer from '../containers/UnitUploaderContainer/UnitUploaderContainer';

const UnitUploader = props => (
  <Provider store={store}>
    <UnitUploaderContainer {...props} />
  </Provider>
);

export default UnitUploader;
