import React from 'react';
import PropTypes from 'prop-types';

export default class DueDateBadge extends React.Component {
  static propTypes = {
    status: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
  };

  render() {
    const { status } = this.props;

    if (status.label) {
      return (
        <span
          className="badge"
          style={{
            backgroundColor: status.background_color,
            color: status.color,
            fontSize: '14px',
          }}
        >
          {status.label}
        </span>
      );
    }
    if (placeholder) {
      return <span>{placeholder}</span>;
    }
    return null;
  }
}
