import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchRecord, saveRecord } from 'redux-json-api-module';
import { Button } from 'react-bootstrap';
import toastr from 'toastr';
import { sleep } from '../../../helpers/function';
import { TYPE_MAP } from '../../../helpers/types';

const getRecord = response => response.payload.data.data;
const getFileUrl = record => record.attributes.file.url;

const DownloadButton = ({ task, checkInterval, disabled, fetchRecord, saveRecord }) => {
  const [loading, setLoading] = useState(false);

  const download = async () => {
    try {
      setLoading(true);
      const attributes = { task_id: task.id, task_type: TYPE_MAP[task.type] };
      let record = { type: 'task_downloads', attributes };
      const params = { window_id: window.WINDOW_ID };
      let response = await saveRecord(record, { params });
      if (response.error) {
        toastr.error('There was a problem when creating a download');
        return;
      }

      record = getRecord(response);
      let url;
      while (!url) {
        // eslint-disable-next-line no-await-in-loop
        response = await fetchRecord(record.type, record.id);
        if (response.error) {
          toastr.error('There was a problem when checking the download');
          return;
        }

        record = getRecord(response);
        url = getFileUrl(record);
        if (url) break;

        // eslint-disable-next-line no-await-in-loop
        await sleep(checkInterval);
      }
      window.open(url, '_blank');
    } catch (error) {
      toastr.error('Oops! Something went wrong.');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button variant="success" onClick={download} disabled={disabled || loading}>
      <i className={`fa fa-fw ${loading ? 'fa-spinner fa-pulse' : 'fa-download'}`} />
      {' Download'}
    </Button>
  );
};

DownloadButton.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  checkInterval: PropTypes.number,
  disabled: PropTypes.bool,
  // connected
  fetchRecord: PropTypes.func.isRequired,
  saveRecord: PropTypes.func.isRequired,
};

DownloadButton.defaultProps = {
  checkInterval: 1000,
  disabled: false,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  fetchRecord,
  saveRecord,
};

export default connect(mapStateToProps, mapDispatchToProps)(DownloadButton);
