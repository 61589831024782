import React from 'react';
import PropTypes from 'prop-types';
import Select, { createFilter } from 'react-select';
import UnitOption from './UnitOption';

import './UnitSelector.scss';

class BasicUnitSelector extends React.Component {
  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    input: PropTypes.object.isRequired,
    menuPortalTarget: PropTypes.any.isRequired,
  };

  handleChange = (unit) => {
    const { input } = this.props;
    input.onChange(unit);
  };

  render() {
    const {
      options,
      menuPortalTarget,
      input,
    } = this.props;

    return (
      <React.Fragment>
        {options.length > 0 ? (
          <div className="form-group row">
            <label className="col-sm-3 col-form-label text-md-right">
              Select Unit
            </label>
            <div className="col-sm-9">
              <div className="unit-select__container">
                <Select
                  classNamePrefix="unit-select"
                  className="unit-select"
                  value={input.value}
                  components={{ Option: UnitOption }}
                  onChange={this.handleChange}
                  styles={{
                    menuPortal: (base) => {
                      const { zIndex, ...rest } = base;
                      return {
                        ...rest,
                        zIndex: 9999,
                      };
                    },
                  }}
                  options={options}
                  placeholder="Select unit"
                  menuPortalTarget={menuPortalTarget}
                  ignoreAccents={false}
                  filterOption={createFilter({
                    ignoreAccents: false,
                    matchFrom: 'any',
                    stringify: option => `${option.label}`,
                  })}
                />
              </div>
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default BasicUnitSelector;
