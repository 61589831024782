import { fetchRecords } from 'redux-json-api-module';
import get from 'lodash.get';

export const FETCH_PLANS_SUCCESS = 'intimely/completedPlans/FETCH_PLANS_SUCCESS';

const INITIAL_STATE = {
  communityIdMap: {},
  currentPage: 1,
  nextPage: 1,
  lastPage: null,
  hasMore: true,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_PLANS_SUCCESS:
      return {
        ...state,
        communityIdMap: action.communityIdMap,
        currentPage: action.currentPage,
        nextPage: action.nextPage,
        lastPage: action.lastPage,
      };

    default:
      return state;
  }
}

const SCOPE_MAP = {
  'Last 30 Days': '30_days',
  'Last 60 Days': '60_days',
  'Last 90 Days': '90_days',
};

function apiFetchProjects(scope, currentPage, filters) {
  let url = 'project_listings';

  if (scope === 'View By Unit' && filters.unitId) {
    url = `units/${filters.unitId}/project_listings`;
  } else if (scope === 'View By Unit' && filters.communityId) {
    url = `communities/${filters.communityId}/project_listings`;
  }

  return dispatch => dispatch(fetchRecords(url, {
    include: 'community,unit,project_services',
    filter: { scope: SCOPE_MAP[scope] || 'completed' },
    sort: 'keys_received_on',
    page: { size: 30, number: currentPage },
  }));
}

function apiFetchWorkOrders(scope, currentPage, filters) {
  let url = 'work_order_listings';

  if (scope === 'View By Unit' && filters.unitId) {
    url = `units/${filters.unitId}/work_order_listings`;
  } else if (scope === 'View By Unit' && filters.communityId) {
    url = `communities/${filters.communityId}/work_order_listings`;
  }

  return dispatch => dispatch(fetchRecords(url, {
    include: 'community,unit,work_order_tasks',
    filter: { scope: SCOPE_MAP[scope] || 'completed' },
    sort: 'keys_received_on',
    page: { size: 30, number: currentPage },
  }));
}

function parsePlans(dispatch, responses, communityIdMap) {
  if (responses.some(r => r.error)) return responses.find(r => r.error).error;

  const response = responses.find(r => r.payload.data.meta);
  const currentPage = response ? response.payload.data.meta.current_page : 1;
  const nextPage = response ? response.payload.data.meta.next_page : null;
  const lastPage = response ? response.payload.data.meta.total_pages : 1;

  const plans = responses.map(resp => resp.payload.data.data).flat();

  plans.forEach((plan) => {
    const communityId = get(plan, 'relationships.community.data.id');

    if (communityId) {
      if (!communityIdMap[communityId]) communityIdMap[communityId] = [];
      communityIdMap[communityId].push({
        id: plan.id,
        type: plan.type,
      });
    }
  });

  return dispatch({
    type: FETCH_PLANS_SUCCESS,
    communityIdMap,
    currentPage,
    nextPage,
    lastPage,
  });
}

export function fetchProjects(scope, page, reset, filters) {
  return (dispatch, getState) => Promise.all([
    dispatch(apiFetchProjects(scope, page, filters)),
  ])
    .then(responses => (
      parsePlans(dispatch, responses, reset ? {} : getState().CompletedPlans.communityIdMap)
    ));
}

export function fetchWorkOrders(scope, page, reset, filters) {
  return (dispatch, getState) => Promise.all([
    dispatch(apiFetchWorkOrders(scope, page, filters)),
  ])
    .then(responses => (
      parsePlans(dispatch, responses, reset ? {} : getState().CompletedPlans.communityIdMap)
    ));
}
