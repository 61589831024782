import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import paginationFactory from 'react-bootstrap-table2-paginator';
import BootstrapTable from 'react-bootstrap-table-next';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { fetchRecords } from 'redux-json-api-module';
import '../CompletedPlansContainer.scss';

const PAGE_SIZE = 10;

const Table = ({ communityId, unitId, serviceId, startDate, endDate, fetchPage, columns }) => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalSize, setTotalSize] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(PAGE_SIZE);
  const [plans, setPlans] = useState([]);
  const [sortField, setSortField] = useState('attributes.completed_at');
  const [sortOrder, setSortOrder] = useState('desc');

  const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    setPage(page);
    setSizePerPage(sizePerPage);
    setSortField(sortField);
    setSortOrder(sortOrder);
  };

  useEffect(() => {
    setLoading(true);
    fetchPage({ page, sizePerPage, sortField, sortOrder })
      .then((resp) => {
        setPlans(resp.payload.data.data);
        setTotalSize(resp.payload.data.meta.total_count);
      })
      .finally(() => setLoading(false));
  }, [communityId, unitId, serviceId, startDate, endDate, page, sizePerPage, sortField, sortOrder]);

  return (
    <BootstrapTable
      bootstrap4
      keyField="id"
      data={plans}
      loading={loading}
      noDataIndication={() => 'No plans found.'}
      overlay={overlayFactory({ spinner: true })}
      columns={columns}
      remote={{
        filter: true,
        pagination: true,
        sort: true,
        cellEdit: false,
      }}
      defaultSorted={[{ dataField: sortField, order: sortOrder }]}
      onTableChange={handleTableChange}
      pagination={paginationFactory({
        page,
        sizePerPage,
        totalSize,
        onPageChange: (page, sizePerPage) => {
          setPage(page);
          setSizePerPage(sizePerPage);
        },
        onSizePerPageChange: (sizePerPage, page) => {
          setPage(page);
          setSizePerPage(sizePerPage);
        },
      })}
    />
  );
};

Table.propTypes = {
  communityId: PropTypes.string.isRequired,
  fetchPage: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapDispatchToProps = {
  fetchRecords,
};

export default connect(null, mapDispatchToProps)(Table);
