import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import toastr from 'toastr';
import { fetchRecords, saveRecord } from 'redux-json-api-module';
import ProjectCreateForm from './ProjectCreateForm/ProjectCreateForm';

class ProjectCreateContainer extends Component {
  state = {
    errors: {},
    saving: false,
  };

  componentDidMount() {
    const { fetchRecords } = this.props;

    fetchRecords('users');
  }

  handleSubmit = (values) => {
    const { saveRecord, service } = this.props;

    this.setState({ saving: true });

    const communityAttributes = values.community ? {
      community_id: values.community.id,
    } : {
      community_attributes: {
        name: values.community_name,
      },
    };

    const unitValues = values.unit ? {
      unit_id: values.unit.id,
    } : {
      unit_attributes: {
        street_address: values.street_address,
        state: values.state && values.state.value,
        city: values.city,
        zip: values.zip,
        number: values.number,
        bath_count: values.bath_count,
        bed_count: values.bed_count,
        housing_type: values.housing_type,
        ...communityAttributes,
      },
    };

    const record = {
      type: 'projects',
      attributes: {
        keys_received_on: moment(values.keys_received_on)
          .startOf('day'),
        due_on: values.due_on,
        inspection_type: values.inspection_type,
        project_services_attributes: [{
          user_id: values.user_id,
          started_at: values.startedAt,
          ended_at: values.endedAt,
          all_day: values.all_day,
          service_id: service.id,
        }],
        ...unitValues,
      },
    };

    saveRecord(record, { params: { window_id: window.WINDOW_ID } })
      .then((resp) => {
        this.setState({ saving: false });

        if (resp.error) {
          toastr.error('Failed to create project');
          if (resp.error.response.data) {
            this.setState({
              errors: resp.error.response.data.errors,
            });
          }
          return;
        }

        toastr.success('Successfully created project!');
        window.location = '/projects';
      });
  };

  render() {
    const { unitOptions, stateOptions, communityOptions, service, team } = this.props;
    const { saving, errors } = this.state;

    if (!service) return null;

    const initialValues = {
      keys_received_on: new Date(),
    };

    return (
      <ProjectCreateForm
        initialValues={initialValues}
        errors={errors}
        unitOptions={unitOptions}
        stateOptions={stateOptions}
        communityOptions={communityOptions}
        service={service}
        onSubmit={this.handleSubmit}
        saving={saving}
        team={team.data}
      />
    );
  }
}

const mapDispatchToProps = {
  fetchRecords,
  saveRecord,
};

const mapStateToProps = (state, ownProps) => ({
  service: ownProps.service.data,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCreateContainer);
