import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

const EmailRecipientInput = ({ index }) => (
  <div key={`email-row-${index}`} className="form-group row">
    <div className="col">
      <label className="form-control-label">Name</label>
      <Field
        name={`name_${index}`}
        component="input"
        placeholder="Name"
        className="form-control is-valid string required"
      />
    </div>
    <div className="col">
      <label className="form-control-label">Email</label>
      <Field
        name={`email_${index}`}
        component="input"
        placeholder="Email"
        className="form-control is-valid string required"
      />
    </div>
  </div>
);

EmailRecipientInput.propTypes = {
  index: PropTypes.number.isRequired,
};

export default EmailRecipientInput;
