import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';
import InvoicesContainer from '../containers/InvoicesContainer';

const Invoices = props => (
  <Provider store={store}>
    <InvoicesContainer {...props} />
  </Provider>
);

export default Invoices;
