import React from 'react';
import ListGroupItem from '../../TeamScheduleContainer/ScheduleSelector/ListGroupItem';

const FILTER_OPTIONS = [
  'Last 30 Days',
  'Last 60 Days',
  'Last 90 Days',
  'View By Unit',
  'View By Team Member',
];

const PrimarySelector = ({ filter, onChange }) => (
  <div className="dropdown">
    <button
      className="btn btn-primary toggle-button"
      type="button"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {filter}
      <i className="fa fa-chevron-down spaced-icon" />
    </button>
    <div className="dropdown-menu scrollable-menu" aria-labelledby="dropdownMenuButton">
      {FILTER_OPTIONS.map(filterOption => (
        <ListGroupItem
          selected={filter === filterOption}
          onClick={() => onChange(filterOption)}
          key={filterOption}
        >
          {filterOption}
        </ListGroupItem>
      ))}
    </div>
  </div>
);

export default PrimarySelector;
