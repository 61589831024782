import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { saveRecord } from 'redux-json-api-module';
import { Form, Field } from 'react-final-form';
import toastr from 'toastr';
import { genericRecordPropType } from '../../../helpers/propTypes';

const ItemEditButton = ({ item, saveRecord }) => {
  const [modalVisible, setModalVisible] = useState(false);

  const onSubmit = (values) => {
    const record = {
      id: item.id,
      type: item.type,
      attributes: values,
    };

    saveRecord(record, { params: { window_id: window.WINDOW_ID } })
      .then((resp) => {
        if (resp.error) {
          toastr.error('Could not save Item.');
          return;
        }

        toastr.success('Item updated.');
        setModalVisible(false);
      });
  };

  return (
    <>
      <Button
        onClick={() => setModalVisible(true)}
        variant="link"
        title="Edit Item"
      >
        <i className="fa fa-pencil" />
      </Button>
      <Modal
        show={modalVisible}
        onHide={() => setModalVisible(false)}
      >
        <Modal.Header dismissable>
          <Modal.Title>
            <h4>Edit Item</h4>
          </Modal.Title>
        </Modal.Header>

        <Form
          onSubmit={onSubmit}
          initialValues={{ name: item.attributes.name }}
          render={({ handleSubmit, form, submitting, pristine }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <Field name="name">
                  {({ input, meta }) => (
                    <div className="form-group">
                      <label className="form-label">Name</label>
                      <input
                        {...input}
                        type="text"
                        className="form-control"
                      />
                      {meta.error && meta.touched && <span>{meta.error}</span>}
                    </div>
                  )}
                </Field>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => {
                    form.reset();
                    setModalVisible((false));
                  }}
                  disabled={submitting}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={submitting}
                >
                  Submit
                </Button>
              </Modal.Footer>
            </form>
          )}
        />
      </Modal>
    </>
  );
};

ItemEditButton.propTypes = {
  item: genericRecordPropType.isRequired,
};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  saveRecord,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ItemEditButton);
