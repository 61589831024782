const TYPE = 'tagging';

function getMatches(taggableUsers, name) {
  return taggableUsers.filter(user => (
    user.name.toLowerCase()
      .indexOf(name) === 0 && user.available
  ));
}

function getTaggingReference(userString) {
  return {
    type: 'tagging',
    data: {
      submitText: userString,
    },
    completed: true,
    characters: [],
  };
}

function parseReference(user, reference, body) {
  const characters = [];

  `@${user.name}`.split('')
    .forEach((char, i) => {
      const c = {
        char,
        reference,
        index: body.length + i,
      };
      reference.characters.push(c);
      characters.push(c);
    });

  return {
    body: `@${user.name}`,
    characters,
  };
}

const Tagging = {
  type: TYPE,

  toText: (textArray, body, index, meta) => {
    let skip;
    let replacementBody;
    let
      replacementCharacters;
    const character = textArray[index];
    if (character === '<') {
      const string = textArray.slice(index)
        .join('');
      meta.taggableUsers.forEach((user) => {
        const userString = `<@${user.id}>`;
        if (string.indexOf(userString) === 0) {
          skip = index + userString.length;
          const ref = getTaggingReference(userString);
          const { body: newBody, characters: newCharacters } = parseReference(user, ref, body);
          replacementBody = newBody;
          replacementCharacters = newCharacters;
        }
      });
    }

    return {
      characters: replacementCharacters,
      body: replacementBody,
      skip,
    };
  },

  generateReference: (text, start, meta) => {
    let matches = meta.taggableUsers.sort((a, b) => a.name.localeCompare(b.name));
    matches = matches.filter(u => u.available);
    return (text === '@' || meta.force) ? {
      type: TYPE,
      start,
      text,
      active: true,
      matches,
      characters: [],
      force: meta.force,
      toggled: meta.toggled,
    } : null;
  },

  updateReference: (reference, text, meta) => {
    reference.matches = getMatches(meta.taggableUsers, text.substring(1));
    reference.active = reference.matches.length > 0;
  },

  mergeReference: (previousCharacter, addition) => {
    if (!previousCharacter
      || !previousCharacter.reference
      || previousCharacter.reference.completed
    ) {
      return addition.reference;
    }
    return previousCharacter.reference;
  },

  toEncodedText: (character) => {
    if (character.reference.completed) {
      return character.char === '@' ? character.reference.data.submitText : '';
    }
    return character.char;
  },
};

export default Tagging;
