import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord } from 'redux-json-api-module';
import { activateProject } from '../../../redux/modules/projectManagement';
import Alert from '../../../components/Alert';
import { canActivate } from '../../WorkOrderImportManagementContainer/helpers';

const ActivateButton = ({ project, active, activateProject }) => (
  <React.Fragment>
    {active && (
      <Alert
        type="info"
        iconClassName="fa fa-exclamation-circle"
        title="Please Activate Project"
      >
        Please click “Activate” below to send work notifications to the Service provider(s).
      </Alert>
    )}
    <button
      type="button"
      className="btn btn-primary btn-lg"
      disabled={!active}
      onClick={() => activateProject(project.id)}
    >
      Activate
    </button>
  </React.Fragment>
);

ActivateButton.propTypes = {
  project: PropTypes.objectOf(PropTypes.any).isRequired,
  active: PropTypes.bool.isRequired,
  activateProject: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const projectServices = (state.ProjectManagement.projectServiceIds || [])
    .map(id => getRecord(state.Api, {
      type: 'project_services',
      id,
    }));

  return {
    active: canActivate(projectServices),
  };
};

const mapDispatchToProps = {
  activateProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivateButton);
