import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import ModalCarousel from '../ModalCarousel/ModalCarousel';
import LazyProgressiveImage from '../LazyProgressiveImage';
import COLORS from '../../helpers/colors';
import { getPairs } from './helpers';

const MAX_IMAGES = 4;

const CENTER_STYLE = {
  fontSize: '40px',
  color: COLORS.white,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  cursor: 'pointer',
};

const ImageRow = ({
  images,
  startingIndex,
  remaining,
  sourceVariant,
  placeholderVariant,
  imageClassName,
  onClick,
}) => (
  <Row>
    {images.map((image, i) => (
      <Col xs={6} key={image.id} className="mb-3" style={{ position: 'relative' }}>
        <LazyProgressiveImage
          sourceUrl={image.file && image.file[sourceVariant].url}
          placeholderUrl={image.file && image.file[placeholderVariant].url}
          onClick={() => onClick(i + startingIndex)}
          alt="Image"
          imageStyle={{
            width: '150px',
            height: '150px',
            objectFit: 'cover',
          }}
          imageClassName={`${imageClassName || ''} cursor-pointer`}
          scrollContainer=".chat-list"
          overflow
        />
        {i + startingIndex === MAX_IMAGES - 1 && remaining > 0 ? (
          <div
            onClick={() => onClick(i + startingIndex)}
            className="font-weight-bold"
            style={CENTER_STYLE}
          >
            {`+${remaining}`}
          </div>
        ) : null}
      </Col>
    ))}
  </Row>
);

ImageRow.propTypes = {
  images: PropTypes.arrayOf(PropTypes.object).isRequired,
  imageClassName: PropTypes.string,
  sourceVariant: PropTypes.string.isRequired,
  placeholderVariant: PropTypes.string.isRequired,
  startingIndex: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  remaining: PropTypes.number.isRequired,
};

ImageRow.defaultProps = {
  imageClassName: null,
};

class ImageGallery extends React.Component {
  static propTypes = {
    images: PropTypes.arrayOf(PropTypes.object).isRequired,
    imageClassName: PropTypes.string,
    sourceVariant: PropTypes.string,
    placeholderVariant: PropTypes.string,
  };

  static defaultProps = {
    sourceVariant: 'thumb',
    placeholderVariant: 'thumb_placeholder_sq',
    imageClassName: null,
  };

  state = {
    modalBody: '',
    modalTitle: '',
    modalVisible: false,
    selectedSlide: 0,
  };

  handleClick = index => this.setState({
    modalVisible: true,
    selectedSlide: index,
  });

  handleClose = () => {
    this.setState({ modalVisible: false });
  };

  render() {
    const {
      images, imageClassName,
      sourceVariant, placeholderVariant,
    } = this.props;

    const { modalVisible, modalTitle, modalBody, selectedSlide } = this.state;

    const remaining = images.length - MAX_IMAGES;

    return (

      <React.Fragment>
        {getPairs(images)
          .slice(0, 2)
          .map((imagePair, index) => (
            <ImageRow
              key={index}
              images={imagePair}
              remaining={remaining}
              startingIndex={index * 2}
              imageClassName={imageClassName}
              sourceVariant={sourceVariant}
              placeholderVariant={placeholderVariant}
              onClick={this.handleClick}
            />
          ))}

        <ModalCarousel
          visible={modalVisible}
          title={modalTitle}
          images={images.map(i => i.file)}
          onClose={() => this.handleClose()}
          bodyHTML={modalBody}
          startIndex={selectedSlide}
        />
      </React.Fragment>
    );
  }
}

export default ImageGallery;
