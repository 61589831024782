import { snakeCase } from '../WorkOrderImportContainer/helpers';

export const WIDTHS = {
  'Unit Address': '100px',
  'Work Order Description': '400px',
};

export const CELL_CLASSES = {
  'Tenant Email': 'no-wrap',
};

export const ATTRIBUTES = [
  'Community',
  'Street Address',
  'Unit Address',
  'City',
  'State',
  'Zip',
  'Bed Count',
  'Bath Count',
  'Housing Type',
];

export const TEXT_ATTRIBUTES = [
  'Community',
  'Unit Address',
  'City',
  'State',
  'Zip',
  'Bed Count',
  'Bath Count',
  'Housing Type',
];

export const REQUIRED = [
  'Community',
  'Street Address',
  'Unit Address',
  'City',
  'State',
  'Zip',
  'Bed Count',
  'Bath Count',
  'Housing Type',
];

export function getAttributeValue(row, attr) {
  return row[snakeCase(attr)];
}
