import moment from 'moment';
import store from '../../../../redux/store';
import { updateTask } from '../../../../redux/modules/scheduler';

export function combineDateTime(date, time) {
  if (!date || !time) return null;

  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time.getHours(),
    time.getMinutes(),
  );
}

const datetimeFormat = 'YYYY-MM-DD HH:mm:ss';
const datetimeWithoutTimezone = datetime => moment(datetime)
  .format(datetimeFormat);

function parseValues(values) {
  const attributes = { all_day: !values.includeTime };

  if (values.includeTime) {
    attributes.started_at = combineDateTime(values.startDate, values.startTime);
    attributes.ended_at = combineDateTime(values.endDate, values.endTime);
  } else {
    attributes.started_at = values.startDate;
    attributes.ended_at = values.endDate;
  }

  // we remove the timezone because we store and view everything as UTC
  //  there is no use case of users in different timezones working on
  //  the same project.
  attributes.started_at = datetimeWithoutTimezone(attributes.started_at);
  attributes.ended_at = datetimeWithoutTimezone(attributes.ended_at);

  attributes.user_id = values.userId;

  return attributes;
}

function submit(values, onSubmit) {
  const attributes = parseValues(values);
  onSubmit(attributes);

  // update the task in scheduler store
  const { task } = store.getState().Scheduler;

  if (task) {
    const newTask = { ...task };
    newTask.attributes = { ...task.attributes, ...attributes };
    newTask.relationships.user = {
      type: 'users',
      id: attributes.user_id,
    };
    store.dispatch(updateTask(newTask));
  }
}

export default submit;
