import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import toastr from 'toastr';
import { saveRecord } from 'redux-json-api-module';
import get from 'lodash.get';
import WorkOrderCreateForm from './WorkOrderCreateForm';
import { fetchAreaOptions } from '../../redux/modules/projectManagement';
import { toDate } from '../../helpers/date';

class WorkOrderCreateContainer extends Component {
  state = {
    errors: {},
    saving: false,
  };

  fetchAreaOptions = (unitId) => {
    const { fetchAreaOptions } = this.props;

    if (unitId) {
      fetchAreaOptions(unitId)
        .then((resp) => {
          if (resp.error) {
            console.log(resp.error);
            return;
          }

          this.setState({ areaOptions: resp.payload.data });
        });
    }
  };

  handleUnitChange = (value) => {
    this.fetchAreaOptions(value.id);
  };

  handleSubmit = (values) => {
    const { saveRecord } = this.props;

    this.setState({ saving: true });

    const communityAttributes = values.community ? {
      community_id: values.community.id,
    } : {
      community_attributes: {
        name: values.community_name,
      },
    };

    const unitValues = values.unit ? {
      unit_id: values.unit.id,
    } : {
      unit_attributes: {
        street_address: values.street_address,
        state: values.state && values.state.value,
        city: values.city,
        zip: values.zip,
        number: values.number,
        housing_type: values.housing_type,
        bed_count: values.bed_count,
        bath_count: values.bath_count,
        ...communityAttributes,
      },
    };

    const {
      recurring, start_recurring_on, stop_recurring_on,
      priority, recurring_frequency, description,
      work_order_number, additional_entry_notes, permission_to_enter,
      tenant_name, tenant_email, tenant_phone,
    } = values;

    const record = {
      type: 'work_orders',
      attributes: {
        keys_received_on: moment(values.keys_received_on)
          .startOf('day'),
        due_on: values.due_on,
        recurring,
        start_recurring_on: toDate(start_recurring_on),
        stop_recurring_on: toDate(stop_recurring_on),
        priority,
        recurring_frequency,
        description,
        work_order_number,
        additional_entry_notes,
        permission_to_enter,
        tenant_name,
        tenant_email,
        tenant_phone,
        ...unitValues,
      },
    };

    saveRecord(record, { params: { window_id: window.WINDOW_ID } })
      .then((resp) => {
        this.setState({ saving: false });

        const id = get(resp, 'payload.data.data.id', null);

        if (resp.error) {
          toastr.error('Failed to create work order');
          console.log(resp);
          if (resp.error.response.data) {
            this.setState({
              errors: resp.error.response.data.errors,
            });
          }
          return;
        }

        toastr.success('Successfully created work order!');
        window.location = `/work_orders/${id}`;
      });
  };

  render() {
    const {
      unitOptions,
      stateOptions,
      communityOptions,
      areaItems,
      serviceOptions,
      defaultAreaOptions,
    } = this.props;

    const { saving, errors, areaOptions } = this.state;

    const initialValues = {
      keys_received_on: new Date(),
      work_order_tasks_attributes: [{
        id: null,
        should_create_key_feature: true,
      }],
    };

    return (
      <>
        <h5 className="font-weight-bold">Add A Work Order</h5>
        <hr />
        <WorkOrderCreateForm
          initialValues={initialValues}
          errors={errors}
          unitOptions={unitOptions}
          stateOptions={stateOptions}
          communityOptions={communityOptions}
          areaItems={areaItems}
          areaOptions={areaOptions || defaultAreaOptions || []}
          onSubmit={this.handleSubmit}
          saving={saving}
          onUnitChange={this.handleUnitChange}
          serviceOptions={serviceOptions}
        />
      </>
    );
  }
}

const mapDispatchToProps = {
  saveRecord,
  fetchAreaOptions,
};

export default connect(null, mapDispatchToProps)(WorkOrderCreateContainer);
