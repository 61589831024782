import React from 'react';
import PropTypes from 'prop-types';
import { getTeams, changeMembership } from './helpers';

class TeamSelector extends React.Component {
  static propTypes = {
    memberships: PropTypes.object.isRequired,
  };

  getElements() {
    const { memberships } = this.props;

    return getTeams(memberships)
      .map(team => (
        <li key={team.membershipId}>
          <a href="/" onClick={this.onClick.bind(this, team.membershipId)}>{team.label}</a>
        </li>
      ));
  }

  onClick = (membershipId, e) => {
    e.preventDefault();
    const { user } = this.props;
    changeMembership(membershipId, user);
  };

  render() {
    return (
      <React.Fragment>
        <a className="menu-section">
          <i className="icon padded-icon fa fa-users" />
          <span className="hide-menu section-title">
            Teams
          </span>
          <hr className="green section-separator" />
        </a>
        <ul>
          <hr className="green popout" />
          {this.getElements()}
        </ul>
      </React.Fragment>
    );
  }
}

export default TeamSelector;
