import React from 'react';
import PropTypes from 'prop-types';
import { inputObject, metaObject } from '../../helpers/propTypes';

const NumberInput = ({ input, label, meta, step, min, max }) => (
  <div className="form-group">
    {label ? (
      <label htmlFor={input.name}>{label}</label>
    ) : null}
    <input
      {...input}
      type="number"
      className={`form-control ${meta.error && meta.touched ? 'is-invalid' : null}`}
      id={input.name}
      step={step}
      min={min}
      max={max}
    />
    {meta.error && meta.touched ? (
      <div className="invalid-feedback">{meta.error}</div>
    ) : null}
  </div>
);

NumberInput.propTypes = {
  input: inputObject.isRequired,
  meta: metaObject.isRequired,
  label: PropTypes.string,
  max: PropTypes.number,
  min: PropTypes.number,
  step: PropTypes.number,
};

NumberInput.defaultProps = {
  label: undefined,
  max: undefined,
  min: undefined,
  step: undefined,
};

export default NumberInput;
