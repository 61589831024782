import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { fetchRecords } from 'redux-json-api-module';
import isEqual from 'lodash.isequal';
import InventoryHistoryTable from './InventoryHistoryTable';
import { genericRecordPropType, idType } from '../../../helpers/propTypes';
import ItemEditButton from './ItemEditButton';
import ItemDeleteButton from './ItemDeleteButton';
import ExpandColumn from '../../../components/Tables/ExpandColumn';
import { Button } from 'react-bootstrap';

const columns = [
  {
    dataField: 'name',
    text: 'Part Name',
    sort: true,
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'quantity',
    text: 'Quantity on Hand',
    sort: true,
    type: 'number',
    align: 'right',
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'supplier',
    text: 'Last Supplier',
    sort: true,
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'price',
    text: 'Price Per Part',
    sort: true,
    type: 'number',
    align: 'right',
    style: { verticalAlign: 'middle' },
    sortFunc: (a, b, order) => {
      if (order === 'asc') {
        if (!a) return -1;
        if (!b) return 1;
        return parseFloat(a) - parseFloat(b);
      }

      if (!a) return 1;
      if (!b) return -1;
      return parseFloat(b) - parseFloat(a);
    },
  },
  {
    dataField: 'orderedAt',
    text: 'Last Ordered',
    sort: true,
    align: 'right',
    style: { verticalAlign: 'middle' },
    sortFunc: (a, b, order) => {
      if (order === 'asc') {
        if (!a) return -1;
        if (!b) return 1;
        return moment(a).unix() - moment(b).unix();
      }

      if (!a) return 1;
      if (!b) return -1;
      return moment(b).unix() - moment(a).unix();
    },
  },
  {
    dataField: 'actions',
    text: '',
    align: 'center',
    style: { verticalAlign: 'middle' },
  },
];

const defaultSorted = [{
  dataField: 'name',
  order: 'asc',
}];

const NoDataIndication = () => (
  <div className="text-center">No Inventory</div>
);

const ExpandHeaderColumn = () => null;

const ExpandRow = ({ id }) => (
  <InventoryHistoryTable itemId={id} />
);

ExpandRow.propTypes = {
  id: idType.isRequired,
};

const expandRow = {
  showExpandColumn: true,
  onlyOneExpanding: true,
  expandByColumnOnly: true,
  expandHeaderColumnRenderer: ExpandHeaderColumn,
  expandColumnRenderer: ExpandColumn,
  renderer: ExpandRow,
};

const InventoryTable = React.memo(({
  items,
  fetchRecords,
  onAddInventory,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchRecords('items', { page: { size: 250 } })
      .finally(() => setLoading(false));
  }, []);

  const data = Object.values(items)
    .map(item => ({
      id: item.id,
      name: item.attributes.name,
      quantity: item.attributes.quantity,
      supplier: item.attributes.supplier,
      price: item.attributes.price ? (
        parseFloat(item.attributes.price)
          .toFixed(2)
      ) : null,
      orderedAt: item.attributes.ordered_at ? (
        moment(item.attributes.ordered_at).format('MM/DD/YY')
      ) : null,
      actions: (
        <>
          <ItemEditButton item={item} />
          <Button
            onClick={() => onAddInventory(item)}
            variant="link"
            title="Edit Item"
          >
            <i className="fa fa-plus" />
          </Button>
          <ItemDeleteButton item={item} />
        </>
      ),
    }));

  const Overlay = overlayFactory({ spinner: true });

  return (
    <BootstrapTable
      keyField="id"
      data={data}
      rowClasses={r => (`row-${r.id}`)}
      columns={columns}
      defaultSorted={defaultSorted}
      expandRow={expandRow}
      loading={loading}
      overlay={Overlay}
      noDataIndication={NoDataIndication}
    />
  );
}, (prevProps, nextProps) => (
  isEqual(prevProps.items, nextProps.items)
));

InventoryTable.propTypes = {
  items: PropTypes.objectOf(genericRecordPropType).isRequired,
  onAddInventory: PropTypes.func.isRequired,

  // connected
  fetchRecords: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  items: state.Api.items || {},
});

const mapDispatchToProps = {
  fetchRecords,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InventoryTable);
