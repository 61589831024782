import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord, getRelationship } from 'redux-json-api-module';
import get from 'lodash.get';
import SelectReportsForm from './SelectReportsForm';
import SendReportForm from '../../../components/SendReportForm';
import { idType } from '../../../helpers/propTypes';

import './ServiceCompletionReportDownload.scss';
import { CHILD_TYPE_MAP } from '../../../helpers/types';

class ServiceCompletionReportDownload extends React.Component {
  static propTypes = {
    planId: idType.isRequired,
    planType: PropTypes.string.isRequired,
    tasks: PropTypes.arrayOf(PropTypes.any).isRequired,
    sendTaskCompletionReports: PropTypes.func.isRequired,
    currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
    inspectionId: idType,
  };

  state = {
    modalVisible: false,
    selectedIds: [],
    inspectionId: null,
  };

  closeModal = () => {
    this.setState({ modalVisible: false });
  };

  handleSubmit = (values) => {
    this.setState({
      modalVisible: true,
      selectedIds: values.selectedIds,
    });
  };

  render() {
    const {
      planId,
      planType,
      unit,
      tasks,
      sendTaskCompletionReports,
      currentUser,
      formatReport,
      inspectionId,
    } = this.props;
    const { modalVisible, selectedIds } = this.state;

    return (
      <React.Fragment>
        <SelectReportsForm
          planType={planType}
          planId={planId}
          tasks={tasks}
          onSubmit={this.handleSubmit}
          inspectionId={inspectionId}
        />
        <SendReportForm
          visible={modalVisible}
          sendReport={sendTaskCompletionReports.bind(this, planId)}
          headerText="Email Service Completion Reports"
          serviceData={selectedIds}
          tasks={tasks.filter(task => selectedIds.includes(task.id))}
          formatReport={formatReport}
          close={this.closeModal}
          currentUser={currentUser}
          address={get(unit, 'attributes.short_address')}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const plan = getRecord(state.Api, {
    type: ownProps.planType,
    id: ownProps.planId,
  });

  const tasks = plan
    ? getRelationship(state.Api, plan.relationships[CHILD_TYPE_MAP[ownProps.planType]]) || [] : [];
  const inspection = tasks.find(task => task.attributes.is_inspection);

  return {
    inspectionId: inspection ? inspection.id : null,
    unit: getRelationship(state.Api, plan.relationships.unit),
  };
};

export default connect(
  mapStateToProps,
)(ServiceCompletionReportDownload);
