import PropTypes from 'prop-types';

export const idPropType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.number,
]);

export const relPropType = PropTypes.shape({
  id: idPropType,
  type: PropTypes.string,
});

export const genericRecordPropType = PropTypes.shape({
  id: idPropType,
  type: PropTypes.string,
  attributes: PropTypes.objectOf(PropTypes.any),
  relationships: PropTypes.objectOf(PropTypes.oneOfType([
    relPropType,
    PropTypes.arrayOf(relPropType),
  ])),
});

export const textSectionObject = PropTypes.shape({
  style: PropTypes.shape({
    fontWeight: PropTypes.string,
  }),
  text: PropTypes.string,
});

export const taggableUserObject = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  available: PropTypes.bool,
});

export const emojiObject = PropTypes.shape({
  key: PropTypes.string,
  emoji: PropTypes.string,
});

export const matchObject = PropTypes.shape({
  key: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

export const attachmentObject = PropTypes.shape({
  thumb: PropTypes.shape({
    url: PropTypes.string,
  }),
  url: PropTypes.string,
  name: PropTypes.string,
  uploading: PropTypes.bool,
});

export const teamObject = PropTypes.shape({
  data: PropTypes.shape({
    id: PropTypes.string,
    attributes: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
});

export const userObject = PropTypes.shape({
  data: PropTypes.shape({
    id: PropTypes.string,
  }),
});

export const eventObject = PropTypes.shape({
  type: PropTypes.string,
});

export const idType = PropTypes.oneOfType([
  PropTypes.string, PropTypes.node, PropTypes.string,
]);

export const option = PropTypes.shape({
  id: idType,
  label: PropTypes.string,
});

export const options = PropTypes.arrayOf(option);

export const inputObject = PropTypes.shape({
  onChange: PropTypes.func,
});

export const metaObject = PropTypes.shape({
  error: PropTypes.string,
  touched: PropTypes.bool,
});
