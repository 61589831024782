import moment from 'moment';

export const ATTRIBUTES = [
  'Street Address',
  'Unit Address',
  'City',
  'State',
  'Zip',
  'Work Order Number',
  'Work Order Description',
  // optional
  'Priority',
  'Created At',
  'Tenant Name',
  'Tenant Email',
  'Tenant Phone',
];

export const TEXT_ATTRIBUTES = [
  'Unit Address',
  'Work Order Number',
  'Work Order Description',
  'Priority',
  'Created At',
];

export const WIDTHS = {
  'Unit Address': '100px',
  Priority: '100px',
  'Work Order Description': '400px',
};

export const CELL_CLASSES = {
  'Tenant Email': 'no-wrap',
};

export const REQUIRED = [
  'Street Address',
  'Unit Address',
  'Work Order Number',
  'Work Order Description',
  'City',
  'State',
  'Zip',
];

export const PRIORITIES = [
  'low',
  'medium',
  'urgent',
];

export const PRIORITY_LABELS = {
  low: 'Low',
  medium: 'Normal',
  urgent: 'Urgent',
};

export const ACCEPTED_FILE_TYPES = [
  'text/csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
];

export const ALERTS = {
  street_address: {
    title: "Please match your column names with Intimely's settings.",
    body: "Let's make sure your report syncs up with Intimely. The labels on the left side are Intimely's settings. On the right side, please select which Column Name in your Work Orders report matches up with the Intimely setting. You don't have to change your Work Orders report's Column Names - you can simply match them up here!",
  },
};

export const snakeCase = string => string.replace(/\W+/g, ' ')
  .split(/ |\B(?=[A-Z])/)
  .map(word => word.toLowerCase())
  .join('_');

export function getAttributeValue(row, attr) {
  if (attr === 'Priority') {
    return PRIORITY_LABELS[row[snakeCase(attr)]];
  }

  if (attr === 'Permission To Enter') {
    return row[snakeCase(attr)] ? 'Yes' : 'No';
  }

  if (attr === 'Created At') {
    const date = moment(row[snakeCase(attr)]);
    return date.isValid() ? date.format('MM/DD/YYYY') : row[snakeCase(attr)];
  }

  return row[snakeCase(attr)];
}
