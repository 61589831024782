import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { genericRecordPropType, idType } from '../../../helpers/propTypes';
import AddressCell from './AddressCell';
import AvatarCell from './AvatarCell';
import StatusCell from './StatusCell';
import ScheduleCell from './ScheduleCell';
import DoneCell from './DoneCell';
import WorkOrderNumberCell from './WorkOrderNumberCell';
import ExpandColumn from '../../../components/Tables/ExpandColumn';
import PurchaseRequestTable from '../../../components/PurchaseRequestTable';

const columns = [{
  dataField: 'address',
  text: 'Address',
}, {
  dataField: 'woNumber',
  text: 'W/O #',
  headerStyle: { width: '8%' },
}, {
  dataField: 'assignedTo',
  text: 'Assigned To',
}, {
  dataField: 'status',
  text: 'Status',
}, {
  dataField: 'schedule',
  text: 'Schedule',
}, {
  dataField: 'done',
  text: 'Done',
  headerStyle: { width: '8%' },
}];

const NoDataIndication = () => (
  <div className="text-center">You do not have any tasks with parts needed!</div>
);

const ExpandRow = ({ id }) => {
  const parts = id.split('-');
  return (
    <PurchaseRequestTable taskType={parts[0]} taskId={parts[1]} />
  );
};

ExpandRow.propTypes = {
  id: PropTypes.string.isRequired,
};

const expandRow = {
  showExpandColumn: true,
  onlyOneExpanding: true,
  expandByColumnOnly: true,
  expandHeaderColumnRenderer: () => null,
  expandColumnRenderer: ExpandColumn,
  renderer: ExpandRow,
};

const PartsTasksTable = ({ tasks, filter }) => {
  const data = tasks.map((t) => {
    if (filter !== 'all' && t.type !== filter) return null;

    return {
      id: `${t.type}-${t.id}`,
      address: (
        <AddressCell taskId={t.id} taskType={t.type} />
      ),
      woNumber: (
        <WorkOrderNumberCell taskId={t.id} taskType={t.type} />
      ),
      assignedTo: (
        <AvatarCell taskId={t.id} taskType={t.type} />
      ),
      status: (
        <StatusCell taskId={t.id} taskType={t.type} />
      ),
      schedule: (
        <ScheduleCell taskId={t.id} taskType={t.type} />
      ),
      done: (
        <DoneCell taskId={t.id} taskType={t.type} />
      ),
    };
  }).filter(t => t);

  return (
    <BootstrapTable
      keyField="id"
      data={data}
      columns={columns}
      rowClasses="v-middle"
      bootstrap4
      bordered={false}
      noDataIndication={NoDataIndication}
      expandRow={expandRow}
    />
  );
};

PartsTasksTable.propTypes = {
  communityId: idType.isRequired,

  // connected
  filter: PropTypes.string.isRequired,
  tasks: PropTypes.arrayOf(genericRecordPropType).isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  filter: state.PartsTasks.filter,
  tasks: state.PartsTasks.communityIdMap[ownProps.communityId] || [],
});

export default connect(
  mapStateToProps,
)(PartsTasksTable);
