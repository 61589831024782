import React from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';

const GalleryUploader = ({ onDrop }) => (
  <Dropzone onDrop={onDrop}>
    {({ getRootProps, getInputProps, isDragActive }) => (
      <div {...getRootProps()} className={`dropzone ${isDragActive ? 'dropzone--isActive' : null}`}>
        <input name="data[attributes][file]" {...getInputProps()} />
        {
          isDragActive
            ? <p className="dropzone-instructions">Drop files here...</p>
            : (
              <p className="dropzone-instructions">
                Drag Files Here
                &nbsp;&nbsp;
                OR
                &nbsp;&nbsp;&nbsp;
                <button className="btn btn-secondary" type="button">
                  Click Here to Choose
                </button>
              </p>
            )
        }
      </div>
    )}
  </Dropzone>
);

GalleryUploader.propTypes = {
  onDrop: PropTypes.func.isRequired,
};

export default GalleryUploader;
