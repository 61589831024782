import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../components/Avatar';

const Member = ({ user }) => (
  <li
    className="nav-item u-pro d-none d-md-block"
    data-toggle="tooltip"
    title=""
    data-original-title={`${user.attributes.name}, Active ${user.attributes.last_seen_at_in_words} ago`}
  >
    <div className="active-user-item">
      <Avatar {...user.attributes} />
    </div>
  </li>
);

Member.propTypes = {
  user: PropTypes.object.isRequired,
};

export default Member;
