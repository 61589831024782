import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getRecord, getRelationship } from 'redux-json-api-module';
import moment from 'moment';
import { deleteMessage, edit } from '../../redux/modules/chat';

const ChatTime = ({ createdAt, editedAt }) => (
  <span
    className="chat-time d-inline-block"
    style={{
      zIndex: 999,
      marginLeft: '8px',
    }}
  >
    {moment(createdAt)
      .format('MMM Do, h:mm a')}
    {editedAt ? <span>&nbsp;(edited)</span> : null}
  </span>
);

ChatTime.propTypes = {
  createdAt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  editedAt: PropTypes.string,
};

ChatTime.defaultProps = {
  body: null,
  editedAt: null,
};

const mapStateToProps = (state, ownProps) => {
  const message = getRecord(state.Api, {
    type: 'messages',
    id: ownProps.messageId,
  });

  return {
    editedAt: message.attributes.edited_at,
    createdAt: message.attributes.created_at,
  };
};

const mapDispatchToProps = {
  deleteMessage,
  edit,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatTime);
