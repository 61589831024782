import { fetchRecords, fetchRecord, saveRecord } from 'redux-json-api-module';

export const FETCH_WORK_ORDERS_RECURRING_SUCCESS = 'intimely/workOrders/FETCH_WORK_ORDERS_RECURRING_SUCCESS';

const INITIAL_STATE = {
  communityIdMap: {},
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_WORK_ORDERS_RECURRING_SUCCESS:
      return {
        ...state,
        communityIdMap: action.communityIdMap,
      };

    default:
      return state;
  }
}

export function fetchWorkOrders() {
  return dispatch => dispatch(fetchRecords('work_order_listings/recurring', {
    include: 'community,unit,work_order_tasks,work_order_tasks.user',
    sort: 'keys_received_on',
  }))
    .then((resp) => {
      if (resp.error) return resp;

      const communityIdMap = {};
      resp.payload.data.data.forEach((r) => {
        const communityId = r.relationships.community.data.id;
        if (!communityIdMap[communityId]) communityIdMap[communityId] = [];
        communityIdMap[communityId].push(r.id);
      });

      dispatch({
        type: FETCH_WORK_ORDERS_RECURRING_SUCCESS,
        communityIdMap,
      });

      return resp;
    });
}

export const disableRecurringWorkOrder = workOrderId => (
  (dispatch) => {
    dispatch(saveRecord({
      type: 'work_orders',
      id: workOrderId,
      attributes: {
        recurring: false,
      },
    }, { params: { window_id: window.WINDOW_ID } }))
      .then(() => dispatch(fetchWorkOrders()));
  }
);
