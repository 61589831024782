const getMultipleRoomsKey = (area) => {
  const lowerCasedArea = area.toLowerCase();

  if (lowerCasedArea.includes('bedroom')) return 'Bedroom :bed_count';
  if (lowerCasedArea.includes('bathroom')) return 'Bathroom :bath_count';
  if (lowerCasedArea.includes('restroom')) return 'Restroom :bath_count';

  return area;
};

const getItems = (itemsByArea, area) => (
  itemsByArea[getMultipleRoomsKey(area)] || itemsByArea[area] || []
);

export const getItemOptions = (itemsByArea, area) => (
  getItems(itemsByArea, area).map(({ id, name }) => [`${area} - ${name}`, { area, id }])
);
