import React from 'react';
import PropTypes from 'prop-types';
import blueChevron from '../../../assets/images/icon/blue-chevron.png';

const containerStyle = {
  minWidth: '60px',
};

const ActiveArrow = ({ active }) => {
  const wrapperStyle = containerStyle;

  return active ? (
    <div style={wrapperStyle}>
      <img
        className="d-inline-block"
        src={blueChevron}
        style={{
          height: '32px',
          marginLeft: '20px',
        }}
      />
    </div>
  ) : null;
};

ActiveArrow.propTypes = {
  active: PropTypes.bool,
};

ActiveArrow.defaultProps = {
  active: false,
};

export default ActiveArrow;
