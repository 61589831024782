import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import COLORS from '../../helpers/colors';
import { INVERSE_TYPE_MAP } from '../../helpers/types';
import PlanUnreadCount from './PlanUnreadCount';
import {
  setActiveMessageThread,
} from '../../redux/modules/chat';
import { idType } from '../../helpers/propTypes';

const PlanHeader = ({
  messageThread,
  messageThreadId,
  active,
  planType,
  planId,
  userId,
  communityId,
  setActiveMessageThread,
}) => (
  <button
    type="button"
    id={`plan-link-${planType}-${planId}`}
    className="btn btn-link text-left"
    style={{
      marginLeft: '10px',
      width: '260px',
      color: COLORS.blue,
    }}
    onClick={() => {
      setActiveMessageThread({
        planId,
        planType,
        communityId,
        messageThreadId,
      });
    }}
  >
    <div className="row">
      <div className="my-auto col-1 pl-0">
        <PlanUnreadCount
          planId={planId}
          planType={planType}
          messageThreadId={messageThreadId}
          userId={userId}
          active={active}
        />
      </div>
      <div className="col-10 pl-0 ml-3">
        <div
          className="pl-0"
        >
          <div style={{ textAlign: 'left' }} className={active ? 'font-weight-bold' : ''}>
            {messageThread.attributes.street_address}
          </div>
          <div style={{ textAlign: 'left' }} className={active ? 'font-weight-bold' : ''}>
            {messageThread.attributes.unit_number}
          </div>
        </div>
      </div>
    </div>
  </button>
);

PlanHeader.propTypes = {
  messageThread: PropTypes.object.isRequired,
  messageThreadId: idType.isRequired,
  active: PropTypes.bool.isRequired,
  planType: PropTypes.string.isRequired,
  planId: idType.isRequired,
  userId: idType.isRequired,
  communityId: idType.isRequired,
  setActiveMessageThread: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const planId = ownProps.messageThread.attributes.subject_id;
  const planType = INVERSE_TYPE_MAP[ownProps.messageThread.attributes.subject_type];
  const messageThreadId = ownProps.messageThread.id;

  return {
    planType,
    planId,
    messageThreadId,
  };
};

const mapDispatchToProps = {
  setActiveMessageThread,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanHeader);
