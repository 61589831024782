import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import toastr from 'toastr';
import { getRelationship, saveRecord } from 'redux-json-api-module';
import get from 'lodash.get';
import MaintenanceSelect from '../../../components/MaintenanceSelect';
import Scheduler from '../../ProjectManagementContainer/Scheduler';
import SchedulerButton from '../../ProjectManagementContainer/Scheduler/SchedulerButton';
import { closeScheduler, openScheduler } from '../../../redux/modules/scheduler';
import { assignUser, saveInspection } from '../../../redux/modules/projectManagement';

class InspectionScheduler extends Component {
  static propTypes = {
    projectService: PropTypes.objectOf(PropTypes.any).isRequired,
    closeScheduler: PropTypes.func.isRequired,
    saveInspection: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit(projectService) {
    const { saveInspection, closeScheduler } = this.props;

    saveInspection(projectService)
      .then((resp) => {
        if (resp.error) {
          toastr.error('The Project Service could not be updated.');
          return;
        }

        closeScheduler();
      });
  }

  handleChange(userId) {
    const { assignUser, projectService } = this.props;

    assignUser(userId, projectService);
  }

  render() {
    const { projectService, service, user } = this.props;

    if (!user) return null;

    return (
      <React.Fragment>
        <div className="row m-t-20 m-b-15">
          <div className="col">
            <MaintenanceSelect
              user={user}
              serviceId={service.id}
              onChange={this.handleChange}
              key={`maintenance-select-${service.id}`}
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <span className="font-weight-bold m-r-10">
              Inspection Schedule Date:
            </span>
            <SchedulerButton taskType="project_services" id={projectService.id} />
            <Scheduler onSubmit={this.handleSubmit} scheduledType="calendar_events" />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const userRel = get(ownProps, 'projectService.relationships.user', null);
  const serviceRel = get(ownProps, 'projectService.relationships.service', null);

  const user = userRel ? getRelationship(state.Api, userRel) : null;
  const service = serviceRel ? getRelationship(state.Api, serviceRel) : null;

  return {
    user,
    service,
  };
};

const mapDispatchToProps = {
  assignUser,
  closeScheduler,
  openScheduler,
  saveRecord,
  saveInspection,
};

export default connect(mapStateToProps, mapDispatchToProps)(InspectionScheduler);
