import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Dropdown } from 'react-bootstrap';
import { deleteRecord, fetchRecords } from 'redux-json-api-module';
import { firstBy } from 'thenby';
import toastr from 'toastr';
import FeatureFormModal from '../FeatureFormModal';

const SHORTCUT_HEIGHT = 37;
const DROPDOWN_PADDING = 100;

const FeatureFormButton = ({
  areas, descriptions, items, onSubmit, services,
  visible, onHide, onShow, fetchRecords,
  featureShortcuts, deleteRecord,
  workOrderDescription, dropdownClassName, dropdownSize,
}) => {
  useEffect(() => {
    fetchRecords('feature_shortcuts');
  }, []);

  const createFeature = (shortcut) => {
    onSubmit({
      room_id: shortcut.relationships.room.data.id,
      item_id: shortcut.relationships.item.data?.id,
      name: shortcut.attributes.feature_name,
      description: shortcut.attributes.feature_description,
      service_id: shortcut.relationships.service.data?.id,
    }).then((resp) => {
      if (resp.error) {
        return toastr.error('Item could not be created');
      }

      return toastr.success('Item created successfully.');
    });
  };

  const deleteShortcut = (shortcut) => {
    if (!window.confirm('Are you sure?')) return;
    deleteRecord(shortcut).then((resp) => {
      if (resp.error) {
        return toastr.error('Shortcut could not be deleted.');
      }

      return toastr.success('Shortcut was deleted.');
    });
  };

  const shortcuts = Object.values(featureShortcuts)
    .sort(firstBy(a => a.attributes.name, { ignoreCase: true }));

  // conditional dropdown menu margin to avoid being hidden under fixed header
  const dropdownMenuStyles = { overflowY: 'scroll', maxHeight: '500px' };
  if (window.innerHeight / 2 < (shortcuts.length * SHORTCUT_HEIGHT) + DROPDOWN_PADDING) {
    dropdownMenuStyles.marginTop = '65px';
  }

  return (
    <>
      <Dropdown className={dropdownClassName}>
        <Dropdown.Toggle variant="success" size={dropdownSize}>
          Add Service
        </Dropdown.Toggle>
        <Dropdown.Menu style={dropdownMenuStyles}>
          <Dropdown.Item onClick={onShow}>
            Add Service
          </Dropdown.Item>
          {shortcuts.length > 0 ? (
            <>
              <Dropdown.Divider />
              {shortcuts.map(s => (
                <div key={s.id} className="position-relative">
                  <Dropdown.Item
                    onClick={() => createFeature(s)}
                  >
                    {s.attributes.name}
                  </Dropdown.Item>
                  <Button
                    size="sm"
                    variant="link"
                    onClick={(e) => {
                      e.preventDefault();
                      return deleteShortcut(s);
                    }}
                    className="position-absolute"
                    style={{ right: 0, top: 5 }}
                  >
                    X
                  </Button>
                </div>
              ))}
            </>
          ) : null}
        </Dropdown.Menu>
      </Dropdown>

      <FeatureFormModal
        visible={visible}
        onHide={onHide}
        onSubmit={onSubmit}
        areas={areas}
        workOrderDescription={workOrderDescription}
        descriptions={descriptions}
        items={items}
        services={services}
        onCancel={onHide}
      />
    </>
  );
};

FeatureFormButton.propTypes = {
  areas: PropTypes.arrayOf(PropTypes.string).isRequired,
  descriptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  items: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.any)).isRequired,
  services: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onShow: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  dropdownClassName: PropTypes.string,
  dropdownSize: PropTypes.string,
};

FeatureFormButton.defaultProps = {
  dropdownClassName: 'float-right',
  dropdownSize: 'sm',
};

const mapStateToProps = state => ({
  featureShortcuts: state.Api.feature_shortcuts || {},
});

const mapDispatchToProps = {
  fetchRecords,
  deleteRecord,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FeatureFormButton);
