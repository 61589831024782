import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchRecord, getRecord } from 'redux-json-api-module';
import UserDisplay from '../MaintenanceSelect/UserDisplay';

const ImageUser = ({ fetchRecord, user, userId }) => {
  useEffect(() => {
    if (!user) fetchRecord('users', userId);
  });

  return user ? (
    <UserDisplay {...user.attributes} id={user.id} />
  ) : null;
};

const mapStateToProps = (state, props) => ({
  user: getRecord(state.Api, {
    type: 'users',
    id: props.userId,
  }),
});


const mapDispatchToProps = {
  fetchRecord,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageUser);
