import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker from './DatePicker';
import calendarIcon from '../../assets/images/icon/calendar.png';

class DateInput extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    detail: PropTypes.string,
    style: PropTypes.object,
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    name: PropTypes.string,
  };

  static defaultProps = {
    detail: 'day',
    className: '',
    inputClassName: 'form-control',
  };

  static detail = {
    day: {
      format: 'YYYY-MM-DD',
      maxDetail: 'month',
    },
    month: {
      format: 'YYYY-MM',
      maxDetail: 'year',
    },
    year: {
      format: 'YYYY',
      maxDetail: 'decade',
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      value: this.label(props.value),
      isOpen: false,
    };

    this.onCalendarChange = this.onCalendarChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onBlur = this.onBlur.bind(this);

    this.input = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      value: this.label(nextProps.value),
      isOpen: false,
    });
  }

  onClick() {
    this.showCalendar();
  }

  onCalendarChange(value) {
    this.setState({
      value: this.label(value),
      isOpen: false,
    }, () => {
      if (this.props.onChange) this.props.onChange(this.state.value);
    });
  }

  onInputChange(event) {
    const { value } = event.target;

    if (value.length == 7 && moment(value)
      .isValid()) {
      this.setState({ value }, () => {
        if (this.props.onChange) this.props.onChange(this.state.value);
      });
    } else {
      this.setState({ value });
    }
  }

  label(value) {
    return value ? moment(value)
      .format(DatePicker.detail[this.props.detail].format) : '';
  }

  focus() {
    this.input.current.focus();
    this.showCalendar();
  }

  showCalendar() {
    this.setState({ isOpen: true });
  }

  onBlur() {
    this.setState({ isOpen: false });
  }

  render() {
    const { inputClassName, name } = this.props;
    const { value, isOpen } = this.state;

    return (
      <React.Fragment>
        <DatePicker
          value={moment(value)
            .isValid() ? moment(value)
              .toDate() : new Date()}
          isOpen={isOpen}
          onBlur={this.onBlur}
          onChange={this.onCalendarChange}
        />

        <div className="input-group">
          <div className="input-group-append">
            <img
              src={calendarIcon}
              onClick={this.onClick}
              alt="Calendar"
              style={{
                maxWidth: '35px',
                maxHeight: '35px',
                marginRight: '15px',
                cursor: 'pointer',
              }}
            />
          </div>
          <input
            ref={this.input}
            type="text"
            value={moment(value)
              .format('MM-DD-YY')}
            onClick={this.onClick}
            className={`form-control ${inputClassName}`}
            onChange={this.onInputChange}
          />
          <input type="hidden" value={value} name={name} />
        </div>

      </React.Fragment>
    );
  }
}

export default DateInput;
