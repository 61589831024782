import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';
import ChatLayoutContainer from '../containers/LayoutContainer/ChatLayoutContainer';

const ChatLayout = props => (
  <Provider store={store}>
    <ChatLayoutContainer {...props} />
  </Provider>
);

export default ChatLayout;
