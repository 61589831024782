import moment from 'moment';

const MIN_HOURS = 7;
const MAX_HOURS = 19;

const MIN_TIME = new Date();
MIN_TIME.setHours(MIN_HOURS, 0, 0);

const MAX_TIME = new Date();
MAX_TIME.setHours(MAX_HOURS, 0, 0);

export { MIN_TIME, MAX_TIME };

export const getTaskPlanRel = task => (task?.relationships.project || task?.relationships.work_order)?.data;

// apply scheduled times to selected start and end dates
export const getUpdatedDates = ({ scheduled, start, end }) => {
  let startedAt;
  let endedAt;

  if (scheduled.attributes.started_at) {
    if (!start) {
      startedAt = moment(scheduled.attributes.started_at).toDate();
      endedAt = moment(scheduled.attributes.ended_at).toDate();
    } else {
      startedAt = moment(start)
        .set('hours', moment(scheduled.attributes.started_at).hours())
        .set('minutes', moment(scheduled.attributes.started_at).minutes())
        .toDate();

      endedAt = moment(end)
        .set('hours', moment(scheduled.attributes.ended_at).hours())
        .set('minutes', moment(scheduled.attributes.ended_at).minutes())
        .toDate();
    }
  } else {
    startedAt = moment(start).set('hours', MIN_HOURS).toDate();
    endedAt = moment(end).set('hours', MAX_HOURS).toDate();
  }

  // avoid duration <= 0
  if (startedAt.valueOf() >= endedAt.valueOf()) {
    endedAt = moment(startedAt)
      .add(1, 'hours')
      .toDate();
  }

  return {
    start: startedAt,
    end: endedAt,
    started_at: startedAt,
    ended_at: endedAt,
  };
};

export const isCompleted = event => Boolean(event.resource?.completed);
export const isDisabled = isCompleted;
export const isEventOfPlan = (event, plan) => (
  event.resource != null && event.resource.planId === plan?.id
);
