import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';
import ChatsContainer from '../containers/ChatsContainer/ChatsContainer';

const Chats = props => (
  <Provider store={store}>
    <ChatsContainer {...props} />
  </Provider>
);

export default Chats;
