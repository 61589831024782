import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchRecords } from 'redux-json-api-module';
import moment from 'moment';
import AddressCell from './AddressCell';
import ServicesCell from './ServicesCell';
import StatusCell from './StatusCell';
import Table from './Table';
import '../CompletedPlansContainer.scss';

const COLUMNS = [
  {
    text: 'Address',
    dataField: 'attributes.address',
    formatter: (_, plan) => <AddressCell plan={plan} />,
    sort: true,
  }, {
    text: 'Done',
    dataField: 'attributes.completed_at',
    type: 'date',
    sort: true,
    formatter: (_, plan) => (
      moment(plan.attributes.completed_at).format('MM/DD/YYYY')
    ),
  }, {
    text: 'Completed Services',
    dataField: 'attributes.completed_tasks',
    formatter: (_, plan) => <ServicesCell plan={plan} />,
  }, {
    text: 'Status',
    dataField: 'attributes.status',
    formatter: (_, plan) => <StatusCell plan={plan} />,
  },
  {
    text: 'Speed',
    dataField: 'attributes.duration',
    formatter: duration => (
      `${duration} day${duration !== 1 ? 's' : ''}`
    ),
  },
];

const ProjectListingsTable = ({ values, fetchRecords }) => {
  const fetchPage = ({ page, sizePerPage, sortField, sortOrder }) => {
    let sort = 'keys_received_on';
    if (sortField) {
      const parts = sortField.split('.');
      parts.shift();
      sort = parts.join('.');
    }

    const params = {
      include: 'community,unit,project_services',
      filter: {
        scope: 'completed',
        unit_id: values.unitId,
        service_id: values.serviceId,
        start_date: values.startDate,
        end_date: values.endDate,
      },
      sort: sortOrder === 'asc' ? sort : `-${sort}`,
      page: {
        size: sizePerPage,
        number: page,
      },
    };

    const path = `communities/${values.communityId}/project_listings`;

    return fetchRecords(path, params);
  };

  return (
    <Table
      communityId={values.communityId}
      unitId={values.unitId}
      serviceId={values.serviceId}
      startDate={values.startDate}
      endDate={values.endDate}
      columns={COLUMNS}
      fetchPage={fetchPage}
    />
  );
};

ProjectListingsTable.propTypes = {
  values: PropTypes.shape({
    communityId: PropTypes.string.isRequired,
    unitId: PropTypes.string,
    serviceId: PropTypes.string,
    startDate: PropTypes.any,
    endDate: PropTypes.any,
  }).isRequired,

  // connected
  fetchRecords: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  fetchRecords,
};

export default connect(null, mapDispatchToProps)(ProjectListingsTable);
