import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { getRecord } from 'redux-json-api-module';
import BadgeHeader from '../../components/ServiceDetails/BadgeHeader';

const mapStateToProps = (state, ownProps) => {
  const messageThread = getRecord(state.Api, {
    type: 'message_threads',
    id: ownProps.messageThreadId,
  });
  const serviceBadge = get(messageThread, 'attributes.service_badge', null);
  const conditionBadge = get(messageThread, 'attributes.conditions', null);
  const serviceStatus = get(messageThread, 'attributes.service_status.private', null);
  const priorityBadge = get(messageThread, 'attributes.priority_badge', null);

  return {
    serviceBadge,
    conditionBadge,
    serviceStatus,
    priorityBadge,
  };
};

export default connect(mapStateToProps)(BadgeHeader);
