import { fetchRecords, SAVE_RECORD, saveRecord } from 'redux-json-api-module';
import toastr from 'toastr';
import { idComparison } from '../../helpers/string';

const INITIAL_STATE = {};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    default:
      return state;
  }
}

export function saveImport(id) {
  return (dispatch) => {
    dispatch({
      type: SAVE_RECORD,
      payload: {
        request: {
          method: 'POST',
          url: `/item_imports/${id}/save`,
        },
      },
    }).then(() => {
      window.location.href = '/inventory';
    });
  };
}
