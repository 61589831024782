import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { getRecord, getRelationship } from 'redux-json-api-module';
import get from 'lodash.get';
import Messenger from '../../components/Messenger';
import { teamObject } from '../../helpers/propTypes';
import { getMessenger, touch } from '../../redux/modules/chat';
import ChatBadgeHeader from './ChatBadgeHeader';

class ChatTab extends PureComponent {
  static propTypes = {
    messageThreadId: PropTypes.string,
    onInputFocus: PropTypes.func,
    team: teamObject,
    userId: PropTypes.string.isRequired,
  };

  static defaultProps = {
    onInputFocus: () => {
    },
    messageThreadId: null,
    team: null,
  };

  static getDerivedStateFromProps(props, state) {
    const { neverViewed, viewedAt, messagesLoaded } = props;
    const { stickyNeverViewed, stickyViewedAt } = state;

    if (stickyNeverViewed || !messagesLoaded) return null;

    if (neverViewed) {
      return {
        stickyNeverViewed: true,
      };
    }

    if (viewedAt && (viewedAt !== stickyViewedAt)) {
      const earliest = stickyViewedAt
        ? moment.min([moment(viewedAt), moment(stickyViewedAt)])
        : viewedAt;

      return {
        stickyViewedAt: earliest,
      };
    }
    return null;
  }

  state = {
    stickyViewedAt: null,
    stickyNeverViewed: null,
  };

  render() {
    const {
      messageThreadId,
      onInputFocus,
      team,
      userId,
    } = this.props;

    const { stickyViewedAt, stickyNeverViewed } = this.state;

    return (
      <div className="chat-main-box">
        {messageThreadId ? <ChatBadgeHeader messageThreadId={messageThreadId} /> : null}
        <div>
          {messageThreadId ? (
            <Messenger
              key={messageThreadId}
              onInputFocus={onInputFocus}
              messageThreadId={messageThreadId}
              team={team}
              userId={userId}
              stickyViewedAt={stickyViewedAt}
              neverViewed={stickyNeverViewed}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let messageThread;

  if (state.Chat.activeChannelId) {
    const channel = getRecord(state.Api, { type: 'chat_channels', id: state.Chat.activeChannelId });
    messageThread = channel ? getRelationship(state.Api, channel.relationships.message_thread) : null;
  } else if (state.Chat.activeDirectMessageChatId) {
    const chat = getRecord(state.Api, { type: 'direct_message_chats', id: state.Chat.activeDirectMessageChatId });
    messageThread = chat ? getRelationship(state.Api, chat.relationships.message_thread) : null;
  } else {
    messageThread = getRecord(state.Api, { type: 'message_threads', id: state.Chat.activeMessageThreadId });
  }

  const messageThreadId = messageThread ? messageThread.id : null;

  const messenger = getMessenger(state, messageThreadId, ownProps.userId);
  const viewedAt = get(messenger, 'attributes.viewed_at', null);
  const neverViewed = messenger && !viewedAt;

  return {
    messageThreadId,
    viewedAt,
    neverViewed,
    messagesLoaded: state.Chat.messagesLoaded,
  };
};

const mapDispatchToProps = {
  onInputFocus: touch,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatTab);
