(function ($) {
  let cocoon_element_counter = 0;

  const create_new_id = function () {
    return (new Date().getTime() + cocoon_element_counter++);
  };

  const newcontent_braced = function (id) {
    return `[${id}]$1`;
  };

  const newcontent_underscord = function (id) {
    return `_${id}_$1`;
  };

  const getInsertionNodeElem = function (insertionNode, insertionTraversal, $this) {
    if (!insertionNode) {
      return $this.parent();
    }

    if (typeof insertionNode === 'function') {
      if (insertionTraversal) {
        console.warn('association-insertion-traversal is ignored, because association-insertion-node is given as a function.');
      }
      return insertionNode($this);
    }

    if (typeof insertionNode === 'string') {
      if (insertionTraversal) {
        return $this[insertionTraversal](insertionNode);
      }
      return insertionNode == 'this' ? $this : $(insertionNode);
    }
  };

  $(document).on('click', '.add_fields', function (e) {
    e.preventDefault();
    const $this = $(this);
    const assoc = $this.data('association');
    const assocs = $this.data('associations');
    const content = $this.data('association-insertion-template');
    const insertionMethod = $this.data('association-insertion-method') || $this.data('association-insertion-position') || 'before';
    const insertionNode = $this.data('association-insertion-node');
    const insertionTraversal = $this.data('association-insertion-traversal');
    let count = parseInt($this.data('count'), 10);
    let regexp_braced = new RegExp(`\\[new_${assoc}\\](.*?\\s)`, 'g');
    let regexp_underscord = new RegExp(`_new_${assoc}_(\\w*)`, 'g');
    let new_id = create_new_id();
    let new_content = content.replace(regexp_braced, newcontent_braced(new_id));
    let new_contents = [];


    if (new_content == content) {
      regexp_braced = new RegExp(`\\[new_${assocs}\\](.*?\\s)`, 'g');
      regexp_underscord = new RegExp(`_new_${assocs}_(\\w*)`, 'g');
      new_content = content.replace(regexp_braced, newcontent_braced(new_id));
    }

    new_content = new_content.replace(regexp_underscord, newcontent_underscord(new_id));
    new_contents = [new_content];

    count = (isNaN(count) ? 1 : Math.max(count, 1));
    count -= 1;

    while (count) {
      new_id = create_new_id();
      new_content = content.replace(regexp_braced, newcontent_braced(new_id));
      new_content = new_content.replace(regexp_underscord, newcontent_underscord(new_id));
      new_contents.push(new_content);

      count -= 1;
    }

    const insertionNodeElem = getInsertionNodeElem(insertionNode, insertionTraversal, $this);

    if (!insertionNodeElem || (insertionNodeElem.length == 0)) {
      console.warn("Couldn't find the element to insert the template. Make sure your `data-association-insertion-*` on `link_to_add_association` is correct.");
    }

    $.each(new_contents, (i, node) => {
      const contentNode = $(node);

      const before_insert = jQuery.Event('cocoon:before-insert');
      insertionNodeElem.trigger(before_insert, [contentNode]);

      if (!before_insert.isDefaultPrevented()) {
        // allow any of the jquery dom manipulation methods (after, before, append, prepend, etc)
        // to be called on the node.  allows the insertion node to be the parent of the inserted
        // code and doesn't force it to be a sibling like after/before does. default: 'before'
        const addedContent = insertionNodeElem[insertionMethod](contentNode);

        insertionNodeElem.trigger('cocoon:after-insert', [contentNode]);
      }
    });
  });

  $(document).on('click', '.remove_fields.dynamic, .remove_fields.existing', function (e) {
    const $this = $(this);
    const wrapper_class = $this.data('wrapper-class') || 'nested-fields';
    const node_to_delete = $this.closest(`.${wrapper_class}`);
    const trigger_node = node_to_delete.parent();

    e.preventDefault();

    const before_remove = jQuery.Event('cocoon:before-remove');
    trigger_node.trigger(before_remove, [node_to_delete]);

    if (!before_remove.isDefaultPrevented()) {
      const timeout = trigger_node.data('remove-timeout') || 0;

      setTimeout(() => {
        if ($this.hasClass('dynamic')) {
          node_to_delete.detach();
        } else {
          $this.prev('input[type=hidden]').val('1');
          node_to_delete.hide();
        }
        trigger_node.trigger('cocoon:after-remove', [node_to_delete]);
      }, timeout);
    }
  });


  $(document).on('ready page:load turbolinks:load', () => {
    $('.remove_fields.existing.destroyed').each(function (i, obj) {
      const $this = $(this);
      const wrapper_class = $this.data('wrapper-class') || 'nested-fields';

      $this.closest(`.${wrapper_class}`).hide();
    });
  });
}(jQuery));
