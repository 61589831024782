import React from 'react';
import PropTypes from 'prop-types';

const downStyle = { marginTop: '10px', marginLeft: '-7px' };
const upStyle = { marginTop: '10px', marginLeft: '-3px' };

const ExpandColumn = ({ expanded }) => (
  expanded ? (
    <i className="fa fa-chevron-down" style={downStyle} />
  ) : (
    <i className="fa fa-chevron-right" style={upStyle} />
  )
);

ExpandColumn.propTypes = {
  expanded: PropTypes.bool.isRequired,
};

export default ExpandColumn;
