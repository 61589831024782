import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getRecord, getRelationship, saveRecord } from 'redux-json-api-module';
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import questionMarkIcon from '../../../../assets/images/icon/question-mark-purple.png';
import { PARENT_TYPE_MAP } from '../../../helpers/types';
import { fetchAreaOptions } from '../../../redux/modules/workOrderManagement';
import FeatureFormModal from '../../FeatureFormModal/FeatureFormModal';

const HelpTextOverlay = (
  {
    task,
    areaItems,
    descriptionOptions,
    serviceOptions,
    unitId,
    saveRecord,
    fetchAreaOptions,
  },
) => {
  const [formVisible, setFormVisible] = useState(false);
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [areaOptions, setAreaOptions] = useState([]);
  const initialValues = { service_id: parseInt(task.relationships.service.data.id) };

  const createFeature = (attributes) => {
    const record = {
      id: null,
      type: 'work_order_features',
      attributes: { ...attributes, work_order_id: task.relationships.work_order.data.id },
    };

    const options = {
      params: {
        include: 'work_order_task',
        window_id: window.WINDOW_ID,
      },
    };

    return saveRecord(record, options)
      .then((resp) => {
        if (resp.error) return resp;

        return Promise.resolve({});
      });
  };

  useEffect(() => {
    fetchAreaOptions(unitId)
      .then((resp) => {
        if (resp.error) {
          console.log(resp.error);
          return;
        }

        setAreaOptions(resp.payload.data);
      });
  }, []);

  return (
    <>
      <OverlayTrigger
        trigger="click"
        placement="auto"
        onToggle={visible => setOverlayVisible(visible)}
        show={overlayVisible}
        overlay={(
          <Popover id="help-text-popover">
            <Popover.Content>
              <p>
                To manually mark this Service as Done, first
                <Button
                  variant="link"
                  size="sm"
                  className="py-0"
                  onClick={() => {
                    setOverlayVisible(false);
                    setFormVisible(true);
                  }}
                >
                  click here
                </Button>
                to add an item to this Service. You may also click on the green + icon for this
                service in the "Service Details" section below, first click on the Service badge
                and then you may click the green + icon.
              </p>
              <p>
                If you want to delete this Service, since it is the only Service in this
                Work Order, you may delete the entire Work Order by clicking the link at the
                bottom of the page
              </p>
            </Popover.Content>
          </Popover>
        )}
      >
        <Button variant="light" className="py-0">
          <i className="fa fa-pencil mr-2" />
          <img
            src={questionMarkIcon}
            alt="Question Mark Icon"
            style={{
              maxWidth: '35px',
              maxHeight: '35px',
            }}
          />
        </Button>
      </OverlayTrigger>
      <FeatureFormModal
        visible={formVisible}
        onHide={() => setFormVisible(false)}
        onSubmit={createFeature}
        areas={areaOptions}
        descriptions={descriptionOptions}
        items={areaItems}
        services={serviceOptions}
        initialValues={initialValues}
      />
    </>
  );
};

const mapStateToProps = (state, ownProps) => {
  const task = getRecord(state.Api, { type: ownProps.taskType, id: ownProps.taskId });
  const plan = getRelationship(state.Api, task.relationships[PARENT_TYPE_MAP[task.type]]);
  const unit = getRelationship(state.Api, plan.relationships.unit);

  return {
    areaItems: state.WorkOrderManagement.areaItems,
    descriptionOptions: state.WorkOrderManagement.descriptionOptions,
    serviceOptions: state.WorkOrderManagement.serviceOptions,
    task,
    unitId: unit.id,
  };
};

const mapDispatchToProps = {
  saveRecord,
  fetchAreaOptions,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HelpTextOverlay);
