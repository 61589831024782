import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord, getRelationship } from 'redux-json-api-module';
import { firstBy } from 'thenby';
import { FEATURE_TYPE_MAP } from '../../../helpers/types';
import { genericRecordPropType } from '../../../helpers/propTypes';
import { numberToCurrency } from '../../../helpers/string';

const RoomInvoiceItems = ({ room, serviceInvoiceItems }) => {
  if (serviceInvoiceItems.length === 0) return null;

  const totalPrice = serviceInvoiceItems
    .map(sii => sii.attributes.total_price)
    .reduce((sum, x) => sum + x);

  return (
    <div className="row mb-3">
      <div className="col">
        <strong>{room.attributes.name}</strong>
        <table className="table">
          <thead>
            <tr>
              <th width="50%">Item</th>
              <th width="20%">Quantity</th>
              <th width="20%">Total Price</th>
            </tr>
          </thead>
          <tbody>
            {serviceInvoiceItems.sort(
              firstBy(sii => sii.attributes.name),
            ).map(sii => (
              <tr key={sii.id}>
                <td>{sii.attributes.part}</td>
                <td>{sii.attributes.quantity}</td>
                <td>{numberToCurrency(sii.attributes.total_price)}</td>
              </tr>
            ))}
            <tr>
              <td className="text-right" colSpan={2}>
                <strong>Total:</strong>
              </td>
              <td>
                {numberToCurrency(totalPrice)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

RoomInvoiceItems.propTypes = {
  room: PropTypes.objectOf(genericRecordPropType).isRequired,
  serviceInvoiceItems: PropTypes.arrayOf(genericRecordPropType).isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const task = getRecord(state.Api, { type: ownProps.taskType, id: ownProps.taskId });
  const room = getRecord(state.Api, { type: 'rooms', id: ownProps.roomId });
  const features = getRelationship(
    state.Api, task.relationships[FEATURE_TYPE_MAP[task.type]],
  ).filter(f => f.relationships.room.data.id === room.id);

  const serviceInvoiceItems = features.map(f => (
    getRelationship(state.Api, f.relationships.service_invoice_item)
  ));

  return {
    room,
    serviceInvoiceItems: serviceInvoiceItems.filter(sii => sii),
  };
};

export default connect(
  mapStateToProps,
)(RoomInvoiceItems);
