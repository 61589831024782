import toastr from 'toastr';

function getIncludedRecord(included, type, id) {
  return included.find(i => i.type === type && (id === undefined || i.id === id));
}

function getRelationship(rel, included) {
  if (Array.isArray(rel.data)) {
    return rel.data.map(child => getIncludedRecord(included, child.type, child.id));
  }
  return getIncludedRecord(included, rel.data.type, rel.data.id);
}

export function getTeams(memberships) {
  return memberships.data.map((m) => {
    const team = getRelationship(m.relationships.team, memberships.included);
    return {
      label: team.attributes.label,
      membershipId: m.id,
    };
  })
    .sort(t => t[0]);
}

export function changeMembership(membershipId, user) {
  toastr.info('Switching Teams');

  const response = $.ajax({
    type: 'PATCH',
    url: `/api/v1/users/${user.data.id}`,
    data: { data: { attributes: { membership_id: membershipId } } },
  });

  response.done(() => {
    window.location = '/';
  });

  response.fail(() => toastr.error('Could not switch teams'));
}
