import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { saveRecord } from 'redux-json-api-module';
import { Form } from 'react-final-form';
import { Button } from 'react-bootstrap';
import TextField from './TextField';

class SignupContainer extends Component {
  static propTypes = {
    email_sent: PropTypes.bool,
    saveRecord: PropTypes.func.isRequired,
  };

  static defaultProps = {
    email_sent: false,
  };

  constructor(props) {
    super(props);

    const { email_sent: emailSent } = props;

    this.state = {
      emailSent,
    };
  }

  handleSubmit = (values) => {
    const { saveRecord } = this.props;
    this.setState({ emailSent: true });

    const record = {
      type: 'user_confirmations',
      attributes: {
        email: values.email,
      },
    };

    saveRecord(record, { params: { window_id: window.WINDOW_ID } });

    $('#confirmation-form').removeClass();
    $('#devise-form').show();
    $('input[name="user[email]"]').val(values.email);
    document.getElementById('user_name').focus();
  };

  render() {
    const { emailSent, email } = this.state;

    if (emailSent) return null;

    return (
      <>
        <Form
          initialValues={{ email }}
          onSubmit={this.handleSubmit}
          render={({ handleSubmit }) => (
            <form className="simple_form form-material" id="new_user" onSubmit={handleSubmit}>
              <h4 className="mb-5">
                Please confirm your full name and email address.
              </h4>
              <h2 className="box-title m-b-20">Let's get your Intimely set up!</h2>
              <TextField
                label="What's your email address?"
                name="email"
                required
                autoFocus
              />
              <div className="form-group">
                <Button type="submit"
                        variant="success">{emailSent ? 'Confirm' : 'Send Confirmation Email'}</Button>
              </div>
            </form>
          )}
        />
        <div className="text-blue">
          <hr />
          <div className="signup-link">
            Already have an account?
            {' '}
            <a href="/users/sign_in">Sign in</a>
          </div>
          <br />
          <div className="mb-3">
            <a href="/users/unlock/new">Didn't receive unlock instructions?</a>
            <br />
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {
  saveRecord,
};

export default connect(null, mapDispatchToProps)(SignupContainer);
