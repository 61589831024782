import React from 'react';

export function splitMessage(message) {
  if (!message) return [];
  const parts = message.split('on');
  if (parts.length === 0) return [];
  const first = parts.slice(0, parts.length - 1);
  let last = parts[parts.length - 1];
  last = `on${last}`;
  return first.length === 0
    ? [last] : [<span key="1">{first.join('on')}</span>, <br key="2" />,
      <span key="3">{last}</span>];
}
