import toastr from 'toastr';
import moment from 'moment';
import {
  FETCH_RECORDS_SUCCESS,
  fetchRecord,
  fetchRecords,
  SAVE_RECORD,
  saveRecord,
  deleteRecord, getRecord,
  getRelationship,
} from 'redux-json-api-module';
import get from 'lodash.get';
import { getInspection } from '../../helpers/inspection';
import { fetchMessengers } from './chat';
import { TYPE_MAP, CHILD_TYPE_MAP, FEATURE_TYPE_MAP, PARENT_TYPE_MAP } from '../../helpers/types';
import { initializeActiveTask, setActiveTask } from './navigation';

export const SET_PROJECT = 'intimely/projectManagement/SET_PROJECT';
export const HYDRATE_OPTIONS = 'intimely/projectManagement/HYDRATE_OPTIONS';
export const HYDRATE_PROJECT_MANAGEMENT_STORE = 'intimely/projectManagement/HYDRATE_PROJECT_MANAGEMENT_STORE';
export const ACTIVATE_PROJECT = 'intimely/projectManagement/ACTIVATE_PROJECT';
export const APPROVE_COMPLETION = 'intimely/projectManagement/APPROVE_COMPLETION';
export const FETCH_USER_OPTIONS = 'intimely/projectManagement/FETCH_USER_OPTIONS';
export const FETCH_AREA_OPTIONS = 'intimely/projectManagement/FETCH_AREA_OPTIONS';
export const SEND_INSPECTION_REPORT = 'intimely/projectManagement/SEND_INSPECTION_REPORT';
export const SEND_COMPLETION_REPORT = 'intimely/projectManagement/SEND_COMPLETION_REPORT';
export const SET_VALIDATION_WARNING = 'intimely/projectManagement/SET_VALIDATION_WARNING';
export const PROJECT_SERVICES_LOADED = 'intimely/projectManagement/PROJECT_SERVICES_LOADED';
export const SET_INSPECTION_TAB_TOGGLE = 'intimely/projectManagement/SET_INSPECTION_TAB_TOGGLE';
export const SET_TAB = 'intimely/projectManagement/SET_TAB';

const KEYS_RECEIVED_ON_WARNING = 'Please ensure the Inspection Schedule Date is later than (or equal to) the Project Start Date. If you are updating the Project Start Date, please change the Inspection Schedule Date first.';
const DUE_DATE_WARNING = 'Please ensure the Project’s Due Date is later than (or equal to) the Project Start Date.';

const INITIAL_STATE = {
  projectId: null,
  transitionedToActive: false,
  areaItems: {},
  descriptionOptions: [],
  serviceOptions: [],
  conditionOptions: [],
  validationWarning: null,
  projectServicesLoaded: false,
  inspectionTabToggle: 'feature-card',
  tab: null,
  projectServiceIds: [],
  recentlyOnline: [],
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HYDRATE_PROJECT_MANAGEMENT_STORE:
      return { ...INITIAL_STATE, ...action.state };

    case HYDRATE_OPTIONS:
      return {
        ...state,
        areaItems: action.areaItems,
        descriptionOptions: action.descriptionOptions,
        serviceOptions: action.serviceOptions,
        conditionOptions: action.conditionOptions,
      };

    case SET_TAB:
      return {
        ...state,
        tab: action.tab,
      };

    case SET_PROJECT:
      return {
        ...INITIAL_STATE,
        areaItems: state.areaItems,
        descriptionOptions: state.descriptionOptions,
        serviceOptions: state.serviceOptions,
        conditionOptions: state.conditionOptions,
        projectId: action.projectId,
      };

    case SET_INSPECTION_TAB_TOGGLE:
      return {
        ...state,
        inspectionTabToggle: action.inspectionTabToggle,
      };

    case ACTIVATE_PROJECT:
      return {
        ...state,
        transitionedToActive: true,
      };

    case SET_VALIDATION_WARNING:
      return {
        ...state,
        validationWarning: action.warning,
      };

    case PROJECT_SERVICES_LOADED:
      return {
        ...state,
        projectServicesLoaded: true,
        projectServiceIds: action.projectServiceIds,
      };

    default:
      return state;
  }
}

export function setValidationWarning(warning) {
  return {
    type: SET_VALIDATION_WARNING,
    warning,
  };
}

export const hydrateProjectManagementStore = state => ({
  type: HYDRATE_PROJECT_MANAGEMENT_STORE,
  state,
});

export const hydrateOptions = ({
  areaItems,
  descriptionOptions,
  serviceOptions,
  conditionOptions,
}) => ({
  type: HYDRATE_OPTIONS,
  areaItems,
  descriptionOptions,
  serviceOptions,
  conditionOptions,
});

export const setInspectionTabToggle = inspectionTabToggle => ({
  type: SET_INSPECTION_TAB_TOGGLE,
  inspectionTabToggle,
});

export const getProject = state => (
  getRecord(state.Api, {
    type: 'projects',
    id: state.ProjectManagement.projectId,
  })
);

export function fetchProject(projectId) {
  return dispatch => (
    dispatch(fetchRecord(
      'projects', projectId, {
        include: [
          'unit', 'community',
        ].join(','),
      },
    ))
  );
}

export function fetchProjectForInspection(projectId) {
  return dispatch => (
    dispatch(fetchRecord(
      'projects', projectId, {
        include: [
          'attachments', 'attachments.user', 'community',
          'project_services', 'project_services.service', 'project_services.features',
          'project_services.checklist_items',
          'rooms', 'team', 'team.memberships', 'team.memberships.user', 'unit',
        ].join(','),
        include_upload_flags: true,
      },
    ))
  );
}

export function fetchPlan(planType, planId) {
  return (dispatch) => {
    const childType = CHILD_TYPE_MAP[planType];
    const featureType = FEATURE_TYPE_MAP[childType];
    return dispatch(fetchRecord(
      planType, planId, {
        include: [
          'unit', 'community',
          childType,
          `${childType}.${featureType}`,
          `${childType}.${featureType}.purchase_request`,
        ].join(','),
      },
    ));

  };
}

export const setProjectServiceIds = projectServiceIds => ({
  type: PROJECT_SERVICES_LOADED,
  projectServiceIds,
});

export function fetchProjectServices(projectId) {
  return dispatch => (
    dispatch(fetchRecords(`projects/${projectId}/project_services`, {
      include: ['service', 'user', 'checklist_items', 'project_service_images'].join(','),
      page: { size: 250 },
      include_upload_flags: true,
    }))
      .then((resp) => {
        if (resp.error) return resp;

        const projectServiceIds = resp.payload.data.data.map(ps => ps.id);

        dispatch(setProjectServiceIds(projectServiceIds));

        return resp;
      })
  );
}

export function fetchTasks(planType, planId) {
  return dispatch => (
    dispatch(fetchRecords(`${planType}/${planId}/${CHILD_TYPE_MAP[planType]}`, {
      include: ['service', 'user', 'checklist_items'].join(','),
      page: { size: 250 },
    }))
      .then((resp) => {
        if (resp.error) return resp;

        const projectServiceIds = resp.payload.data.data.map(ps => ps.id);

        dispatch(setProjectServiceIds(projectServiceIds));

        return resp;
      })
  );
}

export function fetchTask(taskType, taskId) {
  let include = taskType === 'project_services'
    ? ['features', 'features.feature_images', 'features.item', 'project_service_images']
    : [
      'work_order_features', 'work_order_features.feature_images', 'work_order_features.item',
      'work_order_task_images',
    ];

  include = include.concat([
    'service', 'checklist_items', 'user', 'rooms',
    'service_invoice', 'service_invoice.service_invoice_items',
  ]);

  return dispatch => (
    dispatch(fetchRecord(
      taskType, taskId, {
        include: include.join(','),
      },
    ))
  );
}

export function fetchTeamMembers(teamId) {
  return dispatch => (
    dispatch(fetchRecord(
      'teams', teamId, {
        include: [
          'memberships', 'memberships.user',
        ].join(','),
      },
    ))
  );
}

export function setProject(projectId) {
  return (dispatch) => {
    dispatch({
      type: SET_PROJECT,
      projectId,
    });
    dispatch(fetchProject(projectId));
  };
}

export function submitManagementNotes(task, attributes) {
  const record = {
    type: task.type,
    id: task.id,
    attributes: {
      management_notes: attributes.management_notes || null,
    },
  };

  return dispatch => (
    dispatch(saveRecord(record, { params: { window_id: window.WINDOW_ID } }))
      .then((resp) => {
        if (resp.error) {
          toastr.error('The Task could not be updated.');
        } else {
          toastr.success('Management Notes Updated.');
        }

        return resp;
      })
  );
}

function dueOnValidationWarning(dueOn, keysReceivedOn) {
  return (moment(keysReceivedOn) > moment(dueOn))
    ? DUE_DATE_WARNING : null;
}

export function submitDueDate(planType, planId, dueOn, keysReceivedOn) {
  const record = {
    type: planType,
    id: planId,
    attributes: {
      due_on: dueOn,
    },
  };

  return (dispatch) => {
    const warning = dueOnValidationWarning(dueOn, keysReceivedOn);
    if (warning) {
      return Promise.resolve(dispatch(setValidationWarning(warning)));
    }
    return dispatch(saveRecord(record, { params: { window_id: window.WINDOW_ID } }))
      .then((resp) => {
        if (resp.error) {
          toastr.error('Could not be updated.');
        } else {
          toastr.success('Due Date Updated.');
        }
      });
  };
}

function keysReceivedOnValidationWarning(state, project, keysReceivedOn) {
  const inspection = getInspection(project, state.Api);
  if (!inspection) return null;

  if (moment(keysReceivedOn) > moment(inspection.attributes.started_at)) {
    return KEYS_RECEIVED_ON_WARNING;
  }
  if (moment(keysReceivedOn) > moment(project.attributes.due_on)) {
    return DUE_DATE_WARNING;
  }

  return null;
}

export function submitKeysReceivedOn(project, keysReceivedOn) {
  const record = Object.assign({}, project);
  record.attributes = {
    keys_received_on: keysReceivedOn,
  };

  return (dispatch, getState) => {
    const warning = keysReceivedOnValidationWarning(getState(), project, keysReceivedOn);
    if (warning) {
      return Promise.resolve(dispatch(setValidationWarning(warning)));
    }
    return dispatch(saveRecord(record, { params: { window_id: window.WINDOW_ID } }))
      .then((resp) => {
        if (resp.error) {
          toastr.error('The Project could not be updated.');
        } else {
          toastr.success('Keys Received On Updated.');
        }
      });
  };
}

function validInspection(state, inspection) {
  const fullInspection = getRecord(state.Api, {
    type: 'project_services',
    id: inspection.id,
  });
  if (!fullInspection) return true;
  const project = getRelationship(state.Api, fullInspection.relationships.project);
  if (!project || !inspection.attributes.started_at) return true;
  return moment(project.attributes.keys_received_on) <= moment(inspection.attributes.started_at);
}

export function saveInspection(inspection) {
  return (dispatch, getState) => {
    if (validInspection(getState(), inspection)) {
      return dispatch(saveRecord(inspection, { params: { window_id: window.WINDOW_ID } }));
    }
    return Promise.resolve(dispatch(setValidationWarning(KEYS_RECEIVED_ON_WARNING)));
  };
}

export function assignUserGeneric(userId, taskType, taskId) {
  return (dispatch) => {
    const record = {
      id: taskId,
      type: taskType,
      attributes: {
        user_id: userId,
      },
    };

    dispatch(
      saveRecord(record, { params: { window_id: window.WINDOW_ID } }),
    )
      .then(() => {
        toastr.success('Service successfully assigned');
      })
      .catch(() => {
        toastr.error('Service could not be assigned');
      });
  };
}

export function assignUser(userId, projectService) {
  return (dispatch) => {
    const record = Object.assign({}, projectService);
    record.attributes.user_id = userId;

    dispatch(
      saveRecord(record, { params: { window_id: window.WINDOW_ID } }),
    )
      .then(() => {
        toastr.success('Project Service successfully assigned');
      })
      .catch(() => {
        toastr.error('Project Service could not be assigned');
      });
  };
}

function reloadProject(projectId) {
  return dispatch => dispatch(fetchProject(projectId)).then(() => (
    dispatch(fetchProjectServices(projectId))
  )).then(() => (
    dispatch(initializeActiveTask('projects', projectId))
  )).then(() => (
    dispatch(fetchMessengers('projects', projectId))
  ));
}

export function activateProject(projectId) {
  return (dispatch) => {
    dispatch({
      type: SAVE_RECORD,
      payload: {
        request: {
          method: 'patch',
          url: `/projects/${projectId}/activate?include=project_services`,
        },
      },
    })
      .then((resp) => {
        if (resp.error) {
          toastr.error('There was a problem activating the Project.');
        } else {
          toastr.success('Project activated!');
          dispatch({ type: ACTIVATE_PROJECT });
        }
      });
  };
}

export function approveCompletionProject(projectId) {
  return dispatch => dispatch({
    type: SAVE_RECORD,
    payload: {
      request: {
        method: 'patch',
        url: `/projects/${projectId}/approve_completion`,
      },
    },
  })
    .then((resp) => {
      if (resp.error) {
        toastr.error('There was a problem approving completion of the Project.');
      } else {
        toastr.success('Project Completion Approved!');
      }

      return resp;
    });
}

export function sendProjectServiceCompletionReports(projectId, data) {
  return {
    type: SEND_COMPLETION_REPORT,
    payload: {
      request: {
        method: 'post',
        url: `/projects/${projectId}/completion_reports`,
        data,
      },
    },
  };
}

export function fetchUserOptions(serviceId) {
  return {
    type: FETCH_USER_OPTIONS,
    payload: {
      request: {
        method: 'get',
        url: `/services/${serviceId}/user_options`,
      },
    },
  };
}

export function includeData(data) {
  return {
    type: FETCH_RECORDS_SUCCESS,
    payload: { data },
  };
}

export function deleteFeature(feature) {
  const projectId = feature.relationships.project.data.id;

  return async (dispatch, getState) => {
    const task = getRelationship(getState().Api, feature.relationships.project_service);
    const resp = await dispatch(deleteRecord(feature));

    if (resp.error) {
      toastr.error('The Service could not be deleted.');
    } else {
      toastr.success('The Service was successfully deleted');
    }

    if (resp.error) return resp;

    dispatch(fetchProject(projectId));

    if (task.relationships.features.data.length > 1) {
      return dispatch(fetchRecord(task.type, task.id));
    }

    // reload all Tasks because we just removed the last Feature of a Task
    return dispatch(fetchProjectServices(projectId))
      .then((values) => {
        const resp = values[1];
        const taskId = get(resp, 'payload.data.data[0].id', null);
        return taskId
          ? dispatch(setActiveTask({
            activeTaskType: 'project_services',
            activeTaskId: taskId,
            activePlanType: 'project',
            activePlanId: projectId,
          }))
          : Promise.resolve({});
      });
  };
}

export function splitFeature(values, feature) {
  let taskId;
  const projectId = get(feature, 'relationships.project.data.id', null);
  return dispatch => dispatch(saveRecord({
    type: 'features',
    attributes: {
      description: values.description,
      condition: values.condition,
      service_id: values.service_id,
      name: values.name,
      area: values.area,
      item_id: values.itemId,
      project_id: projectId,
      selected_image_ids: values.selectedImages,
      due_date: feature.attributes.due_date,
      original_feature_id: feature.id,
    },
  }, {
    params: {
      include: [
        'room', 'project_service', 'project', 'service', 'project_service',
      ].join(','),
      window_id: window.WINDOW_ID,
    },
  }))
    .then((resp) => {
      if (resp.error) {
        return resp;
      }

      taskId = get(resp, 'payload.data.data.relationships.project_service.data.id', null);

      const promises = [
        dispatch(fetchProjectServices(projectId)),
        dispatch(
          fetchRecord(
            'features',
            feature.id,
            {
              include: [
                'room', 'project_service', 'project', 'service', 'project_service',
              ].join(','),
            },
          ),
        ),
      ];

      return Promise.all(promises);
      // can ignore these fetch errors, if feature or ps has been deleted
    })
    .then(() => (taskId ?
        dispatch(setActiveTask({
          activeTaskType: 'project_services',
          activeTaskId: taskId,
          activePlanType: 'projects',
          activePlanId: projectId,
        }))
        : Promise.resolve({})
    ));
}

export function updateFeatures(features) {
  return async (dispatch) => {
    if (features.length === 0) {
      return undefined;
    }

    const response = await dispatch({
      type: 'intimely/api/UPDATE_FEATURES',
      payload: {
        request: {
          method: 'PATCH',
          url: '/features/bulk_update',
          data: {
            data: features.map(({ id, attributes }) => ({
              id,
              attributes,
            })),
          },
        },
      },
    });
    if (response.error) {
      return response;
    }

    const [feature] = features;
    const projectId = get(feature, 'relationships.project.data.id', null);
    const fetchFeatureOptions = { include: 'room,project_service,project,service' };
    await Promise.all([
      dispatch(fetchProjectServices(projectId)),
      ...features.map(
        ({ id, type }) => dispatch(fetchRecord(type, id, fetchFeatureOptions)),
      ),
    ]);

    const taskId = get(response, 'payload.data.data[0].relationships.project_service.data.id', null);
    if (taskId) {
      return dispatch(setActiveTask({
        activeTaskType: 'project_services',
        activeTaskId: taskId,
        activePlanType: 'projects',
        activePlanId: projectId,
      }));
    }

    return undefined;
  };
}

export function fetchAreaOptions(unitId) {
  return {
    type: FETCH_AREA_OPTIONS,
    payload: {
      request: {
        method: 'get',
        url: `/units/${unitId}/area_options`,
      },
    },
  };
}

export function saveTaskAttachment(attachmentId, taskType, taskId) {
  return dispatch => dispatch(saveRecord({
    type: 'attachments',
    id: attachmentId,
    attributes: {
      task_type: TYPE_MAP[taskType],
      task_id: taskId,
    },
  }, { params: { window_id: window.WINDOW_ID } }));
}

export const setTab = tab => ({
  type: SET_TAB,
  tab,
});

export function markFeatureComplete(feature) {
  return {
    type: SAVE_RECORD,
    payload: {
      request: {
        method: 'PATCH',
        url: `/${feature.type}/${feature.id}/complete`,
      },
    },
  };
}

export function markFeaturePending(feature) {
  return {
    type: SAVE_RECORD,
    payload: {
      request: {
        method: 'PATCH',
        url: `/${feature.type}/${feature.id}/wait?include=${PARENT_TYPE_MAP[feature.type]}`,
      },
    },
  };
}

export const deleteProjectService = projectServiceId => (
  (dispatch, getState) => {
    const oldIds = getState().ProjectManagement.projectServiceIds;
    const newIds = oldIds.filter(id => id !== projectServiceId);

    dispatch(setProjectServiceIds(newIds));
    dispatch(deleteRecord({ type: 'project_services', id: projectServiceId })).then(() => (
      dispatch(fetchProjectServices(getState().ProjectManagement.projectId))
    ));
  }
);

export const updateProjectServices = attrsList => async (dispatch, getState) => {
  const { projectId } = getState().ProjectManagement;
  await dispatch(saveRecord({
    type: 'projects',
    id: projectId,
    attributes: {
      project_services_attributes: attrsList,
    },
  }));
  return dispatch(fetchProjectServices(projectId));
};
