import React from 'react';
import PropTypes from 'prop-types';
import COLORS from '../../helpers/colors';
import withTagging from './withTagging';
import { getHtmlBody } from './helpers';

const SystemMessage = ({ textSections }) => (
  <div className="my-3" style={{ color: COLORS.grey, fontSize: '14px' }}>
    {getHtmlBody(textSections)}
  </div>
);

SystemMessage.propTypes = {
  textSections: PropTypes.array.isRequired,
};

export default withTagging(SystemMessage);
