import React from 'react';
import PropTypes from 'prop-types';
import { humanize } from '../../../helpers/string';
import ValidationIcon from '../../WorkOrderImportContainer/WorkOrderReview/ValidationIcon';

const ErrorTable = ({ errors }) => (
  <table
    className="table"
    style={{
      tableLayout: 'fixed',
      width: '100%',
    }}
  >
    <thead>
      <tr>
        <th>Line Number</th>
        <th>Error Type</th>
        <th>Error details</th>
      </tr>
    </thead>
    <tbody>
      {errors.map((error, index) => (
        <tr key={index} className="v-middle">
          <td>
            {error.line_number ? (
              <div className="d-flex">
                <ValidationIcon valid={false} />
                <div className="my-auto">
                  {`Line ${error.line_number}`}
                </div>
              </div>
            ) : null}
          </td>
          <td>
            {error.message}
          </td>
          <td>
            {Object.keys(error.errors || {})
              .map(key => (
                <div>
                      <span className="font-weight-bold">
                        {humanize(key)}
                        :&nbsp;
                      </span>
                  {(error.errors || {})[key]}
                </div>
              ))}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

ErrorTable.propTypes = {
  errors: PropTypes.array.isRequired,
};

export default ErrorTable;
