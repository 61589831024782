import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchProjects } from '../../redux/modules/projects';
import Loader from '../../components/Loader';
import CommunityCard from './CommunityCard';
import CreatePlanCard from '../ChatsContainer/CreatePlanCard';
import { nameSort } from '../../helpers/sorts';

class ProjectsContainer extends Component {
  static propTypes = {
    fetchProjects: PropTypes.func.isRequired,
    communityIdMap: PropTypes.object.isRequired,
    communities: PropTypes.object.isRequired,
  };

  state = {
    loading: true,
  };

  componentDidMount() {
    const { fetchProjects } = this.props;

    fetchProjects()
      .then(() => this.setState({ loading: false }));
  }

  render() {
    const { loading } = this.state;
    const { communityIdMap, communities } = this.props;

    if (loading) {
      return <Loader />;
    }

    if (Object.keys(communityIdMap).length === 0) {
      return <CreatePlanCard />;
    }

    // sort communities by name
    const communityIds = Object.keys(communityIdMap);
    const sortedCommunityIdMap = Object.values(communities)
      .filter(c => communityIds.includes(c.id))
      .sort(nameSort)
      .map(c => c.id);

    return sortedCommunityIdMap.map(cid => (
      <CommunityCard
        communityId={cid}
        key={cid}
      />
    ));
  }
}

const mapStateToProps = state => ({
  communityIdMap: state.Projects.communityIdMap || {},
  communities: state.Api.communities || {},
});

const mapDispatchToProps = {
  fetchProjects,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsContainer);
