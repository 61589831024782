import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { setPlan } from '../../../redux/modules/teamSchedule';
import ListGroupItem from '../ScheduleSelector/ListGroupItem';
import { options } from '../../../helpers/propTypes';
import Badge from '../../../components/Badge';
import { idComparison } from '../../../helpers/string';

const PlanFilter = ({ title, activePlan, setPlan, visible, planOptions }) => (
  visible ? (
    <span className="ml-2">
      <div className="dropdown">
        <button
          className="btn btn-primary toggle-button"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {title}
          <i className="fa fa-chevron-down spaced-icon" />
        </button>
        <div className="dropdown-menu scrollable-menu" aria-labelledby="dropdownMenuButton">
          {planOptions.map(plan => (
            <ListGroupItem
              selected={activePlan && activePlan.id === plan.id}
              onClick={() => setPlan(plan)}
              key={plan.id}
            >
              <div>
                {plan.label}
                {plan.badge ? <span className="ml-2"><Badge {...plan.badge} /></span> : null}
              </div>
            </ListGroupItem>
          ))}
        </div>
      </div>
    </span>
  ) : null
);

PlanFilter.propTypes = {
  title: PropTypes.string,
  activePlan: PropTypes.object,
  setPlan: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  planOptions: options,
};

PlanFilter.defaultProps = {
  activePlan: null,
  planOptions: [],
  title: null,
};

const mapStateToProps = (state) => {
  const {
    activePlan,
    activeCommunity,
    projectOptions,
    workOrderOptions,
  } = state.TeamSchedule;
  if (!activeCommunity) return { visible: false, unitOptions: [] };

  const { planType } = state.TeamSchedule;
  const planOptions = planType === 'work_orders' ? workOrderOptions : projectOptions;

  const communityOptions = planOptions.find(community => idComparison(activeCommunity.id, community.id));
  const options = communityOptions ? communityOptions.options : [];

  return {
    title: get(activePlan, 'label', 'All Units'),
    activePlan,
    planOptions: options,
    visible: planOptions.length !== 0,
  };
};

const mapDispatchToProps = {
  setPlan,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanFilter);
