import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import COLORS from '../../helpers/colors';
import { idType } from '../../helpers/propTypes';
import AlarmBell from '../../components/Animations/AlarmBell';
import RipplesGreen from '../../components/Animations/RipplesGreen';

class GenericUnreadCount extends PureComponent {
  render() {
    const {
      unreadCount,
      unreadContent,
      id,
      objectType,
      active,
    } = this.props;

    if (active) {
      return (
        <i
          id={`chevron-${id}`}
          className="fa fa-chevron-right"
          style={{
            color: COLORS.blue,
          }}
        />
      );
    }

    if (unreadCount > 0) {
      return (
        <div>
          <AlarmBell />
        </div>
      );
    }

    if (unreadContent) {
      return (
        <div
          id={`${objectType}-unread-content-${id}`}
        >
          <RipplesGreen />
        </div>
      );
    }

    return null;
  }
}

GenericUnreadCount.propTypes = {
  unreadCount: PropTypes.number,
  unreadContent: PropTypes.bool,
  objectType: PropTypes.string.isRequired,
  id: idType,
  active: PropTypes.bool.isRequired,
};

GenericUnreadCount.defaultProps = {
  unreadCount: 0,
  unreadContent: false,
  id: null,
};

export default GenericUnreadCount;
