import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { getRecord, getRelationship } from 'redux-json-api-module';
import get from 'lodash.get';
import Badge from '../../components/Badge';
import Avatar from '../../components/Avatar';

const ProjectServiceRow = ({
  unit,
  user,
  statusBadge,
  planId,
  completedAt,
  scheduleString,
  progress,
  lateBadge,
  partsBadge,
  conditionBadge,
}) => (
  <tr className="v-middle">
    <td>
      <a href={`/projects/${planId}`}>
        <div>
          {unit.attributes.street_address}
          <br />
          {unit.attributes.unit_number}
        </div>
      </a>
    </td>
    <td>
      {user ? (
        <>
          <Avatar {...user.attributes} />
          <span>
            &nbsp;
            {user.attributes.name}
          </span>
        </>
      ) : 'Pending Assignment'}
    </td>
    <td>
      <Badge {...statusBadge} />
      {lateBadge ? <Badge {...lateBadge} className="ml-1" /> : null}
      {partsBadge ? <Badge {...partsBadge} className="ml-1" /> : null}
    </td>
    <td>
      {conditionBadge ? <Badge {...conditionBadge} className="ml-1" /> : null}
    </td>
    <td>
      {scheduleString}
    </td>
    <td>
      {completedAt || `${progress}%`}
    </td>
  </tr>
);

const mapStateToProps = (state, ownProps) => {
  const task = getRecord(state.Api, {
    type: 'project_service_listings',
    id: ownProps.task.id,
  });
  const planId = get(task, 'relationships.project.data.id', null);
  const unit = getRelationship(state.Api, task.relationships.unit);
  const user = getRelationship(state.Api, task.relationships.user);
  const statusBadge = get(task, 'attributes.service_status.private', null);
  const scheduleString = get(task, 'attributes.schedule_string', null);
  const completedAt = moment(get(task, 'attributes.completed_at', null));
  const progress = get(task, 'attributes.progress', 0);

  return {
    task,
    unit,
    user,
    statusBadge,
    scheduleString,
    planId,
    progress,
    completedAt: completedAt.isValid() ? completedAt.format('MM/DD') : null,
    lateBadge: get(task, 'attributes.late_badge', null),
    partsBadge: get(task, 'attributes.parts_badge', null),
    conditionBadge: get(task, 'attributes.condition_badge', null),
  };
};

ProjectServiceRow.propTypes = {
  task: PropTypes.object.isRequired,
  unit: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  statusBadge: PropTypes.object,
  scheduleString: PropTypes.string.isRequired,
  completedAt: PropTypes.string,
  progress: PropTypes.number.isRequired,
};

ProjectServiceRow.defaultProps = {
  statusBadge: null,
  completedAt: null,
};

export default connect(mapStateToProps)(ProjectServiceRow);
