import { connect } from 'react-redux';
import get from 'lodash.get';
import { getRecord } from 'redux-json-api-module';
import { taskSort } from '../../../helpers/sorts';
import { updateProjectServices } from '../../../redux/modules/projectManagement';
import ProjectServiceRow from './ProjectServiceRow';
import ServiceAssignmentTable
  from '../../../components/ServiceAssignmentTable/ServiceAssignmentTable';
import ProjectServiceTableHeaderComponent from './ProjectServiceTableHeaderComponent';

const mapStateToProps = (state, ownProps) => {
  const sortedTasks = (state.ProjectManagement.projectServiceIds || [])
    .map(id => getRecord(state.Api, { type: 'project_services', id }))
    .filter(ps => ps)
    .sort(taskSort);
  const sortedTaskIds = sortedTasks.map(n => n.id);
  const sortedTaskSorts = sortedTasks.map(n => n.attributes.sort);
  const startedCount = sortedTasks.filter(n => n.attributes.started_at).length;

  const project = getRecord(state.Api, {
    type: 'projects',
    id: ownProps.projectId,
  });
  const aasmState = get(project, 'attributes.aasm_state', null);

  return {
    sortedTaskIds,
    sortedTaskSorts,
    startedCount,
    taskType: 'project_services',
    aasmState,
    planId: ownProps.projectId,
    loaded: state.ProjectManagement.projectServicesLoaded,
    ServiceRowComponent: ProjectServiceRow,
    TableHeaderComponent: ProjectServiceTableHeaderComponent,
  };
};

const mapDispatchToProps = {
  updateTasks: updateProjectServices,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceAssignmentTable);
