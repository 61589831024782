const validate = (values) => {
  const errors = {};

  if (values.labor_type === 'hourly_rate') {
    const hours = parseInt(values.hours);
    const minutes = parseInt(values.minutes);
    const hourlyRate = parseFloat(values.hourly_rate);

    if ((Number.isNaN(hours) || hours <= 0) && (Number.isNaN(minutes) || minutes <= 0)) {
      errors.minutes = 'Total time must be greater than 0';
    }

    if (Number.isNaN(hourlyRate) || hourlyRate <= 0) {
      errors.hourly_rate = 'Must be greater than 0';
    }
  } else {
    const totalLaborCost = parseFloat(values.total_labor_cost);

    if (Number.isNaN(totalLaborCost) || totalLaborCost <= 0) {
      errors.total_labor_cost = 'Must be greater than 0';
    }
  }

  return errors;
};

export default validate;
