import React from 'react';

const TableHeader = ({ planAasmState }) => (
  <tr>
    <th>Service</th>
    <th>Assigned To</th>
    <th>Schedule</th>
    {planAasmState !== 'assign' ? <th>Status</th> : null}
    {planAasmState !== 'assign' ? <th>Done</th> : null}
    {planAasmState === 'assign' ? <th>Delete</th> : null}
  </tr>
);

export default TableHeader;
