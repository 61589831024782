import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TITLE_MAP } from '../../../helpers/types';
import { setPlanType } from '../../../redux/modules/teamSchedule';
import ListGroupItem from '../ScheduleSelector/ListGroupItem';

const PlanTypeFilter = ({ title, planType, setPlanType }) => (
  <div className="dropdown">
    <button
      className="btn btn-primary toggle-button"
      type="button"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {title}
      <i className="fa fa-chevron-down spaced-icon" />
    </button>
    <div className="dropdown-menu scrollable-menu" aria-labelledby="dropdownMenuButton">
      <ListGroupItem selected={planType === 'all'} onClick={() => setPlanType('all')} key="all">
        {TITLE_MAP.all}
      </ListGroupItem>
      <ListGroupItem selected={planType === 'projects'} onClick={() => setPlanType('projects')} key="projects">
        {TITLE_MAP.projects}
      </ListGroupItem>
      <ListGroupItem selected={planType === 'work_orders'} onClick={() => setPlanType('work_orders')} key="work_orders">
        {TITLE_MAP.work_orders}
      </ListGroupItem>
    </div>
  </div>
);

PlanTypeFilter.propTypes = {
  title: PropTypes.string.isRequired,
  planType: PropTypes.string,
  setPlanType: PropTypes.func.isRequired,
};

PlanTypeFilter.defaultProps = {
  planType: null,
};

const mapStateToProps = (state) => {
  const { planType } = state.TeamSchedule;

  const title = planType ? TITLE_MAP[planType] : "Make Ready's";

  return {
    title,
    planType,
  };
};

const mapDispatchToProps = {
  setPlanType,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanTypeFilter);
