import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { getRecord } from 'redux-json-api-module';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import {
  setCreatingChannel,
  saveChannel,
  setEditingChannel,
  deleteChannel, toggleArchiveModal,
} from '../../redux/modules/chat';
import COLORS from '../../helpers/colors';
import PrefixedTextInput from './PrefixedTextInput';
import SwitchInput from './SwitchInput';
import TeamMemberSelect from './TeamMemberSelect';
import { idType } from '../../helpers/propTypes';
import ArchiveModal from './ArchiveModal';

function getInitialValues(channel, currentUserId) {
  if (!channel) {
    return {
      public: true,
      [`user_${currentUserId}`]: true,
    };
  }

  const attributes = {
    name: channel.attributes.name,
    public: channel.attributes.public,
  };

  const { user_ids: userIds } = channel.attributes;
  userIds.forEach((userId) => {
    attributes[`user_${userId}`] = true;
  });

  // default select current user
  if (userIds.length === 0) {
    attributes[`user_${currentUserId}`] = true;
  }

  return attributes;
}

const CreateChannelModal = (
  {
    onSubmit, visible,
    setCreatingChannel, channel,
    setEditingChannel, userId,
    deleteChannel, isAdmin,
    toggleArchiveModal, archiveModalVisible
  },
) => (
  <Modal
    show={visible}
    onHide={() => {
      channel ? setEditingChannel(null) : setCreatingChannel(false);
    }}
    size="lg"
  >
    <Form
      initialValues={getInitialValues(channel, userId)}
      onSubmit={onSubmit}
      keepDirtyOnReinitialize
      render={({ handleSubmit, values }) => (
        <div className="modal-body">
          <div className="p-2 m-2" style={{ border: `1px solid ${COLORS.greyBorder}` }}>
            <form onSubmit={handleSubmit} id="new-channel">
              <h4 className="text-center my-3 font-weight-bold">
                {channel ? 'Save Channel' : 'Create Channel'}
              </h4>
              <p className="text-center mb-1">
                A public channel can be used by your whole team.
              </p>
              <p className="text-center mb-3">
                Turn this off to make a private channel with invited members.
              </p>
              <h4 className="text-center mb-3">Channel Name</h4>
              <div style={{ width: '75%' }} className="mx-auto">
                <Field
                  component={PrefixedTextInput}
                  name="name"
                />
              </div>

              <div className="row my-3">
                <div className="col justify-content-end my-auto" style={{ display: 'flex' }}>
                  <h4 className="mb-0">{values.public ? 'Public Channel' : 'Private Channel'}</h4>
                </div>
                <div className="col my-auto">
                  <Field
                    component={SwitchInput}
                    name="public"
                  />
                </div>
              </div>

              <p className="text-center mb-4">
                A public channel can be used by your whole team. Turn this off to make a private
                channel with invited members.
              </p>

              {values.public ? null : (
                <TeamMemberSelect currentUserId={userId} isCreating={!channel} />
              )}

              <div className="justify-content-center my-3" style={{ display: 'flex' }}>
                <Button
                  variant="info"
                  type="submit"
                  disabled={!values.name}
                >
                  {channel ? 'Save Channel' : 'Create Channel'}
                </Button>
                {channel && isAdmin ? (
                  <Button
                    variant="link"
                    type="button"
                    style={{ color: COLORS.black }}
                    onClick={() => deleteChannel(channel.id)}
                  >
                    Delete Channel
                  </Button>
                ) : null}
                <Button
                  variant="link"
                  type="button"
                  className="position-absolute"
                  style={{ color: COLORS.grey, right: '30px' }}
                  onClick={() => toggleArchiveModal(true)}
                >
                  Restore Channel
                </Button>
                {archiveModalVisible ? (
                  <ArchiveModal />
                ) : null}
              </div>
            </form>
          </div>
        </div>
      )}
    />
  </Modal>
);

CreateChannelModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  setCreatingChannel: PropTypes.func.isRequired,
  channel: PropTypes.object,
  setEditingChannel: PropTypes.func.isRequired,
  userId: idType.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  deleteChannel: PropTypes.func.isRequired,
};

CreateChannelModal.defaultProps = {
  channel: null,
};

const mapStateToProps = (state) => {
  const channel = getRecord(state.Api, { type: 'chat_channels', id: state.Chat.editingChannelId });

  return {
    visible: state.Chat.creatingChannel || channel,
    channel,
    archiveModalVisible: state.Chat.archiveModalVisible,
  };
};

const mapDispatchToProps = {
  setCreatingChannel,
  setEditingChannel,
  onSubmit: saveChannel,
  deleteChannel,
  toggleArchiveModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateChannelModal);
