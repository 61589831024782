import { connect } from 'react-redux';
import get from 'lodash.get';
import { getMessenger } from '../../redux/modules/chat';
import GenericUnreadCount from './GenericUnreadCount';

const mapStateToProps = (state, ownProps) => {
  const messenger = getMessenger(state, ownProps.messageThreadId, ownProps.userId);

  return {
    messengerId: get(messenger, 'id'),
    unreadCount: get(messenger, 'attributes.unread_count', 0),
    unreadContent: get(messenger, 'attributes.unread_content', false),
    id: messenger && messenger.id,
    objectType: 'plans',
  };
};

export default connect(mapStateToProps)(GenericUnreadCount);
