import React from 'react';
import PropTypes from 'prop-types';
import greenCloud from '../../../../assets/images/icon/green_cloud.png';
import redCloud from '../../../../assets/images/icon/red_cloud.png';

const ValidationIcon = ({ valid }) => (
  <img
    src={valid ? greenCloud : redCloud}
    alt={valid ? 'Success' : 'Error'}
    style={{ width: '45px', height: '45px' }}
  />
);

ValidationIcon.propTypes = {
  valid: PropTypes.bool.isRequired,
};

export default ValidationIcon;
