import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord } from 'redux-json-api-module';
import SchedulerTitle from '../../../components/GenericScheduler/SchedulerTitle';
import MaintenanceSelect from '../../../components/MaintenanceSelect';
import ViewToggle from '../../TeamScheduleContainer/ViewToggle';

const styles = {
  flexWrapper: {
    marginBottom: '20px',
    flex: 1,
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  flexCol: {
    flexDirection: 'column',
  },
  titleWrapper: {
    flex: 1,
  },
  viewToggleWrapper: {
    height: '30px',
  },
};

const Title = ({
  scheduled,
  user,
  handleAssignment,
}) => (
  scheduled ? (
    <div className="d-flex scheduler-header">
      <div style={styles.titleWrapper}>
        <h4 className="modal-title font-weight-bold">
          <SchedulerTitle {...scheduled.attributes} />
        </h4>
      </div>
      <div style={styles.viewToggleWrapper}>
        <ViewToggle />
      </div>
      <div
        className="d-flex"
        style={styles.flexWrapper}
      >
        <div className="d-flex" style={styles.flexCol}>
          <div className="font-weight-bold">
            View Team Member Schedule: &nbsp;
          </div>
          <MaintenanceSelect
            user={user}
            scheduled={scheduled}
            onChange={handleAssignment}
            key={`maintenance-select-${scheduled.id}`}
          />
        </div>
      </div>
    </div>
  ) : null
);

Title.propTypes = {
  scheduled: PropTypes.object,
  user: PropTypes.object,
  handleAssignment: PropTypes.func.isRequired,
};

Title.defaultProps = {
  scheduled: null,
  user: null,
};

const mapStateToProps = (state, ownProps) => {
  const scheduled = getRecord(state.Api, {
    type: ownProps.scheduledType,
    id: ownProps.scheduledId,
  });

  const user = ownProps.userId && getRecord(state.Api, {
    type: 'users',
    id: ownProps.userId,
  });

  return {
    scheduled,
    user,
  };
};

export default connect(mapStateToProps)(Title);
