import { queryString, SAVE_RECORD } from 'redux-json-api-module';

export function saveBatch(records, options = {}) {
  return {
    type: SAVE_RECORD,
    ignoreFail: options.ignoreFail,
    payload: {
      request: {
        method: 'POST',
        url: `/batch?${queryString(options.params || {})}`,
        data: { data: records },
      },
    },
  };
}
