import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CreateChannelButton from './CreateChannelButton';
import ChannelLink from './ChannelLink';
import { idType } from '../../helpers/propTypes';

const Channels = ({
  channelIds,
  userId,
  threadsList,
  onShowUnread,
  onHideUnread,
}) => (
  <div id="channels">
    {channelIds.map(id => (
      <ChannelLink
        channelId={id}
        userId={userId}
        key={id}
        threadsList={threadsList}
        onShowUnread={onShowUnread}
        onHideUnread={onHideUnread}
      />
    ))}
    <CreateChannelButton />
  </div>
);

Channels.propTypes = {
  channelIds: PropTypes.arrayOf(idType),
  userId: idType.isRequired,
};

const mapStateToProps = state => ({
  channelIds: state.Chat.activeChannelIds,
});

export default connect(mapStateToProps)(Channels);
