import React, { Component } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import { fetchRecords, saveRecord } from 'redux-json-api-module';
import get from 'lodash.get';
import EditUnitForm from './EditUnitForm';

class EditUnitContainer extends Component {
  state = {
    errors: {},
    saving: false,
  };

  handleSubmit = (values) => {
    const { saveRecord, unit } = this.props;

    this.setState({ saving: true });

    const communityAttributes = values.community ? {
      community_id: values.community.id,
    } : {
      community_attributes: {
        name: values.community_name,
      },
    };

    const record = {
      type: 'units',
      id: unit.id,
      attributes: {
        state: values.state ? values.state.id : null,
        street_address: values.street_address,
        zip: values.zip,
        city: values.city,
        number: values.number,
        bed_count: values.bed_count,
        bath_count: values.bath_count,
        housing_type: values.housing_type,
        ...communityAttributes,
      },
    }

    saveRecord(record, { params: { window_id: window.WINDOW_ID } })
      .then((resp) => {
        this.setState({ saving: false });

        if (resp.error) {
          toastr.error('Failed to save unit');
          if (resp.error.response.data) {
            this.setState({
              errors: resp.error.response.data.errors,
            });
          }
          return;
        }

        toastr.success('Successfully saved unit!');
        window.location = '/units';
      });
  };

  getStateValue = () => {
    const { unit, stateOptions } = this.props;
    const state = get(unit, 'attributes.state', null);
    const option = stateOptions.find(option => option.value === state);
    return state ? {
      id: state,
      value: state,
      label: option ? option.label : null,
    } : null;
  };

  render() {
    const { stateOptions, communityOptions, unit, community } = this.props;

    const { saving, errors } = this.state;

    const communityValue = community ? {
      id: community.id,
      value: community.id,
      label: get(community, 'attributes.name', null),
    } : null;

    const initialValues = {
      ...unit.attributes,
      community: communityValue,
      state: this.getStateValue(),
    };

    return (
      <EditUnitForm
        initialValues={initialValues}
        errors={errors}
        stateOptions={stateOptions}
        communityOptions={communityOptions}
        unit={unit}
        onSubmit={this.handleSubmit}
        saving={saving}
      />
    );
  }
}

const mapDispatchToProps = {
  fetchRecords,
  saveRecord,
};

const mapStateToProps = (state, ownProps) => ({
  unit: get(ownProps, 'unit.data', null),
  community: get(ownProps, 'community.data', null),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUnitContainer);
