import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'react-bootstrap';
import { Form, Field } from 'react-final-form';
import axios from 'axios';
import toastr from 'toastr';
import { genericRecordPropType } from '../helpers/propTypes';

const BillingEmailEditor = ({ companyData }) => {
  const [value, setValue] = useState(companyData.data.attributes.billing_email);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const onSubmit = (values) => {
    const record = {
      type: 'companies',
      id: companyData.data.id,
      attributes: values,
    };

    return axios.patch(`/api/v1/companies/${record.id}`, {
      data: record,
    }).then((resp) => {
      if (resp.error) {
        toastr.error('There was a problem updating the email address. Please try again');
        return;
      }

      toastr.success('Billing email address successfully updated.');
      setValue(resp.data.data.attributes.billing_email);
      handleClose();
    });
  };

  return (
    <>
      {value}
      <Button variant="link" onClick={handleShow}>
        <i className="fa fa-pencil" />
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Billing Email</Modal.Title>
        </Modal.Header>
        <Form
          onSubmit={onSubmit}
          initialValues={{ billing_email: value }}
          render={({ handleSubmit, form, submitting, pristine }) => (
            <form onSubmit={handleSubmit}>
              <Modal.Body>
                <div className="form-group">
                  <label htmlFor="firstName">Billing Email</label>
                  <Field
                    name="billing_email"
                    component="input"
                    type="text"
                    placeholder="admin@example.com"
                    className="form-control"
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  type="submit"
                  variant="primary"
                  disabled={submitting || pristine}
                >
                  Submit
                </Button>
                <Button
                  variant="secondary"
                  type="button"
                  onClick={() => {
                    form.reset();
                    handleClose();
                  }}
                  disabled={submitting || pristine}
                >
                  Close
                </Button>
              </Modal.Footer>
            </form>
          )}
        />
      </Modal>
    </>
  );
};

BillingEmailEditor.propTypes = {
  companyData: PropTypes.shape({
    data: genericRecordPropType,
  }).isRequired,
};

export default BillingEmailEditor;
