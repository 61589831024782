import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  setView,
  SCHEDULE_VIEW_CALENDAR,
  SCHEDULE_VIEW_GANTT,
} from '../../redux/modules/teamSchedule';
import GanttActiveIcon from '../../../assets/images/icon/gantt-active.png';
import GanttInactiveIcon from '../../../assets/images/icon/gantt-inactive.png';


const ViewToggle = ({ view, setView }) => (
  <div className="btn-group btn-group-lg" role="group" aria-label="Basic example">
    <button
      id="schedule-view-btn"
      type="button"
      className="btn btn-secondary"
      onClick={() => setView(SCHEDULE_VIEW_CALENDAR)}
      disabled={view === SCHEDULE_VIEW_CALENDAR}
    >
      <i className="fa fa-calendar" />
    </button>
    <button
      id="gantt-view-btn"
      type="button"
      className="btn btn-secondary"
      style={{ width: '50px' }}
      onClick={() => setView(SCHEDULE_VIEW_GANTT)}
      disabled={view === SCHEDULE_VIEW_GANTT}
    >
      <img src={view === SCHEDULE_VIEW_GANTT ? GanttActiveIcon : GanttInactiveIcon} style={{ width: '24px', marginLeft: '-3px' }} />
    </button>
  </div>
);

ViewToggle.propTpyes = {
  view: PropTypes.string.isRequired,
  setView: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  view: state.TeamSchedule.view || SCHEDULE_VIEW_GANTT,
});

const mapDispatchToProps = {
  setView,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ViewToggle);
