import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteRecord, getRelationship, fetchRecord, fetchRecords } from 'redux-json-api-module';
import BootstrapTable from 'react-bootstrap-table-next';
import Badge from '../../../components/Badge';
import Loader from '../../../components/Loader';
import {
  ID_MAP,
  IMAGE_ATTRIBUTE_MAP,
  IMAGE_MAP,
  PARENT_TYPE_MAP,
  TYPE_MAP,
} from '../../../helpers/types';
import { includeData as includeDataForProject } from '../../../redux/modules/projectManagement';
import { includeData as includeDataForWorkOrder } from '../../../redux/modules/workOrderManagement';
import TaskAttachmentManager from '../../ProjectManagementContainer/AttachmentManager/TaskAttachmentManager';
import DownloadButton from './DownloadButton';
import InvoiceButton from './InvoiceButton';
import PlanTypeFilter from './PlanTypeFilter';

const COLUMNS = [
  {
    dataField: 'attributes.short_address',
    text: 'Address',
    sort: true,
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'serviceBadgeToShow',
    text: 'Service',
    formatter: badge => (badge ? <Badge {...badge} /> : null),
    sort: true,
    sortValue: badge => (badge ? badge.label : null),
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'serviceInvoice',
    text: 'Invoice',
    formatter: (serviceInvoice, task) => (
      <InvoiceButton task={task} serviceInvoice={serviceInvoice} />
    ),
    sort: true,
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'taskImages',
    text: 'Attach',
    formatter: (_taskImages, task) => (
      <TaskAttachmentManager
        taskId={task.id}
        taskType={task.type}
        type={IMAGE_MAP[task.type]}
        parentRelationship={ID_MAP[task.type]}
        planClass={TYPE_MAP[task.plan.type]}
        plan={task.plan}
        onDelete={task.deleteRecord}
        onCreate={(data) => {
          task.includeDataMap[task.type](data);
          task.fetchRecord(task.type, task.id);
        }}
        nestedUpload
      />
    ),
    sort: true,
    sortValue: taskImages => taskImages.length,
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'isDownloadable',
    text: 'Download',
    formatter: (isDownloadable, task) => <DownloadButton task={task} disabled={!isDownloadable} />,
    sort: true,
    style: { verticalAlign: 'middle' },
  },
];

const defaultSorted = [
  {
    dataField: 'name',
    order: 'asc',
  },
];

const NoDataIndication = () => <div className="text-center">No Invoices</div>;

const InvoicesTab = ({
  selectedServiceIds,
  tasks,
  deleteRecord,
  includeDataForProject,
  includeDataForWorkOrder,
  fetchRecord,
  fetchRecords,
}) => {
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);

  let data = tasks;
  if (filter) {
    data = data.filter(({ type }) => type === filter);
  }

  const isServiceShown = (task) => {
    if (selectedServiceIds.length === 0) return true;

    const { data } = task.relationships.service;
    return data ? selectedServiceIds.includes(data.id) : false;
  };
  const includeDataMap = {
    project_services: includeDataForProject,
    work_order_tasks: includeDataForWorkOrder,
  };
  data = data.map(task => ({
    ...task,
    isDownloadable: Boolean(task.serviceInvoice || task.taskImages.length),
    serviceBadgeToShow: isServiceShown(task) ? task.attributes.service_badge : null,
    // functions
    deleteRecord,
    includeDataMap,
    fetchRecord,
  }));

  useEffect(() => {
    setLoading(true);

    Promise.all([
      fetchRecords('project_services', { include: 'service_invoice,project' }),
      fetchRecords('work_order_tasks', { include: 'service_invoice,work_order' }),
    ]).finally(() => setLoading(false));
  }, []);

  if (loading) return <Loader />;

  return (
    <div>
      <PlanTypeFilter value={filter} onChange={setFilter} className="my-1" />
      <BootstrapTable
        keyField="id"
        data={data}
        columns={COLUMNS}
        defaultSorted={defaultSorted}
        bootstrap4
        noDataIndication={NoDataIndication}
      />
    </div>
  );
};

InvoicesTab.propTypes = {
  selectedServiceIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  // connected
  // eslint-disable-next-line react/forbid-prop-types
  tasks: PropTypes.array.isRequired,
  deleteRecord: PropTypes.func.isRequired,
  fetchRecord: PropTypes.func.isRequired,
  fetchRecords: PropTypes.func.isRequired,
  includeDataForProject: PropTypes.func.isRequired,
  includeDataForWorkOrder: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  tasks: ['project_services', 'work_order_tasks']
    .flatMap(name => Object.values(state.Api[name] || {}))
    .map(task => ({
      ...task,
      plan: getRelationship(state.Api, task.relationships[PARENT_TYPE_MAP[task.type]]),
      serviceInvoice: getRelationship(state.Api, task.relationships.service_invoice),
      taskImages: task.attributes[IMAGE_ATTRIBUTE_MAP[task.type]],
    }))
    // FIXME: after attaching a file, a new work order task appears in the list
    // but its plan could not be found (null), work around by filtering it out
    // for now.
    .filter(task => task.plan),
});

const mapDispatchToProps = {
  deleteRecord,
  fetchRecord,
  fetchRecords,
  includeDataForProject,
  includeDataForWorkOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesTab);
