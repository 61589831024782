import React, { Fragment, PureComponent } from 'react';
import toastr from 'toastr';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import { fetchUnits } from '../../redux/modules/units';
import ErrorModal from './ErrorModal';

class UnitUploader extends PureComponent {
  state = {
    visible: false,
    errors: [],
  };

  getFormData = (file) => {
    const formData = new FormData();
    formData.append('data[attributes][file]', file);

    return formData;
  };

  onDrop = (acceptedFiles) => {
    const { fetchUnits } = this.props;

    acceptedFiles.forEach((file) => {
      const formData = this.getFormData(file);

      fetch('/api/v1/units/import', {
        method: 'POST',
        body: formData,
      })
        .then((response) => {
          if (response.status === 200) {
            toastr.success('Units imported successfully!');
            return fetchUnits(true);
          }

          response.json()
            .then((data) => {
              this.setState({
                visible: true,
                errors: Array.isArray(data) ? data : [],
              });
            });
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  stopPropagation = e => e.stopPropagation();

  closeModal = () => this.setState({ visible: false });

  render() {
    const { visible, errors } = this.state;

    return (
      <Fragment>
        <Dropzone onDrop={this.onDrop}>
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()} style={{ marginRight: '1rem' }}>
              <input name="data[attributes][file]" {...getInputProps()} />

              <div className="btn-group" role="group">
                <a
                  className="btn btn-secondary"
                  href="/intimely_unit_import_sample_data.xlsx"
                  target="_blank"
                  rel="noopener nofollow"
                  onClick={this.stopPropagation}
                >
                  Download Sample Excel Sheet
                </a>
                <button className="btn btn-secondary ml-2" type="button">
                  Import Multiple Units
                </button>
              </div>
            </div>
          )}
        </Dropzone>
        <ErrorModal visible={visible} errors={errors} closeModal={this.closeModal} />
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  fetchUnits,
};

export default connect(null, mapDispatchToProps)(UnitUploader);
