import React from 'react';
import PropTypes from 'prop-types';

const DefaultChildren = ({ disabled, isDragActive }) => (
  <p
    className="dropzone-instructions"
    style={{
      fontSize: 16,
    }}
  >
    {isDragActive ? (
      'Drop files here...'
    ) : (
      <>
        Drag Files Here &nbsp;&nbsp; OR &nbsp;&nbsp;&nbsp;
        <button
          type="button"
          className="btn btn-secondary"
          disabled={disabled}
        >
          Click Here to Choose
        </button>
      </>
    )}
  </p>
);

DefaultChildren.propTypes = {
  disabled: PropTypes.bool,
  isDragActive: PropTypes.bool.isRequired,
};

DefaultChildren.defaultProps = {
  disabled: false,
};

export default DefaultChildren;
