import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PopoverStickOnHover from '../../components/Messenger/PopoverStickOnHover';
import COLORS from '../../helpers/colors';

const clampStyle = {
  display: 'block',
  textOverflow: 'ellipsis',
  wordWrap: 'break-word',
  overflow: 'hidden',
  maxHeight: '3.6em',
  lineHeight: '1.8em',
};

const tooltipStyle = {
  backgroundColor: COLORS.black,
  color: COLORS.white,
  padding: '10px',
};

class ClampedText extends Component {
  static propTypes = {
    placement: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  };

  state = {
    hasOverflowingChildren: true,
  };

  componentDidUpdate() {
    this.checkOverflow();
  }

  componentDidMount() {
    this.checkOverflow();
  }

  checkOverflow = () => {
    const { element } = this;

    if (!element) return;

    const overflowing = element.offsetHeight < element.scrollHeight
      || element.offsetWidth < element.scrollWidth;

    const { hasOverflowingChildren } = this.state;

    if (overflowing !== hasOverflowingChildren) {
      this.setState({
        hasOverflowingChildren: overflowing,
      });
    }
  };

  render() {
    const { text, placement } = this.props;
    const { hasOverflowingChildren } = this.state;

    if (!hasOverflowingChildren) {
      return (
        <div
          style={clampStyle}
          ref={(el) => {
            this.element = el;
          }}
        >
          {text}
        </div>
      );
    }

    return (
      <PopoverStickOnHover
        component={<div style={tooltipStyle}>{text.slice(0, 200)}</div>}
        placement={placement}
      >
        <div>
          <div
            style={clampStyle}
            ref={(el) => {
              this.element = el;
            }}
          >
            {text}
          </div>
          <span style={{
            float: 'right',
            marginTop: '-22px',
          }}
          >
. . .
          </span>
        </div>
      </PopoverStickOnHover>
    );
  }
}

export default ClampedText;
