import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';
import ReportsContainer from '../containers/ReportsContainer';

const Reports = props => (
  <Provider store={store}>
    <ReportsContainer {...props} />
  </Provider>
);

export default Reports;
