import React from 'react';
import PropTypes from 'prop-types';

const Avatar = ({ avatar, color, initials, className }) => {
  const avatarClass = `avatar ${className}`;
  const avatarStyle = { backgroundColor: color };
  const imageClass = `avatar avatar-image ${className}`;
  const imageStyle = { backgroundImage: (avatar && avatar.exists) ? `url(${avatar.thumb.url})` : 'none' };

  return (
    <div className={avatarClass} style={avatarStyle}>
      {initials}
      <div className={imageClass} style={imageStyle}>
        &nbsp;
      </div>
    </div>
  );
};

Avatar.propTypes = {
  avatar: PropTypes.shape({
    exists: PropTypes.bool,
    thumb: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  color: PropTypes.string.isRequired,
  initials: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Avatar.defaultProps = {
  className: 'avatar-sm d-inline-block',
  avatar: null,
};

export default Avatar;
