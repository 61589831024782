import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import ProgressiveLoadingImage from './ProgressiveLoadingImage';

// pure component instead of func for passing this.props
export default class LazyProgressiveImage extends React.PureComponent {
  static propTypes = {
    imageClassName: PropTypes.string,
    imageStyle: PropTypes.object,
    lazyLoadHeight: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    sourceUrl: PropTypes.string.isRequired,
    placeholderUrl: PropTypes.string.isRequired,
    alt: PropTypes.string,
    onClick: PropTypes.func,
    scrollContainer: PropTypes.string,
    overflow: PropTypes.bool,
  };

  static defaultProps = {
    imageClassName: '',
    lazyLoadHeight: 200,
    alt: 'image',
    onClick: () => undefined,
    imageStyle: {},
    scrollContainer: undefined,
    overflow: false,
  };

  render() {
    const { lazyLoadHeight, scrollContainer, overflow } = this.props;

    return (
      <LazyLoad
        height={lazyLoadHeight}
        offset={100}
        scrollContainer={scrollContainer}
        overflow={overflow}
      >
        <ProgressiveLoadingImage {...this.props} />
      </LazyLoad>
    );
  }
}
