import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

const TaskDisplay = ({
  service,
  service_badge: serviceBadge,
  service_name: serviceName,
}) => (
  <div className="task-select__option">
    <span
      className="badge badge-blank"
      style={{
        backgroundColor: get(service, 'attributes.background_color', serviceBadge.background_color),
        color: get(service, 'attributes.text_color', serviceBadge.text_color),
        marginRight: '8px',
      }}
    >
      {' '}
    </span>
    {get(service, 'attributes.name', serviceName)}
  </div>
);

TaskDisplay.propTypes = {
  service: PropTypes.shape({
    attributes: PropTypes.shape({
      name: PropTypes.string,
      background_color: PropTypes.string,
      text_color: PropTypes.string,
    }),
  }).isRequired,
};

export default TaskDisplay;
