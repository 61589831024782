import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';
import ChecklistsContainer from '../containers/ChecklistsContainer';

const Checklists = props => (
  <Provider store={store}>
    <ChecklistsContainer {...props} />
  </Provider>
);

export default Checklists;
