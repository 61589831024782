import React from 'react';

const Title = () => (
  <div style={{
    flex: 1,
    justifySelf: 'center',
    textAlign: 'center',
    marginTop: '10px',
  }}
  >
    <h4 className="font-weight-bold">
      Assign and Schedule Inspection
    </h4>
  </div>
);

export default Title;
