import { getRecord } from 'redux-json-api-module';
import get from 'lodash.get';
import { idComparison } from '../../helpers/string';

export function anyChosen(values) {
  return Object.keys(values).some(key => values[key]);
}

export function chosen(values) {
  const chosenUser = Object.keys(values).find(key => values[key]);
  return chosenUser ? chosenUser.replace('user_', '') : null;
}

export function getOtherDMUser(directMessageChat, userId, api) {
  const receiver = getRecord(api, { type: 'users', id: get(directMessageChat, 'relationships.receiver.data.id', null) });
  const creator = getRecord(api, { type: 'users', id: get(directMessageChat, 'relationships.creator.data.id', null) });

  let name; let
    otherUserId;
  if (creator && receiver) {
    if (idComparison(creator.id, userId)) {
      name = receiver.attributes.name;
      otherUserId = receiver.id;
    } else {
      name = creator.attributes.name;
      otherUserId = creator.id;
    }
  }

  return {
    name,
    otherUserId,
  };
}
