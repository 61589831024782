import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchRecord, getRecord, getRelationship, saveRecord } from 'redux-json-api-module';
import { firstBy } from 'thenby';
import { FEATURE_TYPE_MAP } from '../../helpers/types';
import { genericRecordPropType, idPropType } from '../../helpers/propTypes';
import PurchaseRequestRow from './PurchaseRequestRow';
import './styles.scss';

const PurchaseRequestTable = ({
  taskId, taskType,
  areaMap, saveRecord,
  fetchRecord,
}) => {
  const onSave = (values) => {
    const record = {
      id: values.id,
      type: 'purchase_requests',
      attributes: values,
    };

    return saveRecord(record)
      .then(() => fetchRecord(taskType, taskId, {
        include: 'purchase_requests',
        window_id: window.WINDOW_ID,
      }));
  };

  return (
    <table className="table">
      <thead>
        <tr>
          <th>Area</th>
          <th>Part</th>
          <th width="115px"># Available</th>
          <th width="115px"># Needed</th>
          <th width="170px">Order Date</th>
          <th width="170px">Received Date</th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(areaMap).sort()
          .map(areaName => (
            areaMap[areaName].sort(
              firstBy(i => i.attributes.name),
            ).map(item => (
              <PurchaseRequestRow
                key={item.feature_id}
                areaName={areaName}
                item={item}
                onSave={onSave}
              />
            ))
          ))}
      </tbody>
    </table>
  );
};

PurchaseRequestTable.propTypes = {
  taskType: PropTypes.string.isRequired,
  taskId: idPropType.isRequired,

  // connected
  areaMap: PropTypes.objectOf(
    PropTypes.arrayOf(genericRecordPropType),
  ).isRequired,
  fetchRecord: PropTypes.func.isRequired,
  saveRecord: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const task = getRecord(state.Api, {
    type: ownProps.taskType,
    id: ownProps.taskId,
  });

  // create map of areas and items
  const areaMap = {};
  getRelationship(
    state.Api,
    task.relationships[FEATURE_TYPE_MAP[ownProps.taskType]],
  )
    .forEach((feature) => {
      if (!areaMap[feature.attributes.area]) areaMap[feature.attributes.area] = [];
      const item = getRelationship(state.Api, feature.relationships.item);
      const purchaseRequest = getRelationship(state.Api, feature.relationships.purchase_request);

      if (item) {
        areaMap[feature.attributes.area].push({
          ...item,
          feature_id: feature.id,
          feature_type: feature.type,
          purchase_request_id: purchaseRequest?.id,
        });
      }
    });

  return {
    areaMap,
  };
};

const mapDispatchToProps = {
  fetchRecord,
  saveRecord,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PurchaseRequestTable);
