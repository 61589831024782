import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';
import WorkOrderCreateContainer
  from '../containers/WorkOrderCreateContainer/WorkOrderCreateContainer';

const WorkOrderCreate = props => (
  <Provider store={store}>
    <WorkOrderCreateContainer {...props} />
  </Provider>
);

export default WorkOrderCreate;
