import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { fetchRecords, getRelationship } from 'redux-json-api-module';
import overlayFactory from 'react-bootstrap-table2-overlay';
import isEqual from 'lodash.isequal';
import moment from 'moment';
import PurchaseOrderActions from './PurchaseOrderActions';
import Avatar from '../../../components/Avatar';

const columns = [
  {
    dataField: 'createdAt',
    text: 'Order Date',
    type: 'date',
    align: 'right',
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'quantity',
    text: 'Quantity Added',
    type: 'number',
    align: 'right',
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'price',
    text: 'Price Per Part',
    type: 'number',
    align: 'right',
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'supplier',
    text: 'Supplier',
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'user',
    text: 'Added By',
    align: 'center',
    headerStyle: { width: '200px' },
    style: {
      verticalAlign: 'middle',
      padding: 0,
    },
  },
  {
    dataField: 'attachments',
    text: 'Attachments',
    style: { verticalAlign: 'middle' },
  },
  {
    id: 'delete',
    dataField: 'actions',
    text: '',
    align: 'center',
    headerStyle: { width: '50px' },
    style: {
      verticalAlign: 'middle',
      padding: 0,
    },
  },
];

const defaultSorted = [{
  dataField: 'createdAt',
  order: 'desc',
}];

const NoDataIndication = () => (
  <div className="text-center">No Purchase Orders</div>
);

const InventoryHistoryTable = React.memo(({
  itemId,
  purchaseOrders,
  fetchRecords,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    fetchRecords('purchase_orders', {
      item_id: itemId,
      page: { size: 250 },
    })
      .finally(() => setLoading(false));
  }, []);

  const data = purchaseOrders.map(po => ({
    id: po.id,
    createdAt: moment(po.attributes.created_at)
      .format('MM/DD/YY'),
    quantity: po.attributes.quantity,
    price: po.attributes.price ? (
      parseFloat(po.attributes.price)
        .toFixed(2)
    ) : null,
    supplier: po.attributes.supplier,
    attachments: 'ATTACHMENTS',
    user: po.attributes.user_avatar ? (
      <>
        <Avatar
          {...po.attributes.user_avatar}
          className="avatar-xs d-inline-block mr-1"
        />
        {po.attributes.user_avatar.name}
      </>
    ) : null,
    actions: <PurchaseOrderActions poId={po.id} />,
  }));

  const Overlay = overlayFactory({ spinner: true });

  return (
    <BootstrapTable
      keyField="id"
      data={data}
      columns={columns}
      defaultSorted={defaultSorted}
      loading={loading}
      overlay={Overlay}
      noDataIndication={NoDataIndication}
    />
  );
}, (prevProps, nextProps) => (
  isEqual(prevProps.purchaseOrders, nextProps.purchaseOrders)
));

InventoryHistoryTable.propTypes = {
  itemId: PropTypes.string.isRequired,

  // connected
  purchaseOrders: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchRecords: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const item = state.Api.items[ownProps.itemId];

  return {
    purchaseOrders: getRelationship(state.Api, item.relationships.purchase_orders) || [],
  };
};

const mapDispatchToProps = {
  fetchRecords,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InventoryHistoryTable);
