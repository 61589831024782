import React from 'react';
import PropTypes from 'prop-types';
import { idType } from '../helpers/propTypes';
import Alert from './Alert';

const ActivateButton = ({ planId, title, active, activate }) => (
  <React.Fragment>
    {active && (
      <Alert
        type="info"
        iconClassName="fa fa-exclamation-circle"
        title={`"Please Activate ${title}"`}
      >
        Please click “Activate” below to send work notifications to the Service provider(s).
      </Alert>
    )}
    <button
      type="button"
      className="btn btn-primary btn-lg"
      disabled={!active}
      onClick={() => activate(planId)}
    >
      Activate
    </button>
  </React.Fragment>
);

ActivateButton.propTypes = {
  active: PropTypes.bool.isRequired,
  activate: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  planId: idType.isRequired,
};

export default ActivateButton;
