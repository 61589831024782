import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import toastr from 'toastr';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { getRelationship } from 'redux-json-api-module';
import {
  allViews,
  decorateEvent,
  eventPropGetter,
  sortEvents,
} from '../containers/ProjectManagementContainer/Scheduler/helpers';
import GenericScheduler from './GenericScheduler';

import { changeUser, loadEvents } from '../redux/modules/scheduler';

import '../containers/ProjectManagementContainer/Scheduler/Scheduler.scss';
import { getUpdatedDates } from './GenericScheduler/helpers';
import Title from '../containers/ProjectCreateContainer/ProjectCreateForm/Title';

class NewInspectionScheduler extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    additionalEvents: PropTypes.arrayOf(PropTypes.object),
    calendarVisible: PropTypes.bool,
    changeUser: PropTypes.func.isRequired,
    dueDate: PropTypes.any,
    deadline: PropTypes.any,
    keysReceivedOn: PropTypes.any,
    loadEvents: PropTypes.func.isRequired,
  };

  static defaultProps = {
    additionalEvents: [],
    calendarVisible: false,
    dueDate: null,
    deadline: null,
    keysReceivedOn: null,
  };

  state = {
    scrolled: false,
    formVisible: false,
  };

  componentWillReceiveProps(nextProps) {
    const { calendarVisible } = this.props;

    // reset scrolled flag when calendar is hidden
    if (nextProps.calendarVisible !== calendarVisible) {
      this.setState({ scrolled: false });
    }
  }

  componentDidUpdate() {
    const { scrolled } = this.state;

    // scroll the current day into view
    if (!scrolled && $('.rbc-now')[0]) {
      $('.rbc-now')[0].scrollIntoView({ block: 'start' });
      this.setState({ scrolled: true });
    }
  }

  handleRangeChange = () => undefined;

  handleAssignmentChange = (userId) => {
    const { changeUser } = this.props;
    changeUser(userId);
  };

  handleChangeScheduled = () => undefined;

  handleSave = (attributes) => {
    const {
      onSubmit,
      userId,
      changeUser,
    } = this.props;
    if (userId && !attributes.user_id) {
      attributes.user_id = userId;
    } else if (attributes.user_id) {
      changeUser(attributes.user_id.toString());
    }

    onSubmit(attributes);
  };

  getFormValues = (attributes) => {
    const dates = getUpdatedDates({ scheduled: { attributes } });

    return {
      includeTime: !attributes.all_day,
      startDate: dates.start,
      startTime: dates.start,
      endDate: dates.end,
      endTime: dates.end,
    };
  };

  validateSelectSlot = ({ start }) => {
    const { keysReceivedOn } = this.props;
    const keysReceivedOnDate = moment(keysReceivedOn)
      .startOf('day');

    if (moment(start)
      .isBefore(keysReceivedOnDate, 'day')) {
      toastr.warning(
        `Please select a date that is on or after the keys received on date, ${keysReceivedOnDate.format('MM/DD')}`,
        null, { timeOut: 2500 },
      );

      return false;
    }

    return true;
  };

  render() {
    const {
      projectService,
      calendarVisible,
      events,
      closeScheduler,
      userId,
      additionalEvents,
      deadline,
      dueDate,
      keysReceivedOn,
      loadEvents,
    } = this.props;

    const { formVisible } = this.state;

    const allEvents = [...events, ...additionalEvents].map(e => decorateEvent(e));
    sortEvents(allEvents);

    return (
      <Modal
        show={calendarVisible}
        onHide={closeScheduler}
        size="lg"
        dialogClassName="scheduler-modal"
      >
        <div
          className="modal-body scheduler-container"
          style={{ maxHeight: '1000px' }}
        >
          <GenericScheduler
            scheduledType="calendar_events"
            events={allEvents}
            views={allViews}
            userId={userId}
            scheduledId={projectService && projectService.id}
            onAssignmentChange={this.handleAssignmentChange}
            onChangeScheduled={this.handleChangeScheduled}
            onRangeChange={loadEvents}
            onSave={this.handleSave}
            getFormValues={this.getFormValues}
            eventPropGetter={eventPropGetter}
            scheduled={projectService}
            dueDate={dueDate}
            deadline={deadline}
            minDate={moment(keysReceivedOn)
              .startOf('day')
              .toDate()}
            validateSelectSlot={this.validateSelectSlot}
            title={<Title />}
            setFormVisible={formVisible => this.setState({ formVisible })}
            formVisible={formVisible}
            refresh={() => loadEvents(false)}
          />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  if (!state.Scheduler.task) return { ...state.Scheduler };

  const { task } = state.Scheduler;
  const project = getRelationship(state.Api, task.relationships.project);
  const service = getRelationship(state.Api, task.relationships.service);

  const dueDate = project ? project.attributes.due_on : null;
  const deadline = project ? moment(project.attributes.keys_received_on)
    .add(21, 'days') : null;

  return {
    ...state.Scheduler,
    projectService: task,
    service,
    dueDate,
    deadline,
  };
};

const mapDispatchToProps = {
  changeUser,
  loadEvents,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewInspectionScheduler);
