import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';
import { getRecord, getRelationship, saveRecord } from 'redux-json-api-module';
import { FEATURE_TYPE_MAP } from '../../../../helpers/types';
import validate from './validate';
import { fetchTask } from '../../../../redux/modules/projectManagement';
import NumberInput from '../../../Inputs/NumberInput';
import { genericRecordPropType, idPropType } from '../../../../helpers/propTypes';
import { firstBy } from 'thenby';

const InvoiceForm = (
  {
    areaMap,
    fetchTask,
    initialValues,
    onHide,
    serviceInvoice,
    saveRecord,
    taskType,
    taskId,
  },
) => {
  const onSubmit = (values) => {
    const record = {
      id: serviceInvoice?.id,
      type: 'service_invoices',
      attributes: {
        labor_type: values.labor_type,
        task_type: taskType === 'project_services' ? 'ProjectService' : 'WorkOrderTask',
        task_id: taskId,
      },
    };

    if (values.labor_type === 'hourly_rate') {
      const hours = parseInt(values.hours) || 0;
      const minutes = parseInt(values.minutes) || 0;
      record.attributes.labor_minutes = (hours * 60) + minutes;
      record.attributes.hourly_rate = values.hourly_rate;
    } else {
      record.attributes.total_labor_cost = values.total_labor_cost;
    }

    const serviceInvoiceItems = [];
    Object.values(areaMap).sort().flat()
      .forEach((item) => {
        const quantity = parseInt(values[`service_invoice_items_quantity-${item.feature_id}`]);
        const price = parseFloat(values[`service_invoice_items_price-${item.feature_id}`]);

        serviceInvoiceItems.push({
          id: item.service_invoice_item_id,
          feature_type: taskType === 'project_services' ? 'Feature' : 'WorkOrderFeature',
          feature_id: item.feature_id,
          quantity,
          price,
          _destroy: Number.isNaN(quantity) || quantity <= 0,
        });
      });

    record.attributes.service_invoice_items_attributes = serviceInvoiceItems;

    return saveRecord(record, { params: { window_id: window.WINDOW_ID } })
      .then(async (resp) => {
        if (resp.error) {
          return { [FORM_ERROR]: 'Submission Error' };
        }

        await fetchTask(taskType, taskId);
        return onHide();
      });
  };

  const areas = Object.values(areaMap);

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      validate={validate}
      mutators={{
        // expect (field, value) args from the mutator
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      render={({
        form,
        handleSubmit,
        submitting,
        values,
      }) => (
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            {areas.flat().length > 0 ? (
              <>
                <div className="row mb-2">
                  <div className="col">
                    <strong>Area</strong>
                  </div>
                  <div className="col">
                    <strong>Item</strong>
                  </div>
                  <div className="col">
                    <strong>Quantity</strong>
                  </div>
                  <div className="col">
                    <strong>Price Per Part</strong>
                  </div>
                  {serviceInvoice?.id ? (
                    <div className="col">
                      <Button
                        onClick={() => {
                          Object.keys(areaMap).map(areaName => (
                            areaMap[areaName].map(item => (
                              form.mutators.setValue(
                                `service_invoice_items_price-${item.feature_id}`,
                                item.attributes.price,
                              )
                            ))));
                        }}
                      >
                        {'<'}
                      </Button>
                      <strong>Current Price Per Part</strong>
                    </div>
                  ) : null}
                </div>
                {Object.keys(areaMap).sort()
                  .map(areaName => (
                    areaMap[areaName].sort(
                      firstBy(i => i.attributes.name),
                    ).map(item => (
                      <div key={item.id} className="row mb-2">
                        <div className="col">
                          {areaName}
                        </div>
                        <div className="col">
                          {item.attributes.name}
                        </div>
                        <div className="col">
                          <Field
                            name={`service_invoice_items_quantity-${item.feature_id}`}
                            component="input"
                            type="number"
                            step={1}
                            min={0}
                            className="form-control"
                          />
                        </div>
                        <div className="col">
                          <Field
                            name={`service_invoice_items_price-${item.feature_id}`}
                            component="input"
                            type="number"
                            step={0.01}
                            min={0}
                            className="form-control"
                          />
                        </div>
                        {serviceInvoice?.id ? (
                          <div className="col">
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <Button
                                  onClick={() => form.mutators.setValue(
                                    `service_invoice_items_price-${item.feature_id}`,
                                    item.attributes.price,
                                  )}
                                >
                                  {'<'}
                                </Button>
                              </div>
                              <input
                                type="number"
                                disabled
                                value={item.attributes.price}
                                className="form-control"
                              />
                            </div>
                          </div>
                        ) : null}
                      </div>
                    ))
                  ))}
              </>
            ) : null}
          </Modal.Body>

          <Modal.Header>
            <Modal.Title>
              <strong>
                Labor Charges
              </strong>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="row">
              <div className="col">
                <label>Labor Type</label>
                <Field name="labor_type" component="select" className="form-control">
                  <option value="hourly_rate">Hourly Rate</option>
                  <option value="fixed_fee">Fixed Fee</option>
                </Field>
              </div>

              {values.labor_type === 'hourly_rate' ? (
                <>
                  <div className="col">
                    <Field
                      name="hours"
                      component={NumberInput}
                      label="Hours"
                      step={1}
                      min={0}
                    />
                  </div>
                  <div className="col">
                    <Field
                      name="minutes"
                      component={NumberInput}
                      label="Minutes"
                      step={15}
                      min={0}
                      max={45}
                    />
                  </div>
                  <div className="col">
                    <Field
                      name="hourly_rate"
                      component={NumberInput}
                      step={0.01}
                      min={0}
                      label="Rate"
                    />
                  </div>
                </>
              ) : (
                <div className="col">
                  <Field
                    name="total_labor_cost"
                    component={NumberInput}
                    step={0.01}
                    min={0}
                    label="Cost"
                  />
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="warning"
              onClick={onHide}
              disabled={submitting}
              value="Cancel"
            >
              Cancel
            </Button>
            {' '}
            <Button
              type="submit"
              variant="success"
              disabled={submitting}
            >
              Submit
            </Button>
          </Modal.Footer>
        </form>
      )}
    />
  );
};

InvoiceForm.propTypes = {
  taskType: PropTypes.string.isRequired,
  taskId: idPropType.isRequired,

  // connected
  areaMap: PropTypes.objectOf(
    PropTypes.arrayOf(genericRecordPropType),
  ).isRequired,
  fetchTask: PropTypes.func.isRequired,
  initialValues: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ).isRequired,
  onHide: PropTypes.func.isRequired,
  serviceInvoice: genericRecordPropType,
  saveRecord: PropTypes.func.isRequired,
};

InvoiceForm.defaultProps = {
  serviceInvoice: undefined,
};

const mapStateToProps = (state, ownProps) => {
  const task = getRecord(state.Api, {
    type: ownProps.taskType,
    id: ownProps.taskId,
  });

  const user = getRelationship(state.Api, task.relationships.user);
  const hourlyRate = user?.attributes?.hourly_rate || 0;
  const serviceInvoice = getRelationship(state.Api, task.relationships.service_invoice);
  const serviceInvoiceItems = serviceInvoice
    ? getRelationship(state.Api, serviceInvoice.relationships.service_invoice_items)
    : [];

  // create map of areas and items
  const areaMap = {};
  getRelationship(
    state.Api,
    task.relationships[FEATURE_TYPE_MAP[ownProps.taskType]],
  )
    .forEach((feature) => {
      if (!areaMap[feature.attributes.area]) areaMap[feature.attributes.area] = [];
      const serviceInvoiceItem = serviceInvoiceItems
        .find(sii => sii.relationships.feature.data.id == feature.id);
      const item = getRelationship(state.Api, feature.relationships.item);
      const detailedItem = {
        ...item,
        feature_id: feature.id,
        service_invoice_item_id: serviceInvoiceItem?.id,
      };

      if (item) {
        areaMap[feature.attributes.area].push(detailedItem);
      }
    });

  const initialValues = {};
  if (serviceInvoice) {
    initialValues.labor_type = serviceInvoice.attributes.labor_type;
    initialValues.hourly_rate = serviceInvoice.attributes.hourly_rate;
    initialValues.hours = Math.floor(serviceInvoice.attributes.labor_minutes / 60);
    initialValues.minutes = serviceInvoice.attributes.labor_minutes % 60;
    initialValues.total_labor_cost = serviceInvoice.attributes.total_labor_cost;

    getRelationship(state.Api, serviceInvoice.relationships.service_invoice_items)
      .forEach((sii) => {
        const featureId = sii.relationships.feature.data.id;
        initialValues[`service_invoice_items_quantity-${featureId}`] = sii.attributes.quantity;
        initialValues[`service_invoice_items_price-${featureId}`] = sii.attributes.price;
      });
  } else {
    initialValues.labor_type = 'hourly_rate';
    initialValues.hourly_rate = hourlyRate;
    initialValues.hours = 0;
    initialValues.minutes = 0;
    initialValues.total_labor_cost = 0;
  }

  Object.values(areaMap)
    .flat()
    .forEach((i) => {
      const quantityKey = `service_invoice_items_quantity-${i.feature_id}`;
      const priceKey = `service_invoice_items_price-${i.feature_id}`;
      initialValues[quantityKey] = initialValues[quantityKey] ?? 1;
      initialValues[priceKey] = initialValues[priceKey] ?? i.attributes.price ?? 0;
    });

  return {
    areaMap,
    initialValues,
    serviceInvoice,
  };
};

const mapDispatchToProps = {
  fetchTask,
  saveRecord,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InvoiceForm);
