import { firstBy } from 'thenby';
import moment from 'moment';
import get from 'lodash.get';
import { getRecord, getRelationship } from 'redux-json-api-module';
import { unitNumberSort } from '../containers/UnitsContainer/helpers';
import { idComparison } from './string';

const dateSort = (a, b) => {
  if (!a && !b) {
    return moment(a)
      .diff(moment(b));
  }

  return (a === null) - (b === null) || moment(a)
    .diff(moment(b));
};

export const completedTaskSort = (
  firstBy((a, b) => dateSort(a.attributes.completed_at, b.attributes.completed_at))
    .thenBy((a, b) => dateSort(a.attributes.started_at, b.attributes.started_at))
);

export const taskSort = (
  firstBy((a, b) => dateSort(a.attributes.started_at, b.attributes.started_at))
    .thenBy((a, b) => dateSort(a.attributes.ended_at, b.attributes.ended_at))
    .thenBy((a, b) => a.attributes.sort - b.attributes.sort)
    .thenBy((a, b) => dateSort(a.attributes.created_at, b.attributes.created_at))
);

export const planMessageThreadSort = api => (
  firstBy((a, b) => {
    // serializer type is broken
    const aId = get(a, 'relationships.last_plan_preview_message.data.id', null);
    const bId = get(b, 'relationships.last_plan_preview_message.data.id', null);

    const aLastMessage = getRecord(api, {
      type: 'messages',
      id: aId,
    });
    const bLastMessage = getRecord(api, {
      type: 'messages',
      id: bId,
    });

    const aLastMessageCreatedAt = get(aLastMessage, 'attributes.created_at', null);
    const bLastMessageCreatedAt = get(bLastMessage, 'attributes.created_at', null);

    const aStartedAt = a.attributes.plan_keys_received_on;
    const bStartedAt = b.attributes.plan_keys_received_on;

    return (aLastMessageCreatedAt === null) - (bLastMessageCreatedAt === null)
      || moment(bLastMessageCreatedAt)
        .diff(moment(aLastMessageCreatedAt))
      || (aStartedAt === null) - (bStartedAt === null)
      || moment(aStartedAt)
        .diff(moment(bStartedAt))
      || moment(a.attributes.plan_created_at)
        .diff(moment(b.attributes.plan_created_at));
  })
);

function getOtherUserName(directMessage, userId) {
  return idComparison(userId, directMessage.attributes.receiver_id)
    ? directMessage.attributes.creator_name : directMessage.attributes.receiver_name;
}

export const directMessageSort = userId => (a, b) => {
  const otherUserNameA = getOtherUserName(a, userId);
  const otherUserNameB = getOtherUserName(b, userId);

  return (otherUserNameA || '').localeCompare(otherUserNameB || '');
};

export const featureSort = api => (
  firstBy((feature) => {
    const room = getRelationship(api, feature.relationships.room);
    return room?.attributes?.name;
  }).thenBy(feature => feature.attributes.name)
);

export const unitSort = (
  firstBy((a, b) => a.attributes.street_address.localeCompare(b.attributes.street_address))
    .thenBy(unitNumberSort)
);

export const directionalStreetAddressSort = (a, b, direction) => (
  direction === 'desc' ? unitSort(b, a) : unitSort(a, b)
);

export const nameSort = (a, b) => {
  const nameA = a.attributes.name.toUpperCase();
  const nameB = b.attributes.name.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  return 0;
};

export const chatPlanSort = (a, b) => {
  const aStart = moment(a.attributes.plan_keys_received_on);
  const bStart = moment(b.attributes.plan_keys_received_on);

  return aStart.diff(bStart);
};

export const messageSort = (a, b) => (
  dateSort(
    a.attributes.written_at || a.attributes.created_at,
    b.attributes.written_at || b.attributes.created_at,
  )
);

export const unitNameSort = (a, b) => {
  const labelA = a.label;
  const labelB = b.label;

  const streetAddressA = labelA.split(',')[0];
  const streetAddressB = labelB.split(',')[0];

  const streetAddressComparison = streetAddressA.localeCompare(streetAddressB);

  if (streetAddressComparison !== 0) return streetAddressComparison;

  const unitA = labelA.split(', Unit # ')[1];
  const unitB = labelB.split(', Unit # ')[1];

  if (unitA && !unitB) return 1;
  if (unitB && !unitA) return -1;
  if (!unitA && !unitB) return 0;

  const unitNumberAMatches = unitA.match(/\d+/g);
  const unitNumberBMatches = unitB.match(/\d+/g);

  const unitNumberA = unitNumberAMatches && unitNumberAMatches[0];
  const unitNumberB = unitNumberBMatches && unitNumberBMatches[0];

  const unitNumberComparison = parseInt(unitNumberA) - parseInt(unitNumberB);

  if (unitNumberComparison !== 0) {
    return unitNumberComparison;
  }

  const unitLetterA = unitA.replace(/[0-9]/g, '');
  const unitLetterB = unitB.replace(/[0-9]/g, '');

  return unitLetterA.localeCompare(unitLetterB);
};

export const messageThreadSort = (
  firstBy((a, b) => dateSort(
    a.attributes.task_started_at,
    b.attributes.task_started_at,
  ))
    .thenBy((a, b) => dateSort(
      a.attributes.task_ended_at,
      b.attributes.task_ended_at,
    ))
    .thenBy((a, b) => dateSort(
      a.attributes.task_created_at,
      b.attributes.task_created_at,
    ))
    .thenBy(a => a.attributes.task_sort)
);
