import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';
import ProjectManagementContainer
  from '../containers/ProjectManagementContainer/ProjectManagementContainer';

const ProjectManagement = props => (
  <Provider store={store}>
    <ProjectManagementContainer {...props} />
  </Provider>
);

export default ProjectManagement;
