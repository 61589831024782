import get from 'lodash.get';
import { getRelationship } from 'redux-json-api-module';

export function stripCount(areaName) {
  return areaName.split(' :')[0];
}

export function isDisabled(name) {
  return ['Bathroom', 'Bedroom', 'Restroom'].includes(name);
}

export const getDescriptionAttributes = (description, meta) => ({
  id: description.id,
  name: description.attributes.name,
  service_id: get(description, 'relationships.service.data.id', meta.defaultServiceId),
  _destroy: false,
  sort: description.attributes.sort,
});

export const getItemAttributes = (item, meta) => ({
  id: item.id,
  name: item.attributes.name,
  area_id: get(item, 'relationships.area.data.id', meta.defaultAreaId),
  _destroy: false,
});

export const getAreaAttributes = (area, meta, api) => {
  const area_items = getRelationship(api, area.relationships.area_items)
    .map(ai => ({
      id: ai.id,
      item_id: ai.attributes.item_id,
      sort: ai.attributes.sort,
      _destroy: false,
    }));

  area_items.sort((a, b) => a.sort - b.sort);

  return {
    id: area.id,
    name: stripCount(area.attributes.name),
    fullName: area.attributes.name,
    area_group_id: get(area, 'relationships.area_group.data.id', meta.defaultAreaGroupId),
    _destroy: false,
    sort: area.attributes.sort,
    area_items,
  };
};

export const getDefaultDescriptionAttributes = meta => ({
  id: null,
  name: '',
  service_id: meta.defaultServiceId,
  _destroy: false,
});

export const getDefaultAreaItemAttributes = meta => ({
  id: null,
  area_id: meta.defaultAreaId,
  _destroy: false,
});

export const getDefaultAreaAttributes = meta => ({
  id: null,
  name: '',
  area_group_id: meta.defaultAreaGroupId,
  _destroy: false,
});

export const getOptions = (state, tab) => {
  if (tab === 'Descriptions') {
    const services = Object.values(state.Api.services || {});
    const serviceOptions = services.map(service => (
      [service.attributes.name, service.id]
    ));
    return {
      serviceOptions,
    };
  }

  const areaIds = state.Inspections.areaIds || [];
  const areas = Object.values(state.Api.areas || {})
    .filter(area => (
      areaIds.includes(area.id.toString())
    ));
  const areaOptions = areas.map(area => (
    [area.attributes.name, area.id]
  ));

  if (tab === 'Items') {
    return {
      areaOptions,
    };
  }

  const areaGroups = Object.values(state.Api.area_groups || {});
  const areaGroupOptions = areaGroups.map(areaGroup => (
    [areaGroup.attributes.name, areaGroup.id]
  ));

  return {
    areaOptions,
    areaGroupOptions,
  };
};

export const getMeta = (options, tab) => {
  if (tab === 'Descriptions') {
    return {
      defaultServiceId: null,
    };
  }

  const defaultAreaId = options.areaOptions && options.areaOptions[0] && options.areaOptions[0][1];
  if (tab === 'Items') {
    return {
      defaultAreaId,
    };
  }

  const defaultAreaGroupId = options.areaGroupOptions
    && options.areaGroupOptions[0]
    && options.areaGroupOptions[0][1];

  return {
    defaultAreaId,
    defaultAreaGroupId,
  };
};

export const ATTRIBUTE_MAPPERS = {
  Areas: getAreaAttributes,
  AreaItems: getItemAttributes,
  Descriptions: getDescriptionAttributes,
};

export const DEFAULT_ATTRIBUTE_MAPPERS = {
  Areas: getDefaultAreaAttributes,
  AreaItems: getDefaultAreaItemAttributes,
  Descriptions: getDefaultDescriptionAttributes,
};
