import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteRecord, saveRecord } from 'redux-json-api-module';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  fetchAreas,
  toggleNewArea,
  toggleExpandedArea,
  fetchItems, reorderAreas,
} from '../../redux/modules/inspections';
import Loader from '../../components/Loader';
import { idType } from '../../helpers/propTypes';
import AreaListItem from './AreaListItem';
import NewAreaForm from './NewAreaForm';
import { isDisabled } from './helpers';
import { saveBatch } from '../../redux/modules/global';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  ...draggableStyle,
});

const getListStyle = () => ({});

const Title = memo(() => (
  <div className="mb-3">
    <h3>Areas & Parts</h3>
    <div>
      You may drag / drop Areas and Parts, and you may add / edit Areas and Parts to customize your
      mobile inspection template.
    </div>
  </div>
));

const AreasTab = (
  {
    areaIds, fetchAreas, fetchItems, saveRecord, saveBatch,
    deleteRecord, toggleNewArea, newArea, expandedId,
    reorderAreas,
  },
) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Promise.all([fetchAreas(), fetchItems()])
      .then(() => {
        setLoading(false);
      });
  }, []);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const orderedAreaIds = reorder(
      areaIds,
      result.source.index,
      result.destination.index,
    );

    const records = orderedAreaIds.map((areaId, index) => ({
      id: areaId,
      type: 'areas',
      attributes: {
        sort: index,
      },
    }));

    saveBatch(records);
    reorderAreas(orderedAreaIds);
  };

  const handleSubmit = (values) => {
    // we strip the :bed_count when showing the user, but don't want to save as that
    const name = isDisabled(values.name) ? values.fullName : values.name;

    let response;
    if (values._destroy) {
      response = deleteRecord({
        type: 'areas',
        id: values.id,
      });
    } else {
      let sort = 0;
      const areaItems = values.area_items
        .filter(item => item.item_id)
        .map((ai) => {
          const sortedItem = { ...ai, sort };
          if (!ai._destroy) sort += 1;

          if (ai.item_id === 'CUSTOM') {
            sortedItem.item_id = null;
            sortedItem.item_attributes = {
              id: null,
              name: ai.name,
            };
          }

          return sortedItem;
        });

      const record = {
        id: values.id,
        type: 'areas',
        attributes: {
          name,
          area_items_attributes: areaItems,
        },
      };

      response = saveRecord(record, { params: { window_id: window.WINDOW_ID } });
    }

    response.then(fetchAreas);
  };

  if (loading) {
    return (
      <>
        <Title />
        <Loader />
      </>
    );
  }

  if (expandedId) {
    return (
      <>
        <Title />
        {areaIds.map(areaId => (
          <AreaListItem key={areaId} areaId={areaId} handleSubmit={handleSubmit} />
        ))}
      </>
    );
  }

  return (
    <div>
      <Title />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {areaIds.map((areaId, index) => (
                <Draggable key={areaId} draggableId={areaId} index={index}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style,
                      )}
                    >
                      <AreaListItem areaId={areaId} handleSubmit={handleSubmit} />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {newArea ? (
        <NewAreaForm />
      ) : null}

      <button
        type="button"
        className="btn btn-info"
        onClick={() => toggleNewArea(true)}
      >
        + Add Area
      </button>
    </div>
  );
};

AreasTab.propTypes = {
  teamId: idType.isRequired,

  // connected
  expandedId: idType,
  areaIds: PropTypes.arrayOf(idType).isRequired,
  toggleExpandedArea: PropTypes.func.isRequired,
  newArea: PropTypes.bool.isRequired,
  saveRecord: PropTypes.func.isRequired,
  saveBatch: PropTypes.func.isRequired,
  deleteRecord: PropTypes.func.isRequired,
  fetchAreas: PropTypes.func.isRequired,
  fetchItems: PropTypes.func.isRequired,
  toggleNewArea: PropTypes.func.isRequired,
};

AreasTab.defaultProps = {
  expandedId: null,
};

const mapStateToProps = state => ({
  areaIds: state.Inspections.areaIds,
  expandedId: state.Inspections.expandedId,
  newArea: state.Inspections.newArea,
});

const mapDispatchToProps = {
  saveRecord,
  saveBatch,
  deleteRecord,
  fetchAreas,
  fetchItems,
  toggleNewArea,
  toggleExpandedArea,
  reorderAreas,
};

export default connect(mapStateToProps, mapDispatchToProps)(AreasTab);
