import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import trashRestore from '../../../assets/images/icon/baseline-restore_from_trash-24px.svg';
import Select from '../../components/Inputs/Select';
import { getOptions, getMeta, ATTRIBUTE_MAPPERS, DEFAULT_ATTRIBUTE_MAPPERS } from './helpers';

const DescriptionsTabForm = (
  {
    handleSubmit,
    initialValues,
    options,
    meta,
  },
) => (
  <Form
    initialValues={initialValues}
    onSubmit={handleSubmit}
    mutators={{
      ...arrayMutators,
    }}
    render={({ handleSubmit, pristine, form, values, submitting }) => (
      <form onSubmit={handleSubmit}>
        <div className="mb-4 row">
          <div className="col">
            <h3>Inspection Service Descriptions</h3>
            <small style={{ fontSize: '12px' }}>You may add/edit the mobile inspection's service description options.</small>
          </div>
          <div className="col">
            <span style={{ fontSize: '18px' }}>Optional: Associate Description with Service</span>
            <div className="mt-2">You may select a default Service for a Service Description. When a description is selected during the mobile inspection, the Service will be auto-selected for that description.</div>
          </div>
        </div>
        <FieldArray name="descriptions">
          {({ fields }) => (
            <div>
              {fields.map((name, index) => (
                <div key={name} className="form-group row">
                  <Field
                    name={`${name}.id`}
                    component="input"
                    type="hidden"
                  />
                  <div className="col">
                    <Field
                      name={`${name}.name`}
                      component="input"
                      type="text"
                      placeholder=""
                      className="form-control is-valid string required"
                    />
                  </div>

                  <div className="col">
                    <Field
                      name={`${name}.service_id`}
                      component={Select}
                      options={options.serviceOptions}
                      includeBlank
                    />
                  </div>

                  <Field name={`${name}._destroy`}>
                    {({ input }) => (
                      input.value ? (
                        <button
                          name={`${name}._destroy`}
                          type="button"
                          className="btn btn-link restore"
                          onClick={() => input.onChange(false)}
                        >
                          <img
                            src={trashRestore}
                            alt="Trash Restore"
                          />
                        </button>
                      ) : (
                        <button
                          name={`${name}._destroy`}
                          type="button"
                          className="btn btn-link delete"
                          onClick={() => {
                            if (values.descriptions[index].id) {
                              input.onChange(true);
                            } else {
                              fields.remove(index);
                            }
                          }}
                        >
                          <i className="fa fa-2x fa-trash" />
                        </button>
                      )
                    )}
                  </Field>
                </div>
              ))}
              <div>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => fields.push('descriptions', DEFAULT_ATTRIBUTE_MAPPERS.Descriptions(meta))}
                >
                    + Add Description
                </button>
                <input
                  id="item-save"
                  type="submit"
                  value="Save"
                  className={`btn btn-success m-l-10 animated ${pristine ? 'invisible' : 'visible fadeIn'}`}
                  disabled={submitting}
                />
                <button
                  type="button"
                  value="Cancel"
                  className={`btn btn-secondary m-l-10 animated ${pristine ? 'invisible' : 'visible fadeIn'}`}
                  disabled={submitting}
                  onClick={form.reset}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </FieldArray>
      </form>
    )}
  />
);

DescriptionsTabForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  options: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const options = getOptions(state, 'Descriptions');
  const meta = getMeta(options, 'Descriptions');

  const attributes = ownProps.descriptions.map(description => (
    ATTRIBUTE_MAPPERS.Descriptions(description, meta)
  ));
  attributes.push(DEFAULT_ATTRIBUTE_MAPPERS.Descriptions(meta));

  return {
    options,
    meta,
    initialValues: {
      descriptions: attributes,
    },
  };
};

export default connect(mapStateToProps)(DescriptionsTabForm);
