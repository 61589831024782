import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import get from 'lodash.get';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { directionalUnitAttributeSort } from './helpers';
import { unitSort } from '../../helpers/sorts';

const columns = [{
  dataField: 'attributes.street_address',
  text: 'Street Address',
}, {
  dataField: 'attributes.unit_number',
  text: 'Unit #',
  sortFunc: directionalUnitAttributeSort,
}, {
  dataField: 'attributes.city',
  text: 'City',
}, {
  dataField: 'attributes.state',
  text: 'State',
}, {
  dataField: 'attributes.zip',
  text: 'Zip',
},
{
  dataField: 'attributes.bed_count',
  text: 'Bed Count',
},
{
  dataField: 'attributes.bath_count',
  text: 'Bath Count',
},
{
  dataField: 'attributes.humanized_housing_type',
  text: 'Housing Type',
}];

const paginationOptions = {
  sizePerPage: 25,
};

class UnitsTable extends Component {
  render() {
    const { units, onSelect, onSelectAll, nonSelectable } = this.props;

    return (
      <BootstrapTable
        keyField="id"
        data={units}
        columns={columns}
        pagination={paginationFactory(paginationOptions)}
        selectRow={{
          style: () => ({
            width: '10%',
          }),
          onSelect,
          onSelectAll,
          mode: 'checkbox',
          nonSelectable,
        }}
      />
    );
  }
}

UnitsTable.propTypes = {
  units: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  onSelectAll: PropTypes.func.isRequired,
  nonSelectable: PropTypes.array.isRequired,
};

UnitsTable.defaultProps = {
  units: [],
};

const mapStateToProps = (state, ownProps) => {
  const units = Object.values(state.Api.units || {})
    .filter(u => (state.Units.unitIds || []).includes(u.id)
      && (!ownProps.communityId || get(u, 'relationships.community.data.id', null) === ownProps.communityId.toString()))
    .sort(unitSort);

  const nonSelectable = units.filter(unit => unit.attributes.has_plans)
    .map(unit => (
      unit.id.toString()
    ));

  return {
    units,
    nonSelectable,
  };
};

export default connect(mapStateToProps)(UnitsTable);
