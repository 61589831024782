import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord, getRelationship } from 'redux-json-api-module';
import humanizeDuration from 'humanize-duration';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { firstBy } from 'thenby';
import { PARENT_TYPE_MAP } from '../../../helpers/types';
import Avatar from '../../Avatar';
import RoomInvoiceItems from './RoomInvoiceItems';
import { numberToCurrency } from '../../../helpers/string';
import InvoiceFormModal from './InvoiceFormModal';
import { genericRecordPropType, idType } from '../../../helpers/propTypes';

const Invoice = ({ rooms, serviceInvoice, partsTotal, task, unit, user }) => {
  const [formVisible, setFormVisible] = useState(false);

  if (!serviceInvoice) {
    return (
      <>
        <Button variant="success" onClick={() => setFormVisible(true)}>
          Create Invoice
        </Button>
        <InvoiceFormModal
          show={formVisible}
          onHide={() => setFormVisible(false)}
          serviceInvoiceId={null}
          taskType={task.type}
          taskId={task.id}
        />
      </>
    );
  }

  return (
    <>
      <Button variant="success" className="mr-1" onClick={() => setFormVisible(true)}>
        Edit Invoice
      </Button>
      <a
        className="btn btn-success"
        target="_blank"
        rel="noopener noreferrer"
        href={`/service_invoices/${serviceInvoice.id}.pdf`}
      >
        Download
      </a>

      <div className="row">
        <div className="col text-center">
          {unit.attributes.short_address}
        </div>
      </div>
      <div className="row mb-3">
        <div className="col text-center">
          {task.attributes.service_name}
          {task.attributes.aasm_state === 'completed' ? (
            ` - Completed on ${moment(task.attributes.completed_at).format('MM/DD')}`
          ) : null}
        </div>
      </div>
      <div className="row mb-3">
        <div className="col text-center">
          <h4 className="mb-0 ml-0 text-uppercase">
            Completed By:
          </h4>
          <Avatar {...user.attributes} />
          <span className="text-grey">
            {` ${user.attributes.name}`}
          </span>
        </div>
      </div>

      {rooms.sort(firstBy(r => r.attributes.name)).map(room => (
        <RoomInvoiceItems
          key={room.id}
          taskType={task.type}
          taskId={task.id}
          roomId={room.id}
        />
      ))}

      <div className="row mb-3">
        <div className="col">
          <strong>Total Parts </strong>
          {numberToCurrency(partsTotal)}
        </div>
      </div>
      <div className="row mb-3">
        <div className="col">
          <strong>Total Labor </strong>
          {serviceInvoice.attributes.labor_type === 'hourly_rate' ? (
            humanizeDuration(serviceInvoice.attributes.labor_minutes * 60 * 1000)
          ) : (
            'Flat Fee'
          )}
          {` - ${numberToCurrency(serviceInvoice.attributes.total_labor_cost)}`}
        </div>
      </div>

      <InvoiceFormModal
        show={formVisible}
        serviceInvoiceId={serviceInvoice.id}
        onHide={() => setFormVisible(false)}
        taskType={task.type}
        taskId={task.id}
      />
    </>
  );
};

Invoice.propTypes = {
  taskType: PropTypes.string.isRequired,
  taskId: idType.isRequired,

  // connected
  serviceInvoice: PropTypes.objectOf(genericRecordPropType),
  partsTotal: PropTypes.number.isRequired,
  task: PropTypes.objectOf(genericRecordPropType).isRequired,
  unit: PropTypes.objectOf(genericRecordPropType).isRequired,
  user: PropTypes.objectOf(genericRecordPropType).isRequired,
  rooms: PropTypes.arrayOf(genericRecordPropType).isRequired,
};

Invoice.defaultProps = {
  serviceInvoice: null,
};

const mapStateToProps = (state, ownProps) => {
  const task = getRecord(state.Api, { type: ownProps.taskType, id: ownProps.taskId });
  const plan = getRelationship(state.Api, task.relationships[PARENT_TYPE_MAP[task.type]]);
  const serviceInvoice = getRelationship(state.Api, task.relationships.service_invoice);
  const serviceInvoiceItems = serviceInvoice
    ? getRelationship(state.Api, serviceInvoice.relationships.service_invoice_items)
    : [];

  return {
    serviceInvoice,
    partsTotal: serviceInvoiceItems.reduce((a, b) => (
      a + b.attributes.total_price
    ), 0),
    task,
    unit: getRelationship(state.Api, plan.relationships.unit),
    user: getRelationship(state.Api, task.relationships.user),
    rooms: getRelationship(state.Api, task.relationships.rooms),
  };
};

export default connect(
  mapStateToProps,
)(Invoice);
