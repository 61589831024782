import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';
import COLORS from '../../helpers/colors';

const SwitchInput = ({ input }) => (
  <Switch
    onColor={COLORS.blue}
    uncheckedIcon={false}
    checkedIcon={false}
    onChange={input.onChange}
    checked={input.value}
    height={20}
    handleDiameter={30}
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
  />
);

SwitchInput.propTypes = {
  input: PropTypes.object.isRequired,
};

export default SwitchInput;
