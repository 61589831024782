import moment from 'moment';

export function dateFormat(date) {
  if (!date || !moment(date)
    .isValid()) {
    return 'Pending';
  }
  return moment(date)
    .format('MM/DD');
}

function estimatedDoneUnformatted(project) {
  if ((project.attributes.aasm_state === 'active' || project.attributes.aasm_state === 'completed')
    && project.attributes.estimated_due_on) {
    return project.attributes.estimated_due_on;
  }
  if (project.attributes.aasm_state === 'review') {
    return project.attributes.last_completed_service_date;
  }
}

export function estimatedDone(project) {
  return dateFormat(estimatedDoneUnformatted(project));
}

function dueOnUnformatted(project) {
  return project.attributes.due_on;
}

export function isLate(project) {
  const est = estimatedDoneUnformatted(project);
  const due = dueOnUnformatted(project);
  if (!est || !moment(est)
    .isValid() || !due || !moment(due)
    .isValid()) {
    return false;
  }
  return moment(due)
    .isBefore(est);
}

export function dueOn(project) {
  return dateFormat(project.attributes.due_on);
}

export function getKeysReceivedOn(project) {
  return dateFormat(project.attributes.keys_received_on);
}
