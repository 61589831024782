import React  from 'react';
import PropTypes from 'prop-types';
import { Badge, Button } from 'react-bootstrap';
import Header from './Header';
import withLayout from './withLayout';

const LayoutContainer = ({
  recentlyOnline,
  modalVisible,
  loaded,
  toastVisible,
  unreadCount,
  icon,
  toggleModal,
  canOpenChat,
  getModal,
}) => (
  <>
    <Header
      recentlyOnline={recentlyOnline.data}
      toastVisible={toastVisible}
    />
    {modalVisible ? getModal() : null}
    {loaded && canOpenChat() ? (
      <Button
        id="open-chat-btn"
        variant="link"
        className="btn-fab"
        onClick={() => toggleModal(!modalVisible)}
      >
        <img
          src={icon}
          style={{
            width: '50px',
            height: '50px',
          }}
        />
        {unreadCount ? (
          <Badge
            pill
            variant="danger"
            className="float-right red-pill"
          >
            {unreadCount}
          </Badge>
        ) : null}
      </Button>
    ) : null}
  </>
);

LayoutContainer.propTypes = {
  recentlyOnline: PropTypes.object.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  loaded: PropTypes.bool.isRequired,
  toastVisible: PropTypes.object,
  unreadCount: PropTypes.number.isRequired,
  icon: PropTypes.any.isRequired,
  toggleModal: PropTypes.func.isRequired,
  canOpenChat: PropTypes.func.isRequired,
  getModal: PropTypes.func.isRequired,
};

LayoutContainer.defaultProps = {
  toastVisible: null,
};

export default withLayout(LayoutContainer);
