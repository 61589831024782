import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { simpleFormat } from '../../helpers/string';
import Alert from '../Alert';
import FeatureForm from '../FeatureForm/FeatureForm';

const FeatureFormModal = ({
  visible,
  onHide,
  workOrderDescription,
  onSubmit,
  areas,
  descriptions,
  items,
  services,
  initialValues,
}) => (
  <Modal
    show={visible}
    onHide={onHide}
    backdropClassName="scheduler-form-modal-backdrop"
  >
    <div className="modal-header text-center">
      <h2 className="modal-title">Add Service</h2>
    </div>
    <div className="modal-body">
      {workOrderDescription ? (
        <>
          <strong>Work Order Description</strong>
          <div dangerouslySetInnerHTML={{ __html: simpleFormat(workOrderDescription) }} />
        </>
      ) : null}
      <Alert type="info">
        Please add at least one item that needs service.
      </Alert>
      <FeatureForm
        onSubmit={onSubmit}
        areas={areas}
        descriptions={descriptions}
        items={items}
        services={services}
        onCancel={onHide}
        initialValues={initialValues}
      />
    </div>
  </Modal>
);

FeatureFormModal.propTypes = {
  visible: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  workOrderDescription: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  areas: PropTypes.array.isRequired,
  descriptions: PropTypes.array.isRequired,
  items: PropTypes.object.isRequired,
  services: PropTypes.array.isRequired,
  initialValues: PropTypes.object,
};

FeatureFormModal.defaultProps = {
  visible: false,
  workOrderDescription: undefined,
  initialValues: undefined,
};

const mapStateToProps = state => ({});

export default connect(
  mapStateToProps,
)(FeatureFormModal);
