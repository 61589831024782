import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { FETCH_RECORDS, fetchRecords } from 'redux-json-api-module';
import { Button } from 'react-bootstrap';
import toastr from 'toastr';
import { fetchChatChannels } from '../../../../redux/modules/chat';

const columns = [
  {
    dataField: 'name',
    text: 'Name',
    sort: true,
  },
  {
    dataField: 'actions',
    sort: true,
    headerStyle: { width: '200px' },
  },
];

const defaultSorted = [{
  dataField: 'name',
  order: 'asc',
}];

const NoDataIndication = () => (
  <div className="text-center">No Archived Chat Channels</div>
);

const ChatChannelActions = ({ onRestore }) => (
  <Button onClick={onRestore}>Restore</Button>
);

const InventoryTable = ({ fetchRecords, restoreChannel, fetchChatChannels }) => {
  const [loading, setLoading] = useState(false);
  const [channels, setChannels] = useState([]);

  useEffect(() => {
    setLoading(true);
    fetchRecords('chat_channels', {
      page: { size: 250 },
      filter: { scope: 'archived' },
    })
      .then(resp => setChannels(resp.payload.data.data))
      .finally(() => setLoading(false));
  }, []);

  const handleRestore = (id) => {
    setLoading(true);
    restoreChannel(id)
      .then((resp) => {
        if (resp.error) return toastr.error('Failed to restore Chat Channel');

        setChannels(channels.filter(c => c.id !== id));
        fetchChatChannels();
        return toastr.success('Successfully restored Chat Channel');
      })
      .finally(() => setLoading(false));
  };

  const data = channels.map(channel => ({
    name: channel.attributes.name,
    actions: <ChatChannelActions onRestore={() => handleRestore(channel.id)} />,
  }));

  const Overlay = overlayFactory({ spinner: true });

  return (
    <BootstrapTable
      keyField="id"
      data={data}
      rowClasses={r => (`row-${r.id}`)}
      columns={columns}
      defaultSorted={defaultSorted}
      loading={loading}
      overlay={Overlay}
      noDataIndication={NoDataIndication}
    />
  );
};

InventoryTable.propTypes = {
  // connected
  fetchRecords: PropTypes.func.isRequired,
  restoreChannel: PropTypes.func.isRequired,
};

const restoreChannel = id => ({
  type: FETCH_RECORDS,
  payload: {
    request: {
      method: 'PATCH',
      url: `/chat_channels/${id}/recover`,
    },
  },
});

const mapDispatchToProps = {
  fetchRecords,
  restoreChannel,
  fetchChatChannels,
};

export default connect(
  null,
  mapDispatchToProps,
)(InventoryTable);
