import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import * as ReactDOM from 'react-dom';

const ReverseScrollList = ({ children, ...props }) => {
  const [height, setHeight] = useState(0);
  const [numChildren, setNumChilrdren] = useState(0);

  const scrollToBottom = (node) => {
    const { clientHeight, scrollHeight } = node;
    const maxScrollTop = scrollHeight - clientHeight;

    ReactDOM.findDOMNode(node).scrollTop = Math.max(maxScrollTop, 0);
  };

  const container = useCallback((node) => {
    if (node !== null) {
      const { scrollHeight } = node;
      if (scrollHeight !== height) {
        setHeight(scrollHeight);
        if (numChildren !== children.length) scrollToBottom(node);
        setNumChilrdren(children.length);
      }
    }
  });

  return (
    <ul {...props} ref={container}>
      {children}
    </ul>
  );
};

ReverseScrollList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ReverseScrollList;
