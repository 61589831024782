import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import checkmarkIcon from '../../../assets/images/icon/checkMarkGreen.png';
import trashRestore from '../../../assets/images/icon/baseline-restore_from_trash-24px.svg';
import './ChecklistItemInput.scss';

function getIcon(checklistItem, toggleDeletion, deleted, serviceCompleted, index) {
  if (checklistItem.attributes && checklistItem.attributes.aasm_state === 'completed') {
    return <img src={checkmarkIcon} alt="Checkmark Icon" className="completed" />;
  }
  if (serviceCompleted || (!checklistItem.id && index === 0)) {
    return <div className="blank" />;
  }
  if (deleted) {
    return (
      <img
        src={trashRestore}
        alt="Trash Restore"
        className="restore"
        id={`undelete-${checklistItem.id}`}
        onClick={() => toggleDeletion(checklistItem, false)}
      />
    );
  }
  return (
    <i
      id={`delete-${checklistItem.id}`}
      className="fa fa-2x fa-trash delete"
      onClick={() => toggleDeletion(checklistItem, true)}
    />
  );
}

const ChecklistItemInput = ({ index, checklistItem, toggleDeletion, deleted, serviceCompleted }) => {
  if (deleted && !checklistItem.id) return null;

  return (
    <div key={`item-row-${index}`} className="form-group row">
      <input
        type="hidden"
        name={`id_${index}`}
        value={checklistItem.id || ''}
      />
      <div className="col">
        <Field
          disabled={
            serviceCompleted
            || deleted
            || (checklistItem.attributes && checklistItem.attributes.aasm_state === 'completed')
          }
          name={`description_${index}`}
          component="input"
          placeholder=""
          className="form-control is-valid string required"
        />
      </div>
      {getIcon(checklistItem, toggleDeletion, deleted, serviceCompleted, index)}
    </div>
  );
};

ChecklistItemInput.propTypes = {
  index: PropTypes.number.isRequired,
  serviceCompleted: PropTypes.bool.isRequired,
  toggleDeletion: PropTypes.func.isRequired,
  deleted: PropTypes.bool,
  checklistItem: PropTypes.any.isRequired,
};

ChecklistItemInput.defaultProps = {
  deleted: false,
};

export default ChecklistItemInput;
