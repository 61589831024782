import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setSecondaryFilter, SECONDARY_FILTERS } from '../../../redux/modules/teamSchedule';
import ListGroupItem from '../ScheduleSelector/ListGroupItem';

const SecondaryFilter = ({ title, secondaryFilter, setSecondaryFilter }) => (
  <span className="ml-2">
    <div className="dropdown">
      <button
        className="btn btn-primary toggle-button"
        type="button"
        id="secondaryFilterButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {title}
        <i className="fa fa-chevron-down spaced-icon" />
      </button>
      <div className="dropdown-menu scrollable-menu" aria-labelledby="secondaryFilterButton">
        {Object.keys(SECONDARY_FILTERS).map(filter => (
          <ListGroupItem
            selected={secondaryFilter === filter}
            onClick={() => setSecondaryFilter(filter)}
            key={filter}
          >
            {SECONDARY_FILTERS[filter]}
          </ListGroupItem>
        ))}
      </div>
    </div>
  </span>
);

SecondaryFilter.propTypes = {
  title: PropTypes.string.isRequired,
  secondaryFilter: PropTypes.string,
  setSecondaryFilter: PropTypes.func.isRequired,
};

SecondaryFilter.defaultProps = {
  secondaryFilter: null,
};

const mapStateToProps = (state) => {
  const { secondaryFilter } = state.TeamSchedule;

  const title = SECONDARY_FILTERS[secondaryFilter] || 'View Full Team Schedule';

  return {
    title,
    secondaryFilter,
  };
};

const mapDispatchToProps = {
  setSecondaryFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryFilter);
