import React from 'react';
import PropTypes from 'prop-types';

const Tab = ({ active, children }) => (
  <div className={`tab-pane${active ? ' active' : ''}`} role="tabpanel">
    {children}
  </div>
);

Tab.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Tab.defaultProps = {
  active: false,
  children: null,
};

export default Tab;
