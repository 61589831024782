import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';
import UnitImportContainer from '../containers/UnitImportContainer/UnitImportContainer';

const UnitImport = props => (
  <Provider store={store}>
    <UnitImportContainer {...props} />
  </Provider>
);

export default UnitImport;
