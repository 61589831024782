import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { fetchRecords } from 'redux-json-api-module';
import PropertyRow from './PropertyRow';
import PropertyForm from './PropertyForm';
import colors from '../../../helpers/colors';

const PropertiesTab = ({ properties, fetchRecords }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    fetchRecords('properties', {
      include: 'units',
      page: { size: 1000 },
    });
  }, []);

  return (
    <div>
      <Button variant="success" onClick={() => setVisible(true)} className="my-3">
        Create Property Group
      </Button>
      <a className="btn btn-info ml-2" href="/reports">
        Create Property Group Report
      </a>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Property Groups</th>
            <th>Units</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {Object.keys(properties).length === 0 ? (
            <tr>
              <td colSpan={3} className="text-center">You do not have any properties.</td>
            </tr>
          ) : (
            Object.keys(properties).map(id => (
              <PropertyRow key={id} id={id} />
            ))
          )}
        </tbody>
      </table>
      <PropertyForm
        visible={visible}
        onHide={() => setVisible(false)}
      />
    </div>
  );
};

PropertiesTab.propTypes = {};

const mapStateToProps = state => ({
  properties: state.Api.properties || {},
});

const mapDispatchToProps = {
  fetchRecords,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PropertiesTab);
