import React from 'react';
import Dropzone from 'react-dropzone';
import PropTypes from 'prop-types';
import DefaultChildren from './DefaultChildren';

const DropFilesInput = ({ disabled, onDrop, children }) => (
  <Dropzone disabled={disabled} onDrop={onDrop}>
    {({ getRootProps, getInputProps, isDragActive }) => (
      <fieldset
        {...getRootProps({
          disabled,
          className: `dropzone ${isDragActive ? 'dropzone--isActive' : ''}`,
        })}
      >
        <input {...getInputProps({ name: 'data[attributes][file]' })} />
        {typeof children === 'function' ? children({ disabled, isDragActive }) : children}
      </fieldset>
    )}
  </Dropzone>
);

DropFilesInput.propTypes = {
  disabled: PropTypes.bool,
  onDrop: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.func,
    PropTypes.node,
  ]),
};

DropFilesInput.defaultProps = {
  disabled: false,
  children: DefaultChildren,
};

export default DropFilesInput;
