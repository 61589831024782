import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';

const ErrorModal = ({
  visible,
  closeModal,
  errors,
  modelName,
}) => (
  <Modal
    show={visible}
    onHide={closeModal}
    className="text-left"
  >
    <div className="modal-header">
      <h5 className="modal-title">
        {`There was a problem importing your ${modelName}.`}
        <br />
        <small>
          Please correct the errors below and try again.
        </small>
      </h5>
    </div>
    <ul className="list-group list-group-modal border-top-0">
      {errors.map(error => (
        <li key={error.line_number} className="list-group-item d-flex justify-content-between">
          {error.line_number ? (
            <div className="no-wrap mr-2">{`Line ${error.line_number}: `}</div>
          ) : null}
          {error.message}
        </li>
      ))}
    </ul>
  </Modal>
);

ErrorModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  errors: PropTypes.array,
  modelName: PropTypes.string.isRequired,
};

ErrorModal.defaultProps = {
  errors: [],
};

export default ErrorModal;
