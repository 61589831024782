import React from 'react';
import PropTypes from 'prop-types';
import { idType } from '../../helpers/propTypes';

const UnitDisplay = ({ id, label }) => (
  <div className="unit-select__option" id={`unit-option-${id}`}>
    {label}
  </div>
);

UnitDisplay.propTypes = {
  label: PropTypes.string.isRequired,
  id: idType.isRequired,
};

export default UnitDisplay;
