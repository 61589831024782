import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';
import { fetchTasks } from '../../redux/modules/partsTasks';
import CommunityCard from './CommunityCard';
import Loader from '../../components/Loader';
import { idType } from '../../helpers/propTypes';
import ProjectTypeFilter from './ProjectTypeFilter';

const PartsTasksContainer = ({ communityIdMap, fetchTasks, teamId, filter }) => {
  const [loading, setLoading] = useState(false);
  const ids = Object.keys(communityIdMap).map((id) => {
    if (filter === 'all') return id;
    if (communityIdMap[id].find(rec => rec.type === filter)) return id;
    return null;
  }).filter(id => id);

  useEffect(() => {
    setLoading(true);
    fetchTasks().finally(() => setLoading(false));

    window.PUSHER.subscribe(`team-${teamId}`).bind('update', fetchTasks);

    return () => {
      window.PUSHER.unsubscribe(`team-${teamId}`);
    };
  }, [teamId]);


  if (loading) return <Loader />;

  return (
    <>
      <Card>
        <Card.Body>
          <ProjectTypeFilter />
        </Card.Body>
      </Card>
      {ids.length === 0 ? (
        'There are no tasks needing parts right now'
      ) : (
        ids.map(id => (
          <CommunityCard
            key={id}
            communityId={id}
          />
        )))}
    </>
  );
};

PartsTasksContainer.propTypes = {
  // connected
  communityIdMap: PropTypes.object.isRequired,
  fetchTasks: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  teamId: idType.isRequired,
};

const mapStateToProps = state => ({
  communityIdMap: state.PartsTasks.communityIdMap || {},
  filter: state.PartsTasks.filter,
  teamId: state.Auth.currentTeam.id,
});

const mapDispatchToProps = {
  fetchTasks,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PartsTasksContainer);
