import { fetchRecords, SAVE_RECORD, saveRecord } from 'redux-json-api-module';
import toastr from 'toastr';
import { idComparison } from '../../helpers/string';

export const DESTROY_MULTIPLE = 'intimely/units/DESTROY_MULTIPLE';
export const FETCH_UNITS_SUCCESS = 'intimely/units/FETCH_UNITS_SUCCESS';
export const FETCH_COMMUNITIES_SUCCESS = 'intimely/units/FETCH_COMMUNITIES_SUCCESS';
export const FETCH_COMMUNITY_OPTIONS_SUCCESS = 'intimely/units/FETCH_COMMUNITY_OPTIONS_SUCCESS';

const INITIAL_STATE = {
  unitIds: [],
  nextPage: 1,
  communityOptions: [],
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_UNITS_SUCCESS:
      return {
        ...state,
        unitIds: action.reset ? action.unitIds : state.unitIds.concat(action.unitIds),
        nextPage: action.nextPage,
      };
    case FETCH_COMMUNITIES_SUCCESS:
      return {
        ...state,
        communityIds: action.communityIds,
      };
    case FETCH_COMMUNITY_OPTIONS_SUCCESS:
      return {
        ...state,
        communityOptions: action.communityOptions,
      };
    default:
      return state;
  }
}

export function fetchUnits(reset) {
  return (dispatch, getState) => {
    const page = reset ? 1 : getState().Units.nextPage || 1;
    return dispatch(fetchRecords('units', {
      page: {
        size: 500,
        number: page,
      },
      sort: 'street_address',
    }))
      .then((resp) => {
        if (resp.error) return resp;

        const unitIds = resp.payload.data.data.sort((a, b) => (
            a.attributes.street_address === null) - (b.attributes.street_address === null)
          || +(a.attributes.street_address > b.attributes.street_address)
          || -(a.attributes.street_address < b.attributes.street_address))
          .map(r => r.id);

        return dispatch({
          type: FETCH_UNITS_SUCCESS,
          unitIds,
          nextPage: page + 1,
          reset,
        });
      });
  };
}

export function destroyMultiple(ids) {
  return {
    type: DESTROY_MULTIPLE,
    payload: {
      request: {
        method: 'post',
        url: 'units/destroy_multiple',
        data: {
          ids,
        },
      },
    },
  };
}

export function saveImport(id) {
  return (dispatch) => {
    dispatch({
      type: SAVE_RECORD,
      payload: {
        request: {
          method: 'POST',
          url: `/unit_imports/${id}/save`,
        },
      },
    }).then(() => {
      window.location.href = '/units';
    });
  };
}

export function fetchCommunityOptions() {
  return dispatch => dispatch(fetchRecords('communities/options')).then((resp) => {
    if (resp.error) return resp;
    const communityOptions = resp.payload.data.community_options;

    return dispatch({
      type: FETCH_COMMUNITY_OPTIONS_SUCCESS,
      communityOptions,
    });
  });
}

export function fetchCommunities() {
  return dispatch => dispatch(fetchRecords('communities', { include: 'team_communities' }))
    .then((resp) => {
      if (resp.error) return resp;
      const communityIds = resp.payload.data.data.map(c => c.id);

      return dispatch({
        type: FETCH_COMMUNITIES_SUCCESS,
        communityIds,
      });
    });
}

function getTeamCommunity(teamCommunities, teamId, communityId) {
  return teamCommunities.find(tc => (
    idComparison(tc.attributes.team_id, teamId)
    && idComparison(tc.attributes.community_id, communityId)
  ));
}

export function saveCommunities(teamId, communityIds, initialSelected) {
  return (dispatch, getState) => {
    const team_communities_attributes = [];
    communityIds.forEach((communityId) => {
      if (!initialSelected.includes(communityId)) {
        team_communities_attributes.push({
          community_id: communityId,
        });
      }
    });

    initialSelected.forEach((communityId) => {
      if (!communityIds.includes(communityId)) {
        const teamCommunity = getTeamCommunity(
          Object.values(getState().Api.team_communities),
          teamId,
          communityId,
        );
        if (teamCommunity) {
          team_communities_attributes.push({
            id: teamCommunity.id,
            _destroy: true,
          });
        }
      }
    });

    const record = {
      type: 'teams',
      id: teamId,
      attributes: {
        team_communities_attributes,
      },
    };

    return dispatch(saveRecord(record, { params: { window_id: window.WINDOW_ID } }))
      .then((resp) => {
        if (resp.error) {
          toastr.error('Failed to update communities.');
        } else {
          toastr.success('Successfully updated communities for the team.');
        }

        return dispatch(fetchCommunities());
      });
  };
}
