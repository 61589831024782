import React from 'react';
import { connect } from 'react-redux';
import { getRecord, getRelationship } from 'redux-json-api-module';
import Avatar from '../../../components/Avatar';
import { genericRecordPropType, idPropType } from '../../../helpers/propTypes';

const UserCell = ({ user }) => (
  <>
    <Avatar
      {...user.attributes}
      className="avatar-xs d-inline-block mr-1"
    />
    {user.attributes.name}
  </>
);

UserCell.propTypes = {
  serviceInvoiceId: idPropType.isRequired,
  // connected
  user: genericRecordPropType.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const serviceInvoice = getRecord(state.Api,
    { type: 'service_invoices', id: ownProps.serviceInvoiceId });

  return {
    user: getRelationship(state.Api, serviceInvoice.relationships.maintenance_user),
  };
};

export default connect(
  mapStateToProps,
)(UserCell);
