import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const TYPE_TITLE_PAIRS = [
  ['', 'View All'],
  ['project_services', "Make Ready's"],
  ['work_order_tasks', 'Work Orders'],
];
const TYPE_TITLE_MAP = Object.fromEntries(TYPE_TITLE_PAIRS);

const Option = ({ title, selected, onClick }) => (
  <button
    type="button"
    className={`list-group-item w-100 d-flex justify-content-between align-items-center btn btn-link text-left ${selected ? 'active' : ''}`}
    onClick={onClick}
  >
    {title}
    {selected ? <i className="fa fa-chevron-right float-right m-l-5 mt-1" /> : null}
  </button>
);

Option.propTypes = {
  title: PropTypes.oneOf(Object.values(TYPE_TITLE_MAP)),
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

Option.defaultProps = {
  title: undefined,
};

const PlanTypeFilter = ({ value, className, onChange }) => (
  <div className={`dropdown ${className ?? ''}`}>
    <button
      className="btn btn-primary toggle-button"
      type="button"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {TYPE_TITLE_MAP[value]}
      <i className="fa fa-chevron-down spaced-icon" />
    </button>
    <div className="dropdown-menu scrollable-menu" aria-labelledby="dropdownMenuButton">
      {TYPE_TITLE_PAIRS.map(([type, title]) => (
        <Option
          key={title}
          title={title}
          selected={type === value}
          onClick={() => onChange(type)}
        />
      ))}
    </div>
  </div>
);

PlanTypeFilter.propTypes = {
  value: PropTypes.oneOf(Object.keys(TYPE_TITLE_MAP)).isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

PlanTypeFilter.defaultProps = {
  className: undefined,
};

const mapStateToProps = () => ({});

export default connect(
  mapStateToProps,
)(PlanTypeFilter);
