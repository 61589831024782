import React from 'react';
import PropTypes from 'prop-types';
import ListGroupItem from '../../TeamScheduleContainer/ScheduleSelector/ListGroupItem';

const CommunitySelector = ({ title, activeCommunity, setCommunity, communityOptions }) => (
  <span className="ml-2">
    <div className="dropdown">
      <button
        className="btn btn-primary toggle-button"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {title}
        <i className="fa fa-chevron-down spaced-icon" />
      </button>
      <div className="dropdown-menu scrollable-menu" aria-labelledby="dropdownMenuButton">
        <ListGroupItem selected={!activeCommunity} onClick={() => setCommunity(null)} key="all">
          All Communities
        </ListGroupItem>
        {communityOptions.map(community => (
          <ListGroupItem
            selected={activeCommunity && activeCommunity.id === community.id}
            onClick={() => setCommunity(community)}
            key={community.id}
          >
            {community.label}
          </ListGroupItem>
        ))}
      </div>
    </div>
  </span>
);

CommunitySelector.propTypes = {
  title: PropTypes.string.isRequired,
  activeCommunity: PropTypes.object,
  setCommunity: PropTypes.func.isRequired,
  communityOptions: PropTypes.array.isRequired,
};

CommunitySelector.defaultProps = {
  activeCommunity: null,
};

export default CommunitySelector;
