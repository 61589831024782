import React from 'react';
import PropTypes from 'prop-types';
import { NAME_MAP } from '../../helpers/types';

const CreatePlanCard = ({ planType }) => (
  <div className="card-body">
    <p>{`You don't have any ${NAME_MAP[planType]} yet.`}</p>
    <p>
      <a
        className="btn btn-success btn-lg"
        href="/projects/new"
      >
        Add Project
      </a>
    </p>
    <br />
  </div>
);

CreatePlanCard.propTypes = {
  planType: PropTypes.string,
};

CreatePlanCard.defaultProps = {
  planType: 'projects',
};

export default CreatePlanCard;
