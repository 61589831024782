import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchRecord, getRecord } from 'redux-json-api-module';
import Loader from '../../Loader';
import MessageList from '../../Messenger/MessageList';
import { fetchMessages } from '../../../redux/modules/chat';

const ChatTab = ({ fetchMessages, fetchRecord, messageThreadId, userId }) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetchRecord('message_threads', messageThreadId)
      .then(() => fetchMessages(messageThreadId))
      .finally(() => setLoading(false));
  }, [messageThreadId]);

  return loading ? (
    <Loader />
  ) : (
    <MessageList
      messageThreadId={messageThreadId}
      loading={loading}
      userId={userId}
    />
  );
};

ChatTab.propTypes = {};

const mapStateToProps = (state, ownProps) => {
  const plan = getRecord(state.Api, { type: ownProps.planType, id: ownProps.planId });

  return {
    messageThreadId: plan.relationships.message_thread.data.id,
    userId: state.Auth.currentUser.id,
  };
};

const mapDispatchToProps = {
  fetchMessages,
  fetchRecord,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChatTab);
