import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { toggleNewArea, createArea } from '../../redux/modules/inspections';

const NewAreaForm = ({
  onSubmit,
  toggleNewArea,
}) => (
  <Form
    onSubmit={onSubmit}
    render={({ pristine, handleSubmit, submitting }) => (
      <form onSubmit={handleSubmit} id="new_area">
        <div className="form-group row">
          <div className="col">
            <Field
              name="name"
              component="input"
              type="text"
              placeholder="Enter Area Name"
              className="form-control is-valid string required"
            />
          </div>
        </div>
        <div className="form-group row">
          {pristine ? null : (
            <input
              type="submit"
              value="Save"
              disabled={submitting}
              className={`btn btn-success m-l-10 animated ${pristine ? 'invisible' : 'visible fadeIn'}`}
            />
          )}
          <button
            type="button"
            value="Cancel"
            className="btn btn-secondary m-l-10 visible"
            onClick={() => {
              toggleNewArea(false);
            }}
          >
              Cancel
          </button>
        </div>
      </form>
    )}
  />
);

NewAreaForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  toggleNewArea: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  toggleNewArea,
  onSubmit: createArea,
};

export default connect(null, mapDispatchToProps)(NewAreaForm);
