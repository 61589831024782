import React from 'react';
import PropTypes from 'prop-types';
import { idType } from '../helpers/propTypes';
import Alert from './Alert';

const ApproveCompletionButton = ({ planId, title, reviewable, approveCompletion }) => (
  <React.Fragment>
    {reviewable && (
      <Alert
        type="info"
        iconClassName="fa fa-exclamation-circle"
        title={`Please Approve Completion of ${title}`}
      >
        {`You may add more services if needed, or you may click “Approve Completion” below to permanently mark this ${title} as completed.`}
      </Alert>
    )}
    <button
      type="button"
      className="btn btn-primary btn-lg"
      disabled={!reviewable}
      onClick={() => approveCompletion(planId)}
    >
      Approve Completion
    </button>
  </React.Fragment>
);

ApproveCompletionButton.propTypes = {
  planId: idType.isRequired,
  title: PropTypes.string.isRequired,
  reviewable: PropTypes.bool.isRequired,
  approveCompletion: PropTypes.func.isRequired,
};

export default ApproveCompletionButton;
