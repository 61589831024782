import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';
import WorkOrderManagementContainer
  from '../containers/WorkOrderManagementContainer/WorkOrderManagementContainer';

const WorkOrderManagement = props => (
  <Provider store={store}>
    <WorkOrderManagementContainer {...props} />
  </Provider>
);

export default WorkOrderManagement;
