import moment from 'moment';
import { fetchRecords } from 'redux-json-api-module';
import { CALENDAR_EVENT_TYPE_MAP } from '../../helpers/types';

export const FETCH_PLANS_SUCCESS = 'intimely/teamSchedule/FETCH_PLANS_SUCCESS';
export const SET_SELECTOR_VALUE = 'intimely/teamSchedule/SET_SELECTOR_VALUE';
export const SET_EVENTS = 'intimely/teamSchedule/SET_EVENTS';
export const SET_TEMPORARY_EVENTS = 'intimely/teamSchedule/SET_TEMPORARY_EVENTS';
export const SET_LOADING = 'intimely/teamSchedule/SET_LOADING';
export const SET_DATE_RANGE = 'intimely/teamSchedule/SET_DATE_RANGE';
export const SET_VIEW = 'intimely/teamSchedule/SET_VIEW';
export const SET_PLAN_TYPE = 'intimely/teamSchedule/SET_PLAN_TYPE';
export const SET_ACTIVE_TASK = 'intimely/teamSchedule/SET_ACTIVE_TASK';
export const SET_SECONDARY_FILTER = 'intimely/teamSchedule/SET_SECONDARY_FILTER';
export const HYDRATE_TEAM_SCHEDULE_STORE = 'intimely/teamSchedule/HYDRATE_TEAM_SCHEDULE_STORE';
export const SET_SELECTED = 'intimely/teamSchedule/SET_SELECTED';
export const SET_PLAN = 'intimely/teamSchedule/SET_PLAN';
export const SET_SCHEDULED_REF = 'intimely/teamSchedule/SET_SCHEDULED_REF';
export const SET_FORM_VISIBLE = 'intimely/teamSchedule/SET_FORM_VISIBLE';

export const SCHEDULE_VIEW_CALENDAR = 'calendar';
export const SCHEDULE_VIEW_GANTT = 'gantt';

export const SECONDARY_FILTERS = {
  all: 'View Full Team Schedule',
  community: 'View By Project',
  team_member: 'View By Team Member',
  service: 'View By Service',
};

export const SELECTED_MAP = {
  community: 'activeCommunity',
  team_member: 'activeUser',
  service: 'activeService',
};

export const OPTIONS = {
  community_all: 'allCommunityOptions',
  community_projects: 'projectCommunityOptions',
  community_work_orders: 'workOrderCommunityOptions',
  team_member: 'userOptions',
  service: 'serviceOptions',
};

const INITIAL_STATE = {
  plans: [],
  communityIds: [],
  events: [],
  objectType: null,
  objectId: null,
  start: moment()
    .startOf('month')
    .subtract(7, 'days')
    .toDate(),
  end: moment()
    .endOf('month')
    .add(7, 'days')
    .toDate(),
  loading: false,
  view: SCHEDULE_VIEW_CALENDAR,
  planType: 'all',
  secondaryFilter: null,
  activeCommunity: null,
  activeService: null,
  activeUser: null,
  activePlan: null,
  projectOptions: [],
  workOrderOptions: [],
  allCommunityOptions: [],
  projectCommunityOptions: [],
  workOrderCommunityOptions: [],
  userOptions: [],
  serviceOptions: [],
  temporaryEvents: {},
  scheduledRef: null,
  formVisible: false,
  activeTask: null,
  userId: null,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HYDRATE_TEAM_SCHEDULE_STORE:
      return { ...INITIAL_STATE, ...action.state };

    case FETCH_PLANS_SUCCESS:
      return {
        ...state,
        communityIds: action.communityIds,
        plans: action.plans,
      };

    case SET_SELECTOR_VALUE:
      return {
        ...state,
        objectType: action.objectType,
        objectId: action.objectId,
      };

    case SET_EVENTS:
      return {
        ...state,
        events: action.events,
        temporaryEvents: {},
      };

    case SET_TEMPORARY_EVENTS:
      return {
        ...state,
        temporaryEvents: action.temporaryEvents,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    case SET_ACTIVE_TASK:
      return {
        ...state,
        activeTask: action.activeTask,
        userId: action.userId,
      };

    case SET_DATE_RANGE:
      return {
        ...state,
        start: action.start,
        end: action.end,
      };

    case SET_VIEW:
      return {
        ...state,
        view: action.view,
      };

    case SET_PLAN_TYPE:
      return {
        ...state,
        planType: action.planType,
        activeCommunity: null,
        activeService: null,
        activeUser: null,
        activePlan: null,
        secondaryFilter: null,
        userId: null,
        activeTask: null,
      };

    case SET_SCHEDULED_REF:
      return {
        ...state,
        scheduledRef: action.scheduledRef,
      };

    case SET_SECONDARY_FILTER:
      return {
        ...state,
        secondaryFilter: action.secondaryFilter,
        activeCommunity: action.activeCommunity,
        activeService: action.activeService,
        activeUser: action.activeUser,
        userId: null,
        activeTask: null,
      };

    case SET_SELECTED:
      return {
        ...state,
        activeCommunity: action.activeCommunity,
        activeService: action.activeService,
        activeUser: action.activeUser,
        userId: null,
        activeTask: null,
      };

    case SET_PLAN:
      return {
        ...state,
        activePlan: action.activePlan,
        userId: null,
        activeTask: null,
      };

    case SET_FORM_VISIBLE:
      return {
        ...state,
        formVisible: action.formVisible,
      };

    default:
      return state;
  }
}

export const hydrateTeamScheduleStore = state => (
  {
    type: HYDRATE_TEAM_SCHEDULE_STORE,
    state,
  }
);

export const setEvents = events => (
  {
    type: SET_EVENTS,
    events,
  }
);

export const setTemporaryEvents = temporaryEvents => (
  {
    type: SET_TEMPORARY_EVENTS,
    temporaryEvents,
  }
);


export const setLoading = loading => (
  {
    type: SET_LOADING,
    loading,
  }
);

export function setFormVisible(formVisible) {
  return {
    type: SET_FORM_VISIBLE,
    formVisible,
  };
}

export function setScheduledRef(scheduledRef) {
  return {
    type: SET_SCHEDULED_REF,
    scheduledRef,
  };
}


function getUrlPrefix(parent) {
  if (!parent) return '';

  return `${parent.type}/${parent.id}/`;
}

function fetchProjectServiceEvents(parent, options) {
  return dispatch => (dispatch(fetchRecords(`${getUrlPrefix(parent)}calendar_events`, options)));
}

function fetchWorkOrderTaskEvents(parent, options) {
  return dispatch => (dispatch(fetchRecords(`${getUrlPrefix(parent)}work_order_calendar_events`, options)));
}

function getFetches(parent, planType, options, dispatch) {
  const fetches = [];

  const isWorkOrderPlanType = planType === 'all' || planType === 'work_orders';
  const hasWorkOrderParentType = CALENDAR_EVENT_TYPE_MAP[parent?.type] !== 'calendar_events';

  if (isWorkOrderPlanType && (!parent || hasWorkOrderParentType)) {
    const updatedOptions = { ...options, include: 'user,work_order,unit,service' };
    fetches.push(dispatch(fetchWorkOrderTaskEvents(parent, updatedOptions)));
  }

  const isProjectPlanType = planType === 'all' || planType === 'projects';
  const hasProjectParentType = CALENDAR_EVENT_TYPE_MAP[parent?.type] !== 'work_order_calendar_events';

  if (isProjectPlanType && (!parent || hasProjectParentType)) {
    const updatedOptions = { ...options, include: 'user,project,unit,service' };
    fetches.push(dispatch(fetchProjectServiceEvents(parent, updatedOptions)));
  }

  return fetches;
}

export function fetch(parent, planType, options) {
  return (dispatch, getState) => Promise.all(getFetches(parent, planType, options, dispatch))
    .then((responses) => {
      if (responses.some(r => r.error)) return responses.find(r => r.error).error;

      const events = responses.map(resp => resp.payload.data.data).flat();

      return getState().TeamSchedule.formVisible
        ? Promise.resolve({}) : dispatch(setEvents(events));
    })
    .finally(() => dispatch(setLoading(false)));
}

function getParent(activeCommunity, activePlan, activeService, activeUser) {
  if (activeUser) {
    return { type: 'users', id: activeUser.id };
  } if (activePlan && activeCommunity) {
    return { type: activePlan.plan_type, id: activePlan.id };
  } if (activeService) {
    return { type: 'services', id: activeService.id };
  } if (activeCommunity) {
    return { type: 'communities', id: activeCommunity.id };
  }

  return null;
}

export function fetchEvents(passive) {
  return (dispatch, getState) => {
    const {
      planType,
      activeCommunity,
      activePlan,
      activeService,
      activeUser,
      start,
      end,
    } = getState().TeamSchedule;

    const parent = getParent(activeCommunity, activePlan, activeService, activeUser);

    const options = {
      filter: {
        scope: 'scheduled',
        date_min: start || moment()
          .startOf('month')
          .subtract(7, 'days')
          .toDate(),
        date_max: end || moment()
          .endOf('month')
          .add(7, 'days')
          .toDate(),
      },
      order: 'started_at',
      page: { size: 1000 },
    };

    if (!passive) dispatch(setLoading(true));

    return dispatch(fetch(parent, planType, options));
  };
}

export const setPlanType = planType => (
  dispatch => (
    Promise.resolve(dispatch({
      type: SET_PLAN_TYPE,
      planType,
    })).then(() => dispatch(fetchEvents()))
  )
);

export function setActiveTask(activeTask, userId) {
  return {
    type: SET_ACTIVE_TASK,
    activeTask,
    userId,
  };
}

export const setSelected = (key, selected) => (dispatch) => {
  const selectedValues = {
    activeCommunity: null,
    activeService: null,
    activeUser: null,
  };

  selectedValues[key] = selected;

  return Promise.resolve(dispatch({
    type: SET_SELECTED,
    ...selectedValues,
  })).then(() => dispatch(fetchEvents()));
};

export const setPlan = activePlan => (
  dispatch => (
    Promise.resolve(dispatch({
      type: SET_PLAN,
      activePlan,
    })).then(() => dispatch(fetchEvents()))
  )
);

export const setSecondaryFilter = secondaryFilter => (dispatch, getState) => {
  const optionsKey = secondaryFilter === 'community'
    ? `community_${getState().TeamSchedule.planType}` : secondaryFilter;

  const options = OPTIONS[optionsKey];
  const selectedValues = {
    activeCommunity: null,
    activeService: null,
    activeUser: null,
    activePlan: null,
  };

  const key = SELECTED_MAP[secondaryFilter];

  const availableOptions = getState().TeamSchedule[options];

  selectedValues[key] = availableOptions ? availableOptions[0] : null;

  return Promise.resolve(dispatch({
    type: SET_SECONDARY_FILTER,
    secondaryFilter,
    ...selectedValues,
  })).then(() => (
    dispatch(fetchEvents())
  ));
};

export const setDateRange = ({ start, end }) => (
  {
    type: SET_DATE_RANGE,
    start: start,
    end: end,
  }
);

export const setView = view => (
  {
    type: SET_VIEW,
    view,
  }
);

export function handleRangeChange(range) {
  return (dispatch) => {
    dispatch(setDateRange(range));
    dispatch(fetchEvents(false));
  };
}
