/* eslint no-underscore-dangle: ["error", { "allow": ["__isNew__"] }] */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import CreatableSelect from 'react-select/creatable';
import { fetchRecords } from 'redux-json-api-module';
import submit from './submit';
import validate from './validate';
import { itemOptions, pricePerPart } from './helpers';
import Alert from '../../../components/Alert';
import DropFilesInput from '../../../components/Inputs/DropFilesInput/DropFilesInput';
import AttachmentsTable from './AttachmentsTable';

const initialValues = {
  item: null,
  quantity: 1,
  supplier: '',
  total: 0,
  nonInventory: false,
  attachments: [],
};

const AddInventoryForm = ({ show, onHide, items, defaultItem }) => {
  if (defaultItem) {
    // eslint-disable-next-line no-console
    initialValues.item = { value: defaultItem.id, label: defaultItem.attributes.name };
    initialValues.nonInventory = !defaultItem.attributes.is_inventory;
  }

  return (
    <Modal show={show} size="lg" onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Add Inventory</Modal.Title>
      </Modal.Header>

      <Form
        initialValues={initialValues}
        onSubmit={submit}
        validate={validate}
        mutators={{
          ...arrayMutators,
          // expect (field, value) args from the mutator
          setValue: ([field, value], state, { changeValue }) => {
            changeValue(state, field, () => value);
          },
        }}
        render={({ handleSubmit, reset, form, submitting, values }) => (
          <form onSubmit={event => handleSubmit(event)?.then(onHide)?.then(reset)}>
            <Modal.Body>
              <div className="row">
                <div className="col-lg-6">
                  <Field name="item">
                    {({ input, meta }) => (
                      <div className="form-group">
                        <label>Part Name</label>
                        <CreatableSelect
                          id="select-item"
                          {...input}
                          onChange={(val) => {
                            if (val.__isNew__) {
                              form.mutators.setValue('nonInventory', false);
                            } else {
                              const item = items[val.value];
                              form.mutators.setValue(
                                'nonInventory',
                                // eslint-disable-next-line camelcase
                                !item?.attributes?.is_inventory,
                              );
                            }

                            return input.onChange(val);
                          }}
                          placeholder="Search or create part..."
                          options={itemOptions(Object.values(items))}
                          classNamePrefix="items"
                        />
                        {meta.error && meta.touched && <span>{meta.error}</span>}
                      </div>
                    )}
                  </Field>
                  {values.item?.__isNew__ ? (
                    <>
                      <Field name="nonInventory" type="checkbox">
                        {({ input }) => (
                          <label>
                            <input {...input} type="checkbox" className="form-check-inline" />
                            Non-Inventory Part
                          </label>
                        )}
                      </Field>
                      <Alert type="info">
                        Check this box if this Part will not be tracked in your inventory. For
                        example, you may add &quot;Laminate Flooring&quot; as a Non-Inventory Part,
                        to allow Make Ready&apos;s or Work Orders to assign &quot;Laminate
                        Flooring&quot; as needing service but you will not keep track of any
                        &quot;Laminate Flooring&quot; inventory. Conversely, you may add
                        &quot;Laminate Planks&quot; and &quot;Laminate Padding&quot; as Inventory
                        Parts, and you will track the quantity used and quantity available for these
                        parts.
                      </Alert>
                    </>
                  ) : null}
                  <Field name="quantity">
                    {({ input, meta }) => (
                      <div className="form-group">
                        <label>Quantity</label>
                        <input
                          {...input}
                          type="number"
                          className="form-control"
                          step={1}
                          min={0}
                          max={999}
                          disabled={values.nonInventory}
                        />
                        {meta.error && meta.touched && <span>{meta.error}</span>}
                      </div>
                    )}
                  </Field>
                  <Field name="supplier">
                    {({ input, meta }) => (
                      <div className="form-group">
                        <label>Supplier</label>
                        <input
                          {...input}
                          type="text"
                          className="form-control"
                          disabled={values.nonInventory}
                        />
                        {meta.error && meta.touched && <span>{meta.error}</span>}
                      </div>
                    )}
                  </Field>
                  <Field name="total">
                    {({ input, meta }) => (
                      <div className="form-group">
                        <label>Order Total</label>
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">$</span>
                          </div>
                          <input
                            {...input}
                            type="number"
                            className="form-control"
                            step={0.01}
                            min={0.0}
                            disabled={values.nonInventory}
                          />
                        </div>
                        {meta.error && meta.touched && <span>{meta.error}</span>}
                      </div>
                    )}
                  </Field>
                  <div className="form-group">
                    <label>Price Per Part</label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text">$</span>
                      </div>
                      <input
                        value={pricePerPart(values.total, values.quantity)}
                        disabled
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <FieldArray name="attachments">
                    {({ fields }) => (
                      <div className="form-group">
                        <label>Purchase Order Attachments</label>
                        <DropFilesInput
                          disabled={values.nonInventory}
                          onDrop={(acceptedFiles) => {
                            acceptedFiles.forEach(file => fields.push({ file }));
                          }}
                        />
                        <AttachmentsTable fields={fields} />
                      </div>
                    )}
                  </FieldArray>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {submitting && <i className="fa fa-spinner fa-pulse text-primary" />}
              <Button
                variant="secondary"
                onClick={() => {
                  form.reset();
                  onHide();
                }}
                disabled={submitting}
              >
                Close
              </Button>
              <Button
                variant="primary"
                type="submit"
                disabled={submitting}
              >
                Save changes
              </Button>
            </Modal.Footer>
          </form>
        )}
      />
    </Modal>
  );
};


AddInventoryForm.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  defaultItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    attributes: PropTypes.shape({
      name: PropTypes.string.isRequired,
      is_inventory: PropTypes.bool.isRequired,
    }).isRequired,
  }),

  // connected
  items: PropTypes.objectOf(PropTypes.any).isRequired,
};

AddInventoryForm.defaultProps = {
  show: false,
  defaultItem: null,
};

const mapStateToProps = state => ({
  items: state.Api.items || {},
});

const mapDispatchToProps = {
  fetchRecords,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddInventoryForm);
