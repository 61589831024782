import React from 'react';
import PropTypes from 'prop-types';
import ValidationIcon from '../../../WorkOrderImportContainer/WorkOrderReview/ValidationIcon';
import { CELL_CLASSES, WIDTHS } from '../../helpers';
import './styles.scss';

const ImportTable = ({ rows, textAttributes, getAttributeValue }) => (
  <div className="scrollable-table-wrapper">
    <table className="table scrollable-table work-order-import-table">
      <thead>
        <tr>
          <th key="street_address" style={{ width: '250px' }}>Street Address</th>
          {textAttributes.map(attr => (
            <th key={attr} style={WIDTHS[attr] ? { width: WIDTHS[attr] } : {}}>{attr}</th>
          ))}
        </tr>
      </thead>

      <tbody>
        {rows.map(row => (
          <tr className="v-middle">
            <td>
              <div className="d-flex">
                <ValidationIcon valid={row.valid} />
                <div className="my-auto">
                  {getAttributeValue(row, 'Street Address')}
                </div>
              </div>
            </td>
            {textAttributes.map(attr => (
              <td key={attr} className={CELL_CLASSES[attr]}>
                {getAttributeValue(row, attr)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);


ImportTable.propTypes = {
  rows: PropTypes.array.isRequired,
  textAttributes: PropTypes.arrayOf(PropTypes.string).isRequired,
  getAttributeValue: PropTypes.func.isRequired,
};

export default ImportTable;
