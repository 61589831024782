import React from 'react';
import PropTypes from 'prop-types';
import Alert from '../../../components/Alert';

const ActiveAlert = ({ title }) => (
  <Alert
    type="success"
    iconClassName="fa fa-exclamation-circle"
    title={`${title} Started!`}
  >
    {`Your ${title} has been inspected, reviewed, assigned and scheduled! Intimely will
    notify each provider about their assignments, work dates, and when to begin work.
    You’ll see each provider’s status updates, work notes, and completion pictures
    in one place.`}
  </Alert>
);

const AssignAlert = ({ title }) => (
  <Alert
    type="danger"
    iconClassName="fa fa-exclamation-circle"
    title={`Please Assign Services & Activate ${title}`}
  >
    {`Please assign a maintenance provider for each Service, schedule their work date(s),
    and activate the ${title.toLowerCase()} to begin work.`}
  </Alert>
);

const ReviewAlert = ({ title }) => (
  <Alert
    type="info"
    title={`Please Approve ${title} Completion`}
  >
    {`All services are completed!  Please review the service completion details,
    and then approve the ${title.toLowerCase()} as completed.  Great job, team!`}
  </Alert>
);

const CompletedAlert = ({ title }) => (
  <Alert
    type="success"
    iconClassName="fa fa-check-circle"
    title={`This ${title} Has Been Completed!`}
  >
    All services have been completed and accepted. Great teamwork, everybody!
  </Alert>
);

const AlertMap = {
  assign: AssignAlert,
  review: ReviewAlert,
  completed: CompletedAlert,
};

const StateAlert = ({ transitionedToActive, aasmState, title }) => {
  if (transitionedToActive && aasmState === 'active') {
    return <ActiveAlert title={title} />;
  }

  const AlertComponent = AlertMap[aasmState];

  return AlertComponent ? <AlertComponent title={title} /> : null;
};

StateAlert.propTypes = {
  transitionedToActive: PropTypes.bool,
  aasmState: PropTypes.string.isRequired,
  title: PropTypes.string,
};

StateAlert.defaultProps = {
  transitionedToActive: false,
  title: 'Project',
};

export default StateAlert;
