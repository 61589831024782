import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord, getRelationship } from 'redux-json-api-module';
import get from 'lodash.get';
import COLORS from '../../helpers/colors';
import { getMessenger, setActiveMessageThread } from '../../redux/modules/chat';
import { idType } from '../../helpers/propTypes';
import ChannelUnreadCount from './ChannelUnreadCount';

const ChannelLink = ({
  messageThread,
  active,
  setActiveMessageThread,
  userId,
  channelId,
  name,
  isPublic,
  threadsList,
  onShowUnread,
  onHideUnread,
  unreadCount,
}) => {
  const link = useRef(null);

  const handleScroll = () => {
    if (unreadCount === 0) return onHideUnread(messageThread.id);

    if (link.current.getBoundingClientRect().bottom < threadsList.current.offsetTop) {
      return onShowUnread(messageThread.id, 'top');
    }

    if (link.current.getBoundingClientRect().top > window.innerHeight) {
      return onShowUnread(messageThread.id, 'bottom');
    }

    return onHideUnread(messageThread?.id);
  };

  useEffect(() => {
    threadsList.current.addEventListener('scroll', handleScroll);
    handleScroll();
  }, [unreadCount]);

  return (
    <div onScroll={handleScroll}>
      <button
        ref={link}
        id={`channel-link-${channelId}`}
        type="button"
        className="mt-1 btn btn-link text-left w-100"
        style={{
          marginLeft: '6px',
          color: COLORS.blue,
        }}
        onClick={() => {
          setActiveMessageThread({
            planType: null,
            planId: null,
            taskType: null,
            taskId: null,
            directMessageChatId: null,
            channelId,
            messageThreadId: messageThread.id,
          });
        }}
      >
        <div className="row">
          <div className="col-1 my-auto pl-0">
            <ChannelUnreadCount
              messageThreadId={messageThread && messageThread.id}
              userId={userId}
              active={active}
              isPublic={isPublic}
            />
          </div>
          <div className={`col-10 pl-0 ml-3 my-auto ${active ? 'font-weight-bold' : ''}`}>
            {name}
          </div>
        </div>
      </button>
    </div>
  );
};

ChannelLink.propTypes = {
  messageThread: PropTypes.object,
  active: PropTypes.bool.isRequired,
  setActiveMessageThread: PropTypes.func.isRequired,
  userId: idType.isRequired,
  channelId: idType.isRequired,
  name: PropTypes.string.isRequired,
  isPublic: PropTypes.bool.isRequired,
};

ChannelLink.defaultProps = {
  messageThread: null,
};

const mapStateToProps = (state, ownProps) => {
  const channel = getRecord(state.Api, { type: 'chat_channels', id: ownProps.channelId });
  if (!channel) return {};

  const messageThread = getRelationship(state.Api, channel.relationships.message_thread);
  const messenger = getMessenger(state, messageThread.id, ownProps.userId);

  const active = state.Chat.activeChannelId
    ? ownProps.channelId.toString() === state.Chat.activeChannelId.toString() : false;

  return {
    name: channel.attributes.name,
    messageThread,
    active,
    isPublic: get(channel, 'attributes.public', false),
    unreadCount: get(messenger, 'attributes.unread_count', 0),
  };
};

const mapDispatchToProps = {
  setActiveMessageThread,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChannelLink);
