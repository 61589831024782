import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRelationship } from 'redux-json-api-module';
import { Dropdown } from 'react-bootstrap';
import Alert from '../../../components/Alert';
import { approveCompletionProject } from '../../../redux/modules/projectManagement';

const ApproveCompletionButton = ({ project, reviewable, approveCompletionProject }) => (
  <>
    {reviewable ? (
      <Alert
        type="info"
        iconClassName="fa fa-exclamation-circle"
        title="Please Approve Completion of Project"
      >
        You may add more services if needed, or you may click “Approve Completion” below to
        permanently mark this Project as completed.
      </Alert>
    ) : null}
    <Dropdown>
      <Dropdown.Toggle variant="primary" size="lg">
        Approve Completion
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href={`/projects/${project.id}/review`}>
          Review Detailed Project Summary
        </Dropdown.Item>
        <Dropdown.Item onClick={() => approveCompletionProject(project.id)}>
          Skip and Mark Approved Now
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  </>
);

ApproveCompletionButton.propTypes = {
  project: PropTypes.objectOf(PropTypes.any).isRequired,

  // connected
  reviewable: PropTypes.bool.isRequired,
  approveCompletionProject: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const projectServices = getRelationship(
    state.Api,
    ownProps.project.relationships.project_services,
  );

  const reviewable = projectServices.some(ps => (
    ps.attributes.completed_at === null
    || ps.attributes.aasm_state !== 'completed'
  ));

  return {
    reviewable,
  };
};

const mapDispatchToProps = {
  approveCompletionProject,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApproveCompletionButton);
