import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';
import LayoutContainer from '../containers/LayoutContainer/LayoutContainer';

const Layout = props => (
  <Provider store={store}>
    <LayoutContainer {...props} />
  </Provider>
);

export default Layout;
