import React from 'react';
import Loader from '../Loader';

const UploadingAttachment = () => (
  <div style={{
    width: '150px',
    height: '150px',
  }}>
    <Loader />
  </div>
)

export default UploadingAttachment;
