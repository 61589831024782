import React from 'react';
import PropTypes from 'prop-types';
import { taggableUserObject } from '../../helpers/propTypes';
import Body from './Body';
import Attachment from './Attachment';
import UploadingAttachment from './UploadingAttachment';

const SimpleMessage = ({
  messageId,
  body,
  taggableUsers,
  isAuthor,
  attachment,
}) => {
  let AttachmentComponent;

  if (attachment && attachment.url) {
    AttachmentComponent = <Attachment attachment={attachment} isAuthor={isAuthor} />;
  } else if (attachment && attachment.uploading) {
    AttachmentComponent = <UploadingAttachment />;
  }

  return (
    <>
      {body ? (
        <Body
          messageId={messageId}
          text={body}
          taggableUsers={taggableUsers}
          isAuthor={isAuthor}
        />
      ) : null}
      {AttachmentComponent}
    </>
  );
};

SimpleMessage.propTypes = {
  body: PropTypes.string,
  isAuthor: PropTypes.bool.isRequired,
  messageId: PropTypes.string.isRequired,
  attachment: PropTypes.object,
  taggableUsers: PropTypes.arrayOf(taggableUserObject).isRequired,
};

SimpleMessage.defaultProps = {
  body: null,
  attachment: null,
};

export default SimpleMessage;
