import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types'
import { fetchRecords } from 'redux-json-api-module';
import ServiceRow from './ServiceRow';
import { idType } from '../../../helpers/propTypes';

const ServicesTable = ({
  serviceIds,
  fetchRecords
}) => {
  useEffect(() => {
    fetchRecords('services', { page: { size: 250 } })
  }, []);

  return (
    <div>
      <table className="table table-borderless table-sm summary-table">
        <thead>
          <tr>
            <th></th>
            <th>Services</th>
          </tr>
        </thead>
        <tbody>
          {serviceIds.map(serviceId => (
            <ServiceRow serviceId={serviceId} key={serviceId} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

ServicesTable.propTypes = {
  serviceIds: PropTypes.arrayOf(idType).isRequired,
  fetchRecords: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  serviceIds: Object.keys(state.Api.services || {})
});

const mapDispatchToProps = {
  fetchRecords
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServicesTable);