import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select, { components, createFilter } from 'react-select';
import TextField from './TextField';
import { Error } from '../../containers/ProjectCreateContainer/ProjectCreateForm/inputs';

const CommunityOption = props => (
  <components.Option {...props}>
    <CommunityDisplay {...props.data} />
  </components.Option>
);

const CommunityDisplay = ({ label }) => (
  <div className="community__option">
    {label}
  </div>
);

CommunityDisplay.propTypes = {
  label: PropTypes.string.isRequired,
};

class CommunitySelector extends Component {
  static propTypes = {
    input: PropTypes.object.isRequired,
    options: PropTypes.array.isRequired,
    errors: PropTypes.object,
    required: PropTypes.bool,
    canCreate: PropTypes.bool,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    removeSpacing: PropTypes.bool,
  };

  static defaultProps = {
    errors: {},
    required: false,
    canCreate: true,
    placeholder: 'Select community',
    onChange: null,
    removeSpacing: false,
  };

  state = {
    createSelected: false,
  };

  handleChange = (community) => {
    const { input, onChange } = this.props;
    if (community) {
      this.setState({ createSelected: false });
    }
    input.onChange(community);
    if (onChange) onChange(community);
  };

  toggleCreate = (createSelected) => {
    const { input } = this.props;
    input.onChange('');
    this.setState({ createSelected });
  };

  render() {
    const {
      input,
      options,
      required,
      menuPortalTarget,
      errors,
      canCreate,
      placeholder,
      removeSpacing,
    } = this.props;

    const { createSelected } = this.state;

    return (
      <React.Fragment>
        {options.length > 0 ? (
          <div className="form-group row">
            {!removeSpacing ? (
              <label className="col-sm-3 col-form-label text-md-right">
                Select Community
                {required ? <abbr title="required">*</abbr> : null}
              </label>
            ) : null}
            <div className="col-sm-9">
              <div className="community-select__container">
                <Select
                  isDisabled={createSelected}
                  classNamePrefix="community-select"
                  className="community-select"
                  value={input.value}
                  components={{ Option: CommunityOption }}
                  onChange={this.handleChange}
                  styles={{
                    menuPortal: (base) => {
                      const { zIndex, ...rest } = base;
                      return {
                        ...rest,
                        zIndex: 9999,
                      };
                    },
                  }}
                  options={options}
                  placeholder={placeholder}
                  menuPortalTarget={menuPortalTarget}
                  ignoreAccents={false}
                  filterOption={createFilter({
                    ignoreAccents: false,
                    matchFrom: 'any',
                    stringify: option => `${option.label}`,
                  })}
                />
                {errors['unit.community.name'] ? errors['unit.community.name'].map(error => (
                  <Error
                    text={error}
                    key={error}
                  />
                )) : null}
              </div>
              {canCreate ? (
                <label className="form-label text-md-right">
                  <input
                    id="create-community"
                    type="checkbox"
                    checked={createSelected}
                    style={{
                      marginTop: '10px',
                      marginRight: '5px',
                    }}
                    onChange={() => this.toggleCreate(!createSelected)}
                  />
                  Create New Community
                </label>
              ) : null}
            </div>
          </div>
        ) : null}

        {createSelected || options.length === 0 ? (
          <TextField
            label="Community Name"
            name="community_name"
            errors={errors['unit.community.name']}
          />
        )
          : null}
      </React.Fragment>
    );
  }
}

export default CommunitySelector;
