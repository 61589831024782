import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import moment from 'moment';
import overlayFactory from 'react-bootstrap-table2-overlay';
import isEqual from 'lodash.isequal';
import PartsUsageHistoryTable from './PartsUsageHistoryTable';
import { genericRecordPropType, idType } from '../../../helpers/propTypes';
import ExpandColumn from '../../../components/Tables/ExpandColumn';

const columns = [
  {
    dataField: 'name',
    text: 'Part Name',
    sort: true,
  },
  {
    dataField: 'updatedAt',
    text: 'Last Updated',
    sort: true,
    type: 'date',
    align: 'right',
    headerStyle: { width: '200px' },
  },
];

const defaultSorted = [{
  dataField: 'name',
  order: 'asc',
}];

const NoDataIndication = () => (
  <div className="text-center">No Inventory</div>
);
const ExpandHeaderColumn = () => null;

const ExpandRow = ({ id }) => (
  <PartsUsageHistoryTable itemId={id} />
);

ExpandRow.propTypes = {
  id: idType.isRequired,
};

const expandRow = {
  showExpandColumn: true,
  onlyOneExpanding: true,
  expandHeaderColumnRenderer: ExpandHeaderColumn,
  expandColumnRenderer: ExpandColumn,
  renderer: ExpandRow,
};

const PartsUsageTable = React.memo(({ items }) => {
  const data = Object.values(items)
    .map(item => ({
      id: item.id,
      name: item.attributes.name,
      updatedAt: moment(item.attributes.updated_at)
        .format('MM/DD/YY'),
    }));

  const Overlay = overlayFactory({ spinner: true });

  return (
    <BootstrapTable
      keyField="id"
      data={data}
      rowClasses={r => (`row-${r.id}`)}
      columns={columns}
      defaultSorted={defaultSorted}
      expandRow={expandRow}
      overlay={Overlay}
      noDataIndication={NoDataIndication}
    />
  );
}, (prevProps, nextProps) => (
  isEqual(prevProps.items, nextProps.items)
));

PartsUsageTable.propTypes = {
  items: PropTypes.objectOf(genericRecordPropType).isRequired,
};

const mapStateToProps = state => ({
  items: state.Api.items || {},
});

export default connect(
  mapStateToProps,
)(PartsUsageTable);
