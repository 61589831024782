import React from 'react';
import PropTypes from 'prop-types';

const InspectionSummaryTab = ({ planId, planType }) => (
  <div style={{ paddingTop: '30px' }}>
    <h3 className="border-bottom pb-2 mb-3">Inspection</h3>
    <div>
      <a
        className="btn btn-success green btn-wrap btn-fixed-size mr-3"
        href={`/${planType}/${planId}/inspection`}
        target="_blank"
      >
        View Inspection Online
      </a>
    </div>
  </div>
);

InspectionSummaryTab.propTypes = {
  planId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  planType: PropTypes.string.isRequired,
};

export default InspectionSummaryTab;
