import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import MaintenanceSelect from '../../../components/MaintenanceSelect';
import './ProjectCreateForm.scss';

export const Hint = ({ text }) => <small className="form-text text-muted">{text}</small>;
Hint.propTypes = {
  text: PropTypes.string.isRequired,
};

const ErrorMessageMap = {
  'can\'t be blank': 'Please complete this field',
  'Must exist': 'Please complete this field',
  'must exist': 'Please complete this field',
};

export const Error = ({ text }) => {
  const message = ErrorMessageMap[text] || text;
  return <small className="form-text error text-capitalize">{message}</small>;
};

Error.propTypes = {
  text: PropTypes.string.isRequired,
};

export const DateInput = ({
  input,
  label,
  hint,
  minDate,
  maxDate,
  optional,
  errors,
  warning,
}) => (
  <div className={`form-group row overflow-visible ${input.name}`}>
    <label className="col-sm-3 col-form-label text-md-right">
      {`${label} `}
      {optional ? null : <abbr title="required">*</abbr>}
    </label>
    <div className="col-sm-9">
      <DatePicker
        selected={input.value ? input.value : null}
        onChange={input.onChange}
        className="form-control"
        minDate={minDate}
        maxDate={maxDate}
        id={input.name}
      />
      {hint ? <Hint text={hint} /> : null}
      {warning ? <Error text={warning} key="warning" /> : null}
      {errors ? errors.map(error => <Error text={error} key={error} />) : null}
    </div>
  </div>
);

export const UserSelect = ({ input, label, hint, options, service, user }) => (
  <div className={`form-group row ${input.name}`}>
    <label className="col-sm-3 col-form-label text-md-right">
      {`${label} `}
      <abbr title="required">*</abbr>
    </label>
    <div className="col-sm-9">
      <MaintenanceSelect
        {...input}
        user={user}
        serviceId={service.id}
        options={options}
        key={`maintenance-select-${service.id}`}
      />
      {hint && <Hint text={hint} />}
    </div>
  </div>
);
