import { momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import get from 'lodash.get';
import COLORS from '../../../helpers/colors';
import { CHILD_TYPE_MAP } from '../../../helpers/types';
import { relComparison } from '../../../helpers/string';

export const localizer = momentLocalizer(moment);

export const allViews = [
  Views.MONTH,
  Views.WEEK,
  Views.DAY,
];

const fontStyle = {
  fontSize: '0.7rem',
  color: COLORS.white,
};

export const eventPropGetter = (event, task) => {
  if (!task) return {};

  const plan = task.relationships.work_order || task.relationships.project;
  const planRel = plan.data;
  const resource = event.resource || {};

  // completed services are displayed w/ $green border/text
  if (resource.completed) {
    return {
      style: {
        ...fontStyle,
        margin: '1px',
        border: `1px solid ${COLORS.green}`,
        backgroundColor: COLORS.white,
        color: COLORS.green,
      },
    };
  }

  // grey out events that belong to other projects
  if (!planRel || planRel.id !== resource.planId) {
    return {
      style: {
        ...fontStyle,
        backgroundColor: COLORS.grey,
        fontStyle: 'italic',
      },
    };
  }

  // related events are shown with service background color as border/text
  if (resource.taskId !== task.id) {
    return {
      style: {
        ...fontStyle,
        backgroundColor: resource.serviceBadge?.background_color,
      },
    };
  }

  // current event is styled like a service badge
  return {
    style: {
      ...fontStyle,
      backgroundColor: resource.serviceBadge?.background_color,
    },
  };
};

export const dayPropGetter = (date, minDate) => {
  if (moment(date)
    .isBefore(minDate, 'day')) {
    return { className: 'rbc-day-disabled' };
  }

  return {};
};

export const minuteOfDay = date => date.getMinutes() + (date.getHours() * 60);

const dailyCounts = (events) => {
  const counts = {};

  events.forEach((event) => {
    const date = moment(event.start);
    const endDate = moment(event.end);

    while (date.isSameOrBefore(endDate, 'day')) {
      const key = date.format('YYYY-MM-DD');
      counts[key] = (counts[key] || 0) + 1;
      date.add(1, 'day');
    }
  });

  return counts;
};

const minHeight = 750;
const heightPerEvent = 25;
const bottomPadding = 100;

export const calendarHeight = (events, range = {}) => {
  let allEvents = events;
  let visibleWeeks = 5;

  if (range && range.start && range.end) {
    allEvents = events.filter(e => (
      moment(e.start).isBetween(
        moment(range.start).startOf('day'),
        moment(range.end).endOf('day'),
      )
    ));

    visibleWeeks = moment(range.end).diff(range.start, 'week');
  }

  const counts = Object.values(dailyCounts(allEvents));
  if (counts.length === 0) return minHeight;

  const eventsPerDay = Math.max(...counts);
  const rowHeight = (eventsPerDay * heightPerEvent) + bottomPadding;
  const height = rowHeight * visibleWeeks;

  return Math.max(height, minHeight);
};

function end(event) {
  const start = moment(event.attributes.started_at);
  const end = moment(event.attributes.completed_at || event.attributes.ended_at);

  if (end.isBefore(start)) {
    return start.add(1, 'days')
      .toDate();
  }

  return end.toDate();
}

export function decorateEvent(event) {
  if (!event.relationships && event.resource) return event;
  const planRelationship = event.relationships.work_order || event.relationships.project;
  const planId = get(planRelationship, 'data.id', null);
  const planType = get(planRelationship, 'data.type', null);
  const taskType = CHILD_TYPE_MAP[planType];

  return {
    id: event.id,
    type: event.type,
    title: event.attributes.title,
    allDay: event.attributes.all_day,
    start: moment(event.attributes.started_at)
      .toDate(),
    end: end(event),
    userId: event.relationships?.user?.data?.id,
    resource: {
      workOrderNumber: event.attributes.work_order_number,
      serviceBadge: event.attributes.service_badge,
      shortAddress: event.attributes.short_address,
      completed: event.attributes.completed,
      taskId: event.id,
      taskType,
      planId,
      planType,
      serviceId: event.relationships.service.data && event.relationships.service.data.id,
      userId: event.relationships.user.data && event.relationships.user.data.id,
    },
  };
}

export const getStyle = (event, currentEvent) => {
  if (!event) return {};

  const plan = event.relationships.work_order || event.relationships.project;
  const eventPlan = currentEvent.relationships.work_order || currentEvent.relationships.project;

  // completed services are displayed w/ $green border/text
  if (event.attributes.completed) {
    return {
      borderColor: COLORS.green,
      backgroundColor: COLORS.white,
      textColor: COLORS.green,
    };
  }

  // grey out events that belong to other projects
  if (!relComparison(plan, eventPlan)) {
    return {
      borderColor: COLORS.grey,
      backgroundColor: COLORS.grey,
      textColor: COLORS.white,
    };
  }

  return {
    textColor: COLORS.white,
    backgroundColor: event?.attributes?.service_badge?.background_color,
    borderColor: event?.attributes?.service_badge?.background_color,
  };
};

export function sortEvents(events) {
  events.sort((a, b) => (a.resource.workOrderNumber || '').localeCompare(b.resource.workOrderNumber || ''));

  return events;
}
