import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import InvoiceFormModal from '../../../components/ServiceDetails/InvoiceTab/InvoiceFormModal';

const InvoiceButton = ({ task, serviceInvoice }) => {
  const [shown, setShown] = useState(false);

  return (
    <>
      <Button
        variant={serviceInvoice ? 'primary' : 'success'}
        className="border-0"
        onClick={() => setShown(true)}
      >
        {serviceInvoice ? 'View' : 'Create'}
      </Button>
      <InvoiceFormModal
        show={shown}
        onHide={() => setShown(false)}
        serviceInvoiceId={serviceInvoice?.id}
        taskType={task.type}
        taskId={task.id}
      />
    </>
  );
};

InvoiceButton.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  serviceInvoice: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};

InvoiceButton.defaultProps = {
  serviceInvoice: undefined,
};

export default InvoiceButton;
