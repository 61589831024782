import { createSelector } from 'reselect';
import { decorateEvent, sortEvents } from '../../containers/ProjectManagementContainer/Scheduler/helpers';
import { makeGetRecords } from './api';

const getApi = state => state.Api;
const getEventRefs = state => state.TeamSchedule.events;

export const makeGetEvents = () => {
  const getRecords = makeGetRecords();
  const getEvents = createSelector(
    [getApi, getEventRefs],
    (api, refs) => getRecords(api, refs.map(ref => ({
      type: ref.type,
      id: ref.id,
    }))),
  );

  // todo, do we need api anymore?
  return createSelector(
    [getApi, getEvents],
    (api, events) => {
      const decoratedEvents = events.map(event => decorateEvent(event));
      sortEvents(decoratedEvents);

      return decoratedEvents;
    },
  );
};

// Each instance of CommunityGroup needs it's own selector
export const makeGetSortedCommunityPlans = () => createSelector(
  [
    state => state.TeamSchedule.plans,
    (state, props) => state.Api.communities[props.communityId],
  ],
  (plans, community) => (
    plans.filter(plan => (
      community.relationships.projects.data.some(p => (
        p.id === plan.id && p.type === plan.type
      ))
      || community.relationships.work_orders.data.some(p => (
        p.id === plan.id && p.type === plan.type
      ))
    ))
  ),
);
