import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select, { components } from 'react-select';
import { fetchRecords } from 'redux-json-api-module';
import Badge from '../Badge';

const Option = props => (
  <components.Option {...props}>
    <Badge {...props.data.service.attributes.service_badge} />
  </components.Option>
);

const SingleValue = props => (
  <components.SingleValue {...props}>
    <Badge {...props.data.service.attributes.service_badge} />
  </components.SingleValue>
);

const ServiceInput = ({ input, meta, label, includeBlank, options, services, fetchRecords }) => {
  useEffect(() => {
    fetchRecords('services');
  }, []);

  const serviceIds = options.map(o => o[1]);
  const formattedOptions = Object.values(services)
    .filter(s => serviceIds.includes(parseInt(s.id))) // only show services passed in options
    .map(service => ({
      value: service.id,
      label: service.attributes.name,
      service,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const selectedOption = formattedOptions.find(o => parseInt(o.value) === parseInt(input.value));

  return (
    <div
      className={`form-group required ${meta.touched && meta.error && 'form-group-invalid'}`}
    >
      <label className="form-control-label required">
        {label}
      </label>
      <Select
        {...input}
        value={selectedOption}
        onChange={({ value }) => input.onChange(value)}
        placeholder={includeBlank}
        options={formattedOptions}
        components={{ Option, SingleValue }}
      />
      {meta.touched && meta.error ? (
        <div className="invalid-feedback d-block">{meta.error}</div>
      ) : null}
    </div>
  );
};

ServiceInput.propTypes = {};

const mapStateToProps = state => ({
  services: state.Api.services,
});

const mapDispatchToProps = {
  fetchRecords,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceInput);
