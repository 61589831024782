import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { getMessenger } from '../../redux/modules/chat';
import GenericUnreadCount from './GenericUnreadCount';
import hashtag from '../../../assets/images/icon/hashtag.png';
import lock from '../../../assets/images/icon/lock.png';
import { idType } from '../../helpers/propTypes';

const ChannelUnreadCount = ({
  unreadContent, unreadCount,
  id, objectType, active,
  isPublic,
}) => (
  (!unreadContent && !unreadCount && !active) ? (
    isPublic ? (
      <img src={hashtag} style={{ width: '18px' }} />
    ) : (
      <img src={lock} style={{ width: '18px' }} />
    )
  ) : (
    <GenericUnreadCount
      unreadContent={unreadContent}
      unreadCount={unreadCount}
      id={id}
      objectType={objectType}
      active={active}
    />
  )
);

ChannelUnreadCount.propTypes = {
  unreadCount: PropTypes.number,
  unreadContent: PropTypes.bool,
  objectType: PropTypes.string.isRequired,
  id: idType,
  active: PropTypes.bool.isRequired,
  isPublic: PropTypes.bool.isRequired,
};

ChannelUnreadCount.defaultProps = {
  unreadCount: 0,
  unreadContent: false,
  id: null,
};

const mapStateToProps = (state, ownProps) => {
  const messenger = getMessenger(state, ownProps.messageThreadId, ownProps.userId);

  return {
    unreadCount: get(messenger, 'attributes.unread_count', 0),
    unreadContent: get(messenger, 'attributes.unread_content', false),
    id: messenger && messenger.id,
    objectType: 'chat_channels',
  };
};

export default connect(mapStateToProps)(ChannelUnreadCount);
