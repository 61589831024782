import React from 'react';
import { connect } from 'react-redux';
import { getRelationship } from 'redux-json-api-module';

const AddressCell = ({ unit, plan }) => {
  let href = `/projects/${plan.id}`;
  if (plan.type === 'work_order_listings') {
    href = `/work_orders/${plan.id}`;
  }

  return (
    <a href={href} target="_blank" rel="noopener noreferrer">
      <div>
        {unit.attributes.street_address}
        <br />
        {unit.attributes.unit_number}
      </div>
    </a>
  );
}

AddressCell.propTypes = {};

const mapStateToProps = (state, ownProps) => {
  return {
    unit: getRelationship(state.Api, ownProps.plan.relationships.unit),
  };
};

export default connect(mapStateToProps)(AddressCell);
