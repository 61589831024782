import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { Field, Form } from 'react-final-form';
import get from 'lodash.get';
import Loader from '../../components/Loader';
import UnitsTable from './UnitsTable';
import { destroyMultiple, fetchUnits, fetchCommunityOptions } from '../../redux/modules/units';
import CommunitySelector from '../../components/UnitSelector/CommunitySelector';
import Alert from '../../components/Alert';

class UnitsTab extends Component {
  static propTypes = {
    fetchUnits: PropTypes.func.isRequired,
    destroyMultiple: PropTypes.func.isRequired,
    units: PropTypes.array,
    communityOptions: PropTypes.array.isRequired,
    fetchCommunityOptions: PropTypes.func.isRequired,
  };

  static defaultProps = {
    units: [],
  };

  state = {
    loading: true,
    selectedUnits: {},
    selectAll: false,
    communityId: null,
  };

  componentDidMount() {
    const { fetchUnits, fetchCommunityOptions } = this.props;

    Promise.all([
      fetchCommunityOptions(),
      fetchUnits(true),
    ]).finally(() => this.setState({ loading: false }));
  }

  handleSelect = (unit, selected) => {
    const { selectedUnits } = this.state;
    this.setState({
      selectedUnits: {
        ...selectedUnits,
        [unit.id]: selected,
      },
    });
  };

  handleSelectAll = (selectAll) => {
    const state = { selectAll };
    if (!selectAll) state.selectedUnits = {};
    this.setState(state);
  };

  delete = () => {
    const { selectedUnits, selectAll } = this.state;
    const { units, destroyMultiple, fetchUnits } = this.props;

    const ids = selectAll
      ? units.map(unit => unit.id)
      : Object.keys(selectedUnits)
        .filter(id => selectedUnits[id]);

    if (ids.length === 0) {
      return;
    }

    this.setState({ loading: true });

    destroyMultiple(ids)
      .then(() => fetchUnits(true))
      .finally(() => this.setState({ loading: false }));
  };

  anySelected = () => {
    const { selectAll, selectedUnits } = this.state;
    const { units } = this.props;

    return selectAll ? units.length > 0 : Object.keys(selectedUnits)
      .some(id => selectedUnits[id]);
  };

  handleCommunitySelect = (community) => {
    this.setState({
      communityId: community.id,
    });
  };

  render() {
    const { loading, communityId } = this.state;
    const { communityOptions } = this.props;

    return loading ? <Loader /> : (
      <div className="mt-2">
        <div className="row">
          <div className="col-6">
            <Form
              initialValues={{ community: null }}
              onSubmit={() => {
              }}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <Field
                    component={CommunitySelector}
                    options={communityOptions}
                    name="community"
                    canCreate={false}
                    placeholder="View All Addresses"
                    onChange={this.handleCommunitySelect}
                    removeSpacing
                  />
                </form>
              )}
            />
          </div>

          <div className="col-6">
            <a href="/units/import" id="import-unit" style={{ marginTop: '3px' }} className="btn btn-secondary ml-2 float-right">
                  Import Multiple Units
            </a>
            <a href="/units/new" id="add-unit" className="btn btn-success mt-1 float-right">
                  Add New Unit
            </a>
            {this.anySelected()
              ? (
                <Button
                  variant="danger"
                  className="float-right mt-1 mr-3 border-0"
                  onClick={this.delete}
                  id="delete-all"
                >
Delete Selected Unit(s)
                </Button>
              )
              : null}
          </div>

        </div>
        <UnitsTable
          key={communityId}
          communityId={communityId}
          onSelect={this.handleSelect}
          onSelectAll={this.handleSelectAll}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  communityOptions: state.Units.communityOptions || [],
  units: Object.values(state.Api.units || {})
    .filter(u => (state.Units.unitIds || []).includes(u.id)
      && (!ownProps.communityId || get(u, 'relationships.community.data.id', null) === ownProps.communityId.toString())),
});

const mapDispatchToProps = {
  fetchUnits,
  destroyMultiple,
  fetchCommunityOptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnitsTab);
