import React from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import PartsUsageTable from './PartsUsageTable';

const InventoryTab = () => (
  <Row>
    <Col>
      <PartsUsageTable />
    </Col>
  </Row>
);

InventoryTab.propTypes = {};

const mapStateToProps = () => ({});

export default connect(
  mapStateToProps,
)(InventoryTab);
