import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { getRecord, getRelationship } from 'redux-json-api-module';
import get from 'lodash.get';
import { estimatedDone } from '../Summary/helpers';

import { Card } from '../Card';
import Avatar from '../Avatar';
import { simpleFormat } from '../../helpers/string';

import Badge from '../Badge';
import '../Summary/Summary.scss';
import KeysReceivedOnDatePicker from '../Summary/KeysReceivedOnDatePicker';
import DueDatePicker from '../Summary/DueDatePicker';
import { submitDueDate, submitKeysReceivedOn } from '../../redux/modules/projectManagement';
import ProjectBadge from '../ProjectBadge';

const ProjectSummary = ({
  planType, planId, fullAddressFormatted, communityName, mapsUrl, mapsImageUrl, submitDueDate,
  submitKeysReceivedOn, aasmState, completedAt, estimatedDone, keysReceivedOn, dueOn, status,
  inspector, manager, project,
}) => (
  <Card className="summary">
    <div className="row">
      <div className="col-sm-6 col-lg-2">
        <p className="font-weight-bold">
          Address
        </p>
        <p
          className="mt-2"
          dangerouslySetInnerHTML={{ __html: simpleFormat(fullAddressFormatted) }}
        />
        <p className="mt-2">{communityName}</p>
        <p>
          <ProjectBadge />
        </p>
      </div>
      <div className="col-sm-6 col-lg-2">
        <a
          href={mapsUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            style={{
              maxHeight: '150px',
              maxWidth: '100%',
            }}
            src={mapsImageUrl}
            alt="Unit Map"
          />
        </a>
      </div>
      <div className="col-sm-6 col-lg-3">
        <table className="table table-borderless table-sm summary-table">
          <tbody>
            <tr>
              <td className="font-weight-bold">
                Start
              </td>
              <td>
                <KeysReceivedOnDatePicker
                  keysReceivedOn={keysReceivedOn}
                  aasmState={aasmState}
                  dueOn={dueOn}
                  onChange={keysReceivedOn => (
                    submitKeysReceivedOn(project, keysReceivedOn)
                  )}
                />
              </td>
            </tr>
            <tr>
              <td className="font-weight-bold">
                Due
              </td>
              <td>
                <DueDatePicker
                  planType={planType}
                  planId={planId}
                  onChange={dueOn => (
                    submitDueDate(planType, planId, dueOn, keysReceivedOn)
                  )}
                />
              </td>
            </tr>
            {aasmState === 'completed' ? (
              <tr>
                <td className="font-weight-bold">
                  Done
                </td>
                <td>
                  {moment(completedAt)
                    .isValid()
                    ? moment(completedAt)
                      .format('MM/DD/YYYY') : 'Unknown'}
                </td>
              </tr>
            ) : (
              <tr>
                <td className="font-weight-bold">
                  Est. Done
                </td>
                <td>
                  {estimatedDone}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="col-sm-6 col-lg-5">
        <table className="table table-borderless table-sm summary-table">
          <tbody>
            <tr>
              <td className="font-weight-bold">
                Status
              </td>
              <td>
                <Badge {...status} />
              </td>
            </tr>
            {inspector ? (
              <tr>
                <td className="font-weight-bold">
                  Inspected By
                </td>
                <td>
                  <React.Fragment>
                    <Avatar {...inspector.attributes} className="avatar-xs d-inline-block" />
                    {` ${inspector.attributes.name}`}
                  </React.Fragment>
                </td>
              </tr>
            ) : null}
            {manager ? (
              <tr>
                <td className="font-weight-bold">
                  Approved By
                </td>
                <td>
                  <React.Fragment>
                    <Avatar {...manager.attributes} className="avatar-xs d-inline-block" />
                    {` ${manager.attributes.name}`}
                  </React.Fragment>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  </Card>
);

const mapStateToProps = (state, ownProps) => {
  const unit = getRelationship(state.Api, ownProps.project.relationships.unit);
  const community = getRelationship(state.Api, ownProps.project.relationships.community);
  const inspector = getRecord(state.Api, {
    type: 'users',
    id: ownProps.project.attributes.inspector_id,
  });
  const manager = getRecord(state.Api, {
    type: 'users',
    id: ownProps.project.attributes.manager_id,
  });

  const unitAttributes = get(unit, 'attributes', {});
  const {
    full_address_formatted: fullAddressFormatted,
    google_maps_url: mapsUrl,
    google_maps_image_url: mapsImageUrl,
  } = unitAttributes;

  const projectAttributes = ownProps.project.attributes;
  const {
    completed_at: completedAt,
    aasm_state: aasmState,
    due_on: dueOn,
    keys_received_on: keysReceivedOn,
    status,
  } = projectAttributes;

  const done = estimatedDone(ownProps.project);

  const communityName = get(community, 'attributes.name', '');

  return {
    fullAddressFormatted,
    communityName,
    mapsUrl,
    mapsImageUrl,
    planId: ownProps.project.id,
    planType: 'projects',
    completedAt,
    estimatedDone: done,
    aasmState,
    dueOn,
    keysReceivedOn,
    status,
    inspector,
    manager,
  };
};

const mapDispatchToProps = {
  submitDueDate,
  submitKeysReceivedOn,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectSummary);
