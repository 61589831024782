import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { renderToString } from 'react-dom/server';
import get from 'lodash.get';
import toastr from 'toastr';
import { connect } from 'react-redux';
import { getRecord } from 'redux-json-api-module';
import { eventObject, taggableUserObject, userObject } from '../../helpers/propTypes';

import Avatar from '../../components/Avatar';
import { getHtmlBody } from '../../components/Messenger/helpers';
import withTagging from '../../components/Messenger/withTagging';

const DefaultToast = () => (
  <div>
    New updates are ready,
    {' '}
    <a href="#" onClick={() => window.location.reload()}>please click here to refresh</a>
    !
  </div>
);

const PlanEventToast = ({ toastVisible }) => (
  <div>
    {toastVisible.title}
  </div>
);

PlanEventToast.propTypes = {
  toastVisible: PropTypes.shape({
    title: PropTypes.string.isRequired,
  }).isRequired,
};

const MessageToast = withTagging(({ message, textSections }) => {
  const name = `@${get(message, 'attributes.messenger.user_name', 'sender')}: `;
  const unitShortAddress = get(message, 'attributes.unit_short_address', null);
  const senderAttributes = get(message, 'attributes.messenger', null);
  const body = getHtmlBody(textSections, {});

  return (
    <div>
      <div className="font-weight-bold">{unitShortAddress}</div>
      <div className="mt-2">
        <div style={{ position: 'absolute' }}><Avatar {...senderAttributes} /></div>
        <span style={{ marginLeft: '60px' }} className="toast-text">
          <span className="font-weight-bold">{name}</span>
          {body}
        </span>
      </div>
    </div>
  );
});

MessageToast.propTypes = {
  textSections: PropTypes.arrayOf(PropTypes.object),
  message: PropTypes.object.isRequired,
};

MessageToast.defaultProps = {
  // DO NOT ADD THIS DEFAULT, IT WILL OVERRIDE THE HOC!
  // textSections: [],
};

const Toast = ({ toastVisible, taggableUsers, message, text }) => {
  if (!toastVisible) return null;

  useEffect(() => {
    let Component = DefaultToast;
    if (toastVisible.type === 'message') {
      Component = MessageToast;
    } else if (toastVisible.title) {
      Component = PlanEventToast;
    }

    const rendered = (
      <Component
        toastVisible={toastVisible}
        taggableUsers={taggableUsers}
        message={message}
        text={text}
      />
    );
    const options = toastVisible.type === 'message' ? { iconClass: 'message-alert' } : {};
    const method = toastVisible.type === 'message' ? toastr.info : toastr.success;
    method(renderToString(rendered), '', options);

    return (() => toastr.remove());
  }, []);

  return (
    <div />
  );
};

const mapStateToProps = (state, ownProps) => {
  const attributes = {
    taggableUsers: state.Chat.taggableUsers || [],
  };
  if (!ownProps.toastVisible) return attributes;

  const messageId = ownProps.toastVisible.message_id;

  const message = getRecord(state.Api, {
    type: 'messages',
    id: messageId,
  });
  const text = get(message, 'attributes.body', '');

  return {
    ...attributes,
    message,
    text,
  };
};

Toast.propTypes = {
  toastVisible: eventObject,
  taggableUsers: PropTypes.arrayOf(taggableUserObject).isRequired,
  text: PropTypes.string,
  message: PropTypes.object,
};

Toast.defaultProps = {
  message: null,
  toastVisible: null,
  text: '',
};

export default connect(mapStateToProps)(Toast);
