import React from 'react';
import Badge from './Badge';
import COLORS from '../helpers/colors';

const ProjectBadge = () => (
  <Badge
    text_color={COLORS.white}
    background_color={COLORS.cyan}
    label="Make Ready"
    style={{ fontSize: '14px' }}
  />
);

export default ProjectBadge;
