import toastr from 'toastr';
import moment from 'moment/moment';
import { firstBy } from 'thenby/thenBy.module';
import { fetchRecords, fetchRecord, getRelationship, getRecord } from 'redux-json-api-module';
import get from 'lodash.get';
import {
  decorateEvent,
  minuteOfDay,
} from '../../containers/ProjectManagementContainer/Scheduler/helpers';
import { getInspection } from '../../helpers/inspection';
import { MIN_TIME, MAX_TIME } from '../../components/GenericScheduler/helpers';
import { PARENT_TYPE_MAP, CALENDAR_EVENT_TYPE_MAP } from '../../helpers/types';
import { relComparison, idComparison } from '../../helpers/string';
import { getCompositeId } from '../../containers/TeamScheduleContainer/Schedule/helpers';

export const ADD_EVENTS = 'intimely/scheduler/ADD_EVENTS';
export const CLOSE_SCHEDULER = 'intimely/scheduler/CLOSE_SCHEDULER';
export const CLOSE_FORM = 'intimely/scheduler/CLOSE_FORM';
export const OPEN_FORM = 'intimely/scheduler/OPEN_FORM';
export const OPEN_SCHEDULER = 'intimely/scheduler/OPEN_SCHEDULER';
export const LOADING_EVENTS_START = 'intimely/scheduler/LOADING_EVENTS_START';
export const LOADING_EVENTS_DONE = 'intimely/scheduler/LOADING_EVENTS_DONE';
export const SET_TASK = 'intimely/scheduler/SET_TASK';
export const SET_USER = 'intimely/scheduler/SET_USER';
export const TOGGLE_MODAL = 'intimely/scheduler/TOGGLE_MODAL';
export const SET_EVENTS = 'intimely/scheduler/SET_RAW_EVENTS';
export const SET_TEMPORARY_EVENTS = 'intimely/scheduler/SET_TEMPORARY_EVENTS';
export const SET_FORM_VISIBLE = 'intimely/scheduler/SET_FORM_VISIBLE';
export const SET_SCHEDULED_REF = 'intimely/scheduler/SET_SCHEDULED_REF';
export const SET_RANGE = 'intimely/scheduler/SET_RANGE';

const INITIAL_STATE = {
  scheduledRef: null,
  userId: null,
  events: [],
  temporaryEvents: {},
  loading: false,
  calendarVisible: false,
  formVisible: false,
  formValues: {
    startDate: new Date(),
    startTime: new Date(),
    endDate: new Date(),
    endTime: new Date(),
    includeTime: false,
  },
  range: null,
};

function mergeEvents(existingEvents, newEvents) {
  const events = [...existingEvents];

  newEvents.forEach((event) => {
    const i = events.findIndex(e => e.id === event.id);

    if (i > -1) {
      events[i] = event;
    } else {
      events.push(event);
    }
  });

  return events;
}

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_EVENTS:
      return {
        ...state,
        events: mergeEvents(state.events, action.events).sort(
          firstBy(e => (e.resource.planId === state.id ? 0 : 1))
            .thenBy(e => e.start),
        ),
      };

    case CLOSE_SCHEDULER:
      return INITIAL_STATE;

    case SET_FORM_VISIBLE:
      return {
        ...state,
        formVisible: action.formVisible,
      };

    case CLOSE_FORM:
      return {
        ...state,
        formVisible: false,
      };

    case OPEN_FORM:
      return {
        ...state,
        formVisible: true,
        formValues: action.formValues,
      };

    case OPEN_SCHEDULER:
      return {
        ...state,
        calendarVisible: true,
        task: action.task,
        userId: action.userId,
        scheduledRef: action.scheduledRef,
      };

    case SET_USER:

      return {
        ...state,
        userId: action.userId,
      };

    case TOGGLE_MODAL:
      return {
        ...state,
        calendarVisible: action.visible,
      };

    case LOADING_EVENTS_START:
      return {
        ...state,
        loading: true,
      };

    case LOADING_EVENTS_DONE:
      return {
        ...state,
        loading: false,
      };

    case SET_TASK:
      return {
        ...state,
        task: action.task,
      };

    case SET_SCHEDULED_REF:
      return {
        ...state,
        scheduledRef: action.scheduledRef,
      };

    case SET_EVENTS:
      return {
        ...state,
        events: action.events,
      };

    case SET_TEMPORARY_EVENTS:
      return {
        ...state,
        temporaryEvents: action.temporaryEvents,
      };

    case SET_RANGE:
      return {
        ...state,
        range: action.range,
      };

    default:
      return state;
  }
}

export const eventFilter = (event, currentTask) => {
  const planRel = event.relationships.work_order || event.relationships.project;
  const curPlanRel = currentTask.relationships.work_order || event.relationships.project;

  if (
    event
    && currentTask && CALENDAR_EVENT_TYPE_MAP[event] === CALENDAR_EVENT_TYPE_MAP[currentTask]
    && idComparison(event, currentTask)
  ) {
    return true;
  }

  return (
    // only show scheduled services
    (event.attributes.started_at && event.attributes.ended_at)
    && (
      // hide completed services belonging to other projects
      (relComparison(planRel, curPlanRel))
      || !event.attributes.completed
    )
  );
};

function sortEvents(events) {
  events.sort((a, b) => (a.attributes.work_order_number || '').localeCompare(b.attributes.work_order_number || ''));

  return events;
}

export function addEvents(events) {
  return {
    type: ADD_EVENTS,
    events,
  };
}

export function setEvents(events) {
  sortEvents(events);
  return {
    type: SET_EVENTS,
    events,
  };
}

export const setRange = range => ({
  type: SET_RANGE,
  range,
});

export function setTemporaryEvents(temporaryEvents) {
  return {
    type: SET_TEMPORARY_EVENTS,
    temporaryEvents,
  };
}

export function setFormVisible(formVisible) {
  return {
    type: SET_FORM_VISIBLE,
    formVisible,
  };
}

export function setScheduledRef(scheduledRef) {
  return (dispatch, getState) => {
    const task = getRecord(getState().Api, scheduledRef);
    const userId = get(task, 'relationships.user.data.id', task.userId);
    dispatch(changeUser(userId));
    dispatch({
      type: SET_SCHEDULED_REF,
      scheduledRef,
    });
    return dispatch(updateTask(task));
  };
}

export function createEvents(events) {
  return (dispatch, getState) => {
    const { task } = getState().Scheduler;
    if (!task) return dispatch(addEvents([]));
    const filteredEvents = events
      .filter(event => eventFilter(event, task))
      .map(event => decorateEvent(event));

    return dispatch(addEvents(filteredEvents));
  };
}

export function loadEvents(loading = true) {
  return (dispatch, getState) => {
    if (loading) dispatch({ type: LOADING_EVENTS_START });

    const {
      userId,
      range,
      scheduledRef,
      task,
    } = getState().Scheduler;
    const event = scheduledRef || task ? getRecord(getState().Api, scheduledRef || task) : null;

    const plan = event
      ? getRelationship(getState().Api, event.relationships[PARENT_TYPE_MAP[task.type]])
      : null;

    const promises = [];

    const calendarEventType = CALENDAR_EVENT_TYPE_MAP[task.type];

    const options = {};
    if (calendarEventType === 'work_order_calendar_events') {
      options.include = 'user,work_order,unit,service';
    } else if (calendarEventType === 'calendar_events') {
      options.include = 'user,project,unit,service';
    }

    const filter = {};
    if (range) {
      filter.date_min = range.start;
      filter.date_max = range.end;
    }

    if (plan) {
      promises.push(fetchRecords(`${plan.type}/${plan.id}/${calendarEventType}`, {
        page: { size: 1000 },
        ...options,
        filter,
      }));
    }

    if (userId) {
      const eventOptions = {
        page: { size: 1000 },
        sort: '-started_at',
        filter,
      };
      promises.push(fetchRecords(`users/${userId}/calendar_events`, eventOptions));
      promises.push(fetchRecords(`users/${userId}/work_order_calendar_events`, eventOptions));
    }

    return Promise.all(promises.map(p => dispatch(p)))
      .then((responses) => {
        const events = {};
        if (scheduledRef) {
          const activeEvent = getRecord(getState().Api, {
            type: calendarEventType,
            id: scheduledRef.id,
          });
          if (activeEvent) events[getCompositeId(activeEvent)] = activeEvent;
        }

        responses.forEach((resp) => {
          if (resp.error) {
            toastr.error('There was a problem loading part of schedule.  Please try again.');
          } else {
            resp.payload.data.data.forEach((event) => {
              events[getCompositeId(event)] = event;
            });
          }
        });

        return getState().Scheduler.formVisible
          ? Promise.resolve({})
          : dispatch(setEvents(Object.values(events)));
      })
      .finally(() => {
        if (loading) dispatch({ type: LOADING_EVENTS_DONE });
      });
  };
}

export const switchTask = (task) => {
  const userId = get(task, 'relationships.user.data.id', task.userId);

  return (dispatch) => {
    const fetch = task.id
      ? dispatch(fetchRecord(task.type, task.id))
      : Promise.resolve({});

    fetch.then((resp) => {
      if (!resp.error) {
        dispatch({
          type: OPEN_SCHEDULER,
          task: task.id ? resp.payload.data.data : task,
          scheduledRef: task.id ? resp.payload.data.data : task,
          userId,
        });
      } else {
        dispatch({
          type: OPEN_SCHEDULER,
          task,
          scheduledRef: task,
          userId,
        });
      }

      dispatch(loadEvents());
    });
  };
};

export function openScheduler(task) {
  const date = task?.attributes?.started_at ?? new Date();
  const range = {
    start: moment(date).startOf('month').subtract(7, 'days').toDate(),
    end: moment(date).endOf('month').add(7, 'days').toDate(),
  };

  return (dispatch) => {
    dispatch(setRange(range));
    dispatch(switchTask(task));
  };
}

export function closeScheduler() {
  return { type: CLOSE_SCHEDULER };
}

export function openForm() {
  return { type: OPEN_FORM };
}

export function closeForm() {
  return { type: CLOSE_FORM };
}

export function getFormValues(
  {
    task, plan, inspection, start,
    end, minDate,
  },
) {
  const { all_day: allDay } = task.attributes;
  let {
    started_at: startedAt,
    ended_at: endedAt,
  } = task.attributes;

  // when there is a previous started_at, we update the date and use the existing time,
  // otherwise we set a start time of 8 am
  if (startedAt) {
    startedAt = moment(start)
      .set('hours', moment(startedAt).hours())
      .set('minutes', moment(startedAt).minutes()).toDate();

    endedAt = moment(end)
      .set('hours', moment(endedAt).hours())
      .set('minutes', moment(endedAt).minutes())
      .toDate();
  } else {
    startedAt = moment(start).set('hours', 7).toDate();
    endedAt = moment(end).set('hours', 19).toDate();
  }

  // don't allow services to start before inspection completion date
  if (plan && plan.type === 'projects' && inspection) {
    const inspectionEnd = moment(inspection.attributes.completed_at)
      .startOf('day');

    if (moment(startedAt)
      .isBefore(inspectionEnd, 'day')) {
      toastr.warning(
        `Please select a date that is on or after the Inspection completion date, ${inspectionEnd.format('MM/DD')}`,
        null, { timeOut: 2500 },
      );

      return null;
    }
  }

  // don't allow inspection to start before keys received date
  if (minDate && moment(startedAt).startOf('day').isBefore(moment(minDate).startOf('day'))) {
    toastr.warning(
      `Please select a date on or after ${moment(minDate).format('MM/DD')}`,
      null,
      { timeOut: 2500 },
    );

    return null;
  }

  // ensure we do not show times outside of 07:00 - 19:00
  if (!allDay) {
    if (minuteOfDay(startedAt) < minuteOfDay(MIN_TIME)) {
      startedAt.setHours(MIN_TIME.getHours(), 0, 0);
    }

    if (minuteOfDay(endedAt) > minuteOfDay(MAX_TIME)) {
      endedAt.setHours(MAX_TIME.getHours(), 0, 0);
    }
  }

  // avoid duration <= 0
  if (startedAt.valueOf() >= endedAt.valueOf()) {
    endedAt = moment(startedAt)
      .add(1, 'hours')
      .toDate();
  }

  return {
    includeTime: !allDay,
    startDate: moment(startedAt)
      .startOf('day')
      .toDate(),
    startTime: moment(startedAt)
      .toDate(),
    endDate: moment(endedAt)
      .startOf('day')
      .toDate(),
    endTime: moment(endedAt)
      .toDate(),
  };
}

export function onSelectSlot(slotInfo, minDate) {
  return (dispatch, getState) => {
    const { task } = getState().Scheduler;
    const plan = getRelationship(getState().Api, task.relationships[PARENT_TYPE_MAP[task.type]]);
    const inspection = plan.type === 'projects' ? getInspection(plan, getState().Api) : null;
    const formValues = getFormValues({
      task,
      plan,
      inspection,
      start: slotInfo.start,
      end: slotInfo.end,
      minDate,
    });

    if (formValues) {
      dispatch({
        type: OPEN_FORM,
        formValues,
      });
    }
  };
}

export function onSelectEvent(event) {
  return (dispatch, getState) => {
    const selectedEvent = event;
    const { task } = getState().Scheduler;
    const plan = task.relationships.work_order || task.relationships.project;
    const planRelation = plan ? plan.data : null;

    if (event.resource.planId !== (planRelation && planRelation.id)
      && event.resource.planType !== (planRelation && planRelation.type)) {
      toastr.warning('Services belonging to another Plan cannot be scheduled from here.', null, { timeOut: 2500 });
      return;
    }

    const attributes = selectedEvent.attributes || selectedEvent;

    if ((!attributes || !attributes.assignable)
      && (!selectedEvent.resource || selectedEvent.resource.completed)) {
      toastr.warning('Service cannot be rescheduled.', null, { timeOut: 2500 });
      return;
    }

    if (selectedEvent.id === task.id) {
      // using selectedEvent ensures we have up to date values,
      // the projectService in the store is only set when we open the scheduler
      const {
        started_at: startedAt,
        ended_at: endedAt,
        all_day: allDay,
      } = attributes;

      dispatch({
        type: OPEN_FORM,
        formValues: {
          includeTime: !allDay,
          startDate: moment(startedAt)
            .startOf('day')
            .toDate(),
          startTime: moment(startedAt)
            .toDate(),
          endDate: moment(endedAt)
            .startOf('day')
            .toDate(),
          endTime: moment(endedAt)
            .toDate(),
        },
      });
    } else {
      toastr.info('Switching Services.', null, { timeOut: 2500 });
      dispatch(switchTask(event));
    }
  };
}

export function changeUser(userId) {
  return (dispatch) => {
    dispatch({
      type: SET_USER,
      userId,
    });
    dispatch(loadEvents());
  };
}

export function updateTask(task) {
  return {
    type: SET_TASK,
    task,
  };
}
