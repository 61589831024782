import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from '../../components/Loader';
import CommunityCard from './CommunityCard';
import CreatePlanCard from '../ChatsContainer/CreatePlanCard';
import { fetchWorkOrders } from '../../redux/modules/workOrdersRecurring';
import { nameSort } from '../../helpers/sorts';

class WorkOrdersRecurringContainer extends Component {
  static propTypes = {
    fetchWorkOrders: PropTypes.func.isRequired,
    communityIdMap: PropTypes.object.isRequired,
    communities: PropTypes.object.isRequired,
  };

  state = {
    loading: true,
  };

  componentDidMount() {
    const { fetchWorkOrders } = this.props;

    fetchWorkOrders()
      .then(() => this.setState({ loading: false }));
  }

  render() {
    const { loading } = this.state;
    const { communityIdMap, communities } = this.props;

    if (loading) {
      return <Loader />;
    }

    if (Object.keys(communityIdMap).length === 0) {
      return <CreatePlanCard />;
    }

    // sort communities by name
    const communityIds = Object.keys(communityIdMap);
    const sortedCommunityIdMap = Object.values(communities)
      .filter(c => communityIds.includes(c.id))
      .sort(nameSort)
      .map(c => c.id);

    return sortedCommunityIdMap.map(cid => (
      <CommunityCard
        communityId={cid}
        key={cid}
      />
    ));
  }
}

const mapStateToProps = state => ({
  communityIdMap: state.WorkOrdersRecurring.communityIdMap || {},
  communities: state.Api.communities || {},
});

const mapDispatchToProps = {
  fetchWorkOrders,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrdersRecurringContainer);
