import React from 'react';
import PropTypes from 'prop-types';
import { getTeams, changeMembership } from './helpers';

class TeamSelector extends React.Component {
  static propTypes = {
    memberships: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  options() {
    const { memberships } = this.props;

    return getTeams(memberships)
      .map(t => (
        <option key={t.membershipId} value={parseInt(t.membershipId)}>{t.label}</option>
      ));
  }

  handleChange(e) {
    const { user } = this.props;
    changeMembership(e.target.value, user);
  }

  render() {
    const { user } = this.props;

    return (
      <div>
        <form className="form-material small-and-large-only">
          <select
            className="form-control"
            value={user.data.relationships.membership.data.id}
            onChange={this.handleChange}
          >
            {this.options()}
          </select>
        </form>
      </div>
    );
  }
}

export default TeamSelector;
