import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveRecord } from 'redux-json-api-module';
import { fetchDescriptions } from '../../redux/modules/inspections';
import Loader from '../../components/Loader';
import { idType } from '../../helpers/propTypes';
import DescriptionsTabForm from './DescriptionsTabForm';

class DescriptionsTab extends Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    const { fetchDescriptions } = this.props;

    fetchDescriptions().then(() => {
      this.setState({ loading: false });
    });
  }

  handleSubmit = (values) => {
    const { fetchDescriptions, saveRecord, teamId } = this.props;

    const attributes = {
      descriptions_attributes: values.descriptions.filter(i => (
        i.id || String(i.name) !== ''
      )),
    };

    const record = {
      type: 'teams',
      id: teamId,
      attributes,
    };

    this.setState({ loading: true });

    saveRecord(record, { params: { window_id: window.WINDOW_ID } }).then(() => {
      fetchDescriptions();
    }).finally(() => this.setState({ loading: false }));
  };

  render() {
    const { descriptions } = this.props;
    const { loading } = this.state;

    return (
      <div>
        {loading ? <Loader /> : (
          <DescriptionsTabForm
            handleSubmit={this.handleSubmit}
            descriptions={descriptions}
          />
        )}
      </div>
    );
  }
}

DescriptionsTab.propTypes = {
  saveRecord: PropTypes.func.isRequired,
  fetchDescriptions: PropTypes.func.isRequired,
  descriptions: PropTypes.array.isRequired,
  teamId: idType.isRequired,
};

const mapStateToProps = (state) => {
  const ids = state.Inspections.descriptionIds || [];
  const descriptions = Object.values(state.Api.descriptions || {})
    .filter(description => ids.includes(description.id.toString()));

  descriptions.sort((a, b) => a.attributes.name.localeCompare(b.attributes.name));

  return {
    descriptions,
  };
};

const mapDispatchToProps = {
  saveRecord,
  fetchDescriptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(DescriptionsTab);
