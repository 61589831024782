import { connect } from 'react-redux';
import get from 'lodash.get';
import { getRecord } from 'redux-json-api-module';
import { workOrderTaskSort } from '../helpers';
import WorkOrderTaskRow from './WorkOrderTaskRow';
import ServiceAssignmentTable
  from '../../../components/ServiceAssignmentTable/ServiceAssignmentTable';
import WorkOrderTableHeaderComponent from './WorkOrderTableHeaderComponent';
import EditServiceRow from '../../../components/ServiceAssignmentTable/EditServiceRow/EditServiceRow';

const mapStateToProps = (state, ownProps) => {
  const tasks = (state.WorkOrderManagement.workOrderTaskIds || [])
    .map(id => getRecord(state.Api, { type: 'work_order_tasks', id }))
    .filter(r => r)
    .sort(workOrderTaskSort);

  const sortedTaskIds = tasks.map(s => s.id);
  const featureIds = tasks.map(t => t.relationships.work_order_features.data.map(f => f.id)).flat();

  const workOrder = getRecord(state.Api, { type: 'work_orders', id: ownProps.workOrderId });
  const aasmState = get(workOrder, 'attributes.aasm_state', null);

  return {
    sortedTaskIds,
    taskType: 'work_order_tasks',
    aasmState,
    planId: ownProps.workOrderId,
    loaded: state.WorkOrderManagement.workOrderTasksLoaded,
    ServiceRowComponent: featureIds.length > 0 ? WorkOrderTaskRow : EditServiceRow,
    TableHeaderComponent: WorkOrderTableHeaderComponent,
  };
};

export default connect(
  mapStateToProps,
)(ServiceAssignmentTable);
