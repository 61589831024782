import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

const CUSTOM = 'CUSTOM';

export default class CustomInputSelect extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
    value: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    includeBlank: PropTypes.string,
  };

  static defaultProps = {
    onChange: null,
    options: [],
    includeBlank: undefined,
    value: undefined,
    errors: undefined,
    name: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      selectValue: props.value && props.options.indexOf(props.value) === -1 ? CUSTOM : (props.value || ''),
      textValue: props.options.indexOf(props.value) === -1 ? (props.value || '') : '',
    };
  }

  handleSelect = (e) => {
    const { onChange, input } = this.props;
    const { textValue } = this.state;
    const selectValue = e.target.value;

    this.setState({ selectValue });
    const value = selectValue === CUSTOM ? textValue : selectValue;

    if (onChange) onChange(value);
    if (input) input.onChange(value);
  };

  handleCustom = (e) => {
    const { onChange, input } = this.props;
    const { value } = e.target;

    this.setState({ textValue: value });
    if (onChange) onChange(value);
    if (input) input.onChange(value);
  };

  render() {
    const { name, options, errors, includeBlank, input, disabled } = this.props;
    const { selectValue, textValue } = this.state;

    const selectParams = input ? { name: `${get(input, 'name', '')}_select` } : {};

    return (
      <React.Fragment>
        <input
          name={name || get(input, 'name', '')}
          type="hidden"
          value={selectValue === CUSTOM ? textValue : selectValue}
        />

        <select
          id={name || get(input, 'name', '')}
          name={name}
          {...selectParams}
          value={selectValue}
          disabled={disabled}
          onChange={this.handleSelect}
          className={`form-control ${errors ? 'is-invalid' : ''}`}
        >

          {includeBlank && <option value="">{includeBlank}</option>}

          {options.map(option => (
            <option key={option} value={option}>{option}</option>
          ))}

          <option value={CUSTOM}>Custom</option>
        </select>

        {selectValue === CUSTOM && (
          <input
            type="text"
            value={textValue}
            onChange={this.handleCustom}
            className={`form-control ${errors ? 'is-invalid' : ''}`}
            style={{ marginTop: '15px' }}
          />
        )}

        {errors && errors.map(error => <div className="invalid-feedback">{error}</div>)}
      </React.Fragment>
    );
  }
}
