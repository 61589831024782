import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { getRecord } from 'redux-json-api-module';
import { Card } from 'react-bootstrap';
import ProjectServiceRow from './ProjectServiceRow';
import WorkOrderTaskRow from './WorkOrderTaskRow';

const CommunityCard = ({ community, tasks }) => (
  <Card>
    <div className="card-body">
      <h4 className="card-title">{get(community, 'attributes.name', '')}</h4>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th width="22%">Address</th>
              <th width="20%">Assigned To</th>
              <th width="12%">Status</th>
              <th width="12%">Condition</th>
              <th width="12%">Schedule</th>
              <th width="12%">Done</th>
            </tr>
          </thead>
          <tbody>
            {tasks.map(task => (
              task.type === 'project_service_listings' ? (
                <ProjectServiceRow task={task} key={task.id} />
              ) : (
                <WorkOrderTaskRow task={task} key={task.id} />
              )))}
          </tbody>
        </table>
      </div>
    </div>
  </Card>
);

const mapStateToProps = (state, ownProps) => {
  const tasks = state.ServiceTasks.communityIdMap[ownProps.communityId] || [];
  const community = getRecord(state.Api, {
    type: 'communities',
    id: ownProps.communityId,
  });

  return {
    community,
    tasks,
  };
};

CommunityCard.propTypes = {
  community: PropTypes.object.isRequired,
  tasks: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(CommunityCard);
