/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { fetchRecord, queryString, saveRecord } from 'redux-json-api-module';
import store from '../../../redux/store';

const submit = async (values) => {
  const { item, supplier, quantity, total, nonInventory, attachments } = values;

  // create item
  let itemId;
  if (item.__isNew__) {
    const itemRecord = {
      id: null,
      type: 'items',
      attributes: {
        name: item.value,
        is_inventory: !nonInventory,
      },
    };

    const resp = await store.dispatch(
      saveRecord(itemRecord, { params: { window_id: window.WINDOW_ID } }),
    );

    itemId = resp.payload.data.data.id;
  } else {
    itemId = item.value;
  }

  // create purchase order
  if (!nonInventory) {
    const formData = new FormData();
    formData.set('data[attributes][item_id]', itemId);
    formData.set('data[attributes][supplier]', supplier);
    formData.set('data[attributes][quantity]', quantity);
    formData.set('data[attributes][total]', total);
    attachments.forEach(({ file }, index) => {
      formData.append(`data[attributes][attachments_attributes][${index}][file]`, file);
    });
    const params = {
      include: 'item',
      window_id: window.WINDOW_ID,
    };
    const response = await axios.post(`/api/v1/purchase_orders?${queryString(params)}`, formData);
    const { id, type } = response.data.data;
    await store.dispatch(fetchRecord(type, id, params));
  }

  return true;
};

export default submit;
