import React from 'react';
import { Form } from 'react-final-form';
import UnitForm from '../../components/UnitSelector/UnitForm';

const EditUnitForm = ({
  initialValues,
  stateOptions,
  communityOptions,
  errors,
  saving,
  onSubmit,
}) => {
  const buttonText = initialValues.id ? 'Save Unit' : 'Create Unit';
  return (
    <Form
      keepDirtyOnReinitialize
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ values, form: { change }, handleSubmit }) => (
        <form onSubmit={handleSubmit} id="new_unit">
          <UnitForm
            stateOptions={stateOptions}
            errors={errors}
            communityOptions={communityOptions}
            canCreate
          />
          <div className="form-group row">
            <div className="offset-sm-3 col-sm-9">
              <input
                type="submit"
                value={saving ? 'Saving...' : buttonText}
                className="btn btn-success"
                disabled={saving}
              />
            </div>
          </div>
        </form>
      )}
    />
  );
};

export default EditUnitForm;
