import { connect } from 'react-redux';
import { getRelationship, getRecord } from 'redux-json-api-module';
import get from 'lodash.get';
import {
  splitFeature,
  updateFeatures,
  deleteFeature,
  fetchAreaOptions,
} from '../../../redux/modules/workOrderManagement';
import ServiceForm from '../../ProjectManagementContainer/ServiceForm/ServiceForm';

const mapStateToProps = (state, ownProps) => {
  const newState = {
    areaItems: state.WorkOrderManagement.areaItems,
    conditionOptions: state.WorkOrderManagement.conditionOptions,
    descriptionOptions: state.WorkOrderManagement.descriptionOptions,
    serviceOptions: state.WorkOrderManagement.serviceOptions,
  };

  const feature = getRecord(state.Api, {
    type: ownProps.featureType,
    id: ownProps.featureId,
  });

  if (!feature) {
    newState.featureImages = ownProps.featureImages || [];
    return newState;
  }

  const plan = getRelationship(state.Api, feature.relationships.work_order);
  const unit = plan ? getRelationship(state.Api, plan.relationships.unit) : null;
  const description = get(plan, 'attributes.description', '');
  const task = getRelationship(state.Api, feature.relationships.work_order_task);
  const serviceBadge = get(task, 'attributes.service_badge', null);

  return Object.assign(newState, {
    feature,
    unit,
    errors: {},
    featureImages: feature.attributes.feature_images,
    description,
    serviceBadge,
  });
};

const mapDispatchToProps = {
  splitFeature,
  updateFeatures,
  deleteFeature,
  fetchAreaOptions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceForm);
