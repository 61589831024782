import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRelationship } from 'redux-json-api-module';
import Badge from '../../../components/Badge';
import dragIcon from '../../../../assets/images/icon/sort.png';

class Row extends React.Component {
  static propTypes = {
    projectService: PropTypes.object.isRequired,
    service: PropTypes.object.isRequired,
    canEdit: PropTypes.bool,
    innerRef: PropTypes.func,
    dragHandleProps: PropTypes.object,
    draggableProps: PropTypes.object,
  };

  render() {
    const {
      dragHandleProps, draggableProps,
      innerRef, projectService, service,
    } = this.props;

    const { condition_badge: conditionBadge, sortable } = projectService.attributes;

    return (
      <React.Fragment>
        <tr ref={innerRef} {...draggableProps} className="v-middle">
          <td>
            <h4>
              <Badge {...service.attributes} label={service.attributes.name} />
            </h4>
          </td>
          <td>{conditionBadge ? <Badge {...conditionBadge} /> : null}</td>
          <td {...dragHandleProps}>
            {sortable
              ? <img src={dragIcon} alt="Drag Icon" width="50" /> : null}
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { relationships } = ownProps.projectService;

  return {
    service: getRelationship(state.Api, relationships.service),
    user: getRelationship(state.Api, relationships.user),
  };
};

export default connect(mapStateToProps)(Row);
