import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { Button } from 'react-bootstrap';
import Alert from '../../../components/Alert';
import ImportTable from './ImportTable';
import { TEXT_ATTRIBUTES, getAttributeValue } from '../helpers';
import icon1 from '../../../../assets/images/icon/number-1.png';
import icon2 from '../../../../assets/images/icon/number-2.png';
import icon3 from '../../../../assets/images/icon/number-3.png';
import FileImporter from '../FileImporter/FileImporter';
import greenCloud from '../../../../assets/images/icon/green_cloud.png';
import redCloud from '../../../../assets/images/icon/red_cloud.png';

const WorkOrderReview = ({
  closeModal,
  importObject,
  onSubmit,
  onDrop,
  openColumnMappingForm,
  uploading,
}) => {
  const errors = get(importObject, 'attributes.import_errors', []) || [];
  const rows = get(importObject, 'attributes.rows', []) || [];

  return (
    <>
      <div className="modal-header justify-content-center">
        <h5 className="font-weight-bold text-center mt-2">Review Imported Work Orders</h5>
      </div>

      <div className="modal-body">
        <div className="mb-3">
          {errors.length > 0 ? (
            <Alert
              type="danger"
              iconClassName="fa fa-exclamation-circle"
              title="Please double check your Excel import file."
            >
              You have successfully imported your Excel report! The work orders listed with green
              cloud icons will be imported, and the work orders with red cloud icons may have
              errors. Please review the errors listed below, and if needed, you may update your
              Excel report and import the file again.
            </Alert>
          ) : (
            <Alert
              type="success"
              iconClassName="fa fa-check-circle"
              title="Import Successful! Please review your imported work orders."
            >
              Your import was successful! Next, you&apos;ll assign and schedule your work orders.
            </Alert>
          )}
        </div>

        <h5 className="font-weight-bold mb-3">
          {errors.length > 0 ? <img src={icon1} alt="Step 1" style={{ width: '50px' }} /> : null}
          Here are the Work Orders in this import:
        </h5>

        <p>
          <img
            src={greenCloud}
            alt="Success"
            style={{ width: '45px', height: '45px' }}
          />
          = these work orders will be imported
          <br />
          <img
            src={redCloud}
            alt="Error"
            style={{ width: '45px', height: '45px' }}
          />
          = these work orders will NOT be imported
        </p>

        <ImportTable
          rows={rows}
          textAttributes={TEXT_ATTRIBUTES}
          getAttributeValue={getAttributeValue}
          errors={errors}
        />

        <h5 className="font-weight-bold mb-3">
          {errors.length > 0 ? <img src={icon2} alt="Step 3" style={{ width: '50px' }} /> : null}
          Optional: If your Excel report data is not displaying correctly, please make sure Intimely
          is reading your report columns by checking the column mapping.
        </h5>

        <div className="mb-3">
          <Button onClick={openColumnMappingForm}>
            Change Column Mapping
          </Button>
        </div>

        <h5 className="mb-4 font-weight-bold">
          <img src={icon3} alt="Step 4" style={{ width: '50px' }} />
          Optional: You may import a new Excel report if needed:
        </h5>

        <FileImporter onDrop={onDrop} uploading={uploading} />
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={onSubmit}
          className="btn btn-primary"
          disabled={!rows.find(row => row.valid)}
        >
          Save
        </button>
      </div>
    </>
  );
};

WorkOrderReview.propTypes = {
  closeModal: PropTypes.func.isRequired,
  importObject: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  openColumnMappingForm: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
};

export default WorkOrderReview;
