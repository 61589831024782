import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';
import WorkOrdersContainer from '../containers/WorkOrdersContainer';

const WorkOrders = props => (
  <Provider store={store}>
    <WorkOrdersContainer {...props} />
  </Provider>
);

export default WorkOrders;
