import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { Button } from 'react-bootstrap';
import Alert from '../../../components/Alert';
import ImportTable from './ImportTable';
import { getAttributeValue } from '../helpers';
import ErrorTable from './ErrorTable';

const StatusAlert = ({ errors }) => {
  if (errors.length > 0) {
    return (
      <Alert
        type="danger"
        iconClassName="fa fa-exclamation-circle"
        title="Please double check your Excel import file."
      >
        Please review the errors listed above, update your Excel report, and then import the file
        again.
      </Alert>
    );
  }

  return (
    <Alert
      type="success"
      iconClassName="fa fa-check-circle"
      title="Import Successful! Please review your imported inventory."
    >
      Your import was successful!.
    </Alert>
  );
};

StatusAlert.propTypes = {
  errors: PropTypes.array.isRequired,
};

const InventoryReview = ({
  closeModal,
  importObject,
  onSubmit,
  onDrop,
  openColumnMappingForm,
  uploading,
}) => {
  const errors = get(importObject, 'attributes.import_errors', []) || [];
  const rows = get(importObject, 'attributes.rows', []) || [];

  return (
    <>
      <div className="modal-header justify-content-center">
        <h5 className="font-weight-bold text-center mt-2">Review Imported Inventory</h5>
      </div>

      <div className="modal-body">
        <div className="mb-3">
          <StatusAlert errors={errors} />
        </div>

        <ErrorTable errors={errors} onDrop={onDrop} uploading={uploading} />

        <h5 className="font-weight-bold mb-3">Here is the Inventory in this import:</h5>

        <div className="mb-3">
          <Button onClick={openColumnMappingForm}>Change Column Mapping</Button>
        </div>

        <ImportTable
          rows={rows}
          getAttributeValue={getAttributeValue}
        />
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={onSubmit}
          className="btn btn-primary"
          disabled={!rows.find(row => row.valid)}
        >
          Save
        </button>
      </div>
    </>
  );
};

InventoryReview.propTypes = {
  closeModal: PropTypes.func.isRequired,
  importObject: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  openColumnMappingForm: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
};

export default InventoryReview;
