import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { fetchRecords } from 'redux-json-api-module';
import EventCard from '../EventCard/EventCard';
import { idType } from '../../helpers/propTypes';

import './PlanTimeline.scss';

class PlanTimeline extends React.Component {
  state = {
    loading: true,
    nextPage: 1,
    hasMore: false,
  };

  UNSAFE_componentWillMount() {
    this.fetchEvents();
  }

  fetchEvents = () => {
    const { fetchRecords, planId, planType } = this.props;
    const { nextPage } = this.state;
    this.setState({ loading: true });

    fetchRecords(`${planType}/${planId}/events`, {
      page: {
        size: 20,
        number: nextPage,
      },
      sort: '-events.created_at',
    })
      .then((resp) => {
        if (resp.error) {
          this.setState({ loading: false });
          return;
        }
        this.setState({
          loading: false,
          nextPage: resp.payload.data.meta.next_page,
          hasMore: !!resp.payload.data.meta.next_page,
        });
      });
  };

  render() {
    const { events } = this.props;
    const { loading, hasMore } = this.state;

    return (
      <React.Fragment>
        <div className="timeline plan-timeline">
          {events.map(event => (
            <EventCard event={event} key={event.id} />
          ))}
        </div>
        {hasMore ? (
          <div className="text-center">
            <button
              type="button"
              className="btn btn-primary"
              onClick={this.fetchEvents}
              disabled={loading}
            >
              Load More Events
            </button>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const events = Object.values(state.Api.events || {})
    .sort((a, b) => -moment(a.attributes.created_at)
      .diff(moment(b.attributes.created_at)));

  return {
    events,
  };
};

const mapDispatchToProps = {
  fetchRecords,
};

PlanTimeline.propTypes = {
  fetchRecords: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired,
  planType: PropTypes.string.isRequired,
  planId: idType.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlanTimeline);
