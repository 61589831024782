import React from 'react';
import PropTypes from 'prop-types';
import { attachmentObject } from '../../helpers/propTypes';
import ImageMessage from './ImageMessage';
import AttachmentLink from './AttachmentLink';

const Attachment = ({ attachment, isAuthor }) => (
  attachment.thumb.url
    ? <ImageMessage attachment={attachment} />
    : <AttachmentLink attachment={attachment} isAuthor={isAuthor} />
);

Attachment.propTypes = {
  attachment: attachmentObject.isRequired,
  isAuthor: PropTypes.bool.isRequired,
};

export default Attachment;
