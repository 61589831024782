import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord, getRelationship, saveRecord } from 'redux-json-api-module';
import get from 'lodash.get';
import { Card, CardHeader } from '../../components/Card';
import InspectionScheduler from './InspectionScheduler/InspectionScheduler';
import InspectionServiceSort from './InspectionServiceSort/InspectionServiceSort';
import ProjectSummary from '../../components/ProjectSummary/ProjectSummary';
import ServiceFeatureCards from './ServiceFeatureCards/ServiceFeatureCards';
import Alert from '../../components/Alert';
import { getInspection } from '../../helpers/inspection';
import Loader from '../../components/Loader';
import {
  hydrateProjectManagementStore,
  setValidationWarning,
  fetchProjectForInspection,
} from '../../redux/modules/projectManagement';
import { setCurrentUser } from '../../redux/modules/auth';
import ProjectServiceForm from '../ProjectManagementContainer/ServiceForm/ProjectServiceForm';
import FeatureFormButton from '../../components/FeatureFormButton';
import { firstBy } from 'thenby';

class InspectionReviewContainer extends Component {
  static propTypes = {
    projectId: PropTypes.number.isRequired,
  };

  state = {
    changeServiceFormVisible: false,
    currentFeature: null,
    featureFormVisible: false,
  };

  componentDidMount() {
    const {
      hydrateProjectManagementStore, fetchProjectForInspection, projectId,
      areaItems, descriptionOptions, serviceOptions, conditionOptions,
      setCurrentUser, currentUser, areaOptions,
    } = this.props;

    hydrateProjectManagementStore({
      projectId,
      areaItems,
      descriptionOptions,
      serviceOptions,
      conditionOptions,
      areaOptions,
    });

    fetchProjectForInspection(projectId);
    setCurrentUser(currentUser.data);

    window.PUSHER.subscribe(`project-${projectId}`)
      .bind('image_upload', () => fetchProjectForInspection(projectId));
  }

  componentWillUnmount() {
    const { projectId } = this.props;
    window.PUSHER.unsubscribe(`project-${projectId}`);
  }

  openChangeServiceForm = (feature) => {
    this.setState({
      changeServiceFormVisible: true,
      currentFeature: feature,
    });
  };

  createFeature = (attributes) => {
    const { projectId, saveRecord, fetchProjectForInspection } = this.props;
    const record = {
      id: null,
      type: 'features',
      attributes: {
        ...attributes,
        project_id: projectId,
      },
    };

    return saveRecord(record, { params: { window_id: window.WINDOW_ID } })
      .then(() => fetchProjectForInspection(projectId));
  };

  closeChangeServiceForm = () => {
    this.setState({ changeServiceFormVisible: false });
  };

  openEditServiceForm = (feature) => {
    this.setState({
      editServiceFormVisible: true,
      currentFeature: feature,
    });
  };

  closeEditServiceForm = () => {
    this.setState({ editServiceFormVisible: false });
  };

  render() {
    const {
      project, inspection, areaOptions,
      projectServices, fixedProjectServices, sortableProjectServices,
      areaItems, descriptionOptions, serviceOptions, conditionOptions,
      validationWarning, setValidationWarning, pendingImageUpload,
    } = this.props;

    const {
      changeServiceFormVisible,
      currentFeature,
      editServiceFormVisible,
      featureFormVisible,
    } = this.state;

    return project ? (
      <React.Fragment>
        {validationWarning ? (
          <Alert
            type="warning"
            iconClassName="fa fa-lightbulb-o"
            onDismiss={() => setValidationWarning(null)}
          >
            {validationWarning}
          </Alert>
        ) : null}

        <ProjectSummary project={project} />

        {pendingImageUpload ? (
          <Alert
            type="info"
            iconClassName="fa fa-exclamation-circle"
            title="Inspection Images Pending Upload"
          >
            This inspection has been marked as completed by the assigned team member, but some or
            all of the images are pending upload from their device. The images may be held in queue
            waiting for an active WIFI or cellular data connection, depending on their Intimely
            profile settings. You may ask the assigned team member to check their Intimely mobile
            app for pending uploads by opening the app, clicking on their profile picture or
            initials on the top left, and checking their "Upload Images" settings.
          </Alert>
        ) : null}

        {project.attributes.aasm_state === 'pending_inspection' && (
          <Card id="inspection-schedule">
            <CardHeader title="Inspection Schedule" />
            <InspectionScheduler projectService={inspection} />
          </Card>
        )}

        {projectServices.map(ps => (
          <React.Fragment key={ps.id}>
            <ServiceFeatureCards
              task={ps}
              openChangeServiceForm={this.openChangeServiceForm}
              openEditServiceForm={this.openEditServiceForm}
            />
            <hr />
          </React.Fragment>
        ))}

        {project.attributes.aasm_state === 'inspection_confirmation' && (
          <React.Fragment>
            <h4>Add More Services</h4>
            <p>
              Is any additional work needed? You may add any additional Services with the &quot;Add
              Service&quot;
              button. Then, sort the Service above or below other Services. In the next step,
              you&#39;ll Assign team
              members for each Service, and Schedule the work dates.
            </p>
            <p>
              <FeatureFormButton
                dropdownClassName=""
                dropdownSize="lg"
                areas={areaOptions}
                descriptions={descriptionOptions}
                items={areaItems}
                services={serviceOptions}
                onSubmit={this.createFeature}
                onHide={() => this.setState({ featureFormVisible: false })}
                onShow={() => this.setState({ featureFormVisible: true })}
                visible={featureFormVisible}
              />
            </p>
            <hr />
            <Alert
              type="info"
              iconClassName="fa fa-exclamation-circle"
              title="Service Sorting"
            >
              Please sort this Project's services in the order they should be completed. For
              example, if you wish to
              have Painting completed before Flooring is installed, please drag Painting above
              Flooring. You may use the
              Sort icon to drag and drop Services in the desired order.
            </Alert>
            <Card>
              <CardHeader title="Sort Services" />
              <InspectionServiceSort
                project={project}
                fixedProjectServices={fixedProjectServices}
                sortableProjectServices={sortableProjectServices}
              />
            </Card>
            <p className="text-center">
              <a
                href={`/projects/${project.id}/inspection`}
                data-method="patch"
                className="btn btn-primary btn-lg"
              >
                Confirm Inspection
              </a>
            </p>
          </React.Fragment>
        )}

        <ProjectServiceForm
          componentType="ChangeService"
          key={`change-${currentFeature && currentFeature.id}`}
          visible={changeServiceFormVisible}
          onClose={this.closeChangeServiceForm}
          featureId={currentFeature && currentFeature.id}
          featureType={currentFeature && currentFeature.type}
          areaItems={areaItems}
          descriptionOptions={descriptionOptions}
          serviceOptions={serviceOptions}
          conditionOptions={conditionOptions}
        />

        <ProjectServiceForm
          componentType="Edit"
          key={`edit-${currentFeature && currentFeature.id}`}
          visible={editServiceFormVisible}
          onClose={this.closeEditServiceForm}
          featureId={currentFeature && currentFeature.id}
          featureType={currentFeature && currentFeature.type}
          areaItems={areaItems}
          descriptionOptions={descriptionOptions}
          serviceOptions={serviceOptions}
          conditionOptions={conditionOptions}
        />
      </React.Fragment>
    ) : <Loader />;
  }
}

const mapStateToProps = (state, ownProps) => {
  const project = getRecord(state.Api, {
    type: 'projects',
    id: ownProps.projectId,
  });

  const projectServices = project
    ? getRelationship(state.Api, project.relationships.project_services)
      .sort(firstBy(a => a.attributes.sort))
    : [];

  const inspection = project && getInspection(project, state.Api);
  const pendingImageUpload = !get(inspection, 'attributes.all_inspection_images_uploaded', false);

  return {
    project,
    projectServices: projectServices.filter(ps => !ps.attributes.is_inspection),
    inspection,
    pendingImageUpload,
    fixedProjectServices: projectServices.filter(ps => !ps.attributes.sortable),
    sortableProjectServices: projectServices.filter(ps => ps.attributes.sortable),
    validationWarning: state.ProjectManagement.validationWarning,
  };
};

const mapDispatchToProps = {
  fetchProjectForInspection,
  hydrateProjectManagementStore,
  setCurrentUser,
  setValidationWarning,
  saveRecord,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(InspectionReviewContainer);
