import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

export default class Select extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    errors: PropTypes.arrayOf(PropTypes.string),
    includeBlank: PropTypes.string,
    style: PropTypes.object,
  };

  static defaultProps = {
    name: null,
    onChange: null,
    value: null,
    style: {},
  };

  handleChange = (value) => {
    const { onChange, input } = this.props;

    if (input) input.onChange(value);
    if (onChange) onChange(value);
  };

  render() {
    const {
      name,
      includeBlank,
      options,
      errors,
      value,
      input,
      style,
    } = this.props;

    return (
      <>
        <select
          name={name || get(input, 'name', '')}
          value={value || get(input, 'value', '')}
          onChange={e => this.handleChange(e.target.value)}
          className={`form-control ${errors ? 'is-invalid' : ''}`}
          style={style}
        >

          {includeBlank && <option value="">{includeBlank}</option>}

          {options.map((option, i) => <Option key={i} option={option} />)}
        </select>
        {errors && errors.map(error => <div key={error} className="invalid-feedback">{error}</div>)}
      </>
    );
  }
}

const Option = ({ option }) => {
  if (Array.isArray(option) && Array.isArray(option[1])) {
    return <OptionGroup options={option[1]} label={option[0]} />;
  }

  if (Array.isArray(option)) {
    return <option value={option[1]}>{option[0]}</option>;
  }

  return <option value={option}>{option}</option>;
};

const OptionGroup = ({ options, label }) => (
  <optgroup label={label}>
    {options.map((option, i) => <Option key={i} option={option} />)}
  </optgroup>
);
