import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { Form, Field } from 'react-final-form';
import Modal from 'react-bootstrap/Modal';
import { fetchRecord, deleteRecord } from 'redux-json-api-module';
import { closeMarkComplete, submit } from '../../../redux/modules/markComplete';
import { DateSelect, GroupSelect } from './inputs';
import TaskAttachmentManager from '../AttachmentManager/TaskAttachmentManager';
import { includeData } from '../../../redux/modules/projectManagement';

import 'react-datepicker/dist/react-datepicker.css';
import './MarkCompleteForm.scss';
import Alert from '../../../components/Alert';
import COLORS from '../../../helpers/colors';
import { TYPE_MAP, IMAGE_MAP, ID_MAP, CHILD_TYPE_MAP } from '../../../helpers/types';
import Badge from '../../../components/Badge';

function isDisabled(loading, values) {
  return loading || !values.userId || !values.date;
}

const MarkCompleteForm = (
  {
    initialValues, onSubmit, onCancel, visible,
    taskId, title, loading, userOptions,
    includeData, fetchRecord, deleteRecord,
    planType, taskType, plan, serviceBadge,
  },
) => (
  <Modal
    show={visible}
    onHide={onCancel}
  >
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} id="markCompleteForm" className="mark-complete-form">
          <div className="modal-header">
            <div className="m-auto text-center">
              <h5 className="modal-title font-weight-bold">
                Mark Service As Completed?
              </h5>
              <div className="mt-2">
                <Badge {...serviceBadge} />
                {title}
              </div>
            </div>
          </div>
          <div className="modal-body">
            <Alert type="warning">
              A Manager can manually mark a Service as completed if the assigned
              user is unable to do so on their mobile app. This action cannot be
              undone.
            </Alert>
            <Field
              name="date"
              label="Completion Date"
              component={DateSelect}
            />
            <Field
              name="userId"
              label="Completed By"
              component={GroupSelect}
              options={userOptions}
            />
            <Field
              name="serviceNotes"
              render={({ input }) => (
                <div>
                  <label className="form-control-label">
                    Completion Notes
                  </label>
                  <textarea
                    rows={4}
                    className="w-100"
                    {...input}
                    style={{
                      border: `1px solid ${COLORS.greyBorder}`,
                    }}
                  />
                </div>
              )}
            />
            <label>Attach Service Completion Images</label>
            <TaskAttachmentManager
              taskId={taskId}
              taskType={taskType}
              type={IMAGE_MAP[taskType]}
              parentRelationship={ID_MAP[taskType]}
              planClass={TYPE_MAP[planType]}
              plan={plan}
              onDelete={deleteRecord}
              onCreate={(data) => {
                includeData(data);
                fetchRecord(taskType, taskId);
              }}
              nestedUpload
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onCancel}
              disabled={loading}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isDisabled(loading, values)}
            >
              Save
            </button>
          </div>
        </form>
      )}
    />
  </Modal>
);

const mapStateToProps = (state, ownProps) => {
  const taskType = CHILD_TYPE_MAP[ownProps.plan.type];
  const task = (state.Api[taskType] || {})[state.MarkComplete.taskId];
  const serviceBadge = get(task, 'attributes.service_badge', null);

  return {
    ...state.MarkComplete,
    task,
    taskType,
    serviceBadge,
    attachments: task ? task.attributes.task_images : [],
  };
};

const mapDispatchToProps = {
  onCancel: closeMarkComplete,
  onSubmit: submit,
  includeData,
  fetchRecord,
  deleteRecord,
};

export default connect(mapStateToProps, mapDispatchToProps)(MarkCompleteForm);
