import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord } from 'redux-json-api-module';
import { genericRecordPropType, idType } from '../../../helpers/propTypes';
import moment from 'moment';

const DoneCell = ({ completedAt, progress }) => completedAt || `${progress}%`;

DoneCell.propTypes = {
  taskId: idType.isRequired,
  taskType: PropTypes.string.isRequired,

  // connected
  user: genericRecordPropType,
};

DoneCell.defaultProps = {
  user: null,
};

const mapStateToProps = (state, ownProps) => {
  const task = getRecord(state.Api, { type: ownProps.taskType, id: ownProps.taskId });
  const completedAt = moment(task.attributes.completed_at);

  return {
    completedAt: completedAt.isValid() ? completedAt.format('MM/DD') : null,
    progress: task.attributes.progress || 0,
  };
};

export default connect(
  mapStateToProps,
)(DoneCell);
