import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getRecord, getRelationship } from 'redux-json-api-module';
import Button from 'react-bootstrap/Button';
import { editable } from './helpers';
import { deleteMessage, edit } from '../../redux/modules/chat';

const EditActions = ({
  messageId,
  body,
  createdAt,
  isAuthor,
  deleteMessage,
  edit,
  messageType,
}) => (
  editable(isAuthor, createdAt, messageType) ? (
    <div>
      <Button
        variant="light"
        style={{
          padding: 3,
          paddingLeft: 7,
          paddingRight: 7,
          marginLeft: 5,
          cursor: 'pointer',
        }}
        onClick={() => {
          deleteMessage(messageId);
        }}
      >
        <i className="fa fa-trash" />
      </Button>
      {body ? (
        <Button
          variant="light"
          style={{
            padding: 3,
            paddingTop: 4,
            paddingLeft: 7,
            paddingRight: 7,
          }}
          onClick={() => edit(messageId)}
        >
          <i className="fa fa-edit" />
        </Button>
      ) : null}
    </div>
  ) : <div />
);

EditActions.propTypes = {
  messageId: PropTypes.string.isRequired,
  body: PropTypes.string,
  createdAt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isAuthor: PropTypes.bool.isRequired,
  deleteMessage: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  messageType: PropTypes.string.isRequired,
};

EditActions.defaultProps = {
  body: null,
};

const mapStateToProps = (state, ownProps) => {
  const message = getRecord(state.Api, {
    type: 'messages',
    id: ownProps.messageId,
  });

  const {
    edited_at: editedAt,
    created_at: createdAt,
    body,
    message_type: messageType,
  } = message.attributes;

  return {
    editedAt,
    createdAt,
    body,
    messageType,
  };
};

const mapDispatchToProps = {
  deleteMessage,
  edit,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditActions);
