import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';
import Container from '../containers/PartsTasksContainer';

const PartsTasks = props => (
  <Provider store={store}>
    <Container {...props} />
  </Provider>
);

export default PartsTasks;
