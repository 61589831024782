import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord, getRelationship } from 'redux-json-api-module';
import { Card, CardHeader } from '../../components/Card';
import ProjectSummary from '../../components/ProjectSummary/ProjectSummary';
import CompletionTab from '../../components/ServiceDetails/CompletionTab';
import Loader from '../../components/Loader';
import {
  hydrateProjectManagementStore,
  fetchProjectServices,
  fetchProjectForInspection,
} from '../../redux/modules/projectManagement';
import { setCurrentUser } from '../../redux/modules/auth';
import StateAlert from '../ProjectManagementContainer/StateAlert';
import ProjectServiceAssignmentTable
  from '../ProjectManagementContainer/ProjectServiceAssignmentTable/ProjectServiceAssignmentTable';
import { pluralize } from '../../helpers/string';
import ApproveCompletionButton from './ApproveCompletionButton/ApproveCompletionButton';
import COLORS from '../../helpers/colors';
import { fetchMessageThreads, fetchMessengers } from '../../redux/modules/chat';
import Alert from '../../components/Alert';
import { taskSort } from '../../helpers/sorts';

class ProjectReviewContainer extends Component {
  static propTypes = {
    projectId: PropTypes.number.isRequired,
    fetchProjectServices: PropTypes.func.isRequired,
    hydrateProjectManagementStore: PropTypes.func.isRequired,
    setCurrentUser: PropTypes.func.isRequired,
    fetchProjectForInspection: PropTypes.func.isRequired,
    fetchMessageThreads: PropTypes.func.isRequired,
    fetchMessengers: PropTypes.func.isRequired,
    project: PropTypes.object,
    currentUser: PropTypes.object.isRequired,
    projectServices: PropTypes.array.isRequired,
  };

  static defaultProps = {
    project: null,
  };

  componentDidMount() {
    const {
      hydrateProjectManagementStore, fetchProjectForInspection, projectId,
      setCurrentUser, currentUser, fetchProjectServices, fetchMessengers, fetchMessageThreads,
    } = this.props;

    hydrateProjectManagementStore({
      projectId,
    });

    fetchProjectForInspection(projectId);
    fetchProjectServices(projectId).then(() => (
      fetchMessageThreads('projects', projectId, true)
    ))
      .then(() => (
        fetchMessengers('projects', projectId)
      ));

    setCurrentUser(currentUser.data);
  }

  getSummaryTitle = () => {
    const { project } = this.props;
    if (!project) return null;

    return 'Services Summary - In Progress for '
      + `${project.attributes.duration || 0} ${pluralize('Day', project.attributes.duration)}`;
  };


  render() {
    const {
      project,
      projectServices, projectId,
    } = this.props;

    return project ? (
      <React.Fragment>
        <StateAlert
          aasmState={project.attributes.aasm_state}
        />

        <ProjectSummary project={project} />

        <Card>
          <CardHeader title={this.getSummaryTitle()} />
          <ProjectServiceAssignmentTable
            projectId={projectId}
            users={[]}
          />
        </Card>

        <Alert
          type="info"
          iconClassName="fa fa-exclamation-circle"
          title="Please review completed services."
        >
          For each service, you may compare the Inspection images and the Completion images to
          ensure the work was completed. If you need to add more work or any new services, please
          click the back arrow or the Cancel button below.
        </Alert>

        {projectServices.filter(n => n.attributes.aasm_state === 'completed').map(ps => (
          <React.Fragment key={ps.id}>
            <div className="card">
              <div className="card-body">
                <CompletionTab
                  taskId={ps.id}
                  taskType={ps.type}
                  summary
                />
              </div>
            </div>
            <hr />
          </React.Fragment>
        ))}

        <div className="m-b-15 text-center">
          <a
            href={`/projects/${projectId}`}
            className="m-4"
            style={{
              color: COLORS.grey,
              fontSize: '16px',
            }}
          >
            Cancel
          </a>
          <ApproveCompletionButton project={project} />
        </div>
      </React.Fragment>
    ) : <Loader />;
  }
}

const mapStateToProps = (state, ownProps) => {
  const project = getRecord(state.Api, {
    type: 'projects',
    id: ownProps.projectId,
  });

  let projectServices = project
    ? getRelationship(state.Api, project.relationships.project_services) : [];
  projectServices = projectServices.filter(ps => !ps.attributes.is_inspection);
  projectServices.sort(taskSort);

  return {
    project,
    projectServices,
  };
};

const mapDispatchToProps = {
  fetchProjectForInspection,
  hydrateProjectManagementStore,
  setCurrentUser,
  fetchProjectServices,
  fetchMessengers,
  fetchMessageThreads,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectReviewContainer);
