import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord, getRelationship } from 'redux-json-api-module';
import { genericRecordPropType, idType } from '../../../helpers/propTypes';
import Avatar from '../../../components/Avatar';
import Badge from '../../../components/Badge';
import get from 'lodash.get';

const StatusCell = ({ statusBadge, lateBadge, partsBadge }) => (
  <>
    <Badge {...statusBadge} />
    {lateBadge ? <Badge {...lateBadge} className="ml-1" /> : null}
    {partsBadge ? <Badge {...partsBadge} className="ml-1" /> : null}
  </>
);

StatusCell.propTypes = {
  taskId: idType.isRequired,
  taskType: PropTypes.string.isRequired,

  // connected
  statusBadge: PropTypes.object.isRequired,
  lateBadge: PropTypes.object,
  partsBadge: PropTypes.object,
};

StatusCell.defaultProps = {
  lateBadge: null,
  partsBadge: null,
};

const mapStateToProps = (state, ownProps) => {
  const task = getRecord(state.Api, { type: ownProps.taskType, id: ownProps.taskId });

  return {
    lateBadge: task.attributes.late_badge,
    partsBadge: task.attributes.parts_badge,
    statusBadge: task.attributes.service_status.private,
  };
};

export default connect(
  mapStateToProps,
)(StatusCell);
