import React from 'react';
import PropTypes from 'prop-types';

const BasicNavLink = ({
  children,
  onClick,
  id,
  defaultStyles,
}) => (
  <li>
    <button
      id={id}
      type="button"
      role="tab"
      onClick={onClick}
      className="btn btn-link basic-nav-link"
      style={defaultStyles}
    >
      {children}
    </button>
  </li>
);

BasicNavLink.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
  defaultStyles: PropTypes.object,
};

BasicNavLink.defaultProps = {
  id: null,
  defaultStyles: null,
};

export default BasicNavLink;
