import {
  SAVE_RECORD,
  fetchRecords,
  fetchRecord,
  deleteRecord,
  saveRecord,
} from 'redux-json-api-module';
import get from 'lodash.get';
import toastr from 'toastr';
import { INVERSE_TYPE_MAP } from '../../helpers/types';
import { planMessageThreadSort, directMessageSort } from '../../helpers/sorts';
import { idComparison } from '../../helpers/string';

export const TOUCH = 'intimely/chat/TOUCH';
export const EDIT = 'intimely/chat/EDIT';
export const FETCH_MESSAGE_THREADS_SUCCESS = 'intimely/chat/FETCH_MESSAGE_THREADS_SUCCESS';
export const FETCH_UNREAD_COUNTS_SUCCESS = 'intimely/chat/FETCH_UNREAD_COUNTS_SUCCESS';
export const TOUCH_SUCCESS = 'intimely/chat/TOUCH_SUCCESS';
export const NEW_MESSAGE_SUCCESS = 'intimely/chat/NEW_MESSAGE_SUCCESS';
export const SET_ACTIVE_PROJECT_ID = 'intimely/chat/SET_ACTIVE_PROJECT_ID';
export const SET_ACTIVE_MESSENGER_ID = 'intimely/chat/SET_ACTIVE_MESSENGER_ID';
export const SET_ACTIVE_MESSAGE_THREAD = 'intimely/chat/SET_ACTIVE_MESSAGE_THREAD';
export const SET_ACTIVE_TASK = 'intimely/chat/SET_ACTIVE_TASK';
export const SET_MESSAGES_LOADED = 'intimely/chat/SET_MESSAGES_LOADED';
export const SET_EXPANDED_COMMUNITY_ID = 'intimely/chat/SET_EXPANDED_COMMUNITY_ID';
export const SET_MESSENGERS_LOADED = 'intimely/chat/SET_MESSENGERS_LOADED';
export const FETCH_TAGGABLE_USERS_SUCCESS = 'intimely/chat/FETCH_TAGGABLE_USERS_SUCCESS';
export const SET_FLASHING_MESSAGE_THREAD_ID = 'intimely/chat/SET_FLASHING_MESSAGE_THREAD_ID';
export const RESORT_COMMUNITY_ID_MAP_SUCCESS = 'intimely/chat/RESORT_COMMUNITY_ID_MAP_SUCCESS';
export const SET_CREATING_CHANNEL = 'intimely/chat/SET_CREATING_CHANNEL';
export const SET_EDITING_CHANNEL = 'intimely/chat/SET_EDITING_CHANNEL';
export const SET_TEAM_USER_IDS = 'intimely/chat/SET_TEAM_USER_IDS';
export const SET_ACTIVE_CHANNEL_IDS = 'intimely/chat/ACTIVE_CHANNEL_IDS';
export const SET_ACTIVE_DIRECT_MESSAGE_CHAT_IDS = 'intimely/chat/ACTIVE_DIRECT_MESSAGE_CHAT_IDS';
export const SET_CURRENTLY_ONLINE_USER_IDS = 'intimely/chat/SET_CURRENTLY_ONLINE_USER_IDS';
export const SET_ACTIVE_USER_IDS = 'intimely/chat/SET_ACTIVE_USER_IDS';
export const TOGGLE_ARCHIVE_MODAL = 'intimely/chat/TOGGLE_ARCHIVE_MODAL'

const INITIAL_STATE = {
  editing: null,
  plans: [],
  taggableUsers: [],
  activePlanId: null,
  activePlanType: null,
  activeMessengerId: null,
  activeCommunityId: null,
  expandedCommunityId: null,
  flashingMessageThreadId: null,

  totalUnreadContent: false,
  totalUnreadCount: 0,
  messengersLoaded: false,
  messagesLoaded: false,

  communities: [],
  communityIdMap: {},

  creatingChannel: false,
  editingChannelId: null,
  managementUserIds: [],
  maintenanceUserIds: [],
  activeChannelIds: [],

  activeDirectMessageChatIds: [],
  currentlyOnlineUserIds: [],

  activeUserIds: [],
  archiveModalVisible: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RESORT_COMMUNITY_ID_MAP_SUCCESS:
      return {
        ...state,
        communityIdMap: action.communityIdMap,
        communities: action.communities,
      };
    case SET_FLASHING_MESSAGE_THREAD_ID:
      return {
        ...state,
        flashingMessageThreadId: action.flashingMessageThreadId,
      };
    case SET_ACTIVE_USER_IDS:
      return {
        ...state,
        activeUserIds: action.activeUserIds,
      };
    case SET_MESSAGES_LOADED:
      return {
        ...state,
        messagesLoaded: action.messagesLoaded,
      };
    case SET_MESSENGERS_LOADED:
      return {
        ...state,
        messengersLoaded: true,
      };
    case SET_CREATING_CHANNEL:
      return {
        ...state,
        creatingChannel: action.creatingChannel,
      };
    case SET_ACTIVE_CHANNEL_IDS:
      const activeChannelId = state.activeChannelId && action.activeChannelIds.includes(state.activeChannelId.toString())
        ? state.activeChannelId : null;
      return {
        ...state,
        activeChannelIds: action.activeChannelIds,
        activeChannelId,
      };
    case SET_EDITING_CHANNEL:
      return {
        ...state,
        editingChannelId: action.channelId,
      };
    case SET_ACTIVE_DIRECT_MESSAGE_CHAT_IDS:
      const activeDirectMessageChatId = state.activeDirectMessageChatId && action.activeDirectMessageChatIds.includes(state.activeDirectMessageChatId.toString())
        ? state.activeDirectMessageChatId : null;
      return {
        ...state,
        activeDirectMessageChatIds: action.activeDirectMessageChatIds,
        activeDirectMessageChatId,
      };
    case SET_TEAM_USER_IDS:
      return {
        ...state,
        managementUserIds: action.managementUserIds,
        maintenanceUserIds: action.maintenanceUserIds,
      };
    case FETCH_TAGGABLE_USERS_SUCCESS:
      return {
        ...state,
        taggableUsers: action.taggableUsers,
      };
    case FETCH_MESSAGE_THREADS_SUCCESS:
      return {
        ...state,
        plans: action.plans,
        communities: action.communities,
        communityIdMap: action.communityIdMap,
        activePlanType: action.activePlanType,
        activePlanId: action.activePlanId,
        activeCommunityId: action.activeCommunityId,
        activeDirectMessageChatId: action.activeDirectMessageChatId,
        // to prevent the floating action buctton from
        // flickering when it changes data sources
        messengersLoaded: true,
      };
    case FETCH_UNREAD_COUNTS_SUCCESS:
      return {
        ...state,
        totalUnreadContent: action.unreadContent,
        totalUnreadCount: action.unreadCount,
      };
    case EDIT:
      return {
        ...state,
        editing: action.messageId,
      };
    case SET_ACTIVE_MESSAGE_THREAD:
      return {
        ...state,
        activePlanId: action.planId,
        activePlanType: action.planType,
        activeMessageThreadId: action.messageThreadId,
        activeCommunityId: action.communityId,
        activeChannelId: action.channelId,
        activeDirectMessageChatId: action.directMessageChatId,
        expandedCommunityId: action.communityId,
      };
    case SET_EXPANDED_COMMUNITY_ID:
      return {
        ...state,
        expandedCommunityId: action.communityId,
      };
    case SET_CURRENTLY_ONLINE_USER_IDS:
      return {
        ...state,
        currentlyOnlineUserIds: action.currentlyOnlineUserIds,
      };
    case TOGGLE_ARCHIVE_MODAL:
      return {
        ...state,
        archiveModalVisible: action.visible,
      }
    default:
      return state;
  }
}

const PLAN_TYPES = [
  'Project',
  'WorkOrder',
];

export function getPlanThreads(state) {
  return Object.values(state.Api.message_threads || {})
    .filter(thread => PLAN_TYPES.includes(thread.attributes.subject_type));
}

export function getActiveTotals(state, userId) {
  let activeTotalUnreadCount = 0;
  let activeTotalUnreadContent = false;
  Object.values(state.Api.messengers || {})
    .forEach((messenger) => {
      const messengerUserId = get(messenger, 'attributes.user_id', '');
      if (messengerUserId.toString() !== userId.toString()) return;
      activeTotalUnreadCount += messenger.attributes.parent_unread_count;
      if (messenger.attributes.parent_unread_content) activeTotalUnreadContent = true;
    });

  return {
    activeTotalUnreadCount,
    activeTotalUnreadContent,
  };
}

export function getMessenger(state, messageThreadId, userId) {
  if (!messageThreadId || !userId) return null;
  return Object.values(state.Api.messengers || {})
    .find(messenger => (
      messenger.attributes.message_thread_id.toString() === messageThreadId.toString()
      && messenger.attributes.user_id.toString() === userId.toString()
    ));
}

function sortCommunitiesAndThreads(state, communityThreadMap) {
  const communityIdMap = {};
  const recentThreads = [];
  const sort = planMessageThreadSort(state.Api);

  Object.keys(communityThreadMap || {})
    .forEach((id) => {
      const threads = communityThreadMap[id];
      threads.sort(sort);
      communityIdMap[id] = threads.map(t => t.id);
      recentThreads.push(threads[0]);
    });

  recentThreads.sort(sort);
  const communities = recentThreads.map(t => t.attributes.community);

  return {
    communityIdMap,
    communities,
  };
}

export function getPlanCommunityIdMap(planThreads, state) {
  const communityMap = {};
  planThreads.forEach((thread) => {
    const communityId = get(thread, 'attributes.community_id', null);
    if (communityMap[communityId] === undefined) {
      communityMap[communityId] = [];
    }
    communityMap[communityId].push(thread);
  });

  const {
    communityIdMap,
    communities,
  } = sortCommunitiesAndThreads(state, communityMap);

  return {
    communityIdMap,
    communities,
  };
}

function initialize(state) {
  const planThreads = getPlanThreads(state);
  const plans = planThreads.map(thread => ({
    type: INVERSE_TYPE_MAP[thread.attributes.subject_type],
    id: thread.attributes.subject_id,
  }));

  const { communityIdMap, communities } = getPlanCommunityIdMap(planThreads, state);

  return {
    plans,
    communities,
    communityIdMap,
  };
}

function handleMessageThreadsResponse(dispatch, getState, resp, setCommunities) {
  if (resp.error) return resp;

  let {
    plans,
    communities,
    communityIdMap,
    activePlanType,
    activePlanId,
    activeCommunityId,
  } = getState().Chat;

  const initializedIds = initialize(getState());

  if (setCommunities) {
    plans = initializedIds.plans;
    communities = initializedIds.communities;
    communityIdMap = initializedIds.communityIdMap;
  }

  return dispatch({
    type: FETCH_MESSAGE_THREADS_SUCCESS,
    plans,
    communities,
    communityIdMap,
    activePlanType,
    activePlanId,
    activeCommunityId,
  });
}

export function fetchMessageThreadCounts(messageThreadId) {
  return dispatch => dispatch(fetchRecords(`message_threads/${messageThreadId}/counts`));
}

export function setFlashingMessageThreadId(messageThreadId) {
  return {
    type: SET_FLASHING_MESSAGE_THREAD_ID,
    flashingMessageThreadId: messageThreadId,
  };
}

export function setCreatingChannel(creatingChannel) {
  return {
    type: SET_CREATING_CHANNEL,
    creatingChannel,
  };
}

export function setEditingChannel(channelId) {
  return {
    type: SET_EDITING_CHANNEL,
    channelId,
  };
}

export function setActiveChannelIds(activeChannelIds) {
  return {
    type: SET_ACTIVE_CHANNEL_IDS,
    activeChannelIds,
  };
}

export function setActiveDirectMessageChatIds(activeDirectMessageChatIds) {
  return {
    type: SET_ACTIVE_DIRECT_MESSAGE_CHAT_IDS,
    activeDirectMessageChatIds,
  };
}

export function saveChannel(attributes) {
  const userIds = [];
  Object.keys(attributes)
    .forEach((key) => {
      if (key.includes('user') && key !== 'user_ids' && attributes[key]) {
        userIds.push(key.replace('user_', ''));
      }
    });

  let channelId;
  let
    error;
  let messageThreadId;

  return (dispatch, getState) => dispatch(saveRecord({
    type: 'chat_channels',
    id: getState().Chat.editingChannelId,
    attributes: {
      name: attributes.name,
      public: attributes.public,
      initial_user_ids: userIds,
    },
    include: 'message_thread',
  }, { params: { window_id: window.WINDOW_ID } }))
    .then((resp) => {
      if (resp.error) {
        error = resp.error;
        toastr.error('Error Saving the Channel, please choose unique name.');
        return resp;
      }

      channelId = get(resp, 'payload.data.data.id', null);
      messageThreadId = get(resp, 'payload.data.data.relationships.message_thread.data.id', null);

      return attributes.public ? Promise.resolve({}) : dispatch(saveRecord({
        type: 'message_threads',
        id: messageThreadId,
        attributes: {
          initial_users: !getState().Chat.editingChannelId,
          messengers_attributes: userIds.map(id => ({
            user_id: id,
          })),
        },
      }, { params: { window_id: window.WINDOW_ID } }));
    })
    .then(() => dispatch(fetchChatChannels()))
    .then(() => {
      if (!error) {
        dispatch(setCreatingChannel(false));
        if (channelId) dispatch(fetchChannelMessages(channelId));
        if (channelId && messageThreadId) {
          dispatch(setActiveMessageThread({
            planType: null,
            planId: null,
            directMessageChatId: null,
            channelId,
            messageThreadId,
          }));
        }

        return dispatch(setEditingChannel(null));
      }
    });
}

function handleMessageThreadCreation(directMessageChatId, messageThreadId) {
  return (dispatch) => {
    dispatch(fetchDirectMessageChatMessages(directMessageChatId));
    dispatch(setActiveMessageThread({
      planType: null,
      planId: null,
      channelId: null,
      directMessageChatId,
      messageThreadId,
    }));
  };
}

function exists(receiverId, userId, state) {
  return Object.values(state.Api.direct_message_chats || {})
    .find(chat => (idComparison(chat.attributes.receiver_id, receiverId)
      && idComparison(chat.attributes.creator_id, userId))
      || (idComparison(chat.attributes.receiver_id, userId)
        && idComparison(chat.attributes.creator_id, receiverId)));
}

export function saveDirectMessageChat(attributes, userId) {
  let receiverId;
  Object.keys(attributes)
    .forEach((key) => {
      if (key.includes('user') && key !== 'user_ids' && attributes[key]) {
        receiverId = key.replace('user_', '');
      }
    });

  return (dispatch, getState) => {
    const existingChat = exists(receiverId, userId, getState());
    if (existingChat) {
      const messageThreadId = get(existingChat, 'relationships.message_thread.data.id', null);
      dispatch(handleMessageThreadCreation(existingChat.id, messageThreadId));
    } else {
      return dispatch(saveRecord({
        type: 'direct_message_chats',
        attributes: {
          receiver_id: receiverId,
        },
        include: 'message_thread',
      }, { params: { window_id: window.WINDOW_ID } }))
        .then((resp) => {
          if (resp.error) return resp;

          const chat = get(resp, 'payload.data.data', null);

          if (chat) {
            const messageThreadId = get(chat, 'relationships.message_thread.data.id', null);
            dispatch(handleMessageThreadCreation(chat.id, messageThreadId));
          }

          return dispatch(fetchDirectMessageChats(userId));
        });
    }
  };
}

function getUserIds(resp) {
  const managementUserIds = [];
  const maintenanceUserIds = [];
  Object.values(resp.payload.data.included)
    .forEach((obj) => {
      // not excluding current user because he needs to be able ot remove himself
      if (obj.type === 'users') {
        if (obj.attributes.app_role === 'maintenance') {
          maintenanceUserIds.push(obj.id);
        } else {
          managementUserIds.push(obj.id);
        }
      }
    });
  return {
    managementUserIds,
    maintenanceUserIds,
  };
}

function setTeamUserIds({ managementUserIds, maintenanceUserIds }) {
  return {
    type: SET_TEAM_USER_IDS,
    managementUserIds,
    maintenanceUserIds,
  };
}

export function fetchTeamMembers(teamId) {
  return dispatch => dispatch(
    fetchRecord('teams', teamId, {
      include: 'memberships,memberships.user',
      page: { size: 250 },
    }),
  )
    .then((resp) => {
      if (resp.error) return resp;

      return dispatch(setTeamUserIds(getUserIds(resp)));
    });
}


function setCurrentlyOnlineUserIds(currentlyOnlineUserIds) {
  return {
    type: SET_CURRENTLY_ONLINE_USER_IDS,
    currentlyOnlineUserIds,
  };
}

export function fetchCurrentlyOnline(teamId) {
  return dispatch => dispatch(
    fetchRecords(`teams/${teamId}/currently_online`, {
      page: { size: 250 },
    }),
  )
    .then((resp) => {
      if (resp.error) return resp;

      return dispatch(setCurrentlyOnlineUserIds(resp.payload.data.currently_online));
    });
}

export function fetchChatChannels() {
  return dispatch => dispatch(fetchRecords('chat_channels', {
    include: 'message_thread',
    page: { size: 250 },
  }))
    .then((resp) => {
      if (resp.error) return resp;

      const activeChannels = get(resp, 'payload.data.data', []);
      activeChannels.sort((a, b) => a.attributes.name.localeCompare(b.attributes.name));
      const activeChannelIds = activeChannels.map(channel => channel.id);

      return dispatch(setActiveChannelIds(activeChannelIds));
    });
}

export function fetchDirectMessageChats(userId) {
  return dispatch => dispatch(fetchRecords('direct_message_chats', {
    include: 'message_thread,receiver,creator',
    page: { size: 250 },
  }))
    .then((resp) => {
      if (resp.error) return resp;

      const activeDirectMessageChats = get(resp, 'payload.data.data', []);
      activeDirectMessageChats.sort(directMessageSort(userId));
      const activeDirectMessageChatIds = activeDirectMessageChats.map(chat => chat.id);

      return dispatch(setActiveDirectMessageChatIds(activeDirectMessageChatIds));
    });
}

export function touch(messageThreadId) {
  return (dispatch, getState) => (dispatch(
    {
      type: SAVE_RECORD,
      payload: {
        request: {
          method: 'PATCH',
          url: `/message_threads/${messageThreadId}/touch`,
        },
      },
    },
  )).then((resp) => {
    const { flashingMessageThreadId } = getState().Chat;
    if (flashingMessageThreadId
      && flashingMessageThreadId.toString() === messageThreadId.toString()) {
      dispatch(setFlashingMessageThreadId(null));
    }

    setTimeout(() => dispatch(fetchMessageThreadCounts(messageThreadId)), 1250);

    return resp;
  });
}

export function fetchMessageThreads(planType, planId) {
  return (dispatch, getState) => dispatch(fetchRecords(`${planType}/${planId}/message_threads`, {
    page: { size: 250 },
    include: 'last_message',
  }))
    .then(resp => handleMessageThreadsResponse(dispatch, getState, resp));
}

export function deleteMessage(messageId) {
  return (dispatch) => {
    if (!window.confirm('Are you sure you want to delete this message?')) return Promise.resolve({});
    return dispatch(deleteRecord({
      type: 'messages',
      id: messageId,
    }));
  };
}

export function getUnreadCounts(userId) {
  return dispatch => (
    dispatch(fetchRecords(`users/${userId}/unread_counts`))
      .then(resp => (
        dispatch({
          type: FETCH_UNREAD_COUNTS_SUCCESS,
          unreadCount: get(resp, 'payload.data.unread_count', 0),
          unreadContent: get(resp, 'payload.data.unread_content', false),
        })
      ))
  );
}

export function edit(messageId) {
  return {
    type: EDIT,
    messageId,
  };
}

export function setMessengersLoaded() {
  return {
    type: SET_MESSENGERS_LOADED,
  };
}

export function fetchChannelMessengers() {
  return dispatch => dispatch(fetchRecords('messengers/channel', {
    page: { size: 1000 },
    sort: '-id',
  }))
    .then(() => dispatch(setMessengersLoaded()));
}

export function fetchDirectMessageChatMessengers() {
  return dispatch => dispatch(fetchRecords('messengers/direct_message_chat', {
    page: { size: 1000 },
    sort: '-id',
  }))
    .then(() => dispatch(setMessengersLoaded()));
}

export function fetchMessengers() {
  return dispatch => dispatch(fetchRecords('messengers', {
    page: { size: 1000 },
    sort: '-id',
  }))
    .then(() => dispatch(setMessengersLoaded()));
}

export function setExpandedCommunityId(communityId) {
  return dispatch => dispatch({
    type: SET_EXPANDED_COMMUNITY_ID,
    communityId,
  });
}

export function setActiveMessageThread({
  planType,
  planId,
  communityId,
  channelId,
  directMessageChatId,
  messageThreadId,
  noTouch,
}) {
  return (dispatch) => {
    if (messageThreadId && !noTouch) dispatch(touch(messageThreadId));

    return dispatch({
      type: SET_ACTIVE_MESSAGE_THREAD,
      planType,
      planId,
      communityId,
      directMessageChatId,
      channelId,
      messageThreadId,
    });
  };
}

export function fetchTaggableUsers(teamId) {
  return dispatch => dispatch(fetchRecords(`teams/${teamId}/taggable_users`))
    .then(resp => dispatch({
      type: FETCH_TAGGABLE_USERS_SUCCESS,
      taggableUsers: resp.payload.data.taggable_users,
    }));
}

export function handleNewMessage(event) {
  return (dispatch) => {
    const messageThreadId = get(event, 'message_thread_id', null);
    const messageId = get(event, 'message_id', null);

    if (messageThreadId) {
      dispatch(fetchRecords(`message_threads/${messageThreadId}/counts`));
    }

    return messageId ? dispatch(fetchRecord('messages', messageId)) : Promise.resolve({});
  };
}

export function fetchMessageThreadIndex() {
  return (dispatch, getState) => dispatch(fetchRecords('message_threads', {
    page: { size: 250 },
    sort: '-id',
    filter: { scope: 'plans' },
  }))
    .then(resp => handleMessageThreadsResponse(dispatch, getState, resp, true));
}

export function setMessagesLoaded(messagesLoaded) {
  return {
    type: SET_MESSAGES_LOADED,
    messagesLoaded,
  };
}


// to keep policy for messages simple, access messages differently, through parent
export function fetchChannelMessages(channelId) {
  return (dispatch) => {
    dispatch(setMessagesLoaded(false));
    return dispatch(fetchRecords(`chat_channels/${channelId}/messages`, {
      page: { size: 250 },
      sort: '-created_at',
    }))
      .then(() => {
        dispatch(fetchRecords(`chat_channels/${channelId}/messages`, {
          page: { size: 250 },
          sort: '-created_at',
        }));

        return dispatch(setMessagesLoaded(true));
      });
  };
}

export function fetchDirectMessageChatMessages(directMessageChatId) {
  return (dispatch) => {
    dispatch(setMessagesLoaded(false));
    return dispatch(fetchRecords(`direct_message_chats/${directMessageChatId}/messages`, {
      page: { size: 10 },
      sort: '-created_at',
    }))
      .then(() => {
        dispatch(fetchRecords(`direct_message_chats/${directMessageChatId}/messages`, {
          page: { size: 250 },
          sort: '-created_at',
        }));

        return dispatch(setMessagesLoaded(true));
      });
  };
}

export function fetchPlanMessages(messageThreadId) {
  // get some messages quickly,
  // load the backlog later (can't use page[:number] because the size changes)

  return (dispatch) => {
    dispatch(setMessagesLoaded(false));
    return dispatch(fetchRecords(`message_threads/${messageThreadId}/messages`, {
      page: { size: 10 },
      sort: '-created_at',
    }))
      .then(() => {
        dispatch(fetchRecords(`message_threads/${messageThreadId}/messages`, {
          page: { size: 250 },
          sort: '-created_at',
        }));

        return dispatch(setMessagesLoaded(true));
      });
  };
}

export function fetchMessages(messageThreadId) {
  return (dispatch, getState) => {
    if (getState().Chat.activeChannelId) {
      return dispatch(fetchChannelMessages(getState().Chat.activeChannelId));
    }
    return dispatch(fetchPlanMessages(messageThreadId));
  };
}

export function deleteChannel(channelId) {
  if (!window.confirm('Are you sure you want to delete this Channel?')) return Promise.resolve({});
  return (dispatch, getState) => dispatch(deleteRecord({
    type: 'chat_channels',
    id: channelId,
  }))
    .then(() => dispatch(setActiveMessageThread({
      planType: null,
      planId: null,
      channelId: null,
      directMessageChatId: null,
      messageThreadId: null,
    })))
    .then(() => (
      dispatch(setActiveChannelIds(getState()
        .Chat
        .activeChannelIds
        .filter(id => !idComparison(id, channelId))))
    ))
    .then(() => (
      dispatch(setEditingChannel(null))
    ));
}

export function getMessages(api, messageThreadId) {
  return Object.values(api.messages || {})
    .filter((message) => {
      const id = get(message, 'attributes.message_thread_id', null);
      const deletedAt = get(message, 'attributes.deleted_at', null);
      return idComparison(id, messageThreadId) && !deletedAt;
    });
}

// reduces pusher data requirements
export function getMessengerData(api, messageThreadId) {
  const messages = getMessages(api, messageThreadId);
  const messengers = {};
  messages.forEach((message) => {
    const messenger = get(message, 'attributes.messenger', null);
    if (messenger) messengers[messenger.id] = messenger;
  });

  return messengers;
}

export function fetchMessageThreadMessengers() {
  return (dispatch, getState) => (
    dispatch(fetchRecords(`message_threads/${getState().Chat.activeMessageThreadId}/user_listings`))
  ).then((resp) => {
    if (resp.error) return resp;

    const activeUserIds = resp.payload.data.data.map(u => u.id);

    return dispatch({
      type: SET_ACTIVE_USER_IDS,
      activeUserIds,
    });
  });
}

export function setLastActive(messageThreadId) {
  return dispatch => dispatch(fetchRecord('message_threads', messageThreadId))
    .then((resp) => {
      const {
        community,
        subject_id: subjectId,
        subject_type: subjectType,
      } = resp.payload.data.data.attributes;

      const planType = INVERSE_TYPE_MAP[subjectType];
      let channelId;
      let directMessageChatId;
      let planId;

      if (subjectType === 'ChatChannel') {
        channelId = subjectId;
      } else if (subjectType === 'DirectMessageChat') {
        directMessageChatId = subjectId;
      } else {
        planId = subjectId;
      }

      return dispatch({
        type: SET_ACTIVE_MESSAGE_THREAD,
        planId,
        planType,
        channelId,
        directMessageChatId,
        messageThreadId,
        communityId: community ? community.id : null,
      });
    });
}

export const toggleArchiveModal = visible => ({
  type: TOGGLE_ARCHIVE_MODAL,
  visible,
})
