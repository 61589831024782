import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord } from 'redux-json-api-module';
import get from 'lodash.get';
import withTagging from './withTagging';
import { textSectionObject, idType } from '../../helpers/propTypes';
import MessengerForm from './MessengerForm';
import { edit } from '../../redux/modules/chat';
import { getHtmlBody } from './helpers';

const Body = ({
  textSections,
  isAuthor,
  isEditing,
  messageThreadId,
  messageId,
  text,
  edit,
  canTag,
}) => (
  isEditing ? (
    <MessengerForm
      messageId={messageId}
      messageThreadId={messageThreadId}
      body={text}
      reset={() => edit(null)}
      canTag={canTag}
    />
  ) : (
    <div className={`box ${isAuthor ? 'bg-light-inverse' : 'bg-light-info'} p-0`}>
      {getHtmlBody(textSections)}
    </div>
  )
);

Body.propTypes = {
  textSections: PropTypes.arrayOf(textSectionObject).isRequired,
  isAuthor: PropTypes.bool.isRequired,
  messageId: PropTypes.string.isRequired,
  messageThreadId: idType.isRequired,
  text: PropTypes.string.isRequired,
  isEditing: PropTypes.bool.isRequired,
  edit: PropTypes.func.isRequired,
  canTag: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const message = getRecord(state.Api, {
    type: 'messages',
    id: ownProps.messageId,
  });
  const messageThreadId = get(message, 'attributes.message_thread_id');
  const messageThread = getRecord(state.Api, { type: 'message_threads', id: ownProps.messageThreadId });

  return {
    messageThreadId,
    isEditing: state.Chat.editing === ownProps.messageId,
    canTag: get(messageThread, 'attributes.subject_type', null) !== 'DirectMessageChat',
  };
};

const mapDispatchToProps = {
  edit,
};

export default withTagging(connect(mapStateToProps, mapDispatchToProps)(Body));
