import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getRecord } from 'redux-json-api-module';
import get from 'lodash.get';
import hashtag from '../../../assets/images/icon/hashtag.png';
import lock from '../../../assets/images/icon/lock.png';

const ChannelLabel = ({
  name,
  isPublic,
}) => {
  const icon = isPublic ? (
    <img src={hashtag} style={{ width: '18px' }} />
  ) : (
    <img src={lock} style={{ width: '18px' }} />
  );

  return (
    <div style={{ width: '20%', whiteSpace: 'nowrap' }}>
      {icon}
      {name}
    </div>
  );
};

ChannelLabel.propTypes = {
  name: PropTypes.string.isRequired,
  isPublic: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const channel = getRecord(state.Api, { type: 'chat_channels', id: state.Chat.activeChannelId });
  const name = get(channel, 'attributes.name', '');
  const isPublic = get(channel, 'attributes.public', false);

  return {
    name,
    isPublic,
  };
};

export default connect(mapStateToProps)(ChannelLabel);
