import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Card, Col, Row } from 'react-bootstrap';
import { fetchRecords } from 'redux-json-api-module';
import { Form, Field } from 'react-final-form';
import { firstBy } from 'thenby';
import moment from 'moment';
import qs from 'qs';
import DateInput from '../../components/Inputs/DateInput';
import './styles.scss';

const ReportsContainer = ({ fetchRecords, properties }) => {
  const handleSubmit = (values) => {
    const params = qs.stringify(values, { arrayFormat: 'brackets' });
    const url = `/reports/total_parts_labor.${values.format}?${params}`;

    return fetch(url)
      .then(resp => resp.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `total_parts_labor.${values.format}`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert('oh no!'));
  };

  useEffect(() => {
    fetchRecords('properties', {
      page: { size: 1000 },
    });
  }, []);

  return (
    <Card>
      <Card.Body>
        <Card.Title>Total Parts & Labor Report</Card.Title>
        <Form
          onSubmit={handleSubmit}
          initialValues={{
            property_ids: Object.keys(properties),
            plan_types: ['projects', 'work_orders'],
            start_date: moment().subtract(1, 'year').toDate(),
            end_date: moment().toDate(),
            format: 'pdf',
          }}
          validate={(values) => {
            const errors = {};
            if (!values.property_ids || values.property_ids.length === 0) {
              errors.username = 'Select at least 1';
            }

            if (!values.start_date) {
              errors.start_date = 'Required';
            }

            if (!values.end_date) {
              errors.end_date = 'Required';
            }

            if (values.start_date && values.end_date) {
              if (moment(values.start_date).isAfter(moment(values.end_date))) {
                errors.start_date = 'Must be before end date';
              }
            }

            return errors;
          }}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <Row>
                <Col>
                  <div className="form-group">
                    <label>Properties</label>
                    {Object.values(properties)
                      .sort(firstBy(a => a.attributes.name))
                      .map(p => (
                        <div key={p.id}>
                          <label>
                            <Field
                              name="property_ids"
                              component="input"
                              type="checkbox"
                              value={p.id}
                            />
                            {` ${p.attributes.name}`}
                          </label>
                        </div>
                      ))}
                    <p>
                      <a href="/units#properties">Add Property Group</a>
                    </p>
                  </div>
                </Col>
                <Col>
                  <div className="form-group">
                    <label>Start Date</label>
                    <div className="d-block w-100">
                      <Field
                        name="start_date"
                        component={DateInput}
                        className="form-control"
                      />
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="form-group">
                    <label>End Date</label>
                    <div className="d-block w-100">
                      <Field
                        name="end_date"
                        component={DateInput}
                        className="form-control"
                      />
                    </div>
                  </div>
                </Col>
                <Col>
                  <div className="form-group">
                    <label>Project Types</label>
                    <div>
                      <label>
                        <Field
                          name="plan_types"
                          component="input"
                          type="checkbox"
                          value="projects"
                        />
                        {' Make Ready\'s'}
                      </label>
                    </div>
                    <div>
                      <label>
                        <Field
                          name="plan_types"
                          component="input"
                          type="checkbox"
                          value="work_orders"
                        />
                        {' Work Orders'}
                      </label>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="form-group">
                    <label>Format</label>
                    <div>
                      <label>
                        <Field
                          name="format"
                          component="input"
                          type="radio"
                          value="pdf"
                        />
                        {' PDF'}
                      </label>
                    </div>
                    <div>
                      <label>
                        <Field
                          name="format"
                          component="input"
                          type="radio"
                          value="xlsx"
                        />
                        {' Excel'}
                      </label>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <button
                    type="submit"
                    disabled={submitting}
                    className="btn btn-success"
                  >
                    Generate Report
                  </button>
                </Col>
              </Row>
            </form>
          )}
        />
      </Card.Body>
    </Card>
  );
};

ReportsContainer.propTypes = {};

const mapStateToProps = state => ({
  properties: state.Api.properties || {},
});

const mapDispatchToProps = {
  fetchRecords,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportsContainer);
