import React from 'react';
import Select from 'react-select';

export const CustomInput = ({ input, meta, placeholder }) => (
  <div
    className={`form-group required ${meta.error && meta.touched && 'form-group-invalid'}`}
  >
    <input
      {...input}
      className={`form-control ${meta.touched && meta.error ? 'is-invalid' : null}`}
      placeholder={placeholder}
    />
    {meta.error ? (
      <div className="invalid-feedback">{meta.error}</div>
    ) : null}
  </div>
);

export const SelectInput = ({ input, meta, options, label, includeBlank }) => {
  const formattedOptions = formatOptions(options);

  return (
    <div
      className={`form-group required ${meta.touched && meta.error && 'form-group-invalid'}`}
    >
      <label className="form-control-label required">
        {label}
      </label>
      <Select
        {...input}
        value={inputValue(input.value, formattedOptions)}
        onChange={({ value }) => input.onChange(value)}
        placeholder={includeBlank}
        options={formattedOptions}
      />
      {meta.touched && meta.error ? (
        <div className="invalid-feedback d-block">{meta.error}</div>
      ) : null}
    </div>
  );
};

const formatOptions = options => (
  options.map((option) => {
    if (Array.isArray(option) && Array.isArray(option[1])) {
      return formatOptionGroup(option[0], option[1]);
    }

    if (Array.isArray(option)) {
      return { value: option[1], label: option[0] };
    }

    return { value: option, label: option };
  })
);

const formatOptionGroup = (label, options) => (
  { label, options: formatOptions(options) }
);

const inputValue = (value, options) => {
  let selectedValue;

  options.forEach((option) => {
    if (option.options) {
      selectedValue = inputValue(value, option.options);
    }

    selectedValue = option.value === value ? option : undefined;
  });

  return selectedValue;
};
