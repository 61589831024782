import React from 'react';
import PropTypes from 'prop-types';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import highchartsGantt from 'highcharts/modules/gantt';
import draggablePoints from 'highcharts/modules/draggable-points';
import moment from 'moment';
import COLORS from '../../../helpers/colors';

highchartsGantt(Highcharts);
draggablePoints(Highcharts);

const GanttChart = ({ eventData, handleClick, handleDrag, handleDragEnd, handleSelectDate }) => {
  if (eventData && eventData.length === 0) return null;

  const options = {
    chart: {
      panning: {
        enabled: true,
        type: 'x',
      },
      events: {
        load() {
          const chart = this;
          if (eventData[1]) {
            chart.xAxis[0].setExtremes(
              moment().startOf('day').valueOf(),
              moment().add(14, 'days').valueOf(),
            );
          }
        },
        click(e) {
          if (handleSelectDate) handleSelectDate(e.xAxis[0].value);
        },
      },
    },
    series: [{
      name: '',
      data: eventData,
    }],
    xAxis: [{
      labels: {
        formatter() {
          return moment.utc(this.value).format('MM/DD');
        },
      },
      tickInterval: 1000 * 60 * 60 * 24,
    }, {
      dateTimeLabelFormats: {
        day: '%a',
      },
      tickInterval: 1000 * 60 * 60 * 24,
    }],
    plotOptions: {
      series: {
        animation: false,
        cursor: 'pointer',
        point: {
          events: {
            click: handleClick,
            drop: handleDragEnd,
            drag: handleDrag,
          },
        },
        dragDrop: {
          draggableX: true,
          dragPrecisionX: 1000 * 60 * 60 * 24 - 1,
        },
        dataLabels: {
          enabled: true,
          format: '{point.name}',
          textOverflow: 'none',
          style: {
            cursor: 'default',
            pointerEvents: 'none',
            textOutline: 'none',
          },
        },
      },
    },
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        constructorType="ganttChart"
        options={options}
      />
      <div style={{
        width: '100%',
        paddingRight: '25px',
        height: '64px',
        position: 'absolute',
        bottom: '0px',
      }}
      >
        <div style={{
          width: '100%',
          height: '64px',
          backgroundColor: COLORS.white,
        }}
        />
      </div>
    </div>
  );
};

GanttChart.propTypes = {
  eventData: PropTypes.array.isRequired,
  handleClick: PropTypes.func.isRequired,
  handleDrag: PropTypes.func.isRequired,
  handleDragEnd: PropTypes.func.isRequired,
  handleSelectDate: PropTypes.func,
};

GanttChart.defaultProps = {
  handleSelectDate: null,
};

export default GanttChart;
