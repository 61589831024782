import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Badge from '../Badge';
import COLORS from '../../helpers/colors';
import { CALENDAR_TITLE_MAP } from '../../helpers/types';

const DueBadge = ({ title }) => (
  <div className="text-left">
    <Badge
      text_color={COLORS.white}
      background_color={COLORS.green}
      label={`${title} Due`}
      icon="icon/checkMarkGreen.png"
      iconLeft
      style={{ display: 'inline-block' }}
    />
  </div>
);

const DeadlineBadge = () => (
  <div className="text-left">
    <Badge
      text_color={COLORS.white}
      background_color={COLORS.red}
      label="21-Day Deadline"
      icon="warning"
      iconLeft
      style={{ display: 'inline-block' }}
    />
  </div>
);

class DateHeader extends PureComponent {
  static propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
    dueDate: PropTypes.any,
    deadline: PropTypes.any,
    planType: PropTypes.string.isRequired,
  };

  static defaultProps = {
    dueDate: null,
    deadline: null,
  };

  getDueBadge = () => {
    const { date, dueDate, planType } = this.props;

    const title = CALENDAR_TITLE_MAP[planType] || 'Project';

    return moment(date)
      .isSame(moment(dueDate), 'day')
      ? <DueBadge title={title} /> : null;
  };

  getDeadlineBadge = () => {
    const { date, deadline, planType } = this.props;

    return moment(date)
      .isSame(moment(deadline), 'day') && planType === 'projects'
      ? <DeadlineBadge /> : null;
  };

  render() {
    const { date } = this.props;

    const dateNumber = moment(date)
      .format('DD');

    return (
      <React.Fragment>
        <a href="#">{dateNumber}</a>
        {this.getDueBadge()}
        {this.getDeadlineBadge()}
      </React.Fragment>
    );
  }
}


export default DateHeader;
