import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord, saveRecord, getRelationship } from 'redux-json-api-module';
import { UserAvatar } from '../../../components/Avatar';
import { userObject } from '../../../helpers/propTypes';

const UserRow = ({
  user,
  hourlyRate,
  saveRecord,
}) => {
  const [rate, setHourlyRate] = useState(hourlyRate ? hourlyRate.attributes.hourly_rate : 0);

  const handleChange = (event) => {
    setHourlyRate(event.target.value);
  };

  const handleBlur = (event) => {
    const record = {
      type: 'users',
      id: user.id,
      attributes: {
        hourly_rate_attributes: {
          hourly_rate: event.target.value,
        },
      },
    };

    saveRecord(record, { params: { window_id: window.WINDOW_ID } });
  };

  return (
    <tr>
      <td>
        <UserAvatar userId={user.id} includeName size="xs" />
      </td>
      <td>
        <input
          id={`hourly-rate-${user.id}`}
          type="text"
          name="hourly_rate"
          className="form-control"
          style={{ maxWidth: '200px' }}
          maxLength="3"
          size="10"
          onChange={handleChange}
          onBlur={handleBlur}
          value={rate}
        />
      </td>
    </tr>
  );
};

UserRow.propTypes = {
  user: userObject.isRequired,
  saveRecord: PropTypes.func.isRequired,
  hourlyRate: PropTypes.object,
};

UserRow.defaultProps = {
  hourlyRate: null,
};

const mapStateToProps = (state, ownProps) => {
  const user = getRecord(state.Api, { type: 'users', id: ownProps.userId });
  const hourlyRate = getRelationship(state.Api, user.relationships.hourly_rate);

  return {
    user,
    hourlyRate,
  };
};

const mapDispatchToProps = {
  saveRecord,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserRow);
