import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';
import CompletedPlansContainer from '../containers/CompletedPlansContainer/CompletedPlansContainer';

const CompletedPlans = props => (
  <Provider store={store}>
    <CompletedPlansContainer {...props} />
  </Provider>
);

export default CompletedPlans;
