import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Member from './Member';
import CurrentUser from './CurrentUser';
import { userObject } from '../../helpers/propTypes';

const TeamMembers = ({ currentUser, recentlyOnline }) => {
  if (!currentUser) return null;

  return (
    <ul className="navbar-nav">
      {recentlyOnline.filter(u => currentUser.id !== u.id)
        .map((user, index) => (
          <Member key={user.id} user={user} index={index} />
        ))}
      <CurrentUser avatarClass="avatar-sm d-inline-block" />
    </ul>
  );
};

TeamMembers.propTypes = {
  recentlyOnline: PropTypes.arrayOf(userObject).isRequired,
  currentUser: PropTypes.object,
};

const mapStateToProps = state => ({
  currentUser: state.Auth.currentUser,
});

export default connect(
  mapStateToProps,
)(TeamMembers);
