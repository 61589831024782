import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import toastr from 'toastr';
import {
  deleteRecord,
  fetchRecord,
  getRecord,
  saveRecord,
} from 'redux-json-api-module';
import { Card, CardHeader } from '../../components/Card';
import {
  includeData,
  setWorkOrder,
  hydrateWorkOrderManagementStore, fetchWorkOrder,
  setValidationWarning,
  fetchWorkOrderTasks,
  fetchTeamMembers,
} from '../../redux/modules/workOrderManagement';
import {
  closeScheduler,
  closeForm,
  openScheduler,
  loadEvents,
} from '../../redux/modules/scheduler';
import Loader from '../../components/Loader';
import { setCurrentUser } from '../../redux/modules/auth';
import { teamObject, idType } from '../../helpers/propTypes';
import { fetchMessageThreads, fetchMessengers } from '../../redux/modules/chat';
import AttachmentManager from '../ProjectManagementContainer/AttachmentManager/AttachmentManager';
import Scheduler from '../ProjectManagementContainer/Scheduler';
import WorkOrderSummary from '../WorkOrderManagementContainer/WorkOrderSummary/WorkOrderSummary';
import WorkOrderDetailsWrapper
  from '../WorkOrderManagementContainer/WorkOrderDetails/WorkOrderDetailsWrapper';
import ServiceCreationTable from './ServiceCreationTable/ServiceCreationTable';
import ActivateButton from './ActivateButton/ActivateButton';
import Alert from '../../components/Alert';
import SuccessModal from './SuccessModal/SuccessModal';
import FeatureFormButton from '../../components/FeatureFormButton';
import WorkOrderTaskAssignmentTable
  from '../WorkOrderManagementContainer/WorkOrderTaskAssignmentTable/WorkOrderTaskAssignmentTable';
import { pluralize } from '../../helpers/string';
import get from 'lodash.get';

class WorkOrderImportManagementContainer extends Component {
  static propTypes = {
    workOrderId: PropTypes.number.isRequired,
    team: teamObject.isRequired,
    includeData: PropTypes.func.isRequired,
    deleteRecord: PropTypes.func.isRequired,
    saveRecord: PropTypes.func.isRequired,
    closeScheduler: PropTypes.func.isRequired,
    closeForm: PropTypes.func.isRequired,
    currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
    fetchWorkOrderTasks: PropTypes.func.isRequired,
    fetchWorkOrder: PropTypes.func.isRequired,
    fetchTeamMembers: PropTypes.func.isRequired,
    fetchMessengers: PropTypes.func.isRequired,
    fetchMessageThreads: PropTypes.func.isRequired,
    areaItems: PropTypes.object.isRequired,
    descriptionOptions: PropTypes.array.isRequired,
    serviceOptions: PropTypes.array.isRequired,
    conditionOptions: PropTypes.array.isRequired,
    setCurrentUser: PropTypes.func.isRequired,
    areaOptions: PropTypes.array.isRequired,
    hydrateWorkOrderManagementStore: PropTypes.func.isRequired,
    loadEvents: PropTypes.func.isRequired,
    openScheduler: PropTypes.func.isRequired,
    workOrderTasks: PropTypes.array.isRequired,
    workOrder: PropTypes.object,
    workOrderTaskIds: PropTypes.arrayOf(idType).isRequired,
    successPrompt: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    workOrder: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      successModalVisible: props.successPrompt,
      featureFormVisible: false,
    };

    this.submitScheduler = this.submitScheduler.bind(this);
    this.createFeature = this.createFeature.bind(this);
    this.openFeatureForm = this.openFeatureForm.bind(this);
    this.closeFeatureForm = this.closeFeatureForm.bind(this);
  }

  componentDidMount() {
    const {
      hydrateWorkOrderManagementStore, fetchWorkOrder, workOrderId,
      areaItems, descriptionOptions, serviceObjectOptions, conditionOptions,
      setCurrentUser, currentUser, team,
      fetchTeamMembers, fetchWorkOrderTasks, fetchMessengers,
      fetchMessageThreads, areaOptions,
    } = this.props;

    hydrateWorkOrderManagementStore({
      workOrderId,
      areaItems,
      descriptionOptions,
      serviceObjectOptions,
      conditionOptions,
      areaOptions,
    });

    fetchWorkOrder(workOrderId);
    fetchTeamMembers(team.data.id);
    setCurrentUser(currentUser.data);

    fetchWorkOrderTasks(workOrderId)
      .then(() => (
        fetchMessageThreads('work_orders', workOrderId, true)
      ))
      .then(() => (
        fetchMessengers('work_orders', workOrderId)
      ));
  }

  componentWillReceiveProps(nextProps) {
    const { workOrderId } = this.props;

    if (workOrderId !== nextProps.workOrderId) {
      setWorkOrder(nextProps.workOrderId);
    }
  }

  async submitScheduler(workOrderTask) {
    const {
      saveRecord, closeForm, closeScheduler, loadEvents,
      workOrderTasks, openScheduler, workOrder, workOrderId,
      fetchWorkOrderTasks,
    } = this.props;

    const resp = await saveRecord(workOrderTask, { params: { window_id: window.WINDOW_ID } });

    if (resp.error) {
      toastr.error('The Work Order Task could not be updated.');
      return undefined;
    }

    await fetchWorkOrderTasks(workOrderId);

    const newWorkOrderTask = resp.payload.data.data;

    closeForm();

    const nextWorkOrderTask = workOrderTasks.find(t => (
      t.id !== workOrderTask.id
      && t.attributes.started_at === null
      && t.relationships.service.data.id
    ));

    if (nextWorkOrderTask) {
      toastr.info(`Please assign the next service, ${nextWorkOrderTask.attributes.service_name}`);

      if (nextWorkOrderTask.relationships.user.data === null) {
        nextWorkOrderTask.relationships.user = newWorkOrderTask.relationships.user;
        nextWorkOrderTask.attributes.user_id = newWorkOrderTask.attributes.user_id;
      }

      openScheduler(nextWorkOrderTask);
    } else if (workOrder.attributes.aasm_state === 'assign') {
      toastr.info('All services have been assigned.  You can now activate the work order.');
      return closeScheduler();
    } else {
      toastr.success('Schedule Updated.');
      return loadEvents(false);
    }
  }

  getSummaryTitle = () => {
    const { workOrder } = this.props;
    if (!workOrder) return null;

    const text = workOrder.attributes.aasm_state === 'completed' ? 'Completed in' : 'In Progress for';

    return `Services Summary - ${text} `
      + `${workOrder.attributes.duration} ${pluralize('Day', workOrder.attributes.duration)}`;
  };

  createFeature = (attributes) => {
    const { fetchWorkOrderTasks, workOrderId, saveRecord } = this.props;
    const record = {
      id: null,
      type: 'work_order_features',
      attributes: {
        ...attributes,
        work_order_id: workOrderId,
      },
    };

    return saveRecord(record, { params: { window_id: window.WINDOW_ID } })
      .then((resp) => {
        if (resp.error) return resp;
        return fetchWorkOrderTasks(workOrderId);
      });
  };

  openFeatureForm = () => this.setState({ featureFormVisible: true });

  closeFeatureForm = () => this.setState({ featureFormVisible: false });

  render() {
    const {
      workOrder, includeData, deleteRecord, workOrderId, workOrderTaskIds, areaOptions,
      descriptionOptions, areaItems, serviceOptions, users,
    } = this.props;

    const { successModalVisible, featureFormVisible } = this.state;

    return workOrder ? (
      <Fragment>
        <div className="animated fadeIn">
          <Alert
            type="danger"
            iconClassName="fa fa-exclamation-circle"
            title="Please Assign Services & Activate Project"
          >
            Please assign a maintenance provider for each Service, schedule their work date(s), and
            activate the project to begin work.
          </Alert>

          <WorkOrderSummary workOrder={workOrder} />

          <WorkOrderDetailsWrapper workOrder={workOrder} />

          <Alert
            type="danger"
            iconClassName="fa fa-exclamation-circle"
            title="Please Assign and Schedule Services, and then Activate Project"
          >
            Please assign a maintenance provider for each Service, and schedule their work date(s).
            You may assign one or more Services to this Work Order, depending on the work you need
            completed. You may schedule any Service for any available date. All Services will
            automatically sort based on their status and work dates, so if you need to move one
            Service above or below another, simply change the work dates.
          </Alert>

          {workOrder.attributes.aasm_state === 'assign' ? (
            <ServiceCreationTable
              workOrderId={workOrderId}
              users={users}
            />
          ) : (
            <Card>
              <CardHeader title={this.getSummaryTitle()}>
                {workOrder.attributes.aasm_state !== 'completed' && (
                  <FeatureFormButton
                    workOrderDescription={workOrder.attributes.description}
                    areas={areaOptions}
                    descriptions={descriptionOptions}
                    items={areaItems}
                    services={serviceOptions}
                    onSubmit={this.createFeature}
                    onHide={this.closeFeatureForm}
                    onShow={this.openFeatureForm}
                    visible={featureFormVisible}
                  />
                )}
              </CardHeader>
              <WorkOrderTaskAssignmentTable
                workOrderId={workOrderId}
                users={users}
              />
            </Card>
          )}

          <div className="m-b-15 text-center">
            <ActivateButton workOrder={workOrder} activate={this.activate} />
          </div>

          <Alert
            type="info"
            iconClassName="fa fa-exclamation-circle"
            title="Share attachments with your assigned service providers."
          >
            You may attach images or PDF files here, and share them with your assigned service
            providers. You may also attach any file for storage here, and it does not need to be
            shared with a service provider. Intimely will archive your attachments inside this
            project.
          </Alert>

          <Card>
            <CardHeader title="Attachments" />
            <AttachmentManager
              showUser
              id={workOrderId}
              type="attachments"
              parentRelationship="plan_id"
              planClass="WorkOrder"
              uploadEndpoint="/api/v1/attachments"
              onDelete={deleteRecord}
              taskIds={workOrderTaskIds}
              plan={workOrder}
              taskType="work_order_tasks"
              onCreate={(data) => {
                includeData(data);
              }}
            />
          </Card>
        </div>
        <Scheduler
          onSubmit={this.submitScheduler}
          planType="work_orders"
          scheduledType="work_order_calendar_events"
        />
        <SuccessModal
          visible={successModalVisible}
          closeModal={() => this.setState({ successModalVisible: false })}
        />
      </Fragment>
    ) : <Loader />;
  }
}

const mapStateToProps = (state, ownProps) => {
  const workOrder = getRecord(state.Api, {
    type: 'work_orders',
    id: ownProps.workOrderId,
  });

  const workOrderTasks = (state.WorkOrderManagement.workOrderTaskIds || [])
    .map(id => getRecord(state.Api, {
      type: 'work_order_tasks',
      id,
    }));

  return {
    scheduler: state.WorkOrderManagement.scheduler,
    workOrder,
    workOrderTasks,
    workOrderTaskIds: state.WorkOrderManagement.workOrderTaskIds,
  };
};

const mapDispatchToProps = {
  closeForm,
  closeScheduler,
  deleteRecord,
  fetchWorkOrder,
  fetchRecord,
  hydrateWorkOrderManagementStore,
  includeData,
  loadEvents,
  openScheduler,
  saveRecord,
  setCurrentUser,
  setValidationWarning,
  fetchWorkOrderTasks,
  fetchTeamMembers,
  fetchMessageThreads,
  fetchMessengers,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorkOrderImportManagementContainer);
