import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord, getRelationship } from 'redux-json-api-module';
import { idPropType } from '../../../helpers/propTypes';
import { PARENT_TYPE_MAP } from '../../../helpers/types';

const WorkOrderNumberCell = ({ number }) => number;

WorkOrderNumberCell.propTypes = {
  taskId: idPropType.isRequired,
  taskType: PropTypes.string.isRequired,

  // connected
  number: PropTypes.string,
};

WorkOrderNumberCell.defaultProps = {
  number: null,
};

const mapStateToProps = (state, ownProps) => {
  const task = getRecord(state.Api, {
    type: ownProps.taskType,
    id: ownProps.taskId,
  });

  const plan = getRelationship(state.Api, task.relationships[PARENT_TYPE_MAP[task.type]]);

  return {
    number: plan.attributes.work_order_number,
  };
};

export default connect(
  mapStateToProps,
)(WorkOrderNumberCell);
