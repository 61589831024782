import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import NewInspectionScheduler from '../../../components/NewInspectionScheduler';
import { closeScheduler, openScheduler } from '../../../redux/modules/scheduler';
import SchedulerButton from '../../ProjectManagementContainer/Scheduler/SchedulerButton';

const SchedulerInput = (
  {
    label, change, projectService, closeScheduler,
    service, user, keysReceivedOn, title,
  },
) => {
  const additionalEvents = projectService.attributes.started_at
    ? [{
      id: null,
      title: 'New Project - Inspection',
      allDay: projectService.attributes.all_day,
      start: Date.parse(moment(projectService.attributes.started_at)
        .format('YYYY-MM-DD HH:mm:ss')),
      end: Date.parse(moment(projectService.attributes.started_at)
        .format('YYYY-MM-DD HH:mm:ss')),
      userId: user && user.id,
      resource: {
        completed: false,
        serviceBadge: {},
        shortAddress: null,
        projectServiceId: null,
        projectId: null,
        serviceId: service.id,
        userId: user && user.id,
      },
    }] : [];

  return (
    <div className="form-group row">
      <label className="col-sm-3 col-form-label text-md-right">
        {`${label} `}
        <abbr title="required">*</abbr>
      </label>
      <div className="col-sm-9">
        <SchedulerButton taskType="project_services" task={projectService} />
        <NewInspectionScheduler
          // The ProjectService does not exist in the database, so loadEvents() will not pick it up,
          // We pass it as an additional event, formatted as if it were created from loadEvents(),
          additionalEvents={additionalEvents}
          closeScheduler={closeScheduler}
          keysReceivedOn={keysReceivedOn}
          onSubmit={(attributes) => {
            change('userId', attributes.user_id);
            change('startedAt', attributes.started_at);
            change('endedAt', attributes.ended_at);
            change('allDay', attributes.all_day);
            return closeScheduler();
          }}
          title={title}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  openScheduler,
  closeScheduler,
};

export default connect(null, mapDispatchToProps)(SchedulerInput);
