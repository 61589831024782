import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Schedule from './Schedule';
import { SCHEDULE_VIEW_GANTT, hydrateTeamScheduleStore } from '../../redux/modules/teamSchedule';
import './TeamScheduleContainer.scss';
import ViewToggle from './ViewToggle';
import GanttChartContainer from './GanttChart/GanttChartContainer';
import PlanTypeFilter from './Filters/PlanTypeFilter';
import SecondaryFilter from './Filters/SecondaryFilter';

import { options } from '../../helpers/propTypes';
import OptionFilter from './Filters/OptionFilter';
import PlanFilter from './Filters/PlanFilter';

class TeamScheduleContainer extends Component {
  static propTypes = {
    hydrateTeamScheduleStore: PropTypes.func.isRequired,
    serviceOptions: options.isRequired,
    userOptions: options.isRequired,
    projectOptions: options.isRequired,
    workOrderOptions: options.isRequired,
    allCommunityOptions: options.isRequired,
    projectCommunityOptions: options.isRequired,
    workOrderCommunityOptions: options.isRequired,
    view: PropTypes.string.isRequired,
  };

  UNSAFE_componentWillMount() {
    const {
      hydrateTeamScheduleStore,
      serviceOptions,
      userOptions,
      projectOptions,
      workOrderOptions,
      allCommunityOptions,
      projectCommunityOptions,
      workOrderCommunityOptions,
    } = this.props;

    hydrateTeamScheduleStore({
      serviceOptions,
      userOptions,
      projectOptions,
      workOrderOptions,
      allCommunityOptions,
      projectCommunityOptions,
      workOrderCommunityOptions,
    });
  }

  render() {
    const { view } = this.props;

    return (
      <React.Fragment>
        <div className="d-flex flex-row mb-4">

          <PlanTypeFilter />
          <SecondaryFilter />
          <OptionFilter />
          <PlanFilter />
          <div className="ml-4">
            <ViewToggle />
          </div>
        </div>
        <div className="row">
          <div className="col">
            {view === SCHEDULE_VIEW_GANTT ? <GanttChartContainer /> : <Schedule />}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  view: state.TeamSchedule.view,
});

const mapDispatchToProps = {
  hydrateTeamScheduleStore,
};

export default connect(mapStateToProps, mapDispatchToProps)(TeamScheduleContainer);
