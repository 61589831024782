import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord } from 'redux-json-api-module';
import LinesEllipsis from 'react-lines-ellipsis-with-tooltip';
import { NextArrow, PrevArrow } from '../ServiceDetails/helpers';
import GenericImageSlider from '../GenericImageSlider/GenericImageSlider';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import doneIcon from '../../../assets/images/icon/checkMarkGreen.png';
import editIcon from '../../../assets/images/icon/edit-icon.png';
import changeServiceIcon from '../../../assets/images/icon/change-service-icon.png';
import Placeholder from '../../../assets/images/image-placeholder.png';
import incompleteIcon from '../../../assets/images/icon/checkMarkGrey.png';
import './FeatureCard.scss';

const settings = {
  className: 'slick-feature-carousel',
  dots: false,
  lazyLoad: true,
  speed: 500,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

const FeatureCard = (
  {
    feature, openChangeServiceForm, openEditServiceForm,
    onMarkComplete, onMarkPending,
  },
) => (feature ? (
  <div className="card feature-card card-bordered h-100">
    <div className="card-header">
      <LinesEllipsis
        style={{ whiteSpace: 'pre-wrap' }}
        text={feature.attributes.area || ''}
        maxLine="1"
        ellipsis="..."
        trimRight
        basedOn="words"
      />
    </div>

    {feature.attributes.feature_images && feature.attributes.feature_images.length > 0 ? (
      <div className="card-img-top">
        <GenericImageSlider
          images={feature.attributes.feature_images}
          settings={settings}
          sliderClassName="slick-feature-carousel slider-image-container"
          imageClassName="slider-image w-100"
        />
      </div>
    ) : (
      <div className="image-placeholder-container">
        <img src={Placeholder} className="image-placeholder mx-auto" alt="placeholder" />
      </div>
    )}

    <div className="card-body py-0">
      <h4 className="card-title card-title-fixed-height truncate" title={feature.attributes.name}>
        {feature.attributes.name}
      </h4>
      <div className="card-text card-text-fixed-height">
        <LinesEllipsis
          style={{ whiteSpace: 'pre-wrap' }}
          text={feature.attributes.description}
          maxLine="2"
          ellipsis="..."
          trimRight
          basedOn="words"
          component="p"
        />
      </div>
      <div className="text-center">
        {feature.attributes.aasm_state === 'pending' ? (
          <div className="btn-group btn-group-lg" role="group" aria-label="Feature actions">
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                openEditServiceForm(feature);
              }}
              className="btn btn-link"
              id={`edit-service-button-${feature.id}`}
              title="Edit Item Details"
            >
              <img src={editIcon} width={40} alt="Edit" />
            </button>
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                openChangeServiceForm(feature);
              }}
              className="btn btn-link"
              id={`change-service-button-${feature.id}`}
              title="Move Image(s) to New Service"
            >
              <img src={changeServiceIcon} width={40} alt="Change Service" />
            </button>
            <button
              type="button"
              onClick={(e) => {
                e.preventDefault();
                onMarkComplete(feature);
              }}
              className="btn btn-link"
              id={`mark-feature-complete-button-${feature.id}`}
              title="Mark Complete"
            >
              <img
                src={incompleteIcon}
                width={30}
                alt="Mark Complete"
                className="mb-1"
              />
            </button>
          </div>
        ) : (
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              onMarkPending(feature);
            }}
            className="btn btn-link"
            id={`mark-feature-pending-button-${feature.id}`}
            title="Mark Complete"
          >
            <img
              src={doneIcon}
              className="d-inline mb-1"
              alt="Completed"
              height={30}
            />
          </button>
        )}
      </div>
    </div>
  </div>
) : null);

FeatureCard.propTypes = {
  featureId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  featureType: PropTypes.string.isRequired,
  openChangeServiceForm: PropTypes.func.isRequired,
  openEditServiceForm: PropTypes.func.isRequired,
  onMarkComplete: PropTypes.func,
  onMarkPending: PropTypes.func,

  // connected
  feature: PropTypes.objectOf(PropTypes.any),
};

FeatureCard.defaultProps = {
  feature: null,
  onMarkComplete: () => null,
  onMarkPending: () => null,
};

const mapStateToProps = (state, props) => ({
  feature: getRecord(state.Api, {
    type: props.featureType,
    id: props.featureId,
  }),
});

export default connect(
  mapStateToProps,
)(FeatureCard);
