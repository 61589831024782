import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchEvents, setTemporaryEvents, setScheduledRef, setFormVisible } from '../../../redux/modules/teamSchedule';
import { mapEventGrouping, groupEvents, getStyle } from './helpers';
import Loader from '../../../components/Loader';
import './GanttChart.scss';
import GenericGanttChart from './GenericGanttChart';

class GanttChartContainer extends Component {
  static propTypes = {
    eventData: PropTypes.array.isRequired,
    chartKey: PropTypes.string.isRequired,
    fetchEvents: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    setTemporaryEvents: PropTypes.func.isRequired,
    temporaryEvents: PropTypes.object.isRequired,
    setScheduledRef: PropTypes.func.isRequired,
    setFormVisible: PropTypes.func.isRequired,
    scheduledRef: PropTypes.object,
    formVisible: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    scheduledRef: null,
  }

  state = {
    eventsLoaded: false,
  }

  componentDidMount() {
    const { fetchEvents } = this.props;

    fetchEvents().then(() => this.setState({
      eventsLoaded: true,
    }));
  }

  render() {
    const {
      eventData,
      chartKey,
      loading,
      setTemporaryEvents,
      temporaryEvents,
      fetchEvents,
      setScheduledRef,
      setFormVisible,
      scheduledRef,
      formVisible,
    } = this.props;
    const { eventsLoaded } = this.state;

    if (loading) {
      return <Loader />;
    }

    return (
      <>
        {eventData.length === 0 && eventsLoaded ? (
          <div className="card">
            <div className="card-body">
              <div className="font-italic">No services for this option</div>
            </div>
          </div>
        ) : (
          <GenericGanttChart
            eventData={eventData}
            temporaryEvents={temporaryEvents}
            chartKey={chartKey}
            setTemporaryEvents={setTemporaryEvents}
            fetchEvents={fetchEvents}
            setScheduledRef={setScheduledRef}
            setFormVisible={setFormVisible}
            scheduledRef={scheduledRef}
            formVisible={formVisible}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { events } = state.TeamSchedule;
  const { grouping, unitOrder } = groupEvents(events, state.Api);
  const eventData = mapEventGrouping(grouping, unitOrder, state.TeamSchedule.temporaryEvents, state.Api, getStyle);

  const {
    planType,
    activeCommunity,
    activeService,
    activeUnit,
    activeUser,
    formVisible,
    scheduledRef,
  } = state.TeamSchedule;

  const key = `${planType}-${activeCommunity && activeCommunity.id}-${activeService && activeService.id}-${activeUnit && activeUnit.id}-${activeUser && activeUser.id}`;

  return {
    eventData,
    chartKey: key,
    loading: state.TeamSchedule.loading,
    temporaryEvents: state.TeamSchedule.temporaryEvents,
    scheduledRef,
    formVisible,
  };
};

const mapDispatchToProps = {
  fetchEvents,
  setTemporaryEvents,
  setScheduledRef,
  setFormVisible,
};

export default connect(mapStateToProps, mapDispatchToProps)(GanttChartContainer);
