import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Row } from 'react-bootstrap';
import InventoryTable from './InventoryTable';
import AddInventoryForm from './AddInventoryForm';

const InventoryTab = () => {
  const [showForm, setShowForm] = useState(false);
  const [item, setItem] = useState(null);

  const handleClose = () => setShowForm(false);
  const handleShow = (item) => {
    setItem(item);
    setShowForm(true);
  };

  return (
    <>
      <Row className="mb-4 mr-2">
        <Col className="text-right">
          <Button variant="info" onClick={() => handleShow(null)}>
            Add Inventory
          </Button>

          <a
            href="/inventory/import"
            id="import-unit"
            className="btn btn-secondary ml-2"
          >
            Import Inventory
          </a>

          <AddInventoryForm
            show={showForm}
            onHide={handleClose}
            defaultItem={item}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <InventoryTable
            onAddInventory={i => handleShow(i)}
          />
        </Col>
      </Row>
    </>
  );
};

InventoryTab.propTypes = {};

const mapStateToProps = () => ({});

export default connect(
  mapStateToProps,
)(InventoryTab);
