import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { ACCEPTED_FILE_TYPES } from '../helpers';
import COLORS from '../../../helpers/colors';

const FileImporter = ({ onDrop, uploading }) => (
  uploading ? (
    <div
      className="form-group row py-2 ml-1 px-3 font-weight-bold"
      style={{
        backgroundColor: COLORS.greyLight,
        width: '70%',
      }}
    >
      Uploading...
    </div>
  ) : (
    <Dropzone onDrop={onDrop} accept={ACCEPTED_FILE_TYPES}>
      {({ getRootProps, getInputProps }) => (
        <div {...getRootProps()} style={{ marginRight: '1rem' }}>
          <input name="data[attributes][file]" {...getInputProps()} />
          <div
            className="form-group row py-2 ml-1"
            style={{
              backgroundColor: COLORS.greyLight,
              width: '70%',
            }}
          >
            <div className="col font-weight-bold text-center my-auto">
              Drag file here
              <span style={{ marginLeft: '25px' }}>OR</span>
            </div>
            <label className="col col-form-label">
              <button
                className="btn btn-primary"
                type="button"
                style={{
                  border: 0,
                  backgroundColor: COLORS.blue,
                  outline: 'none',
                  outlineOffset: 'none',
                  boxShadow: 'none',
                }}
              >
                Click Here to Choose
              </button>
            </label>
          </div>
        </div>
      )}
    </Dropzone>
  )
);

FileImporter.propTypes = {
  uploading: PropTypes.bool.isRequired,
  onDrop: PropTypes.func.isRequired,
};

export default FileImporter;
