import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';

export const DateSelect = ({ input, label, minDate }) => (
  <div className="form-group">
    <label>{label}</label>
    <DatePicker
      selected={moment(input.value).isValid() ? moment(input.value)
        .toDate() : null}
      onChange={input.onChange}
      className="form-control"
      minDate={minDate}
    />
  </div>
);

export const GroupSelect = ({ input, label, options }) => (
  <div className="form-group">
    <label>{label}</label>
    <select {...input} className="form-control">
      {options && Object.keys(options)
        .map(label => (
          <optgroup key={label} label={label}>
            {options[label].map(usr => <option key={usr.id} value={usr.id}>{usr.name}</option>)}
          </optgroup>
        ))}
    </select>
  </div>
);
