import get from 'lodash.get';
import moment from 'moment';
import { getRecord } from 'redux-json-api-module';
import { firstBy } from 'thenby';
import COLORS from '../../../helpers/colors';
import { getCompositeId } from '../Schedule/helpers';
import { TASK_SORTS } from '../../../helpers/types';
import { relComparison } from '../../../helpers/string';

function getUnitOrder(sortingData) {
  sortingData = sortingData.sort(firstBy((a, b) => a.communityName.localeCompare(b.communityName))
    .thenBy((a, b) => moment(a.keysReceivedOn).diff(b.keysReceivedOn)));

  return sortingData.map(unit => unit.parentId);
}

function getSortingData(event, parentId) {
  return {
    parentId,
    communityName: get(event, 'attributes.community_name', ''),
    keysReceivedOn: get(event, 'attributes.keys_received_on', null),
  };
}

export function groupEvents(events, api) {
  const sortingData = {};
  const grouping = {};
  events.forEach((eventRef) => {
    const event = getRecord(api, eventRef);
    const parentId = get(event, 'relationships.unit.data.id', null);
    if (parentId) {
      if (!grouping[parentId]) grouping[parentId] = [];
      grouping[parentId].push(event);
      if (!sortingData[parentId]) {
        sortingData[parentId] = getSortingData(event, parentId);
      }
    }
  });

  return {
    grouping,
    unitOrder: getUnitOrder(Object.values(sortingData)),
  };
}

export function getStyle(event) {
  if (get(event, 'attributes.completed', false)) {
    return {
      borderColor: COLORS.green,
      textColor: COLORS.green,
      backgroundColor: COLORS.white,
    };
  }
  const color = get(event, 'attributes.service_badge.background_color', null);
  return {
    borderColor: color,
    textColor: COLORS.white,
    backgroundColor: color,
  };
}

export function isDisabled(event, currentEvent) {
  if (!currentEvent) return false;
  const plan = event.relationships.work_order || event.relationships.project;
  const eventPlan = currentEvent.relationships.work_order || currentEvent.relationships.project;

  return (!relComparison(plan, eventPlan) || !!get(event, 'attributes.completed', false));
}

export function mapEventGrouping(eventGrouping, unitOrder, temporaryEvents, api, styler, currentEvent) {
  const data = [];
  let min;
  let max;

  unitOrder.forEach((unitId, i) => {
    const unit = getRecord(api, { type: 'units', id: unitId });
    const shortAddress = get(unit, 'attributes.short_address', null);
    data.push({
      color: i % 2 == 0 ? COLORS.blue : COLORS.blueLight,
      name: shortAddress,
      id: unitId,
      isParent: true,
      collapsed: true,
      dragDrop: {
        draggableX: false,
        draggableY: false,
      },
    });

    let events = eventGrouping[unitId];
    events = events.sort(TASK_SORTS[events[0].type]);

    events.forEach((event) => {
      const temporaryEvent = temporaryEvents[getCompositeId(event)];
      const startedAt = temporaryEvent ? temporaryEvent.calendarStartedAt : event.attributes.started_at;
      const endedAt = temporaryEvent ? temporaryEvent.calendarEndedAt : event.attributes.ended_at;
      const start = moment.utc(startedAt).valueOf();
      const end = moment.utc(endedAt).valueOf();

      if (start < min || !min) min = start;
      if (end > max || !max) max = end;

      const {
        borderColor,
        textColor,
        backgroundColor,
      } = styler(event, currentEvent);
      data.push({
        color: backgroundColor,
        borderColor,
        dataLabels: {
          color: textColor,
        },
        parent: unitId,
        id: getCompositeId(event),
        name: event.attributes.title,
        start,
        end,
        minDate: event.attributes.min_date,
        isDisabled: isDisabled(event, currentEvent),
      });
    });
  });

  if (min && max) {
    data.push({
      start: moment(min).subtract(28, 'days').valueOf(),
      end: moment(max).add(28, 'days').valueOf(),
      color: 'rgba(0,0,0,0)',
    });
  }

  return data;
}

export function adjustDragStart(start) {
  return moment(start).endOf('hour').add(1, 'seconds').subtract(moment(start).utcOffset(), 'minutes')
    .toDate();
}

export function adjustDragEnd(end) {
  return moment(end).subtract(moment(end).utcOffset(), 'minutes').subtract(1, 'minutes').toDate();
}

export function validateDrag(start, end, targetStart) {
  return !(end && !start && moment(end).isBefore(moment(targetStart).add(1, 'hours')));
}
