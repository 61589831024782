import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setEditingChannel } from '../../redux/modules/chat';
import COLORS from '../../helpers/colors';
import settingsIcon from '../../../assets/images/icon/settings.png';
import { idType } from '../../helpers/propTypes';

const EditChannelButton = ({ setEditingChannel, channelId }) => (
  channelId ? (
    <button
      type="button"
      id="edit-channel-btn"
      className="my-0 btn btn-link pl-0"
      style={{
        color: COLORS.blue,
        textAlign: 'left',
      }}
      onClick={() => setEditingChannel(channelId)}
    >
      <img
        src={settingsIcon}
        style={{
          width: '24px',
          height: '24px',
          paddingBottom: '2px',
        }}
      />
    </button>
  ) : null
);

EditChannelButton.propTypes = {
  channelId: idType,
  setEditingChannel: PropTypes.func.isRequired,
};

EditChannelButton.defaultProps = {
  channelId: null,
};

const mapStateToProps = state => ({
  channelId: state.Chat.activeChannelId,
});

const mapDispatchToProps = {
  setEditingChannel,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditChannelButton);
