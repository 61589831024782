import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { FILTERS, setFilter } from '../../redux/modules/partsTasks';

const ProjectTypeFilter = ({ filter, setFilter }) => (
  <Dropdown>
    <Dropdown.Toggle>
      {FILTERS[filter]}
    </Dropdown.Toggle>

    <Dropdown.Menu>
      {Object.keys(FILTERS).map(f => (
        <Dropdown.Item
          key={f}
          onClick={() => setFilter(f)}
        >
          {FILTERS[f]}
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </Dropdown>
);

ProjectTypeFilter.propTypes = {
  // connected
  filter: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  filter: state.PartsTasks.filter || Object.keys(FILTERS)[0],
});

const mapDispatchToProps = {
  setFilter,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectTypeFilter);
