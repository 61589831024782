import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ExpandToggle = ({ expanded, setExpanded, type }) => (
  <div className="clearfix">
    <button
      type="button"
      className={`btn btn-link pull-right alert-${type}`}
      style={{ border: 0 }}
      onClick={() => setExpanded(!expanded)}
    >
      {expanded ? 'Hide Examples' : 'Show Examples'}
    </button>
  </div>
);

ExpandToggle.propTypes = {
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

const ExpandedChildren = ({ expanded, children }) => (
  expanded ? (
    <div className="mt-4">
      {children}
    </div>
  ) : null
);

ExpandedChildren.propTpes = {
  expanded: PropTypes.bool.isRequired,
  children: PropTypes.component,
};

ExpandedChildren.defaultProps = {
  children: null,
};

const DismissToggle = ({ onDismiss }) => (
  <button
    type="button"
    className="btn btn-link pull-right pt-0"
    style={{ border: 0 }}
    onClick={onDismiss}
  >
    X
  </button>
);

DismissToggle.propTypes = {
  onDismiss: PropTypes.func.isRequired,
};

const Alert = ({ type, iconClassName, title, children, expandedChildren, onDismiss }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div className={`alert alert-${type}`}>
      {title && (
        <h3 className={`text-${type}`}>
          {iconClassName && <i className={iconClassName} />}
          {iconClassName ? ` ${title}` : title}
        </h3>
      )}
      {onDismiss ? <DismissToggle onDismiss={onDismiss} /> : null}
      {children}
      <ExpandedChildren expanded={expanded}>
        {expandedChildren}
      </ExpandedChildren>
      {expandedChildren ? (
        <ExpandToggle
          expanded={expanded}
          setExpanded={setExpanded}
          type={type}
        />
      ) : null}
    </div>
  );
};

Alert.propTypes = {
  type: PropTypes.string,
  iconClassName: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  expandedChildren: PropTypes.array,
  onDismiss: PropTypes.func,
};

Alert.defaultProps = {
  type: 'info',
  iconClassName: undefined,
  title: undefined,
  expandedChildren: null,
  onDismiss: null,
};

export default Alert;
