import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { getRecord } from 'redux-json-api-module';
import { Form, Field } from 'react-final-form';
import DateInput from './DateInput';

function changeDueDate(value, change, onChange) {
  onChange(value);
  change('due_on', value);
}

function getCompletedComponent(plan) {
  return plan.attributes.due_on ? (
    moment(plan.attributes.due_on)
      .format('MM/DD/YYYY')
  ) : '-';
}

const DueDatePicker = ({ plan, initialValues, onChange }) => (
  plan.attributes.aasm_state === 'completed'
    ? getCompletedComponent(plan)
    : (
      <Form
        initialValues={initialValues}
        onSubmit={() => {
          // DO NOTHING
        }}
        render={({ form: { change } }) => (
          <Field name="due_on">
            {({ input }) => {
              const newInput = { ...input };
              newInput.onChange = value => changeDueDate(value, change, onChange);

              return (
                <DateInput
                  input={newInput}
                  minDate={moment(plan.attributes.keys_received_on)
                    .toDate()}
                />
              );
            }}
          </Field>
        )}
      />
    )
);

const mapStateToProps = (state, ownProps) => {
  const plan = getRecord(state.Api, {
    type: ownProps.planType,
    id: ownProps.planId,
  });

  return {
    plan,
    initialValues: plan.attributes,
  };
};

export default connect(mapStateToProps)(DueDatePicker);
