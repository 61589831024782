import { connect } from 'react-redux';
import { getRelationship } from 'redux-json-api-module';
import { approveCompletionWorkOrder } from '../../../redux/modules/workOrderManagement';
import ApproveCompletionButton from '../../../components/ApproveCompletionButton';

const mapStateToProps = (state, ownProps) => {
  const workOrderTasks = getRelationship(
    state.Api,
    ownProps.workOrder.relationships.work_order_tasks,
  );

  return {
    planId: ownProps.workOrder.id,
    title: 'Work Order',
    reviewable: workOrderTasks.filter(t => (
      t.attributes.completed_at === null
      || t.attributes.aasm_state !== 'completed'
    )).length === 0,
  };
};

const mapDispatchToProps = {
  approveCompletion: approveCompletionWorkOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApproveCompletionButton);
