import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getRecord } from 'redux-json-api-module';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import get from 'lodash.get';
import InspectionTab from './InspectionTab';
import CompletionTab from './CompletionTab';
import NotesTab from './NotesTab';
import { setTab } from '../../redux/modules/projectManagement';
import './ServiceTabs.scss';
import { teamObject, idType } from '../../helpers/propTypes';
import InspectionSummaryTab from './InspectionSummaryTab';
import InvoiceTab from './InvoiceTab';
import ChatTab from './ChatTab';
import PurchaseRequestTab from './PurchaseRequestTab';
import PurchaseRequestHeader from './PurchaseRequestTab/PurchaseRequestHeader';

const ServiceTabs = ({
  completed,
  taskType,
  taskId,
  planType,
  planId,
  communityId,
  defaultTab,
  inspection,
  setTab,
  reducedSize,
  inspectionTitle,
  ServiceForm,
}) => (
  <div id="service-tab" className="animated fadeIn full-tabs">
    {taskId && taskId !== 'chat' ? (
      <Tabs
        key={taskId}
        defaultActiveKey={defaultTab}
        unmountOnExit
        id="full-tabs"
        onSelect={tab => setTab(tab)}
      >
        {inspection ? (
          <Tab eventKey="inspection" title="Inspection">
            <InspectionSummaryTab planId={planId} planType={planType} />
          </Tab>
        ) : (
          <Tab eventKey="inspection" title={inspectionTitle}>
            <InspectionTab
              key={`${taskType}-${taskId}`}
              taskId={taskId}
              taskType={taskType}
              reducedSize={reducedSize}
              title={inspectionTitle}
              ServiceForm={ServiceForm}
            />
          </Tab>
        )}
        <Tab eventKey="notes" title="Notes/Checklist">
          <NotesTab
            key={`${taskType}-${taskId}`}
            taskId={taskId}
            taskType={taskType}
          />
        </Tab>
        <Tab
          eventKey="purchaseRequest"
          title={<PurchaseRequestHeader taskId={taskId} taskType={taskType} />}
        >
          <PurchaseRequestTab
            key={`${taskType}-${taskId}`}
            taskId={taskId}
            taskType={taskType}
          />
        </Tab>
        {completed && !inspection ? (
          <Tab eventKey="completion" title="Completion">
            <CompletionTab
              key={`${taskType}-${taskId}`}
              taskId={taskId}
              taskType={taskType}
            />
          </Tab>
        ) : null}
        <Tab eventKey="invoice" title="Invoice">
          <InvoiceTab
            key={`${taskType}-${taskId}`}
            taskId={taskId}
            taskType={taskType}
          />
        </Tab>
      </Tabs>
    ) : null}

    {taskId === 'chat' ? (
      <Tabs
        defaultActiveKey="chat"
        unmountOnExit
        id="full-tabs"
        onSelect={tab => setTab(tab, {
          planId,
          planType,
          communityId,
          noTouch: true,
        })}
      >
        <Tab eventKey="chat" title="Chat">
          <ChatTab
            planId={planId}
            planType={planType}
          />
        </Tab>
      </Tabs>
    ) : null}
  </div>
);

ServiceTabs.propTypes = {
  completed: PropTypes.bool,
  taskId: idType,
  taskType: PropTypes.string,
  planId: idType.isRequired,
  planType: PropTypes.string.isRequired,
  team: teamObject,
  userId: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  defaultTab: PropTypes.string,
  inspection: PropTypes.bool,
  setTab: PropTypes.func.isRequired,
  messageThreadId: idType,
  reducedSize: PropTypes.bool,
  inspectionTitle: PropTypes.string,
  ServiceForm: PropTypes.elementType.isRequired,
  communityId: idType,
};

ServiceTabs.defaultProps = {
  taskId: null,
  taskType: null,
  team: null,
  autoFocus: false,
  defaultTab: 'inspection',
  inspectionTitle: 'Inspection',
  completed: false,
  inspection: false,
  messageThreadId: null,
  communityId: null,
};

const mapStateToProps = (state, ownProps) => {
  const task = getRecord(state.Api, {
    type: ownProps.taskType,
    id: ownProps.taskId,
  });

  const completed = get(task, 'attributes.aasm_state', null) === 'completed';
  const inspection = get(task, 'attributes.is_inspection', false);

  const { defaultTab } = ownProps;

  return {
    completed,
    inspection,
    defaultTab,
  };
};

const mapDispatchToProps = {
  setTab,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceTabs);
