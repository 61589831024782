import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setCreatingChannel } from '../../redux/modules/chat';
import COLORS from '../../helpers/colors';
import plusIcon from '../../../assets/images/icon/blue-plus.png';

const CreateChannelButton = ({ setCreatingChannel }) => (
  <button
    type="button"
    className="mt-1 mb-1 btn btn-link pl-0"
    style={{
      color: COLORS.blue,
      textAlign: 'left',
      marginLeft: '6px',
    }}
    onClick={() => setCreatingChannel(true)}
  >
    <img
      src={plusIcon}
      style={{
        width: '18px',
        height: '18px',
        paddingBottom: '2px',
        paddingLeft: '4px',
      }}
    />
    <span className="font-italic" style={{ marginLeft: '21px' }}>Add a new channel</span>
  </button>
);

CreateChannelButton.propTypes = {
  setCreatingChannel: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  setCreatingChannel,
};

export default connect(null, mapDispatchToProps)(CreateChannelButton);
