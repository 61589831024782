import { connect } from 'react-redux';
import { getRecord } from 'redux-json-api-module';
import ActivateButton from '../../../components/ActivateButton';
import { activateWorkOrder } from '../../../redux/modules/workOrderManagement';
import { canActivate } from '../../WorkOrderImportManagementContainer/helpers';

const mapStateToProps = (state, ownProps) => {
  const workOrderTasks = (state.WorkOrderManagement.workOrderTaskIds || [])
    .map(id => getRecord(state.Api, {
      type: 'work_order_tasks',
      id,
    }));

  return {
    active: canActivate(workOrderTasks),
    planId: ownProps.workOrder.id,
    title: 'Work Order',
  };
};

const mapDispatchToProps = {
  activate: activateWorkOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivateButton);
