import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { getRelationship } from 'redux-json-api-module';
import get from 'lodash.get';
import '../../../components/Summary/Summary.scss';
import { simpleFormat } from '../../../helpers/string';
import Avatar from '../../../components/Avatar';
import Badge from '../../../components/Badge';
import { Card } from '../../../components/Card';
import DueDatePicker from '../../../components/Summary/DueDatePicker';
import KeysReceivedOnDatePicker from '../../../components/Summary/KeysReceivedOnDatePicker';
import { estimatedDone } from '../../../components/Summary/helpers';
import { submitDueDate } from '../../../redux/modules/workOrderManagement';
import WorkOrderBadge from '../../../components/WorkOrderBadge';

const WorkOrderSummary = ({
  planType,
  planId,
  fullAddressFormatted,
  communityName,
  mapsUrl,
  mapsImageUrl,
  submitDueDate,
  submitKeysReceivedOn,
  aasmState,
  keysReceivedOn,
  dueOn,
  status,
  manager,
  done,
  completedAt,
}) => (
  <Card className="summary">
    <div className="row">
      <div className="col-sm-6 col-lg-2">
        <p className="font-weight-bold">
          Address
        </p>
        <p
          className="mt-2"
          dangerouslySetInnerHTML={{ __html: simpleFormat(fullAddressFormatted) }}
        />
        <p className="mt-2">{communityName}</p>
        <p>
          <WorkOrderBadge />
        </p>
      </div>
      <div className="col-sm-6 col-lg-2">
        <a
          href={mapsUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            style={{
              maxHeight: '150px',
              maxWidth: '100%',
            }}
            src={mapsImageUrl}
            alt="Unit Map"
          />
        </a>
      </div>
      <div className="col-sm-6 col-lg-3">
        <table className="table table-borderless table-sm summary-table">
          <tbody>
            <tr>
              <td className="font-weight-bold">
                Start
              </td>
              <td>
                <KeysReceivedOnDatePicker
                  keysReceivedOn={keysReceivedOn}
                  aasmState={aasmState}
                  dueOn={dueOn}
                  onChange={keysReceivedOn => (
                    submitKeysReceivedOn(parentType, parentId, keysReceivedOn)
                  )}
                />
              </td>
            </tr>
            <tr>
              <td className="font-weight-bold">
                Due
              </td>
              <td>
                <DueDatePicker
                  planType={planType}
                  planId={planId}
                  onChange={dueOn => submitDueDate(planType, planId, dueOn, keysReceivedOn)}
                />
              </td>
            </tr>
            {aasmState === 'completed' ? (
              <tr>
                <td className="font-weight-bold">
                  Done
                </td>
                <td>
                  {moment(completedAt)
                    .isValid()
                    ? moment(completedAt)
                      .format('MM/DD') : 'Unknown'}
                </td>
              </tr>
            ) : (
              <tr>
                <td className="font-weight-bold">
                  Est. Done
                </td>
                <td>
                  {done}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="col-sm-6 col-lg-5">
        <table className="table table-borderless table-sm summary-table">
          <tbody>
            <tr>
              <td className="font-weight-bold">
                Status
              </td>
              <td>
                <Badge {...status} />
              </td>
            </tr>
            {manager ? (
              <tr>
                <td className="font-weight-bold">
                  Created By
                </td>
                <td>
                  <React.Fragment>
                    <Avatar {...manager.attributes} className="avatar-xs d-inline-block" />
                    {` ${manager.attributes.name}`}
                  </React.Fragment>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  </Card>
);

const mapStateToProps = (state, ownProps) => {
  const unit = getRelationship(state.Api, ownProps.workOrder.relationships.unit);
  const community = getRelationship(state.Api, ownProps.workOrder.relationships.community);
  const manager = getRelationship(state.Api, ownProps.workOrder.relationships.manager);

  const unitAttributes = get(unit, 'attributes', {});
  const {
    full_address_formatted: fullAddressFormatted,
    google_maps_url: mapsUrl,
    google_maps_image_url: mapsImageUrl,
  } = unitAttributes;

  const workOrderAttributes = ownProps.workOrder.attributes;
  const {
    aasm_state: aasmState,
    due_on: dueOn,
    keys_received_on: keysReceivedOn,
    status,
    completed_at: completedAt,
  } = workOrderAttributes;

  const done = estimatedDone(ownProps.workOrder);

  const communityName = get(community, 'attributes.name', '');

  return {
    fullAddressFormatted,
    communityName,
    mapsUrl,
    mapsImageUrl,
    planId: ownProps.workOrder.id,
    planType: 'work_orders',
    aasmState,
    dueOn,
    keysReceivedOn,
    status,
    manager,
    done,
    completedAt,
  };
};

const mapDispatchToProps = {
  submitDueDate,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorkOrderSummary);
