import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';

const DelayedLottie = ({
  options,
  height,
  width,
}) => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    setTimeout(() => setVisible(true), 1000);
  });

  return visible ? (
    <Lottie
      options={options}
      height={height}
      width={width}
    />
  ) : <div style={{ height }} />;
};

DelayedLottie.propTypes = {
  options: PropTypes.object.isRequired,
  height: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
};

export default DelayedLottie;
