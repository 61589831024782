import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import BulkChangeServiceForm from './BulkChangeServiceForm';
import ChangeServiceForm from './ChangeServiceForm';
import EditServiceForm from './EditServiceForm';

import './ServiceForm.scss';

const components = {
  Edit: EditServiceForm,
  BulkChangeService: BulkChangeServiceForm,
  ChangeService: ChangeServiceForm,
};

class ServiceForm extends React.Component {
  static propTypes = {
    visible: PropTypes.bool,
    feature: PropTypes.object,
    areaItems: PropTypes.object,
    serviceOptions: PropTypes.array.isRequired,
    conditionOptions: PropTypes.array.isRequired,
    descriptionOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
    task: PropTypes.objectOf(PropTypes.any),
    features: PropTypes.arrayOf(PropTypes.any),
    featureImages: PropTypes.array.isRequired,
    errors: PropTypes.object,
    componentType: PropTypes.string.isRequired,
    fetchAreaOptions: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onSaveSuccess: PropTypes.func,
    splitFeature: PropTypes.func.isRequired,
    updateFeatures: PropTypes.func.isRequired,
    deleteFeature: PropTypes.func.isRequired,
  };

  static defaultProps = {
    visible: false,
    task: null,
    feature: null,
    features: [],
    errors: {},
    areaItems: {},
    onSaveSuccess: null,
  };

  constructor(props) {
    super(props);

    this.state = props.feature ? props.feature.attributes : {};
    this.state.areaOptions = [];
  }

  componentDidMount() {
    this.fetchAreaOptions();
  }

  fetchAreaOptions = () => {
    const { fetchAreaOptions, unit } = this.props;

    if (unit) {
      fetchAreaOptions(unit.id)
        .then((resp) => {
          if (resp.error) {
            console.log(resp.error);
            return;
          }

          this.setState({ areaOptions: resp.payload.data });
        });
    }
  };

  onChange = (attributes) => {
    this.setState({ ...attributes });
  };

  render() {
    const {
      componentType,
      descriptionOptions,
      serviceOptions,
      conditionOptions,
      areaItems,
      errors,
      task,
      features,
      featureImages,
      onClose,
      onSaveSuccess,
      visible,
      feature,
      splitFeature,
      updateFeatures,
      deleteFeature,
    } = this.props;

    const {
      area,
      name,
      description,
      service_id: serviceId,
      condition,
      areaOptions,
      item_id: itemId,
    } = this.state;

    const Component = components[componentType];

    return (
      <Modal show={visible} onHide={onClose} size="lg">
        <Component
          task={task}
          feature={feature}
          features={features}
          featureImages={featureImages}
          errors={errors}
          onChange={this.onChange}
          onClose={onClose}
          onSaveSuccess={onSaveSuccess}
          areaOptions={areaOptions}
          areaItems={areaItems}
          descriptionOptions={descriptionOptions}
          conditionOptions={conditionOptions}
          name={name}
          visible={visible}
          area={area}
          description={description}
          condition={condition}
          service_id={serviceId}
          itemId={itemId}
          serviceOptions={serviceOptions}
          splitFeature={splitFeature}
          updateFeatures={updateFeatures}
          deleteFeature={deleteFeature}
        />
      </Modal>
    );
  }
}

export default ServiceForm;
