import get from 'lodash.get';
import { firstBy } from 'thenby';

export const isImageFile = file => file.type.startsWith('image/');

export const pricePerPart = (total, quantity) => {
  const ppp = parseFloat(total || 0) / parseFloat(quantity || 0);

  if (Number.isNaN(ppp)) return '0.00';

  return ppp.toFixed(2);
};

export const itemOptions = items => (
  items
    .sort(
      firstBy(i => get(i, 'attributes.name')),
    )
    .map(i => ({
      value: i.id,
      label: get(i, 'attributes.name'),
    }))
);
