import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { getRecord } from 'redux-json-api-module';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import get from 'lodash.get';
import SchedulerForm
  from '../../containers/ProjectManagementContainer/Scheduler/SchedulerForm/SchedulerForm';
import {
  getTaskPlanRel,
  getUpdatedDates,
  isDisabled,
  isEventOfPlan,
  MIN_TIME,
  MAX_TIME,
} from './helpers';
import Loader from '../Loader';
import DateHeader from './DateHeader';
import { getCompositeId } from '../../containers/TeamScheduleContainer/Schedule/helpers';
import Title from './Title';
import './styles.css';
import {
  calendarHeight,
  decorateEvent,
} from '../../containers/ProjectManagementContainer/Scheduler/helpers';
import { setRange } from '../../redux/modules/scheduler';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';
import '../../containers/ProjectManagementContainer/Scheduler/Scheduler.scss';
import { PARENT_TYPE_MAP } from '../../helpers/types';
import MonthEvent from './MonthEvent';

const DragAndDropCalendar = withDragAndDrop(Calendar);
const localizer = momentLocalizer(moment);

const GenericScheduler = ({
  scheduled,
  views,
  user,
  loading,
  formTitle,
  eventPropGetter,
  startDate,
  dueDate,
  deadline,
  minDate,
  title,
  planType,
  showTitle,
  formVisible,
  range,
  currentTeamId,
  refresh,
  setFormVisible,
  isSelectable,
  onChangeScheduled,
  validateSelectSlot,
  getFormValues,
  onSave,
  events,
  onAssignmentChange,
  openOnAssignment,
  onRangeChange,
  setRange,
}) => {
  const [temporaryEvents, setTemporaryEvents] = useState({});
  const [refreshing, setRefreshing] = useState(false);

  const handleSelectEvent = (event) => {
    if (!isSelectable(event)) return;
    if (isDisabled(event)) return;

    const parentTypeMatch = PARENT_TYPE_MAP[scheduled?.type] !== PARENT_TYPE_MAP[event?.type];

    if (!scheduled || scheduled.id !== event.id || parentTypeMatch) {
      onChangeScheduled({
        type: event.type,
        id: event.id,
      }, event.userId, event);
    } else {
      setFormVisible(true);
    }
  };

  const handleCloseForm = () => {
    setFormVisible(false);
    setTemporaryEvents({});
  };

  const dndEvent = (updatedEvent) => {
    const { start, end } = updatedEvent;

    if (!isSelectable(updatedEvent)) return;
    if (validateSelectSlot && !validateSelectSlot({ start, end })) return;

    setTemporaryEvents({
      ...temporaryEvents,
      [getCompositeId(updatedEvent)]: updatedEvent,
    });

    onChangeScheduled({
      type: updatedEvent.type,
      id: updatedEvent.id,
    }, updatedEvent.userId, updatedEvent);

    setFormVisible(true);
  };


  const handleEventDrop = ({
    event,
    start,
    end,
  }) => {
    if (start < event.start && event.start < new Date()) return;

    const endedAt = moment(end)
      .subtract(1, 'second')
      .toDate();

    const updatedEvent = {
      ...event,
      start,
      end: endedAt,
      started_at: start,
      ended_at: endedAt,
    };

    dndEvent(updatedEvent);
  };

  const handleResizeEvent = ({
    event,
    start,
    end,
  }) => {
    if (start < event.start && event.start < new Date()) return false;

    const endedAt = moment(end)
      .subtract(1, 'second')
      .toDate();

    const updatedEvent = {
      ...event,
      start,
      end: endedAt,
      started_at: start,
      ended_at: endedAt,
    };

    return dndEvent(updatedEvent);
  };

  const getInitialValues = () => {
    const temporaryEvent = temporaryEvents[getCompositeId({
      type: scheduled.type,
      id: scheduled.id,
    })];

    let combined;
    if (temporaryEvent) {
      combined = {
        ...scheduled.attributes,
        ...getUpdatedDates({
          scheduled,
          start: temporaryEvent.start,
          end: temporaryEvent.end,
        }),
      };
    } else {
      combined = { ...scheduled.attributes };

      if (scheduled.attributes.started_at) {
        combined.start = scheduled.attributes.started_at;
      }

      if (scheduled.attributes.ended_at) {
        combined.end = scheduled.attributes.ended_at;
      }
    }

    return getFormValues(combined);
  };

  const handleSubmit = (values) => {
    Promise.resolve(onSave(values))
      .then(() => setFormVisible(false));
  };

  const handleSelectSlot = ({
    start,
    end,
  }) => {
    if (!scheduled) return;
    if (validateSelectSlot && !validateSelectSlot({ start, end })) return;

    const eventStart = moment(scheduled.attributes.started_at).toDate();
    if (start < eventStart && eventStart < new Date()) return;

    const updatedDates = getUpdatedDates({
      scheduled,
      start,
      end: moment(end).subtract(1, 'second').toDate(),
    });

    const event = events.find(event => (
      event.id === scheduled.id && event.type === scheduled.type
    ));

    const updatedEvent = {
      ...(event || decorateEvent(scheduled)),
      ...updatedDates,
    };

    setFormVisible(true);

    setTemporaryEvents({
      ...temporaryEvents,
      [getCompositeId(updatedEvent)]: updatedEvent,
    });
  };

  const handleAssignment = (userId) => {
    onAssignmentChange(userId);
    if (openOnAssignment) setFormVisible(true);
  };

  const handleRangeChange = (range) => {
    const rangeObj = Array.isArray(range)
      ? { start: range[0], end: range[range.length - 1] }
      : { start: range.start, end: range.end };

    setRange(rangeObj);

    return onRangeChange(rangeObj);
  };

  const setDefaultRange = () => {
    const range = {
      start: moment(startDate || undefined)
        .startOf('month')
        .subtract(7, 'days')
        .toDate(),
      end: moment(startDate || undefined)
        .endOf('month')
        .add(7, 'days')
        .toDate(),
    };

    setRange(range);
  };

  useEffect(() => {
    if (!range) setDefaultRange();

    window.PUSHER.subscribe(`team-${currentTeamId}`)
      .bind('update', (event) => {
        if (event.window_id === window.WINDOW_ID) return;

        if (refresh && !formVisible && !refreshing) {
          setRefreshing(true);
          refresh()
            .finally(() => setRefreshing(false));
        }
      });

    return () => {
      window.PUSHER.unsubscribe(`team-${currentTeamId}`);
    };
  }, []);

  const decoratedEvents = [
    ...Object.values(temporaryEvents),
    ...events.filter(event => !Object.keys(temporaryEvents).includes(getCompositeId(event))),
  ].filter(event => event);

  const calendar = useMemo(() => (
    <DragAndDropCalendar
      key={`${dueDate}-${deadline}`}
      events={decoratedEvents}
      eventPropGetter={event => eventPropGetter(event, scheduled)}
      localizer={localizer}
      popup={false}
      showAllEvents
      selectable
      showMultiDayTimes
      views={views}
      onRangeChange={handleRangeChange}
      onSelectEvent={handleSelectEvent}
      onEventDrop={handleEventDrop}
      onEventResize={handleResizeEvent}
      onSelectSlot={handleSelectSlot}
      minTime={MIN_TIME}
      maxTime={MAX_TIME}
      defaultDate={startDate}
      resizable
      draggableAccessor={e => !isDisabled(e)}
      resizableAccessor={e => !isDisabled(e)}
      components={{
        month: {
          dateHeader: props => (
            <DateHeader
              {...props}
              dueDate={dueDate}
              deadline={deadline}
              planType={planType}
            />
          ),
          event: ({ event }) => (
            <MonthEvent
              event={event}
              completedIndicator={isEventOfPlan(event, getTaskPlanRel(scheduled))}
            />
          ),
        },
      }}
    />
  ), [JSON.stringify(decoratedEvents), JSON.stringify(scheduled)]);

  return (
    <>
      {scheduled && formVisible ? (
        <SchedulerForm
          user={user}
          onSubmit={handleSubmit}
          onCancel={handleCloseForm}
          visible={formVisible}
          initialValues={getInitialValues()}
          minTime={MIN_TIME}
          maxTime={MAX_TIME}
          key={scheduled.id}
          title={formTitle}
          scheduled={scheduled}
          minDate={minDate}
        />
      ) : null}

      {showTitle ? (
        <Title
          user={user}
          scheduled={scheduled}
          handleAssignment={handleAssignment}
          title={title}
        />
      ) : null}

      {loading ? (
        <Loader />
      ) : null}

      <div
        className={loading ? 'loading' : null}
        style={{ height: `${calendarHeight(decoratedEvents, range)}px` }}
      >
        {calendar}
      </div>
    </>
  );
};

GenericScheduler.propTypes = {
  scheduled: PropTypes.object,
  onChangeScheduled: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onAssignmentChange: PropTypes.func.isRequired,
  onRangeChange: PropTypes.func.isRequired,
  openOnAssignment: PropTypes.bool,
  formTitle: PropTypes.string,
  eventPropGetter: PropTypes.func.isRequired,
  startDate: PropTypes.any,
  dueDate: PropTypes.any,
  deadline: PropTypes.any,
  minDate: PropTypes.any,
  validateSelectSlot: PropTypes.func,
  title: PropTypes.func,
  showTitle: PropTypes.bool,
  getFormValues: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  views: PropTypes.array.isRequired,
  planType: PropTypes.string,
  user: PropTypes.object,
  isSelectable: PropTypes.func,
  setFormVisible: PropTypes.func.isRequired,
  formVisible: PropTypes.bool.isRequired,
  refresh: PropTypes.func,

  // connected
  setRange: PropTypes.func.isRequired,
  range: PropTypes.shape({
    start: PropTypes.instanceOf(Date),
    end: PropTypes.instanceOf(Date),
  }),
};

GenericScheduler.defaultProps = {
  scheduled: null,
  openOnAssignment: false,
  formTitle: null,
  startDate: null,
  dueDate: null,
  deadline: null,
  minDate: null,
  planType: 'projects',
  validateSelectSlot: null,
  title: null,
  showTitle: true,
  loading: false,
  user: null,
  isSelectable: () => true,
  refresh: null,
  range: null,
};

const mapStateToProps = (state, ownProps) => {
  let scheduled;
  if (ownProps.scheduled && ownProps.scheduled.attributes) {
    scheduled = ownProps.scheduled;
  } else if (ownProps.scheduled) {
    scheduled = getRecord(state.Api, {
      type: ownProps.scheduled.type,
      id: ownProps.scheduled.id,
    });
  } else {
    scheduled = getRecord(state.Api, {
      type: ownProps.scheduledType,
      id: ownProps.scheduledId,
    });
  }

  let { minDate } = ownProps;

  const scheduledMinDate = get(scheduled, 'attributes.min_date', null);

  if (!minDate && scheduledMinDate) {
    minDate = moment(scheduledMinDate)
      .startOf('day')
      .toDate();
  }

  const user = ownProps.userId && getRecord(state.Api, {
    type: 'users',
    id: ownProps.userId,
  });

  return {
    scheduled,
    event: scheduled ? getRecord(state.Api, scheduled) : {},
    user,
    loading: state.Scheduler.loading,
    minDate,
    range: state.Scheduler.range,
    currentTeamId: get(state, 'Auth.currentTeam.id', null),
  };
};

const mapDispatchToProps = {
  setRange,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GenericScheduler);
