import { snakeCase } from '../WorkOrderImportContainer/helpers';

export const ATTRIBUTES = [
  'Part Name',
  'Quantity',
  'Supplier',
  'Order Total',
];

export const TEXT_ATTRIBUTES = [
  'Part Name',
  'Supplier',
];

export const REQUIRED = [
  'Part Name',
  'Quantity',
];

export function getAttributeValue(row, attr) {
  return row[snakeCase(attr)];
}
