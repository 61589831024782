import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { Button } from 'react-bootstrap';
import { TEXT_ATTRIBUTES, getAttributeValue } from '../helpers';
import ErrorTable from './ErrorTable';
import ImportTable from './ImportTable';
import StatusAlert from './StatusAlert';

const UnitReview = ({
  closeModal,
  importObject,
  onSubmit,
  onDrop,
  openColumnMappingForm,
  uploading,
}) => {
  const errors = get(importObject, 'attributes.import_errors', []) || [];
  const rows = get(importObject, 'attributes.rows', []) || [];

  return (
    <>
      <div className="modal-header justify-content-center">
        <h5 className="font-weight-bold text-center mt-2">Review Imported Units</h5>
      </div>

      <div className="modal-body">
        <div className="mb-3">
          <StatusAlert errors={errors} importObject={importObject} />
        </div>

        <ErrorTable
          errors={errors}
          onDrop={onDrop}
          uploading={uploading}
        />

        <h5 className="font-weight-bold mb-3">Here are the Addresses in this import:</h5>

        <div className="mb-3">
          <Button onClick={openColumnMappingForm}>Change Column Mapping</Button>
        </div>

        <ImportTable
          rows={rows}
          textAttributes={TEXT_ATTRIBUTES}
          getAttributeValue={getAttributeValue}
        />
      </div>

      <div className="modal-footer">
        <button
          type="button"
          className="btn btn-secondary"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="button"
          onClick={onSubmit}
          className="btn btn-primary"
          disabled={!rows.find(row => row.valid)}
        >
          Save
        </button>
      </div>
    </>
  );
};

UnitReview.propTypes = {
  closeModal: PropTypes.func.isRequired,
  importObject: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  openColumnMappingForm: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
};

export default UnitReview;
