import React from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'react-bootstrap';
import VisibilitySensor from 'react-visibility-sensor/visibility-sensor';
import COLORS from '../../helpers/colors';

const UnreadBreak = ({ onVisibilityChange }) => (
  <VisibilitySensor onChange={onVisibilityChange}>
    <div id="unread-break">
      <hr style={{ backgroundColor: COLORS.red }} />
      <Badge
        variant="light"
        className="float-right"
        style={{
          color: COLORS.red,
          marginTop: '-27px',
          marginRight: '8px',
        }}
      >
        New Messages
      </Badge>
    </div>
  </VisibilitySensor>
);

UnreadBreak.propTypes = {
  onVisibilityChange: PropTypes.func.isRequired,
};

export default UnreadBreak;
