import React from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import toastr from 'toastr';
import { getRecord } from 'redux-json-api-module';
import EmailRecipientInput from './EmailRecipientInput';
import { isBlank } from '../../helpers/string';
import Badge from '../Badge';

class SendReportForm extends React.Component {
  static propTypes = {
    sendReport: PropTypes.func.isRequired,
    address: PropTypes.string.isRequired,
    tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
    close: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    serviceData: PropTypes.arrayOf(PropTypes.string).isRequired,
    formatReport: PropTypes.func.isRequired,
    headerText: PropTypes.string.isRequired,
    currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  state = {
    numRecipients: 2,
    submitting: false,
  };

  addRecipient = () => {
    const { numRecipients } = this.state;
    this.setState({ numRecipients: numRecipients + 1 });
  };

  getInitialValues = () => {
    const { currentUser } = this.props;
    return {
      name_0: currentUser.data.attributes.name,
      email_0: currentUser.data.attributes.email,
    };
  };

  getRecipientFields = () => {
    const { numRecipients } = this.state;
    const recipients = [];

    for (let i = 0; i < numRecipients; i++) {
      recipients.push(<EmailRecipientInput index={i} key={`email-recipient-${i}`} />);
    }

    return recipients;
  };

  formatRecipients = (report) => {
    const { numRecipients } = this.state;
    const recipients = [];
    for (let i = 0; i < numRecipients; i++) {
      if (!isBlank(report[`name_${i}`]) && !isBlank(report[`email_${i}`])) {
        recipients.push({
          name: report[`name_${i}`],
          email: report[`email_${i}`],
        });
      }
    }
    return recipients;
  };

  handleSubmit = (report, form) => {
    const { sendReport, close, formatReport, serviceData } = this.props;
    this.setState({ submitting: true });

    const recipients = this.formatRecipients(report);
    const data = formatReport(recipients, serviceData);

    sendReport(data)
      .then((resp) => {
        if (resp.error) {
          toastr.error('There was a problem sending the report');
          return;
        }

        toastr.success('Report sent!');
        form.reset();
        close();
      })
      .finally(() => this.setState({ submitting: false }));
  };

  render() {
    const { close, visible, headerText, tasks, address } = this.props;
    const { submitting } = this.state;

    return (
      <Modal
        show={visible}
        onHide={close}
        size="lg"
      >
        <Form
          initialValues={this.getInitialValues()}
          onSubmit={this.handleSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <div className="modal-header">
                <div className="m-auto text-center">
                  <h5 className="modal-title font-weight-bold">
                    {headerText}
                  </h5>
                </div>
              </div>
              <div className="modal-body">
                <div className="w-100 m-b-10 m-t-5 font-weight-bold">
                  Service Address
                </div>
                <div className="w-100 m-b-15">
                  {address}
                </div>
                <div className="w-100 m-b-10 m-t-5 font-weight-bold">
                  Send Service Report(s) For
                </div>
                <div className="w-100 m-b-15">
                  {tasks.map(task => (
                    <Badge
                      key={task.id}
                      style={{ marginRight: 10 }}
                      {...task.attributes.service_badge}
                    />
                  ))}
                </div>
                <div className="w-100 m-b-15 m-t-5 font-weight-bold">
                  Recipients
                </div>
                {this.getRecipientFields()}
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={this.addRecipient}
                >
                  + Add Recipient
                </button>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={close}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={submitting}
                >
                  Send
                </button>
              </div>
            </form>
          )}
        />
      </Modal>
    );
  }
}

export default SendReportForm;
