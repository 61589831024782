import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord, getRelationship } from 'redux-json-api-module';
import CommunityHeader from './CommunityHeader';
import PlanGroup from './PlanGroup';
import { idType } from '../../helpers/propTypes';
import { chatPlanSort } from '../../helpers/sorts';
import { getMessenger } from '../../redux/modules/chat';
import moment from 'moment';

const CommunityGroup = ({
  community,
  planThreads,
  userId,
  threadsList,
  onShowUnread,
  onHideUnread,
}) => (
  planThreads.length > 0 ? (
    <div>
      <CommunityHeader
        community={community}
        userId={userId}
      />
      {planThreads.map(thread => (
        <PlanGroup
          key={thread.id}
          messageThread={thread}
          userId={userId}
          communityId={community.id}
          threadsList={threadsList}
          onShowUnread={onShowUnread}
          onHideUnread={onHideUnread}
        />
      ))}
    </div>
  ) : null
);

CommunityGroup.propTypes = {
  showRead: PropTypes.bool.isRequired,
  community: PropTypes.object.isRequired,
  userId: idType.isRequired,

  // connected
  planThreads: PropTypes.array.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const threadIds = state.Chat.communityIdMap[ownProps.community.id] || [];
  const planThreads = threadIds.map(id => (
    getRecord(state.Api, {
      type: 'message_threads',
      id,
    })
  ))
    .filter(t => t.attributes.subject_type === ownProps.subjectType)
    .filter((thread) => {
      const messenger = getMessenger(state, thread.id, ownProps.userId);
      const unreadCount = messenger?.attributes?.unread_count ?? 0;
      const lastMessage = getRelationship(state.Api, thread.relationships.last_message);

      const chatRead = lastMessage
        ? moment().subtract('3 days').isAfter(lastMessage.attributes.created_at)
        : false;

      const threadViewed = messenger
        ? moment().subtract('3 days').isAfter(messenger.attributes.viewed_at)
        : false;

      return ownProps.showRead || unreadCount > 0 || chatRead || threadViewed;
    })
    .sort(chatPlanSort);

  return {
    planThreads,
  };
};

export default connect(mapStateToProps)(CommunityGroup);
