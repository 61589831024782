import React from 'react';
import { Form, Field } from 'react-final-form';
import moment from 'moment';
import arrayMutators from 'final-form-arrays';
import { OnChange } from 'react-final-form-listeners';
import UnitSelector from '../../../components/UnitSelector';
import { DateInput } from '../../ProjectCreateContainer/ProjectCreateForm/inputs';
import PrioritySelector from '../../../components/PrioritySelector/PrioritySelector';
import InputWrapper from './InputWrapper';
import Select from '../../../components/Inputs/Select';

const frequencies = [
  ['Daily', 'daily'],
  ['Weekly', 'weekly'],
  ['Every Two Weeks', 'biweekly'],
  ['Monthly', 'monthly'],
];

const WorkOrderCreateForm = ({
  initialValues,
  stateOptions,
  communityOptions,
  errors,
  unitOptions,
  saving,
  onSubmit,
  onUnitChange,
}) => (
  <Form
    keepDirtyOnReinitialize
    initialValues={initialValues}
    onSubmit={onSubmit}
    mutators={{
      ...arrayMutators,
    }}
    render={({ values, handleSubmit }) => (
      <form onSubmit={handleSubmit} id="new_work_order" className="fixed-width-inputs">
        <OnChange name="unit">
          {onUnitChange}
        </OnChange>

        <Field
          name="unit"
          component={UnitSelector}
          options={unitOptions}
          stateOptions={stateOptions}
          communityOptions={communityOptions}
          label="Select Unit"
          errors={errors}
          required
        />

        <InputWrapper
          label="Work Order #"
          required
          errors={errors}
          attribute="work_order_number"
        >
          <Field
            name="work_order_number"
            type="text"
            label="Work Order #"
            component="input"
            placeholder=""
            className="form-control string required"
          />
        </InputWrapper>

        <InputWrapper
          label="Work Order Description"
          required
          errors={errors}
          attribute="description"
        >
          <Field
            name="description"
            type="text"
            label="Description"
            component="textarea"
            placeholder=""
            className="form-control string required"
            rows="4"
          />
        </InputWrapper>

        <Field
          className="col-sm-9"
          name="keys_received_on"
          component={DateInput}
          optional
          label="Start Date"
          errors={errors ? errors.keys_received_on : null}
          maxDate={values.startedAt ? moment(values.startedAt)
            .endOf('day')
            .toDate() : null}
        />

        <Field
          name="due_on"
          component={DateInput}
          label="Due Date"
          minDate={values.keys_received_on}
          errors={errors ? errors.due_on : null}
          optional
        />

        <InputWrapper
          label="Permission to Enter"
          fieldContainerClassName="pt-2"
          required
          errors={errors}
          attribute="permission_to_enter"
        >
          <label className="mr-3">
            <Field
              name="permission_to_enter"
              component="input"
              type="radio"
              value="true"
            />
            {' '}
            Yes
          </label>
          <label>
            <Field
              name="permission_to_enter"
              component="input"
              type="radio"
              value="false"
            />
            {' '}
            No
          </label>
        </InputWrapper>

        <InputWrapper label="Additional Entry Notes">
          <Field
            name="additional_entry_notes"
            type="text"
            label="Additional Entry Notes"
            component="textarea"
            placeholder=""
            className="form-control string required"
            rows="2"
          />
        </InputWrapper>

        <InputWrapper label="Priority" required>
          <Field
            name="priority"
            component={PrioritySelector}
            label="Priority"
            errors={errors}
          />
        </InputWrapper>

        <InputWrapper label="Tenant Name">
          <Field
            name="tenant_name"
            type="text"
            label="Tenant Name"
            component="input"
            placeholder=""
            className="form-control string required"
          />
        </InputWrapper>

        <InputWrapper label="Tenant Email">
          <Field
            name="tenant_email"
            type="text"
            label="Tenant Email"
            component="input"
            placeholder=""
            className="form-control string required"
          />
        </InputWrapper>

        <InputWrapper label="Tenant Phone">
          <Field
            name="tenant_phone"
            type="text"
            label="Tenant Phone"
            component="input"
            placeholder=""
            className="form-control string required"
          />
        </InputWrapper>

        <h5 className="font-weight-bold">Make this a recurring work order</h5>
        <hr />

        <InputWrapper label="Repeat Work Order?" fieldContainerClassName="pt-2">
          <Field
            name="recurring"
            component="input"
            type="checkbox"
            className="scaled-input"
          />
        </InputWrapper>

        {values.recurring
          ? (
            <>
              <Field
                className="col-sm-9"
                name="start_recurring_on"
                component={DateInput}
                label="Start Repeating On"
                errors={errors ? errors.start_recurring_on : null}
                minDate={new Date()}
              />

              <Field
                className="col-sm-9"
                name="stop_recurring_on"
                component={DateInput}
                label="Stop Repeating On"
                errors={errors ? errors.stop_recurring_on : null}
                minDate={values.start_recurring_on ? moment(values.start_recurring_on)
                  .endOf('day')
                  .toDate() : null}
              />

              <InputWrapper
                label="Repeat Work Order"
                required
                errors={errors}
                attribute="recurring_frequency"
              >
                <Field
                  component={Select}
                  name="recurring_frequency"
                  includeBlank="Select Frequency"
                  options={frequencies}
                />
              </InputWrapper>
            </>
          ) : null
        }

        <div className="form-group row">
          <div className="offset-sm-3 col-sm-9">
            <input
              type="submit"
              id="workOrderCreateFormSubmitBtn"
              value={saving ? 'Saving...' : 'Save Work Order'}
              className="btn btn-success"
            />
            <a className="btn btn-inverse" href="/work_orders">Cancel</a>
          </div>
        </div>
      </form>
    )}
  />
);

export default WorkOrderCreateForm;
