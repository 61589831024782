import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { isImageFile } from './helpers';

const FilePreview = ({ file }) => {
  const previewUrl = useMemo(
    () => (isImageFile(file) ? URL.createObjectURL(file) : undefined),
    [file],
  );

  // Make sure to revoke the data uris to avoid memory leaks
  useEffect(() => () => (previewUrl && URL.revokeObjectURL(previewUrl)), [previewUrl]);

  return previewUrl ? (
    <img
      src={previewUrl}
      alt={file.name}
      className="rounded"
      style={{ maxWidth: 78, maxHeight: 45 }}
    />
  ) : (
    <i className="fa fa-file-o" />
  );
};

FilePreview.propTypes = {
  file: PropTypes.instanceOf(File).isRequired,
};

export default FilePreview;
