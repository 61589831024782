import React from 'react';
import PropTypes from 'prop-types';
import toastr from 'toastr';
import moment from 'moment';
import DatePicker from './DatePicker';
import editIcon from '../../assets/images/icon/edit-icon.png';

class ProjectStartDateSelector extends React.Component {
  static propTypes = {
    projectId: PropTypes.number.isRequired,
    value: PropTypes.string,
  };

  static defaultProps = {
    value: new Date(),
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      isOpen: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  handleChange(value) {
    const { projectId } = this.props;
    const { value: oldValue } = this.state;

    this.setState({
      value,
      isOpen: false,
    });

    const response = $.ajax({
      type: 'PATCH',
      url: `/api/v1/projects/${projectId}`,
      data: {
        data: {
          attributes: {
            start_date: moment(value)
              .utc()
              .format(),
          },
        },
      },
    });

    response.done((resp) => {
      toastr.success('Project Start Date has been updated.');

      // TODO: when project summary is controller via redux
      const dueDate = moment(resp.data.attributes.due_date);
      $('.project-due-date')
        .text(dueDate.format('MM/DD'));
    });

    response.fail(() => {
      toastr.error('Project Start Date could not be updated');
      this.setState({ value: oldValue });
    });
  }

  handleBlur() {
    this.setState({ isOpen: false });
  }

  render() {
    const { isOpen, value } = this.state;

    return (
      <React.Fragment>
        <span onClick={() => this.setState({ isOpen: true })} style={{ cursor: 'pointer' }}>
          {moment(value)
            .format('MM/DD')}
          <img
            src={editIcon}
            alt="Edit"
            width={25}
            style={{
              marginLeft: '5px',
              paddingBottom: '2px',
            }}
          />
        </span>
        <DatePicker
          onChange={this.handleChange}
          value={value}
          onBlur={this.handleBlur}
          isOpen={isOpen}
        />
      </React.Fragment>
    );
  }
}

export default ProjectStartDateSelector;
