import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { humanize } from '../../helpers/string';

const ErrorModal = ({
  visible,
  closeModal,
  errors,
}) => (
  <Modal
    show={visible}
    onHide={closeModal}
    className="text-left"
    size="xl"
  >
    <div className="modal-header">
      <h5 className="modal-title">
        There was a problem importing your work orders.
        <br />
        <small>
          Please correct the errors below and try again.
        </small>
      </h5>
    </div>
    <div className="modal-body">
      <table
        className="table"
        style={{
          tableLayout: 'fixed',
          width: '100%',
        }}
      >
        <thead>
          <tr>
            <th>Line Number</th>
            <th>Error Type</th>
            <th>Error details</th>
          </tr>
        </thead>
        <tbody>
          {errors.map((error, index) => (
            <tr key={index}>
              <td>
                {error.line_number ? (
                  <div className="no-wrap mr-2 col-2">{`Line ${error.line_number}`}</div>
                ) : null}
              </td>
              <td>
                {error.message}
              </td>
              <td>
                {Object.keys(error.errors || {})
                  .map(key => (
                    <div>
                      <span className="font-weight-bold">
                        {humanize(key)}
                      :&nbsp;
                      </span>
                      {(error.errors || {})[key]}
                    </div>
                  ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </Modal>
);

ErrorModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  errors: PropTypes.array,
};

ErrorModal.defaultProps = {
  errors: [],
};

export default ErrorModal;
