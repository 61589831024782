import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { deleteRecord, fetchRecord } from 'redux-json-api-module';
import get from 'lodash.get';


const PurchaseOrderActions = ({ purchaseOrder, deleteRecord, fetchRecord }) => {
  const handleDelete = () => {
    if (window.confirm('Are you sure?')) {
      return deleteRecord(purchaseOrder).then(() => {
        const itemId = get(purchaseOrder, 'relationships.item.data.id', null);
        if (itemId) fetchRecord('items', itemId);
      });
    }

    return true;
  };

  return (
    <>
      <Button
        id={`delete-${purchaseOrder.id}`}
        variant="link"
        size="lg"
        onClick={handleDelete}
      >
        <i className="fa fa-trash" />
      </Button>
    </>
  );
};

PurchaseOrderActions.propTypes = {
  // connected
  purchaseOrder: PropTypes.objectOf(PropTypes.any).isRequired,
  deleteRecord: PropTypes.func.isRequired,
  fetchRecord: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  purchaseOrder: state.Api.purchase_orders[ownProps.poId],
});

const mapDispatchToProps = {
  deleteRecord,
  fetchRecord,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PurchaseOrderActions);
