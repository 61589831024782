import React from 'react';
import PropTypes from 'prop-types';
import resizeIcon from '../../../assets/images/icon/resize.png';
import LazyProgressiveImage from '../LazyProgressiveImage';

class ThumbnailSlide extends React.Component {
  static propTypes = {
    image: PropTypes.shape({
      url: PropTypes.string,
      thumb_sq: PropTypes.shape({
        url: PropTypes.string,
      }),
      placeholder: PropTypes.shape({
        url: PropTypes.string,
      }),
      thumb_placeholder_sq: PropTypes.shape({
        url: PropTypes.string,
      }),
    }).isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      hover: false,
    };

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  handleMouseEnter() {
    this.setState({ hover: true });
  }

  handleMouseLeave() {
    this.setState({ hover: false });
  }

  render() {
    const { image } = this.props;
    const { hover } = this.state;

    return (
      <div
        className="position-relative"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <LazyProgressiveImage
          sourceUrl={image.thumb_sq.url}
          placeholderUrl={image.thumb_placeholder_sq.url}
          alt="Service completion"
          imageClassName="mw-100"
        />
        {hover && (
          <a
            href={image.url}
            target="_blank"
            rel="noopener noreferrer"
            className="resize-badge"
          >
            <img src={resizeIcon} width={25} alt="Resize Icon" />
          </a>
        )}
      </div>
    );
  }
}

export default ThumbnailSlide;
