import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import FilePreview from './FilePreview';
import { isImageFile } from './helpers';

const AttachmentsTable = ({ fields }) => {
  if (fields.length === 0) return null;

  return (
    <div className="table-responsive">
      <table className="table table-attachments vertical-middle">
        <thead>
          <tr>
            <th width="70">Preview</th>
            <th>Name</th>
            <th width="47" />
          </tr>
        </thead>
        <tbody>
          {fields.map((name, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <tr key={`attachment-${index}`}>
              <Field name={`${name}.file`}>
                {({ input: { value: file } }) => (
                  /*
                  path: "sample-1.jpg"
                  lastModified: 1505064790000
                  lastModifiedDate: Sun Sep 10 2017 10:33:10 GMT-0700 (Mountain Standard Time) {}
                  name: "sample-1.jpg"
                  size: 31394
                  type: "image/jpeg"
                  webkitRelativePath: ""
                  */
                  <>
                    <td className={`text-center ${isImageFile(file) ? 'p-1' : ''}`}>
                      <FilePreview file={file} />
                    </td>
                    <td>{file.name}</td>
                    <td className="p-1 pt-2">
                      <button
                        type="button"
                        title="Remove attachment"
                        className="btn btn-link text-dark"
                        onClick={() => fields.remove(index)}
                      >
                        <i className="fa fa-trash" />
                      </button>
                    </td>
                  </>
                )}
              </Field>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

AttachmentsTable.propTypes = {
  fields: PropTypes.shape({
    length: PropTypes.number.isRequired,
    map: PropTypes.func.isRequired,
    remove: PropTypes.func.isRequired,
  }).isRequired,
};

export default AttachmentsTable;
