import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRelationship } from 'redux-json-api-module';
import get from 'lodash.get';
import Badge from '../../../components/Badge';
import ConditionSelector from '../../../components/ConditionSelector/ConditionSelector';
import { Button } from 'react-bootstrap';

const Header = ({
  task,
  service,
  condition,
  conditions,
  serviceStatus,
  visible,
  onShow,
  onHide,
}) => (
  <div className="mt-3 mb-4 d-flex justify-content-center align-items-center">
    <div style={{ marginRight: 'auto' }} />
    <div
      style={{ paddingLeft: '37px' }}
      className="d-flex justify-content-center align-items-center"
    >
      <Badge
        {...service.attributes}
        label={service.attributes.name}
        style={{ fontSize: '14px' }}
      />
      {task.attributes.aasm_state === 'completed' ? (
        <Badge
          {...conditions}
          style={{ fontSize: '14px' }}
          className="ml-1"
        />
      ) : (
        <div className="ml-1">
          <ConditionSelector
            condition={condition}
            conditionBadge={conditions}
            taskType={task.type}
            id={task.id}
          />
        </div>
      )}
      <Badge
        {...serviceStatus}
        style={{ fontSize: '14px' }}
        className="ml-1"
      />
    </div>
    <div style={{ marginLeft: 'auto' }}>
      {visible ? (
        <Button
          variant="link"
          onClick={onHide}
          id={`hide-service-button-${service.id}`}
        >
          <i className="fa fa-arrow-up font-20" />
        </Button>
      ) : (
        <Button
          variant="link"
          onClick={onShow}
          id={`show-service-button-${service.id}`}
        >
          <i className="fa fa-arrow-right font-20" />
        </Button>
      )}
    </div>
  </div>
);

Header.propTypes = {
  task: PropTypes.objectOf(PropTypes.any).isRequired,
  visible: PropTypes.bool.isRequired,
  onShow: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  conditions: PropTypes.object,
  condition: PropTypes.string,
  summary: PropTypes.bool,
  service: PropTypes.object,
  serviceStatus: PropTypes.object,
};

Header.defaultProps = {
  service: null,
  summary: false,
  conditions: null,
  condition: null,
  serviceStatus: null,
};

const mapStateToProps = (state, ownProps) => {
  const conditions = ownProps.task.attributes.condition_badge;
  const serviceStatus = get(ownProps, 'task.attributes.service_status.private', null);

  return {
    conditions,
    condition: get(ownProps, 'task.attributes.condition', null),
    serviceStatus,
    service: getRelationship(state.Api, ownProps.task.relationships.service),
  };
};

export default connect(mapStateToProps)(Header);
