import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchRecords } from 'redux-json-api-module';
import PlansForm from './PlansForm';
import ProjectListingsTable from './Table/ProjectListingsTable';
import WorkOrderListingsTable from './Table/WorkOrderListingsTable';
import './CompletedPlansContainer.scss';

const CompletedPlansContainer = ({ fetchRecords }) => {
  const [values, setValues] = useState({ planType: 'projects' }); // [communityId, unitId, serviceId, startDate, endDate]

  const handleSubmit = (formValues) => {
    const newValues = { ...values, ...formValues };
    newValues.unitId = parseInt(newValues.unitId) > 0 ? newValues.unitId : null;
    newValues.serviceId = parseInt(newValues.serviceId) > 0 ? newValues.serviceId : null;

    setValues(newValues);
  };

  useEffect(() => {
    fetchRecords('communities').then((resp) => {
      setValues({ ...values, communityId: resp.payload.data.data[0].id });
    });
  }, []);

  return values.planType && values.communityId ? (
    <>
      <div className="card">
        <div className="card-body">
          <PlansForm onSubmit={handleSubmit} communityId={values.communityId} />
        </div>
      </div>
      <div className="card">
        <div className="card-body">
          {values.planType === 'projects' ? (
            <ProjectListingsTable values={values} />
          ) : (
            <WorkOrderListingsTable values={values} />
          )}
        </div>
      </div>
    </>
  ) : null;
};

CompletedPlansContainer.propTypes = {
  communityId: PropTypes.string,
  planType: PropTypes.string,

  // connected
  fetchRecords: PropTypes.func.isRequired,
};

CompletedPlansContainer.defaultProps = {
  communityId: '23',
  planType: 'projects',
};

const mapDispatchToProps = {
  fetchRecords,
};

export default connect(null, mapDispatchToProps)(CompletedPlansContainer);
