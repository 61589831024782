import React from 'react';
import moment from 'moment';
import { Card } from '../Card';
import Avatar from '../Avatar';
import { simpleFormat } from '../../helpers/string';
import DueDatePicker from './DueDatePicker';
import KeysReceivedOnDatePicker from './KeysReceivedOnDatePicker';
import Badge from '../Badge';
import './Summary.scss';

const Summary = ({
  planType,
  planId,
  fullAddressFormatted,
  communityName,
  mapsUrl,
  mapsImageUrl,
  submitDueDate,
  submitKeysReceivedOn,
  aasmState,
  completedAt,
  estimatedDone,
  keysReceivedOn,
  dueOn,
  status,
  inspector,
  manager,
}) => (
  <Card className="summary">
    <div className="row">
      <div className="col-sm-6 col-lg-2">
        <p className="font-weight-bold">
          Address
        </p>
        <p
          className="mt-2"
          dangerouslySetInnerHTML={{ __html: simpleFormat(fullAddressFormatted) }}
        />
        <p className="mt-2">{communityName}</p>
      </div>
      <div className="col-sm-6 col-lg-2">
        <a
          href={mapsUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            style={{
              maxHeight: '150px',
              maxWidth: '100%',
            }}
            src={mapsImageUrl}
            alt="Unit Map"
          />
        </a>
      </div>
      <div className="col-sm-6 col-lg-3">
        <table className="table table-borderless table-sm summary-table">
          <tbody>
            <tr>
              <td className="font-weight-bold">
                Start
              </td>
              <td>
                <KeysReceivedOnDatePicker
                  keysReceivedOn={keysReceivedOn}
                  aasmState={aasmState}
                  dueOn={dueOn}
                  onChange={keysReceivedOn => submitKeysReceivedOn(parentType, parentId, keysReceivedOn)}
                />
              </td>
            </tr>
            <tr>
              <td className="font-weight-bold">
                Due
              </td>
              <td>
                <DueDatePicker
                  planType={planType}
                  planId={planId}
                  onChange={dueOn => submitDueDate(planType, planId, dueOn, keysReceivedOn)}
                />
              </td>
            </tr>
            {aasmState === 'completed' ? (
              <tr>
                <td className="font-weight-bold">
                  Done
                </td>
                <td>
                  {moment(completedAt)
                    .format('MM/DD')}
                </td>
              </tr>
            ) : (
              <tr>
                <td className="font-weight-bold">
                  Est. Done
                </td>
                <td>
                  {estimatedDone}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="col-sm-6 col-lg-5">
        <table className="table table-borderless table-sm summary-table">
          <tbody>
            <tr>
              <td className="font-weight-bold">
                Status
              </td>
              <td>
                <Badge {...status} />
              </td>
            </tr>
            {inspector ? (
              <tr>
                <td className="font-weight-bold">
                  Inspected By
                </td>
                <td>
                  <React.Fragment>
                    <Avatar {...inspector.attributes} className="avatar-xs d-inline-block" />
                    {` ${inspector.attributes.name}`}
                  </React.Fragment>
                </td>
              </tr>
            ) : null}
            {manager ? (
              <tr>
                <td className="font-weight-bold">
                  Approved By
                </td>
                <td>
                  <React.Fragment>
                    <Avatar {...manager.attributes} className="avatar-xs d-inline-block" />
                    {` ${manager.attributes.name}`}
                  </React.Fragment>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </div>
  </Card>
);

export default Summary;
