import React from 'react';
import PropTypes from 'prop-types';
import Badge from '../Badge';

const BadgeHeader = ({
  serviceBadge,
  conditionBadge,
  serviceStatus,
  priorityBadge,
  lateBadge,
}) => (
  <div className="mt-3 mb-4 text-center" id="badge-header">
    <Badge
      {...serviceBadge}
      className="font-14 mr-1"
    />

    {priorityBadge && Object.keys(priorityBadge).length > 0 ? (
      <Badge
        {...priorityBadge}
        className="font-14 mr-1"
      />
    ) : (
      <Badge
        {...conditionBadge}
        className="font-14 mr-1"
      />
    )}

    <Badge
      {...serviceStatus}
      className="font-14 mr-1"
    />
    {lateBadge ? <Badge {...lateBadge} className="font-14" /> : null}
  </div>
);

BadgeHeader.propTypes = {
  serviceBadge: PropTypes.object,
  conditionBadge: PropTypes.object,
  serviceStatus: PropTypes.object,
  priorityBadge: PropTypes.object,
};

BadgeHeader.defaultProps = {
  serviceBadge: null,
  conditionBadge: null,
  serviceStatus: null,
  priorityBadge: null,
};

export default BadgeHeader;
