import React from 'react';
import DatePicker from 'react-datepicker';

export const DateInput = ({ input, label, minDate }) => (
  <div className="form-group">
    <DatePicker
      selected={input.value}
      onChange={input.onChange}
      className="form-control"
      minDate={minDate}
    />
    <br />
    <span>{label}</span>
  </div>
);

export const TimeInput = ({ input, label, minTime, maxTime }) => (
  <div className="form-group">
    <DatePicker
      selected={input.value}
      onChange={input.onChange}
      className="form-control"
      showTimeSelect
      showTimeSelectOnly
      dateFormat="h:mm aa"
      minTime={minTime}
      maxTime={maxTime}
    />
    <br />
    <span>{label}</span>
  </div>
);

export const Checkbox = ({ input, label }) => (
  <label>
    <input {...input} type="checkbox" checked={input.value} className="mr-1" />
    {label}
  </label>
);
