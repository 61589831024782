import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { getRecord, fetchRecord } from 'redux-json-api-module';
import moment from 'moment';
import get from 'lodash.get';
import { IMAGE_MAP } from '../../../helpers/types';
import { idType } from '../../../helpers/propTypes';
import './AttachmentManager.scss';

const TaskAttachmentManager = (
  {
    showUser,
    taskId,
    taskType,
    attachments,
    onDelete,
    onCreate,
    nestedUpload,
    fetchRecord,
    type,
    planClass,
  },
) => {
  const [pending, setPending] = useState([]);

  const getFormData = (file) => {
    const formData = new FormData();
    const nestedAttributesName = nestedUpload ? `[${type}_attributes][0]` : '';
    formData.append(`data[attributes]${nestedAttributesName}[task_id]`, taskId);
    formData.append(`data[attributes]${nestedAttributesName}[plan_type]`, planClass);
    formData.append(`data[attributes]${nestedAttributesName}[user_id]`, CURRENT_USER_ID);
    formData.append(`data[attributes]${nestedAttributesName}[file]`, file);

    return formData;
  };

  const fetchAttachments = () => {
    fetchRecord(taskType, taskId, { include: IMAGE_MAP[taskType] });
  };

  const getAttachmentAttributes = attachment => (
    attachment.attributes ? attachment : {
      ...attachment,
      attributes: attachment,
    }
  );

  const onDrop = (acceptedFiles) => {
    // add a pending record for everything
    setPending(acceptedFiles);

    acceptedFiles.forEach((file) => {
      const formData = getFormData(file);

      fetch(`/api/v1/${taskType}/${taskId}`, { // Your POST endpoint
        method: nestedUpload ? 'PATCH' : 'POST',
        body: formData,
      })
        .then(response => response.json())
        .then(onCreate)
        .then(fetchAttachments)
        .catch(error => console.log(error))
        .finally(() => {
          // remove an item from pending
          setPending(pending.slice(0, -1));
        });
    });
  };

  const handleDelete = (attachment) => {
    if (!window.confirm('Are you sure?')) return;
    onDelete(attachment);
  };

  useEffect(() => {
    fetchAttachments();
  }, []);

  return (
    <div className="attachment-manager">
      <Dropzone onDrop={onDrop}>
        {({ getRootProps, getInputProps, isDragActive }) => (
          <div
            {...getRootProps()}
            className={`dropzone ${isDragActive ? 'dropzone--isActive' : null}`}
          >
            <input name="data[attributes][file]" {...getInputProps()} />
            {
              isDragActive ? (
                <p className="dropzone-instructions">Drop files here...</p>
              ) : (
                <p className="dropzone-instructions">
                  Drag Files Here
                  &nbsp;&nbsp;
                  OR
                  &nbsp;&nbsp;&nbsp;
                  <button className="btn btn-secondary" type="button">
                    Click Here to Choose
                  </button>
                </p>
              )
            }
          </div>
        )}
      </Dropzone>

      {(attachments.length > 0 || pending.length > 0) && (
        <div className="table-responsive">
          <table className="table table-attachments vertical-middle">
            <thead>
            <tr>
              <th width="15%">File</th>
              {showUser ? <th width="15%">Uploaded By</th> : null}
              <th width="10%">Date</th>
              <th width="5%" />
            </tr>
            </thead>
            <tbody>
            {attachments.map(a => getAttachmentAttributes(a))
              .map((attachment) => {
                if (attachment.attributes.file === undefined) return null;
                const url = get(attachment, 'attributes.file.thumb.url', null);
                const name = get(attachment, 'attributes.file.name', 'upload');
                return (
                  <tr key={attachment.id}>
                    <td className="file-name">
                      <a
                        href={attachment.attributes.file.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {url ? (
                          <img
                            src={url}
                            alt="upload preview"
                            className="mw-100"
                          />
                        ) : (
                          name
                        )}
                      </a>
                    </td>
                    {showUser ? <td>{attachment.attributes.user_name}</td> : null}
                    <td>
                      {moment(attachment.attributes.created_at)
                        .format('MM/DD/YYYY')}
                    </td>
                    <td>
                      <button
                        type="button"
                        title="Delete Attachment"
                        className="btn btn-link text-dark"
                        onClick={() => handleDelete(attachment)}
                      >
                        <i className="fa fa-trash" />
                      </button>
                    </td>
                  </tr>
                );
              })}

            {pending.map((attachment, i) => (
              <tr key={`pending-${i}`}>
                <td colSpan={showUser ? 4 : 3}>Uploading...</td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const task = getRecord(state.Api, { type: ownProps.taskType, id: ownProps.taskId });

  if (!task) {
    return {
      attachments: [],
    };
  }

  const attachments = (task.relationships[IMAGE_MAP[ownProps.taskType]].data || []).map(image => (
    getRecord(state.Api, { type: 'task_images', id: image.id })
  )).filter(a => a);

  return {
    attachments,
  };
};

TaskAttachmentManager.propTypes = {
  showUser: PropTypes.bool,
  taskIds: PropTypes.arrayOf(idType),
  taskId: idType,
  taskType: PropTypes.string,
  type: PropTypes.string,
  attachments: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDelete: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  nestedUpload: PropTypes.bool,
  fetchRecord: PropTypes.func.isRequired,
  planClass: PropTypes.string.isRequired,
};

TaskAttachmentManager.defaultProps = {
  showUser: false,
  nestedUpload: false,
  taskId: null,
  taskType: null,
  taskIds: [],
  type: null,
};

const mapDispatchToProps = {
  fetchRecord,
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskAttachmentManager);
