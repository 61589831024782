import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../../components/Avatar';
import { idComparison } from '../../helpers/string';
import { idType } from '../../helpers/propTypes';

const TeamMemberCheckbox = ({
  user,
  input,
  currentUserId,
  isCreating,
}) => (
  <div className="my-3">
    <label>
      <input
        id={`team-member-${user.id}`}
        type="checkbox"
        checked={input.checked}
        style={{
          marginTop: '10px',
          marginRight: '10px',
        }}
        onChange={isCreating && idComparison(currentUserId, user.id) ? () => {} : input.onChange}
        value={input.name}
        name={input.name}
      />
      <Avatar {...user.attributes} className="avatar-xs d-inline-block" />
      <span>
        &nbsp;
        {user.attributes.name}
      </span>
    </label>
  </div>
);

TeamMemberCheckbox.propTypes = {
  user: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired,
  isCreating: PropTypes.bool.isRequired,
  currentUserId: idType.isRequired,
};

export default TeamMemberCheckbox;
