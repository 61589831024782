import React from 'react';
import { components } from 'react-select';
import UserDisplay from './UserDisplay';

const UserOption = props => (
  <components.Option {...props}>
    <UserDisplay {...props.data} />
  </components.Option>
);

export default UserOption;
