import React from 'react';
import PropTypes from 'prop-types';

const CompletedEventIndicator = ({ color }) => (
  <i
    className="fa fa-fw fa-circle"
    style={{
      marginRight: 4,
      color,
    }}
  />
);

CompletedEventIndicator.propTypes = {
  color: PropTypes.string.isRequired,
};

export default CompletedEventIndicator;
