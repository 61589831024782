import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord, getRelationship } from 'redux-json-api-module';
import { genericRecordPropType, idPropType } from '../../../helpers/propTypes';

const projectLink = (feature) => {
  if (feature.type === 'features') {
    return `/projects/${feature.relationships.project.data.id}`;
  }

  if (feature.type === 'work_order_features') {
    return `/work_orders/${feature.relationships.work_order.data.id}`;
  }

  return '#';
};

const UnitCell = ({ feature, unit }) => (
  <a href={projectLink(feature)}>
    {unit.attributes.short_address}
  </a>
);

UnitCell.propTypes = {
  featureId: idPropType.isRequired,
  featureType: PropTypes.string.isRequired,

  // connected
  unit: genericRecordPropType.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const feature = getRecord(state.Api, { id: ownProps.featureId, type: ownProps.featureType });
  const room = getRelationship(state.Api, feature.relationships.room);

  return {
    feature,
    unit: getRelationship(state.Api, room.relationships.unit),
  };
};

export default connect(
  mapStateToProps,
)(UnitCell);
