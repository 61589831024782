import get from 'lodash.get';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import Badge from '../../../../components/Badge';
import { idType } from '../../../../helpers/propTypes';
import { getRecord } from 'redux-json-api-module';

const WorkOrderDetails = ({ workOrder }) => (
  <div className="p-3" id="work_order_description_details">
    <div className="row">
      <div className="col">
        <h5 className="font-weight-bold" style={{ fontSize: '14px' }}>Work Order Description</h5>
      </div>
    </div>
    <div className="row mb-3">
      <div className="col">
        {get(workOrder, 'attributes.description', '')}
      </div>
    </div>
    <div className="row">
      <div className="col">
        <div className="row">
          <div className="col">
            <h5 className="font-weight-bold" style={{ fontSize: '14px' }}>Work Order #</h5>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {get(workOrder, 'attributes.work_order_number', '')}
          </div>
        </div>
      </div>
      <div className="col">
        <div className="row">
          <div className="col">
            <h5 className="font-weight-bold" style={{ fontSize: '14px' }}>Priority</h5>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Badge {...get(workOrder, 'attributes.priority_badge', {})} />
          </div>
        </div>
      </div>
    </div>
  </div>
);

WorkOrderDetails.propTypes = {
  workOrderId: idType.isRequired,

  // connected
  workOrder: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const workOrder = getRecord(state.Api, {
    id: ownProps.workOrderId,
    type: 'work_orders',
  });

  return {
    workOrder,
  };
};

export default connect(
  mapStateToProps,
)(WorkOrderDetails);
