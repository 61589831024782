import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import get from 'lodash.get';
import { getRecord, fetchRecords } from 'redux-json-api-module';
import { compose } from 'redux';
import neutralZeroUnreadIcon from '../../../assets/images/icon/neutral-zero-unread.png';
import unreadMentionIcon from '../../../assets/images/icon/unread-mention.png';
import unreadNonMentionIcon from '../../../assets/images/icon/unread-non-mention.png';
import {
  getUnreadCounts,
  getActiveTotals,
  fetchTaggableUsers,
  handleNewMessage,
  setFlashingMessageThreadId,
} from '../../redux/modules/chat';
import { userObject, teamObject, idType } from '../../helpers/propTypes';
import ChatsContainer from '../ChatsContainer/ChatsContainer';
import {
  fetchPlan,
  hydrateProjectManagementStore,
  fetchTasks,
} from '../../redux/modules/projectManagement';
import { fetchProjects, fetchProjectListing } from '../../redux/modules/projects';
import { fetchWorkOrders, fetchWorkOrderListing } from '../../redux/modules/workOrders';
import chatFavicon from '../../../assets/images/icon/red-chat-favicon.png';
import favicon from '../../../assets/images/favicon.png';
import { openPlanChat } from '../../redux/modules/navigation';
import { idComparison } from '../../helpers/string';
import { setCurrentTeam, setCurrentUser } from '../../redux/modules/auth';
import AlarmBell from '../../components/Animations/AlarmBell';
import RipplesGreen from '../../components/Animations/RipplesGreen';
import { fetchPartsNeeded } from '../../redux/modules/partsTasks';
import RipplesPurple from '../../components/Animations/RipplesPurple';

function getIcon(totalUnreadCount, totalUnreadContent) {
  if (totalUnreadCount) return unreadMentionIcon;
  if (totalUnreadContent) return unreadNonMentionIcon;

  return neutralZeroUnreadIcon;
}

// alternate between adding all messenger data once it's loaded by the modal
// or raw count queries by fetch when the modal and data are not being refreshed
function useAggregateCount(modalVisible, messengersLoaded, closeFinished) {
  return (modalVisible && messengersLoaded) || !closeFinished;
}

function checkPlanMatch(planId, planType, activeProjectId, activeWorkOrderId) {
  if (!activeProjectId && !activeWorkOrderId) return true;

  if (activeProjectId && planType === 'projects' && idComparison(activeProjectId, planId)) {
    return true;
  }

  if (activeWorkOrderId && planType === 'work_orders' && idComparison(activeWorkOrderId, planId)) {
    return true;
  }

  return false;
}

const CHAT_PATHS = [
  '/projects',
  '/events',
  '/project_services',
  '/schedule',
  '/',
  '/work_orders',
  '/work_order_tasks',
];

function withLayout(LayoutComponent) {
  return class LayoutContainer extends Component {
    static propTypes = {
      totalUnreadCount: PropTypes.number.isRequired,
      totalUnreadContent: PropTypes.bool.isRequired,
      activeTotalUnreadContent: PropTypes.bool.isRequired,
      activeTotalUnreadCount: PropTypes.number.isRequired,
      messengersLoaded: PropTypes.bool.isRequired,
      team: teamObject.isRequired,
      user: userObject.isRequired,
      getUnreadCounts: PropTypes.func.isRequired,
      recentlyOnline: PropTypes.object.isRequired,
      fetchTaggableUsers: PropTypes.func.isRequired,
      fetchPlan: PropTypes.func.isRequired,
      fetchProjects: PropTypes.func.isRequired,
      fetchProjectListing: PropTypes.func.isRequired,
      fetchWorkOrders: PropTypes.func.isRequired,
      fetchWorkOrderListing: PropTypes.func.isRequired,
      handleNewMessage: PropTypes.func.isRequired,
      setFlashingMessageThreadId: PropTypes.func.isRequired,
      hydrateProjectManagementStore: PropTypes.func.isRequired,
      fetchTasks: PropTypes.func.isRequired,
      fetchRecords: PropTypes.func.isRequired,
      openPlanChat: PropTypes.func.isRequired,
      flashingMessageThreadId: idType,
      activeProjectId: idType,
      activeWorkOrderId: idType,
      areaItems: PropTypes.object.isRequired,
      serviceOptions: PropTypes.array.isRequired,
      conditionOptions: PropTypes.array.isRequired,
      descriptionOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
      aasmState: PropTypes.string,
      modalVisible: PropTypes.bool,

      // connected
      setCurrentUser: PropTypes.func.isRequired,
      setCurrentTeam: PropTypes.func.isRequired,
      fetchPartsNeeded: PropTypes.func.isRequired,
    };

    static defaultProps = {
      flashingMessageThreadId: null,
      activeProjectId: null,
      activeWorkOrderId: null,
      team: null,
      aasmState: null,
      modalVisible: false,
    };

    state = {
      modalVisible: false,
      loaded: false,
      closeFinished: false,
      toastVisible: null,
      team: null,
      title: 'Intimely',
    };

    componentDidMount() {
      const {
        fetchTaggableUsers,
        user,
        team,
        hydrateProjectManagementStore,
        areaItems,
        descriptionOptions,
        serviceOptions,
        conditionOptions,
        setCurrentUser,
        setCurrentTeam,
      } = this.props;

      hydrateProjectManagementStore({
        areaItems,
        descriptionOptions,
        serviceOptions,
        conditionOptions,
      });

      setCurrentUser(user.data);
      setCurrentTeam(team.data);
      fetchTaggableUsers(team.data.id);

      // the unread count fetch is very expensive to compute on the db,
      // this gives the rest of the page some time to load
      if (!this.canIgnoreCounts()) this.fetch();
      this.subscribe();
    }

    componentDidUpdate() {
      const { flashingMessageThreadId } = this.props;
      if (!flashingMessageThreadId) this.removeTitleNotification();
    }

    componentWillUnmount() {
      if (this.titleInterval) clearInterval(this.titleInterval);
      this.unsubscribe();
      this.removeTitleNotification();
    }

    addTitleNotification = (messageThreadId) => {
      this.removeTitleNotification();
      const { setFlashingMessageThreadId } = this.props;
      setFlashingMessageThreadId(messageThreadId);
      this.titleInterval = setInterval(() => {
        const { title } = this.state;
        const defaultState = title === 'Intimely';
        document.title = defaultState ? 'New Message' : 'Intimely';

        this.setFavicon(defaultState ? chatFavicon : favicon);

        this.setState({ title: document.title });
      }, 1000);
    };

    setFavicon = (faviconSrc) => {
      const link = document.querySelector('link[rel*=\'icon\']') || document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = faviconSrc;
      document.getElementsByTagName('head')[0].appendChild(link);
    };

    removeTitleNotification = () => {
      document.title = 'Intimely';
      this.setFavicon(favicon);
      if (this.titleInterval) clearInterval(this.titleInterval);
    };

    unsubscribe = () => {
      const { user, teamId } = this.props;

      window.PUSHER.unsubscribe(`team-${teamId}`);
      window.PUSHER.unsubscribe(`user-messages-${user.data.id}`);
    };

    subscribe = () => {
      const { user, team, handleNewMessage } = this.props;
      const teamId = get(team, 'data.id', null);

      if (!teamId) return;

      const channel = `user-messages-${user.data.id}`;

      window.PUSHER.subscribe(channel)
        .bind('new-message', (event) => {
          const { modalVisible } = this.state;

          Promise.all([
            handleNewMessage(event)
              .then(() => {
                const sameWindow = event.window_id === window.WINDOW_ID;

                if (event.tagged && this.canShowToast() && !sameWindow) {
                  this.setToastVisible(event);
                  this.addTitleNotification(event.message_thread_id);
                }
              }),
            modalVisible ? Promise.resolve({}) : this.fetch(),
          ]).then(this.handleCountUpdate);
        });

      window.PUSHER.subscribe(`team-${teamId}`)
        .bind('activity', this.handleActivityEvent);

      window.PUSHER.subscribe(`team-${teamId}`)
        .bind('update', this.handlePlanUpdateEvent);
    };

    handlePlanUpdateEvent = (event) => {
      const { fetchPartsNeeded, fetchProjects, fetchWorkOrders, fetchPlan } = this.props;
      const { plan_type: planType, plan_id: planId } = event;
      const { location: { pathname } } = window;

      fetchPartsNeeded().then((partsNeeded) => {
        if (partsNeeded) {
          ReactDOM.render(
            <RipplesPurple />,
            document.getElementById('parts-needed-alert-container'),
          );
        } else {
          document.getElementById('parts-needed-alert-container').innerHTML = null;
        }
      });

      if (event.window_id === window.WINDOW_ID) return;


      if (['/', '/projects'].includes(pathname)) {
        fetchProjects(planId);
      } else if (pathname === '/work_orders') {
        fetchWorkOrders(planId);
      } else {
        fetchPlan(planType, planId);
      }
    };

    setToastVisible = (toastVisible) => {
      this.setState({ toastVisible });
      if (toastVisible) {
        // 500ms extra buffer of time for fadeout animation to finish
        setTimeout(() => {
          this.setState({ toastVisible: null });
        }, 5500);
      }
    };

    loadActivity = (planType, planId) => {
      const { fetchRecords } = this.props;

      fetchRecords(`${planType}/${planId}/events`, {
        page: {
          size: 20,
          number: 0,
        },
        sort: '-events.created_at',
      });
    };

    handleActivityEvent = (event) => {
      const { user } = this.props;
      const { modalVisible } = this.state;
      const { plan_type: planType, plan_id: planId } = event;
      this.loadActivity(planType, planId);

      try {
        if ($('#activity-count').length) {
          $('#activity-count').html(parseInt($('#activity-count').html()) + 1);
        } else {
          $('#activity-link')
            .append('<span id=\'activity-count\' class=\'badge badge-pill badge-info float-right\'>1</span>');
        }
      } catch (e) {
        console.log(e);
      }

      if (parseInt(event.user_id) === parseInt(user.data.id)) return;

      if (!modalVisible) this.setToastVisible(event);
      this.reloadPlan(event);
    };

    handleCountUpdate = () => {
      const unreadCount = this.getUnreadCount();
      const unreadContent = this.getUnreadContent();

      try {
        if (unreadCount) {
          ReactDOM.render(<AlarmBell />, document.getElementById('unread-container'));
        } else if (unreadContent) {
          ReactDOM.render(<RipplesGreen />, document.getElementById('unread-container'));
        } else {
          document.getElementById('unread-container').innerHTML = '';
        }
      } catch (e) {
        console.log(e);
      }
    };

    reloadPlan = (event) => {
      const {
        fetchTasks,
        activeProjectId,
        activeWorkOrderId,
        fetchProjectListing,
        fetchWorkOrderListing,
        fetchPlan,
      } = this.props;
      const { plan_type: planType, plan_id: planId } = event;
      const { location: { pathname } } = window;
      const planMatch = checkPlanMatch(planId, planType, activeProjectId, activeWorkOrderId);

      if (planId && planMatch) {
        if (['/', '/projects'].includes(pathname)) {
          fetchProjectListing(planId);
        } else if (pathname === '/work_orders') {
          fetchWorkOrderListing(planId);
        } else {
          fetchPlan(planType, planId);
        }

        fetchTasks(planType, planId);
      }
    };

    fetch = () => {
      const { getUnreadCounts, user, team } = this.props;
      const teamId = get(team, 'data.id', null);

      if (!user || !user.data || !teamId) return undefined;

      return getUnreadCounts(user.data.id)
        .then(() => this.setState({
          loaded: true,
          closeFinished: true,
        }))
        .then(() => this.handleCountUpdate());
    };

    toggleModal = (open) => {
      const { openPlanChat } = this.props;

      if (open && this.isShowPage()) {
        openPlanChat();
      }
      this.setModalSate(open);
    };

    setModalSate = (open) => {
      this.setState({
        modalVisible: open,
        closeFinished: open,
      }, () => {
        this.fetch();
      });
    };

    getModal = () => {
      const { user, team } = this.props;
      const { modalVisible } = this.state;

      return (
        <Modal
          className="chat-modal"
          show={modalVisible}
          onHide={() => this.toggleModal(false)}
          dialogClassName="modal-lg scheduler-modal h-100"
        >
          <div className="p-4 modal-body scheduler-container" style={{ overflowY: 'hidden' }}>
            <ChatsContainer
              expandable
              user={user}
              team={team}
              closeModal={() => this.toggleModal(false)}
            />
          </div>
        </Modal>
      );
    };

    canOpenChat = () => (
      CHAT_PATHS.includes(window.location.pathname)
      || window.location.pathname.indexOf('/project_services') === 0
      || window.location.pathname.indexOf('/work_order_tasks') === 0
      || this.isShowPage()
    );

    isShowPage = () => {
      const { aasmState } = this.props;

      const parts = window.location.pathname.split('/')
        .filter(p => p);

      if (parts.length < 2) return false;

      if (parts.length === 3 && parts[2] !== 'review') return false;

      if (aasmState === 'completed') return false;

      if (parts[0] === 'projects' && parseInt(parts[1])) return true;

      // do not show chat bubble for work orders in assign state
      return (parts[0] === 'work_orders' && parseInt(parts[1]) && aasmState && aasmState !== 'assign');
    };

    canIgnoreCounts = () => (
      window.location.pathname === '/chats'
    );

    getPlanFetch = () => {
      const { fetchWorkOrders, fetchProjects, fetchPlan } = this.props;
      const { location: { pathname } } = window;

      let planFetch = fetchPlan;

      if (['/', '/projects'].includes(pathname)) {
        planFetch = fetchProjects;
      } else if (pathname === '/work_orders') {
        planFetch = fetchWorkOrders;
      }

      return planFetch;
    };

    canShowToast = () => {
      const { modalVisible } = this.state;

      return !modalVisible
        && window.location.pathname.indexOf('/projects/') !== 0
        && window.location.pathname.indexOf('/work_orders/') !== 0
        && window.location.pathname.indexOf('/teams/') !== 0;
    };

    getUnreadCount = () => {
      const {
        modalVisible,
        messengersLoaded,
        activeTotalUnreadCount,
        totalUnreadCount,
      } = this.props;

      const { closeFinished } = this.state;

      return useAggregateCount(modalVisible, messengersLoaded, closeFinished)
        ? activeTotalUnreadCount : totalUnreadCount;
    };

    getUnreadContent = () => {
      const {
        modalVisible,
        messengersLoaded,
        activeTotalUnreadContent,
        totalUnreadContent,
      } = this.props;

      const { closeFinished } = this.state;

      return useAggregateCount(modalVisible, messengersLoaded, closeFinished)
        ? activeTotalUnreadContent : totalUnreadContent;
    };

    render() {
      const unreadCount = this.getUnreadCount();
      const unreadContent = this.getUnreadContent();

      const icon = getIcon(unreadCount, unreadContent);

      return (
        <LayoutComponent
          {...this.props}
          {...this.state}
          icon={icon}
          unreadCount={unreadCount}
          unreadContent={unreadContent}
          toggleModal={this.toggleModal}
          canOpenChat={this.canOpenChat}
          getModal={this.getModal}
        />
      );
    }
  };
}

const mapStateToProps = (state, ownProps) => {
  // we request the totals via api until we are actively showing the chat modal, which will fetch
  // the necessary data live to keep it updated without a specific api call each change
  const userId = get(ownProps, 'user.data.id', null);
  const { activeTotalUnreadCount, activeTotalUnreadContent } = getActiveTotals(state, userId);

  const parts = window.location.pathname.split('/').filter(p => p);
  let aasmState;
  if (parts.length >= 2 && ['projects', 'work_orders'].includes(parts[0])) {
    const id = parseInt(parts[1]);
    if (id) {
      const plan = getRecord(state.Api, { type: parts[0], id });
      aasmState = get(plan, 'attributes.aasm_state', null);
    }
  }

  return {
    totalUnreadCount: state.Chat.totalUnreadCount,
    totalUnreadContent: state.Chat.totalUnreadContent,
    activeTotalUnreadContent,
    activeTotalUnreadCount,
    messengersLoaded: state.Chat.messengersLoaded,
    flashingMessageThreadId: state.Chat.flashingMessageThreadId,
    aasmState,
    activeProjectId: state.ProjectManagement.projectId,
    activeWorkOrderId: state.WorkOrderManagement.workOrderId,
  };
};

const mapDispatchToProps = {
  getUnreadCounts,
  fetchTaggableUsers,
  fetchPartsNeeded,
  fetchPlan,
  fetchProjects,
  fetchProjectListing,
  fetchWorkOrders,
  fetchWorkOrderListing,
  fetchRecords,
  handleNewMessage,
  setFlashingMessageThreadId,
  hydrateProjectManagementStore,
  fetchTasks,
  openPlanChat,
  setCurrentUser,
  setCurrentTeam,
};

const composedLayoutWrapper = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withLayout,
);

export default composedLayoutWrapper;
