import React from 'react';
import { components } from 'react-select';
import TaskDisplay from './TaskDisplay';

const TaskOption = props => (
  <components.Option {...props}>
    <TaskDisplay {...props.data} />
  </components.Option>
);

export default TaskOption;
