import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select, { createFilter } from 'react-select';
import toastr from 'toastr';
import { fetchUserOptions } from '../../redux/modules/projectManagement';
import UserDisplay from './UserDisplay';
import UserOption from './UserOption';

import './MaintenanceSelect.scss';
import UserAvatar from '../Avatar/UserAvatar';

const menuPortal = (base) => {
  const { zIndex, ...rest } = base;

  return {
    ...rest,
    zIndex: 9999,
  };
};

class MaintenanceSelect extends React.Component {
  static propTypes = {
    serviceId: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    fetchUserOptions: PropTypes.func.isRequired,
    user: PropTypes.shape({}),
    disabled: PropTypes.bool,
    menuPortalTarget: PropTypes.any,
  };

  static defaultProps = {
    disabled: false,
    user: undefined,
    menuPortalTarget: document.body,
  };

  constructor(props) {
    super(props);

    this.state = {
      options: [],
      loaded: false,
    };
  }

  fetchOptions = () => {
    const { fetchUserOptions, serviceId } = this.props;

    return fetchUserOptions(serviceId)
      .then((resp) => {
        if (resp.error) {
          toastr.error('Unable to fetch user options');
          return resp;
        }

        const options = [];
        const users = {};
        Object.keys(resp.payload.data)
          .forEach((label) => {
            resp.payload.data[label].forEach((option) => {
              users[option.id] = {
                id: option.id,
                attributes: option,
              };
            });
            options.push({
              label,
              options: resp.payload.data[label].map(option => ({
                ...option,
                value: option.id,
              })),
            });
          });

        this.setState({
          options,
          users,
        });
        return resp;
      });
  };

  handleMenuOpen = () => {
    const { loaded } = this.state;
    const { disabled } = this.props;
    if (loaded || disabled) return;

    this.fetchOptions()
      .then((resp) => {
        if (resp.error) return;

        this.setState({ loaded: true });
      });
  };

  handleChange = ({ id }) => {
    const { onChange } = this.props;
    const { users } = this.state;
    onChange(id, users[id]);
  };

  render() {
    const { disabled, user, menuPortalTarget } = this.props;
    const { options } = this.state;

    const value = user ? (
      [{
        value: user.id,
        label: <UserDisplay {...user.attributes} />,
      }]
    ) : null;

    if (disabled) {
      return user ? (
        <div className="maintenance-select-wrapper disabled">
          <UserAvatar userId={user.id} includeName size="xs" />
        </div>
      ) : null;
    }

    return (
      <div className="maintenance-select-wrapper">
        <Select
          classNamePrefix="maintenance-select"
          className="maintenance-select"
          components={{ Option: UserOption }}
          menuPortalTarget={menuPortalTarget}
          onChange={this.handleChange}
          onMenuOpen={this.handleMenuOpen}
          options={options}
          placeholder="Select user"
          styles={{ menuPortal }}
          value={value}
          filterOption={createFilter({
            ignoreAccents: false,
            stringify: o => o.data.name,
          })}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  fetchUserOptions,
};

const mapStateToProps = (state, ownProps) => {
  let { serviceId } = ownProps;
  if (!serviceId) {
    serviceId = ownProps.scheduled && ownProps.scheduled.relationships.service
      && ownProps.scheduled.relationships.service.data.id;
  }

  return {
    serviceId,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceSelect);
