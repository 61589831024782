import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';
import { fetchTasks, SCOPE_MAP } from '../../redux/modules/serviceTasks';
import SearchSelector from './SearchSelector';
import CommunityCard from './CommunityCard';
import Loader from '../../components/Loader';
import { idType } from '../../helpers/propTypes';
import './ServiceTasksContainer.scss';
import { fetchRecord, getRecord } from 'redux-json-api-module';
import Badge from '../../components/Badge';

const DEFAULT_FILTERS = {
  unitId: null,
  communityId: null,
  userId: null,
};

class ServiceTasksContainer extends Component {
  static propTypes = {
    fetchTasks: PropTypes.func.isRequired,
    communityIdMap: PropTypes.object.isRequired,
    serviceId: idType.isRequired,
    unitOptions: PropTypes.array.isRequired,
    userOptions: PropTypes.array.isRequired,
  };

  state = {
    ...DEFAULT_FILTERS,
    loading: false,
    planType: 'View All',
    scope: 'Last 30 Days',
  };

  componentDidMount() {
    const { fetchTasks, serviceId, fetchRecord } = this.props;
    const { scope } = this.state;

    this.setState({ loading: true });

    fetchRecord('services', serviceId);
    fetchTasks(serviceId, scope, this.state)
      .then(() => this.setState({ loading: false }));
  }

  handlePlanTypeChange = (planType) => {
    const { fetchTasks, serviceId } = this.props;
    const { scope } = this.state;

    this.setState({ planType, loading: true }, () => {
      fetchTasks(serviceId, scope, this.state)
        .then(() => this.setState({ loading: false }));
    });
  };

  handleScopeChange = (scope) => {
    const { fetchTasks, serviceId, userOptions } = this.props;
    const newState = { ...DEFAULT_FILTERS, scope, loading: true };

    if (scope === 'View By Team Member') {
      newState.userId = userOptions[0].id;
    }

    this.setState(newState, () => {
      fetchTasks(serviceId, scope, this.state)
        .then(() => this.setState({ loading: false }));
    });
  };

  handleUnitChange = (unit) => {
    const { fetchTasks, serviceId } = this.props;
    const { scope, communityId } = this.state;
    const newState = {
      ...DEFAULT_FILTERS,
      communityId,
      unitId: unit.id,
      loading: true,
    };

    this.setState(newState, () => {
      fetchTasks(serviceId, scope, this.state)
        .then(() => this.setState({ loading: false }));
    });
  };

  handleCommunityChange = (community) => {
    const { fetchTasks, serviceId } = this.props;
    const { scope } = this.state;

    this.setState({ ...DEFAULT_FILTERS, communityId: community.id, loading: true }, () => {
      fetchTasks(serviceId, scope, this.state)
        .then(() => this.setState({ loading: false }));
    });
  };

  handleUserChange = (_, user) => {
    const { fetchTasks, serviceId } = this.props;
    const { scope } = this.state;

    this.setState({ ...DEFAULT_FILTERS, userId: user.id, loading: true }, () => {
      fetchTasks(serviceId, scope, this.state)
        .then(() => this.setState({ loading: false }));
    });
  };

  render() {
    const { unitOptions, communityIdMap, userOptions, service } = this.props;
    const { scope, loading, unit, community, userId, planType } = this.state;

    return (
      <>
        <Card className="service-tasks">
          <div className="card-body p-3">
            {service ? (
              <div className="ml-2 mb-2">
                <Badge
                  {...service.attributes.service_badge}
                  style={{ fontSize: '14px' }}
                />
              </div>
            ) : null}
            <SearchSelector
              planType={planType}
              onPlanTypeChange={this.handlePlanTypeChange}
              filter={scope}
              onChange={this.handleScopeChange}
              communityOptions={unitOptions}
              onUnitChange={this.handleUnitChange}
              onCommunityChange={this.handleCommunityChange}
              unit={unit}
              community={community}
              userId={userId}
              onUserChange={this.handleUserChange}
              userOptions={userOptions}
            />
          </div>
        </Card>
        {loading ? <Loader /> : (
          Object.keys(communityIdMap)
            .map(communityId => (
              <CommunityCard
                communityId={communityId}
                key={communityId}
              />
            ))
        )}
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  communityIdMap: state.ServiceTasks.communityIdMap || {},
  service: getRecord(state.Api, { type: 'services', id: ownProps.serviceId }),
});

const mapDispatchToProps = {
  fetchTasks,
  fetchRecord,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceTasksContainer);
