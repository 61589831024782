import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';
import InventoryContainer from '../containers/InventoryContainer';

const Inventory = props => (
  <Provider store={store}>
    <InventoryContainer {...props} />
  </Provider>
);

export default Inventory;
