import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteRecord, getRecord, getRelationship } from 'redux-json-api-module';
import Button from 'react-bootstrap/Button';
import toastr from 'toastr';
import PropertyForm from './PropertyForm';
import { firstBy } from 'thenby';

const PropertyRow = ({ deleteRecord, property, units }) => {
  const [visible, setVisible] = useState(false);

  const handleDelete = () => {
    deleteRecord(property).then(() => {
      toastr.success('Property was successfully deleted.');
    });
  };

  const orderedUnits = units.sort(firstBy(a => a.attributes.short_address));

  return (
    <tr>
      <td className="v-middle">
        {property.attributes.name}
      </td>
      <td>
        <ul>
          {orderedUnits.map(u => (
            <li>{u.attributes.short_address}</li>
          ))}
        </ul>
      </td>
      <td>
        <Button
          size="sm"
          onClick={() => setVisible(true)}
        >
          Edit
        </Button>
        <Button
          size="sm"
          className="ml-1"
          onClick={handleDelete}
        >
          Delete
        </Button>
        <PropertyForm
          id={property.id}
          visible={visible}
          onHide={() => setVisible(false)}
        />
      </td>
    </tr>
  );
};

PropertyRow.propTypes = {};

const mapStateToProps = (state, ownProps) => {
  const property = getRecord(state.Api, { type: 'properties', id: ownProps.id });
  const units = getRelationship(state.Api, property.relationships.units);

  return {
    property,
    units,
  };
};

const mapDispatchToProps = {
  deleteRecord,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PropertyRow);
