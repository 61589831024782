import React from 'react';
import { connect } from 'react-redux';
import ServiceCompletionReportDownload
  from '../../ProjectManagementContainer/ServiceCompletionReportDownload/ServiceCompletionReportDownload';
import { sendWorkOrderCompletionReports } from '../../../redux/modules/workOrderManagement';

const formatReport = (recipients, serviceIds) => ({
  work_order: {
    completion_reports: {
      recipients,
      work_order_task_ids: serviceIds,
    },
  },
});

const WorkOrderTaskCompletionReportDownload = props => (
  <ServiceCompletionReportDownload
    planType="work_orders"
    taskType="work_order_tasks"
    formatReport={formatReport}
    {...props}
  />
);

const mapDispatchToProps = {
  sendTaskCompletionReports: sendWorkOrderCompletionReports,
};

export default connect(null, mapDispatchToProps)(WorkOrderTaskCompletionReportDownload);
