import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import toastr from 'toastr';
import Alert from '../../../components/Alert';
import ServiceAttributesForm from './ServiceAttributesForm';

import 'react-image-picker/dist/index.css';

const selectedFeatureImageStyle = {
  cursor: 'auto',
};

const selectedFeatureImageImgStyle = {
  width: 150,
  height: 150,
  objectFit: 'cover',
  boxShadow: 'none',
};

const SelectedFeatureImage = ({ src }) => (
  <div
    className="responsive selected"
    style={selectedFeatureImageStyle}
  >
    <img
      src={src}
      alt=""
      className="thumbnail selected"
      style={selectedFeatureImageImgStyle}
    />
    <div className="checked">
      <div className="icon" />
    </div>
  </div>
);

SelectedFeatureImage.propTypes = {
  src: PropTypes.string.isRequired,
};

const SelectedFeatureImages = ({ images }) => (
  <div className="image_picker">
    {images.map(image => (
      <SelectedFeatureImage
        key={image.id}
        src={image.file.thumb_sq.url}
      />
    ))}
  </div>
);

SelectedFeatureImages.propTypes = {
  images: PropTypes.arrayOf(PropTypes.any).isRequired,
};

const onSubmitError = (error) => {
  // eslint-disable-next-line no-console
  console.error(error);
  toastr.error('There was a problem changing the service');
};

const onSubmitSuccess = () => {
  toastr.success('Service saved!');
};

const BulkChangeServiceForm = ({
  errors,
  features,
  featureImages,
  service_id: serviceId,
  serviceOptions,
  task,
  onChange,
  onClose,
  onSaveSuccess,
  updateFeatures,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const serviceName = task.attributes.service_badge.label;

  const isSubmitDisabled = serviceId === null
    || task.relationships.service.data.id === serviceId
    || featureImages.length === 0;

  const onSubmit = async () => {
    try {
      setIsLoading(true);

      const data = features.map(feature => ({
        ...feature,
        attributes: {
          service_id: serviceId,
          bulk_move: true,
        },
      }));
      const { error } = await updateFeatures(data);

      if (error) {
        onSubmitError(error);
      } else {
        if (onSaveSuccess) onSaveSuccess();
        onSubmitSuccess();
        onClose();
      }
    } catch (err) {
      onSubmitError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form
      id="bulk-change-service-form"
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form id="bulk-change-service-form" onSubmit={handleSubmit}>
          <div className="modal-header">
            <div className="m-auto text-center">
              <h5 className="modal-title font-weight-bold">
                Update Services
              </h5>
            </div>
          </div>
          <div className="modal-body feature-form">
            <Alert
              type="info"
              iconClassName="fa fa-exclamation-circle"
              title="Bulk Move All Items To A New Service"
            >
              This Bulk Move option will transfer all of the items currently in
              {` the ${serviceName} Service to another Service of your `}
              choice. If you want to move all of these items to a new Service,
              please select the new Service below. If you want to move only some
              of these items to a new service, please move each item one at a
              time using the middle icon on each item card.
            </Alert>
            <div className="row">
              <div
                className="col"
                style={{
                  borderRight: '1px solid #dfe6e9',
                  paddingRight: '20px',
                }}
              >
                <h5 className="font-weight-bold mb-20">
                  Images to move to a new Service
                </h5>

                {featureImages.length > 0 ? (
                  <div className="form-group required">
                    <div>
                      <SelectedFeatureImages images={featureImages} />
                    </div>
                  </div>
                ) : null}
              </div>
              <ServiceAttributesForm
                bulk
                disabled={isSubmitDisabled}
                errors={errors}
                loading={isLoading}
                selectService
                service_id={serviceId}
                serviceOptions={serviceOptions}
                onChange={onChange}
                onClose={onClose}
              />
            </div>
          </div>
        </form>
      )}
    />
  );
};

BulkChangeServiceForm.propTypes = {
  errors: PropTypes.objectOf(PropTypes.any),
  features: PropTypes.arrayOf(PropTypes.any).isRequired,
  featureImages: PropTypes.arrayOf(PropTypes.any).isRequired,
  service_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  serviceOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  task: PropTypes.objectOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSaveSuccess: PropTypes.func,
  updateFeatures: PropTypes.func.isRequired,
};

BulkChangeServiceForm.defaultProps = {
  errors: {},
  onSaveSuccess: null,
  service_id: null,
};

export default BulkChangeServiceForm;
