import React, { Component } from 'react';
import PropTypes from 'prop-types';
import PrimarySelector from './PrimarySelector';
import UnitSelector from './UnitSelector';
import CommunitySelector from './CommunitySelector';
import GenericFilter from '../../TeamScheduleContainer/Filters/GenericFilter';
import { idType } from '../../../helpers/propTypes';
import PlanTypeSelector from './PlanTypeSelector';

class SearchSelector extends Component {
  static propTypes = {
    filter: PropTypes.string,
    unit: PropTypes.object,
    onUnitChange: PropTypes.func.isRequired,
    onCommunityChange: PropTypes.func.isRequired,
    communityOptions: PropTypes.array.isRequired,
    community: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    userId: idType,
    onUserChange: PropTypes.func,
    userOptions: PropTypes.array,
    serviceId: PropTypes.string,
    onServiceChange: PropTypes.func,
    serviceOptions: PropTypes.array,
  };

  static defaultProps = {
    unit: null,
    filter: null,
    community: null,
    userId: null,
    onUserChange: () => null,
    userOptions: [],
    serviceId: null,
    onServiceChange: () => null,
    serviceOptions: [],
  };

  onChange = (value) => {
    const { onChange } = this.props;

    onChange(value);
  };

  unitOptions = () => {
    const { community } = this.props;

    const options = [
      { label: 'All Units', id: null, value: null },
    ];

    return community ? [...options, ...community.options] : options;
  };

  render() {
    const {
      filter,
      unit,
      onUnitChange,
      community,
      communityOptions,
      onCommunityChange,
      userId,
      onUserChange,
      userOptions,
      planType,
      onPlanTypeChange,
    } = this.props;

    const userLabel = userOptions.find(m => m.id === userId)?.label ?? 'Team User';

    return (
      <div className="row d-flex flex-row ml-2">
        <PlanTypeSelector
          value={planType}
          onChange={onPlanTypeChange}
        />

        <span className="ml-2">
          <PrimarySelector
            filter={filter}
            onChange={this.onChange}
          />
        </span>

        {filter === 'View By Unit' ? (
          <>
            <CommunitySelector
              activeCommunity={community}
              communityOptions={communityOptions}
              setCommunity={onCommunityChange}
              title={(community && community.label) || 'All Communities'}
            />
            <UnitSelector
              activeUnit={unit}
              setUnit={onUnitChange}
              unitOptions={this.unitOptions()}
              visible
            />
          </>
        ) : null}

        {filter === 'View By Team Member' ? (
          <span className="ml-2">
            <GenericFilter
              title={userLabel}
              selected={userId}
              setSelected={onUserChange}
              options={userOptions}
            />
          </span>
        ) : null}
      </div>
    );
  }
}

export default SearchSelector;
