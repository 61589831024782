import React from 'react';
import Alert from '../../components/Alert';

const PropertiesAlert = () => (
  <Alert
    type="info"
    iconClassName="fa fa-exclamation-circle"
    title="Add or Edit Property Group"
  >
    A Property Group is a group of Units from your Address List. You may optionally add one or more
    units to a Property Group, and use this Property to create reports. You may add as many Property
    Groups as you need. To add a new Unit, click on the “Address List” sub-tab.
  </Alert>
);

export default PropertiesAlert;
