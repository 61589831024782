import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';
import ProjectsContainer from '../containers/ProjectsContainer/ProjectsContainer';

const Projects = props => (
  <Provider store={store}>
    <ProjectsContainer {...props} />
  </Provider>
);

export default Projects;
