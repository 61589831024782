import React from 'react';
import PropTypes from 'prop-types';

const ListGroupItem = ({ children, onClick, selected }) => (
  <button
    type="button"
    className={`list-group-item w-100 d-flex justify-content-between align-items-center btn btn-link text-left ${selected ? 'active' : null}`}
    onClick={onClick}
  >
    {children}
    {selected ? <i className="fa fa-chevron-right float-right m-l-5 mt-1" /> : null}
  </button>
);

ListGroupItem.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
  selected: PropTypes.bool,
};

ListGroupItem.defaultProps = {
  selected: false,
};

export default ListGroupItem;
