import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import toastr from 'toastr';
import {
  deleteRecord,
  getRecord,
  getRelationship,
  saveRecord,
} from 'redux-json-api-module';
import { Card, CardHeader } from '../../components/Card';
import Alert from '../../components/Alert';
import ActivateButton from './ActivateButton/ActivateButton';
import ApproveCompletionButton from './ApproveCompletionButton/ApproveCompletionButton';
import {
  includeData,
  setWorkOrder,
  hydrateWorkOrderManagementStore, fetchWorkOrder,
  setValidationWarning,
  fetchWorkOrderTasks,
  fetchTeamMembers, setWorkOrderTaskIds,
} from '../../redux/modules/workOrderManagement';
import {
  closeScheduler,
  closeForm,
  openScheduler,
  loadEvents,
} from '../../redux/modules/scheduler';
import Loader from '../../components/Loader';
import './WorkOrderManagementContainer.scss';
import { workOrderTaskSort } from './helpers';
import { setCurrentUser } from '../../redux/modules/auth';
import { teamObject } from '../../helpers/propTypes';
import { fetchMessengers } from '../../redux/modules/chat';
import StateAlert from '../ProjectManagementContainer/StateAlert';
import WorkOrderSummary from './WorkOrderSummary/WorkOrderSummary';
import WorkOrderTaskDetails from './WorkOrderTaskDetails';
import AttachmentManager from '../ProjectManagementContainer/AttachmentManager/AttachmentManager';
import PlanTimeline from '../../components/PlanTimeline/PlanTimeline';
import WorkOrderTaskCompletionReportDownload
  from './WorkOrderTaskCompletionReportDownload/WorkOrderTaskCompletionReportDownload';
import Scheduler from '../ProjectManagementContainer/Scheduler';
import MarkCompleteForm from '../ProjectManagementContainer/MarkCompleteForm/MarkCompleteForm';
import WorkOrderDetailsWrapper from './WorkOrderDetails/WorkOrderDetailsWrapper';
import NotificationsSent from './WorkOrderTaskCompletionReportDownload/NotificationsSent';
import { initializeActiveTask } from '../../redux/modules/navigation';
import FeatureFormButton from '../../components/FeatureFormButton';
import { pluralize } from '../../helpers/string';
import WorkOrderTaskAssignmentTable
  from './WorkOrderTaskAssignmentTable/WorkOrderTaskAssignmentTable';
import ServiceCreationTable
  from '../WorkOrderImportManagementContainer/ServiceCreationTable/ServiceCreationTable';

class WorkOrderManagementContainer extends Component {
  static propTypes = {
    workOrderId: PropTypes.number.isRequired,
    team: teamObject.isRequired,
    includeData: PropTypes.func.isRequired,
    deleteRecord: PropTypes.func.isRequired,
    saveRecord: PropTypes.func.isRequired,
    closeScheduler: PropTypes.func.isRequired,
    closeForm: PropTypes.func.isRequired,
    transitionedToActive: PropTypes.bool,
    currentUser: PropTypes.objectOf(PropTypes.any).isRequired,
    fetchWorkOrderTasks: PropTypes.func.isRequired,
    fetchTeamMembers: PropTypes.func.isRequired,
    fetchMessengers: PropTypes.func.isRequired,
    initializeActiveTask: PropTypes.func.isRequired,

    // connected
    hydrateWorkOrderManagementStore: PropTypes.func.isRequired,
  };

  static defaultProps = {
    transitionedToActive: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      featureFormVisible: false,
    };

    this.submitScheduler = this.submitScheduler.bind(this);
    this.createFeature = this.createFeature.bind(this);
    this.openFeatureForm = this.openFeatureForm.bind(this);
    this.closeFeatureForm = this.closeFeatureForm.bind(this);
  }

  componentDidMount() {
    const {
      hydrateWorkOrderManagementStore, fetchWorkOrder, workOrderId, areaItems,
      descriptionOptions, serviceOptions, conditionOptions, setCurrentUser, currentUser,
      team, serviceObjectOptions, fetchTeamMembers, fetchWorkOrderTasks, fetchMessengers,
      initializeActiveTask, areaOptions,
    } = this.props;

    hydrateWorkOrderManagementStore({
      workOrderId,
      areaItems,
      descriptionOptions,
      serviceOptions,
      serviceObjectOptions,
      conditionOptions,
      areaOptions,
    });

    fetchTeamMembers(team.data.id);
    setCurrentUser(currentUser.data);

    fetchWorkOrder(workOrderId)
      .then(() => (
        fetchWorkOrderTasks(workOrderId)
      ))
      .then(() => (
        initializeActiveTask('work_orders', workOrderId)
      ))
      .then(() => (
        fetchMessengers('work_orders', workOrderId)
      ));
  }

  componentWillReceiveProps(nextProps) {
    const { workOrderId } = this.props;

    if (workOrderId !== nextProps.workOrderId) {
      setWorkOrder(nextProps.workOrderId);
    }
  }

  getSummaryTitle = () => {
    const { workOrder } = this.props;
    if (!workOrder) return null;

    const text = workOrder.attributes.aasm_state === 'completed' ? 'Completed in' : 'In Progress for';

    return `Services Summary - ${text} `
      + `${workOrder.attributes.duration} ${pluralize('Day', workOrder.attributes.duration)}`;
  };

  async submitScheduler(workOrderTask) {
    const {
      saveRecord,
      closeForm,
      closeScheduler,
      loadEvents,
      workOrderTasks,
      openScheduler,
      workOrder,
      workOrderId,
      fetchWorkOrderTasks,
    } = this.props;

    const resp = await saveRecord(workOrderTask, { params: { window_id: window.WINDOW_ID } });

    if (resp.error) {
      toastr.error('The Work Order Task could not be updated.');
      return undefined;
    }

    await fetchWorkOrderTasks(workOrderId);

    const newWorkOrderTask = resp.payload.data.data;

    closeForm();

    const nextWorkOrderTask = workOrderTasks.find(t => (
      t.id !== workOrderTask.id && t.attributes.started_at === null
    ));

    if (nextWorkOrderTask) {
      toastr.info(`Please assign the next service, ${nextWorkOrderTask.attributes.service_name}`);

      if (nextWorkOrderTask.relationships.user.data === null) {
        nextWorkOrderTask.relationships.user = newWorkOrderTask.relationships.user;
        nextWorkOrderTask.attributes.user_id = newWorkOrderTask.attributes.user_id;
      }

      return openScheduler(nextWorkOrderTask);
    } else if (workOrder.attributes.aasm_state === 'assign') {
      toastr.info('All services have been assigned.  You can now activate the work order.');
      return closeScheduler();
    } else {
      toastr.success('Schedule Updated.');
      return loadEvents(false);
    }
  }

  createFeature = (attributes) => {
    const { workOrderId, saveRecord, setWorkOrderTaskIds } = this.props;
    const record = {
      id: null,
      type: 'work_order_features',
      attributes: {
        ...attributes,
        work_order_id: workOrderId,
      },
    };

    return saveRecord(record, {
      params: {
        include: 'work_order,work_order_task',
        window_id: window.WINDOW_ID,
      },
    })
      .then((resp) => {
        if (resp.error) return resp;

        const newWo = resp.payload.data.included.find(i => i.type === 'work_orders');

        return setWorkOrderTaskIds(
          newWo.relationships.work_order_tasks.data.map(ps => ps.id),
        );
      });
  };

  openFeatureForm = () => this.setState({ featureFormVisible: true });

  closeFeatureForm = () => this.setState({ featureFormVisible: false });


  render() {
    const {
      workOrder, workOrderTasks, users, includeData, deleteRecord, transitionedToActive,
      currentUser, workOrderId, workOrderTaskIds, validationWarning, setValidationWarning,
      team, areaOptions, areaItems, descriptionOptions, serviceOptions,
    } = this.props;
    const { featureFormVisible } = this.state;

    return workOrder ? (
      <Fragment>
        <div className="animated fadeIn">
          <StateAlert
            transitionedToActive={transitionedToActive}
            aasmState={workOrder.attributes.aasm_state}
            title="Work Order"
          />

          {validationWarning ? (
            <Alert
              type="warning"
              iconClassName="fa fa-lightbulb-o"
              onDismiss={() => setValidationWarning(null)}
            >
              {validationWarning}
            </Alert>
          ) : null}

          <WorkOrderSummary workOrder={workOrder} />

          <WorkOrderDetailsWrapper workOrder={workOrder} />

          {workOrder.attributes.aasm_state === 'assign' ? (
            <Alert
              type="danger"
              iconClassName="fa fa-exclamation-circle"
              title="Please Assign and Schedule Services, and then Activate the Work Order"
              expandedChildren={[
                <Alert
                  key="Scheduling Services One-At-A-Time"
                  type="warning"
                  iconClassName="fa fa-lightbulb-o"
                  title="Scheduling Services One-At-A-Time"
                >
                  If each Service has non-overlapping work dates, then each Service will be allowed
                  to complete work before the next Service is activated, avoiding confusion and
                  rescheduling. For example, if Painting needs to be completed before Janitorial
                  starts, then Janitorial will be activated after Painting is completed.
                </Alert>,
                <Alert
                  key="Scheduling Overlapping Services"
                  type="warning"
                  iconClassName="fa fa-lightbulb-o"
                  title="Scheduling Overlapping Services"
                >
                  If any Services have overlapping work dates, their assigned maintenance providers
                  will be able to work simultaneously. For example, if Carpet Cleaning and Grout
                  Cleaning can work around each other, you may overlap their work dates and both
                  will
                  be allowed to begin work together.
                </Alert>,
              ]}
            >
              Please assign a maintenance provider for each Service, and schedule
              their work date(s). Each Service is in the order you previously
              specified, and you may schedule any Service for any available date. All Services
              will automatically sort based on their status and work dates, so if you need to
              move one Service above or below another, simply change the work dates.
            </Alert>
          ) : null}

          {workOrder.attributes.aasm_state === 'assign' ? (
            <ServiceCreationTable
              workOrderId={workOrderId}
              users={users}
            />
          ) : (
            <Card>
              <CardHeader title={this.getSummaryTitle()}>
                <FeatureFormButton
                  workOrderDescription={workOrder.attributes.description}
                  areas={areaOptions}
                  descriptions={descriptionOptions}
                  items={areaItems}
                  services={serviceOptions}
                  onSubmit={this.createFeature}
                  onHide={this.closeFeatureForm}
                  onShow={this.openFeatureForm}
                  visible={featureFormVisible}
                />
              </CardHeader>
              <WorkOrderTaskAssignmentTable
                workOrderId={workOrderId}
                users={users}
              />
            </Card>
          )}

          {workOrder.attributes.aasm_state === 'assign' ? (
            <div className="m-b-15 text-center">
              <ActivateButton workOrder={workOrder} />
            </div>
          ) : null}

          {workOrder.attributes.aasm_state === 'review' ? (
            <div className="m-b-15 text-center">
              <ApproveCompletionButton workOrder={workOrder} />
            </div>
          ) : null}

          {workOrder.attributes.aasm_state !== 'assign' ? (
            <Fragment>
              <Card id="service-details">
                <CardHeader title="Service Details" />
                <WorkOrderTaskDetails
                  planId={workOrderId}
                  userId={currentUser.data.id}
                  team={team}
                />
              </Card>
              <Card>
                <CardHeader title="Attachments" />
                <AttachmentManager
                  showUser
                  id={workOrderId}
                  type="attachments"
                  parentRelationship="plan_id"
                  planClass="WorkOrder"
                  uploadEndpoint="/api/v1/attachments"
                  onDelete={deleteRecord}
                  taskIds={workOrderTaskIds}
                  plan={workOrder}
                  taskType="work_order_tasks"
                  onCreate={(data) => {
                    includeData(data);
                  }}
                />
              </Card>

              <div className="row">
                <div className="col-sm-12 col-md-6">
                  <Card>
                    <CardHeader title="Activity" />
                    <PlanTimeline planType="work_orders" planId={workOrderId} />
                  </Card>
                </div>

                <div className="col-sm-12 col-md-6">
                  <Card>
                    <CardHeader title="Service Completion Reports" />

                    <WorkOrderTaskCompletionReportDownload
                      planId={workOrderId}
                      tasks={workOrderTasks}
                      currentUser={currentUser}
                    />

                    <NotificationsSent workOrderId={workOrderId} />
                  </Card>
                </div>
              </div>

            </Fragment>
          ) : null}

        </div>
        <Scheduler
          onSubmit={this.submitScheduler}
          planType="work_orders"
          scheduledType="work_order_calendar_events"
        />
        <MarkCompleteForm plan={workOrder} />
      </Fragment>
    ) : <Loader />;
  }
}

const mapStateToProps = (state, ownProps) => {
  const workOrder = getRecord(state.Api, {
    type: 'work_orders',
    id: ownProps.workOrderId,
  });

  const workOrderTasks = (state.WorkOrderManagement.workOrderTaskIds || [])
    .map(id => getRecord(state.Api, { type: 'work_order_tasks', id }))
    .filter(t => t);

  workOrderTasks.sort(workOrderTaskSort);

  let inspectionImages = [];
  if (workOrder) {
    const featureRelationship = getRelationship(state.Api, workOrder.relationships.features);

    inspectionImages = featureRelationship ? (
      featureRelationship.map(feature => (
        feature.attributes.feature_images
      ))
        .flat()
    ) : [];
  }

  return {
    scheduler: state.WorkOrderManagement.scheduler,
    transitionedToActive: state.WorkOrderManagement.transitionedToActive,
    workOrder,
    workOrderTasks,
    workOrderTaskIds: workOrderTasks.map(t => t.id),
    inspectionImages,
    validationWarning: state.WorkOrderManagement.validationWarning,
  };
};

const mapDispatchToProps = {
  closeForm,
  closeScheduler,
  deleteRecord,
  fetchWorkOrder,
  hydrateWorkOrderManagementStore,
  includeData,
  loadEvents,
  openScheduler,
  saveRecord,
  setCurrentUser,
  setValidationWarning,
  fetchWorkOrderTasks,
  fetchTeamMembers,
  initializeActiveTask,
  fetchMessengers,
  setWorkOrderTaskIds,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorkOrderManagementContainer);
