import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getRecord } from 'redux-json-api-module';
import get from 'lodash.get';
import Badge from '../../components/Badge';
import COLORS from '../../helpers/colors';

const PlanLabel = ({
  unitShortAddress,
  status,
  planLink,
}) => (
  <div style={{ width: '20%', whiteSpace: 'nowrap' }}>
    <div className="d-inline-block mr-2">
      {unitShortAddress}
    </div>
    <Badge {...status} />
    <a
      href={planLink}
      className="ml-1"
      target="_blank"
      style={{
        border: `1px solid ${COLORS.blue}`,
        color: COLORS.blue,
        borderRadius: 5,
        padding: '0.4em',
        fontSize: '75%',
      }}
    >
      View Project
    </a>
  </div>
);

PlanLabel.propTypes = {
  // connected
  status: PropTypes.object.isRequired,
  unitShortAddress: PropTypes.string.isRequired,
  planLink: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  const messageThread = getRecord(state.Api, {
    type: 'message_threads',
    id: state.Chat.activeMessageThreadId,
  });
  const unitShortAddress = get(messageThread, 'attributes.unit_short_address', null);
  const status = get(messageThread, 'attributes.plan_status', null);

  return {
    unitShortAddress,
    status,
    planLink: `/${messageThread.relationships.subject.data.type}/${messageThread.relationships.subject.data.id}`,
  };
};

export default connect(mapStateToProps)(PlanLabel);
