import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ModalCarousel from '../../../components/ModalCarousel/ModalCarousel';
import LazyProgressiveImage from '../../../components/LazyProgressiveImage';

class ImageContainer extends Component {
  state = {
    modalOpen: false,
    selectedImage: 0,
  };

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ modalOpen: false });
  };

  openModal = (e, index) => {
    e.preventDefault();
    this.setState({
      modalOpen: true,
      selectedImage: index,
    });
  };

  render() {
    const { images, event } = this.props;
    const { modalOpen, selectedImage } = this.state;

    const imageColumns = images.slice(0, 3)
      .map((image, index) => (
        <div className="col-3 m-t-10 m-b-10" key={`${image.url}-${index}`}>
          <a href="#" onClick={e => this.openModal(e, index)}>
            <LazyProgressiveImage
              sourceUrl={image.thumb_sq.url}
              placeholderUrl={image.thumb_placeholder_sq.url}
              imageClassName="mw-100"
            />
          </a>
        </div>
      ));

    const showMore = images.length > 3
      && (
        <div className="col-3 align-self-center text-center">
          <h5 className="timeline-title">
            {`+ ${images.length - 3} More`}
          </h5>
        </div>
      );

    return (
      <div className="row">
        {imageColumns}
        {showMore}
        <ModalCarousel
          images={images}
          projectServiceId={event.relationships.target.data.id}
          userId={event.relationships.user.data.id}
          onClose={this.closeModal}
          visible={modalOpen}
          startIndex={selectedImage}
        />
      </div>
    );
  }
}

ImageContainer.propTypes = {
  event: PropTypes.object.isRequired,
  images: PropTypes.arrayOf(PropTypes.shape({
    url: PropTypes.string.isRequired,
    thumb_sq: PropTypes.shape({
      url: PropTypes.string.isRequired,
    }),
  })).isRequired,
};

const mapStateToProps = (state, props) => ({});

export default connect(mapStateToProps)(ImageContainer);
