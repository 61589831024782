/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import { firstBy } from 'thenby';
import Select from '../../../components/Inputs/Select';
import CustomInputSelect from '../../../components/Inputs/CustomInputSelect';
import CustomInputSelectWithId from '../../../components/Inputs/CustomInputSelectWithId';

function itemOptions(areaItems, area) {
  if (!area) {
    return [];
  }
  if (area.includes('Bedroom')) {
    return areaItems['Bedroom :bed_count'];
  }
  if (area.includes('Bathroom')) {
    return areaItems['Bathroom :bath_count'];
  }
  if (area.includes('Restroom')) {
    return areaItems['Restroom :bath_count'];
  }
  return areaItems[area] || [];
}

const ServiceAttributesForm = ({
  bulk,

  errors,
  onChange,
  onClose,

  areaOptions,
  areaItems,
  descriptionOptions,
  serviceOptions,

  name,
  itemId,
  area,
  description,
  service_id: serviceId,

  loading,
  disabled,
  selectService,
}) => (
  <div className="col" style={{ paddingLeft: '20px' }}>
    <h5 className="font-weight-bold mb-20">Please Update the details for this item as needed</h5>

    {selectService
      ? (
        <div className={`form-group required ${errors.service_id && 'form-group-invalid'}`}>
          <label className="form-control-label required">
            * Please select a Service to complete this work
          </label>
          <Select
            name="feature[service_id]"
            errors={errors.service_id}
            value={serviceId ? serviceId.toString() : ''}
            includeBlank="Select Service"
            onChange={serviceId => onChange({ service_id: serviceId })}
            options={serviceOptions.sort(firstBy(a => a[0]))}
          />
        </div>
      ) : null}

    {!bulk ? (
      <>
        <div className={`form-group required ${errors.area && 'form-group-invalid'}`}>
          <label className="form-control-label required">
            Please select an Area/Room/Category
          </label>
          <Select
            name="feature[area]"
            errors={errors.area}
            value={area || ''}
            includeBlank="Select Area"
            onChange={area => onChange({ area })}
            options={areaOptions}
          />
        </div>

        <div className={`form-group required ${errors.name && 'form-group-invalid'}`}>
          <label className="form-control-label required">
            Please select an Item/Amenity
          </label>
          <CustomInputSelectWithId
            name="name"
            includeBlank="Select Item"
            errors={errors.name}
            value={itemId}
            options={itemOptions(areaItems, area)}
            onChange={onChange}
            idName="item_id"
            textValue={itemId ? '' : name}
            selectName="feature[name_select]"
          />
        </div>

        <div className={`form-group required mb-70 ${errors.description && 'form-group-invalid'}`}>
          <label className="form-control-label required">
            Please describe the work needed
          </label>
          <CustomInputSelect
            name="feature[description]"
            includeBlank="Select Description"
            errors={errors.description}
            value={description}
            options={descriptionOptions}
            onChange={description => onChange({ description })}
          />
        </div>
      </>
    ) : null}

    <div
      className="modal-footer bottom-row"
      style={{
        borderTop: 'none',
        justifyContent: 'flex-start',
      }}
    >
      {selectService ? (
        <div style={{
          position: 'absolute',
          top: '-10px',
          width: '100px',
        }}
        >
          * Required
        </div>
      ) : null}
    </div>

    <div
      className="modal-footer bottom-row"
      style={{
        borderTop: 'none',
        right: '16px',
      }}
    >
      <button
        type="button"
        className="btn btn-link"
        onClick={onClose}
        disabled={loading}
      >
        Cancel
      </button>
      <button
        type="submit"
        className={`btn btn-primary ${disabled || loading ? 'disabled' : ''}`}
        disabled={disabled || loading}
      >
        Save
      </button>
    </div>
  </div>
);

const unlessBulk = validator => (props, ...rest) => (
  props.bulk ? null : validator(props, ...rest)
);
ServiceAttributesForm.propTypes = {
  bulk: PropTypes.bool,
  serviceOptions: PropTypes.arrayOf(PropTypes.any).isRequired,
  areaItems: unlessBulk(PropTypes.objectOf(PropTypes.any).isRequired),
  areaOptions: unlessBulk(PropTypes.arrayOf(PropTypes.any).isRequired),
  descriptionOptions: unlessBulk(PropTypes.arrayOf(PropTypes.string).isRequired),
  errors: PropTypes.objectOf(PropTypes.any),
  selectService: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  area: PropTypes.string,
  name: PropTypes.string,
  itemId: PropTypes.string,
  description: PropTypes.string,
  service_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

ServiceAttributesForm.defaultProps = {
  bulk: false,
  selectService: false,
  areaItems: {},
  areaOptions: [],
  descriptionOptions: [],
  disabled: false,
  loading: false,
  errors: {},
  area: undefined,
  name: undefined,
  itemId: undefined,
  description: undefined,
  service_id: undefined,
};

export default ServiceAttributesForm;
