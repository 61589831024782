export const SET_CURRENT_USER = 'intimely/auth/SET_CURRENT_USER';
export const SET_CURRENT_TEAM = 'intimely/auth/SET_CURRENT_TEAM';

const INITIAL_STATE = {
  currentUser: null,
  currentTeam: null,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.currentUser,
      };

    case SET_CURRENT_TEAM:
      return {
        ...state,
        currentTeam: action.currentTeam,
      };

    default:
      return state;
  }
}

export function setCurrentUser(currentUser) {
  return {
    type: SET_CURRENT_USER,
    currentUser,
  };
}

export function setCurrentTeam(currentTeam) {
  return {
    type: SET_CURRENT_TEAM,
    currentTeam,
  };
}
