import React, { Component } from 'react';
import { connect } from 'react-redux';
import { saveRecord } from 'redux-json-api-module';
import PropTypes from 'prop-types';
import Select, { components, createFilter } from 'react-select';
import Badge from '../Badge';
import COLORS from '../../helpers/colors';
import { idType } from '../../helpers/propTypes';

const OPTIONS = [
  {
    label: 'Light',
    value: 'light',
    background_color: COLORS.yellow,
    text_color: COLORS.white,
  },
  {
    label: 'Medium',
    value: 'medium',
    background_color: COLORS.orange,
    text_color: COLORS.white,
  },
  {
    label: 'Heavy',
    value: 'heavy',
    background_color: COLORS.redDark,
    text_color: COLORS.white,
  },
  {
    label: 'Very Heavy',
    value: 'very_heavy',
    background_color: COLORS.redDark,
    text_color: COLORS.white,
  },
];

const ConditionOption = props => (
  <components.Option {...props}>
    <ConditionDisplay {...props.data} />
  </components.Option>
);

const ConditionDisplay = ({ label, background_color, text_color }) => (
  <div className="condition__option">
    <Badge label={label} background_color={background_color} text_color={text_color} />
  </div>
);

ConditionDisplay.propTypes = {
  label: PropTypes.string.isRequired,
};

const menuPortal = (base) => {
  const { zIndex, ...rest } = base;

  return {
    ...rest,
    zIndex: 9999,
  };
};

class ConditionSelector extends Component {
  static propTypes = {
    id: idType.isRequired,
    taskType: PropTypes.string.isRequired,
    saveRecord: PropTypes.func.isRequired,
    conditionBadge: PropTypes.object,
    condition: PropTypes.string,
  };

  static defaultProps = {
    conditionBadge: null,
    condition: null,
    menuPortalTarget: document.body,
  };

  handleChange = (condition) => {
    const { saveRecord, id, taskType } = this.props;
    const record = {
      type: taskType,
      id,
      attributes: {
        condition: condition.value,
      },
    };

    saveRecord(record, { params: { window_id: window.WINDOW_ID } });
  };

  render() {
    const {
      menuPortalTarget,
      placeholder,
      conditionBadge,
      condition,
    } = this.props;

    const value = conditionBadge
      ? [{
        value: condition,
        label: <ConditionDisplay {...conditionBadge} />,
      }]
      : null;

    return (
      <div className="condition-select-wrapper">
        <Select
          classNamePrefix="condition-select"
          className="condition-select"
          components={{ Option: ConditionOption }}
          isSearchable={false}
          menuPortalTarget={menuPortalTarget}
          onChange={this.handleChange}
          options={OPTIONS}
          placeholder={placeholder}
          styles={{ menuPortal }}
          value={value}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  saveRecord,
};

export default connect(null, mapDispatchToProps)(ConditionSelector);
