/* eslint-disable react/prop-types */
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Button } from 'react-bootstrap';
import dragIcon from '../../../assets/images/icon/sort.png';
import Badge from '../Badge';
import ConditionSelector from '../ConditionSelector/ConditionSelector';
import MaintenanceSelect from '../MaintenanceSelect';
import SchedulerButton from '../../containers/ProjectManagementContainer/Scheduler/SchedulerButton';
import TableCell from './TableCell';

const ServiceRow = (
  {
    taskId,
    taskType,
    serviceBadge,
    showCondition,
    condition,
    conditionBadge,
    user,
    userOptions,
    assignUser,
    assignable,
    planAasmState,
    serviceStatus,
    aasmState,
    scheduleString,
    formattedCompletedAt,
    progress,
    serviceId,
    openMarkComplete,
    showConditionCell,
    completedAt,
    startedAt,
    lateBadge,
    partsBadge,
    deleteTask,
    showDelete,
    index,
  },
) => {
  const pending = planAasmState === 'active' && aasmState === 'pending';
  const active = (planAasmState === 'active' && aasmState === 'active')
    || (aasmState === 'completed' && !completedAt);
  const completed = aasmState === 'completed' && completedAt;
  const isDragDisabled = startedAt !== null;

  return (
    <Draggable
      draggableId={`project-service-${taskId}`}
      index={index}
      isDragDisabled={isDragDisabled}
      disableInteractiveElementBlocking
    >
      {({ innerRef: draggableRef, draggableProps, dragHandleProps }, { isDragging }) => (
        <tr
          ref={draggableRef}
          {...draggableProps}
          id={`serviceRow-${taskId}`}
          className={`v-middle ${isDragging ? 'table-active' : ''}`}
          style={{
            ...draggableProps.style,
            // workaround no locking support
            ...(isDragDisabled ? { transform: 'none' } : {}),
          }}
        >
          <TableCell>
            <h4 className="m-0">
              <Badge {...serviceBadge} />
            </h4>
          </TableCell>
          {showConditionCell ? (
            <TableCell>
              {Boolean(showCondition) && (
                aasmState === 'completed' ? (
                  <Badge {...conditionBadge} />
                ) : (
                  <ConditionSelector
                    condition={condition}
                    conditionBadge={conditionBadge}
                    id={taskId}
                    taskType={taskType}
                  />
                )
              )}
            </TableCell>
          ) : null}
          <TableCell style={{ padding: '.25em 1em' }}>
            <MaintenanceSelect
              user={user}
              serviceId={serviceId}
              options={userOptions || []}
              onChange={userId => assignUser(userId, taskType, taskId)}
              disabled={!assignable}
              key={`maintenance-select-${taskId}`}
            />
          </TableCell>
          {['active', 'completed', 'review'].includes(planAasmState) && (
            <TableCell>
              <Badge {...serviceStatus} />
              {lateBadge ? <Badge {...lateBadge} className="ml-1" /> : null}
              {partsBadge ? <Badge {...partsBadge} className="ml-1" /> : null}
            </TableCell>
          )}
          {taskType === 'project_services' && (
            <TableCell style={{ padding: '.25em' }}>
              <img
                {...dragHandleProps}
                src={dragIcon}
                alt="Drag Icon"
                className={`${isDragDisabled ? 'd-none' : ''}`}
                width="50"
              />
            </TableCell>
          )}
          <TableCell>
            {aasmState === 'completed' ? (
              scheduleString
            ) : (
              <SchedulerButton id={taskId} taskType={taskType} />
            )}
          </TableCell>
          <TableCell>
            {(showDelete || !startedAt) ? (
              <Button
                variant="danger"
                className="ml-1 border-0"
                onClick={() => {
                  // eslint-disable-next-line no-alert
                  if (window.confirm('Are you sure?')) {
                    deleteTask(taskId);
                  }
                }}
              >
                Delete
              </Button>
            ) : null}

            {pending ? `${Math.round(progress)}%` : null}

            {active && !showDelete ? (
              <Button
                variant="light"
                id={`markCompleteBtn${taskId}`}
                onClick={() => openMarkComplete(taskType, taskId)}
                disabled={!user}
              >
                <i className="fa fa-pencil mr-2" />
                {`${Math.round(progress)}%`}
              </Button>
            ) : null}

            {completed ? formattedCompletedAt : null}
          </TableCell>
        </tr>
      )}
    </Draggable>
  );
};

export default ServiceRow;
