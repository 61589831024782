import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { IsDraggingContext } from './IsDraggingContext';
import { reorder } from './helpers';
import './ServiceAssignmentTable.scss';
import Loader from '../Loader';

const ServiceAssignmentTable = ({
  loaded,
  aasmState,
  sortedTaskIds,
  sortedTaskSorts,
  startedCount,
  taskType,
  ServiceRowComponent,
  TableHeaderComponent,
  planId,
  updateTasks,
}) => {
  const [taskIds, setTaskIds] = useState(sortedTaskIds);
  const [isDragging, setIsDragging] = useState(false);
  const onBeforeDragStart = () => {
    setIsDragging(true);
  };
  const onDragEnd = (result) => {
    setIsDragging(false);

    if ((updateTasks ?? sortedTaskSorts ?? startedCount) === undefined) {
      return;
    }

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const dstIndex = Math.max(result.destination.index, startedCount);
    const srcIndex = result.source.index;
    if (dstIndex === srcIndex) {
      return;
    }

    const newTaskIds = reorder(taskIds, srcIndex, dstIndex);
    setTaskIds(newTaskIds);
    updateTasks(newTaskIds.map((id, i) => ({ id, sort: sortedTaskSorts[i] })));
  };

  useEffect(() => {
    setTaskIds([...sortedTaskIds]);
  }, [sortedTaskIds.join(',')]);

  return (
    <div className="table-responsive">
      {loaded
        ? (
          <table className="table service-assignment-table">
            <thead>
              <TableHeaderComponent aasmState={aasmState} />
            </thead>

            <IsDraggingContext.Provider value={isDragging}>
              <DragDropContext
                onBeforeDragStart={onBeforeDragStart}
                onDragEnd={onDragEnd}
              >
                <Droppable droppableId="tasks">
                  {({ innerRef: droppableRef, droppableProps, placeholder }) => (
                    <tbody
                      ref={droppableRef}
                      {...droppableProps}
                    >
                      {taskIds.map((taskId, index) => (
                        <ServiceRowComponent
                          key={taskId}
                          taskId={taskId}
                          taskType={taskType}
                          canEdit={aasmState !== 'completed'}
                          planAasmState={aasmState}
                          planId={planId}
                          index={index}
                          includeDelete
                        />
                      ))}
                      {placeholder}
                    </tbody>
                  )}
                </Droppable>
              </DragDropContext>
            </IsDraggingContext.Provider>
          </table>
        ) : (
          <div style={{ height: '500px' }}>
            <Loader />
          </div>
        )}
    </div>
  );
};

ServiceAssignmentTable.propTypes = {
  sortedTaskIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  sortedTaskSorts: PropTypes.arrayOf(PropTypes.number.isRequired),
  startedCount: PropTypes.number,
  taskType: PropTypes.string.isRequired,
  aasmState: PropTypes.string.isRequired,
  loaded: PropTypes.bool.isRequired,
  ServiceRowComponent: PropTypes.elementType.isRequired,
  TableHeaderComponent: PropTypes.elementType.isRequired,
  planId: PropTypes.number.isRequired,
  updateTasks: PropTypes.func,
};

ServiceAssignmentTable.defaultProps = {
  sortedTaskSorts: undefined,
  startedCount: undefined,
  updateTasks: undefined,
};

export default ServiceAssignmentTable;
