import { taskSort } from './sorts';
import ProjectServiceForm from '../containers/ProjectManagementContainer/ServiceForm/ProjectServiceForm';
import WorkOrderTaskForm from '../containers/WorkOrderManagementContainer/ServiceForm/WorkOrderTaskForm';

export const TYPE_MAP = {
  features: 'Feature',
  work_order_features: 'WorkOrderFeature',
  project_services: 'ProjectService',
  projects: 'Project',
  work_orders: 'WorkOrder',
  work_order_tasks: 'WorkOrderTask',
};

export const CHILD_TYPE_MAP = {
  projects: 'project_services',
  work_orders: 'work_order_tasks',
};

export const FEATURE_TYPE_MAP = {
  project_services: 'features',
  project_service_listings: 'features',
  work_order_tasks: 'work_order_features',
  work_order_task_listings: 'work_order_features',
};

export const PARENT_TYPE_MAP = {
  features: 'project_service',
  project_services: 'project',
  project_service_listings: 'project',
  work_order_features: 'work_order_task',
  work_order_tasks: 'work_order',
  work_order_task_listings: 'work_order',
  calendar_events: 'project',
  work_order_calendar_events: 'work_order',
};

export const TASK_SORTS = {
  project_services: taskSort,
  work_order_tasks: taskSort,
  projects: taskSort,
  work_orders: taskSort,
  calendar_events: taskSort,
  work_order_calendar_events: taskSort,
};

export const INVERSE_TYPE_MAP = {
  ProjectService: 'project_services',
  Project: 'projects',
  WorkOrder: 'work_orders',
  WorkOrderTask: 'work_order_tasks',
};

export const LISTING_TYPE_MAP = {
  projects: 'project_listings',
  work_orders: 'work_order_listings',
};

export const CHILD_LISTING_TYPE_MAP = {
  projects: 'project_service_listings',
  work_orders: 'work_order_task_listings',
  project_listings: 'project_service_listings',
  work_order_listings: 'work_order_task_listings',
};

export const RELATIONSHIP_MAP = {
  projects: 'project',
  work_orders: 'work_order',
  project_listings: 'project',
  work_order_listings: 'work_order',
  project_services: 'project_service',
  work_order_tasks: 'work_order_task',
  features: 'project',
  work_order_features: 'work_order',
};

export const NAME_MAP = {
  projects: 'projects',
  work_orders: 'work orders',
};

export const IMAGE_ATTRIBUTE_MAP = {
  project_services: 'project_service_images',
  work_order_tasks: 'task_images',
};

export const IMAGE_MAP = {
  project_services: 'project_service_images',
  work_order_tasks: 'work_order_task_images',
};

export const ID_MAP = {
  project_services: 'project_service_id',
  work_order_tasks: 'work_order_task_id',
};

export const CALENDAR_EVENT_TYPE_MAP = {
  projects: 'calendar_events',
  project_services: 'calendar_events',
  work_order_tasks: 'work_order_calendar_events',
  work_orders: 'work_order_calendar_events',
  calendar_events: 'calendar_events',
  work_order_calendar_events: 'work_order_calendar_events',
};

export const INVERSE_CALENDAR_EVENT_TYPE_MAP = {
  work_order_calendar_events: 'work_order_tasks',
  calendar_events: 'project_services',
  project_services: 'project_services',
  work_order_tasks: 'work_order_tasks',
};

export const CALENDAR_TITLE_MAP = {
  projects: 'Project',
  work_orders: 'Work Order',
};

export const MANAGEMENT_CONTAINER_MAP = {
  features: 'ProjectManagement',
  work_order_features: 'WorkOrderManagement',
};

export const SERVICE_FORM_MAP = {
  projects: ProjectServiceForm,
  work_orders: WorkOrderTaskForm,
};

export const TITLE_MAP = {
  projects: "Make Ready's",
  work_orders: 'Work Orders',
  all: 'View All',
};
