import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { idType } from '../../../helpers/propTypes';
import TaskCheckbox from './TaskCheckbox';
import TaskPendingImagesWarning from './TaskPendingImagesWarning';
import { getSelectedValues } from './helpers';
import { CHILD_TYPE_MAP } from '../../../helpers/types';
import { idComparison } from '../../../helpers/string';
import Alert from '../../../components/Alert';

class SelectReportsForm extends Component {
  static propTypes = {
    planType: PropTypes.string.isRequired,
    planId: idType.isRequired,
    onSubmit: PropTypes.func.isRequired,
    tasks: PropTypes.array.isRequired,
    inspectionId: idType,
  };

  static defaultProps = {
    inspectionId: null,
  };

  handleSubmit = (selected) => {
    const { onSubmit } = this.props;
    onSubmit({ selectedIds: getSelectedValues(selected) });
  };

  isDisabled = selected => (
    getSelectedValues(selected).length === 0
  );

  isWarningVisible = selected => (
    getSelectedValues(selected).length > 1
  )

  getCompletedTasks = () => {
    const { tasks } = this.props;
    return tasks.filter(t => (
      t.attributes.aasm_state === 'completed'
    ))
      .sort((a, b) => (b.attributes.all_images_uploaded - a.attributes.all_images_uploaded));
  };

  getFields = () => (
    this.getCompletedTasks()
      .map(t => (
        t.attributes.all_images_uploaded
          ? <TaskCheckbox key={t.id} task={t} />
          : <TaskPendingImagesWarning key={t.id} task={t} />
      ))
  );

  downloadReports = (values) => {
    const { inspectionId, planType, planId } = this.props;
    const selectedIds = getSelectedValues(values);

    selectedIds.forEach((id) => {
      const url = idComparison(id, inspectionId)
        ? `/${planType}/${planId}/inspection_report.pdf` : `/${CHILD_TYPE_MAP[planType]}/${id}/completion_report.pdf`;
      window.open(url, '_blank');
      window.focus();
    });
  };

  render() {
    if (this.getCompletedTasks().length === 0) return null;

    return (
      <div className="row">
        <div className="col">
          <Form
            onSubmit={this.handleSubmit}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                {this.getFields()}

                <div className="form-group" style={{ marginTop: '10px' }}>
                  <button
                    type="button"
                    className="btn btn-success btn-fixed-size mr-2"
                    disabled={this.isDisabled(values)}
                    onClick={() => this.downloadReports(values)}
                  >
                    Download Selected Reports
                  </button>
                  <button
                    type="submit"
                    className={`btn btn-success btn-fixed-size ${this.isDisabled(values) ? 'disabled-btn' : ''}`}
                    disabled={this.isDisabled(values)}
                  >
                    Email Selected Reports
                  </button>
                </div>
                {this.isWarningVisible(values) ? (
                  <Alert
                    type="info"
                    iconClassName="fa fa-exclamation-circle"
                    title="Allow Pop-Ups"
                  >
                    To download multiple reports, please allow pop-ups and redirects from
                    https://app.intimely.co
                  </Alert>
                ) : null}
              </form>
            )}
          />
        </div>
      </div>
    );
  }
}

export default SelectReportsForm;
