import { connect } from 'react-redux';
import { getRelationship, getRecord } from 'redux-json-api-module';
import ServiceDetails from '../../components/ServiceDetails';
import { taskSort } from '../../helpers/sorts';

const mapStateToProps = (state, ownProps) => {
  const workOrder = getRecord(state.Api, {
    type: 'work_orders',
    id: ownProps.planId,
  });

  const tasks = (workOrder
    ? getRelationship(state.Api, workOrder.relationships.work_order_tasks)
    : []);

  tasks.sort(taskSort);

  const completed = workOrder ? workOrder.attributes.aasm_state === 'completed' : false;

  return {
    messengersLoaded: state.Chat.messengersLoaded,
    tasks,
    activeTaskId: state.Navigation.activeTaskId,
    activeTaskType: state.Navigation.activeTaskType,
    completed,
    planType: 'work_orders',
    taskType: 'work_order_tasks',
    inspectionTitle: 'Work Order',
  };
};

export default connect(mapStateToProps)(ServiceDetails);
