import { connect } from 'react-redux';
import { getRecord } from 'redux-json-api-module';
import ActivateButton from '../../../components/ActivateButton';
import { canActivate } from '../helpers';
import { activateImportedWorkOrder } from '../../../redux/modules/workOrderManagement';

const mapStateToProps = (state, ownProps) => {
  const tasks = (state.WorkOrderManagement.workOrderTaskIds || [])
    .map(id => getRecord(state.Api, {
      type: 'work_order_tasks',
      id,
    }));

  return {
    active: canActivate(tasks),
    planId: ownProps.workOrder.id,
    title: 'Work Order',
  };
};

const mapDispatchToProps = {
  activate: activateImportedWorkOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivateButton);
