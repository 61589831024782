import moment from 'moment/moment';
import toastr from 'toastr';
import { getRecord, getRelationship, SAVE_RECORD } from 'redux-json-api-module';
import { fetchUserOptions } from './projectManagement';
import { PARENT_TYPE_MAP } from '../../helpers/types';

// TODO: merge into projectManagement duck

export const CLOSE_FORM = 'intimely/markComplete/CLOSE_FORM';
export const OPEN_FORM = 'intimely/markComplete/OPEN_FORM';
export const SET_USER_OPTIONS = 'intimely/markComplete/SET_USER_OPTIONS';
export const TOGGLE_LOADING = 'intimely/markComplete/TOGGLE_LOADING';

const INITIAL_STATE = {
  taskId: null,
  taskType: null,
  visible: false,
  initialValues: {
    date: moment()
      .format('YYYY-MM-DD'),
    userId: 0,
  },
  title: null,
  loading: false,
  userOptions: {},
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case CLOSE_FORM:
      return INITIAL_STATE;

    case OPEN_FORM:
      return {
        ...state,
        visible: true,
        taskId: action.taskId,
        taskType: action.taskType,
        initialValues: action.initialValues,
        title: action.title,
      };

    case SET_USER_OPTIONS:
      return {
        ...state,
        userOptions: action.userOptions,
      };

    case TOGGLE_LOADING:
      return {
        ...state,
        loading: action.loading,
      };

    default:
      return state;
  }
}

export function getTask(state) {
  if (!state.MarkComplete.taskType || !state.MarkComplete.taskId) return null;
  return state.Api[state.MarkComplete.taskType][state.MarkComplete.taskId];
}

export function openMarkComplete(taskType, taskId) {
  return (dispatch, getState) => {
    const { Api } = getState();

    const task = getRecord(Api, {
      type: taskType,
      id: taskId,
    });
    const plan = getRelationship(Api, task.relationships[PARENT_TYPE_MAP[taskType]]);
    const service = getRelationship(Api, task.relationships.service);
    const unit = getRelationship(Api, plan.relationships.unit);

    dispatch(fetchUserOptions(service.id))
      .then((resp) => {
        if (resp.error) {
          toastr.error('Error loading user options. Please close the form and try again');
          return;
        }

        dispatch({
          type: SET_USER_OPTIONS,
          userOptions: resp.payload.data,
        });
      });

    return unit && plan && service ? dispatch({
      type: OPEN_FORM,
      taskId,
      taskType,
      title: ` - ${unit.attributes.short_address}`,
      initialValues: {
        date: moment()
          .format('YYYY-MM-DD'),
        userId: task.relationships.user.data.id,
      },
    }) : Promise.resolve({});
  };
}

export function closeMarkComplete() {
  return { type: CLOSE_FORM };
}

export function toggleLoading(loading) {
  return {
    type: TOGGLE_LOADING,
    loading,
  };
}

const INCLUDED = {
  project_services: 'features,project,project.project_services',
  work_order_tasks: 'work_order_features,work_order,work_order.work_order_tasks',
};

export function submit(values) {
  return (dispatch, getState) => {
    if (!values.userId || !values.date) return Promise.resolve({});

    const task = getTask(getState());

    dispatch(toggleLoading(true));

    return dispatch({
      type: SAVE_RECORD,
      payload: {
        request: {
          method: 'PATCH',
          url: `/${task.type}/${task.id}/complete?include=${INCLUDED[task.type]}`,
          data: {
            data: {
              attributes: {
                completed_at: moment(values.date)
                  .toDate(),
                user_id: values.userId,
                service_notes: values.serviceNotes,
              },
            },
          },
        },
      },
    })
      .then((resp) => {
        if (resp.error) {
          toastr.error('The Task could not be updated.');
          return resp;
        }
        toastr.success('Task completed.');
        return dispatch(closeMarkComplete());
      })
      .finally(() => (
        dispatch(toggleLoading(false))
      ));
  };
}
