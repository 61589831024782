import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord, getRelationship } from 'redux-json-api-module';
import { genericRecordPropType, idPropType } from '../../../helpers/propTypes';
import { PARENT_TYPE_MAP } from '../../../helpers/types';

const AddressCell = ({ task, unit }) => (
  <a href={`/${task.relationships[PARENT_TYPE_MAP[task.type]].data.type}/${task.relationships[PARENT_TYPE_MAP[task.type]].data.id}`}>
    {unit.attributes.street_address}
    <br />
    {unit.attributes.unit_number}
  </a>
);

AddressCell.propTypes = {
  taskId: idPropType.isRequired,
  taskType: PropTypes.string.isRequired,

  // connected
  task: genericRecordPropType.isRequired,
  unit: genericRecordPropType.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const task = getRecord(state.Api, {
    type: ownProps.taskType,
    id: ownProps.taskId,
  });

  const unit = getRelationship(state.Api, task.relationships.unit);

  return {
    task,
    unit,
  };
};

export default connect(
  mapStateToProps,
)(AddressCell);
