import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord, getRelationship } from 'redux-json-api-module';
import PendingWork from '../../components/PendingWork';
import { getKeysReceivedOn } from '../../components/Summary/helpers';
import Badge from '../../components/Badge';
import ClampedText from './ClampedText';
import COLORS from '../../helpers/colors';
import TeamCell from './TeamCell';

const WorkOrderRow = ({ plan, unit, isLast }) => (
  <tr className="v-middle work-order-row">
    <td>
      <a href={`/work_orders/${plan.id}`}>
        <div>
          {unit.attributes.street_address}
          <br />
          {unit.attributes.unit_number}
        </div>
      </a>
    </td>
    <td>{plan.attributes.work_order_number}</td>
    <td>{getKeysReceivedOn(plan)}</td>
    <TeamCell plan={plan} />
    <PendingWork plan={plan} />
    <td>
      <ClampedText
        text={plan.attributes.description || ''}
        placement={isLast ? 'top' : 'bottom'}
      />
    </td>
    <td>
      <Badge {...plan.attributes.status} />
      {plan.attributes.late_badge ? (
        <Badge {...plan.attributes.late_badge} className="ml-1" />
      ) : null}
      {plan.attributes.parts_badge ? (
        <Badge {...plan.attributes.parts_badge} className="ml-1" />
      ) : null}
    </td>
    <td>
      <Badge {...plan.attributes.priority_badge} />
    </td>
    <td style={plan.attributes.permission_to_enter ? {} : {
      color: COLORS.redDark,
      fontWeight: 'bold',
    }}
    >
      {plan.attributes.permission_to_enter ? 'Yes' : 'No'}
    </td>
  </tr>
);

const mapStateToProps = (state, ownProps) => {
  const plan = getRecord(state.Api, {
    type: 'work_order_listings',
    id: ownProps.planId,
  });
  const unit = getRelationship(state.Api, plan.relationships.unit);

  return {
    plan,
    unit,
  };
};

WorkOrderRow.propTypes = {
  plan: PropTypes.object.isRequired,
  unit: PropTypes.object.isRequired,
  isLast: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps)(WorkOrderRow);
