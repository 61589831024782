import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord, getRelationship } from 'redux-json-api-module';
import Badge from '../../../components/Badge';
import { genericRecordPropType, idPropType } from '../../../helpers/propTypes';

const ServiceCell = ({ service }) => (
  <Badge {...service.attributes.service_badge} />
);

ServiceCell.propTypes = {
  featureId: idPropType.isRequired,
  featureType: PropTypes.string.isRequired,

  // connected
  service: genericRecordPropType.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const feature = getRecord(state.Api, { id: ownProps.featureId, type: ownProps.featureType });

  return {
    service: getRelationship(state.Api, feature.relationships.service),
  };
};

export default connect(
  mapStateToProps,
)(ServiceCell);
