import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';
import SignupContainer from '../containers/SignupContainer/SignupContainer';

const Signup = props => (
  <Provider store={store}>
    <SignupContainer {...props} />
  </Provider>
);

export default Signup;
