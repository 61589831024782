import React from 'react';
import PropTypes from 'prop-types';
import ListGroupItem from '../ScheduleSelector/ListGroupItem';
import { options } from '../../../helpers/propTypes';
import Badge from '../../../components/Badge';

const UnitFilter = ({ title, activeUnit, setUnit, visible, unitOptions }) => (
  visible ? (
    <span className="ml-2">
      <div className="dropdown">
        <button
          className="btn btn-primary toggle-button"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {title}
          <i className="fa fa-chevron-down spaced-icon" />
        </button>
        <div className="dropdown-menu scrollable-menu" aria-labelledby="dropdownMenuButton">
          {unitOptions.map(unit => (
            <ListGroupItem
              selected={activeUnit && activeUnit.id === unit.id}
              onClick={() => setUnit(unit)}
              key={unit.id}
            >
              <div>
                {unit.label}
                {unit.badge ? <span className="ml-2"><Badge {...unit.badge} /></span> : null}
              </div>
            </ListGroupItem>
          ))}
        </div>
      </div>
    </span>
  ) : null
);

UnitFilter.propTypes = {
  title: PropTypes.string,
  activeUnit: PropTypes.object,
  setUnit: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  unitOptions: options,
};

UnitFilter.defaultProps = {
  activeUnit: null,
  unitOptions: [],
  title: null,
};

export default UnitFilter;
