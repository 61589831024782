import React from 'react';
import PropTypes from 'prop-types';
import Badge from '../Badge';

const aroundServiceTagRegex = /(<@.*?@>)/g;
const isServiceTag = text => text.startsWith('<@') && text.endsWith('@>');
const parseServiceName = serviceTag => serviceTag.slice(2, -2);
const findServiceBadge = (serviceBadges, serviceTag) => {
  const serviceName = parseServiceName(serviceTag);
  return serviceBadges.find(({ name }) => (name === serviceName));
};

const TitleSections = ({
  taggedTitle,
  serviceBadges,
}) => (
  <>
    {taggedTitle.split(aroundServiceTagRegex).map((text, index) => {
      let content = text;
      if (isServiceTag(text)) {
        const badge = findServiceBadge(serviceBadges, text);
        if (badge) {
          content = <Badge {...badge} />;
        }
      }

      return (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={index}>
          {content}
        </React.Fragment>
      );
    })}
  </>
);

TitleSections.propTypes = {
  taggedTitle: PropTypes.string.isRequired,
  serviceBadges: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)).isRequired,
};

export default TitleSections;
