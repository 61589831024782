import React from 'react';
import PropTypes from 'prop-types';
import SchedulerTitle from './SchedulerTitle';
import MaintenanceSelect from '../MaintenanceSelect';

const Title = ({
  scheduled,
  user,
  title,
  handleAssignment,
}) => (
  scheduled ? (
    <div className="d-flex scheduler-header">
      <div style={{ flex: 1 }}>
        <h4 className="modal-title font-weight-bold">
          <SchedulerTitle {...scheduled.attributes} />
        </h4>
      </div>
      {title}
      <div
        className="d-flex"
        style={{
          marginBottom: '20px',
          flex: 1,
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
        }}
      >
        <div className="d-flex" style={{ flexDirection: 'column' }}>
          <div className="font-weight-bold">
            Assign To: &nbsp;
          </div>
          <MaintenanceSelect
            user={user}
            scheduled={scheduled}
            onChange={handleAssignment}
            key={`maintenance-select-${scheduled.id}`}
          />
        </div>
      </div>
    </div>
  ) : null
);

Title.propTypes = {
  scheduled: PropTypes.object,
  user: PropTypes.object,
  title: PropTypes.string,
  handleAssignment: PropTypes.func.isRequired,
};

Title.defaultProps = {
  scheduled: null,
  user: null,
  title: null,
};

export default Title;
