import React from 'react';
import moment from 'moment';
import { Form, Field } from 'react-final-form';
import DateInput from './DateInput';

function changeKeysReceivedOn(value, change, onChange) {
  onChange(value);
  change('keys_received_on', value);
}

const KeysReceivedOnDatePicker = ({
  keysReceivedOn,
  aasmState,
  dueOn,
  onChange,
}) => {
  if (aasmState === 'pending_inspection') {
    return (
      <Form
        initialValues={{ keys_received_on: keysReceivedOn }}
        onSubmit={() => {
          // DO NOTHING
        }}
        render={({ form: { change } }) => (
          <Field name="keys_received_on">
            {({ input }) => {
              const newInput = { ...input };
              newInput.onChange = value => changeKeysReceivedOn(value, change, onChange);

              return (
                <DateInput
                  input={newInput}
                  maxDate={moment(dueOn)
                    .toDate()}
                />
              );
            }}
          </Field>
        )}
      />
    );
  }

  if (aasmState === 'completed') {
    return (
      moment(keysReceivedOn).format('MM/DD/YYYY')
    );
  }

  return (
    moment(keysReceivedOn).format('MM/DD')
  );
};

export default KeysReceivedOnDatePicker;
