import { fetchRecords, saveRecord } from 'redux-json-api-module';

export const FETCH_DESCRIPTIONS_SUCCESS = 'intimely/inspections/FETCH_DESCRIPTIONS_SUCCESS';
export const FETCH_AREAS_SUCCESS = 'intimely/inspections/FETCH_AREAS_SUCCESS';
export const FETCH_ITEMS_SUCCESS = 'intimely/inspections/FETCH_ITEMS_SUCCESS';
export const TOGGLE_EXPANDED_AREA = 'intimely/inspections/TOGGLE_EXPANDED_AREA';
export const TOGGLE_NEW_AREA = 'intimely/inspections/TOGGLE_NEW_AREA';

const INITIAL_STATE = {
  descriptionIds: [],
  areaIds: [],
  itemIds: [],
  expandedId: null,
  newArea: false,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_DESCRIPTIONS_SUCCESS:
      return {
        ...state,
        descriptionIds: action.descriptionIds,
      };

    case FETCH_AREAS_SUCCESS:
      return {
        ...state,
        areaIds: action.areaIds,
      };

    case FETCH_ITEMS_SUCCESS:
      return {
        ...state,
        itemIds: action.itemIds,
      };

    case TOGGLE_EXPANDED_AREA:
      return {
        ...state,
        expandedId: action.expandedId,
        newArea: false,
      };

    case TOGGLE_NEW_AREA:
      return {
        ...state,
        newArea: action.newArea,
        expandedId: null,
      };

    default:
      return state;
  }
}

export function fetchDescriptions() {
  return dispatch => Promise.all([
    dispatch(fetchRecords('descriptions',
      {
        include: 'service',
        page: { size: 250 },
      })),
    dispatch(fetchRecords('services')),
  ])
    .then((responses) => {
      if (responses.some(r => r.error)) return responses.find(r => r.error).error;

      const descriptionIds = responses[0].payload.data.data.map(description => description.id);

      return dispatch({
        type: FETCH_DESCRIPTIONS_SUCCESS,
        descriptionIds,
      });
    });
}

export function fetchAreas() {
  return dispatch => dispatch(fetchRecords('areas', {
    include: 'area_group,area_items,area_items.item',
    sort: 'sort',
    page: { size: 250 },
  }))
    .then((resp) => {
      if (resp.error) return resp;

      const areaIds = resp.payload.data.data.map(area => area.id);

      return dispatch({
        type: FETCH_AREAS_SUCCESS,
        areaIds,
      });
    });
}

export function fetchItems() {
  return dispatch => dispatch(fetchRecords('items', { page: { size: 250 } }))
    .then((resp) => {
      if (resp.error) return resp;

      const itemIds = resp.payload.data.data.map(item => item.id);

      return dispatch({
        type: FETCH_ITEMS_SUCCESS,
        itemIds,
      });
    });
}

export function toggleExpandedArea(expandedId) {
  return {
    type: TOGGLE_EXPANDED_AREA,
    expandedId,
  };
}


export function toggleNewArea(newArea) {
  return {
    type: TOGGLE_NEW_AREA,
    newArea,
  };
}

export function createArea(values) {
  return (dispatch, getState) => {
    const record = {
      type: 'areas',
      attributes: {
        sort: getState().Inspections.areaIds.length,
        name: values.name,
      },
    };

    return dispatch(saveRecord(record, { params: { window_id: window.WINDOW_ID } }))
      .then((resp) => {
        if (resp.error) return resp;

        return dispatch(fetchAreas());
      })
      .then(() => (
        dispatch(toggleNewArea(false))
      ));
  };
}

export const reorderAreas = areaIds => ({
  type: FETCH_AREAS_SUCCESS,
  areaIds,
});
