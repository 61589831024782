import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from '../../Loader';
import { idType } from '../../../helpers/propTypes';
import TaskBadgeHeader from '../TaskBadgeHeader';
import { fetchTask } from '../../../redux/modules/projectManagement';
import Invoice from './Invoice';

const InvoiceTab = ({ taskId, taskType, fetchTask }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchTask(taskType, taskId).finally(() => setLoading(false));
  }, []);

  if (loading) return <Loader />;

  return (
    <div>
      <TaskBadgeHeader taskId={taskId} taskType={taskType} />
      <h3 className="border-bottom pb-2 mb-3">Invoice</h3>
      <Invoice taskType={taskType} taskId={taskId} />
    </div>
  );
};

InvoiceTab.propTypes = {
  taskType: PropTypes.string.isRequired,
  taskId: idType.isRequired,
  fetchTask: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  fetchTask,
};

export default connect(
  null,
  mapDispatchToProps,
)(InvoiceTab);
