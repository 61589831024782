import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';
import { saveRecord, fetchRecord } from 'redux-json-api-module';
import GraphemeSplitter from 'grapheme-splitter';
import { taggableUserObject, idType } from '../../helpers/propTypes';
import Tagging from './generators/Tagging';
import Emoji from './generators/Emoji';
import EmojiAutoParser from './auto_parsers/EmojiAutoParser';
import { parseEmoji } from './helpers';
import { includeData } from '../../redux/modules/projectManagement';
import { getMessenger } from '../../redux/modules/chat';

const uuidv4 = require('uuid/v4');

const splitter = new GraphemeSplitter();

const AUTO_PARSERS = [
  EmojiAutoParser,
];

function getGenerator(typed, referenceGenerators) {
  const gen = referenceGenerators.find(generator => generator.type === (typed.type || typed));

  return gen;
}

function setup(text, taggableUsers, referenceGenerators) {
  let body = '';
  let characters = [];

  if (!text) {
    return {
      characters,
      body,
    };
  }
  let skip = 0;

  const textArray = splitter.splitGraphemes(text);

  textArray.forEach((character, index) => {
    let applied = false;
    referenceGenerators.forEach((generator) => {
      const {
        characters: newCharacters,
        body: newBody,
        skip: newSkip,
      } = generator.toText(textArray, body, index, { taggableUsers });
      if (newCharacters && !applied) {
        applied = true;
        body += newBody;
        characters = characters.concat(newCharacters);
        skip = newSkip;
      }
    });
    if (!applied && index >= skip) {
      body += character;
      characters.push({
        char: character,
        index: body.length,
      });
    }
  });

  return {
    body,
    characters,
  };
}

function getDeletion(text, previousText) {
  let deletion = null;

  if (text.length >= previousText.length) return deletion;

  const textArray = splitter.splitGraphemes(text);
  const previousTextArray = splitter.splitGraphemes(previousText);

  const length = previousTextArray.length - textArray.length;

  previousTextArray.forEach((c, index) => {
    if (c !== textArray[index] && !deletion) {
      deletion = {
        start: index,
        end: index + length,
        length,
      };
    }
  });

  return deletion;
}

function getReference(text, start, taggableUsers, referenceGenerators) {
  return referenceGenerators.map(generator => (
    generator.generateReference(text, start, { taggableUsers })
  ))
    .find(reference => reference);
}

function getAddition(text, previousText, taggableUsers, referenceGenerators) {
  let addition = null;

  if (text.length <= previousText.length) return addition;

  const textArray = splitter.splitGraphemes(text);
  const previousTextArray = splitter.splitGraphemes(previousText);
  const length = textArray.length - previousTextArray.length;

  textArray.forEach((c, index) => {
    if (previousTextArray[index] !== c && !addition) {
      const end = index + length;
      const newText = textArray.slice(index, end)
        .join('');
      const reference = getReference(newText, index, taggableUsers, referenceGenerators);
      addition = {
        type: reference ? reference.type : 'text',
        start: index,
        end,
        length,
        reference,
        text: newText,
      };
    }
  });

  return addition;
}

function updateReference(reference, taggableUsers, referenceGenerators) {
  let text = '';
  reference.characters.sort((a, b) => (a.index - b.index));
  reference.characters.forEach((character) => {
    text += character.char;
  });
  reference.text = text;
  const referenceGenerator = getGenerator(reference, referenceGenerators);
  if (referenceGenerator) referenceGenerator.updateReference(reference, text, { taggableUsers });
}

function concatenate(start, added, end, currentAction) {
  const all = start.concat(added)
    .concat(end);
  all.forEach((character, index) => {
    if (character.reference
      !== currentAction
      && (!character.reference || !character.reference.completed)) {
      character.reference = null;
    }
  });
  return all;
}

function mergeReference(previousCharacter, addition, referenceGenerators) {
  if (previousCharacter && previousCharacter.reference) {
    const referenceGenerator = getGenerator(previousCharacter.reference, referenceGenerators);
    if (referenceGenerator) {
      return referenceGenerator.mergeReference(previousCharacter, addition);
    }
  }

  return addition.reference;
}

function getText(characters, referenceGenerators) {
  let body = '';

  characters.forEach((character) => {
    const referenceGenerator = character.reference ? getGenerator(character.reference, referenceGenerators) : null;
    body += (referenceGenerator ? referenceGenerator.toEncodedText(character) : character.char);
  });

  return body;
}

function stringToCharacters(string, reference) {
  return splitter.splitGraphemes(string)
    .map((char) => {
      const character = {
        char,
        reference,
      };
      if (reference) reference.characters.push(character);
      return character;
    });
}

function getReferenceGenerators(canTag) {
  return canTag ? [
    Tagging,
    Emoji,
  ] : [
    Emoji,
  ];
}

const withTaggingForm = Form => class extends React.Component {
  static propTypes = {
    messageThreadId: PropTypes.string,
    taggableUsers: PropTypes.arrayOf(taggableUserObject).isRequired,
    saveRecord: PropTypes.func.isRequired,
    fetchRecord: PropTypes.func.isRequired,
    reset: PropTypes.func,
    body: PropTypes.string,
    messageId: PropTypes.string,
    messengerData: PropTypes.object,
    messengerId: idType,
    canTag: PropTypes.bool,
    includeData: PropTypes.func.isRequired,
  };

  static defaultProps = {
    messageThreadId: null,
    messageId: null,
    reset: null,
    canTag: true,
  };

  constructor(props) {
    super(props);
    this.referenceGenerators = getReferenceGenerators(props.canTag);

    const { characters, body } = setup(props.body, props.taggableUsers, this.referenceGenerators);

    this.state = {
      disabled: true,
      characters,
      currentAction: null,
      body,
    };
  }

  handleSubmit = () => {
    const { characters } = this.state;
    const {
      saveRecord,
      messageThreadId,
      messageId,
      reset,
      includeData,
      messengerData,
    } = this.props;

    this.setState({ disabled: true });

    let text = getText(characters, this.referenceGenerators);
    text = parseEmoji(text, text.length);

    const editedAt = messageId ? new Date() : null;
    const createdAt = Date.now();
    const tempId = uuidv4();

    const attributes = {
      id: messageId || tempId,
      body: text,
      message_thread_id: messageThreadId,
      edited_at: editedAt,
      created_at: createdAt,
      message_type: 'simple',
      messenger: messengerData,
    };

    const message = {
      type: 'messages',
      id: messageId,
      attributes,
    };

    const includedMessage = {
      type: 'messages',
      id: messageId || tempId,
      attributes: {
        written_at: createdAt,
        ...attributes,
      },
    };

    includeData({ data: includedMessage });
    this.setState({
      body: '',
      characters: [],
      currentAction: null,
    });

    return saveRecord(message, {
      params: {
        include: 'message_thread',
        window_id: window.WINDOW_ID,
      },
    }).then(() => (
      reset ? reset(messageId) : Promise.resolve()
    ));
  };

  setBody = (characters, currentAction) => {
    AUTO_PARSERS.forEach((parser) => {
      characters = parser.parse(characters);
    });
    characters.forEach((c, index) => c.index = index);
    this.setState({
      characters,
      body: characters.map(c => c.char)
        .join(''),
      disabled: characters.length === 0,
      currentAction,
    });
  };

  handleDeletion = (deletion) => {
    const { characters, currentAction } = this.state;
    const { taggableUsers } = this.props;

    let action = currentAction;
    let toDelete = [];

    for (let step = deletion.start; step < deletion.end; step++) {
      toDelete.push(step);
      const character = characters[step];
      if (!character) return;
      if (character.reference && character.reference.completed) {
        toDelete = toDelete.concat(character.reference.characters.map(c => c.index));
      } else if (character.reference) {
        character.reference.characters = character.reference.characters.filter(c => c !== character);
      }
    }

    const remaining = characters.filter((character, index) => !toDelete.includes(index));

    if (action && action.characters.length === 0) {
      action = null;
    } else if (action) {
      updateReference(action, taggableUsers, this.referenceGenerators);
    }

    this.setBody(remaining, action);
  };

  handleAddition = (addition) => {
    const { taggableUsers } = this.props;
    const { characters } = this.state;
    const start = characters.slice(0, addition.start);
    const end = characters.slice(addition.start);

    const previousCharacter = start[start.length - 1];
    const reference = mergeReference(previousCharacter, addition, this.referenceGenerators);

    const added = stringToCharacters(addition.text, reference);
    const all = concatenate(start, added, end, reference);
    all.forEach((c, index) => c.index = index);
    if (reference) updateReference(reference, taggableUsers, this.referenceGenerators);

    this.setBody(all, reference);
  };

  handleInputChange = (event) => {
    const { body: existingText } = this.state;
    const { taggableUsers } = this.props;

    const text = event.target.value;
    const deletion = getDeletion(text, existingText);

    if (deletion) {
      this.handleDeletion(deletion);
    } else {
      const addition = getAddition(text, existingText, taggableUsers, this.referenceGenerators);
      if (addition) this.handleAddition(addition);
    }
  };

  handleSelectUser = (user) => {
    this.chooseReference(`@${user.name}`, {
      submitText: `<@${user.id}>`,
    });
  };

  handleSelectTypingEmoji = (emoji) => {
    this.chooseReference(emoji.emoji);
  };

  chooseReference = (referenceText, data) => {
    const { currentAction, characters } = this.state;

    currentAction.active = false;
    currentAction.completed = true;
    currentAction.data = data;

    const start = characters.slice(0, currentAction.start);
    const end = characters.slice(currentAction.start + currentAction.text.length);

    const added = stringToCharacters(referenceText, currentAction);
    currentAction.characters = added;

    const addedWithSpace = added.concat({
      char: ' ',
    });

    const all = concatenate(start, addedWithSpace, end, currentAction);

    this.setBody(all, null);
  };

  handleCancelTagging = (cursorIndex) => {
    const { body, currentAction } = this.state;

    if (body[cursorIndex - 1] && body[cursorIndex - 1] === '@' && currentAction.toggled) {
      const newBody = body.slice(0, cursorIndex - 1) + body.slice(cursorIndex);
      const event = {
        target: {
          value: newBody,
        },
      };
      this.handleInputChange(event);
    } else {
      this.resetCurrentAction();
    }
  };

  resetCurrentAction = () => {
    const { characters, currentAction } = this.state;
    characters.forEach((character) => {
      if (character.reference === currentAction) {
        character.reference = null;
      }
    });

    this.setBody(characters, null);
  };

  createReference = (type, cursorIndex, meta) => {
    const { taggableUsers } = this.props;

    const generator = getGenerator(type, this.referenceGenerators);

    if (generator) {
      const reference = generator.generateReference(
        '@', cursorIndex, {
          taggableUsers,
          force: true,
          ...(meta || {}),
        },
      );
      const addition = {
        type,
        start: cursorIndex,
        end: cursorIndex + 1,
        length: 1,
        reference,
        text: '@',
      };
      this.handleAddition(addition);
    }
  };

  render() {
    const { disabled, currentAction, body } = this.state;

    return (
      <Form
        {...this.props}
        disabled={disabled}
        body={body}
        currentAction={currentAction}
        onInputChange={this.handleInputChange}
        onSubmit={this.handleSubmit}
        onSelectUser={this.handleSelectUser}
        onSelectTypingEmoji={this.handleSelectTypingEmoji}
        onTagging={this.handleSelectUser}
        onCancelTagging={this.handleCancelTagging}
        onCancelTypingEmoji={this.resetCurrentAction}
        createReference={this.createReference}
      />
    );
  }
};

const mapDispatchToProps = {
  saveRecord,
  fetchRecord,
  includeData,
};

const mapStateToProps = (state, ownProps) => {
  const messenger = getMessenger(state, ownProps.messageThreadId, ownProps.userId);

  return {
    messengerId: messenger ? messenger.id : null,
    messengerData: messenger ? messenger.attributes.user : {},
    taggableUsers: state.Chat.taggableUsers || [],
  };
};

const composedTaggingWrapper = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withTaggingForm,
);

export default composedTaggingWrapper;
