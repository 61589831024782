// Updates here should be copied to app/javascript/stylesheets/shared/_colors.scss

const COLORS = {
  // shared
  black: '#000000',
  blackIcon: '#212529',
  blueGrey: '#8395a7',
  blue: '#0984e3',
  blueLight: '#74b9ff',
  cyan: '#48dbfb',
  cyanMedium: '#7ed6df',
  cyanDark: '#22a6b3',
  green: '#00b894',
  green2: '#6ab04c',
  greenBright: '#00ce41',
  greenConditionLight: '#2ed573',
  greenLight: '#55efc4',
  greenMedium: '#00b800',
  greenSea: '#96c3ab',
  grey: '#636e72',
  greyBorder: '#e9ecef',
  greyLight: '#dfe6e9',
  greyMedium: '#b2bec3',
  greyText: '#8d97ad',
  lime: '#1dd1a1',
  navyLight: '#6a89cc',
  orange: '#e17055',
  orange2: '#f0932b',
  orangeBright: '#f09f2b',
  orangeLight: '#fab1a0',
  purple: '#6c5ce7',
  purpleLight2: '#686de0',
  purpleLight: '#a29bfe',
  red: '#ff0000',
  redAlert: '#e74c3c',
  redDark: '#d63031',
  redLight: '#ff7675',
  teal: '#00cec9',
  pink: '#e056fd',
  violet: '#d980fa',
  yellow: '#fdcb6e',
  yellow2: '#f9ca24',
  yellowBright: '#f09f2b',
  yellowLight: '#ffeaa7',
  white: '#ffffff',

  // conditions
  conditionYellow: '#f9ca24',
  conditionOrange: '#f0932b',
  conditionRed: '#eb4d4b',
};

export default COLORS;
