import 'core-js/stable';

// JS
import Rails from 'rails-ujs';
import 'popper.js';
import 'bootstrap';
import * as loadImage from 'blueimp-load-image/js/load-image';
import '../template/css/pages/login-register-lock.css';
import '../template/css/style.css';
import '../stylesheets/devise.scss';
import '../vendor/cocoon';

global.$ = require('jquery');

global.loadImage = loadImage;

// Support component names relative to this directory:
const componentRequireContext = require.context('components', true);
const ReactRailsUJS = require('react_ujs');

ReactRailsUJS.useContext(componentRequireContext);

$(document).ready(() => {
  Rails.start();

  $('.preloader').fadeOut();
  $('[data-toggle="tooltip"]').tooltip();
});
