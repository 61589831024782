import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

import InputWrapper from '../../WorkOrderCreateContainer/WorkOrderCreateForm/InputWrapper';
import Select from '../../../components/Inputs/Select';
import { ATTRIBUTES, REQUIRED } from '../helpers';
import Alert from '../../../components/Alert';
import { snakeCase } from '../../WorkOrderImportContainer/helpers';

function isDisabled(values) {
  return !REQUIRED.every(attr => values[snakeCase(attr)]);
}

function getInitialValues(importObject) {
  if (Object.keys(importObject.attributes.column_mapping).length > 0) {
    return {
      ...importObject.attributes.column_mapping,
      header_row: importObject.attributes.header_row,
      skip_last_row: importObject.attributes.skip_last_row,
    };
  }

  const values = {
    header_row: importObject.attributes.header_row || 1,
  };

  ATTRIBUTES.forEach((attr) => {
    if (importObject.attributes.columns.includes(attr)) {
      values[snakeCase(attr)] = attr;
    }
  });
  return values;
}

const HEADER_ROW_OPTIONS = Array.from({ length: 20 }, (v, k) => k + 1);

const InventoryColumnMappingForm = ({
  closeModal,
  importObject,
  onSubmit,
  onHeaderRowChange,
}) => (
  <>
    <div className="modal-header justify-content-center">
      <h5 className="font-weight-bold text-center">Confirm Import Inventory Settings</h5>
    </div>
    <Form
      initialValues={getInitialValues(importObject)}
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => (

        <form onSubmit={handleSubmit} id="inventory_attributes" className="fixed-width-inputs">

          <div className="modal-body">
            <Alert
              type="info"
              iconClassName="fa fa-exclamation-circle"
              title="Please select the row that contains Column Names."
            >
              Please select the row number for your Column Names. For example, if your Inventory report has general report details on rows 1 through 12, and the Column Names (like Street Address) are on row 13, then select "13" in the dropdown menu below. Your first row of inventory data would start on row 14 in this example.
            </Alert>

            <InputWrapper label="Header Row" key="header_row">
              <Field
                name="header_row"
                component={Select}
                options={HEADER_ROW_OPTIONS}
              />
            </InputWrapper>

            <Alert
              type="info"
              iconClassName="fa fa-exclamation-circle"
              title="Skip Last Row?"
            >
               If your Excel report's last row says "Total" or contains any non-inventory information, please check this box. Intimely will skip this row to prevent an error.
            </Alert>

            <InputWrapper label="Skip Last Row" key="skip_last_row">
              <Field
                name="skip_last_row"
                component="input"
                type="checkbox"
                className="mt-2"
              />
            </InputWrapper>

            <Alert
              type="info"
              iconClassName="fa fa-exclamation-circle"
              title="Please match your column names with Intimely's settings."
            >
              Let's make sure your report syncs up with Intimely. The labels on the left side are Intimely's settings. On the right side, please select which Column Name in your Inventory report matches up with the Intimely setting. You don't have to change your Inventory report's Column Names - you can simply match them up here!
            </Alert>

            {ATTRIBUTES.map(attr => (
              <InputWrapper
                key={attr}
                label={attr}
                required={REQUIRED.includes(attr)}
                attribute={snakeCase(attr)}
              >
                <Field
                  component={Select}
                  name={snakeCase(attr)}
                  includeBlank="Select Field"
                  options={importObject.attributes.columns}
                />
              </InputWrapper>
            ))}

            <OnChange name="header_row">
              {onHeaderRowChange}
            </OnChange>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isDisabled(values, importObject)}
            >
              Save
            </button>
          </div>
        </form>
      )}
    />
  </>
);

InventoryColumnMappingForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  importObject: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onHeaderRowChange: PropTypes.func.isRequired,
};

export default InventoryColumnMappingForm;
