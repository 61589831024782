import React from 'react';
import Alert from '../../components/Alert';

const ServicesAlert = () => (
  <Alert
    type="info"
    iconClassName="fa fa-exclamation-circle"
    title="Select the services that need invoices created or updated"
  >
    The selected services will be displayed in the Invoices tab.
  </Alert>
);

export default ServicesAlert;
