import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchRecord, getRecord } from 'redux-json-api-module';
import Badge from '../Badge';

const ImageBadge = ({ fetchRecord, service, serviceId, style }) => {
  useEffect(() => {
    if (!service) fetchRecord('services', serviceId);
  });

  return service ? (
    <Badge
      {...service.attributes.service_badge}
      style={style}
    />
  ) : null;
};

const mapStateToProps = (state, props) => ({
  service: getRecord(state.Api, {
    type: 'services',
    id: props.serviceId,
  }),
});

const mapDispatchToProps = {
  fetchRecord,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageBadge);
