import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import toastr from 'toastr';
import {
  deleteRecord,
  getRecord,
  getRelationship,
  saveRecord,
} from 'redux-json-api-module';
import { Card, CardHeader } from '../../components/Card';
import Alert from '../../components/Alert';
import ProjectServiceAssignmentTable
  from './ProjectServiceAssignmentTable/ProjectServiceAssignmentTable';
import ActivateButton from './ActivateButton/ActivateButton';
import ApproveCompletionButton from './ApproveCompletionButton/ApproveCompletionButton';
import AttachmentManager from './AttachmentManager/AttachmentManager';
import Scheduler from './Scheduler';
import StateAlert from './StateAlert';
import MarkCompleteForm from './MarkCompleteForm/MarkCompleteForm';
import ProjectSummary from '../../components/ProjectSummary/ProjectSummary';
import {
  includeData,
  setProject,
  hydrateProjectManagementStore, fetchProject,
  setValidationWarning,
  fetchProjectServices,
  fetchTeamMembers, setProjectServiceIds,
} from '../../redux/modules/projectManagement';
import { initializeActiveTask } from '../../redux/modules/navigation';
import {
  closeScheduler,
  closeForm,
  switchTask,
  loadEvents,
} from '../../redux/modules/scheduler';
import { pluralize } from '../../helpers/string';
import Loader from '../../components/Loader';
import './ProjectManagementContainer.scss';
import { taskSort } from '../../helpers/sorts';
import { setCurrentUser } from '../../redux/modules/auth';
import { teamObject } from '../../helpers/propTypes';
import { fetchMessengers } from '../../redux/modules/chat';
import ProjectServiceDetails from './ProjectServiceDetails';
import PlanTimeline from '../../components/PlanTimeline/PlanTimeline';
import ProjectServiceCompletionReportDownload
  from '../../components/ProjectServiceCompletionReportDownload/ProjectServiceCompletionReportDownload';
import FeatureFormButton from '../../components/FeatureFormButton';
import get from 'lodash.get'

const ProjectManagementContainer = ({
  projectId,
  areaItems, descriptionOptions, serviceOptions, conditionOptions,
  setCurrentUser, currentUser, team,

  initializeActiveTask,
  project, projectServices, users, includeData,

  projectServiceIds, validationWarning, setValidationWarning,
  areaOptions,

  deleteRecord, transitionedToActive,
  saveRecord, closeScheduler, loadEvents, setProject,
  fetchTeamMembers, fetchProjectServices, fetchMessengers,
  hydrateProjectManagementStore, fetchProject,
  closeForm, switchTask, setProjectServiceIds,
}) => {
  const [featureFormVisible, setFeatureFormVisible] = useState(false);

  useEffect(() => {
    hydrateProjectManagementStore({
      projectId,
      areaItems,
      descriptionOptions,
      serviceOptions,
      conditionOptions,
    });

    fetchTeamMembers(team.data.id);
    setCurrentUser(currentUser.data);

    fetchProject(projectId)
      .then(() => fetchProjectServices(projectId))
      .then(() => initializeActiveTask('projects', projectId))
      .then(() => fetchMessengers('projects', projectId));

    setProject(projectId);
  }, [projectId]);

  const getSummaryTitle = () => {
    if (!project) return null;

    const text = project.attributes.aasm_state === 'completed' ? 'Completed in' : 'In Progress for';

    return `Services Summary - ${text} `
      + `${project.attributes.duration || 0} ${pluralize('Day', project.attributes.duration)}`;
  };

  const submitScheduler = async (projectService) => {
    const resp = await saveRecord(projectService, { params: { window_id: window.WINDOW_ID } });

    if (resp.error) {
      toastr.error('The Project Service could not be updated.');
      return undefined;
    }

    fetchProjectServices(projectId);

    const newProjectService = resp.payload.data.data;

    const nextProjectService = projectServices.find(ps => (
      ps.id !== projectService.id && ps.attributes.started_at === null
    ));

    if (nextProjectService) {
      toastr.info(`Please assign the next service, ${nextProjectService.attributes.service_name}`);

      if (nextProjectService.relationships.user.data === null) {
        nextProjectService.relationships.user = newProjectService.relationships.user;
        nextProjectService.attributes.user_id = newProjectService.attributes.user_id;
      }

      closeForm();
      switchTask(nextProjectService);
    } else if (project.attributes.aasm_state === 'assign') {
      toastr.info('All services have been assigned.  You can now activate the project.');
      closeForm();
      return closeScheduler();
    } else {
      toastr.success('Schedule Updated.');
      return loadEvents(false).then(closeForm);
    }
  };

  const createFeature = (attributes) => {
    const record = {
      id: null,
      type: 'features',
      attributes: {
        ...attributes,
        project_id: projectId,
      },
    };

    return saveRecord(record, {
      params: {
        include: 'project,project_service',
        window_id: window.WINDOW_ID,
      },
    }).then((resp) => {
      if (resp.error) return resp;

      const newProject = resp.payload.data.included.find(i => i.type === 'projects');

      return setProjectServiceIds(
        newProject.relationships.project_services.data.map(ps => ps.id),
      );
    });
  };

  const openFeatureForm = () => setFeatureFormVisible(true);
  const closeFeatureForm = () => setFeatureFormVisible(false);

  if (!project) return <Loader />;

  return (
    <>
      <div className="animated fadeIn">
        <StateAlert
          transitionedToActive={transitionedToActive}
          aasmState={project.attributes.aasm_state}
        />

        {validationWarning ? (
          <Alert
            type="warning"
            iconClassName="fa fa-lightbulb-o"
            onDismiss={() => setValidationWarning(null)}
          >
            {validationWarning}
          </Alert>
        ) : null}

        <ProjectSummary project={project} />

        {project.attributes.aasm_state === 'assign' ? (
          <Alert
            type="danger"
            iconClassName="fa fa-exclamation-circle"
            title="Please Assign and Schedule Services, and then Activate Project"
            expandedChildren={[
              <Alert
                key="Scheduling Services One-At-A-Time"
                type="warning"
                iconClassName="fa fa-lightbulb-o"
                title="Scheduling Services One-At-A-Time"
              >
                If each Service has non-overlapping work dates, then each Service will be allowed
                to complete work before the next Service is activated, avoiding confusion and
                rescheduling. For example, if Painting needs to be completed before Janitorial
                starts, then Janitorial will be activated after Painting is completed.
              </Alert>,
              <Alert
                key="Scheduling Overlapping Services"
                type="warning"
                iconClassName="fa fa-lightbulb-o"
                title="Scheduling Overlapping Services"
              >
                If any Services have overlapping work dates, their assigned maintenance providers
                will be able to work simultaneously. For example, if Carpet Cleaning and Grout
                Cleaning can work around each other, you may overlap their work dates and both
                will
                be allowed to begin work together.
              </Alert>,
            ]}
          >
            Please assign a maintenance provider for each Service, and schedule
            their work date(s). Each Service is in the order you previously
            specified, and you may schedule any Service for any available date. All Services
            will automatically sort based on their status and work dates, so if you need to
            move one Service above or below another, simply change the work dates.
          </Alert>
        ) : null}

        <Card>
          <CardHeader title={getSummaryTitle()}>
            {project.attributes.aasm_state !== 'completed' && (
              <FeatureFormButton
                areas={areaOptions}
                descriptions={descriptionOptions}
                items={areaItems}
                services={serviceOptions}
                onSubmit={createFeature}
                onHide={closeFeatureForm}
                onShow={openFeatureForm}
                visible={featureFormVisible}
              />
            )}
          </CardHeader>
          <ProjectServiceAssignmentTable
            projectId={projectId}
            users={users}
          />
        </Card>

        {project.attributes.aasm_state === 'assign' ? (
          <div className="m-b-15 text-center">
            <ActivateButton project={project} />
          </div>
        ) : null}

        {project.attributes.aasm_state === 'review' ? (
          <div className="m-b-15 text-center">
            <ApproveCompletionButton project={project} />
          </div>
        ) : null}

        {project.attributes.aasm_state !== 'assign' && (
          <>
            <Card id="service-details">
              <CardHeader title="Service Details" />
              <ProjectServiceDetails
                planId={projectId}
                userId={currentUser.data.id}
                team={team}
              />
            </Card>
            <Card>
              <CardHeader title="Attachments" />
              <AttachmentManager
                showUser
                id={projectId}
                type="attachments"
                parentRelationship="plan_id"
                planClass="Project"
                uploadEndpoint="/api/v1/attachments"
                onDelete={deleteRecord}
                taskIds={projectServiceIds}
                plan={project}
                task_type="project_services"
                onCreate={(data) => {
                  includeData(data);
                }}
              />
            </Card>

            <div className="row">
              <div className="col-sm-12 col-md-6">
                <Card>
                  <CardHeader title="Activity" />
                  <PlanTimeline planType="projects" planId={projectId} />
                </Card>
              </div>

              <div className="col-sm-12 col-md-6">
                <Card>
                  <CardHeader title="Service Completion Reports" />

                  <ProjectServiceCompletionReportDownload
                    planId={projectId}
                    tasks={projectServices}
                    currentUser={currentUser}
                  />
                  <hr />
                  <CardHeader title="Inspection Details" />

                  <div className="row">
                    <div className="col">
                      <a
                        className="btn btn-success green btn-wrap btn-fixed-size mr-3"
                        href={`/projects/${projectId}/inspection`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        View Inspection Online
                      </a>
                      <a
                        className="btn btn-success green btn-wrap btn-fixed-size"
                        href={`/api/v1/projects/${projectId}/download_images`}
                      >
                        Download Inspection Images
                      </a>
                    </div>
                  </div>
                </Card>
              </div>
            </div>
          </>
        )}
      </div>

      <Scheduler
        onSubmit={submitScheduler}
        planType="projects"
        scheduledType="calendar_events"
      />

      <MarkCompleteForm plan={project} />
    </>
  );
};

ProjectManagementContainer.propTypes = {
  projectId: PropTypes.number.isRequired,
  team: teamObject.isRequired,
  currentUser: PropTypes.objectOf(PropTypes.any).isRequired,

  // connected
  transitionedToActive: PropTypes.bool,
  includeData: PropTypes.func.isRequired,
  deleteRecord: PropTypes.func.isRequired,
  saveRecord: PropTypes.func.isRequired,
  closeScheduler: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired,
  fetchProjectServices: PropTypes.func.isRequired,
  fetchTeamMembers: PropTypes.func.isRequired,
  fetchMessengers: PropTypes.func.isRequired,
  initializeActiveTask: PropTypes.func.isRequired,
  hydrateProjectManagementStore: PropTypes.func.isRequired,
};

ProjectManagementContainer.defaultProps = {
  transitionedToActive: false,
};

const mapStateToProps = (state, ownProps) => {
  const project = getRecord(state.Api, {
    type: 'projects',
    id: ownProps.projectId,
  });

  const projectServices = (state.ProjectManagement.projectServiceIds || [])
    .map(id => getRecord(state.Api, {
      type: 'project_services',
      id,
    }))
    .filter(ps => ps);

  projectServices.sort(taskSort);

  let inspectionImages = [];
  if (project) {
    const featureRelationship = getRelationship(state.Api, project.relationships.features);

    inspectionImages = featureRelationship ? (
      featureRelationship.map(feature => (
        feature.attributes.feature_images
      ))
        .flat()
    ) : [];
  }

  return {
    scheduler: state.ProjectManagement.scheduler,
    transitionedToActive: state.ProjectManagement.transitionedToActive,
    project,
    projectServices,
    projectServiceIds: projectServices.map(ps => ps.id),
    inspectionImages,
    validationWarning: state.ProjectManagement.validationWarning,
  };
};

const mapDispatchToProps = {
  closeForm,
  closeScheduler,
  deleteRecord,
  fetchProject,
  hydrateProjectManagementStore,
  includeData,
  loadEvents,
  switchTask,
  saveRecord,
  setCurrentUser,
  setValidationWarning,
  fetchTeamMembers,
  fetchProjectServices,
  fetchMessengers,
  initializeActiveTask,
  setProject,
  setProjectServiceIds,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProjectManagementContainer);
