import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-bootstrap';
import { getRelationship } from 'redux-json-api-module';
import changeServiceIcon from '../../../../assets/images/icon/change-service-icon.png';
import featureCardIcon from '../../../../assets/images/icon/feature-card.png';
import inspectionImagesIcon from '../../../../assets/images/icon/inspection-images.png';
import FeatureCard from '../../../components/FeatureCard/FeatureCard';
import LazyProgressiveImage from '../../../components/LazyProgressiveImage';
import ModalCarousel from '../../../components/ModalCarousel/ModalCarousel';
import './ServiceFeatureCards.scss';
import ChecklistItemsForm from '../../../components/ChecklistItemsForm';
import ManagementNotes
  from '../../../components/ServiceDetails/ManagementNotes';
import hashLinkScroll from '../../../helpers/scroll';
import CompletionTab from '../../../components/ServiceDetails/CompletionTab';
import CompletedChecklistItems
  from '../../../components/ServiceDetails/CompletedChecklistItems';
import TaskForm from '../../../components/TaskForm';
import { PARENT_TYPE_MAP, SERVICE_FORM_MAP } from '../../../helpers/types';
import Header from './Header';
import { featureSort } from '../../../helpers/sorts';

const buttonToolbarStyle = {
  marginTop: '-14px',
  marginRight: '6px',
  float: 'right',
  gap: '5px',
};

const imageViewStyle = {
  paddingTop: '10px',
  paddingBottom: '10px',
};

const ServiceFeatureCards = ({
  features, task, onBulkChangeSuccess, openChangeServiceForm,
  openEditServiceForm, summary, collapse,
}) => {
  const [bulkChangeServiceFormVisible, setBulkChangeServiceFormVisible] = useState(false);
  const [modalCarouselStartIndex, setModalCarouselStartIndex] = useState(0);
  const [modalCarouselVisible, setModalCarouselVisible] = useState(false);
  const [viewMode, setViewMode] = useState('cards');
  const [visible, setVisible] = useState(!collapse);

  useEffect(() => {
    hashLinkScroll();
  }, []);

  if (features.length === 0 || !task) return null;

  const featureImages = features.flatMap(n => n.attributes.feature_images);
  const ServiceForm = SERVICE_FORM_MAP[`${PARENT_TYPE_MAP[task.type]}s`];

  return (
    <>
      <div className="card">
        <div className="card-body">
          <Header
            task={task}
            visible={visible}
            onShow={() => setVisible(true)}
            onHide={() => setVisible(false)}
          />
          {visible ? (
            <>
              <h4 className="form-header mb-3">
                Inspection Details
                <ButtonToolbar style={buttonToolbarStyle}>
                  <ButtonGroup>
                    <Button
                      id="bulk-change-service-button"
                      variant="secondary"
                      title="Move All Image(s) to New Service"
                      onClick={() => setBulkChangeServiceFormVisible(true)}
                    >
                      <img
                        src={changeServiceIcon}
                        alt="Bulk Update"
                        width="30"
                        height="30"
                      />
                    </Button>
                  </ButtonGroup>
                  <ToggleButtonGroup
                    name="view-mode"
                    value={viewMode}
                    onChange={value => setViewMode(value)}
                  >
                    {[
                      ['cards', featureCardIcon],
                      ['images', inspectionImagesIcon],
                    ].map(([value, icon]) => (
                      <ToggleButton
                        key={value}
                        variant="secondary"
                        value={value}
                      >
                        <img
                          src={icon}
                          alt={`${value} view`}
                          width="30"
                          height="30"
                        />
                      </ToggleButton>
                    ))}
                  </ToggleButtonGroup>
                </ButtonToolbar>
              </h4>
              {viewMode === 'cards' ? (
                <div className="row mb-3 slick-service-carousel">
                  {features.map(f => (
                    <div
                      className="col-3"
                      style={{ paddingTop: '10px', paddingBottom: '10px' }}
                      key={f.id}
                    >
                      <FeatureCard
                        featureId={f.id}
                        featureType={f.type}
                        summary={summary}
                        openChangeServiceForm={openChangeServiceForm}
                        openEditServiceForm={openEditServiceForm}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="row mb-3">
                  {featureImages.map((image, index) => (
                    <div
                      key={image.id}
                      className="col-3"
                      style={imageViewStyle}
                    >
                      <LazyProgressiveImage
                        sourceUrl={image.file.thumb_sq.url}
                        placeholderUrl={image.file.thumb_placeholder_sq.url}
                        onClick={() => {
                          setModalCarouselStartIndex(index);
                          setModalCarouselVisible(true);
                        }}
                        alt="Image"
                        imageClassName="w-100 cursor-pointer"
                      />
                    </div>
                  ))}
                </div>
              )}
              <ServiceForm
                componentType="BulkChangeService"
                visible={bulkChangeServiceFormVisible}
                task={task}
                features={features}
                featureImages={featureImages}
                onClose={() => setBulkChangeServiceFormVisible(false)}
                onSaveSuccess={onBulkChangeSuccess}
              />
              <ModalCarousel
                visible={modalCarouselVisible}
                images={featureImages.map(n => n.file)}
                onClose={() => setModalCarouselVisible(false)}
                startIndex={modalCarouselStartIndex}
              />
            </>
          ) : null}
          <div className="row">
            <div className="col-md-6">
              {task.attributes.aasm_state === 'completed' ? (
                <>
                  <ManagementNotes task={task} />
                  <CompletedChecklistItems task={task} />
                </>
              ) : (
                <TaskForm
                  initialValues={{
                    management_notes: task.attributes.management_notes,
                  }}
                  loading={false}
                  task={task}
                />
              )}
            </div>

            {task.attributes.aasm_state !== 'completed' ? (
              <div className="col-md-6">
                <ChecklistItemsForm
                  parent={task}
                  checklistItemType="checklist_items"
                  allowAdditions={task.attributes.aasm_state !== 'completed'}
                  title="Checklist Items"
                  instructions="Please add checklist items for the service provider"
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {task.attributes.aasm_state === 'completed' ? (
        <div className="card">
          <div className="card-body">
            <CompletionTab taskId={task.id} taskType={task.type} summary />
          </div>
        </div>
      ) : null}
    </>
  );
};

ServiceFeatureCards.propTypes = {
  task: PropTypes.objectOf(PropTypes.any).isRequired,
  onBulkChangeSuccess: PropTypes.func,
  openChangeServiceForm: PropTypes.func,
  openEditServiceForm: PropTypes.func,
  summary: PropTypes.bool,
  collapse: PropTypes.bool,

  // connected
  features: PropTypes.arrayOf(PropTypes.object).isRequired,
};

ServiceFeatureCards.defaultProps = {
  summary: false,
  collapse: false,
  onBulkChangeSuccess: null,
  openChangeServiceForm: () => {
  },
  openEditServiceForm: () => {
  },
};

const mapStateToProps = (state, ownProps) => {
  const plan = getRelationship(
    state.Api,
    ownProps.task.relationships[PARENT_TYPE_MAP[ownProps.task.type]],
  );

  const features = getRelationship(
    state.Api,
    ownProps.task.relationships.features,
  ) || [];

  return {
    features: features.sort(featureSort(state.Api)),
    unit: getRelationship(state.Api, plan.relationships.unit),
  };
};

export default connect(mapStateToProps)(ServiceFeatureCards);
