import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';
import InspectionReviewContainer
  from '../containers/InspectionReviewContainer/InspectionReviewContainer';

const InspectionReview = props => (
  <Provider store={store}>
    <InspectionReviewContainer {...props} />
  </Provider>
);

export default InspectionReview;
