import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { fetchRecords, getRelationship } from 'redux-json-api-module';
import overlayFactory from 'react-bootstrap-table2-overlay';
import moment from 'moment';
import ServiceCell from './ServiceCell';
import UserCell from './UserCell';
import UnitCell from './UnitCell';

const columns = [
  {
    dataField: 'createdAt',
    text: 'Date',
    type: 'date',
    align: 'right',
    style: { verticalAlign: 'middle' },
    headerStyle: { width: '200px' },
  },
  {
    dataField: 'unit',
    text: 'Unit',
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'quantity',
    text: 'Quantity',
    type: 'number',
    align: 'right',
    style: { verticalAlign: 'middle' },
    headerStyle: { width: '200px' },
  },
  {
    dataField: 'service',
    text: 'Service',
    align: 'center',
    style: { verticalAlign: 'middle' },
  },
  {
    dataField: 'user',
    text: 'Completed By',
    align: 'center',
    headerStyle: { width: '200px' },
    style: {
      verticalAlign: 'middle',
      padding: 0,
    },
  },
];

const defaultSorted = [{
  dataField: 'createdAt',
  order: 'desc',
}];

const NoDataIndication = () => (
  <div className="text-center">No Parts Usage History</div>
);

const PartsUsageHistoryTable = ({
  itemId,
  serviceInvoiceItems,
  fetchRecords,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    fetchRecords(`items/${itemId}`, {
      page: { size: 250 },
      include: [
        'service_invoice_items', 'service_invoice_items.feature',
        'service_invoice_items.feature.room', 'service_invoice_items.feature.room.unit',
        'service_invoice_items.service_invoice',
        'service_invoice_items.service_invoice.maintenance_user',
      ].join(','),
    }).finally(() => setLoading(false));
  }, []);

  const data = serviceInvoiceItems.map(si => ({
    id: si.id,
    createdAt: moment(si.attributes.created_at)
      .format('MM/DD/YY'),
    unit: (
      <UnitCell
        featureId={si.relationships.feature.data.id}
        featureType={si.relationships.feature.data.type}
      />
    ),
    quantity: si.attributes.quantity,
    service: (
      <ServiceCell
        featureId={si.relationships.feature.data.id}
        featureType={si.relationships.feature.data.type}
      />
    ),
    user: (
      <UserCell
        serviceInvoiceId={si.relationships.service_invoice.data.id}
      />
    ),
  }));

  const Overlay = overlayFactory({ spinner: true });

  return (
    <BootstrapTable
      keyField="id"
      data={data}
      columns={columns}
      defaultSorted={defaultSorted}
      loading={loading}
      overlay={Overlay}
      noDataIndication={NoDataIndication}
    />
  );
};

PartsUsageHistoryTable.propTypes = {
  itemId: PropTypes.string.isRequired,

  // connected
  serviceInvoiceItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  fetchRecords: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const item = state.Api.items[ownProps.itemId];

  return {
    serviceInvoiceItems: getRelationship(state.Api, item.relationships.service_invoice_items) || [],
  };
};

const mapDispatchToProps = {
  fetchRecords,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PartsUsageHistoryTable);
