import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord, getRelationship } from 'redux-json-api-module';
import COLORS from '../../helpers/colors';
import { getMessenger, setActiveMessageThread } from '../../redux/modules/chat';
import { idType } from '../../helpers/propTypes';
import DirectMessageChatUnreadCount from './DirectMessageChatUnreadCount';
import { idComparison } from '../../helpers/string';
import { getOtherDMUser } from './helpers';
import get from 'lodash.get';

const DirectMessageChatLink = ({
  messageThread,
  active,
  setActiveMessageThread,
  userId,
  directMessageChatId,
  name,
  otherUserId,
  threadsList,
  onShowUnread,
  onHideUnread,
  unreadCount,
}) => {
  const link = useRef(null);

  const handleScroll = () => {
    if (unreadCount === 0) return onHideUnread(messageThread.id);

    if (link.current.getBoundingClientRect().bottom < threadsList.current.offsetTop) {
      return onShowUnread(messageThread.id, 'top');
    }

    if (link.current.getBoundingClientRect().top > window.innerHeight) {
      return onShowUnread(messageThread.id, 'bottom');
    }

    return onHideUnread(messageThread.id);
  };

  useEffect(() => {
    threadsList.current.addEventListener('scroll', handleScroll);
    handleScroll();
  }, [unreadCount]);

  if (!otherUserId) return null;

  return (
    <div onScroll={handleScroll}>
      <button
        ref={link}
        id={`direct_message_chat-link-${directMessageChatId}`}
        type="button"
        className="mt-1 btn btn-link text-left w-100"
        style={{
          color: COLORS.blue,
          marginLeft: '8px',
        }}
        onClick={() => {
          setActiveMessageThread({
            planType: null,
            planId: null,
            taskType: null,
            taskId: null,
            channelId: null,
            directMessageChatId,
            messageThreadId: messageThread.id,
          });
        }}
      >
        <div className="row">
          <div className="col-1 my-auto pl-0">
            <DirectMessageChatUnreadCount
              messageThreadId={messageThread && messageThread.id}
              userId={userId}
              directMessageChatId={directMessageChatId}
              otherUserId={otherUserId}
            />
          </div>
          <div className={`col-10 pl-0 ml-3 my-auto ${active ? 'font-weight-bold' : ''}`}>
            {name}
          </div>
        </div>
      </button>
    </div>
  );
};

DirectMessageChatLink.propTypes = {
  messageThread: PropTypes.object,
  active: PropTypes.bool.isRequired,
  setActiveMessageThread: PropTypes.func.isRequired,
  userId: idType.isRequired,
  directMessageChatId: idType.isRequired,
  name: PropTypes.string,
  otherUserId: idType,
};

DirectMessageChatLink.defaultProps = {
  messageThread: null,
  name: '',
  otherUserId: undefined,
};

const mapStateToProps = (state, ownProps) => {
  const directMessageChat = getRecord(state.Api, {
    type: 'direct_message_chats', id: ownProps.directMessageChatId,
  });

  if (!directMessageChat) {
    return {
      active: false,
    };
  }

  const messageThread = getRelationship(state.Api, directMessageChat.relationships.message_thread);
  const messenger = getMessenger(state, messageThread.id, ownProps.userId);

  const active = state.Chat.activeDirectMessageChatId
    ? idComparison(ownProps.directMessageChatId, state.Chat.activeDirectMessageChatId) : false;

  const { name, otherUserId } = getOtherDMUser(directMessageChat, ownProps.userId, state.Api);

  return {
    messageThread,
    active,
    name,
    otherUserId,
    unreadCount: get(messenger, 'attributes.unread_count', 0),
  };
};

const mapDispatchToProps = {
  setActiveMessageThread,
};

export default connect(mapStateToProps, mapDispatchToProps)(DirectMessageChatLink);
