import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { getRecord, getRelationship } from 'redux-json-api-module/dist/selectors';
import { Button } from 'react-bootstrap';
import Loader from '../../components/Loader';
import { fetchCommunities, saveCommunities } from '../../redux/modules/units';
import Alert from '../../components/Alert';
import { idType } from '../../helpers/propTypes';

const columns = [{
  dataField: 'attributes.name',
  text: 'Name',
}];

function getSelected(communities, teamId, api) {
  const selected = [];
  communities.forEach((community) => {
    const teamCommunities = getRelationship(api, community.relationships.team_communities);
    const teamIds = teamCommunities.map(tc => get(tc, 'attributes.team_id', '')
      .toString());
    if (teamIds.includes(teamId.toString())) {
      selected.push(community.id);
    }
  });
  return selected;
}

class CommunitiesTab extends Component {
  static propTypes = {
    communities: PropTypes.array.isRequired,
    selectedCommunityIds: PropTypes.arrayOf(idType).isRequired,
    fetchCommunities: PropTypes.func.isRequired,
    saveCommunities: PropTypes.func.isRequired,
    teamId: idType.isRequired,
  };

  state = {
    loading: true,
    selected: [],
  };

  componentDidMount() {
    const { fetchCommunities } = this.props;

    fetchCommunities()
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  componentWillReceiveProps(nextProps) {
    const { communities } = this.props;
    const { communities: nextCommunities, selectedCommunityIds } = nextProps;

    if (communities.length === 0 && nextCommunities.length !== 0) {
      this.setState({
        selected: selectedCommunityIds,
      });
    }
  }

  save = () => {
    const { selected } = this.state;
    const { saveCommunities, teamId, selectedCommunityIds } = this.props;

    saveCommunities(teamId, selected, selectedCommunityIds);
  };

  onSelect = (community, isSelected) => {
    const { selected } = this.state;

    let updatedSelected = [];
    if (isSelected) {
      updatedSelected = selected.concat([community.id]);
    } else {
      updatedSelected = selected.filter(communityId => communityId !== community.id);
    }

    this.setState({ selected: updatedSelected });
  };

  onSelectAll = (isSelected) => {
    const { communities } = this.props;

    this.setState({
      selected: isSelected ? communities.map(c => c.id) : [],
    });
  };

  render() {
    const { communities } = this.props;
    const { loading, selected } = this.state;

    return (
      loading ? <Loader /> : (
        <div className="mt-2">
          <BootstrapTable
            id="community-table"
            keyField="id"
            columns={columns}
            data={communities}
            rowClasses={r => (`row-${r.id}`)}
            selectRow={{
              selected,
              onSelect: this.onSelect,
              onSelectAll: this.onSelectAll,
              mode: 'checkbox',
              clickToSelect: true,
            }}
          />
          <div>
            <Button variant="primary" onClick={this.save}>Save</Button>
          </div>
        </div>
      )
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const communities = (state.Units.communityIds || []).map(communityId => (
    getRecord(state.Api, {
      type: 'communities',
      id: communityId,
    })
  ))
    .filter(c => c);

  const selectedCommunityIds = getSelected(communities, ownProps.teamId, state.Api);

  return {
    communities,
    selectedCommunityIds,
  };
};

const mapDispatchToProps = {
  fetchCommunities,
  saveCommunities,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunitiesTab);
