import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord } from 'redux-json-api-module';
import { Button } from 'react-bootstrap';
import COLORS from '../../helpers/colors';
import { toggleExpandedArea } from '../../redux/modules/inspections';
import { stripCount } from './helpers';
import editIcon from '../../../assets/images/icon/edit-icon.png';
import AreaTabForm from './AreaTabForm';
import { idType } from '../../helpers/propTypes';

const AreaListItem = ({
  toggleExpandedArea,
  area,
  areaId,
  expanded,
  handleSubmit,
}) => {
  if (!area) return null;

  if (expanded) {
    return <AreaTabForm area={area} handleSubmit={handleSubmit} />;
  }

  return (
    <li
      className="my-3"
      style={{
        borderBottom: `1px solid ${COLORS.greyBorder}`,
        justifyContent: 'space-between',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',

      }}
    >
      <span style={{ fontSize: '18px' }}>
        {stripCount(area.attributes.name)}
      </span>

      <Button variant="link" onClick={() => toggleExpandedArea(areaId)} id={`edit-${areaId}-btn`}>
        <img src={editIcon} style={{ width: '30px', height: '30px' }} alt="Edit" />
      </Button>
    </li>
  );
};

AreaListItem.propTypes = {
  toggleExpandedArea: PropTypes.func.isRequired,
  area: PropTypes.object,
  areaId: idType.isRequired,
  expanded: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
};

AreaListItem.defaultProps = {
  area: undefined,
  expanded: false,
};

const mapDispatchToProps = {
  toggleExpandedArea,
};

const mapStateToProps = (state, ownProps) => {
  const area = getRecord(state.Api, { type: 'areas', id: ownProps.areaId });

  const expanded = state.Inspections.expandedId === ownProps.areaId;

  return {
    area,
    expanded,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AreaListItem);
