import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import withTaggingForm from './withTaggingForm';
import MessengerActions from './MessengerActions';
import EmojiPicker from './EmojiPicker';
import ActionPopover from './ActionPopover';
import { insert, onlyEmojis } from './helpers';
import COLORS from '../../helpers/colors';
import { idType } from '../../helpers/propTypes';

class MessengerForm extends PureComponent {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    disabled: PropTypes.bool.isRequired,
    body: PropTypes.string.isRequired,
    onInputChange: PropTypes.func.isRequired,
    currentAction: PropTypes.shape({
      type: PropTypes.string,
    }),
    onSelectUser: PropTypes.func.isRequired,
    onCancelTagging: PropTypes.func.isRequired,
    onCancelTypingEmoji: PropTypes.func.isRequired,
    onSelectTypingEmoji: PropTypes.func.isRequired,
    reset: PropTypes.func,
    messageId: PropTypes.string,
    onInputFocus: PropTypes.func,
    createReference: PropTypes.func.isRequired,
    messageThreadId: idType.isRequired,
    userId: idType.isRequired,
  };

  static defaultProps = {
    currentAction: null,
    reset: null,
    messageId: null,
    onInputFocus: () => {
    },
  };

  state = {
    selectingEmoji: false,
    cursorIndex: 0,
  };

  componentDidMount() {
    const element = document.getElementById('body');

    if (element) {
      element.focus({
        preventScroll: true,
      });
    }
  }

  onEnterPress = (e) => {
    const { onSubmit, disabled } = this.props;
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (!disabled) onSubmit();
    }
  };

  handleSelectUser = (user) => {
    const { onSelectUser, currentAction } = this.props;
    if (!currentAction) return;
    this.bodyInput.focus();
    setTimeout(() => {
      this.bodyInput.selectionStart = currentAction.start + user.name.length + 2;
      this.bodyInput.selectionEnd = currentAction.start + user.name.length + 2;
    }, 100);
    onSelectUser(user);
  };

  handleCancelTagging = () => {
    const { cursorIndex } = this.state;
    const { onCancelTagging } = this.props;

    onCancelTagging(cursorIndex);
    this.bodyInput.focus();
  };

  handleSelectTypingEmoji = (emoji) => {
    const { onSelectTypingEmoji } = this.props;
    this.bodyInput.focus();
    onSelectTypingEmoji(emoji);
  };

  handleCancelTypingEmoji = () => {
    const { onCancelTypingEmoji } = this.props;
    this.bodyInput.focus();
    onCancelTypingEmoji();
  };

  handleEmojiSelection = (event, emojiObject) => {
    const { onInputChange, body } = this.props;
    const { cursorIndex } = this.state;

    const value = insert(body, cursorIndex || body.length - 1, emojiObject.emoji);
    this.bodyInput.focus();

    onInputChange({ target: { value } });
    this.setState({ selectingEmoji: false });
  };

  toggleEmojiSelection = () => {
    this.handleCancelTagging();
    const { selectingEmoji } = this.state;
    const cursorIndex = this.bodyInput.selectionStart;
    this.bodyInput.focus();

    this.setState({
      selectingEmoji: !selectingEmoji,
      cursorIndex,
    }, () => {
      this.bodyInput.selectionStart = cursorIndex + 1;
      this.bodyInput.selectionEnd = cursorIndex + 1;
    });
  };

  cancelEmojiSelecting = () => {
    this.setState({ selectingEmoji: false });
  }

  toggleTagging = () => {
    const { createReference, currentAction } = this.props;

    if (currentAction && currentAction.type === 'tagging') {
      this.handleCancelTagging();
    } else {
      this.cancelEmojiSelecting();
      const cursorIndex = this.bodyInput.selectionStart;
      createReference('tagging', cursorIndex, { toggled: true });
      this.bodyInput.focus();

      this.setState({ cursorIndex: cursorIndex + 1 }, () => {
        this.bodyInput.selectionStart = cursorIndex + 1;
        this.bodyInput.selectionEnd = cursorIndex + 1;
      });
    }
  };

  handleInputChange = (event) => {
    const { onInputChange } = this.props;
    const cursorIndex = this.bodyInput.selectionStart;
    this.setState({ cursorIndex });
    onInputChange(event, cursorIndex);
  };

  render() {
    const {
      disabled,
      onSubmit,
      body,
      messageId,
      reset,
      messageThreadId,
      currentAction,
      userId,
      onInputFocus,
    } = this.props;

    const { selectingEmoji } = this.state;

    return (
      <div className="card-body pb-0" style={{ position: 'relative', borderTop: `1px solid ${COLORS.greyMedium}` }}>
        {currentAction && currentAction.type === 'tagging' && currentAction.active ? (
          <ActionPopover
            key={body}
            matches={currentAction.matches}
            onSelect={this.handleSelectUser}
            onCancel={this.handleCancelTagging}
            display={match => match.name}
          />
        ) : null}
        {currentAction && currentAction.type === 'emoji' && currentAction.active ? (
          <ActionPopover
            key={body}
            matches={currentAction.matches}
            onSelect={this.handleSelectTypingEmoji}
            onCancel={this.handleCancelTypingEmoji}
            display={match => `${match.emoji} ${match.key}`}
          />
        ) : null}
        <EmojiPicker
          onEmojiSelection={this.handleEmojiSelection}
          selectingEmoji={selectingEmoji}
          condensed={!!messageId}
        />
        <div className="row">
          <div className="col-8">
            <textarea
              id="body"
              value={body}
              style={{ fontSize: (onlyEmojis(body) ? '32px' : '14px') }}
              onChange={this.handleInputChange}
              rows={onlyEmojis(body) ? 1 : 2}
              className="form-control border-0"
              placeholder="Type your message here"
              width="100%"
              onKeyPress={this.onEnterPress}
              ref={(input) => {
                this.bodyInput = input;
              }}
              onClick={() => onInputFocus(messageThreadId)}
            />
          </div>
          <div className="col-4 text-right">
            {messageId ? (
              <React.Fragment>
                <Button
                  type="submit"
                  variant="info"
                  size="md"
                  id="submit-chat"
                  disabled={disabled}
                  onClick={onSubmit}
                >
                  Save
                </Button>
                <Button
                  type="button"
                  variant="link"
                  size="md"
                  onClick={reset}
                >
                  Cancel
                </Button>
              </React.Fragment>
            ) : (
              <Button
                type="submit"
                variant="info"
                className="btn-circle"
                size="lg"
                id="submit-chat"
                disabled={disabled}
                onClick={onSubmit}
              >
                <i className="fa fa-paper-plane" />
              </Button>
            )}
          </div>
        </div>
        <MessengerActions
          toggleTagging={this.toggleTagging}
          toggleEmojiSelection={this.toggleEmojiSelection}
          userId={userId}
          messageId={messageId}
          messageThreadId={messageThreadId}
        />
      </div>
    );
  }
}

export default withTaggingForm(MessengerForm);
