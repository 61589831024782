import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import InventoryColumnMappingForm from '../InventoryColumnMappingForm/InventoryColumnMappingForm';
import InventoryReview from '../InventoryReview/InventoryReview';

const ImportModal = ({
  mappingFormVisible,
  reviewVisible,
  closeModal,
  importObject,
  onSubmitColumnMapping,
  onSubmitReview,
  onDrop,
  openColumnMappingForm,
  uploading,
  onHeaderRowChange,
}) => (
  <Modal
    show={mappingFormVisible || reviewVisible}
    onHide={closeModal}
    size="xl"
  >
    {mappingFormVisible ? (
      <InventoryColumnMappingForm
        closeModal={closeModal}
        importObject={importObject}
        onSubmit={onSubmitColumnMapping}
        onHeaderRowChange={onHeaderRowChange}
        key={importObject.attributes.updated_at}
      />
    ) : (
      <InventoryReview
        closeModal={closeModal}
        importObject={importObject}
        onSubmit={onSubmitReview}
        onDrop={onDrop}
        openColumnMappingForm={openColumnMappingForm}
        uploading={uploading}
      />
    )}
  </Modal>
);

ImportModal.propTypes = {
  mappingFormVisible: PropTypes.bool.isRequired,
  reviewVisible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  importObject: PropTypes.object.isRequired,
  onSubmitColumnMapping: PropTypes.func.isRequired,
  onSubmitReview: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  openColumnMappingForm: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
  onHeaderRowChange: PropTypes.func.isRequired,
};

export default ImportModal;
