const regex = new RegExp('<bold.*>', 'ig');
const BOLD_STYLE = {
  fontWeight: 'bold',
};

function getPhrase(phrase) {
  return phrase.replace('<bold', '').slice(0, -1);
}

const BoldFormatter = {
  format: (textSections) => {
    const sections = [];
    textSections.forEach((section) => {
      const matches = (section.text || '').match(regex);
      if (!matches || matches.length === 0) {
        sections.push(section);
      } else {
        matches.forEach((match) => {
          const parts = section.text.split(match);
          if (parts[0].length > 0) {
            sections.push({
              text: parts[0],
            });
          }
          sections.push({
            text: getPhrase(match),
            style: BOLD_STYLE,
          });
          if (parts[1] && parts[1].length > 0) {
            sections.push({
              text: parts[1],
            });
          }
        });
      }
    });
    return sections;
  },
};

export default BoldFormatter;
