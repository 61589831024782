import COLORS from '../../../helpers/colors';
import { CALENDAR_EVENT_TYPE_MAP } from '../../../helpers/types';

const fontStyle = {
  fontSize: '0.7rem',
  color: COLORS.white,
};

export const eventPropGetter = (event) => {
  // completed services are displayed w/ $green border/text
  if (event.resource.completed) {
    return {
      style: {
        ...fontStyle,
        margin: '1px',
        border: `1px solid ${COLORS.green}`,
        backgroundColor: COLORS.white,
        color: COLORS.green,
      },
    };
  }

  return {
    style: {
      ...fontStyle,
      backgroundColor: event.resource.serviceBadge.background_color,
    },
  };
};

export function parseCompositeId(compositeId) {
  const parts = compositeId.split('-');
  const type = parts[0];
  const id = parts[1];

  return {
    type, id,
  };
}

export function getCompositeId(event) {
  return `${CALENDAR_EVENT_TYPE_MAP[event.type]}-${event.id}`;
}
