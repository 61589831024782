import React from 'react';
import ListGroupItem from '../../TeamScheduleContainer/ScheduleSelector/ListGroupItem';

const PLAN_TYPE_OPTIONS = [
  'View All',
  'Make Ready\'s',
  'Work Orders',
];

const PlanTypeSelector = ({ value, onChange }) => (
  <div className="dropdown">
    <button
      className="btn btn-primary toggle-button"
      type="button"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {value}
      <i className="fa fa-chevron-down spaced-icon" />
    </button>
    <div className="dropdown-menu scrollable-menu" aria-labelledby="dropdownMenuButton">
      {PLAN_TYPE_OPTIONS.map(filterOption => (
        <ListGroupItem
          selected={value === filterOption}
          onClick={() => onChange(filterOption)}
          key={filterOption}
        >
          {filterOption}
        </ListGroupItem>
      ))}
    </div>
  </div>
);

export default PlanTypeSelector;
