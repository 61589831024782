import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord } from 'redux-json-api-module';
import get from 'lodash.get';
import Avatar from './Avatar';
import { genericRecordPropType, idPropType } from '../../helpers/propTypes';
import { ellipsisStyle } from '../../containers/ChatsContainer/constants';

const UserAvatar = ({ user, includeName, size }) => {
  if (!user) return null;

  const avatar = (
    <Avatar
      {...user.attributes}
      className={`avatar-${size} d-inline-block m-r-5`}
    />
  );

  if (!includeName) return avatar;

  return (
    <div style={ellipsisStyle}>
      <span>
        {avatar}
      </span>
      {get(user, 'attributes.name', '')}
    </div>
  );
};

UserAvatar.propTypes = {
  userId: idPropType.isRequired,
  includeName: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm']),

  // connected
  user: genericRecordPropType.isRequired,
};

UserAvatar.defaultProps = {
  includeName: false,
  size: 'sm',
};

const mapStateToProps = (state, props) => ({
  user: getRecord(state.Api, {
    type: 'users',
    id: props.userId,
  }),
});

export default connect(
  mapStateToProps,
)(UserAvatar);
