import React from 'react';
import PropTypes from 'prop-types';
import CompletedEventIndicator from './CompletedEventIndicator';
import { isCompleted } from './helpers';

const getServiceColor = event => event.resource.serviceBadge.background_color;

const MonthEvent = ({ event, completedIndicator }) => (
  <>
    {completedIndicator && isCompleted(event) ? (
      <CompletedEventIndicator color={getServiceColor(event)} />
    ) : null}
    {event.title}
  </>
);

MonthEvent.propTypes = {
  event: PropTypes.objectOf(PropTypes.any).isRequired,
  completedIndicator: PropTypes.bool,
};

MonthEvent.defaultProps = {
  completedIndicator: true,
};

export default MonthEvent;
