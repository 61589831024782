import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import GenericFilter from './GenericFilter';
import { SELECTED_MAP, setSelected, OPTIONS } from '../../../redux/modules/teamSchedule';
import { options } from '../../../helpers/propTypes';

const OptionFilter = ({
  options,
  selected,
  setSelected,
  name,
  title,
}) => (
  options ? (
    <span className="ml-2">
      <GenericFilter
        title={title}
        options={options}
        selected={selected}
        setSelected={setSelected}
        name={name}
      />
    </span>
  ) : null
);

OptionFilter.propTypes = {
  options,
  selected: PropTypes.object,
  setSelected: PropTypes.func.isRequired,
  name: PropTypes.string,
  title: PropTypes.string,
};

OptionFilter.defaultProps = {
  selected: null,
  title: null,
  options: null,
  name: null,
};

const mapStateToProps = (state) => {
  const optionsKey = state.TeamSchedule.secondaryFilter === 'community'
    ? `community_${state.TeamSchedule.planType}` : state.TeamSchedule.secondaryFilter;

  const options = state.TeamSchedule[OPTIONS[optionsKey]];

  let selected = state.TeamSchedule[SELECTED_MAP[state.TeamSchedule.secondaryFilter]];

  if (!selected && options) {
    selected = options[0];
  }

  return {
    options,
    selected: selected ? selected.id : null,
    name: state.TeamSchedule.secondaryFilter,
    title: selected ? selected.label : null,
  };
};

const mapDispatchToProps = {
  setSelected,
};

export default connect(mapStateToProps, mapDispatchToProps)(OptionFilter);
