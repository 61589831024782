import React from 'react';
import { Form, Field } from 'react-final-form';
import moment from 'moment';
import { Card } from '../../../components/Card';
import { showErrors } from '../../WorkOrderCreateContainer/WorkOrderCreateForm/helpers';
import PrioritySelector from '../../../components/PrioritySelector/PrioritySelector';
import DateInput from './DateInput';

function getInitialValues(attributes) {
  return {
    ...attributes,
    permission_to_enter: attributes.permission_to_enter ? 'true' : 'false',
    recurring: attributes.recurring ? 'true' : 'false',
    stop_recurring_on: moment(attributes.stop_recurring_on).toDate(),
  };
}

const EditWorkOrderDetailsForm = ({
  errors,
  saving,
  onSubmit,
  toggleEdit,
  attributes,
}) => (
  <Card className="summary">
    <h4 className="card-title">Work Order Details</h4>
    <hr />
    <Form
      keepDirtyOnReinitialize
      initialValues={getInitialValues(attributes)}
      onSubmit={onSubmit}

      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} id="edit_work_order">
          <div className="row">
            <div className="col-sm-6 col-lg-4">
              <table
                className="table table-borderless table-sm details-table"
              >
                <tbody>
                  <tr>
                    <td className="font-weight-bold">
                      <div className="row">
                        <div className="col">Work Order #</div>
                        <div className="col">Priority</div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ lineHeight: 'normal' }}>
                      <div className="row">
                        <div className="col">
                          <Field
                            name="work_order_number"
                            type="text"
                            label="Work Order #"
                            component="input"
                            placeholder=""
                            className="form-control string required"
                            style={{ width: '100px' }}
                          />
                          {showErrors(errors, 'work_order_number')}
                        </div>
                        <div className="col">
                          <Field
                            name="priority"
                            component={PrioritySelector}
                            label="Priority"
                          />
                          {showErrors(errors, 'priority')}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold">
                      Work Order Description
                    </td>
                  </tr>
                  <tr>
                    <td style={{ lineHeight: 'normal' }}>
                      <Field
                        name="description"
                        type="text"
                        label="Description"
                        component="textarea"
                        placeholder=""
                        className="form-control string required"
                        rows="4"
                      />
                      {showErrors(errors, 'description')}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-sm-6 col-lg-4">
              <table className="table table-borderless table-sm details-table">
                <tbody>
                  <tr>
                    <td className="font-weight-bold">
                      Permission to Enter
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label className="mr-3">
                        <Field
                          name="permission_to_enter"
                          component="input"
                          type="radio"
                          value="true"
                        />
                        {' '}
                        Yes
                      </label>
                      <label>
                        <Field
                          name="permission_to_enter"
                          component="input"
                          type="radio"
                          value="false"
                        />
                        {' '}
                        No
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td className="font-weight-bold">
                      Additional Entry Notes
                    </td>
                  </tr>
                  <tr>
                    <td style={{ lineHeight: 'normal' }}>
                      <Field
                        name="additional_entry_notes"
                        type="text"
                        label="Additional Entry Notes"
                        component="textarea"
                        placeholder=""
                        className="form-control string required"
                        rows="4"
                      />
                      {showErrors(errors, 'entry_noes')}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-sm-6 col-lg-4">
              <table className="table table-borderless table-sm details-table">
                <tbody>
                  <tr>
                    <td className="font-weight-bold">
                      Recurring Work Order?
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <label className="mr-3">
                        <Field
                          name="recurring"
                          component="input"
                          type="radio"
                          value="true"
                        />
                        {' '}
                        Yes
                      </label>
                      <label>
                        <Field
                          name="recurring"
                          component="input"
                          type="radio"
                          value="false"
                        />
                        {' '}
                        No
                      </label>
                    </td>
                  </tr>
                  {values.recurring === 'true' ? (
                    <>
                      <tr>
                        <td className="font-weight-bold">
                          Start Recurring On
                        </td>
                      </tr>
                      <tr>
                        <td>
                          {moment(attributes.startRecurringOn)
                            .format('MM/DD/YY')}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">
                            Stop Recurring On
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Field
                            name="stop_recurring_on"
                            component={DateInput}
                            label="Stop Repeating On"
                            minDate={attributes.start_recurring_on ? moment(attributes.start_recurring_on)
                              .endOf('day')
                              .toDate() : null}
                          />
                          {showErrors(errors, 'stop_recurring_on')}
                        </td>
                      </tr>
                    </>
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-lg-4">
              <table className="table table-borderless table-sm details-table">
                <tbody>
                  <tr>
                    <td className="font-weight-bold">
                      Tenant Name
                    </td>
                  </tr>
                  <tr>
                    <td style={{ lineHeight: 'normal' }}>
                      <Field
                        name="tenant_name"
                        type="text"
                        label="Tenant Name"
                        component="input"
                        placeholder=""
                        className="form-control string required"
                        style={{ width: '200px' }}
                      />
                      {showErrors(errors, 'tenant_name')}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-sm-6 col-lg-4">
              <table className="table table-borderless table-sm details-table">
                <tbody>
                  <tr>
                    <td className="font-weight-bold">
                      Tenant Email
                    </td>
                  </tr>
                  <tr>
                    <td style={{ lineHeight: 'normal' }}>
                      <Field
                        name="tenant_email"
                        type="text"
                        label="Tenant Email"
                        component="input"
                        placeholder=""
                        className="form-control string required"
                        style={{ width: '200px' }}
                      />
                      {showErrors(errors, 'tenant_email')}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-sm-6 col-lg-4">
              <table className="table table-borderless table-sm details-table">
                <tbody>
                  <tr>
                    <td className="font-weight-bold">
                      Tenant Phone
                    </td>
                  </tr>
                  <tr>
                    <td style={{ lineHeight: 'normal' }}>
                      <Field
                        name="tenant_phone"
                        type="text"
                        label="Tenant Phone"
                        component="input"
                        placeholder=""
                        className="form-control string required"
                        style={{ width: '200px' }}
                      />
                      {showErrors(errors, 'tenant_phone')}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="form-group row">
            <div className="col-sm-9">
              <input
                type="submit"
                id="workOrderEditFormSubmitBtn"
                value={saving ? 'Saving...' : 'Save Work Order'}
                className="btn btn-success"
              />
              <a className="btn btn-inverse" onClick={() => toggleEdit(false)}>Cancel</a>
            </div>
          </div>
        </form>
      )}
    />
  </Card>

);

export default EditWorkOrderDetailsForm;
