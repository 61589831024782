import React from 'react';
import PropTypes from 'prop-types';
import TeamMembers from './TeamMembers';
import Toast from './Toast';
import logo from '../../../assets/images/logo.png';
import { eventObject } from '../../helpers/propTypes';

const Header = ({ recentlyOnline, toastVisible }) => (
  <div className="navbar" style={{ width: '100%' }}>
    <div className="navbar-brand">
      <a href="/projects">
        <img height="40" src={logo} />
      </a>
    </div>
    <div className="navbar-nav mr-auto">
      <Toast
        toastVisible={toastVisible}
        key={JSON.stringify(toastVisible) || 'empty'}
      />
    </div>
    <TeamMembers recentlyOnline={recentlyOnline} />
  </div>
);

Header.propTypes = {
  recentlyOnline: PropTypes.arrayOf(PropTypes.object).isRequired,
  toastVisible: eventObject,
};

Header.defaultProps = {
  toastVisible: null,
};

export default Header;
