import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getRecord, saveRecord } from 'redux-json-api-module';

const ServiceRow = ({
  service,
  saveRecord,
}) => {
  const handleChange = (event) => {
    const record = {
      type: 'services',
      id: service.id,
      attributes: {
        track_inventory: event.target.checked,
      },
    };

    saveRecord(record, { params: { window_id: window.WINDOW_ID } });
  };

  return (
    <tr>
      <td className="text-center">
        <input
          id={`service-track-inventory-${service.id}`}
          type="checkbox"
          name="track_inventory"
          checked={service.attributes.track_inventory}
          onChange={handleChange}
        />
      </td>
      <td>
        {service.attributes.name}
      </td>
    </tr>
  );
};

ServiceRow.propTypes = {
  service: PropTypes.object.isRequired,
  saveRecord: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  service: getRecord(state.Api, { type: 'services', id: ownProps.serviceId }),
});

const mapDispatchToProps = {
  saveRecord,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ServiceRow);
