import get from 'lodash.get';

function getNumber(str) {
  const matches = str.match(/[0-9]+/g);
  if (!matches) return null;
  return matches.map(n => parseInt(n))[0];
}

function getString(str, number) {
  if (!str || !number) return null;
  const parts = str.split(number.toString());
  const remainder = parts[parts.length - 1];
  if (!remainder) return null;
  return remainder.replace(/\s/g, '');
}

export const unitAttributeSort = (a, b) => {
  const aNumber = getNumber(a);
  const bNumber = getNumber(b);

  if (aNumber === null || bNumber === null) {
    return (aNumber === null) - (bNumber === null);
  }

  const aString = getString(a, aNumber);
  const bString = getString(b, bNumber);

  if (aNumber === bNumber) {
    return (aString === null) - (bString === null) || (
      (`${aString}`).localeCompare(bString)
    );
  }
  return aNumber - bNumber;
};

export const directionalUnitAttributeSort = (a, b, direction) => (direction === 'desc' ? unitAttributeSort(b, a) : unitAttributeSort(a, b));

export const unitNumberSort = (a, b, direction) => {
  const aValue = get(a, 'attributes.unit_number', '');
  const bValue = get(b, 'attributes.unit_number', '');

  return directionalUnitAttributeSort(aValue, bValue, direction);
};
