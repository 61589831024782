import React from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab, Card } from 'react-bootstrap';
import InventoryTab from './InventoryTab';
import ServicesTab from './ServicesTab';
import LaborTab from './LaborTab';
import PartsUsageTab from './PartsUsageTab';
import './InventoryContainer.scss';

const InventoryContainer = () => (
  <Card className="inventory-tabs">
    <Tabs defaultActiveKey="inventory">
      <Tab eventKey="inventory" title="Inventory">
        <InventoryTab />
      </Tab>
      <Tab eventKey="partsUsage" title="Parts Usage">
        <PartsUsageTab />
      </Tab>
      <Tab eventKey="services" title="Services">
        <ServicesTab />
      </Tab>
      <Tab eventKey="labor" title="Labor">
        <LaborTab />
      </Tab>
    </Tabs>
  </Card>
);

InventoryContainer.propTypes = {};

const mapStateToProps = () => ({});

export default connect(
  mapStateToProps,
)(InventoryContainer);
