import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRelationship } from 'redux-json-api-module';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import get from 'lodash.get';
import Badge from '../../../components/Badge';

const TaskPendingImagesWarning = ({ service, userName }) => (
  <div>
    <OverlayTrigger
      overlay={(
        <Tooltip>
          {`Completion images pending upload by ${userName}`}
        </Tooltip>
      )}
    >
      <label>
        &nbsp;
        <Badge
          style={{
            fontSize: '14px',
            marginTop: '3px',
          }}
          {...service.attributes}
          label={service.attributes.name}
        />
      </label>
    </OverlayTrigger>
  </div>
);

TaskPendingImagesWarning.propTypes = {
  service: PropTypes.object.isRequired,
  userName: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const user = getRelationship(state.Api, ownProps.task.relationships.user);
  const userName = get(user, 'attributes.name', 'assigned user');

  return {
    service: getRelationship(state.Api, ownProps.task.relationships.service),
    userName,
  };
};

export default connect(mapStateToProps)(TaskPendingImagesWarning);
