import React from 'react';
import PropTypes from 'prop-types';
import COLORS from '../../helpers/colors';
import { idType } from '../../helpers/propTypes';

const CommunityHeader = ({ community }) => (
  <div
    className="mt-2 mb-1 w-100 text-left"
    style={{ color: COLORS.grey, marginLeft: '23px' }}
  >
    <div className="row" style={{ height: '20px' }}>
      <div className="col pl-0 my-auto">
        <div
          className="pl-0"
        >
          {community.name}
        </div>
      </div>
    </div>
  </div>
);

CommunityHeader.propTypes = {
  community: PropTypes.shape({
    id: idType.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default CommunityHeader;
