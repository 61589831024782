import React from 'react';
import PropTypes from 'prop-types';

const ManagementNotes = ({ task }) => (
  <div className="m-b-25">
    <div className="row ml-0">
      <h4 className="form-header">
        Service Notes
      </h4>
    </div>
    <div className="row">
      <div className="col col-lg-8">
        <p className="font-italic">
          {task.attributes.management_notes || 'No service notes'}
        </p>
      </div>
    </div>
  </div>
);

ManagementNotes.propTypes = {
  task: PropTypes.object.isRequired,
};

export default ManagementNotes;
