const validate = (values) => {
  const errors = {};

  if (!values.item || !values.item.value) {
    errors.item = 'Required';
  }

  if (values.is_inventory) {
    if (!values.quantity) {
      errors.quantity = 'Required';
    }

    if (!values.supplier) {
      errors.supplier = 'Required';
    }

    if (!values.total) {
      errors.total = 'Required';
    }
  }

  return errors;
};

export default validate;
