import React from 'react';
import PropTypes from 'prop-types';

const WorkOrderTableHeaderComponent = ({ aasmState }) => (
  <tr>
    <th>Service</th>
    <th width="25%">Assigned To</th>
    {['active', 'completed', 'review'].includes(aasmState) && <th>Status</th>}
    <th>Schedule</th>
    <th>Done</th>
  </tr>
);

WorkOrderTableHeaderComponent.propTypes = {
  aasmState: PropTypes.string.isRequired,
};

export default WorkOrderTableHeaderComponent;
