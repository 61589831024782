import React from 'react';
import PropTypes from 'prop-types';
import doneIcon from '../../assets/images/icon/checkMarkGreen.png';
import RipplesRed from './Animations/RipplesRed';

const Icon = ({ icon, iconLeft }) => {
  switch (icon) {
    case 'icon/checkMarkGreen.png':
      return (
        <img
          src={doneIcon}
          alt="Done Icon"
          className={iconLeft ? 'badge-image-left' : 'badge-image'}
        />
      );
    case 'warning':
      return <i className={`fa fa-warning ${iconLeft ? 'badge-image-left' : 'badge-image'}`} />;
    default:
      return null;
  }
};

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  iconLeft: PropTypes.bool,
};

Icon.defaultProps = {
  iconLeft: false,
};

const styles = {
  badge: (
    {
      backgroundColor,
      textColor: color,
      borderColor,
    },
  ) => ({
    backgroundColor,
    color,
    border: '1px solid',
    borderColor,
  }),
};

const Badge = (
  {
    label,
    background_color: backgroundColor,
    text_color: textColor,
    border_color: borderColor,
    style,
    className,
    icon,
    iconLeft,
    name,
    lateNotification,
  },
) => {
  const iconComponent = icon ? <Icon icon={icon} iconLeft={iconLeft} /> : null;

  // to make all badges have same size (including non status badges)
  const borderWithDefault = borderColor || backgroundColor;

  return (
    <span
      className={`badge ${className} position-relative`}
      style={Object.assign(styles.badge({
        backgroundColor,
        textColor,
        borderColor: borderWithDefault,
      }), style)}
    >
      {iconComponent && iconLeft ? iconComponent : null}
      {label || name}
      {iconComponent && !iconLeft ? iconComponent : null}
      {lateNotification ? (
        <div className="position-absolute" style={{ top: -10, right: -10 }}>
          <RipplesRed />
        </div>
      ) : null}
    </span>
  );
};

Badge.propTypes = {
  label: PropTypes.string,
  background_color: PropTypes.string,
  text_color: PropTypes.string,
  icon: PropTypes.string,
  border_color: PropTypes.string,
  style: PropTypes.objectOf(PropTypes.any),
  className: PropTypes.string,
  iconLeft: PropTypes.bool,
  name: PropTypes.string,
  lateNotification: PropTypes.bool
};

Badge.defaultProps = {
  label: null,
  style: {},
  icon: undefined,
  background_color: '#ffffff',
  border_color: undefined,
  className: '',
  iconLeft: false,
  name: '',
  text_color: '#000000',
  lateNotification: false,
};

export default Badge;
