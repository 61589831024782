import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import COLORS from '../../helpers/colors';
import { matchObject } from '../../helpers/propTypes';

const styles = {
  container: {
    position: 'absolute',
    zIndex: '999',
    width: '90%',
    backgroundColor: 'white',
    border: '1px solid rgba(0,0,0,0.15)',
    maxHeight: '270px',
  },
  button: {
    position: 'absolute',
    top: 0,
    right: 5,
    fontSize: '20px',
  },
  list: {
    listStyle: 'none',
    padding: 0,
    maxHeight: '265px',
    overflowY: 'scroll',
  },
  listElement: {
    cursor: 'pointer',
    marginLeft: '12px',
    marginRight: '35px',
    marginTop: '8px',
    paddingTop: '8px',
    paddingLeft: '8px',
    borderBottom: '1px solid rgba(0,0,0,0.15)',
    borderRadius: '3px',
  },
};

class ActionPopover extends Component {
  constructor(props) {
    super(props);

    this.popover = null;

    this.state = {
      selected: props.matches[0],
    };
  }

  componentDidMount() {
    window.addEventListener('keydown', this.keyDownHandler);
    window.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.keyDownHandler);
    window.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    const { onCancel } = this.props;
    if (
      this.popover
      && !this.popover.contains(event.target)
      && !['tagging-btn', 'tagging-btn-img'].includes(event.target.id)
    ) {
      onCancel();
    }
  }

  keyDownHandler = (event) => {
    const { matches, onSelect } = this.props;
    const { selected } = this.state;

    if (event.key === 'ArrowDown') {
      matches.forEach((emoji, index) => {
        if (selected === emoji) {
          if (index === matches.length - 1) {
            this.setState({ selected: matches[0] });
          } else {
            this.setState({ selected: matches[index + 1] });
          }
        }
      });
    } else if (event.key === 'ArrowUp') {
      matches.forEach((emoji, index) => {
        if (selected === emoji) {
          if (index === 0) {
            this.setState({ selected: matches[matches.length - 1] });
          } else {
            this.setState({ selected: matches[index - 1] });
          }
        }
      });
    } else if (event.key === 'Enter' || event.key === 'Tab') {
      event.preventDefault();
      onSelect(selected);
    }
  };

  render() {
    const { matches, onSelect, onCancel, display } = this.props;
    const { selected } = this.state;

    const top = Math.min(30 * matches.length + 25, 270);

    return (
      <div
        style={{
          ...styles.container,
          top: `-${top}px`,
        }}
        id="action-selector"
        ref={el => (this.popover = el)}
      >
        <Button
          id="close-action-selector"
          variant="link"
          style={styles.button}
          onClick={onCancel}
        >
          X
        </Button>
        <ul style={styles.list}>
          {matches.map(match => (
            <li
              id={`tagging-option-${match.id || match.key}`}
              style={{
                ...styles.listElement,
                ...(selected === match ? {
                  backgroundColor: COLORS.orange,
                  color: COLORS.white,
                } : {}),
              }}
              onClick={() => onSelect(match)}
              key={match.key || match.id}
            >
              {display(match)}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

ActionPopover.propTypes = {
  matches: PropTypes.arrayOf(matchObject).isRequired,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  display: PropTypes.func.isRequired,
};

export default ActionPopover;
