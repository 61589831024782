import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteRecord } from 'redux-json-api-module';
import { Button } from 'react-bootstrap';

const ItemDeleteButton = ({ item, deleteRecord }) => {

  const onClick = () => {
    if (window.confirm('Are you sure you want to delete this item. This action cannot be undone.')) {
      deleteRecord(item);
    }
  };

  return (
    <Button
      onClick={onClick}
      variant="link"
    >
      <i className="fa fa-trash" />
    </Button>
  );
};

ItemDeleteButton.propTypes = {};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  deleteRecord,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ItemDeleteButton);
