import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { getRecord, getRelationship } from 'redux-json-api-module';
import get from 'lodash.get';
import SchedulerForm from './SchedulerForm';
import { getUpdatedDates, MIN_TIME, MAX_TIME } from '../../../../components/GenericScheduler/helpers';
import { getCompositeId } from '../../../TeamScheduleContainer/Schedule/helpers';


const getFormValues = (attributes) => {
  const start = moment(attributes.started_at).isValid() ? moment(attributes.started_at) : moment();
  const end = moment(attributes.ended_at).isValid() ? moment(attributes.ended_at) : moment();

  return {
    includeTime: !attributes.all_day,
    startDate: start
      .startOf('day')
      .toDate(),
    endDate: end
      .startOf('day')
      .toDate(),
    startTime: start
      .toDate(),
    endTime: end
      .toDate(),
  };
};

class ScheduleFormWrapper extends Component {
  static propTypes = {
    scheduled: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onCloseForm: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    user: PropTypes.object,
    temporaryEvents: PropTypes.object.isRequired,
    minDate: PropTypes.string.isRequired,
  }

  static defaultProps = {
    scheduled: null,
    user: null,
  }

  getInitialValues = () => {
    const { scheduled, temporaryEvents } = this.props;
    const temporaryEvent = temporaryEvents[getCompositeId(scheduled)];
    let combined;
    if (temporaryEvent) {
      combined = {
        ...scheduled.attributes,
        ...getUpdatedDates({
          scheduled,
          start: temporaryEvent.start,
          end: temporaryEvent.end,
        }),
      };
    } else {
      combined = { ...scheduled.attributes };
      if (scheduled.attributes.started_at) {
        combined.start = scheduled.attributes.started_at;
      }
      if (scheduled.attributes.ended_at) {
        combined.end = scheduled.attributes.ended_at;
      }
    }

    return getFormValues(combined);
  };

  render() {
    const {
      scheduled,
      onSubmit,
      onCloseForm,
      visible,
      user,
      minDate,
    } = this.props;

    return (
      <SchedulerForm
        user={user}
        onSubmit={onSubmit}
        onCancel={onCloseForm}
        visible={visible}
        initialValues={this.getInitialValues()}
        minTime={MIN_TIME}
        maxTime={MAX_TIME}
        key={scheduled.id}
        scheduled={scheduled}
        minDate={minDate}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const scheduled = getRecord(state.Api, ownProps.scheduledRef);
  let user = scheduled ? getRelationship(state.Api, scheduled.relationships.user) : null;
  const { userId } = state.Scheduler;
  if (!user && userId) user = getRecord(state.Api, { type: 'users', id: userId });

  const minDate = get(scheduled, 'attributes.min_date', null);

  return {
    scheduled,
    user,
    minDate,
  };
};

export default connect(mapStateToProps)(ScheduleFormWrapper);
