import React from 'react';
import PropTypes from 'prop-types';
import { InputGroup, FormControl } from 'react-bootstrap';

const PrefixedTextInput = ({ input }) => (
  <InputGroup className="mb-2 mr-sm-2">
    <InputGroup.Prepend>
      <InputGroup.Text>#</InputGroup.Text>
    </InputGroup.Prepend>
    <FormControl placeholder="" onChange={input.onChange} value={input.value} name={input.name} />
  </InputGroup>
);

PrefixedTextInput.propTypes = {
  input: PropTypes.object.isRequired,
};

export default PrefixedTextInput;
