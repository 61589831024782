import React from 'react';
import PropTypes from 'prop-types';
import COLORS from '../helpers/colors';

const SectionHeader = ({ title, style, className }) => (
  <div
    className={className}
    style={{
      color: COLORS.grey,
      ...style,
    }}
  >
    {title}
  </div>
);

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
};

SectionHeader.defaultProps = {
  style: {
    marginLeft: '20px',
  },
  className: 'mt-3 mb-1 pb-1',
};

export default SectionHeader;
