import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveRecord } from 'redux-json-api-module';
import get from 'lodash.get';
import Alert from '../../components/Alert';
import FileImporter from '../WorkOrderImportContainer/FileImporter/FileImporter';
import { saveImport } from '../../redux/modules/inventory';
import ErrorModal from '../WorkOrderImportContainer/ErrorModal/ErrorModal';
import ImportModal from './ImportModal/ImportModal';
import { ATTRIBUTES } from './helpers';
import { snakeCase } from '../WorkOrderImportContainer/helpers';

class InventoryImportContainer extends Component {
  static propTypes = {
    saveRecord: PropTypes.func.isRequired,
    saveImport: PropTypes.func.isRequired,
  };

  state = {
    errorModalVisible: false,
    errors: [],
    importObject: null,
    uploading: false,
  };

  getFormData = (file) => {
    const formData = new FormData();
    formData.append('data[attributes][file]', file);
    const attributeMapping = $('#inventory_attributes')
      .serializeArray();
    attributeMapping.forEach((attr) => {
      formData.append(`data[attributes][${attr.name}]`, attr.value);
    });

    return formData;
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      this.setState({
        errorModalVisible: true,
        errors: [{
          message: 'Incorrect file type, must be .xlsx or .csv',
        }],
      });
    }
    acceptedFiles.forEach((file) => {
      this.setState({ uploading: true });
      const formData = this.getFormData(file);

      fetch('/api/v1/item_imports', {
        method: 'POST',
        body: formData,
      })
        .then(resp => resp.json())
        .then((response) => {
          const previousMapping = get(response, 'data.attributes.column_mapping', {}) || {};
          const visibility = Object.keys(previousMapping).length > 0
            ? {
              reviewVisible: true,
              mappingFormVisible: false,
            }
            : {
              mappingFormVisible: true,
              reviewVisible: false,
            };
          this.setState({
            importObject: response.data,
            ...visibility,
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.setState({ uploading: false });
        });
    });
  };

  saveInventory = () => {
    const { saveImport } = this.props;
    const { importObject } = this.state;

    const rows = get(importObject, 'attributes.rows', []) || [];
    if (rows.find(row => !row.valid)) {
      if (!window.confirm('Inventory with errors will NOT be imported.')) return;
    }

    saveImport(importObject.id);
  };

  closeErrorModal = () => this.setState({ errorModalVisible: false });

  closeMappingModal = () => this.setState({ mappingFormVisible: false });

  closeReviewModal = () => this.setState({ reviewVisible: false });

  closeModals = () => this.setState({
    reviewVisible: false,
    mappingFormVisible: false,
  });

  openColumnMappingForm = () => this.setState({
    reviewVisible: false,
    mappingFormVisible: true,
  });

  saveColumnMapping = (values) => {
    const { importObject } = this.state;
    const { saveRecord } = this.props;

    const columnMapping = {};
    const priorityMapping = {};

    ATTRIBUTES.forEach((attr) => {
      columnMapping[snakeCase(attr)] = values[snakeCase(attr)];
    });

    const record = {
      type: 'item_imports',
      id: importObject.id,
      attributes: {
        skip_last_row: values.skip_last_row,
        header_row: values.header_row,
        column_mapping: columnMapping,
        priority_mapping: priorityMapping,
      },
    };

    saveRecord(record, { params: { window_id: window.WINDOW_ID } })
      .then((resp) => {
        const data = get(resp, 'payload.data.data', null);

        this.setState({
          reviewVisible: true,
          mappingFormVisible: false,
          importObject: data,
        });
      });
  };

  handleHeaderRowChange = (headerRow) => {
    const { importObject } = this.state;
    const { saveRecord } = this.props;

    const record = {
      type: 'item_imports',
      id: importObject.id,
      attributes: {
        header_row: headerRow,
      },
    };

    saveRecord(record, { params: { window_id: window.WINDOW_ID } })
      .then((resp) => {
        const data = get(resp, 'payload.data.data', null);

        this.setState({
          importObject: data,
        });
      });
  };

  stopPropagation = e => e.stopPropagation();

  render() {
    const {
      errorModalVisible,
      errors,
      mappingFormVisible,
      importObject,
      reviewVisible,
      uploading,
    } = this.state;

    return (
      <div className="card">
        <div className="card-body">
          <Alert
            type="info"
            iconClassName="fa fa-exclamation-circle"
            title="Import Inventory"
          >
            - To add inventory, such as a rent roll of properties, click "Download Sample
            Excel Sheet", add your inventory to this sheet,
            <br />
            &nbsp;&nbsp;&nbsp;and then click "Import Inventory" to upload your file.
          </Alert>

          <div className="font-weight-bold">Step 1</div>
          <div className="mb-3">
            Download your property management system's Rent Roll or Address List
            report.
          </div>

          <div className="font-weight-bold">Step 2</div>
          <div className="mb-3">
            Add Inventory to the Sample Excel Sheet, ensuring your
            columns are labeled like the Sample.
            <span className="font-weight-bold">&nbsp;&nbsp;Save your report in .xlsx format.</span>
          </div>

          <div className="font-weight-bold">Step 3</div>
          <div className="mb-3">
            Upload your file, and you can then view inventory in Intimely.
          </div>

          <FileImporter onDrop={this.onDrop} uploading={uploading} />

          <div className="font-weight-bold">
            Settings
          </div>
          <hr className="mt-1" />
          <div className="mb-4">
            <div className="font-weight-bold">
              Sample Report
            </div>
            <div className="mb-3">
              Use the Sample Excel Sheet to view required columns. You may add new Inventory to this
              sheet and upload it above.
            </div>
            <div>
              <a
                className="btn btn-secondary ml-3"
                href="/intimely_inventory_import_sample_data.xlsx"
                target="_blank"
                rel="noopener nofollow"
                onClick={this.stopPropagation}
              >
                Download Sample Excel Sheet
              </a>
            </div>
          </div>

          <ErrorModal
            errors={errors}
            visible={errorModalVisible}
            closeModal={this.closeErrorModal}
            modelName="inventory"
          />

          {importObject && (mappingFormVisible || reviewVisible) ? (
            <ImportModal
              mappingFormVisible={mappingFormVisible}
              reviewVisible={reviewVisible}
              closeModal={this.closeModals}
              importObject={importObject}
              onSubmitColumnMapping={this.saveColumnMapping}
              onSubmitReview={this.saveInventory}
              onDrop={this.onDrop}
              openColumnMappingForm={this.openColumnMappingForm}
              uploading={uploading}
              onHeaderRowChange={this.handleHeaderRowChange}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = {
  saveRecord,
  saveImport,
};

export default connect(null, mapDispatchToProps)(InventoryImportContainer);
