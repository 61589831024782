import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord, getRelationship } from 'redux-json-api-module';
import { genericRecordPropType, idType } from '../../../helpers/propTypes';
import Avatar from '../../../components/Avatar';

const AvatarCell = ({ user }) => (
  user ? (
    <>
      <Avatar {...user.attributes} />
      <span>
        &nbsp;
        {user.attributes.name}
      </span>
    </>
  ) : 'Pending Assignment'
);

AvatarCell.propTypes = {
  taskId: idType.isRequired,
  taskType: PropTypes.string.isRequired,

  // connected
  user: genericRecordPropType,
};

AvatarCell.defaultProps = {
  user: null,
};

const mapStateToProps = (state, ownProps) => {
  const task = getRecord(state.Api, { type: ownProps.taskType, id: ownProps.taskId });

  return {
    user: getRelationship(state.Api, task.relationships.user),
  };
};

export default connect(
  mapStateToProps,
)(AvatarCell);
