import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { fetchRecords, getRelationship } from 'redux-json-api-module';
import EventCard from '../../components/EventCard/EventCard';
import Loader from '../../components/Loader';

class ActivityContainer extends Component {
  state = {
    loading: true,
    paginating: false,
    nextPage: 1,
    hasMore: false,
  };

  UNSAFE_componentWillMount() {
    this.fetchEvents('loading');
  }

  fetchEvents = (loadingAttribute) => {
    const { fetchRecords } = this.props;
    const { nextPage } = this.state;

    this.setState({ [loadingAttribute]: true });

    fetchRecords('events', {
      page: {
        size: 20,
        number: nextPage,
      },
      sort: '-events.created_at',
    })
      .then((resp) => {
        if (resp.error) {
          this.setState({
            [loadingAttribute]: false,
          });
          return;
        }
        this.setState({
          [loadingAttribute]: false,
          nextPage: resp.payload.data.meta.next_page,
          hasMore: !!resp.payload.data.meta.next_page,
        });
      });
  };

  paginate = () => {
    this.fetchEvents('paginating');
  };

  render() {
    const { events } = this.props;
    const { loading, paginating, hasMore } = this.state;

    return loading ? <Loader /> : (
      <div className="card">
        <div className="card-body">
          <div className="timeline plan-timeline">
            {events.map((event, index) => (
              <EventCard event={event} key={event.id} index={index} />
            ))}
          </div>
          {hasMore ? (
            <div className="text-center">
              <button
                type="button"
                className="btn btn-primary"
                onClick={this.paginate}
                disabled={paginating}
              >
                Load More Events
              </button>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const events = Object.values(state.Api.events || {})
    .sort((a, b) => -moment(a.attributes.created_at)
      .diff(moment(b.attributes.created_at)));

  return {
    events,
  };
};

const mapDispatchToProps = {
  fetchRecords,
};

ActivityContainer.propTypes = {
  fetchRecords: PropTypes.func.isRequired,
  events: PropTypes.array.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActivityContainer);
