import React from 'react';
import PropTypes from 'prop-types';
import Select, { createFilter } from 'react-select';
import UnitOption from './UnitOption';
import UnitForm from './UnitForm';
import { Error } from '../../containers/ProjectCreateContainer/ProjectCreateForm/inputs';

import './UnitSelector.scss';

class UnitSelector extends React.Component {
  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    stateOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    communityOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
    errors: PropTypes.object,
    input: PropTypes.object.isRequired,
    required: PropTypes.bool,
  };

  static defaultProps = {
    errors: {},
    required: false,
  };

  state = {
    createSelected: false,
  };

  handleChange = (unit) => {
    const { input } = this.props;
    if (unit) {
      this.setState({ createSelected: false });
    }
    input.onChange(unit);
  };

  toggleCreate = (createSelected) => {
    const { input } = this.props;
    input.onChange('');
    this.setState({ createSelected });
  };

  render() {
    const {
      options,
      menuPortalTarget,
      input,
      stateOptions,
      communityOptions,
      errors,
      required,
    } = this.props;
    const { createSelected } = this.state;

    const unitSelectErrors = errors && errors.unit;

    return (
      <React.Fragment>
        {options.length > 0 ? (
          <div className="form-group row">
            <label className="col-sm-3 col-form-label text-md-right">
              Select Unit
              {required ? (
                <>
                  <abbr title="required">*</abbr>
                </>
              ) : null}
            </label>
            <div className="col-sm-9">
              <div className="unit-select__container">
                <Select
                  isDisabled={createSelected}
                  isOptionDisabled={o => o.disabled}
                  classNamePrefix="unit-select"
                  className="unit-select"
                  value={input.value}
                  components={{ Option: UnitOption }}
                  onChange={this.handleChange}
                  styles={{
                    menuPortal: (base) => {
                      const { zIndex, ...rest } = base;
                      return {
                        ...rest,
                        zIndex: 9999,
                      };
                    },
                  }}
                  options={options}
                  placeholder="Select unit"
                  menuPortalTarget={menuPortalTarget}
                  ignoreAccents={false}
                  filterOption={createFilter({
                    ignoreAccents: false,
                    matchFrom: 'any',
                    stringify: option => `${option.label}`,
                  })}
                />
                {unitSelectErrors ? (
                  unitSelectErrors.map(error => <Error text={error} key={error} />)
                ) : null}

              </div>
              <label className="form-label text-md-right">
                <input
                  id="create-unit"
                  type="checkbox"
                  checked={createSelected}
                  style={{
                    marginTop: '10px',
                    marginRight: '5px',
                  }}
                  onChange={() => this.toggleCreate(!createSelected)}
                />
                Create New Unit
              </label>
            </div>
          </div>
        ) : null}

        {createSelected || options.length === 0 ? (
          <UnitForm
            stateOptions={stateOptions}
            errors={errors}
            communityOptions={communityOptions}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default UnitSelector;
