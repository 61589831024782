import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { fetchRecord, fetchRecords } from 'redux-json-api-module';
import toastr from 'toastr';
import Dropzone from 'react-dropzone';
import { getItemOptions } from './helpers';
import validate from './validate';
import { CustomInput, SelectInput } from './inputs';
import ServiceInput from './ServiceInput';
import axios from "axios";
import { TYPE_MAP } from "../../helpers/types";

const CUSTOM_OPTION = [['Custom', 'custom']];

const PARENT_INCLUDES = {
  features: 'feature',
  work_order_features: 'work_order_feature',
}

const initialValues = {
  item: null,
  name: null,
  description: null,
  service_id: null,
};

const ItemField = ({ fieldName, isConcatCustomOptions, areas = [], items }) => {
  const options = areas
    .flatMap(area => getItemOptions(items, area))
    .concat(isConcatCustomOptions ? CUSTOM_OPTION : []);

  return (
    <Field
      name={fieldName}
      component={SelectInput}
      label="Please select an Area/Room/Category - Item/Amenity"
      options={options}
      includeBlank="Select Area - Item"
    />
  );
};

const FeatureForm = (
  {
    areas,
    descriptions,
    items,
    services,
    onSubmit,
    onCancel,
    fetchRecord,
    fetchRecords,
    initialValues,
  },
) => {
  const [submitReset, setSubmitReset] = useState(false);
  const [showShortcutField, setShowShortcutField] = useState(false);
  const [images, setImages] = useState([]);

  const deleteImage = (index) => {
    const newImages = [...images];
    newImages.splice(index, 1);
    setImages(newImages);
  };

  const uploadImages = (feature) => {
    const promises = [];
    images.forEach((image, i) => {
      const formData = new FormData();
      formData.append('data[attributes][file]', image);
      formData.append('data[attributes][position]', i);
      formData.append('data[attributes][feature_type]', TYPE_MAP[feature.type]);
      formData.append('data[attributes][feature_id]', feature.id);

      promises.push(
        axios.post('/api/v1/feature_images', formData, {
          params: {
            include: 'feature',
          },
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }),
      );
    });

    return Promise.all(promises);
  };

  const handleSubmit = async (values, form) => {
    const isCustomItem = values.areaItem === 'custom';
    const result = {
      area: values.areaItem?.area ?? values.areaItem,
      item_id: values.areaItem?.id ?? values.areaItem,
      name: isCustomItem ? values.name : null,
      description: values.description === 'custom' ? values.custom_description : values.description,
      service_id: values.service_id,
    };

    if (values.shortcut_name && values.shortcut_name !== '') {
      result.shortcut_name = values.shortcut_name;
    }

    const resp = await onSubmit(result);

    if (resp.error) {
      const errors = {};

      Object.keys(resp.error.response.data).forEach((k) => {
        [errors[k]] = resp.error.response.data[k];
      });

      if (resp.error.response.data.area) {
        [errors.areaItem] = resp.error.response.data.area;
      } else if (resp.error.response.data.item_id) {
        [errors.areaItem] = resp.error.response.data.item_id;
      }

      toastr.error('Item could not be created.');
      return errors;
    }

    toastr.success('Item created successfully.');

    if (values.shortcut_name && values.shortcut_name !== '') {
      fetchRecords('feature_shortcuts');
    }

    const feature = resp.payload.data.data;
    await uploadImages(feature);
    fetchRecord(feature.type, feature.id);

    form.reset();
    if (!submitReset) onCancel();

    return undefined;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      initialValues={initialValues}
      render={({ handleSubmit, form, values, submitting }) => (
        <form id="featureForm" onSubmit={handleSubmit}>
          <ItemField
            fieldName="areaItem"
            isConcatCustomOptions={values.areaItem !== 'custom'}
            areas={areas}
            items={items}
          />

          {values.areaItem === 'custom' ? (
            <Field name="name" component={CustomInput} placeholder="Custom name" />
          ) : null}

          <Field
            name="description"
            component={SelectInput}
            label="Please describe the service issue"
            options={descriptions.concat(CUSTOM_OPTION)}
            includeBlank="Select Description"
          />

          {values.description === 'custom' ? (
            <Field
              name="custom_description"
              component={CustomInput}
              placeholder="Custom description"
            />
          ) : null}

          <Field
            name="service_id"
            component={ServiceInput}
            label="Please select a Service to complete this issue"
            options={services.sort()}
            includeBlank="Select Service"
          />

          <label htmlFor="saveAsShortcut">
            <input
              type="checkbox"
              onChange={() => setShowShortcutField(!showShortcutField)}
              defaultChecked={showShortcutField}
              className="mr-2"
              id="saveAsShortcut"
            />
            Save As Custom Shortcut?
          </label>

          {showShortcutField ? (
            <Field name="shortcut_name" component={CustomInput} placeholder="Shortcut Name" />
          ) : null}

          <Dropzone onDrop={files => setImages(files)}>
            {({ getRootProps, getInputProps, isDragActive }) => (
              <div
                {...getRootProps()}
                className={`dropzone ${isDragActive ? 'dropzone--isActive' : null}`}
              >
                <input
                  name="data[attributes][feature_images_attributes][]"
                  {...getInputProps()}
                />

                {
                  isDragActive ? (
                    <p className="dropzone-instructions">Drop files here...</p>
                  ) : (
                    <p className="dropzone-instructions">
                      Drag Files Here
                      &nbsp;&nbsp;
                      OR
                      &nbsp;&nbsp;&nbsp;
                      <button className="btn btn-secondary" type="button">
                        Click Here to Choose
                      </button>
                    </p>
                  )
                }
              </div>
            )}
          </Dropzone>

          {images.length > 0 && (
            <div className="table-responsive">
              <table className="table table-attachments vertical-middle">
                <thead>
                <tr>
                  <th width="15%">File</th>
                  <th width="5%" />
                </tr>
                </thead>
                <tbody>
                {images.map((image, i) => (
                  <tr key={i}>
                    <td className="file-name">
                      <img
                        src={URL.createObjectURL(image)}
                        alt="upload preview"
                        className="mw-100"
                      />
                    </td>
                    <td>
                      <button
                        type="button"
                        title="Delete Attachment"
                        className="btn btn-link text-dark"
                        onClick={() => deleteImage(i)}
                      >
                        <i className="fa fa-trash" />
                      </button>
                    </td>
                  </tr>
                ))}
                </tbody>
              </table>
            </div>
          )}

          <div className="text-center mt-3">
            <button
              type="button"
              disabled={submitting}
              className="btn btn-secondary mr-1"
              onClick={onCancel}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={submitting}
              className="btn btn-success mr-1"
              onClick={() => setSubmitReset(true)}
            >
              Add Another Item
            </button>
            <button
              type="submit"
              disabled={submitting}
              className="btn btn-primary"
              onClick={() => setSubmitReset(false)}
            >
              Save Service
            </button>
          </div>
        </form>
      )}
    />
  );
};

FeatureForm.propTypes = {
  areas: PropTypes.arrayOf(PropTypes.string).isRequired,
  descriptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  items: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.any)).isRequired,
  services: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)).isRequired,
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  onCancel: PropTypes.func.isRequired,

  // connected
  fetchRecords: PropTypes.func.isRequired,
};

FeatureForm.defaultProps = {
  initialValues,
};

const mapDispatchToProps = {
  fetchRecords,
  fetchRecord,
};

export default connect(null, mapDispatchToProps)(FeatureForm);
