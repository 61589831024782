import React from 'react';
import PropTypes from 'prop-types';
import CompletedServices from './CompletedServices';

const ServicesCell = ({ plan }) => (
  <CompletedServices plan={plan} />
);

ServicesCell.propTypes = {
  plan: PropTypes.object.isRequired,
};

export default ServicesCell;
