import React from 'react';
import Placeholder from '../../../assets/images/image-placeholder.png';

const ImagePlacholder = () => (
  <div className="image-placeholder-container">
    <img src={Placeholder} className="image-placeholder" alt="placeholder" />
  </div>
);

export default ImagePlacholder;
