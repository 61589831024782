import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Card } from '../../../components/Card';
import Badge from '../../../components/Badge';
import COLORS from '../../../helpers/colors';
import editIcon from '../../../../assets/images/icon/edit-icon.png';
import { capitalize } from '../../../helpers/string';

const WorkOrderDetails = ({
  priorityBadge,
  recurring,
  startRecurringOn,
  stopRecurringOn,
  recurringFrequency,
  permissionToEnter,
  description,
  additionalEntryNotes,
  workOrderNumber,
  toggleEdit,
  aasmState,
  tenantName,
  tenantEmail,
  tenantPhone,
}) => (
  <Card className="summary">
    <h4 className="card-title">
      Work Order Details
      {aasmState !== 'completed' ? (
        <a
          id="edit-button"
          className="btn btn-link float-right"
          style={{
            position: 'relative',
            top: '-8px',
          }}
        >
          <img
            src={editIcon}
            alt="Edit"
            width={25}
            onClick={() => toggleEdit(true)}
          />
        </a>
      ) : null}
    </h4>
    <hr />
    <div className="row">
      <div className="col-sm-6 col-lg-4">
        <table className="table table-borderless table-sm details-table">
          <tbody>
            <tr>
              <td className="font-weight-bold">
                Work Order #
              </td>
              <td className="font-weight-bold">
                Priority
              </td>
            </tr>
            <tr>
              <td>
                {workOrderNumber || 'None'}
              </td>
              <td>
                <Badge {...priorityBadge} />
              </td>
            </tr>
            <tr>
              <td className="font-weight-bold">
                Work Order Description
              </td>
            </tr>
            <tr>
              <td style={{ lineHeight: 'normal' }}>
                {description}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-sm-6 col-lg-4">
        <table className="table table-borderless table-sm details-table">
          <tbody>
            <tr>
              <td className="font-weight-bold">
                Permission to Enter
              </td>
            </tr>
            <tr>
              <td style={permissionToEnter ? {} : {
                color: COLORS.redDark,
                fontWeight: 'bold',
              }}
              >
                {permissionToEnter ? 'Yes' : 'No'}
              </td>
            </tr>
            <tr>
              <td className="font-weight-bold">
                Additional Entry Notes
              </td>
            </tr>
            <tr>
              <td style={{ lineHeight: 'normal' }}>
                {additionalEntryNotes}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {recurring ? (
        <div className="col-sm-6 col-lg-4">
          <table className="table table-borderless table-sm details-table">
            <tbody>
              <tr>
                <td className="font-weight-bold">
                  Start / Stop Repeating On
                </td>
              </tr>
              <tr>
                <td>
                  {`${moment(startRecurringOn)
                    .format('MM/DD/YY')} / ${moment(stopRecurringOn)
                    .format('MM/DD/YY')}`}
                </td>
              </tr>
              <tr>
                <td className="font-weight-bold">
                  Repeat Work Order
                </td>
              </tr>
              <tr>
                <td className="text-capitalize">
                  {recurringFrequency}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
    <div className="row">
      <div className="col-sm-6 col-lg-4">
        <table className="table table-borderless table-sm details-table">
          <tbody>
            <tr>
              <td className="font-weight-bold">
                Tenant Name
              </td>
            </tr>
            <tr>
              <td style={{ lineHeight: 'normal' }}>
                {tenantName}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-sm-6 col-lg-4">
        <table className="table table-borderless table-sm details-table">
          <tbody>
            <tr>
              <td className="font-weight-bold">
                  Tenant Email
              </td>
            </tr>
            <tr>
              <td style={{ lineHeight: 'normal' }}>
                {tenantEmail}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-sm-6 col-lg-4">
        <table className="table table-borderless table-sm details-table">
          <tbody>
            <tr>
              <td className="font-weight-bold">
                  Tenant Phone
              </td>
            </tr>
            <tr>
              <td style={{ lineHeight: 'normal' }}>
                {tenantPhone}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </Card>
);

const mapStateToProps = (state, ownProps) => {
  const {
    priority_badge: priorityBadge,
    recurring,
    start_recurring_on: startRecurringOn,
    stop_recurring_on: stopRecurringOn,
    recurring_frequency: recurringFrequency,
    permission_to_enter: permissionToEnter,
    description,
    additional_entry_notes: additionalEntryNotes,
    work_order_number: workOrderNumber,
    aasm_state: aasmState,
    tenant_name: tenantName,
    tenant_email: tenantEmail,
    tenant_phone: tenantPhone,
  } = ownProps.workOrder.attributes;


  return {
    priorityBadge,
    recurring,
    startRecurringOn,
    stopRecurringOn,
    recurringFrequency,
    permissionToEnter,
    description,
    additionalEntryNotes,
    workOrderNumber,
    aasmState,
    tenantName,
    tenantEmail,
    tenantPhone,
  };
};

export default connect(
  mapStateToProps,
)(WorkOrderDetails);
