import React from 'react';
import { connect } from 'react-redux';
import LaborTable from './LaborTable';
import Alert from '../../components/Alert';


const LaborTab = () => (
  <div>
    <Alert
      type="info"
      iconClassName="fa fa-exclamation-circle"
      title="Set Labor Rates"
    >
        You may set labor rates for team members below. If they submit their Labor Time (hours/minutes) when completing assigned services, this labor rate will be used to calculate the Total Labor on the Service Invoice. Alternatively, team members may submit a Flat Fee when completing assigned services, and that flat fee will be displayed on the Service Invoice.
    </Alert>

    <LaborTable />
  </div>
);

LaborTab.propTypes = {};

const mapStateToProps = () => ({});

export default connect(
  mapStateToProps,
)(LaborTab);
