import React from 'react';
import PropTypes from 'prop-types';
import ChatHeader from './ChatHeader';
import withLayout from './withLayout';

const ChatLayoutContainer = ({ toastVisible }) => (
  <ChatHeader toastVisible={toastVisible} />
);

ChatLayoutContainer.propTypes = {
  toastVisible: PropTypes.bool,
};

ChatLayoutContainer.defaultProps = {
  toastVisible: false,
};

export default withLayout(ChatLayoutContainer);
