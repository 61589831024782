import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';
import WorkOrderImportManagementContainer
  from '../containers/WorkOrderImportManagementContainer/WorkOrderImportManagementContainer';

const WorkOrderImportManagement = props => (
  <Provider store={store}>
    <WorkOrderImportManagementContainer {...props} />
  </Provider>
);

export default WorkOrderImportManagement;
