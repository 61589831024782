import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ChatTab from './ChatTab';
import '../../components/ServiceDetails/ServiceTabs.scss';
import { teamObject, idType } from '../../helpers/propTypes';
import EditChannelButton from './EditChannelButton';

const ChatOnlyTabs = ({
  team,
  userId,
  autoFocus,
  activeChannelId,
}) => (
  <div id="service-tab" className="animated fadeIn full-tabs">
    <Tabs
      unmountOnExit
      id={activeChannelId ? 'chat-only-full-tabs' : 'full-tabs'}
    >
      <Tab
        eventKey="chat"
        title={(
          <span style={{ padding: 0 }}>
            Chat
            <EditChannelButton />
          </span>
        )}
      >
        <ChatTab
          team={team}
          userId={userId}
          autoFocus={autoFocus}
        />
      </Tab>
    </Tabs>
  </div>
);

ChatOnlyTabs.propTypes = {
  team: teamObject,
  userId: PropTypes.string.isRequired,
  autoFocus: PropTypes.bool,
  activeChannelId: idType,
};

ChatOnlyTabs.defaultProps = {
  activeChannelId: null,
};

const mapStateToProps = state => ({
  activeChannelId: state.Chat.activeChannelId,
});

export default connect(mapStateToProps)(ChatOnlyTabs);
