import React from 'react';
import { connect } from 'react-redux';
import { sendProjectServiceCompletionReports } from '../../redux/modules/projectManagement';
import ServiceCompletionReportDownload
  from '../../containers/ProjectManagementContainer/ServiceCompletionReportDownload/ServiceCompletionReportDownload';

const formatReport = (recipients, serviceIds) => ({
  project: {
    completion_reports: {
      recipients,
      project_service_ids: serviceIds,
    },
  },
});

const ProjectServiceCompletionReportDownload = props => (
  <ServiceCompletionReportDownload
    planType="projects"
    taskType="project_services"
    formatReport={formatReport}
    {...props}
  />
);

const mapStateToProps = {};

const mapDispatchToProps = {
  sendTaskCompletionReports: sendProjectServiceCompletionReports,
};

export default connect(null, mapDispatchToProps)(ProjectServiceCompletionReportDownload);
