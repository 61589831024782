const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const URL_REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
const PHONE_REGEX = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/i;

export const presenceValidator = value => (
  value ? undefined : 'Cannot be blank'
);

export const emailValidator = value => (
  EMAIL_REGEX.test(value) ? undefined : 'Invalid email address'
);

export const urlValidator = value => (
  !value || URL_REGEX.test(value) ? undefined : 'Invalid URL (ex: https://artisn.co)'
);

export const phoneValidator = value => (
  !value || PHONE_REGEX.test(value) ? undefined : 'Invalid phone number'
);
