import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { getRecord } from 'redux-json-api-module';
import WorkOrderRow from './WorkOrderRow';

const CommunityCard = ({ community, planIds }) => (
  <div className="card">
    <div className="card-body">

      <h4 className="card-title">{get(community, 'attributes.name', '')}</h4>
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th width="18%">Address</th>
              <th width="10%" className="no-wrap">W/O #</th>
              <th width="10%">Start</th>
              <th width="10%">Stop</th>
              <th width="10%">Frequency</th>
              <th width="30%">Description</th>
              <th width="10%">Priority</th>
              <th width="10%">PTE</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {planIds.map((id, index) => (
              <WorkOrderRow planId={id} key={id} isLast={index === planIds.length - 1} />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

const mapStateToProps = (state, ownProps) => {
  const planIds = state.WorkOrdersRecurring.communityIdMap[ownProps.communityId] || [];
  const community = getRecord(state.Api, {
    type: 'communities',
    id: ownProps.communityId,
  });

  return {
    community,
    planIds,
  };
};

CommunityCard.propTypes = {
  community: PropTypes.object.isRequired,
  planIds: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(CommunityCard);
