import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getRecord } from 'redux-json-api-module';
import { getOtherDMUser } from './helpers';
import Avatar from '../../components/Avatar';

const DirectMessageLabel = ({ user, name }) => (
  <div style={{ width: '20%', whiteSpace: 'nowrap' }}>
    {user ? <Avatar {...user.attributes} className="avatar-xs d-inline-block" /> : null}
    <span>
        &nbsp;
      {name}
    </span>
  </div>
);

DirectMessageLabel.propTypes = {
  user: PropTypes.object,
  name: PropTypes.string,
};

DirectMessageLabel.defaultProps = {
  user: null,
  name: null,
};

const mapStateToProps = (state, ownProps) => {
  const directMessageChat = getRecord(state.Api, { type: 'direct_message_chats', id: state.Chat.activeDirectMessageChatId });
  const { name, otherUserId } = getOtherDMUser(directMessageChat, ownProps.userId, state.Api);
  const user = otherUserId ? getRecord(state.Api, { type: 'user_listings', id: otherUserId }) : null;

  return {
    user,
    name,
  };
};

export default connect(mapStateToProps)(DirectMessageLabel);
