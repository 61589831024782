import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchRecord } from 'redux-json-api-module';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { simpleFormat } from '../../helpers/string';
import ImageUser from './ImageUser';
import ImageBadge from './ImageBadge';
import Pagination from './Pagination';
import './ModalCarousel.scss';

class ModalCarousel extends React.Component {
  static propTypes = {
    images: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    visible: PropTypes.bool.isRequired,
    userId: PropTypes.string,
    startIndex: PropTypes.number,
  };

  static defaultProps = {
    startIndex: 0,
  };

  static getDerivedStateFromProps(props, state) {
    if (props.startIndex === state.startIndex) return null;

    return {
      startIndex: props.startIndex,
      currentIndex: props.startIndex,
    };
  }

  state = {
    startIndex: 0,
    currentIndex: 0,
    page: 1,
  };

  componentDidMount() {
    this.fetchRecords();
  }

  componentDidUpdate() {
    this.fetchRecords();
  }

  fetchRecords = () => {
    const { images } = this.props;
    const { currentIndex } = this.state;
    const image = images[currentIndex];

    if (!image) return;

    if (image.user_id) fetchRecord('users', image.user_id);
    if (image.service_id) fetchRecord('services', image.service_id);
  };

  setCurrentIndex = index => this.setState({
    currentIndex: index,
    page: index + 1,
  });

  prevImage = () => {
    const { images } = this.props;
    const { currentIndex } = this.state;

    this.setCurrentIndex((currentIndex + images.length - 1) % images.length);
  };

  nextImage = () => {
    const { images } = this.props;
    const { currentIndex } = this.state;

    this.setCurrentIndex((currentIndex + 1) % images.length);
  };

  handlePagination = (value) => {
    const { images } = this.props;
    const newState = { page: value || '' };

    if (newState.page !== '') {
      const newIndex = parseInt(value) - 1;
      if (newIndex > 0 && newIndex < images.length) newState.currentIndex = newIndex;
    }

    this.setState(newState);
  };


  render() {
    const { images, onClose, visible } = this.props;
    const { currentIndex, page } = this.state;
    const image = images[currentIndex];

    if (!visible) return null;

    const mainImg = images[currentIndex];
    const nextImg = images[(currentIndex + 1) % images.length];
    const prevImg = images[(currentIndex + images.length - 1) % images.length];

    const pagination = images.length > 1
      ? {
        nextSrc: nextImg.url,
        nextSrcThumbnail: nextImg.placeholder.url,
        prevSrc: prevImg.url,
        prevSrcThumbnail: prevImg.placeholder.url,
      } : {};

    return (
      <Fragment>
        <Lightbox
          mainSrc={mainImg.url}
          mainSrcThumbnail={mainImg.placeholder.url}
          onCloseRequest={onClose}
          onMovePrevRequest={this.prevImage}
          onMoveNextRequest={this.nextImage}
          imageTitle={image.title}
          {...pagination}
          imagePadding={100}
          imageCaption={(
            <div className="d-flex justify-content-between align-items-center">
              <div>
                {image.description ? (
                  <div dangerouslySetInnerHTML={{ __html: simpleFormat(image.description) }} />
                ) : null}

                {image.user_id ? (
                  <div className="d-inline-block mr-3">
                    <ImageUser userId={image.user_id} />
                  </div>
                ) : null}

                {image.service_id ? (
                  <ImageBadge serviceId={image.service_id} style={{ verticalAlign: 'text-top' }} />
                ) : null}
              </div>

              {images.length > 1 ? (
                <Pagination
                  value={currentIndex + 1}
                  length={images.length}
                  onChange={this.handlePagination}
                />
              ) : null}
            </div>
          )}
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = {
  fetchRecord,
};

export default connect(null, mapDispatchToProps)(ModalCarousel);
