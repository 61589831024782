import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import get from 'lodash.get';
import { isBlank } from '../../helpers/string';
import ImageContainer from '../../containers/ActivityContainer/ImageContainer/ImageContainer';
import Avatar from '../Avatar';
import { simplifiedPlanPath } from '../../helpers/routing';

import alertIcon from '../../../assets/images/icon/alert.png';
import checkIcon from '../../../assets/images/icon/checkMarkGreen.png';
import nounAddIcon from '../../../assets/images/icon/noun-add.svg';
import nounCalIcon from '../../../assets/images/icon/noun-calendar.svg';
import nounRenoIcon from '../../../assets/images/icon/noun-renovation.svg';
import nounTransferIcon from '../../../assets/images/icon/noun-transfer-bitcoin.svg';
import TitleSections from './TitleSections';

const actionIconMap = {
  add_service: nounRenoIcon,
  approve_completion_project: checkIcon,
  change_start_date: nounCalIcon,
  complete_service: checkIcon,
  create_project: nounAddIcon,
  due_on_updated: nounCalIcon,
  late_notification_sent: alertIcon,
  reassign_service: nounTransferIcon,
  reschedule_service: nounCalIcon,
};

function stripAddress(title, shortAddress) {
  let strippedTitle = title.replace(`${shortAddress}.`, '').replace(shortAddress, '');
  if (!strippedTitle.endsWith(' in ') && !strippedTitle.endsWith(' at ')) {
    strippedTitle += ' in ';
  }
  return strippedTitle;
}

const EventCard = ({ event, index }) => {
  const { action } = event.attributes;

  return (
    <li className={index % 2 === 1 ? 'timeline-inverted' : ''}>
      <div className="timeline-badge">
        {event.attributes.user ? (
          <Avatar {...event.attributes.user} />
        ) : null}
      </div>
      <div className="timeline-panel">
        <div className="timeline-heading">
          <h5 className="timeline-title">
            {actionIconMap[action] ? (
              <img
                src={actionIconMap[action]}
                alt="event action"
                className={`icon mr-1 ${actionIconMap[action] === checkIcon ? 'icon-check' : ''}`}
              />
            ) : null}
            <TitleSections
              taggedTitle={stripAddress(event.attributes.tagged_title, event.attributes.unit_short_address)}
              serviceBadges={event.attributes.service_badges}
            />
            <a href={simplifiedPlanPath(
              event.attributes.project_aasm_state,
              get(event, 'relationships.plan.data.type', null),
              get(event, 'relationships.plan.data.id', null),
            )}
            >
              {event.attributes.unit_short_address}
            </a>
          </h5>
          <small>
            {moment(event.attributes.created_at)
              .format('MM/DD [at] hh:mm a')}
          </small>
        </div>
        <div className="timeline-body">
          {event.attributes.images.length > 0 && (
            <ImageContainer
              event={event}
              images={event.attributes.images}
            />
          )}
          {!isBlank(event.attributes.subtitle) && (
            <div className="row">
              <div className="m-l-10">
                {event.attributes.subtitle}
              </div>
            </div>
          )}
        </div>
      </div>
    </li>
  );
};

EventCard.propTypes = {
  event: PropTypes.object.isRequired,
  index: PropTypes.number,
};

EventCard.defaultProps = {
  index: 0,
};

export default EventCard;
