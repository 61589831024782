import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Avatar from '../../components/Avatar';

const CurrentUser = ({ currentUser, avatarClass }) => {
  return (
    <li className="nav-item dropdown u-pro">
      <a
        aria-expanded="false"
        aria-haspopup="true"
        className="nav-link dropdown-toggle waves-effect waves-dark pr-0"
        data-toggle="dropdown"
        href=""
      >
        <Avatar {...currentUser.attributes} className={avatarClass} />
        <span className="hidden-md-down font-14">
        &nbsp;
          {currentUser.attributes.name}
          &nbsp;
          <i className="fa fa-angle-down" />
      </span>
      </a>
      <div className="dropdown-menu dropdown-menu-right">
        <a className="dropdown-item" href="/users/edit">
          <i className="fa fa-user fa-fw" />
          My Profile
        </a>
        <a className="dropdown-item" href="/subscriptions">
          <i className="fa fa-credit-card fa-fw" />
          Manage Company
        </a>
        <a className="dropdown-item" href="/users/sign_out">
          <i className="fa fa-power-off fa-fw" />
          Logout
        </a>
      </div>
    </li>
  );
}

CurrentUser.propTypes = {
  currentUser: PropTypes.object.isRequired,
  avatarClass: PropTypes.string,
};

CurrentUser.defaultProps = {
  avatarClass: null,
};

const mapStateToProps = state => ({
  currentUser: state.Auth.currentUser,
});

export default connect(
  mapStateToProps,
)(CurrentUser);
