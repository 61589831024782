import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord } from 'redux-json-api-module';
import moment from 'moment';

import get from 'lodash.get';
import { Button } from 'react-bootstrap';
import Message from './Message';
import ReverseScrollList from './ReverseScrollList';
import UnreadBreak from './UnreadBreak';
import { idType } from '../../helpers/propTypes';
import { touch, getMessages } from '../../redux/modules/chat';
import { messageSort } from '../../helpers/sorts';

function isUnread(message, viewedAt, lastLoadedCreatedAt) {
  if (!viewedAt) return false;
  const createdAt = get(message, 'attributes.created_at', null);
  if (!createdAt) return false;

  const viewed = get(message, 'attributes.default_viewed', true);
  if (viewed) return false;

  return moment(createdAt)
    .isAfter(moment(viewedAt))
    && moment(createdAt)
      .isSameOrBefore(moment(lastLoadedCreatedAt));
}

const UpUnreadBadge = ({ onClick }) => (
  <Button
    variant="light"
    style={{
      position: 'absolute',
      borderRadius: '20px',
      top: '4px',
      left: '50%',
      transform: 'translate(-50%, 0)',
      fontSize: '14px',
      filter: 'drop-shadow(0 0 8px #999)',
      zIndex: 99999999,
      cursor: 'pointer',
    }}
    onClick={onClick}
  >
    <i className="fa fa-arrow-up" />
    &nbsp;More Unreads
  </Button>
);

UpUnreadBadge.propTypes = {
  onClick: PropTypes.func.isRequired,
};

const DownUnreadBadge = ({ onClick }) => (
  <Button
    variant="light"
    style={{
      borderRadius: '20px',
      position: 'absolute',
      bottom: '4px',
      left: '50%',
      transform: 'translate(-50%, 0)',
      fontSize: '14px',
      filter: 'drop-shadow(0 0 8px #999)',
      zIndex: 99999999,
      cursor: 'pointer',
    }}
    onClick={onClick}
  >
    <i className="fa fa-arrow-down" />
    &nbsp;More Unreads
  </Button>
);

DownUnreadBadge.propTypes = {
  onClick: PropTypes.func.isRequired,
};

class MessageList extends Component {
  static propTypes = {
    messages: PropTypes.array.isRequired,
    userId: idType.isRequired,
    loading: PropTypes.bool.isRequired,
    stickyViewedAt: PropTypes.any,
    neverViewed: PropTypes.bool,
    touch: PropTypes.func.isRequired,
    messageThreadId: idType.isRequired,
  };

  static defaultProps = {
    stickyViewedAt: null,
    neverViewed: false,
  };

  constructor(props) {
    super(props);
    this.breakPointRef = null;
  }

  state = {
    breakPointVisible: false,
    messagesVisible: {},
    lastLoadedCreatedAt: null,
    noLoadedMessages: false,
    breakPointEverVisible: false,
    hideBreakPoint: false,
  };

  // to prevent the breakpoint from showing up while user is on screen
  static getDerivedStateFromProps(props, state) {
    const { loading, messages } = props;
    const { lastLoadedCreatedAt } = state;

    if (loading) return null;

    if (messages.length === 0) {
      return {
        noLoadedMessages: true,
      };
    }

    if (lastLoadedCreatedAt) return null;

    const message = props.messages[props.messages.length - 1];
    const createdAt = get(message, 'attributes.created_at', null);

    return {
      lastLoadedCreatedAt: createdAt,
    };
  }

  handleScroll = () => {
    if (!this.breakPointRef) return;
    this.breakPointRef.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  };

  handleMessageVisibilty = (createdAt, visible) => {
    const { messagesVisible } = this.state;
    this.setState({
      messagesVisible: {
        ...messagesVisible,
        [createdAt]: visible,
      },
    });
  };

  getUnreadBadgeComponent = () => {
    const { neverViewed } = this.props;
    if (neverViewed) return UpUnreadBadge;

    const breakPoint = this.getBreakPoint();
    const { messagesVisible } = this.state;

    if (!Object.values(messagesVisible)
      .some(v => v)) {
      return UpUnreadBadge;
    }

    const up = breakPoint ? Object.keys(messagesVisible)
      .some((createdAt) => {
        if (!messagesVisible[createdAt]) return false;
        return moment(createdAt)
          .isAfter(moment(breakPoint.attributes.created_at));
      }) : true;

    return up ? UpUnreadBadge : DownUnreadBadge;
  };

  getBreakPoint = () => {
    const { messages, stickyViewedAt, neverViewed } = this.props;
    const { noLoadedMessages, lastLoadedCreatedAt } = this.state;

    if (noLoadedMessages || !lastLoadedCreatedAt) return null;

    if (neverViewed) return messages.find(message => !message.attributes.default_viewed);

    return messages.find(message => (
      isUnread(message, stickyViewedAt, lastLoadedCreatedAt)
    ));
  };

  setBreakpointVisibility = (breakPointVisible) => {
    const { messageThreadId, touch } = this.props;
    const { breakPointEverVisible, hideBreakPoint } = this.state;
    const state = { breakPointVisible };
    if (breakPointVisible && !breakPointEverVisible) state.breakPointEverVisible = true;
    if (!breakPointVisible && breakPointEverVisible && !hideBreakPoint) {
      state.hideBreakPoint = true;
      touch(messageThreadId);
    }
    this.setState(state);
  }

  render() {
    const { breakPointVisible, hideBreakPoint } = this.state;
    const { messages, userId, loading } = this.props;

    const breakPoint = this.getBreakPoint();

    const UnreadBadgeComponent = this.getUnreadBadgeComponent();

    return (
      <div
        className="chat-rbox ps ps--theme_default ps--active-y"
        style={{
          position: 'relative',
          paddingTop: '20px',
          paddingBottom: '20px',
        }}
      >
        {!breakPointVisible && breakPoint && !hideBreakPoint ? (
          <UnreadBadgeComponent onClick={this.handleScroll} />
        ) : null}
        <ReverseScrollList
          className="chat-list p-3"
          style={{
            overflowY: 'scroll',
            height: '100%',
          }}
        >
          {loading ? [] : messages.map((message, index) => {
            const hasBreakPoint = breakPoint && breakPoint.id === message.id && !hideBreakPoint;
            return (
              <React.Fragment key={message.id}>
                {hasBreakPoint ? (
                  <li ref={el => (this.breakPointRef = el)} key="unread-break">
                    <UnreadBreak
                      onVisibilityChange={breakPointVisible => this.setBreakpointVisibility(breakPointVisible)}
                    />
                  </li>
                ) : null}
                <Message
                  hasBreakPoint={hasBreakPoint}
                  key={message.id}
                  messageId={message.id}
                  userId={userId}
                  onVisibilityChange={this.handleMessageVisibilty}
                  previousMessageId={messages[index - 1] && messages[index - 1].id}
                />
              </React.Fragment>
            );
          })}
        </ReverseScrollList>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { messageThreadId } = ownProps;
  const messageThread = getRecord(state.Api, {
    type: 'message_threads',
    id: messageThreadId,
  });

  if (!messageThread) return { messages: [] };

  const messages = getMessages(state.Api, messageThreadId);

  messages.sort(messageSort);

  return {
    messages,
  };
};

const mapDispatchToProps = {
  touch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MessageList);
