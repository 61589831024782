import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { getRecord } from 'redux-json-api-module';
import './ServiceSelector.scss';
import get from 'lodash.get';
import { Error } from '../../ProjectCreateContainer/ProjectCreateForm/inputs';
import Badge from '../../../components/Badge';
import { firstBy } from 'thenby';

function getOption(service, serviceOptions) {
  return serviceOptions.find(option => option.value === service);
}

const ServiceOption = props => (
  <components.Option {...props}>
    <ServiceDisplay {...props.data} />
  </components.Option>
);

function getOptions(serviceOptions) {
  return serviceOptions.map(option => ({
    value: option.id,
    ...option.attributes.service_badge,
  })).sort(firstBy('label'));
}

const ServiceDisplay = ({ label, background_color, text_color }) => (
  <div className="service__option">
    <Badge label={label} background_color={background_color} text_color={text_color} />
  </div>
);

ServiceDisplay.propTypes = {
  label: PropTypes.string.isRequired,
};

const menuPortal = (base) => {
  const { zIndex, ...rest } = base;

  return {
    ...rest,
    zIndex: 9999,
  };
};

class ServiceSelector extends Component {
  static propTypes = {
    serviceBadge: PropTypes.object,
    service: PropTypes.string,
    input: PropTypes.object,
    serviceOptions: PropTypes.array.isRequired,
    menuPortalTarget: PropTypes.any,
    errors: PropTypes.array,
  };

  static defaultProps = {
    serviceBadge: null,
    service: null,
    menuPortalTarget: document.body,
    input: null,
    errors: [],
  };

  state = {
    service: null,
  };

  handleChange = (service) => {
    const { input } = this.props;
    this.setState({ service: service.value });
    if (input) input.onChange(service.value);
  };

  render() {
    const { menuPortalTarget, errors, input, serviceOptions } = this.props;

    const { service } = this.state;

    const serviceValue = service || (input && input.value);

    const value = serviceValue
      ? [{
        value: serviceValue,
        label: <ServiceDisplay {...getOption(serviceValue, serviceOptions)} />,
      }]
      : null;

    const serviceSelectErrors = errors && errors.service;

    return (
      <div className="service-select-wrapper">
        <Select
          classNamePrefix="service-select"
          className="service-select"
          components={{ Option: ServiceOption }}
          menuPortalTarget={menuPortalTarget}
          onChange={this.handleChange}
          options={serviceOptions}
          placeholder="Select Service"
          styles={{ menuPortal }}
          value={value}
        />
        {serviceSelectErrors ? (
          serviceSelectErrors.map(error => <Error text={error} key={error} />)
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const tasks = (state.WorkOrderManagement.workOrderTaskIds || [])
    .map(id => getRecord(state.Api, {
      type: 'work_order_tasks',
      id,
    }));

  const takenServiceIds = tasks.map(task => get(task, 'relationships.service.data.id', null));

  const serviceFilter = service => (
    !takenServiceIds.includes(service.id) || service.id === ownProps.input.value
  );

  const filteredServiceOptions = (
    state.WorkOrderManagement.serviceObjectOptions?.data?.filter(serviceFilter) ?? []
  );

  return {
    serviceOptions: getOptions(filteredServiceOptions),
  };
};

export default connect(mapStateToProps)(ServiceSelector);
