import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { firstBy } from 'thenby';
import { fetchRecords } from 'redux-json-api-module';
import ServiceTab from './ServiceTab';
import NavLink from '../../components/ServiceDetails/NavLink';
import { Card } from '../../components/Card';
import Alert from '../../components/Alert';

class ChecklistsContainer extends Component {
  static propTypes = {
    services: PropTypes.array.isRequired,
    fetchRecords: PropTypes.func.isRequired,
  };

  state = {
    selected: null,
  };

  componentDidMount() {
    this.fetchRecords();
  }

  fetchRecords = () => {
    const { fetchRecords } = this.props;
    fetchRecords('services', { include: 'checklist_item_templates' })
      .then((resp) => {
        if (resp.error) {
          console.log(resp.error);
          return;
        }

        const firstService = resp.payload.data.data[0];
        this.setState({ selected: firstService ? firstService.id : null });
      });
  };

  render() {
    const { services } = this.props;
    const { selected } = this.state;

    if (services.length === 0 || !selected) return null;

    const selectedService = services.find(service => service.id === selected);

    return (
      <Card id="service-details">
        <Alert
          type="info"
          iconClassName="fa fa-exclamation-circle"
          title="Add Routine Reminders In One Step!"
        >
          Checklist templates are an easy way to add recurring or routine reminders to any Service!
          For example, if you always want your Maintenance providers to "Check Thermostat and HVAC"
          prior to completing their move-out turnover service, add this item below. When you assign
          Services, the service provider will see the specific work requested of them (such as
          inspection pictures and notes), and also the default Checklist Items that management want
          to be completed for all units.
        </Alert>
        <div className="vtabs w-100" style={{ tableLayout: 'fixed' }}>
          <ul className="nav nav-tabs tabs-vertical" role="tablist" style={{ overflowY: 'hidden' }}>
            {services.map(service => (
              <NavLink
                key={service.id}
                onClick={() => this.setState({ selected: service.id })}
                textColor={service.attributes.text_color}
                backgroundColor={service.attributes.background_color}
                active={selected === service.id}
              >
                <div>
                  {service.attributes.name}
                </div>
              </NavLink>
            ))}
          </ul>

          <div className="tab-content" style={{ paddingTop: 0 }}>
            <ServiceTab
              key={selected}
              service={selectedService}
            />
          </div>
        </div>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  services: state.Api.services
    ? Object.values(state.Api.services)
      .sort(
        firstBy(s => s.attributes.sort)
          .thenBy(s => s.attributes.created_at, -1),
      ) : [],
});

const mapDispatchToProps = {
  fetchRecords,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistsContainer);
