import React from 'react';
import { components } from 'react-select';
import UnitDisplay from './UnitDisplay';

const UnitOption = props => (
  <components.Option {...props}>
    <UnitDisplay {...props.data} />
  </components.Option>
);

export default UnitOption;
