import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field } from 'react-final-form';
import { getRelationship } from 'redux-json-api-module';
import Badge from '../../../components/Badge';

const TaskCheckbox = ({ task, service }) => (
  service ? (
    <div>
      <Field
        name={`task-${task.id}`}
        component="input"
        type="checkbox"
        className="scaled-input"
      />
      <label>
        &nbsp;
        <Badge
          style={{
            fontSize: '14px',
            marginTop: '3px',
          }}
          {...service.attributes}
          label={service.attributes.name}
        />
      </label>
    </div>
  ) : null
);

TaskCheckbox.propTypes = {
  task: PropTypes.object.isRequired,
  service: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  service: getRelationship(state.Api, ownProps.task.relationships.service),
});

export default connect(mapStateToProps)(TaskCheckbox);
