import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { FORM_ERROR } from 'final-form';
import toastr from 'toastr';
import { getRelationship, saveRecord } from 'redux-json-api-module';
import trashRestore from '../../../assets/images/icon/baseline-restore_from_trash-24px.svg';
import checkmarkIcon from '../../../assets/images/icon/checkMarkGreen.png';

// TODO: test submit and fix toggle delete (was working)

const ChecklistItemsForm = (
  {
    checklistItemType, parent, allowAdditions, title, instructions,
    // connected
    initialValues, saveRecord,
  },
) => {
  const attribute = `${checklistItemType}_attributes`;

  const handleSubmit = async (values) => {
    const attributes = {
      [attribute]: values[attribute].filter(ci => (
        ci.id || String(ci.description) !== ''
      )),
    };

    const record = {
      type: parent.type,
      id: parent.id,
      attributes,
    };

    const resp = await saveRecord(record, {
      params: {
        include: checklistItemType,
        window_id: window.WINDOW_ID,
      },
    });

    if (resp.error) {
      toastr.error('There was a problem updating the checklist items');
      return { [FORM_ERROR]: 'There was a problem updating the checklist items' };
    }

    toastr.success('Checklist items updated');
    return undefined;
  };


  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      mutators={{
        ...arrayMutators,
      }}
      render={({ handleSubmit, pristine, form, values, submitting }) => (
        <form onSubmit={handleSubmit}>
          <div>
            <div>
              <h3>{title}</h3>
            </div>
          </div>
          <div>
            <div className="w-100 my-1">
              {instructions}
            </div>
            <div className="w-100 my-1">
              Item Description
            </div>
            <FieldArray name={attribute}>
              {({ fields }) => (
                <div>
                  {fields.map((name, index) => {
                    const fieldValue = values[attribute][index];

                    if (!fieldValue) return null;

                    const disabled = fieldValue.completed
                      || parent.attributes.completed_at
                      || fieldValue._destroy;

                    return (
                      <div key={name} className="form-group row">
                        <Field
                          name={`${name}.id`}
                          component="input"
                          type="hidden"
                        />
                        <div className="col">
                          <Field
                            name={`${name}.description`}
                            component="input"
                            type="text"
                            placeholder=""
                            className="form-control is-valid string required"
                            disabled={disabled}
                          />
                        </div>
                        <Field name={`${name}._destroy`}>
                          {({ input }) => {
                            if (fieldValue.completed) {
                              return (
                                <img
                                  src={checkmarkIcon}
                                  alt="Checkmark Icon"
                                  className="completed"
                                />
                              );
                            }

                            if (parent.attributes.completed_at) {
                              return <div className="blank" />;
                            }

                            return (
                              input.value ? (
                                <button
                                  name={`${name}._destroy`}
                                  type="button"
                                  className="btn btn-link restore"
                                  onClick={() => input.onChange(false)}
                                >
                                  <img
                                    src={trashRestore}
                                    alt="Trash Restore"
                                  />
                                </button>
                              ) : (
                                <button
                                  name={`${name}._destroy`}
                                  type="button"
                                  className="btn btn-link delete"
                                  onClick={() => {
                                    if (fieldValue.id) {
                                      input.onChange(true);
                                    } else {
                                      fields.remove(index);
                                    }
                                  }}
                                >
                                  <i className="fa fa-2x fa-trash" />
                                </button>
                              )
                            );
                          }}
                        </Field>
                      </div>
                    );
                  })}

                  {allowAdditions ? (
                    <div>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => fields.push(attribute, {
                          id: null,
                          description: '',
                          _destroy: false,
                        })}
                      >
                        + Add Item
                      </button>
                      <input
                        id="checklist-save"
                        type="submit"
                        value="Save"
                        className={`btn btn-success m-l-10 animated ${pristine ? 'invisible' : 'visible fadeIn'}`}
                        disabled={submitting}
                      />
                      <button
                        type="reset"
                        value="Cancel"
                        className={`btn btn-secondary m-l-10 animated ${pristine ? 'invisible' : 'visible fadeIn'}`}
                        disabled={submitting}
                        onClick={form.reset}
                      >
                        Cancel
                      </button>
                    </div>
                  ) : null}
                </div>
              )}
            </FieldArray>
          </div>
        </form>
      )}
    />
  );
};

ChecklistItemsForm.propTypes = {
  saveRecord: PropTypes.func.isRequired,
  parent: PropTypes.object.isRequired,
  checklistItemType: PropTypes.string.isRequired,
  allowAdditions: PropTypes.bool,
  title: PropTypes.string.isRequired,
  instructions: PropTypes.string.isRequired,
};

ChecklistItemsForm.defaultProps = {
  allowAdditions: false,
};

const mapDispatchToProps = {
  saveRecord,
};

const mapStateToProps = (state, ownProps) => {
  const checklistItems = getRelationship(
    state.Api,
    ownProps.parent.relationships[ownProps.checklistItemType],
  ) || [];

  const attributes = checklistItems.map(ci => ({
    id: ci.id,
    description: ci.attributes.description,
    completed: !!ci.attributes.completed_at,
    _destroy: false,
  }));

  attributes.push({
    id: null,
    description: '',
    completed: false,
    _destroy: false,
  });

  return {
    initialValues: {
      [`${ownProps.checklistItemType}_attributes`]: attributes,
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistItemsForm);
