import {
  getRecord,
  getRelationship,
  SAVE_RECORD,
} from 'redux-json-api-module';
import get from 'lodash.get';
import { CHILD_TYPE_MAP, TASK_SORTS } from '../../helpers/types';
import { setActiveMessageThread } from './chat';

export const SET_ACTIVE_TASK = 'intimely/navigation/SET_ACTIVE_TASK';

const INITIAL_STATE = {
  activeTaskId: null,
  activeTaskType: null,
  activePlanType: null,
  activePlanId: null,
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_ACTIVE_TASK:
      return {
        ...state,
        activeTaskId: action.activeTaskId,
        activeTaskType: action.activeTaskType,
        activePlanId: action.activePlanId || state.activePlanId,
        activePlanType: action.activePlanType || state.activePlanType,
      };

    default:
      return state;
  }
}

export function setActiveTask({
                                activeTaskType,
                                activeTaskId,
                                activePlanType,
                                activePlanId,
                              }) {
  return {
    type: SET_ACTIVE_TASK,
    activeTaskType,
    activeTaskId,
    activePlanType,
    activePlanId,
  };
}

export function initializeActiveTask(planType, planId) {
  return (dispatch, getState) => {
    const state = getState();
    const plan = getRecord(state.Api, { type: planType, id: planId });

    const tasks = (plan
      ? getRelationship(state.Api, plan.relationships[CHILD_TYPE_MAP[planType]])
      : []) || [];

    tasks.sort(TASK_SORTS[planType]);

    const activeTask = tasks.find(task => !task.attributes.is_inspection);

    return activeTask ? dispatch(setActiveTask({
      activeTaskType: activeTask.type,
      activeTaskId: activeTask.id,
      activePlanType: planType,
      activePlanId: planId,
    })) : Promise.resolve({});
  };
}

export function openPlanChat() {
  return (dispatch, getState) => {
    const state = getState();

    const { activePlanType, activePlanId } = state.Navigation;

    if (!activePlanId) return Promise.resolve({});

    const plan = getRecord(state.Api, {
      type: activePlanType,
      id: activePlanId,
    });

    if (!plan) return Promise.resolve({});

    const communityId = get(plan, 'relationships.community.data.id', null);
    const messageThreadId = get(plan, 'relationships.message_thread.data.id', null);

    dispatch({
      type: SAVE_RECORD,
      payload: {
        request: {
          method: 'PATCH',
          url: `/message_threads/${messageThreadId}/active`,
        },
      },
    });

    return dispatch(setActiveMessageThread({
      planType: activePlanType,
      planId: activePlanId,
      communityId,
      messageThreadId,
      noTouch: true,
    }));
  };
}
