import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import axios from 'axios';
import axiosMiddleware from 'redux-axios-middleware';
import reducer from './modules/reducer';

const client = axios.create({
  baseURL: '/api/v1',
  responseType: 'json',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

window.axiosActive = 0;

const middlewareConfig = {
  interceptors: {
    request: [
      (functions, request) => {
        window.axiosActive += 1;
        return request;
      },
    ],
    response: [{
      success: (_, response) => {
        window.axiosActive -= 1;
        return response;
      },
      error: (_, error) => {
        window.axiosActive -= 1;
        return Promise.reject(error);
      },
    }],
  },
};

const store = createStore(
  reducer,
  applyMiddleware(
    thunk,
    axiosMiddleware(client, middlewareConfig),
  ),
);

export default store;
