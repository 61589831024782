import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';
import EditUnitContainer from '../containers/EditUnitContainer/EditUnitContainer';

const EditUnit = props => (
  <Provider store={store}>
    <EditUnitContainer {...props} />
  </Provider>
);

export default EditUnit;
