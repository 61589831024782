import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchRecords } from 'redux-json-api-module';
import UserRow from './UserRow';
import { idType } from '../../../helpers/propTypes';

const LaborTable = ({
  userIds,
  fetchRecords,
}) => {
  useEffect(() => {
    fetchRecords('users', {
      filter: { scope: 'maintenance' },
      include: 'hourly_rate',
      page: { size: 250 },
    });
  }, []);


  return (
    <div>
      <table className="table table-borderless table-sm summary-table">
        <thead>
          <tr>
            <th>Team Member</th>
            <th>Hourly Labor Rate</th>
          </tr>
        </thead>
        <tbody>
          {userIds.map(userId => (
            <UserRow userId={userId} key={userId} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

LaborTable.propTypes = {
  userIds: PropTypes.arrayOf(idType).isRequired,
  fetchRecords: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const userIds = Object.values(state.Api.users || {}).filter(user => (
    user.attributes.app_role === 'maintenance'
  )).map(user => user.id);


  return {
    userIds,
  };
};

const mapDispatchToProps = {
  fetchRecords,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LaborTable);
