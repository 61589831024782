import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';
import InventoryImportContainer from '../containers/InventoryImportContainer/InventoryImportContainer';

const InventoryImport = props => (
  <Provider store={store}>
    <InventoryImportContainer {...props} />
  </Provider>
);

export default InventoryImport;
