import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ChecklistItemsForm from '../../components/ChecklistItemsForm';

class ServiceTab extends Component {
  static propTypes = {
    service: PropTypes.object.isRequired,
  };

  render() {
    const { service } = this.props;

    if (!service) return null;

    return (
      <div id="service-tab">
        <ChecklistItemsForm
          parent={service}
          checklistItemType="checklist_item_templates"
          allowAdditions
          title={`${service.attributes.name} Default Checklist Items`}
          instructions={`Please add or remove default checklist items for ${service.attributes.name}`}
        />
      </div>
    );
  }
}

export default ServiceTab;
