import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';

const DateInput = ({ input, className }) => {
  return (
    <DatePicker
      {...input}
      selected={input.value}
      className={className}
    />
  );
};

export default DateInput;
