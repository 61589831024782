import React, { Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { connect } from 'react-redux';
import moment from 'moment';
import { getRecord } from 'redux-json-api-module';
import MessengerForm from './MessengerForm';
import {
  touch,
  fetchTaggableUsers,
  fetchMessages,
  getMessengerData,
} from '../../redux/modules/chat';
import MessageList from './MessageList';
import { includeData } from '../../redux/modules/projectManagement';

import './Messenger.scss';
import { teamObject } from '../../helpers/propTypes';

class Messenger extends Component {
  state = {
    loading: true,
  };

  componentDidMount() {
    const { messageThreadId, team, fetchTaggableUsers, fetchMessages, touch } = this.props;
    const channel = `message-thread-${messageThreadId}`;

    Promise.all([
      (team ? fetchTaggableUsers(team.data.id) : Promise.resolve()),
      fetchMessages(messageThreadId),
    ]).then(() => this.setState({ loading: false }));

    window.PUSHER.subscribe(channel)
      .bind('new-message', () => touch(messageThreadId));

    window.PUSHER.subscribe(channel)
      .bind('simple-new-message', this.handleSimpleMessage);
  }

  componentWillUnmount() {
    const { messageThreadId } = this.props;
    const channel = `message-thread-${messageThreadId}`;

    window.PUSHER.unsubscribe(channel);
  }

  handleSimpleMessage = (event) => {
    const { messengerData, includeData } = this.props;
    const { message } = event;
    message.attributes.created_at = moment().toDate();
    message.attributes.messenger = (messengerData || {})[message.attributes.messenger_id];

    if (message.attributes.messenger) includeData({ data: message });
  };

  render() {
    const {
      messageThreadId,
      onInputFocus,
      userId,
      stickyViewedAt,
      neverViewed,
      canTag,
    } = this.props;

    const { loading } = this.state;

    return (
      <React.Fragment>
        <MessageList
          messageThreadId={messageThreadId}
          userId={userId}
          loading={loading}
          stickyViewedAt={stickyViewedAt}
          neverViewed={neverViewed}
        />
        <MessengerForm
          messageThreadId={messageThreadId}
          onInputFocus={onInputFocus}
          userId={userId}
          canTag={canTag}
        />
      </React.Fragment>
    );
  }
}

Messenger.propTypes = {
  messageThreadId: PropTypes.string.isRequired,
  touch: PropTypes.func.isRequired,
  fetchTaggableUsers: PropTypes.func.isRequired,
  fetchMessages: PropTypes.func.isRequired,
  onInputFocus: PropTypes.func,
  team: teamObject,
  userId: PropTypes.string.isRequired,
  neverViewed: PropTypes.bool,
  stickyViewedAt: PropTypes.any,
  canTag: PropTypes.bool,
};

Messenger.defaultProps = {
  onInputFocus: () => {
  },
  team: null,
  stickyViewedAt: null,
  neverViewed: false,
  canTag: true,
};

const mapDispatchToProps = {
  fetchTaggableUsers,
  fetchMessages,
  touch,
  includeData,
};

const mapStateToProps = (state, ownProps) => {
  const messageThread = getRecord(state.Api, {
    type: 'message_threads', id: ownProps.messageThreadId,
  });

  return {
    canTag: get(messageThread, 'attributes.subject_type', null) !== 'DirectMessageChat',
    messengerData: getMessengerData(state.Api, ownProps.messageThreadId),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Messenger);
