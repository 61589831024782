import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { saveRecord, getRecord, getRelationship } from 'redux-json-api-module';
import get from 'lodash.get';
import { Button } from 'react-bootstrap';
import ServiceSelector from '../../../containers/WorkOrderImportManagementContainer/ServiceSelector/ServiceSelector';
import SchedulerButton from '../../../containers/ProjectManagementContainer/Scheduler/SchedulerButton';
import MaintenanceSelect from '../../MaintenanceSelect';
import {
  assignUserGeneric,
  createService,
  removePendingService, deleteWorkOrderTask,
} from '../../../redux/modules/workOrderManagement';
import { idType } from '../../../helpers/propTypes';
import Badge from '../../Badge';
import HelpTextOverlay from './HelpTextOverlay';
import ServiceInput from '../../FeatureForm/ServiceInput';

class EditServiceRow extends Component {
  static propTypes = {
    saveRecord: PropTypes.func.isRequired,
    assignUser: PropTypes.func.isRequired,
    deleteWorkOrderTask: PropTypes.func.isRequired,
    taskId: idType.isRequired,
    planId: idType.isRequired,
    pending: PropTypes.bool,
    pendingIndex: PropTypes.number,
    createService: PropTypes.func.isRequired,
    removePendingService: PropTypes.func.isRequired,
    includeDelete: PropTypes.bool,

    //connected
    user: PropTypes.object,
    serviceId: idType,
    userOptions: PropTypes.array,
    planAasmState: PropTypes.string,
    aasmState: PropTypes.string,
    scheduleString: PropTypes.string,
    formattedCompletedAt: PropTypes.string,
    progress: PropTypes.number,
    serviceStatus: PropTypes.object,
    serviceOptions: PropTypes.array,
  };

  static defaultProps = {
    user: null,
    serviceId: null,
    completedAt: null,
    serviceStatus: null,
    pending: false,
    pendingIndex: null,
    includeDelete: false,
    serviceOptions: [],
  };

  handleServiceSelection = (serviceId) => {
    const { saveRecord, taskId, pending, createService, planId } = this.props;

    if (pending) {
      return createService(planId, taskId, serviceId);
    }

    const record = {
      type: 'work_order_tasks',
      id: taskId,
      attributes: {
        service_id: serviceId,
      },
    };

    return saveRecord(record, { params: { window_id: window.WINDOW_ID } });
  };

  render() {
    const {
      taskId,
      user,
      serviceId,
      assignUser,
      userOptions,
      deleteWorkOrderTask,
      planAasmState,
      aasmState,
      scheduleString,
      formattedCompletedAt,
      progress,
      serviceStatus,
      pending,
      pendingIndex,
      removePendingService,
      includeDelete,
      startedAt,
    } = this.props;

    return (
      <tr className="v-middle" id={`serviceRow-${pending ? `pending-${pendingIndex}` : taskId}`}>
        <td>
          <ServiceSelector
            input={{
              onChange: this.handleServiceSelection,
              value: serviceId,
            }}
          />
        </td>
        <td style={{ padding: '.25em 1em' }}>
          {serviceId ? (
            <MaintenanceSelect
              user={user}
              serviceId={serviceId}
              options={userOptions || []}
              onChange={userId => assignUser(userId, 'work_order_tasks', taskId)}
              key={`maintenance-select-${taskId}`}
            />
          ) : 'Awaiting Service Assignment'}
        </td>
        <td>
          {aasmState === 'completed' ? scheduleString : (
            serviceId
              ? (
                <SchedulerButton
                  id={taskId}
                  taskType="work_order_tasks"
                />
              ) : 'Awaiting Service Assignment'
          )}
        </td>
        {planAasmState !== 'assign' && !pending && startedAt ? (
          <td>
            <Badge {...serviceStatus} />
          </td>
        ) : null}
        {planAasmState === 'assign' || pending || !startedAt ? null : (
          <td>
            {aasmState === 'completed' ? formattedCompletedAt : null}
            {planAasmState === 'active' && aasmState === 'active' ? (
              <HelpTextOverlay taskType="work_order_tasks" taskId={taskId} />
            ) : null}
            {planAasmState === 'active' && aasmState === 'pending' && (
              `${Math.round(progress)}%`
            )}
          </td>
        )}
        {includeDelete && aasmState !== 'completed' && (planAasmState === 'assign' || pending || !startedAt) ? (
          <td>
            <Button
              variant="danger"
              id={`delete-${pending ? `pending-${pendingIndex}` : taskId}`}
              onClick={() => (pending ? removePendingService(taskId) : deleteWorkOrderTask(taskId))}
            >
              Delete
            </Button>
          </td>
        ) : null}
      </tr>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  if (ownProps.pending) {
    return {};
  }

  const task = getRecord(state.Api, {
    type: 'work_order_tasks',
    id: ownProps.taskId,
  });
  const user = getRelationship(state.Api, task.relationships.user);
  const serviceId = get(task, 'relationships.service.data.id', null);
  const userOptions = get(task, 'attributes.user_options', []);

  const aasmState = get(task, 'attributes.aasm_state', null);
  const scheduleString = get(task, 'attributes.schedule_string', null);
  const completedAt = moment(get(task, 'attributes.completed_at', get(task, 'attributes.manually_completed_at', null)));
  const progress = get(task, 'attributes.progress', 0);
  const serviceStatus = get(task, 'attributes.service_status.private', null);

  const formattedCompletedAt = completedAt.isValid() && aasmState === 'completed'
    ? moment(completedAt).format('MM/DD')
    : 'Unknown';

  return {
    serviceId,
    user,
    userOptions,
    aasmState,
    scheduleString,
    progress,
    formattedCompletedAt,
    serviceStatus,
    startedAt: task.attributes.started_at,
  };
};

const mapDispatchToProps = {
  saveRecord,
  assignUser: assignUserGeneric,
  deleteWorkOrderTask,
  createService,
  removePendingService,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditServiceRow);
