import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { toggleArchiveModal } from '../../../redux/modules/chat';
import ChatChannelsTable from './ChatChannelsTable';

const ArchiveModal = ({ toggleArchiveModal, visible }) => (
  <Modal
    show={visible}
    onHide={() => toggleArchiveModal(false)}
    size="lg"
  >
    <Modal.Header closeButton>
      <Modal.Title>Archived Channels</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <ChatChannelsTable />
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={() => toggleArchiveModal(false)}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
);

ArchiveModal.propTypes = {
  // connected
  toggleArchiveModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  visible: state.Chat.archiveModalVisible,
});

const mapDispatchToProps = {
  toggleArchiveModal,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ArchiveModal);
