import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import toastr from 'toastr';
import { fetchRecord, getRecord, saveRecord } from 'redux-json-api-module';
import moment from 'moment';

import get from 'lodash.get';
import { allViews, decorateEvent } from '../../ProjectManagementContainer/Scheduler/helpers';
import { makeGetEvents } from '../../../redux/selectors/teamSchedule';
import {
  fetchEvents,
  handleRangeChange,
  setActiveTask,
  setEvents,
} from '../../../redux/modules/teamSchedule';
import GenericScheduler from '../../../components/GenericScheduler';
import { eventPropGetter } from './helpers';
import { getInspection } from '../../../helpers/inspection';
import Loader from '../../../components/Loader';
import { INVERSE_CALENDAR_EVENT_TYPE_MAP } from '../../../helpers/types';

class Schedule extends React.PureComponent {
  static propTypes = {
    onRangeChange: PropTypes.func,
    saveRecord: PropTypes.func.isRequired,
    loading: PropTypes.bool,

    // connected
    events: PropTypes.arrayOf(PropTypes.object),
    fetchEvents: PropTypes.func.isRequired,
    startDate: PropTypes.any,
    dueDate: PropTypes.any,
    deadline: PropTypes.any,
  };

  static defaultProps = {
    events: [],
    onRangeChange: () => null,
    loading: false,
    startDate: null,
    dueDate: null,
    deadline: null,
  };

  state = {
    formVisible: false,
  };

  UNSAFE_componentWillMount() {
    const { fetchEvents } = this.props;

    fetchEvents();
    document.addEventListener('mousedown', this.handleBlur, false);
  }

  componentDidUpdate() {
    window.dispatchEvent(new Event('resize'));
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleBlur, false);
  }

  getFormValues = attributes => ({
    includeTime: !attributes.all_day,
    startDate: moment(attributes.started_at)
      .startOf('day')
      .toDate(),
    endDate: moment(attributes.ended_at)
      .startOf('day')
      .toDate(),
    startTime: moment(attributes.started_at)
      .toDate(),
    endTime: moment(attributes.ended_at)
      .toDate(),
  });

  handleAssignmentChange = (userId) => {
    const {
      saveRecord,
      setActiveTask,
      activeTask,
    } = this.props;

    setActiveTask(activeTask, userId);

    const type = INVERSE_CALENDAR_EVENT_TYPE_MAP[activeTask.type];

    const record = {
      id: activeTask.id,
      type,
      attributes: {
        user_id: userId,
      },
    };

    saveRecord(record, { params: { include: 'user', window_id: window.WINDOW_ID } })
      .then(() => {
        toastr.success('Project Service assignee has been updated');
      })
      .catch(() => {
        toastr.error('There was a problem saving Project Service assignee');
      });
  };

  handleChangeScheduled = (scheduled, userId) => {
    const { setActiveTask } = this.props;
    setActiveTask(scheduled, userId);
  };

  handleSave = (attributes) => {
    const {
      activeTask,
      fetchEvents,
      saveRecord,
    } = this.props;
    const type = INVERSE_CALENDAR_EVENT_TYPE_MAP[activeTask.type];

    const record = {
      id: activeTask.id,
      type,
      attributes,
    };

    if (attributes.user_id) this.setState({ userId: attributes.user_id });

    return saveRecord(record, { params: { include: 'user', window_id: window.WINDOW_ID } })
      .then(() => fetchEvents(true))
      .then(() => toastr.success('Task schedule has been updated'))
      .catch(() => toastr.error('There was a problem saving task schedule'));
  };

  handleBlur = (e) => {
    const { formVisible } = this.state;
    const { setActiveTask } = this.props;
    const className = get(e, 'target.className', '');
    const isSelector = className.includes && className.includes('maintenance-select');
    if (isSelector) return;
    if (this.node && e.target && this.node.contains(e.target)) return;
    if (!formVisible) setActiveTask(null, null);
  };

  render() {
    const {
      events,
      startDate,
      loading,
      dueDate,
      deadline,
      activeTask,
      userId,
      fetchEvents,
      handleRangeChange,
    } = this.props;
    const { formVisible } = this.state;

    return (
      <React.Fragment>
        <div className="card">
          <div className="card-body">
            {loading ? (
              <Loader />
            ) : null}
            <div
              ref={(node) => {
                this.node = node;
              }}
              className="scheduler-container p-10"
            >
              <div className={loading ? 'loading' : null}>
                <GenericScheduler
                  events={events}
                  views={allViews}
                  userId={userId}
                  scheduled={activeTask}
                  onAssignmentChange={this.handleAssignmentChange}
                  onChangeScheduled={this.handleChangeScheduled}
                  onRangeChange={handleRangeChange}
                  onSave={this.handleSave}
                  getFormValues={this.getFormValues}
                  eventPropGetter={eventPropGetter}
                  startDate={startDate}
                  dueDate={dueDate}
                  deadline={deadline}
                  setFormVisible={formVisible => this.setState({ formVisible })}
                  formVisible={formVisible}
                  refresh={() => fetchEvents(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const makeMapStateToProps = () => {
  const getEvents = makeGetEvents();

  const mapStateToProps = (state, props) => {
    const { activePlan, activeTask } = state.TeamSchedule;

    let startDate;
    let dueDate;
    let deadline;

    if (activePlan && activePlan.plan_type === 'projects') {
      const plan = getRecord(state.Api, {
        type: activePlan.plan_type,
        id: activePlan.id,
      });
      const inspection = plan && getInspection(plan, state.Api);
      startDate = inspection ? moment(inspection.attributes.started_at)
        .toDate() : null;
      dueDate = plan ? plan.attributes.due_on : null;
      deadline = plan ? moment(plan.attributes.keys_received_on)
        .add(21, 'days') : null;
    } else if (activePlan && activePlan.plan_type === 'work_orders') {
      const plan = getRecord(state.Api, {
        type: activePlan.plan_type,
        id: activePlan.id,
      });
      dueDate = plan ? plan.attributes.due_on : null;
    }

    return {
      events: getEvents(state, props),
      loading: state.TeamSchedule.loading,
      startDate,
      dueDate,
      deadline,
      activeTask,
      userId: state.TeamSchedule.userId,
    };
  };

  return mapStateToProps;
};

const mapDispatchToProps = {
  fetchEvents,
  handleRangeChange,
  saveRecord,
  setActiveTask,
  setEvents,
  fetchRecord,
};

export default connect(makeMapStateToProps, mapDispatchToProps)(Schedule);
