import emoji from 'node-emoji';

const TYPE = 'emoji';

function getEmojiMatches(name) {
  return emoji.search(name)
    .slice(0, 3);
}

const Emoji = {
  type: TYPE,
  toText: () => ({
    characters: null,
  }),

  generateReference: (text, start) => (text === ':' ? {
    type: TYPE,
    start,
    text,
    active: false,
    matches: emoji.search('')
      .slice(0, 3),
    characters: [],
  } : null),

  updateReference: (reference, text) => {
    reference.matches = getEmojiMatches(text.substring(1));
    reference.active = reference.matches.length !== 0
      && (reference.active || reference.characters.length > 2);
  },

  mergeReference: (previousCharacter, addition) => {
    if (!previousCharacter
      || !previousCharacter.reference
      || previousCharacter.reference.completed
    ) {
      return addition.reference;
    }
    const previousReference = previousCharacter.reference;

    if (addition.text === ' ') {
      return null;
    }

    return previousReference;
  },

  toEncodedText: character => character.char,
};

export default Emoji;
