import React from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import PropTypes from 'prop-types';
import { fetchRecord, saveRecord } from 'redux-json-api-module';
import { Form } from 'react-final-form';
import Button from 'react-bootstrap/Button';
import { includeData } from '../../../redux/modules/projectManagement';
import Alert from '../../../components/Alert';
import GalleryUploader from './GalleryUploader';
import ImageGallery from './ImageGallery';
import ServiceAttributesForm from './ServiceAttributesForm';

import 'react-image-picker/dist/index.css';
import { idType } from '../../../helpers/propTypes';

const uuidv4 = require('uuid/v4');

class EditServiceForm extends React.Component {
  static propTypes = {
    feature: PropTypes.object,
    areaItems: PropTypes.object,
    areaOptions: PropTypes.array.isRequired,
    serviceOptions: PropTypes.array.isRequired,
    conditionOptions: PropTypes.array.isRequired,
    descriptionOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
    featureImages: PropTypes.array.isRequired,
    errors: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    area: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    condition: PropTypes.string,
    saveRecord: PropTypes.func.isRequired,
    includeData: PropTypes.func.isRequired,
    fetchRecord: PropTypes.func.isRequired,
    deleteFeature: PropTypes.func.isRequired,
    itemId: idType,
  };

  static defaultProps = {
    feature: null,
    area: null,
    areaItems: {},
    name: null,
    description: null,
    condition: null,
    itemId: null,
  };

  state = {
    pendingImages: [],
    loading: false,
  };

  featureImageIdByPreview = {};

  componentWillUnmount() {
    const { pendingImages } = this.state;
    pendingImages.forEach(n => URL.revokeObjectURL(n.preview));
  }

  handleSubmit = () => {
    const {
      feature,
      onClose,
      name,
      condition,
      description,
      area,
      saveRecord,
      itemId,
    } = this.props;

    this.setState({ loading: true });

    const record = {
      type: feature.type,
      id: feature.id,
      attributes: {
        name,
        condition,
        description,
        area,
        item_id: itemId,
      },
    };

    saveRecord(record, { params: { window_id: window.WINDOW_ID } })
      .then((resp) => {
        if (resp.error) {
          toastr.error('There was a problem updating the service');
        } else {
          toastr.success('Service updated!');
          onClose();
        }
        this.setState({ loading: false });
      });
  };

  handleDelete = (e) => {
    e.preventDefault();

    const { deleteFeature, feature, onClose } = this.props;
    const confirmed = window.confirm('Are you sure you want to delete this Item from the Inspection? This action cannot be undone.');


    if (confirmed) {
      deleteFeature(feature)
        .then(() => onClose());
    }
  };

  getFormData = (file) => {
    const { feature } = this.props;
    const formData = new FormData();
    formData.append('data[attributes][feature_images_attributes][0][feature_id]', feature.id);
    formData.append('data[attributes][feature_images_attributes][0][user_id]', CURRENT_USER_ID);
    formData.append('data[attributes][feature_images_attributes][0][file]', file);
    formData.append('data[attributes][feature_images_attributes][0][file_object_url]', file.preview);

    return formData;
  };

  onDrop = (acceptedFiles) => {
    const { feature, fetchRecord, includeData } = this.props;
    const { pendingImages } = this.state;

    acceptedFiles.forEach((file) => {
      file.id = uuidv4();
      file.preview = URL.createObjectURL(file);
    });

    this.setState({ pendingImages: [...pendingImages, ...acceptedFiles] });

    const promises = acceptedFiles.map((file) => {
      const formData = this.getFormData(file);

      return fetch(`/api/v1/${feature.type}/${feature.id}`, {
        method: 'PATCH',
        body: formData,
      })
        .then((response) => {
          response.json()
            .then((data) => {
              includeData(data);
              this.featureImageIdByPreview[file.preview] = parseInt(data.data.id);
              this.setState(prevState => ({ pendingImages: [...prevState.pendingImages] }));
            });
        })
        .catch((error) => {
          console.log(error);
          this.setState(prevState => ({ pendingImages: prevState.pendingImages.filter(n => n.id !== file.id) }));
        });
    });

    Promise.all(promises).finally(() => fetchRecord(feature.type, feature.id));
  };

  handleDeleteImage = (id) => {
    const { saveRecord, feature } = this.props;
    if (window.confirm('Are you sure you want to delete this Image? This action cannot be undone.')) {
      const record = {
        type: feature.type,
        id: feature.id,
        attributes: {
          feature_images_attributes: {
            id,
            _destroy: true,
          },
        },
      };

      saveRecord(record, { params: { window_id: window.WINDOW_ID } });
    }
  };

  handleLoadImage = (image) => {
    if (!image.file_object_url) return;

    const { pendingImages } = this.state;

    this.setState({ pendingImages: pendingImages.filter(n => n.preview !== image.file_object_url) });
    URL.revokeObjectURL(image.file_object_url);
  };

  render() {
    const {
      descriptionOptions,
      serviceOptions,
      conditionOptions,
      errors,
      featureImages,
      onClose,
      feature,
      area,
      name,
      description,
      condition,
      areaOptions,
      areaItems,
      onChange,
      itemId,
    } = this.props;

    const { pendingImages, loading } = this.state;

    return (
      <Form
        id="edit-service-form"
        onSubmit={this.handleSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} id="change-service-form">
            <div className="modal-header">
              <div className="m-auto text-center">
                <h5 className="modal-title font-weight-bold">
                  Update Inspection Item
                </h5>
              </div>
            </div>
            {feature ? (
              <div className="modal-body feature-form">
                <Alert
                  type="info"
                  iconClassName="fa fa-exclamation-circle"
                  title="Update Inspection Details and Images"
                >
                  Please review the details for this Inspection Item, and add any
                  additional inspection images for this Item as needed.
                </Alert>
                <div className="container fluid" />
                <div className="row">
                  <div
                    className="col"
                    style={{
                      borderRight: '1px solid #dfe6e9',
                      paddingRight: '20px',
                    }}
                  >
                    <h5 className="font-weight-bold mb-20">Inspection Images for this item</h5>

                    <div className="row">
                      <div className="col">
                        <div className="form-group image-gallery-container">
                          <ImageGallery
                            images={featureImages}
                            pendingImages={pendingImages.filter(n => !this.featureImageIdByPreview[n.preview])}
                            onDelete={this.handleDeleteImage}
                            onLoad={this.handleLoadImage}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mb-70">
                      <div className="col">
                        <h5 className="font-weight-bold mt-20 mb-20">Attach Inspection Images</h5>
                        <GalleryUploader
                          onDrop={this.onDrop}
                        />
                      </div>
                    </div>

                    <div className="row bottom-row">
                      <div className="col">

                        <div
                          className="modal-footer"
                          style={{
                            borderTop: 'none',
                            justifyContent: 'flex-start',
                          }}
                        >
                          <Button
                            variant="link"
                            onClick={this.handleDelete}
                          >
                            Delete Item from Inspection
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ServiceAttributesForm
                    errors={errors}
                    onChange={onChange}
                    onClose={onClose}

                    areaOptions={areaOptions}
                    areaItems={areaItems}
                    descriptionOptions={descriptionOptions}
                    conditionOptions={conditionOptions}
                    serviceOptions={serviceOptions}

                    name={name}
                    area={area}
                    description={description}
                    condition={condition}
                    itemId={itemId}

                    loading={loading}
                  />
                </div>
              </div>
            ) : null}
          </form>
        )}
      />
    );
  }
}

const mapDispatchToProps = {
  fetchRecord,
  includeData,
  saveRecord,
};

export default connect(null, mapDispatchToProps)(EditServiceForm);
