import React from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import ModalCarousel from '../ModalCarousel';
import LazyProgressiveImage from '../LazyProgressiveImage';

import './GenericImageSlider.scss';

class GenericImageSlider extends React.Component {
  static propTypes = {
    images: PropTypes.arrayOf(PropTypes.object).isRequired,
    settings: PropTypes.object,
    sliderClassName: PropTypes.string,
    imageClassName: PropTypes.string,
    pagination: PropTypes.bool,
    sourceVariant: PropTypes.string,
    placeholderVariant: PropTypes.string,
  };

  static defaultProps = {
    settings: {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      lazyLoad: true,
      centerMode: true,
    },
    pagination: true,
    sliderClassName: '',
    imageClassName: 'w-100',
    sourceVariant: 'thumb',
    placeholderVariant: 'thumb_placeholder',
  };

  state = {
    modalBody: '',
    modalTitle: '',
    modalVisible: false,
    currentSlide: 0,
    selectedSlide: 0,
  };

  static getDerivedStateFromProps;

  handleClick = index => this.setState({
    modalVisible: true,
    selectedSlide: index,
  });

  handleClose = () => {
    this.setState({ modalVisible: false });
  };

  render() {
    const {
      images, settings, sliderClassName, imageClassName, pagination,
      sourceVariant, placeholderVariant,
    } = this.props;

    const { modalVisible, modalTitle, modalBody, currentSlide, selectedSlide } = this.state;

    const filteredImages = images.filter(i => i.file);

    return (
      <React.Fragment>
        <Slider
          ref={function (c) {
            this._slick = c;
          }.bind(this)}
          {...settings}
          className={`${sliderClassName} ${settings.className}`}
          afterChange={currentSlide => this.setState({ currentSlide })}
        >
          {filteredImages.map((image, i) => (
            <div key={image.id}>
              <LazyProgressiveImage
                sourceUrl={image.file[sourceVariant].url}
                placeholderUrl={image.file[placeholderVariant].url}
                onClick={() => this.handleClick(i)}
                alt="Image"
                imageClassName={`${imageClassName || ''} cursor-pointer`}
              />
            </div>
          ))}
        </Slider>

        {pagination ? (
          <div className={`text-center ${settings.dots ? 'mt-35' : 'mb-25 pt-10'}`}>
            {`${currentSlide + 1} of ${images.length}`}
          </div>
        ) : null}

        <ModalCarousel
          visible={modalVisible}
          title={modalTitle}
          images={filteredImages.map(i => i.file)}
          onClose={() => this.handleClose()}
          bodyHTML={modalBody}
          startIndex={selectedSlide}
        />
      </React.Fragment>
    );
  }
}

export default GenericImageSlider;
