import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import toastr from 'toastr';
import { fetchRecord, saveRecord } from 'redux-json-api-module';
import Row from './Row';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const InspectionServiceSort = ({
  fixedProjectServices,
  sortableProjectServices,
  project,
  saveRecord,
}) => {
  const [sortedProjectServices, setSortedProjectServices] = useState(sortableProjectServices);
  const table = useRef(null);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const order = reorder(
      sortableProjectServices,
      result.source.index,
      result.destination.index,
    );

    setSortedProjectServices(order);

    const newProject = Object.assign(
      {},
      project,
      {
        attributes: {
          task_order: fixedProjectServices.concat(order)
            .map(ps => ps.id),
        },
      },
    );

    saveRecord(
      newProject, {
        params: {
          include: 'project_services',
          window_id: window.WINDOW_ID,
        },
      },
    )
      .then(() => {
        toastr.success('Project Service order updated');
      })
      .catch(() => {
        toastr.error('There was a problem saving Project Service order');
      });
  };

  // lock all cell dimensions to ensure things don't jump around during drag/drop
  const lockCellDimensions = () => {
    const cells = table.current.getElementsByTagName('td');
    let bounds;

    for (let i = 0; i < cells.length; i += 1) {
      bounds = cells[i].getBoundingClientRect();
      cells[i].style.height = `${bounds.height}px`;
      cells[i].style.width = `${bounds.width}px`;
    }
  };

  useEffect(() => {
    lockCellDimensions();
    setSortedProjectServices(sortableProjectServices);
  }, [sortableProjectServices]);

  return (
    <div className="table-responsive">
      <DragDropContext onDragEnd={onDragEnd}>
        <table ref={table} className="table project-service-assignment-table">
          <thead>
            <tr>
              <th>Service</th>
              <th>Condition</th>
              <th>Sort</th>
            </tr>
          </thead>

          <tbody>
            {fixedProjectServices.map(ps => (
              <Row
                key={ps.id}
                projectService={ps}
                canEdit={false}
              />
            ))}
          </tbody>

          <Droppable droppableId="table">
            {provided => (
              <tbody ref={provided.innerRef}>
                {sortedProjectServices.map((ps, i) => (
                  <Draggable key={ps.id} draggableId={ps.id} index={i}>
                    {provided => (
                      <Row
                        innerRef={provided.innerRef}
                        key={ps.id}
                        projectService={ps}
                        canEdit
                        dragHandleProps={provided.dragHandleProps}
                        draggableProps={provided.draggableProps}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </table>
      </DragDropContext>
    </div>
  );
};

InspectionServiceSort.propTypes = {
  project: PropTypes.object.isRequired,
  fixedProjectServices: PropTypes.arrayOf(PropTypes.object).isRequired,
  sortableProjectServices: PropTypes.arrayOf(PropTypes.object).isRequired,
  saveRecord: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  fetchRecord,
  saveRecord,
};

export default connect(
  null,
  mapDispatchToProps,
)(InspectionServiceSort);
