import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';
import WorkOrderImportContainer
  from '../containers/WorkOrderImportContainer/WorkOrderImportContainer';

const WorkOrderImport = props => (
  <Provider store={store}>
    <WorkOrderImportContainer {...props} />
  </Provider>
);

export default WorkOrderImport;
