import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Badge from './Badge';
import { CHILD_LISTING_TYPE_MAP, RELATIONSHIP_MAP } from '../helpers/types';
import { taskSort } from '../helpers/sorts';
import { humanize } from '../helpers/string';

const Break = () => (
  <div style={{
    flexBasis: '100%',
    height: 0,
  }}
  />
);

const PendingWork = ({ plan, tasks }) => {
  if (plan.attributes.aasm_state === 'completed') {
    return (
      <td>
        Completed in
        {' '}
        {`${plan.attributes.duration || 0} day${plan.attributes.duration === 1 ? '' : 's'}`}
      </td>
    );
  }
  if (plan.attributes.aasm_state === 'active') {
    return (
      <td>
        <div style={{
          display: 'flex',
          flexWrap: 'wrap',
        }}
        >
          {tasks.map((t, index) => (
            <Fragment key={t.id}>
              <Badge
                {...t.attributes.service_badge}
                style={{
                  marginRight: '5px',
                  marginBottom: '5px',
                }}
                lateNotification={t.attributes.late_badge ? true : false}
              />
              {(index + 1) % 3 === 0 ? <Break /> : null}
            </Fragment>
          ))}

        </div>
      </td>
    );
  }

  return (
    <td>
      {humanize(plan.attributes.aasm_state)}
    </td>
  );
};

const mapStateToProps = (state, ownProps) => {
  const planType = ownProps.plan.type;

  const tasks = Object.values(state.Api[CHILD_LISTING_TYPE_MAP[planType]] || {})
    .filter(t => t.attributes.aasm_state !== 'completed' && t.relationships[RELATIONSHIP_MAP[planType]].data.id === ownProps.plan.id)
    .sort(taskSort);

  return {
    tasks,
  };
};

PendingWork.propTypes = {
  plan: PropTypes.object.isRequired,
  tasks: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(PendingWork);
