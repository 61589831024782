import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord } from 'redux-json-api-module';
import RipplesPurple from '../../Animations/RipplesPurple';

const PurchaseRequestHeader = ({ partsNeeded }) => (
  <div className="row">
    <div className="col text-nowrap">
      Parts Needed
    </div>
    {partsNeeded ? (
      <div className="col p-0">
        <RipplesPurple />
      </div>
    ) : null}
  </div>
);

PurchaseRequestHeader.propTypes = {
  partsNeeded: PropTypes.bool,
};

PurchaseRequestHeader.defaultProps = {
  partsNeeded: false,
};

const mapStateToProps = (state, ownProps) => {
  const task = getRecord(state.Api, { type: ownProps.taskType, id: ownProps.taskId });

  return {
    partsNeeded: !!task?.attributes?.parts_badge,
  };
};

export default connect(
  mapStateToProps,
)(PurchaseRequestHeader);
