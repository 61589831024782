import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord } from 'redux-json-api-module';

const NotificationsSent = ({
  tenantRescheduledEmailSentAt,
  tenantCompletionEmailSentAt,
}) => {
  if (!tenantCompletionEmailSentAt && !tenantRescheduledEmailSentAt) return null;

  return (
    <div>
      {tenantCompletionEmailSentAt ? (
        <div>
          {`Service completed emailed to Tenant, ${moment(tenantCompletionEmailSentAt).format('MM-DD-YYYY HH:mm:ss')}`}
        </div>
      ) : null}
      {tenantRescheduledEmailSentAt ? (
        <div>
          {`Service rescheduled emailed to Tenant, ${moment(tenantRescheduledEmailSentAt).format('MM-DD-YYYY HH:mm:ss')}`}
        </div>
      ) : null}
    </div>
  );
};

NotificationsSent.propTypes = {
  tenantRescheduledEmailSentAt: PropTypes.string,
  tenantCompletionEmailSentAt: PropTypes.string,
};

NotificationsSent.defaultProps = {
  tenantRescheduledEmailSentAt: null,
  tenantCompletionEmailSentAt: null,
};

const mapStateToProps = (state, ownProps) => {
  const workOrder = getRecord(state.Api, { type: 'work_orders', id: ownProps.workOrderId });
  if (!workOrder) return {};

  const {
    tenant_completion_email_sent_at: tenantCompletionEmailSentAt,
    tenant_rescheduled_email_sent_at: tenantRescheduledEmailSentAt,
  } = workOrder.attributes;

  return {
    tenantRescheduledEmailSentAt,
    tenantCompletionEmailSentAt,
  };
};

export default connect(mapStateToProps)(NotificationsSent);
