import React from 'react';
import PropTypes from 'prop-types';
import ProgressiveImage from 'react-progressive-image';

const ProgressiveLoadingImage = ({
  imageClassName,
  sourceUrl,
  placeholderUrl,
  alt,
  onClick,
  imageStyle,
}) => (
  <ProgressiveImage src={sourceUrl} placeholder={placeholderUrl}>
    {(src, loading) => (
      <img
        src={src}
        alt={alt}
        className={imageClassName}
        onClick={onClick}
        style={{
          ...imageStyle,
          opacity: loading ? 0.5 : 1,
        }}
      />
    )}
  </ProgressiveImage>
);

ProgressiveLoadingImage.propTypes = {
  imageClassName: PropTypes.string,
  imageStyle: PropTypes.object,
  alt: PropTypes.string,
  sourceUrl: PropTypes.string.isRequired,
  placeholderUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

ProgressiveLoadingImage.defaultProps = {
  imageClassName: '',
  alt: 'iamge',
  onClick: () => {
  },
  imageStyle: {},
};

export default ProgressiveLoadingImage;
