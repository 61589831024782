import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchRecords, saveRecord } from 'redux-json-api-module';
import get from 'lodash.get';
import { Button } from 'react-bootstrap';
import {
  fetchMessageThreadIndex,
  fetchTaggableUsers,
  fetchMessengers,
  fetchChatChannels,
  touch,
  fetchTeamMembers,
  fetchDirectMessageChats,
  fetchCurrentlyOnline,
  setLastActive, fetchDirectMessageChatMessengers,
} from '../../redux/modules/chat';
import Loader from '../../components/Loader';
import { teamObject, idType } from '../../helpers/propTypes';
import { hydrateOptions } from '../../redux/modules/projectManagement';
import SectionHeader from '../../components/SectionHeader';
import CommunityGroup from './CommunityGroup';
import closeIcon from '../../../assets/images/icon/close.png';
import expandIcon from '../../../assets/images/icon/noun_expand.png';
import Channels from './Channels';
import CreateChannelModal from './CreateChannelModal';
import DirectMessageChats from './DirectMessageChats';
import ChatOnlyTabs from './ChatOnlyTabs';
import Header from './Header';
import colors from '../../helpers/colors';
import Badge from '../../components/Badge';
import CreateDirectMessageModal from './CreateDirectMessageModal';

const imageStyle = {
  width: '30px',
  height: '30px',
};

const buttonStyle = {
  position: 'relative',
  zIndex: 99999,
};

function getChatClass(expandable, taskId) {
  let chatClass;
  if (expandable && taskId) {
    chatClass = 'expandable-chat';
  } else if (expandable && !taskId) {
    chatClass = 'expandable-no-task-chat';
  } else if (taskId) {
    chatClass = 'default-chat';
  } else {
    chatClass = 'no-task-chat px-3';
  }

  return chatClass;
}

const UnreadNotification = ({ top, bottom }) => (
  <div
    className="position-fixed"
    style={{
      top: top ? 103 : undefined,
      bottom: bottom ? 0 : undefined,
      left: 70,
    }}
  >
    <Badge
      label={`${top ? '↑' : '↓'} Unread Mentions`}
      background_color={colors.red}
      text_color={colors.white}
    />
  </div>
);

class ChatsContainer extends Component {
  static propTypes = {
    fetchMessageThreadIndex: PropTypes.func.isRequired,
    fetchMessengers: PropTypes.func.isRequired,
    team: teamObject,
    fetchTaggableUsers: PropTypes.func.isRequired,
    closeModal: PropTypes.func,
    expandable: PropTypes.bool,
    hydrateOptions: PropTypes.func.isRequired,
    touch: PropTypes.func.isRequired,
    fetchChatChannels: PropTypes.func.isRequired,
    fetchTeamMembers: PropTypes.func.isRequired,
    activeChannelId: idType,
    fetchDirectMessageChats: PropTypes.func.isRequired,
    fetchCurrentlyOnline: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    setLastActive: PropTypes.func.isRequired,
    areaItems: PropTypes.object,
    serviceOptions: PropTypes.array,
    conditionOptions: PropTypes.array,
    descriptionOptions: PropTypes.arrayOf(PropTypes.string),
    communities: PropTypes.array.isRequired,
    activePlanId: idType,
    activeDirectMessageChatId: idType,
    modalVisible: PropTypes.bool,
    activeMessageThreadId: idType,
  };

  static defaultProps = {
    closeModal: () => {
    },
    expandable: false,
    team: null,
    activeChannelId: null,
    activePlanId: null,
    activeDirectMessageChatId: null,
    activeMessageThreadId: null,
    modalVisible: false,
  };

  state = {
    loading: true,
    unreadTop: {},
    unreadBottom: {},
    showAllProjects: false,
    showAllWorkOrders: false,
    showCreateDm: false,
  };

  constructor(props) {
    super(props);
    this.threadsList = React.createRef();
    this.handleShowUnread = this.handleShowUnread.bind(this);
    this.handleHideUnread = this.handleHideUnread.bind(this);
  }

  componentDidMount() {
    const {
      fetchMessageThreadIndex,
      team,
      fetchTaggableUsers,
      fetchMessengers,
      areaItems,
      descriptionOptions,
      serviceOptions,
      conditionOptions,
      hydrateOptions,
      fetchChatChannels,
      fetchTeamMembers,
      fetchDirectMessageChats,
      fetchCurrentlyOnline,
      user,
      setLastActive,
      activeMessageThreadId,
      fetchDirectMessageChatMessengers,
    } = this.props;

    hydrateOptions({
      areaItems,
      descriptionOptions,
      serviceOptions,
      conditionOptions,
    });

    const teamId = get(team, 'data.id', null);
    if (!teamId) return;
    fetchTeamMembers(teamId);

    const userId = get(user, 'data.id', null);

    Promise.all([
      fetchMessageThreadIndex(),
      fetchTaggableUsers(teamId),
      fetchDirectMessageChats(userId),
      fetchChatChannels(),
    ])
      .then((responses) => {
        this.setState({ loading: false });
        return responses;
      })
      .then(() => {
        fetchMessengers();
        fetchDirectMessageChatMessengers();
      });

    const lastActiveMessageThreadId = get(user, 'data.relationships.last_active_message_thread.data.id', null);
    if (lastActiveMessageThreadId && !activeMessageThreadId) {
      setLastActive(lastActiveMessageThreadId);
    }

    fetchCurrentlyOnline(teamId);
    this.fetchUserInterval = setInterval(() => fetchCurrentlyOnline(teamId), 10000);
  }

  componentWillUnmount() {
    if (this.fetchUserInterval) clearInterval(this.fetchUserInterval);
  }

  handleShowUnread(threadId, direction) {
    const { unreadTop, unreadBottom } = this.state;

    if (direction === 'top') {
      return this.setState({ unreadTop: { ...unreadTop, [threadId]: true } });
    }

    if (direction === 'bottom') {
      return this.setState({ unreadBottom: { ...unreadBottom, [threadId]: true } });
    }

    return false;
  }

  handleHideUnread(threadId) {
    const { unreadTop, unreadBottom } = this.state;

    return this.setState({
      unreadTop: { ...unreadTop, [threadId]: false },
      unreadBottom: { ...unreadBottom, [threadId]: false },
    });
  }

  hasUnreadTop() {
    const { unreadTop } = this.state;
    return Object.values(unreadTop).find(v => v === true) !== undefined;
  }

  hasUnreadBottom() {
    const { unreadBottom } = this.state;
    return Object.values(unreadBottom).find(v => v === true) !== undefined;
  }

  render() {
    const { loading, showAllProjects, showAllWorkOrders, showCreateDm } = this.state;
    const {
      expandable,
      user,
      closeModal,
      team,
      communities,
      activePlanId,
      activeChannelId,
      activeDirectMessageChatId,
      touch,
      modalVisible,
      activeMessageThreadId,
    } = this.props;

    const userId = get(user, 'data.id', null);
    const isAdmin = get(user, 'data.attributes.app_role', '') === 'admin';

    return (
      <>
        <div className={getChatClass(expandable)}>
          {loading ? <Loader /> : (
            <>
              <Header
                key={activeMessageThreadId}
                userId={userId}
                teamName={get(team, 'data.attributes.label', '')}
              />
              <div className="d-flex">
                <div className="thread-list" ref={this.threadsList}>
                  <SectionHeader title="Channels" className="ml-0" />
                  <Channels
                    userId={userId}
                    threadsList={this.threadsList}
                    onShowUnread={this.handleShowUnread}
                    onHideUnread={this.handleHideUnread}
                  />
                  <SectionHeader title="Make Ready's" className="ml-0" />
                  {communities.map(community => (
                    <CommunityGroup
                      key={community.id}
                      community={community}
                      userId={userId}
                      subjectType="Project"
                      threadsList={this.threadsList}
                      onShowUnread={this.handleShowUnread}
                      onHideUnread={this.handleHideUnread}
                      showRead={showAllProjects}
                    />
                  ))}
                  {!showAllProjects ? (
                    <Button
                      variant="link"
                      size="block"
                      className="text-left pl-5 text-blue"
                      onClick={() => this.setState({ showAllProjects: true })}
                    >
                      <i
                        className="fa fa-angle-down pr-1 font-16"
                      />
                      <span className="font-italic">
                        Show all Make Ready's
                      </span>
                    </Button>
                  ) : null}
                  <SectionHeader title="Work Orders" className="ml-0" />
                  {communities.map(community => (
                    <CommunityGroup
                      key={community.id}
                      community={community}
                      userId={userId}
                      subjectType="WorkOrder"
                      threadsList={this.threadsList}
                      onShowUnread={this.handleShowUnread}
                      onHideUnread={this.handleHideUnread}
                      showRead={showAllWorkOrders}
                    />
                  ))}
                  {!showAllWorkOrders ? (
                    <Button
                      variant="link"
                      size="block"
                      className="text-left pl-5 text-blue"
                      onClick={() => this.setState({ showAllWorkOrders: true })}
                    >
                      <i
                        className="fa fa-angle-down pr-1 font-16"
                      />
                      <span className="font-italic">
                        Show all Work Orders
                      </span>
                    </Button>
                  ) : null}
                  <SectionHeader title="Direct Messages" className="ml-0" />
                  <div>
                    <DirectMessageChats
                      userId={userId}
                      appRole="management"
                      threadsList={this.threadsList}
                      onShowUnread={this.handleShowUnread}
                      onHideUnread={this.handleHideUnread}
                      header="Management Team"
                    />
                  </div>
                  <div>
                    <DirectMessageChats
                      userId={userId}
                      appRole="maintenance"
                      threadsList={this.threadsList}
                      onShowUnread={this.handleShowUnread}
                      onHideUnread={this.handleHideUnread}
                      header="Maintenance Team"
                    />
                  </div>
                  <Button
                    variant="link"
                    size="block"
                    className="text-left pl-5 text-blue"
                    onClick={() => this.setState({ showCreateDm: true })}
                  >
                    <i className="fa fa-plus pr-1 font-16 font-italic" />
                    Add Direct Message
                  </Button>
                  {this.hasUnreadTop() ? (
                    <UnreadNotification top />
                  ) : null}
                  {this.hasUnreadBottom() ? (
                    <UnreadNotification bottom />
                  ) : null}
                </div>
                <div className="chat-window ml-2">
                  {expandable ? (
                    <>
                      <Button
                        variant="link"
                        className="float-right p-0"
                        style={buttonStyle}
                        onClick={closeModal}
                      >
                        <img src={closeIcon} style={imageStyle} />
                      </Button>
                      <a href="/chats" className="float-right" target="_blank" style={buttonStyle}>
                        <img src={expandIcon} style={imageStyle} />
                      </a>
                    </>
                  ) : null}
                  {activeChannelId || activeDirectMessageChatId || activePlanId ? (
                    <ChatOnlyTabs userId={userId} team={team} onInputFocus={touch} autoFocus />
                  ) : null}
                </div>
              </div>
            </>
          )}
        </div>
        {modalVisible ? <CreateChannelModal userId={userId} isAdmin={isAdmin} /> : null}
        {showCreateDm ? (
          <CreateDirectMessageModal
            show={showCreateDm}
            onHide={() => this.setState({ showCreateDm: false })}
          />
        ) : null}
      </>
    );
  }
}

const mapDispatchToProps = {
  fetchRecords,
  saveRecord,
  fetchMessageThreadIndex,
  fetchTaggableUsers,
  fetchMessengers,
  fetchDirectMessageChatMessengers,
  hydrateOptions,
  touch,
  fetchChatChannels,
  fetchTeamMembers,
  fetchDirectMessageChats,
  fetchCurrentlyOnline,
  setLastActive,
};

const mapStateToProps = state => ({
  communityIdMap: state.Chat.communityIdMap || {},
  communities: state.Chat.communities || [],
  activePlanId: state.Chat.activePlanId,
  activeCommunityId: state.Chat.activeCommunityId,
  activeChannelId: state.Chat.activeChannelId,
  modalVisible: state.Chat.creatingChannel || state.Chat.editingChannelId,
  activeDirectMessageChatId: state.Chat.activeDirectMessageChatId,
  activeMessageThreadId: state.Chat.activeMessageThreadId,
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatsContainer);
