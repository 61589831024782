import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';
import ActivityContainer from '../containers/ActivityContainer';

const Activity = props => (
  <Provider store={store}>
    <ActivityContainer {...props} />
  </Provider>
);

export default Activity;
