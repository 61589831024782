import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Lottie from 'react-lottie';
import { Button } from 'react-bootstrap';
import animationData from '../../../../assets/lotties/checked-done.json';
import COLORS from '../../../helpers/colors';
import DelayedLottie from './DelayedLottie';

const options = {
  loop: false,
  autoplay: true,
  animationData,
};

const SuccessModal = ({
  visible,
  closeModal,
}) => (
  <Modal
    show={visible}
    onHide={closeModal}
    size="lg"
  >
    <div className="modal-body" style={{ border: `1px solid ${COLORS.greyBorder}` }}>
      <div style={{ border: `1px solid ${COLORS.greyBorder}` }} className="m-2 p-3">
        <p className="mt-3 text-center font-weight-bold" style={{ fontSize: '22px' }}>
          Work Orders Imported Successfully!
        </p>

        <div>
          <DelayedLottie options={options} height={200} width={200} />
        </div>

        <p className="mt-3 text-center font-weight-bold" style={{ fontSize: '18px', paddingLeft: '80px', paddingRight: '80px' }}>
          Next, please assign and schedule your work orders. Your assigned team members will be notified, and they will mark services as completed on their Intimely mobile app.
        </p>

        <div style={{ marginTop: '35px' }}>
          <Button size="md" type="button" onClick={closeModal} variant="info" className="mx-auto d-flex justify-content-center">
            Next
          </Button>
        </div>
      </div>
    </div>

  </Modal>
);

SuccessModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default SuccessModal;
