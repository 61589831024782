import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord } from 'redux-json-api-module';
import ChecklistItemsForm from '../ChecklistItemsForm';
import ManagementNotes from './ManagementNotes/ManagementNotes';
import CompletedChecklistItems from './CompletedChecklistItems';
import { NextArrow, PrevArrow } from './helpers';
import { fetchTask } from '../../redux/modules/projectManagement';
import Loader from '../Loader';
import { idType } from '../../helpers/propTypes';
import TaskForm from '../TaskForm';
import TaskBadgeHeader from './TaskBadgeHeader';

class NotesTab extends PureComponent {
  state = {
    loading: true,
  };

  componentDidMount() {
    const { fetchTask, taskType, taskId } = this.props;

    fetchTask(taskType, taskId)
      .then(() => this.setState({ loading: false }));
  }

  getControlArrows = (slideCount) => {
    // really needs to be divided by slidesToScroll
    // because the library controls the placement of the buttons, it's probably easier
    // to create a custom control component rather than this much absolute positioning
    const size = slideCount / 2;
    return {
      nextArrow: <NextArrow btnClassName="bottom-control" relativeControls sliderCount={size} />,
      prevArrow: <PrevArrow btnClassName="bottom-control" relativeControls sliderCount={size} />,
    };
  };

  render() {
    const { task } = this.props;

    if (!task) return null;

    const { loading } = this.state;

    if (loading) return <Loader />;

    return (
      <div>
        <TaskBadgeHeader taskId={task.id} taskType={task.type} />

        <h3 className="border-bottom pb-2 mb-3">Notes/Checklist</h3>

        {task.attributes.aasm_state === 'completed' ? (
          <React.Fragment>
            <ManagementNotes task={task} />

            <CompletedChecklistItems task={task} />
          </React.Fragment>
        ) : (
          <div className="row">
            <div className="col-md-6">
              <TaskForm
                loading={false}
                initialValues={{ management_notes: task.attributes.management_notes }}
                task={task}
              />
            </div>

            <div className="col-md-6">
              <ChecklistItemsForm
                parent={task}
                checklistItemType="checklist_items"
                allowAdditions={task.attributes.aasm_state !== 'completed'}
                title="Checklist Items"
                instructions="Please add checklist items for the service provider"
                key={`checklist-items-form-${task.id}`}
              />
            </div>
          </div>

        )}
      </div>
    );
  }
}

NotesTab.propTypes = {
  task: PropTypes.objectOf(PropTypes.any),
  taskType: PropTypes.string.isRequired,
  taskId: idType.isRequired,
  fetchTask: PropTypes.func.isRequired,
};

NotesTab.defaultProps = {
  task: null,
};

const mapStateToProps = (state, ownProps) => ({
  task: getRecord(state.Api, {
    type: ownProps.taskType,
    id: ownProps.taskId,
  }),
});

const mapDispatchToProps = {
  fetchTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotesTab);
