import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord } from 'redux-json-api-module';
import { genericRecordPropType, idType } from '../../../helpers/propTypes';

const ScheduleCell = ({ scheduleString }) => scheduleString;

ScheduleCell.propTypes = {
  taskId: idType.isRequired,
  taskType: PropTypes.string.isRequired,

  // connected
  user: genericRecordPropType,
};

ScheduleCell.defaultProps = {
  user: null,
};

const mapStateToProps = (state, ownProps) => {
  const task = getRecord(state.Api, { type: ownProps.taskType, id: ownProps.taskId });

  return {
    scheduleString: task.attributes.schedule_string,
  };
};

export default connect(
  mapStateToProps,
)(ScheduleCell);
