import React from 'react';
import PropTypes from 'prop-types';

const visible = className => !className.includes('slick-disabled');

const propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  btnClassName: PropTypes.string,
  sliderCount: PropTypes.number,
  relativeControls: PropTypes.bool,
};

const defaultProps = {
  className: '',
  onClick: undefined,
  btnClassName: '',
  sliderCount: 0,
  relativeControls: false,
};

function getPosition(sliderCount) {
  if (sliderCount < 8) {
    return `calc(50% - ${sliderCount / 2 * 30 + 50}px)`;
  }
  return '15px';
}

export const NextArrow = ({
  className,
  onClick,
  btnClassName,
  sliderCount,
  relativeControls,
}) => visible(className) && (
  <button
    className={`btn btn-arrow-next ${btnClassName}`}
    type="button"
    onClick={onClick}
    style={relativeControls ? { right: getPosition(sliderCount) } : {}}
  >
    <i className="ti-angle-right" />
  </button>
);

NextArrow.propTypes = propTypes;
NextArrow.defaultProps = defaultProps;

export const PrevArrow = ({
  className,
  onClick,
  btnClassName,
  sliderCount,
  relativeControls,
}) => visible(className) && (
  <button
    className={`btn btn-arrow-prev ${btnClassName}`}
    type="button"
    onClick={onClick}
    style={relativeControls ? { left: getPosition(sliderCount) } : {}}
  >
    <i className="ti-angle-left" />
  </button>
);

PrevArrow.propTypes = propTypes;
PrevArrow.defaultProps = defaultProps;

export const SETTINGS = {
  className: 'slick-left slick-padded slick-service-carousel mb-5',
  centerMode: false,
  dots: true,
  draggable: false,
  infinite: false,
  lazyLoad: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const FULL_PAGE_SETTINGS = {
  className: 'slick-left slick-padded slick-service-carousel mb-5',
  centerMode: false,
  dots: true,
  draggable: false,
  infinite: false,
  lazyLoad: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

export const CHAT_INDEX_SETTINGS = {
  ...SETTINGS,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
