import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import Badge from '../Badge';
import COLORS from '../../helpers/colors';
import './PrioritySelector.scss';
import { Error } from '../../containers/ProjectCreateContainer/ProjectCreateForm/inputs';

const OPTIONS = [
  {
    label: 'Low',
    value: 'low',
    background_color: COLORS.conditionYellow,
    text_color: COLORS.white,
  },
  {
    label: 'Normal',
    value: 'medium',
    background_color: COLORS.conditionOrange,
    text_color: COLORS.white,
  },
  {
    label: 'Urgent',
    value: 'urgent',
    background_color: COLORS.conditionRed,
    text_color: COLORS.white,
  },
];

function getOption(priority) {
  return OPTIONS.find(option => option.value === priority);
}

const PriorityOption = props => (
  <components.Option {...props}>
    <PriorityDisplay {...props.data} />
  </components.Option>
);

const PriorityDisplay = ({ label, background_color, text_color }) => (
  <div className="priority__option">
    <Badge label={label} background_color={background_color} text_color={text_color} />
  </div>
);

PriorityDisplay.propTypes = {
  label: PropTypes.string.isRequired,
};

const menuPortal = (base) => {
  const { zIndex, ...rest } = base;

  return {
    ...rest,
    zIndex: 9999,
  };
};

class PrioritySelector extends Component {
  static propTypes = {
    priorityBadge: PropTypes.object,
    priority: PropTypes.string,
    input: PropTypes.object,
  };

  static defaultProps = {
    priorityBadge: null,
    priority: null,
    menuPortalTarget: document.body,
    input: null,
  };

  state = {
    priority: null,
  };

  handleChange = (priority) => {
    const { input } = this.props;
    this.setState({ priority: priority.value });
    if (input) input.onChange(priority.value);
  };

  render() {
    const { menuPortalTarget, errors, input } = this.props;

    const { priority } = this.state;

    const priorityValue = priority || (input && input.value);

    const value = priorityValue
      ? [{
        value: priorityValue,
        label: <PriorityDisplay {...getOption(priorityValue)} />,
      }]
      : null;

    const prioritySelectErrors = errors && errors.priority;

    return (
      <div className="priority-select-wrapper">
        <Select
          classNamePrefix="priority-select"
          className="priority-select"
          components={{ Option: PriorityOption }}
          isSearchable={false}
          menuPortalTarget={menuPortalTarget}
          onChange={this.handleChange}
          options={OPTIONS}
          placeholder="Select Priority"
          styles={{ menuPortal }}
          value={value}
        />
        {prioritySelectErrors ? (
          prioritySelectErrors.map(error => <Error text={error} key={error} />)
        ) : null}
      </div>
    );
  }
}

const mapDispatchToProps = {};

export default connect(null, mapDispatchToProps)(PrioritySelector);
