import React from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import PropTypes from 'prop-types';
import { submitManagementNotes } from '../../redux/modules/projectManagement';
import './TaskForm.scss';

class TaskForm extends React.PureComponent {
  state = {
    submitting: false,
  };

  handleSubmit = (values) => {
    const { submitManagementNotes, task } = this.props;

    this.setState({ submitting: true });

    submitManagementNotes(task, values)
      .then(() => {
        this.setState({ submitting: false });
      });
  };

  render() {
    const { loading, initialValues } = this.props;

    return (
      <Form
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        render={({ handleSubmit, pristine, form }) => (
          <form onSubmit={handleSubmit} className="w-100">
            <h4 className="form-header">
              Service Notes
            </h4>
            <div className="form-body">
              <Field
                name="management_notes"
                render={({ input }) => (
                  <div>
                    <label className="form-control-label">
                      Please add notes or instructions for the service provider
                    </label>
                    <textarea rows={4} className="w-100" {...input} />
                  </div>
                )}
              />
            </div>
            <button
              type="submit"
              className={`btn btn-success animated ${pristine ? 'invisible' : 'visible fadeIn'}`}
              disabled={loading}
            >
              Save
            </button>
            <button
              type="button"
              className={`btn btn-secondary m-l-10 animated ${pristine ? 'invisible' : 'visible fadeIn'}`}
              disabled={loading}
              onClick={form.reset}
            >
              Cancel
            </button>
          </form>
        )}
      />
    );
  }
}

TaskForm.propTypes = {
  submitManagementNotes: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  task: PropTypes.object.isRequired,
  initialValues: PropTypes.object.isRequired,
};

const mapDispatchToProps = {
  submitManagementNotes,
};

export default connect(null, mapDispatchToProps)(TaskForm);
