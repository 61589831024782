import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Error } from '../ProjectCreateContainer/ProjectCreateForm/inputs';

const TextField = ({ label, name, required, errors, autoFocus }) => (
  <div className="form-group">
    <div className="form-group email required">
      <label className="form-control-label required">
        {label}
        {required ? <abbr title="required">*</abbr> : null}
      </label>
      <Field
        name={name}
        component="input"
        type="text"
        placeholder=""
        className="form-control string required"
        autoFocus={autoFocus}
      />
      {errors ? errors.map(error => <Error text={error} key={error} />) : null}
    </div>
  </div>
);

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.array,
  required: PropTypes.bool,
  autoFocus: PropTypes.bool,
};

TextField.defaultProps = {
  required: false,
  errors: [],
  autoFocus: false,
};

export default TextField;
