import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord, getRelationship } from 'redux-json-api-module';
import COLORS from '../../helpers/colors';
import { getKeysReceivedOn, dueOn, estimatedDone, isLate } from '../../components/Summary/helpers';
import Badge from '../../components/Badge';
import PendingWork from '../../components/PendingWork';

const ProjectRow = ({ plan, unit }) => (
  <tr className="v-middle">
    <td>
      <a href={`/projects/${plan.id}`}>
        <div>
          {unit.attributes.street_address}
          <br />
          {unit.attributes.unit_number}
        </div>
      </a>
    </td>
    <td>{getKeysReceivedOn(plan)}</td>
    <PendingWork plan={plan} />
    <td>
      <Badge {...plan.attributes.status} />
      {plan.attributes.late_badge ? (
        <Badge {...plan.attributes.late_badge} className="ml-1" />
      ) : null}
      {plan.attributes.parts_badge ? (
        <Badge {...plan.attributes.parts_badge} className="ml-1" />
      ) : null}
    </td>
    <td>{dueOn(plan)}</td>
    <td style={isLate(plan) ? {
      fontWeight: 'bold',
      color: COLORS.red,
    } : {}}
    >
      {estimatedDone(plan)}
    </td>
  </tr>
);

const mapStateToProps = (state, ownProps) => {
  const plan = getRecord(state.Api, {
    type: 'project_listings',
    id: ownProps.planId,
  });
  const unit = getRelationship(state.Api, plan.relationships.unit);

  return {
    plan,
    unit,
  };
};

ProjectRow.propTypes = {
  plan: PropTypes.object.isRequired,
  unit: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(ProjectRow);
