import React from 'react';
import PropTypes from 'prop-types';
import COLORS from '../../helpers/colors';
import { idType } from '../../helpers/propTypes';

const OnlineBubble = ({ online, otherUserId }) => (
  <div
    id={`online-bubble-${otherUserId}-${online}`}
    style={{
      display: 'inline-block',
      border: `1px solid ${COLORS.blue}`,
      width: '14px',
      height: '14px',
      borderRadius: '7px',
      backgroundColor: online ? COLORS.blue : COLORS.white,
    }}
  />
);

OnlineBubble.propTypes = {
  online: PropTypes.bool.isRequired,
  otherUserId: idType.isRequired,
};

export default OnlineBubble;
