import React, { Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Dropzone from 'react-dropzone';
import { fetchRecord, getRecord } from 'redux-json-api-module';
import paperclipIcon from '../../../assets/images/icon/noun-paperclip.png';
import { getMessenger } from '../../redux/modules/chat';
import { includeData } from '../../redux/modules/projectManagement';

const uuidv4 = require('uuid/v4');

class MessengerActions extends PureComponent {
  static propTypes = {
    toggleEmojiSelection: PropTypes.func.isRequired,
    toggleTagging: PropTypes.func.isRequired,
    messageThreadId: PropTypes.string.isRequired,
    messageId: PropTypes.string,
    fetchRecord: PropTypes.func.isRequired,
    includeData: PropTypes.func.isRequired,
    canTag: PropTypes.bool.isRequired,
    messengerData: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  };

  static defaultProps = {
    messageId: undefined,
  };

  getFormData = (file, id) => {
    const { messageThreadId } = this.props;
    const formData = new FormData();

    formData.append('data[id]', id);
    formData.append('data[attributes][attachment]', file);
    formData.append('data[attributes][message_thread_id]', messageThreadId);
    formData.append('Content-Type', 'multipart/formdata');

    return formData;
  };

  onDrop = (acceptedFiles) => {
    const { fetchRecord, includeData, messageThreadId, messengerData } = this.props;
    acceptedFiles.forEach((file) => {
      const tempId = uuidv4();

      const formData = this.getFormData(file, tempId);

      const createdAt = Date.now();

      const attributes = {
        id: tempId,
        body: '',
        message_thread_id: messageThreadId,
        created_at: createdAt,
        message_type: 'simple',
        messenger: messengerData,
      };

      const message = {
        type: 'messages',
        id: tempId,
        attributes,
      };

      const includedMessage = {
        ...message,
        id: tempId,
      };

      includedMessage.attributes.attachment = { uploading: true };

      includeData({ data: includedMessage });

      fetch('/api/v1/messages', {
        method: 'POST',
        body: formData,
      })
        .then(resp => resp.json())
        .then(data => fetchRecord('messages', data.data.id))
        .catch(error => console.log(error));
    });
  };

  render() {
    const { toggleEmojiSelection, toggleTagging, messageId, canTag } = this.props;

    return (
      <div className="row messenger-actions">
        <div className="col">
          <ButtonToolbar>
            {!messageId ? (
              <Fragment>
                <Button id="emoji-btn" variant="icon" onClick={toggleEmojiSelection}>
                  <span role="img" aria-label="emoji">😀</span>
                </Button>
                <Dropzone onDrop={this.onDrop}>
                  {({ getRootProps, getInputProps, isDragActive }) => (
                    <div
                      id="messenger-dropzone"
                      {...getRootProps()}
                      className={`dropzone ${isDragActive ? 'dropzone--isActive' : ''}`}
                    >
                      <input name="data[attributes][file]" {...getInputProps()} />
                      <Button variant="icon" onClick={() => undefined}>
                        <img
                          src={paperclipIcon}
                          alt="attachment icon"
                          style={{
                            width: '30px',
                            height: '30px',
                          }}
                        />
                      </Button>
                    </div>
                  )}
                </Dropzone>
              </Fragment>
            ) : null}
            {canTag ? (
              <Button variant="icon" onClick={toggleTagging} id="tagging-btn">
                <span role="img" aria-label="tagging" id="tagging-btn-img">@</span>
              </Button>
            ) : null}
          </ButtonToolbar>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const messenger = getMessenger(state, ownProps.messageThreadId, ownProps.userId);
  const messageThread = getRecord(state.Api, { type: 'message_threads', id: ownProps.messageThreadId });

  return {
    messengerData: messenger ? messenger.attributes.user : {},
    canTag: get(messageThread, 'attributes.subject_type', null) !== 'DirectMessageChat',
  };
};
const mapDispatchToProps = {
  fetchRecord,
  includeData,
};

export default connect(mapStateToProps, mapDispatchToProps)(MessengerActions);
