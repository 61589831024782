import get from 'lodash.get';

export function simpleFormat(str, noParagraph) {
  let html = str || '';
  html = html.replace(/\r\n?/, '\n');
  html = $.trim(html);

  if (html.length > 0) {
    html = html.replace(/\n\n+/g, '</p><p>');
    html = html.replace(/\n/g, '<br />');
    if (!noParagraph) html = `<p>${html}</p>`;
  }

  return html;
}

export function pluralize(str, count, plural) {
  if (Math.abs(count) <= 1) return str;
  return plural || `${str}s`;
}

export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function isBlank(str) {
  return str === undefined || str === null || str === '';
}

export function initials(str) {
  return str ? str.split(' ')
    .slice(0, 2)
    .map(n => n[0])
    .join('') : null;
}

export const humanize = string => (
  (string || '').split('_')
    .map(s => s.charAt(0)
      .toUpperCase() + s.substring(1))
    .join(' ')
);

export function idComparison(id1, id2) {
  if (!id1 || !id2) return false;

  return id1.toString() === id2.toString();
}

export function relComparison(rel1, rel2) {
  if (!rel1 || !rel2) return false;

  const type1 = get(rel1, 'data.type', null);
  const type2 = get(rel2, 'data.type', null);

  const id1 = get(rel1, 'data.id', null);
  const id2 = get(rel2, 'data.id', null);

  return type1 === type2 && idComparison(id1, id2);
}

export const numberToCurrency = val => (
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(val)
);
