import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchRecord, getRecord, getRelationship } from 'redux-json-api-module';
import {
  hydrateProjectManagementStore,
  setValidationWarning,
} from '../../redux/modules/projectManagement';
import { setCurrentUser } from '../../redux/modules/auth';
import { getInspection } from '../../helpers/inspection';
import Loader from '../../components/Loader';
import ProjectSummary from '../../components/ProjectSummary/ProjectSummary';
import ServiceFeatureCards
  from '../InspectionReviewContainer/ServiceFeatureCards/ServiceFeatureCards';
import ProjectServiceForm from '../ProjectManagementContainer/ServiceForm/ProjectServiceForm';

class InspectionSummaryContainer extends Component {
  static propTypes = {
    projectId: PropTypes.number.isRequired,
  };

  state = {
    changeServiceFormVisible: false,
    currentFeature: null,
  };

  componentDidMount() {
    const {
      hydrateProjectManagementStore, fetchRecord, projectId,
      setCurrentUser, currentUser,
      areaItems, descriptionOptions, serviceOptions, conditionOptions,
    } = this.props;

    hydrateProjectManagementStore({
      projectId,
      areaItems,
      descriptionOptions,
      serviceOptions,
      conditionOptions,
    });

    fetchRecord(
      'projects', projectId, {
        include: [
          'attachments', 'attachments.user', 'community',
          'project_services_with_deleted',
          'project_services_with_deleted.service',
          'project_services_with_deleted.features',
          'project_services_with_deleted.checklist_items',
          'project_services_with_deleted.project_service_images',
          'rooms', 'team', 'team.memberships', 'team.memberships.user', 'unit',
        ].join(','),
      },
    );

    setCurrentUser(currentUser.data);
  }

  openChangeServiceForm = (feature) => {
    this.setState({
      changeServiceFormVisible: true,
      currentFeature: feature,
    });
  };

  closeChangeServiceForm = () => {
    this.setState({ changeServiceFormVisible: false });
  };

  openEditServiceForm = (feature) => {
    this.setState({
      editServiceFormVisible: true,
      currentFeature: feature,
    });
  };

  closeEditServiceForm = () => {
    this.setState({ editServiceFormVisible: false });
  };

  render() {
    const {
      fetchRecord,
      project,
      projectServices,
      areaItems, descriptionOptions, serviceOptions, conditionOptions,
    } = this.props;

    const { changeServiceFormVisible, currentFeature, editServiceFormVisible } = this.state;

    return project ? (
      <>
        <ProjectSummary project={project} />

        {projectServices.map(ps => (
          <React.Fragment key={ps.id}>
            <ServiceFeatureCards
              task={ps}
              onBulkChangeSuccess={() => {
                fetchRecord('projects', project.id, { include: 'project_services_with_deleted' });
              }}
              openChangeServiceForm={this.openChangeServiceForm}
              openEditServiceForm={this.openEditServiceForm}
              summary
            />
            <hr />
          </React.Fragment>
        ))}

        <ProjectServiceForm
          componentType="ChangeService"
          key={`change-${currentFeature && currentFeature.id}`}
          visible={changeServiceFormVisible}
          onClose={this.closeChangeServiceForm}
          featureId={currentFeature && currentFeature.id}
          featureType={currentFeature && currentFeature.type}
          areaItems={areaItems}
          descriptionOptions={descriptionOptions}
          serviceOptions={serviceOptions}
          conditionOptions={conditionOptions}
        />

        <ProjectServiceForm
          componentType="Edit"
          key={`edit-${currentFeature && currentFeature.id}`}
          visible={editServiceFormVisible}
          onClose={this.closeEditServiceForm}
          featureId={currentFeature && currentFeature.id}
          featureType={currentFeature && currentFeature.type}
          areaItems={areaItems}
          descriptionOptions={descriptionOptions}
          serviceOptions={serviceOptions}
          conditionOptions={conditionOptions}
        />
      </>
    ) : <Loader />;
  }
}

const projectServiceSort = (project, projectServices) => (
  projectServices.sort((a, b) => {
    const order = project.attributes.task_order;

    if (order) {
      return order.indexOf(a.id) - order.indexOf(b.id);
    }

    return a.attributes.sort - b.attributes.sort;
  })
);

const mapStateToProps = (state, ownProps) => {
  const project = getRecord(state.Api, {
    type: 'projects',
    id: ownProps.projectId,
  });

  const projectServices = project ? (
    projectServiceSort(
      project,
      getRelationship(state.Api, project.relationships.project_services_with_deleted),
    )
  ) : [];

  return {
    project,
    projectServices: projectServices.filter(ps => !ps.attributes.is_inspection),
    inspection: project && getInspection(project, state.Api),
  };
};

const mapDispatchToProps = {
  fetchRecord,
  hydrateProjectManagementStore,
  setCurrentUser,
  setValidationWarning,
};

export default connect(mapStateToProps, mapDispatchToProps)(InspectionSummaryContainer);
