import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import get from 'lodash.get';
import { attachmentObject } from '../../helpers/propTypes';
import LazyProgressiveImage from '../LazyProgressiveImage';

const ImageMessage = ({ attachment }) => {
  const [open, setOpen] = useState(false);

  const sourceUrl = get(attachment, 'thumb.url', null);
  const placeholderUrl = get(attachment, 'thumb_placeholder_sq.url', null);

  return (
    <React.Fragment>
      {open
        ? (
          <Lightbox
            mainSrc={attachment.url}
            onCloseRequest={() => setOpen(false)}
          />
        ) : null}
      <div style={{
        height: '150px',
        width: '170px',
      }}
      >
        <LazyProgressiveImage
          sourceUrl={sourceUrl}
          placeholderUrl={placeholderUrl}
          onClick={() => setOpen(true)}
          alt="Image"
          imageStyle={{
            width: '150px',
            height: '150px',
            objectFit: 'cover',
          }}
          imageClassName="cursor-pointer"
          scrollContainer=".chat-list"
        />
      </div>
    </React.Fragment>
  );
};

ImageMessage.propTypes = {
  attachment: attachmentObject.isRequired,
};

export default ImageMessage;
