import React from 'react';
import PropTypes from 'prop-types';
import { Overlay, Popover } from 'react-bootstrap';

class PopoverStickOnHover extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      popoverFullyHidden: true,
      popoverDisplayed: false,
    };

    this.hideTimeout = null;
  }

  handleMouseEnter = () => {
    const { onMouseEnter } = this.props;

    this.setState({
      popoverFullyHidden: false,
      popoverDisplayed: true,
    });
    if (onMouseEnter) onMouseEnter();

    if (this.hideTimeout) {
      clearTimeout(this.hideTimeout);
      this.hideTimeout = null;
    }
  };

  handleMouseLeave = () => {
    const { onMouseLeave, delay } = this.props;
    this.setState({ popoverDisplayed: false });
    this.hideTimeout = setTimeout(() => {
      if (onMouseLeave) onMouseLeave();
      this.setState({ popoverFullyHidden: true });
    }, delay);
  };

  componentWillUnmount() {
    if (this.setTimeoutConst) {
      clearTimeout(this.setTimeoutConst);
    }
  }

  render() {
    const { component, children, placement } = this.props;

    const child = React.Children.map(children, child => (
      React.cloneElement(child, {
        onMouseEnter: this.handleMouseEnter,
        onMouseLeave: this.handleMouseLeave,
        ref: (node) => {
          this._child = node;
          const { ref } = child;
          if (typeof ref === 'function') {
            ref(node);
          }
        },
      })
    ))[0];

    return (
      <React.Fragment>
        {child}
        <Overlay
          show={!this.state.popoverFullyHidden}
          placement={placement}
          target={this._child}
          shouldUpdatePosition
        >
          <Popover
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
            id="action-popover"
          >
            {component}
          </Popover>
        </Overlay>
      </React.Fragment>
    );
  }
}

PopoverStickOnHover.defaultProps = {
  delay: 0,
};

PopoverStickOnHover.propTypes = {
  delay: PropTypes.number,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  children: PropTypes.element.isRequired,
  component: PropTypes.node.isRequired,
};

export default PopoverStickOnHover;
