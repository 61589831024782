import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { getRecord } from 'redux-json-api-module';
import BadgeHeader from './BadgeHeader';

const mapStateToProps = (state, ownProps) => {
  const task = getRecord(state.Api, {
    type: ownProps.taskType,
    id: ownProps.taskId,
  });
  const serviceBadge = get(task, 'attributes.service_badge', null);
  const conditionBadge = get(task, 'attributes.condition_badge', null);
  const serviceStatus = get(task, 'attributes.service_status.private', null);
  const priorityBadge = get(task, 'attributes.priority_badge', null);
  const lateBadge = get(task, 'attributes.late_badge', null);

  return {
    serviceBadge,
    conditionBadge,
    serviceStatus,
    priorityBadge,
    lateBadge,
  };
};

export default connect(mapStateToProps)(BadgeHeader);
