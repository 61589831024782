import React from 'react';
import Alert from '../../components/Alert';

const CommunitiesAlert = () => (
  <Alert
    type="info"
    iconClassName="fa fa-exclamation-circle"
    title="Select the Communities your Team manages"
  >
    You can select the Communities your Team manages by clicking the checkboxes below. Only
    the units belonging to your selected Communities will display as available units for
    your Team when creating new Make Ready or Work Order projects.
  </Alert>
);

export default CommunitiesAlert;
