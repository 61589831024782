import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { fetchRecords } from "redux-json-api-module";
import DatePicker from 'react-datepicker';

const PlansForm = ({ communityId, onSubmit, communities, fetchRecords }) => {
  const [units, setUnits] = useState([]);
  const [services, setServices] = useState([]);

  const fetchUnits = (communityId) => {
    const params = {
      filter: {
        community_id: communityId,
      },
      sort: 'street_address,number',
      page: {
        size: 1000,
      },
    };

    fetchRecords('units', params).then((resp) => {
      setUnits(resp.payload.data.data);
    });
  };

  const fetchServices = () => {
    const params = {
      sort: 'name',
      page: {
        size: 1000,
      },
    };

    fetchRecords('services', params).then((resp) => {
      setServices(resp.payload.data.data);
    });
  };

  useEffect(() => {
    fetchUnits(communityId);
    fetchServices();
  }, [communityId]);

  return (
    <Form
      initialValues={{
        planType: 'projects',
        communityId,
        unitId: null,
        startDate: null,
        endDate: null,
      }}
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <div className="row">
            <Field name="planType">
              {({ input }) => (
                <div className="form-group mr-2">
                  <select
                    {...input}
                    className="form-control select-primary"
                  >
                    <option value="projects">Make Ready</option>
                    <option value="work_orders">Work Orders</option>
                  </select>
                </div>
              )}
            </Field>
            <Field name="communityId">
              {({ input }) => (
                <div className="form-group mr-2">
                  <select
                    {...input}
                    className="form-control select-primary"
                    onChange={(e) => {
                      fetchUnits(e.target.value);
                      input.onChange(e.target.value);
                    }}
                  >
                    {communities.map(c => (
                      <option key={c.id} value={c.id}>
                        {c.attributes.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </Field>
            <Field name="unitId">
              {({ input }) => (
                <div className="form-group mr-2">
                  <select
                    {...input}
                    className="form-control select-primary"
                  >
                    <option value={-1}>All Units</option>
                    {units.map(unit => (
                      <option key={unit.id} value={unit.id}>
                        {unit.attributes.short_address}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </Field>
            <Field name="serviceId">
              {({ input }) => (
                <div className="form-group mr-2">
                  <select
                    {...input}
                    className="form-control select-primary"
                  >
                    <option value={-1}>All Services</option>
                    {services.map(service => (
                      <option key={service.id} value={service.id}>
                        {service.attributes.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </Field>
          </div>
          <div className="row">
            <Field name="startDate">
              {({ input }) => (
                <div className="form-group mr-2">
                  <DatePicker
                    {...input}
                    selected={input.value}
                    className="form-control"
                    selectsStart
                    placeholderText="Start Date"
                  />
                </div>
              )}
            </Field>
            <Field name="endDate">
              {({ input }) => (
                <div className="form-group mr-2">
                  <DatePicker
                    {...input}
                    selected={input.value}
                    className="form-control"
                    selectEnd
                    placeholderText="End Date"
                    startDate={values.startDate}
                    endDate={values.endDate}
                    minDate={values.startDate}
                  />
                </div>
              )}
            </Field>
          </div>
          <div className="row">
            <button type="submit" className="btn btn-md btn-primary">
              Search
            </button>
          </div>
        </form>
      )}
    />
  );
};

PlansForm.propTypes = {
  // connected
  communities: PropTypes.arrayOf(PropTypes.object).isRequired,
};

function mapStateToProps(state) {
  return {
    communities: Object.values(state.Api.communities || {}),
  };
}

const mapDispatchToProps = {
  fetchRecords,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PlansForm);
