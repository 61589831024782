import React from 'react';

const Pagination = ({ value, length, onChange }) => (
  <div className="lightbox-pagination">
    <input
      type="number"
      min={1}
      max={length}
      value={value}
      onChange={e => onChange(e.target.value)}
    />
    {` of ${length}`}
  </div>
);

export default Pagination;
