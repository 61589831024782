import React, { useContext, useLayoutEffect, useRef } from 'react';
import { IsDraggingContext } from './IsDraggingContext';

const TableCell = (props) => {
  const ref = useRef(null);
  const isDragOccuring = useContext(IsDraggingContext);
  const prevIsDragOccuringRef = useRef(isDragOccuring);
  const snapshotRef = useRef(null);

  useLayoutEffect(() => {
    const element = ref.current;
    const isDragStarting = isDragOccuring && !prevIsDragOccuringRef.current;
    prevIsDragOccuringRef.current = isDragOccuring;

    if (isDragStarting) {
      const { width, height } = element.getBoundingClientRect();
      snapshotRef.current = { width, height };
    } else {
      snapshotRef.current = null;
    }

    const snapshot = snapshotRef.current;
    const { style } = element;
    if (snapshot) {
      if (style.width === snapshot.width) {
        return;
      }

      style.width = `${snapshot.width}px`;
      style.height = `${snapshot.height}px`;
      return;
    }

    if (isDragOccuring) {
      return;
    }

    // inline styles not applied
    if (style.width == null) {
      return;
    }

    // no snapshot and drag is finished - clear the inline styles
    style.removeProperty('height');
    style.removeProperty('width');
  }, [isDragOccuring]);

  return <td ref={ref} {...props} />;
};

export default TableCell;
