import React, { Component } from 'react';
import { connect } from 'react-redux';
import toastr from 'toastr';
import { saveRecord } from 'redux-json-api-module';
import get from 'lodash.get';
import WorkOrderDetails from './WorkOrderDetails';
import EditWorkOrderDetailsForm from './EditWorkOrderDetailsForm';
import { toDate } from '../../../helpers/date';

class WorkOrderDetailsWrapper extends Component {
  state = {
    editing: false,
  };

  toggleEdit = (editing) => {
    this.setState({
      editing,
    });
  };

  handleSubmit = (values) => {
    const { saveRecord, workOrder } = this.props;

    this.setState({ saving: true });

    const {
      description,
      additional_entry_notes, permission_to_enter,
      work_order_number,
      priority, tenant_name, tenant_email, tenant_phone,
      recurring, stop_recurring_on,
    } = values;

    const record = {
      type: 'work_orders',
      id: workOrder.id,
      attributes: {
        description: description || null,
        additional_entry_notes: additional_entry_notes || null,
        permission_to_enter,
        work_order_number: work_order_number || '',
        priority,
        tenant_name: tenant_name || '',
        tenant_email: tenant_email || '',
        tenant_phone: tenant_phone || '',
        recurring,
        stop_recurring_on: toDate(stop_recurring_on),
      },
    };

    saveRecord(record, { params: { window_id: window.WINDOW_ID } })
      .then((resp) => {
        this.setState({ saving: false });

        if (resp.error) {
          toastr.error('Failed to save work order');
          if (resp.error.response.data) {
            this.setState({
              errors: resp.error.response.data.errors,
            });
          }

          return;
        }

        toastr.success('Successfully updated work order!');
        this.setState({
          editing: false,
        });
      });
  };

  render() {
    const { workOrder } = this.props;
    const { editing, saving, errors } = this.state;

    return editing
      ? (
        <EditWorkOrderDetailsForm
          onSubmit={this.handleSubmit}
          toggleEdit={this.toggleEdit}
          attributes={get(workOrder, 'attributes', {}) || {}}
          saving={saving}
          errors={errors}
        />
      ) : (
        <WorkOrderDetails workOrder={workOrder} toggleEdit={this.toggleEdit} />
      );
  }
}

const mapDispatchToProps = {
  saveRecord,
};

export default connect(null, mapDispatchToProps)(WorkOrderDetailsWrapper);
