import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '../Avatar';

const UserDisplay = ({
  id,
  name,
  initials,
  avatar,
  color,
  service_count,
}) => (
  <div className="maintenance-select__option" id={`user-option-${id}`}>
    <Avatar
      avatar={avatar}
      initials={initials}
      color={color}
      className="avatar-xs d-inline-block m-r-5"
    />
    {name}
    &nbsp;
    {service_count ? `(${service_count})` : null}
  </div>
);

UserDisplay.propTypes = {
  id: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  initials: PropTypes.string.isRequired,
  avatar: PropTypes.object,
  color: PropTypes.string.isRequired,
  service_count: PropTypes.number,
};

UserDisplay.defaultProps = {
  avatar: null,
  service_count: null,
};

export default UserDisplay;
