import React, { Component, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import CommunitiesTab from './CommunitiesTab';
import UnitsTab from './UnitsTab';
import { idType } from '../../helpers/propTypes';
import CommunitiesAlert from './CommunitiesAlert';
import UnitsAlert from './UnitsAlert';
import PropertiesTab from './PropertiesTab';
import PropertiesAlert from './PropertiesAlert';

const TAB_KEYS = [
  'units',
  'communities',
  'properties',
];

const getActiveKeyParam = () => {
  if (window.location.hash) {
    const hashKey = window.location.hash.replace('#', '');

    if (TAB_KEYS.includes(hashKey)) {
      return hashKey;
    }
  }

  return TAB_KEYS[0];
};

const UnitsContainer = ({ teamId }) => {
  const [activeKey, setActiveKey] = useState(getActiveKeyParam());

  let alert;
  if (activeKey === 'units') {
    alert = <UnitsAlert />;
  } else if (activeKey === 'communities') {
    alert = <CommunitiesAlert />;
  } else if (activeKey === 'properties') {
    alert = <PropertiesAlert />;
  }

  return (
    <div className="card">
      <div className="card-body large-tabs">
        {alert}
        <Tabs
          activeKey={activeKey}
          onSelect={setActiveKey}
        >
          <Tab eventKey="units" title="Address List">
            {activeKey === 'units' ? <UnitsTab /> : null}
          </Tab>
          <Tab eventKey="communities" title="Community List" id="community-tab">
            {activeKey === 'communities' ? <CommunitiesTab teamId={teamId} /> : null}
          </Tab>
          <Tab eventKey="properties" title="Property Group" id="property-tab">
            {activeKey === 'properties' ? <PropertiesTab teamId={teamId} /> : null}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

UnitsContainer.propTypes = {
  teamId: idType.isRequired,
};

export default UnitsContainer;
