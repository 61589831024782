import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchRecords } from 'redux-json-api-module';
import { firstBy } from 'thenby';
import Badge from '../../../components/Badge';
import Loader from '../../../components/Loader';

const ServicesTab = ({
  selectedIds,
  onSelect,
  services,
  fetchRecords,
}) => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    fetchRecords('services')
      .finally(() => setLoading(false));
  }, []);

  if (loading) return <Loader />;

  return (
    <div>
      {Object.values(services)
        .sort(firstBy(a => a.attributes.name))
        .map(service => (
          <div key={service.id} className="d-flex align-items-center mt-2">
            <label>
              <input
                type="checkbox"
                className="mr-1"
                checked={selectedIds.includes(service.id)}
                onChange={event => onSelect(service.id, event.target.checked)}
              />
              <Badge {...service.attributes.service_badge} />
            </label>
          </div>
        ))}
    </div>
  );
};

ServicesTab.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  onSelect: PropTypes.func.isRequired,
  // connected
  services: PropTypes.object.isRequired,
  fetchRecords: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  services: state.Api.services || {},
});

const mapDispatchToProps = {
  fetchRecords,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicesTab);
