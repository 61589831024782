import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { getRelationship } from 'redux-json-api-module';
import moment from 'moment';
import toastr from 'toastr';
import get from 'lodash.get';
import {
  changeUser,
  closeForm,
  closeScheduler,
  onSelectEvent,
  onSelectSlot,
  getFormValues,
  eventFilter,
  loadEvents,
} from '../../../redux/modules/scheduler';
import './Scheduler.scss';
import { allViews, eventPropGetter, decorateEvent } from './helpers';
import { getInspection } from '../../../helpers/inspection';
import GenericScheduler from '../../../components/GenericScheduler';
import { PARENT_TYPE_MAP, INVERSE_CALENDAR_EVENT_TYPE_MAP } from '../../../helpers/types';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import { SCHEDULE_VIEW_GANTT } from '../../../redux/modules/teamSchedule';
import Title from './Title';
import PlanGanttChart from './PlanGanttChart';
import { getCompositeId } from '../../TeamScheduleContainer/Schedule/helpers';
import { idComparison } from '../../../helpers/string';

class Scheduler extends Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    additionalEvents: PropTypes.arrayOf(PropTypes.object),
    calendarVisible: PropTypes.bool,
    userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    scheduledType: PropTypes.string.isRequired,
    loadEvents: PropTypes.func.isRequired,
  };

  static defaultProps = {
    additionalEvents: [],
    calendarVisible: false,
    userId: null,
  };

  state = {
    scrolled: false,
    formVisible: false,
  };

  constructor(props) {
    super(props);

    this.schedulerContainerEnd = React.createRef();
  }

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    const { calendarVisible } = this.props;

    // reset scrolled flag when calendar is hidden
    if (nextProps.calendarVisible !== calendarVisible) {
      this.setState({ scrolled: false });
    }
  };

  componentDidUpdate = () => {
    const { scrolled } = this.state;

    if (!scrolled) this.scrollToNow();
  };

  scrollToNow = () => {
    if ($('.rbc-now')[0]) {
      $('.rbc-now')[0].scrollIntoView({ block: 'start' });
      this.setState({ scrolled: true });
    }
  };

  handleRangeChange = () => {
    const { loadEvents } = this.props;

    loadEvents().then(() => {
      // trigger resize event to make react-big calendar resize correctly
      window.dispatchEvent(new Event('resize'));

      this.scrollToNow();
    });
  };

  handleAssignmentChange = (userId) => {
    const { changeUser } = this.props;
    changeUser(userId);
  };

  handleChangeScheduled = (scheduled, userId, event) => {
    const { onSelectEvent } = this.props;
    onSelectEvent(event);
  };

  handleSave = (attributes) => {
    const {
      onSubmit,
      userId,
      task,
    } = this.props;
    if (userId && !attributes.user_id) {
      attributes.user_id = userId;
    }

    const type = INVERSE_CALENDAR_EVENT_TYPE_MAP[task.type];

    const record = {
      type,
      id: task.id,
      attributes,
    };

    return onSubmit(record);
  };

  getFormValues = (attributes) => {
    const { plan, task, minDate, inspection } = this.props;

    return getFormValues({
      task,
      plan,
      inspection,
      start: attributes.start,
      end: attributes.end,
      minDate,
    });
  };

  isSelectable = (event) => {
    const { plan: { id: planId } } = this.props;
    if (idComparison(planId, event.resource.planId)) return true;

    toastr.warning(
      'Please select a service that belongs to this Project',
      null, { timeOut: 2500 },
    );

    return false;
  };

  validateSelectSlot = ({ start }) => {
    const { inspection, plan: { attributes: { keys_received_on: keysReceivedOn } } } = this.props;
    const inspectionEnd = inspection
      ? moment(inspection.attributes.completed_at).startOf('day')
      : null;
    const keysReceivedOnDate = moment(keysReceivedOn).startOf('day');

    if (moment(start)
      .isBefore(keysReceivedOnDate, 'day')) {
      toastr.warning(
        `Please select a date that is on or after the keys received on date, ${keysReceivedOnDate.format('MM/DD')}`,
        null, { timeOut: 2500 },
      );

      return false;
    }

    if (inspectionEnd && moment(start)
      .isBefore(inspectionEnd, 'day')) {
      toastr.warning(
        `Please select a date that is on or after the Inspection completion date, ${inspectionEnd.format('MM/DD')}`,
        null, { timeOut: 2500 },
      );

      return false;
    }

    return true;
  };

  decoratedEvents = () => {
    const { events, additionalEvents } = this.props;
    const allEvents = [...(events || []), ...(additionalEvents || [])];

    return allEvents.map(event => decorateEvent(event));
  };

  render() {
    const {
      task, calendarVisible, events, closeScheduler, userId, title, plan, inspection, planType,
      scheduledType, view, onSubmit, loadEvents,
    } = this.props;

    const { formVisible } = this.state;

    let startDate = get(task, 'attributes.started_at', null);
    if (startDate) {
      startDate = moment(startDate).toDate();
    } else {
      startDate = new Date();
    }

    return (
      <Modal
        show={calendarVisible}
        onHide={closeScheduler}
        size="lg"
        dialogClassName="scheduler-modal"
      >
        <div
          className="modal-body scheduler-container"
        >
          <Title
            handleAssignment={this.handleAssignmentChange}
            scheduledType={task ? task.type : scheduledType}
            scheduledId={task ? task.id : null}
            userId={userId}
          />
          <div className="row">
            <div className="col">
              {view === SCHEDULE_VIEW_GANTT ? (
                <PlanGanttChart
                  events={events}
                  onSubmit={onSubmit}
                  key={getCompositeId(task)}
                />
              ) : (
                <GenericScheduler
                  isSelectable={this.isSelectable}
                  scheduledType={task ? task.type : scheduledType}
                  scheduledId={task ? task.id : null}
                  events={this.decoratedEvents()}
                  views={allViews}
                  userId={userId}
                  onAssignmentChange={this.handleAssignmentChange}
                  onChangeScheduled={this.handleChangeScheduled}
                  onRangeChange={this.handleRangeChange}
                  onSave={this.handleSave}
                  getFormValues={this.getFormValues}
                  eventPropGetter={eventPropGetter}
                  formTitle={title}
                  openOnAssignment
                  startDate={startDate}
                  minDate={inspection ? moment(inspection.attributes.completed_at)
                    .startOf('day')
                    .toDate() : new Date()}
                  dueDate={plan ? plan.attributes.due_on : null}
                  deadline={plan ? moment(plan.attributes.keys_received_on)
                    .add(21, 'days') : null}
                  validateSelectSlot={this.validateSelectSlot}
                  planType={planType}
                  showTitle={false}
                  setFormVisible={formVisible => this.setState({ formVisible })}
                  formVisible={formVisible}
                  refresh={() => loadEvents(false)}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  if (!state.Scheduler.task) return { ...state.Scheduler };

  const { task } = state.Scheduler;
  const plan = getRelationship(state.Api, task.relationships[PARENT_TYPE_MAP[task.type]]);

  const service = getRelationship(state.Api, task.relationships.service);
  const inspection = plan && getInspection(plan, state.Api);

  const filteredEvents = (state.Scheduler.events || []).filter(event => (
    eventFilter(event, state.Scheduler.task)
  ));

  return {
    ...state.Scheduler,
    task,
    service,
    plan,
    inspection,
    view: state.TeamSchedule.view,
    unit: plan ? getRelationship(state.Api, plan.relationships.unit) : null,
    events: filteredEvents,
  };
};

const mapDispatchToProps = {
  closeScheduler,
  onSelectSlot,
  closeForm,
  onSelectEvent,
  changeUser,
  loadEvents,
};

export default connect(mapStateToProps, mapDispatchToProps)(Scheduler);
