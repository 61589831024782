import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';
import ServiceTasksContainer from '../containers/ServiceTasksContainer/ServiceTasksContainer';

const ServiceTasks = props => (
  <Provider store={store}>
    <ServiceTasksContainer {...props} />
  </Provider>
);

export default ServiceTasks;
