import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import animationData from '../../../assets/lotties/red-ring-bell.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const AlarmBell = ({ height, width }) => (
  <div style={{ marginTop: '-5px' }}>
    <Lottie
      options={defaultOptions}
      height={height}
      width={width}
    />
  </div>
);

AlarmBell.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

AlarmBell.defaultProps = {
  height: 30,
  width: 30,
};

export default AlarmBell;
