import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';
import UnitsContainer from '../containers/UnitsContainer/UnitsContainer';

const Units = props => (
  <Provider store={store}>
    <UnitsContainer {...props} />
  </Provider>
);

export default Units;
