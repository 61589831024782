import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchRecord } from 'redux-json-api-module';
import Loader from '../../Loader';
import { idType } from '../../../helpers/propTypes';
import TaskBadgeHeader from '../TaskBadgeHeader';
import PurchaseRequestTable from '../../PurchaseRequestTable';
import './style.scss';

const PurchaseRequestTab = ({
  taskId,
  taskType,
  fetchRecord,
}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchRecord(taskType, taskId, {
      include: 'purchase_requests',
    }).finally(() => setLoading(false));
  }, []);

  if (loading) return <Loader />;

  return (
    <div>
      <TaskBadgeHeader taskId={taskId} taskType={taskType} />
      <h3 className="pb-2">Parts Needed</h3>
      <PurchaseRequestTable taskType={taskType} taskId={taskId} />
    </div>
  );
};

PurchaseRequestTab.propTypes = {
  taskType: PropTypes.string.isRequired,
  taskId: idType.isRequired,

  // connected
  fetchRecord: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  fetchRecord,
};

export default connect(
  null,
  mapDispatchToProps,
)(PurchaseRequestTab);
