import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord } from 'redux-json-api-module';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { genericRecordPropType } from '../../helpers/propTypes';
import { TYPE_MAP } from '../../helpers/types';

const PurchaseRequestRow = (
  {
    areaName, item, onSave, disabled,
    purchaseRequest,
  },
) => {
  const [quantity, setQuantity] = useState(0);
  const [orderedOn, setOrderedOn] = useState(null);
  const [receivedOn, setReceivedOn] = useState(null);

  const initialValues = {
    id: item.purchase_request_id,
    item_id: item.id,
    feature_type: TYPE_MAP[item.feature_type],
    feature_id: item.feature_id,
  };

  useEffect(() => {
    if (purchaseRequest) {
      setQuantity(purchaseRequest.attributes.quantity);
      setOrderedOn(purchaseRequest.attributes.ordered_on);
      setReceivedOn(purchaseRequest.attributes.received_on);
    }
  }, [purchaseRequest]);

  return (
    <tr>
      <td>
        {areaName}
      </td>
      <td>
        {item.attributes.name}
      </td>
      <td>
        {item.attributes.quantity}
      </td>
      <td>
        <select
          value={quantity}
          onChange={(event) => {
            onSave({ ...initialValues, quantity: event.target.value });
            setQuantity(event.target.value);
          }}
        >
          {[...Array(11)].map((_, i) => (
            <option key={i} value={i}>{i}</option>
          ))}
        </select>
      </td>
      <td>
        {orderedOn || (quantity && quantity > 0) ? (
          <DatePicker
            selected={orderedOn ? moment(orderedOn).toDate() : undefined}
            disabled={disabled}
            onChange={(value) => {
              onSave({ ...initialValues, ordered_on: moment(value).format('YYYY-MM-DD') });
              setOrderedOn(value);
            }}
            isClearable
            className={`mw-100 pr-table-datepicker ${orderedOn ? 'input-info' : null}`}
          />
        ) : null}
      </td>
      <td>
        {receivedOn || orderedOn ? (
          <DatePicker
            selected={receivedOn ? moment(receivedOn).toDate() : undefined}
            disabled={disabled}
            onChange={(value) => {
              onSave({ ...initialValues, received_on: moment(value).format('YYYY-MM-DD') });
              setReceivedOn(value);
            }}
            isClearable
            className={`mw-100 pr-table-datepicker ${receivedOn ? 'input-success' : null}`}
          />
        ) : null}
      </td>
    </tr>
  );
};

PurchaseRequestRow.propTypes = {
  areaName: PropTypes.string.isRequired,
  item: genericRecordPropType.isRequired,
  onSave: PropTypes.func.isRequired,
  disabled: PropTypes.bool,

  // connected
  purchaseRequest: genericRecordPropType,
};

PurchaseRequestRow.defaultProps = {
  disabled: false,
  purchaseRequest: null,
};

const mapStateToProps = (state, ownProps) => ({
  purchaseRequest: getRecord(state.Api, {
    type: 'purchase_requests',
    id: ownProps.item.purchase_request_id,
  }),
});

export default connect(
  mapStateToProps,
)(PurchaseRequestRow);
