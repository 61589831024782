import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { getRecord } from 'redux-json-api-module';
import GenericImageSlider from '../GenericImageSlider';
import { UserAvatar } from '../Avatar';
import { NextArrow, PrevArrow, FULL_PAGE_SETTINGS } from './helpers';
import { fetchTask } from '../../redux/modules/projectManagement';
import Loader from '../Loader';
import { idType } from '../../helpers/propTypes';
import { IMAGE_MAP } from '../../helpers/types';
import TaskBadgeHeader from './TaskBadgeHeader';
import { simpleFormat } from '../../helpers/string';

class CompletionTab extends PureComponent {
  state = {
    loading: true,
  };

  componentDidMount() {
    const { fetchTask, taskId, taskType, summary } = this.props;

    if (!summary) {
      fetchTask(taskType, taskId)
        .then(() => this.setState({ loading: false }));
    }
  }

  getControlArrows = (slideCount) => {
    // really needs to be divided by slidesToScroll
    // because the library controls the placement of the buttons, it's probably easier
    // to create a custom control component rather than this much absolute positioning
    const size = slideCount / 2;
    return {
      nextArrow: <NextArrow btnClassName="bottom-control" relativeControls sliderCount={size} />,
      prevArrow: <PrevArrow btnClassName="bottom-control" relativeControls sliderCount={size} />,
    };
  };

  render() {
    const { task, taskImages, summary } = this.props;

    const { loading } = this.state;

    if (loading && !summary) return <Loader />;

    if (!task) return null;

    const { relationships: { user: userRel } } = task;

    return (
      <div>
        <TaskBadgeHeader taskId={task.id} taskType={task.type} />
        <h4 className="form-header mb-3">Completion Details</h4>

        {taskImages && taskImages.length > 0 ? (
          <GenericImageSlider
            images={taskImages}
            settings={{ ...FULL_PAGE_SETTINGS, ...this.getControlArrows(taskImages.length) }}
            pagination={false}
            sourceVariant="thumb_sq"
            placeholderVariant="thumb_placeholder_sq"
          />
        ) : null}

        <div
          className="font-italic m-t-10"
          dangerouslySetInnerHTML={{ __html: simpleFormat(task.attributes.service_notes) }}
        />

        <p className="font-italic">
          {`Completed on ${moment(task.attributes.completed_at)
            .format('MM/DD')}`}
        </p>

        {userRel.data && (
          <UserAvatar userId={userRel.data.id} includeName />
        )}
      </div>
    );
  }
}

CompletionTab.propTypes = {
  task: PropTypes.objectOf(PropTypes.any),
  taskId: idType.isRequired,
  taskType: PropTypes.string.isRequired,
  fetch: PropTypes.bool,
  fetchTask: PropTypes.func.isRequired,
  summary: PropTypes.bool,
};

CompletionTab.defaultProps = {
  fetch: false,
  task: null,
  summary: false,
};

const mapStateToProps = (state, ownProps) => {
  const task = getRecord(state.Api, {
    type: ownProps.taskType,
    id: ownProps.taskId,
  });

  const taskImages = task ? (task.relationships[IMAGE_MAP[ownProps.taskType]].data || []).map(image => (
    getRecord(state.Api, { type: 'task_images', id: image.id })
  )).filter(a => a) : [];

  return {
    task,
    taskImages: (taskImages || []).map(ti => ti.attributes) || [],
  };
};

const mapDispatchToProps = {
  fetchTask,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompletionTab);
