import { fetchRecords, fetchRecord } from 'redux-json-api-module';
import get from 'lodash.get';

export const FETCH_PROJECTS_SUCCESS = 'intimely/projects/FETCH_PROJECTS_SUCCESS';

const INITIAL_STATE = {
  communityIdMap: {},
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        communityIdMap: action.communityIdMap,
      };

    default:
      return state;
  }
}

export function fetchProjects() {
  return dispatch => dispatch(fetchRecords('project_listings/current', {
    include: 'community,unit,project_services',
    filter: { scope: 'current' },
    sort: 'keys_received_on,created_at',
    page: { size: 250 },
  }))
    .then((resp) => {
      if (resp.error) return resp;

      const communityIdMap = {};
      resp.payload.data.data.forEach((r) => {
        const communityId = get(r, 'relationships.community.data.id');

        if (communityId) {
          if (!communityIdMap[communityId]) communityIdMap[communityId] = [];
          communityIdMap[communityId].push(r.id);
        }
      });

      dispatch({
        type: FETCH_PROJECTS_SUCCESS,
        communityIdMap,
      });

      return resp;
    });
}

export function fetchProjectListing(projectId) {
  return dispatch => dispatch(fetchRecord('project_listings', projectId, {
    include: 'community,unit,project_services',
  }));
}
