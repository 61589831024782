import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import TextField from './TextField';
import StateSelector from './StateSelector';
import CommunitySelector from './CommunitySelector';
import Select from '../Inputs/Select';
import InputWrapper
  from '../../containers/WorkOrderCreateContainer/WorkOrderCreateForm/InputWrapper';

const bedCountOptions = [
  null, 0, 1, 2, 3, 4, 5,
];

const bathCountOptions = [
  null, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5,
];

const HOUSING_TYPES = [
  ['', null],
  ['Apartment', 'apartment'],
  ['Single Family Home', 'single_family_home'],
  ['Condominium', 'condominium'],
  ['Office', 'office'],
];

const UnitForm = ({ stateOptions, communityOptions, errors, canCreate }) => (
  <div>
    <Field
      component={CommunitySelector}
      options={communityOptions}
      required
      name="community"
      errors={errors}
      canCreate={canCreate}
    />
    <TextField
      label="Street Address"
      name="street_address"
      required
      errors={errors['unit.street_address']}
    />
    <TextField label="Unit Number" name="number" errors={errors['unit.number']} />
    <TextField label="City" name="city" required errors={errors['unit.city']} />
    <Field
      component={StateSelector}
      options={stateOptions}
      required
      name="state"
      errors={errors['unit.state']}
    />
    <TextField label="Zip" name="zip" required errors={errors['unit.zip']} />
    <InputWrapper label="Bed Count" required errors={errors} attribute="bed_count">
      <Field
        component={Select}
        name="bed_count"
        required
        errors={errors['unit.bed_count']}
        options={bedCountOptions}
      />
    </InputWrapper>
    <InputWrapper label="Bath Count" attribute="bath_count" required errors={errors}>
      <Field
        component={Select}
        name="bath_count"
        required
        errors={errors['unit.bath_count']}
        options={bathCountOptions}
      />
    </InputWrapper>
    <InputWrapper
      label="Housing Type"
      required
      errors={errors}
      attribute="housing_type"
    >
      <Field
        component={Select}
        name="housing_type"
        required
        includeBlank=""
        errors={errors['unit.housing_type']}
        options={HOUSING_TYPES}
      />
    </InputWrapper>
  </div>
);

UnitForm.propTypes = {
  stateOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })).isRequired,
  communityOptions: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  })).isRequired,
  errors: PropTypes.object,
};

UnitForm.defaultProps = {
  errors: {},
};

export default UnitForm;
