import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import animationData from './lotties/ripples-red.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const Animation = ({ height, width }) => (
  <Lottie
    options={defaultOptions}
    height={height}
    width={width}
  />
);

Animation.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

Animation.defaultProps = {
  height: 20,
  width: 20,
};

export default Animation;
