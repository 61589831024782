import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PlanHeader from './PlanHeader';
import { INVERSE_TYPE_MAP } from '../../helpers/types';
import { idType } from '../../helpers/propTypes';
import { getMessenger } from '../../redux/modules/chat';

const PlanGroup = ({
  unreadCount,
  onHideUnread,
  onShowUnread,
  messageThread,
  threadsList,
  userId,
  communityId,
  planType,
  planId,
  active,
}) => {
  const container = useRef(null);

  const scrollTo = () => {
    if (container && active) {
      container.current.scrollIntoView({
        behavior: 'smooth', block: 'nearest', inline: 'start',
      });
    }
  };

  const handleScroll = () => {
    if (unreadCount === 0) return onHideUnread(messageThread.id);

    if (container.current.getBoundingClientRect().bottom < threadsList.current.offsetTop) {
      return onShowUnread(messageThread.id, 'top');
    }

    if (container.current.getBoundingClientRect().top > window.innerHeight) {
      return onShowUnread(messageThread.id, 'bottom');
    }

    return onHideUnread(messageThread.id);
  };

  useEffect(() => {
    setTimeout(scrollTo, 1500);
    threadsList.current.addEventListener('scroll', handleScroll);
    handleScroll();
  }, [unreadCount]);

  return (
    <div onScroll={handleScroll} ref={container} id={`plan-group-${planType}-${planId}`}>
      <PlanHeader
        messageThread={messageThread}
        key={messageThread.id}
        userId={userId}
        communityId={communityId}
        active={active}
      />
    </div>
  );
};

PlanGroup.propTypes = {
  messageThread: PropTypes.object.isRequired,
  userId: idType.isRequired,
  communityId: idType.isRequired,
  planType: PropTypes.string.isRequired,
  planId: idType.isRequired,
  active: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const planId = ownProps.messageThread.attributes.subject_id;
  const planType = INVERSE_TYPE_MAP[ownProps.messageThread.attributes.subject_type];

  const sameId = state.Chat.activePlanId && planId
    ? state.Chat.activePlanId.toString() === planId.toString() : false;

  const active = sameId && state.Chat.activePlanType === planType;
  const messenger = getMessenger(state, ownProps.messageThread.id, ownProps.userId);

  return {
    planType,
    planId,
    active,
    unreadCount: messenger?.attributes?.unread_count ?? 0,
  };
};

export default connect(mapStateToProps)(PlanGroup);
