import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import toastr from 'toastr';
import Select from 'react-select';
import { getRelationship, getRecord } from 'redux-json-api-module';
import TaskDisplay from './TaskDisplay';
import TaskOption from './TaskOption';
import { saveTaskAttachment } from '../../../redux/modules/projectManagement';
import Badge from '../../../components/Badge';
import { ID_MAP } from '../../../helpers/types';

class TaskSelector extends React.Component {
  static propTypes = {
    taskType: PropTypes.string.isRequired,
    taskOption: PropTypes.object,
    disabled: PropTypes.bool,
    menuPortalTarget: PropTypes.any,
    attachment: PropTypes.object.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  static defaultProps = {
    disabled: false,
    taskOption: undefined,
    menuPortalTarget: document.body,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
    };
  }

  handleChange = ({ id }) => {
    const {
      saveTaskAttachment,
      taskType,
      attachment,
    } = this.props;
    saveTaskAttachment(attachment.id, taskType, id)
      .then((resp) => {
        if (resp.error) {
          toastr.error('Task could not be attached');
        } else {
          const message = resp.payload.data.data.attributes.notification_message;
          toastr.success(`Task attached${message ? ` and ${message}` : ''}`);
        }
      });
  };

  render() {
    const { disabled, options, taskOption, menuPortalTarget, attachment } = this.props;

    const value = taskOption
      ? ([{
        value: taskOption.id,
        label: <TaskDisplay {...taskOption} />,
      }])
      : null;

    if (disabled) {
      return taskOption ? <TaskDisplay {...taskOption} /> : null;
    }

    return (
      <div id={`attachment-task-selector-${attachment.id}`}>
        {taskOption ? <h4><Badge {...taskOption.service_badge} /></h4> : (
          <Select
            classNamePrefix="task-select"
            className="task-select"
            value={value}
            components={{ Option: TaskOption }}
            onChange={this.handleChange}
            styles={{
              menuPortal: (base) => {
                const { zIndex, ...rest } = base;
                return {
                  ...rest,
                  zIndex: 9999,
                };
              },
            }}
            options={options}
            isSearchable={false}
            placeholder="Send to Service Provider"
            menuPortalTarget={menuPortalTarget}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = {
  saveTaskAttachment,
};

const mapStateToProps = (state, ownProps) => {
  let task;
  if (ownProps.attachment.relationships) {
    task = getRelationship(state.Api, ownProps.attachment.relationships.task);
  } else {
    task = getRecord(state.Api, {
      type: ownProps.taskType,
      id: ownProps.attachment[ID_MAP[ownProps.taskType]],
    });
  }

  if (!task) return {};

  let taskOption;
  ownProps.options.forEach((option) => {
    if (task && task.id === option.id) {
      taskOption = option;
    }
  });

  return {
    taskOption,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TaskSelector);
