import React from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';
import { Error } from '../../containers/ProjectCreateContainer/ProjectCreateForm/inputs';

const StateOption = props => (
  <components.Option {...props}>
    <StateDisplay {...props.data} />
  </components.Option>
);

const StateDisplay = ({ label }) => (
  <div className="state-select__option" id={`state-option-${label}`}>
    {label}
  </div>
);

StateDisplay.propTypes = {
  label: PropTypes.string.isRequired,
};

const StateSelector = ({
  input,
  options,
  required,
  menuPortalTarget,
  errors,
}) => (
  <div className="form-group row">
    <label className="col-sm-3 col-form-label text-md-right">
      Select State
      {required ? <abbr title="required">*</abbr> : null}
    </label>
    <div className="col-sm-9">
      <div className="state-select__container">
        <Select
          classNamePrefix="state-select"
          className="state-select"
          value={input.value}
          components={{ Option: StateOption }}
          onChange={input.onChange}
          styles={{
            menuPortal: (base) => {
              const { zIndex, ...rest } = base;
              return {
                ...rest,
                zIndex: 9999,
              };
            },
          }}
          options={options}
          placeholder="Select state"
          menuPortalTarget={menuPortalTarget}
        />
        {errors ? errors.map(error => <Error text={error} key={error} />) : null}
      </div>
    </div>
  </div>
);

StateSelector.propTypes = {
  input: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  errors: PropTypes.array,
  required: PropTypes.bool,
};

StateSelector.defaultProps = {
  errors: [],
  required: false,
};

export default StateSelector;
