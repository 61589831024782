import React, { PureComponent } from 'react';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Badge from 'react-bootstrap/Badge';
import { getRecord } from 'redux-json-api-module';
import COLORS from '../../helpers/colors';
import { idType } from '../../helpers/propTypes';
import { getMessenger } from '../../redux/modules/chat';
import OnlineBubble from './OnlineBubble';
import { idComparison } from '../../helpers/string';

const containerStyle = {
  minWidth: '60px',
};

class DirectMessageChatUnreadCount extends PureComponent {
  render() {
    const {
      unreadCount,
      id,
      objectType,
      online,
      otherUserId,
    } = this.props;

    if (unreadCount > 0) {
      return (
        <div style={containerStyle}>
          <Badge
            pill
            variant="info"

            id={`${objectType}-unread-count-${id}`}
            style={{ backgroundColor: COLORS.redAlert }}
          >
            {unreadCount}
          </Badge>
        </div>
      );
    }

    return (
      <div style={{ paddingTop: '4px' }}>
        <OnlineBubble online={online} otherUserId={otherUserId} />
      </div>
    );
  }
}

DirectMessageChatUnreadCount.propTypes = {
  unreadCount: PropTypes.number,
  unreadContent: PropTypes.bool,
  objectType: PropTypes.string.isRequired,
  id: idType,
  online: PropTypes.bool.isRequired,
  otherUserId: idType.isRequired,
};

DirectMessageChatUnreadCount.defaultProps = {
  unreadCount: 0,
  unreadContent: false,
  active: false,
  id: null,
};

const mapStateToProps = (state, ownProps) => {
  const messenger = getMessenger(state, ownProps.messageThreadId, ownProps.userId);

  const directMessageChat = getRecord(state.Api, { type: 'direct_message_chats', id: ownProps.directMessageChatId });

  let online;

  if (directMessageChat) {
    if (directMessageChat.attributes.creator_id === directMessageChat.attributes.receiver_id) {
      online = true;
    } else {
      const userIds = get(state, 'Chat.currentlyOnlineUserIds', []);
      online = !!userIds.find(userId => !idComparison(ownProps.userId, userId)
          && (idComparison(directMessageChat.attributes.creator_id, userId)
            || idComparison(directMessageChat.attributes.receiver_id, userId)));
    }
  }

  return {
    unreadCount: get(messenger, 'attributes.unread_count', 0),
    id: messenger && messenger.id,
    objectType: 'tasks',
    online,
  };
};

export default connect(mapStateToProps)(DirectMessageChatUnreadCount);
