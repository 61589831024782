import { fetchRecords } from 'redux-json-api-module';
import { groupBy } from '../../helpers/array';

export const FETCH_TASKS_SUCCESS = 'intimely/serviceTasks/FETCH_TASKS_SUCCESS';

const INITIAL_STATE = {
  communityIdMap: {},
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_TASKS_SUCCESS:
      return {
        ...state,
        communityIdMap: action.communityIdMap,
      };

    default:
      return state;
  }
}

export const SCOPE_MAP = {
  'Last 30 Days': '30_days',
  'Last 60 Days': '60_days',
  'Last 90 Days': '90_days',
};

export function fetchTasks(serviceId, scope, filters) {
  const reqFilters = {
    community_id: filters.communityId,
    service_id: filters.serviceId,
    unit_id: filters.unitId,
    user_id: filters.userId,
  };

  return (dispatch) => {
    const promises = [];

    if (filters.planType === 'View All' || filters.planType === "Make Ready's") {
      promises.push(dispatch(fetchRecords(`services/${serviceId}/project_service_listings`, {
        include: 'community,unit,project,user',
        filter: {
          scope: SCOPE_MAP[scope],
          ...reqFilters,
        },
        sort: 'started_at',
        page: { size: 250 },
      })));
    }

    if (filters.planType === 'View All' || filters.planType === 'Work Orders') {
      promises.push(dispatch(fetchRecords(`services/${serviceId}/work_order_task_listings`, {
        include: 'work_order,unit,community,user',
        filter: {
          scope: SCOPE_MAP[scope],
          ...reqFilters,
        },
        sort: 'started_at',
        page: { size: 250 },
      })));
    }

    return Promise.all(promises)
      .then((responses) => {
        if (responses.some(r => r.error)) return responses.find(r => r.error).error;

        return dispatch({
          type: FETCH_TASKS_SUCCESS,
          communityIdMap: groupBy(
            responses.map(resp => resp.payload.data.data).flat(),
            'relationships.community.data.id',
          ),
        });
      });
  };
}
