import { createSelector } from 'reselect';
import { getRecord } from 'redux-json-api-module';

const getApi = api => api;
const getRecIds = (_, recIds) => recIds;

export const makeGetRecords = () => createSelector(
  [getRecIds, getApi],
  (recIds, api) => recIds.map(rec => getRecord(api, rec))
    .filter(r => r),
);
