import React from 'react';
import { connect } from 'react-redux';
import Alert from '../../components/Alert';
import ServicesTable from './ServicesTable';

const ServicesTab = () => {
  return (
    <div>
      <Alert
        type="info"
        iconClassName="fa fa-exclamation-circle"
        title="Select Services that Utilize Inventory"
      >
        Click the checkbox for any Service for which your Team tracks inventory. As your Team completes assigned work in the selected Services, the completed items in each project will decrease your inventory accordingly.
      </Alert>

      <ServicesTable />
    </div>
  );
};

ServicesTab.propTypes = {};

const mapStateToProps = () => ({});

export default connect(
  mapStateToProps,
)(ServicesTab);
