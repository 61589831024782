import React from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import editIcon from '../../../assets/images/icon/edit-icon.png';

class DateInput extends React.Component {
  state = {
    isOpen: false,
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  togglePicker = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  handleChange = (event) => {
    const { input } = this.props;
    this.setState({ isOpen: false });
    input.onChange(event);
  };

  render() {
    const { isOpen } = this.state;
    const { input, minDate, maxDate } = this.props;

    return (
      <div ref={this.setWrapperRef}>
        <span onClick={this.togglePicker} style={{ cursor: 'pointer' }}>
          {input.value ? moment(input.value)
            .format('MM/DD') : 'Pending'}
          <img
            src={editIcon}
            alt="Edit"
            width={25}
            style={{
              marginLeft: '5px',
              paddingBottom: '2px',
            }}
          />
        </span>
        <DatePicker
          onChange={this.handleChange}
          value={input.value}
          open={isOpen}
          minDate={minDate}
          maxDate={maxDate}
        />
      </div>
    );
  }
}

export default DateInput;
