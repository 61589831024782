import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { getRecord, getRelationship, fetchRecords } from 'redux-json-api-module';
import moment from 'moment';
import get from 'lodash.get';
import TaskSelector from './TaskSelector';

import './AttachmentManager.scss';
import { splitMessage } from './helpers';
import { CHILD_TYPE_MAP } from '../../../helpers/types';
import { idType } from '../../../helpers/propTypes';

class AttachmentManager extends React.Component {
  static propTypes = {
    showUser: PropTypes.bool,
    uploadEndpoint: PropTypes.string.isRequired,
    id: idType,
    type: PropTypes.string,
    parentRelationship: PropTypes.string.isRequired,
    attachments: PropTypes.arrayOf(PropTypes.object).isRequired,
    onDelete: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    taskIds: PropTypes.arrayOf(idType),
    nestedUpload: PropTypes.bool,
    fetchRecords: PropTypes.func.isRequired,
  };

  static defaultProps = {
    showUser: false,
    nestedUpload: false,
    id: null,
    type: null,
    taskIds: [],
  };

  state = {
    pending: [],
  };

  componentDidMount() {
    this.fetchAttachments();
  }

  fetchAttachments = () => {
    const { fetchRecords, plan } = this.props;
    fetchRecords(`${plan.type}/${plan.id}/attachments`, { include: 'user,plan' });
  };

  getFormData = (file) => {
    const {
      id,
      type,
      nestedUpload,
      parentRelationship,
      planClass,
    } = this.props;

    const formData = new FormData();
    const nestedAttributesName = nestedUpload ? `[${type}_attributes][0]` : '';
    formData.append(`data[attributes]${nestedAttributesName}[${parentRelationship}]`, id);
    // todo: not always a plan
    formData.append(`data[attributes]${nestedAttributesName}[plan_type]`, planClass);
    formData.append(`data[attributes]${nestedAttributesName}[user_id]`, CURRENT_USER_ID);
    formData.append(`data[attributes]${nestedAttributesName}[file]`, file);

    return formData;
  };

  getUploadedImage = (data) => {
    const { type, nestedUpload } = this.props;

    if (nestedUpload) {
      return data.data.attributes[type].reduce((prev, current) => (
        (prev.id > current.id) ? prev : current
      ));
    }

    return data.data.id;
  };

  getAttachmentAttributes = attachment => (
    attachment.attributes ? attachment : {
      ...attachment,
      attributes: attachment,
    }
  );

  onDrop = (acceptedFiles) => {
    const { nestedUpload, uploadEndpoint } = this.props;

    // add a pending record for everything
    this.setState({ pending: acceptedFiles });

    acceptedFiles.forEach((file) => {
      const { onCreate } = this.props;
      const { pending } = this.state;
      const formData = this.getFormData(file);

      fetch(uploadEndpoint, { // Your POST endpoint
        method: nestedUpload ? 'PATCH' : 'POST',
        body: formData,
      })
        .then(response => response.json())
        .then(onCreate)
        .then(this.fetchAttachments)
        .catch(error => console.log(error))
        .finally(() => {
          // remove an item from pending
          this.setState({ pending: pending.slice(0, -1) });
        });
    });
  };

  onDelete = (attachment) => {
    const { onDelete } = this.props;
    if (!window.confirm('Are you sure?')) return;
    onDelete(attachment);
  };

  render() {
    const { attachments, showUser, options, taskType } = this.props;
    const { pending } = this.state;

    return (
      <div className="attachment-manager">
        <Dropzone onDrop={this.onDrop}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div
              {...getRootProps()}
              className={`dropzone ${isDragActive ? 'dropzone--isActive' : null}`}
            >
              <input
                {...getInputProps()}
                name="data[attributes][file]"
              />
              {isDragActive ? (
                <p className="dropzone-instructions">Drop files here...</p>
              ) : (
                <p className="dropzone-instructions">
                  Drag Files Here
                  &nbsp;&nbsp;
                  OR
                  &nbsp;&nbsp;&nbsp;
                  <button className="btn btn-secondary" type="button">
                    Click Here to Choose
                  </button>
                </p>
              )}
            </div>
          )}
        </Dropzone>

        {(attachments.length > 0 || pending.length > 0) && (
          <div className="table-responsive">
            <table className="table table-attachments vertical-middle">
              <thead>
                <tr>
                  <th width="15%">Name</th>
                  <th width="30%">Send to Service Provider (Optional)</th>
                  <th width="25%">Notification Message</th>
                  {showUser ? <th width="15%">Uploaded By</th> : null}
                  <th width="10%">Date</th>
                  <th width="5%" />
                </tr>
              </thead>
              <tbody>
                {attachments.map(a => this.getAttachmentAttributes(a))
                  .map((attachment) => {
                    if (attachment.attributes.file === undefined) return null;
                    const url = get(attachment, 'attributes.file.thumb.url', null);
                    const name = get(attachment, 'attributes.file.name', 'upload');
                    const message = get(attachment, 'attributes.notification_message', '');
                    const messageParts = splitMessage(message);
                    return (
                      <tr key={attachment.id}>
                        <td className="file-name">
                          <a
                            href={attachment.attributes.file.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {url ? (
                              <img
                                src={url}
                                alt="upload preview"
                                className="mw-100"
                              />
                            ) : (
                              name
                            )}
                          </a>
                        </td>
                        <td>
                          <TaskSelector
                            options={options}
                            attachment={attachment}
                            taskType={taskType}
                          />
                        </td>
                        <td>
                          {messageParts}
                        </td>
                        {showUser ? <td>{attachment.attributes.user_name}</td> : null}
                        <td>
                          {moment(attachment.attributes.created_at)
                            .format('MM/DD/YYYY')}
                        </td>
                        <td>
                          <button
                            type="button"
                            title="Delete Attachment"
                            className="btn btn-link text-dark"
                            onClick={() => this.onDelete(attachment)}
                          >
                            <i className="fa fa-trash" />
                          </button>
                        </td>
                      </tr>
                    );
                  })}

                {pending.map((attachment, i) => (
                  <tr key={`pending-${i}`}>
                    <td colSpan={showUser ? 6 : 5}>Uploading...</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const planType = ownProps.plan.type;

  const tasks = (ownProps.taskIds || []).map(id => (
    getRecord(state.Api, {
      type: CHILD_TYPE_MAP[planType],
      id,
    })
  ))
    .filter(t => t)
    .filter(t => !t.attributes.is_inspection);

  const options = tasks.filter(t => (
    !!t.relationships.user.data
  ))
    .map((t) => {
      const service = getRelationship(state.Api, t.relationships.service);
      return {
        ...t.attributes,
        value: t.id,
        service,
        id: t.id,
      };
    });

  const attachments = ownProps.plan ? (
    getRelationship(state.Api, ownProps.plan.relationships.attachments)
  ) : [];

  return {
    options,
    attachments,
    taskType: CHILD_TYPE_MAP[planType],
  };
};

const mapDispatchToProps = {
  fetchRecords,
};

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentManager);
