import React from 'react';
import PropTypes from 'prop-types';
import { taggableUserObject } from '../../helpers/propTypes';
import { parseTag, parseText } from './helpers';
import BoldFormatter from './formatters/BoldFormatter';

const FORMATTERS = [
  BoldFormatter,
];

function addSection(text, accumulation, currentIndex, match) {
  accumulation.push({
    text,
    style: { fontWeight: match ? 'bold' : 'normal' },
  });
}

function parseEnd({ text, accumulation, currentSection }) {
  const section = currentSection + text[0];
  addSection(section, accumulation, null, null);
  return {
    text: text.substring(1),
    accumulation,
    currentSection: '',
  };
}

function parseNewLine({ text, accumulation, currentSection }) {
  if (currentSection) addSection(currentSection, accumulation, null, null);
  addSection(null, accumulation, null, null);
  return {
    text: text.substring(1),
    accumulation,
    currentSection: '',
  };
}

function parseCharacter({ text, accumulation, currentSection, taggableUsers }) {
  if (text[0] === '<') {
    return parseTag({
      text,
      accumulation,
      currentSection,
      taggableUsers,
      addFunction: addSection,
      currentIndex: 0,
    });
  }
  if (text.indexOf('\n') === 0) {
    return parseNewLine({
      text,
      accumulation,
      currentSection,
    });
  }
  if (text.length === 1) {
    return parseEnd({
      text,
      accumulation,
      currentSection,
    });
  }
  return parseText({
    text,
    accumulation,
    currentSection,
    currentIndex: 0,
  });
}

function withTagging(Message) {
  return class extends React.Component {
    static propTypes = {
      taggableUsers: PropTypes.arrayOf(taggableUserObject).isRequired,
      text: PropTypes.string,
      messageId: PropTypes.string.isRequired,
    };

    static defaultProps = {
      text: '',
    };

    getTextSections = () => {
      const { taggableUsers } = this.props;
      let { text } = this.props;
      let textSections = [];
      let currentSection = '';

      if (!text) {
        return [{
          text: '',
        }];
      }

      while ((text || '').length > 0) {
        const values = parseCharacter({
          text,
          accumulation: textSections,
          currentSection,
          taggableUsers,
        });
        text = values.text;
        textSections = values.accumulation;
        currentSection = values.currentSection;
      }

      return this.format(textSections);
    };

    format = (textSections) => {
      let sections = textSections;
      FORMATTERS.forEach((formatter) => {
        sections = formatter.format(sections);
      });
      return sections;
    }

    render() {
      return <Message textSections={this.getTextSections()} {...this.props} />;
    }
  };
}

export default withTagging;
