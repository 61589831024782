import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';

const DateInput = ({
  input,
  minDate,
  maxDate,
}) => (
  <DatePicker
    selected={input.value ? input.value : null}
    onChange={input.onChange}
    className="form-control"
    minDate={minDate}
    maxDate={maxDate}
    id={input.name}
  />
);

DateInput.propTypes = {
  input: PropTypes.object.isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
};

DateInput.defaultProps = {
  minDate: null,
  maxDate: null,
};

export default DateInput;
