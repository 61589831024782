import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { connect } from 'react-redux';
import { getRecord } from 'redux-json-api-module';
import { Button } from 'react-bootstrap';
import { workOrderTaskSort } from '../../WorkOrderManagementContainer/helpers';
import EditService from '../../../components/ServiceAssignmentTable/EditServiceRow/EditServiceRow';
import { Card, CardHeader } from '../../../components/Card';
import TableHeader from './TableHeader';
import { addService } from '../../../redux/modules/workOrderManagement';
import { idType } from '../../../helpers/propTypes';
import { pluralize } from '../../../helpers/string';

const ServiceCreationTable = ({
  sortedTaskIds,
  workOrderId,
  workOrder,
  addService,
  pendingWorkOrderTaskIds,
}) => {
  const getSummaryTitle = () => {
    if (!workOrder) return null;

    const text = workOrder.attributes.aasm_state === 'completed'
      ? 'Completed in'
      : 'In Progress for';

    return `Services Summary - ${text} `
      + `${workOrder.attributes.duration} ${pluralize('Day', workOrder.attributes.duration)}`;
  };

  useEffect(() => {
    if (sortedTaskIds.length === 0 && pendingWorkOrderTaskIds.length === 0) {
      addService();
    }
  }, []);

  const taskCount = (sortedTaskIds || []).length + (pendingWorkOrderTaskIds || []).length;

  return (
    <Card>
      <CardHeader title={getSummaryTitle()}>
        {workOrder.attributes.aasm_state !== 'completed' ? (
          <Button
            onClick={addService}
            className="px-2 py-0"
            variant="success"
            id="add-service-btn"
          >
            + Add Service
          </Button>
        ) : null}

      </CardHeader>
      <div className="table-responsive">
        <table className="table service-assignment-table">
          <thead>
            <TableHeader planAasmState={get(workOrder, 'attributes.aasm_state', null)} />
          </thead>

          <tbody>
            {(sortedTaskIds || []).map(taskId => (
              <EditService
                key={taskId}
                taskId={taskId}
                planId={workOrderId}
                includeDelete={taskCount > 1}
              />
            ))}
            {(pendingWorkOrderTaskIds || []).map((taskId, index) => (
              <EditService
                key={taskId}
                taskId={taskId}
                planId={workOrderId}
                pending
                pendingIndex={index}
                includeDelete={taskCount > 1}
              />
            ))}
          </tbody>
        </table>
      </div>
    </Card>
  );
};

ServiceCreationTable.propTypes = {
  addService: PropTypes.func.isRequired,
  workOrder: PropTypes.object.isRequired,
  sortedTaskIds: PropTypes.arrayOf(idType).isRequired,
  workOrderId: idType.isRequired,
  pendingWorkOrderTaskIds: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const workOrder = getRecord(state.Api, { type: 'work_orders', id: ownProps.workOrderId });
  if (!workOrder) return {};

  const sortedTaskIds = (state.WorkOrderManagement.workOrderTaskIds || [])
    .map(id => getRecord(state.Api, {
      type: 'work_order_tasks',
      id,
    }))
    .filter(t => t)
    .sort(workOrderTaskSort)
    .map(s => s.id);

  return {
    workOrder,
    sortedTaskIds,
    pendingWorkOrderTaskIds: state.WorkOrderManagement.pendingWorkOrderTaskIds || [],
  };
};

const mapDispatchToProps = {
  addService,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceCreationTable);
