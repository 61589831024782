import React from 'react';
import PropTypes from 'prop-types';
import { ATTRIBUTES } from '../../helpers';
import ValidationIcon from '../../../WorkOrderImportContainer/WorkOrderReview/ValidationIcon';
import './styles.scss';

const ImportTable = ({ rows, getAttributeValue }) => (
  <div className="scrollable-table-wrapper">
    <table className="table scrollable-table work-order-import-table">
      <thead>
        <tr>
          {ATTRIBUTES.map(attr => (
            <th key={attr}>{attr}</th>
          ))}
        </tr>
      </thead>

      <tbody>
        {rows.map((row, i) => (
          <tr key={i} className="v-middle">
            <td>
              <div className="d-flex">
                <ValidationIcon valid={row.valid} />
                <div className="my-auto">
                  {getAttributeValue(row, ATTRIBUTES[0])}
                </div>
              </div>
            </td>
            {ATTRIBUTES.slice(1, ATTRIBUTES.length).map(attr => (
              <td key={attr}>
                {getAttributeValue(row, attr)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);


ImportTable.propTypes = {
  rows: PropTypes.array.isRequired,
  getAttributeValue: PropTypes.func.isRequired,
};

export default ImportTable;
