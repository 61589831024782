import { combineReducers } from 'redux';
import Api from 'redux-json-api-module';

import ProjectManagement from './projectManagement';
import MarkComplete from './markComplete';
import Scheduler from './scheduler';
import TeamSchedule from './teamSchedule';
import Auth from './auth';
import Chat from './chat';
import Projects from './projects';
import Units from './units';
import WorkOrderManagement from './workOrderManagement';
import WorkOrders from './workOrders';
import WorkOrdersRecurring from './workOrdersRecurring';
import CompletedPlans from './completedPlans';
import ServiceTasks from './serviceTasks';
import Navigation from './navigation';
import Inspections from './inspections';
import PartsTasks from './partsTasks';
import Inventory from './inventory';

const appReducer = combineReducers({
  Api,
  MarkComplete,
  ProjectManagement,
  Scheduler,
  TeamSchedule,
  Auth,
  Chat,
  PartsTasks,
  Projects,
  Units,
  WorkOrderManagement,
  WorkOrders,
  WorkOrdersRecurring,
  CompletedPlans,
  ServiceTasks,
  Navigation,
  Inspections,
  Inventory,
});

const reducer = (state, action) => appReducer(state, action);

export default reducer;
