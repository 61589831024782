import React from 'react';
import PropTypes from 'prop-types';

class NavLink extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hover: false,
    };

    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
  }

  handleMouseEnter() {
    this.setState({ hover: true });
  }

  handleMouseLeave() {
    this.setState({ hover: false });
  }

  render() {
    const {
      active,
      backgroundColor,
      children,
      onClick,
      textColor,
      id,
      defaultStyles,
      activeStyles,
    } = this.props;
    const { hover } = this.state;

    const buttonStyles = active || hover
      ? {
        backgroundColor,
        color: textColor,
        ...activeStyles,
      } : defaultStyles;

    return (
      <li
        className="nav-item"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <button
          id={id}
          type="button"
          role="tab"
          onClick={onClick}
          className={`btn btn-link nav-link ${active ? 'active' : ''}`}
          style={buttonStyles}
        >
          {children}
        </button>
      </li>
    );
  }
}

NavLink.propTypes = {
  id: PropTypes.string,
  active: PropTypes.bool,
  backgroundColor: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
  textColor: PropTypes.string,
  defaultStyles: PropTypes.object,
  activeStyles: PropTypes.object,
};

NavLink.defaultProps = {
  id: null,
  textColor: null,
  backgroundColor: null,
  defaultStyles: null,
  activeStyles: {},
};

NavLink.defaultProps = {
  active: false,
};

export default NavLink;
