import get from 'lodash.get';

export function canActivate(tasks) {
  if (tasks.length === 0) return false;

  return tasks.every((task) => {
    const hasService = !!get(task, 'relationships.service.data.id', null);
    const hasUser = !!get(task, 'relationships.user.data.id', null);
    const hasStart = !!get(task, 'attributes.started_at', null);
    const hasEnd = !!get(task, 'attributes.ended_at', null);

    return hasService && hasUser && hasStart && hasEnd;
  });
}
