import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import PlanLabel from './PlanLabel';
import ChannelLabel from './ChannelLabel';
import DirectMessageLabel from './DirectMessageLabel';
import { idType } from '../../helpers/propTypes';
import { fetchMessageThreadMessengers } from '../../redux/modules/chat';
import Messengers from './Messengers';

class Header extends Component {
  componentDidMount() {
    this.refresh();
  }

  refresh = () => {
    const { activeMessageThreadId, fetchMessageThreadMessengers } = this.props;

    if (activeMessageThreadId) {
      fetchMessageThreadMessengers();
    }
  }

  getLabel = () => {
    const { activeChatType, userId } = this.props;

    if (activeChatType === 'Plan') {
      return <PlanLabel />;
    } if (activeChatType === 'ChatChannel') {
      return <ChannelLabel />;
    } if (activeChatType === 'DirectMessage') {
      return <DirectMessageLabel userId={userId} />;
    }

    return null;
  }

  render() {
    const { teamName } = this.props;

    return (
      <div className="pt-3" style={{ height: '60px', display: 'flex' }}>
        <div className="font-weight-bold fs-16" style={{ width: '300px' }}>
          {`${teamName} Chat`}
        </div>

        <div className="chat-window ml-1" style={{ justifyContent: 'space-between', display: 'flex' }}>
          {this.getLabel()}

          <Messengers />
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  activeChatType: PropTypes.string,
  teamName: PropTypes.string.isRequired,
  activeMessageThreadId: idType,
  fetchMessageThreadMessengers: PropTypes.func.isRequired,
  userId: idType.isRequired,
};

Header.defaultProps = {
  activeChatType: null,
  activeMessageThreadId: null,
};

const mapStateToProps = (state) => {
  let activeChatType = null;
  if (state.Chat.activePlanType) {
    activeChatType = 'Plan';
  } else if (state.Chat.activeChannelId) {
    activeChatType = 'ChatChannel';
  } else if (state.Chat.activeDirectMessageChatId) {
    activeChatType = 'DirectMessage';
  }

  return {
    activeChatType,
    activeMessageThreadId: state.Chat.activeMessageThreadId,
  };
};

const mapDispatchToProps = {
  fetchMessageThreadMessengers,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
