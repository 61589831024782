import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { getRecord, getRelationship } from 'redux-json-api-module';
import Select from 'react-select';
import { getOtherDMUser } from '../helpers';
import { setActiveMessageThread } from '../../../redux/modules/chat';
import UserOption from '../../../components/MaintenanceSelect/UserOption';

const CreateDirectMessageModal = ({
  show,
  onHide,
  options,
  threadIdMap,
  setActiveMessageThread,
}) => {
  const [chatId, setChatId] = useState(null);

  const createDirectMessage = () => {
    setActiveMessageThread({
      planType: null,
      planId: null,
      taskType: null,
      taskId: null,
      channelId: null,
      directMessageChatId: chatId,
      messageThreadId: threadIdMap[chatId],
    });

    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="sm"
      id="create-direct-message-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>New Direct Message</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Select
          options={options}
          onChange={opt => setChatId(opt.value)}
          components={{ Option: UserOption }}
          className="user-select"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={createDirectMessage}
          variant="success"
          disabled={!chatId}
        >
          Create Direct Message
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CreateDirectMessageModal.propTypes = {};

const mapStateToProps = (state) => {
  const managementOptions = [];
  const maintenanceOptions = [];
  const threadIdMap = {};

  state.Chat.activeDirectMessageChatIds.forEach((id) => {
    const dmc = getRecord(state.Api, { type: 'direct_message_chats', id });
    const mt = getRelationship(state.Api, dmc.relationships.message_thread);

    // hide chats with messages
    if (mt.relationships.last_message.data !== null) {
      return;
    }

    const { otherUserId } = getOtherDMUser(dmc, state.Auth.currentUser.id, state.Api);
    const user = getRecord(state.Api, { type: 'users', id: otherUserId });

    threadIdMap[dmc.id] = mt.id;

    const option = {
      value: id,
      label: user.attributes.name,
      id: user.id,
      name: user.attributes.name,
      initials: user.attributes.initials,
      color: user.attributes.color,
      avatar: user.attributes.avatar,
    };

    if (user?.attributes?.app_role === 'maintenance') {
      maintenanceOptions.push(option);
    } else {
      managementOptions.push(option);
    }
  });

  return {
    options: [
      { label: 'Management', options: managementOptions },
      { label: 'Maintenance', options: maintenanceOptions },
    ],
    threadIdMap,
  };
};

const mapDispatchToProps = {
  setActiveMessageThread,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CreateDirectMessageModal);
