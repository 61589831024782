/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../components/Loader';

const Image = ({ src, onDelete }) => {
  const [loading, setLoading] = useState(true);

  return (
    <div className="responsive mb-10 mr-15" style={{ cursor: 'default' }}>
      {loading ? (
        <div style={{ width: 150, height: 150, aspectRatio: '1' }}>
          <Loader />
        </div>
      ) : null}
      <img
        src={src}
        alt=""
        className="thumbnail"
        style={{ width: 150, height: 150, aspectRatio: '1', objectFit: 'cover', display: loading ? 'none' : 'inline' }}
        onLoad={() => setLoading(false)}
      />
      {onDelete ? (
        <i
          className="fa fa-trash position-absolute cursor-pointer text-center"
          style={{ width: 14 }}
          onClick={onDelete}
        />
      ) : (
        <i
          className="fa fa-spinner fa-pulse position-absolute text-muted"
        />
      )}
    </div>
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  onDelete: PropTypes.func,
};

Image.defaultProps = {
  onDelete: null,
};

const ImageGallery = ({ images, pendingImages, onDelete }) => (
  <div className="image_picker">
    {images.map(image => (
      <Image
        key={image.file_object_url || image.id}
        src={image.file_object_url || image.file.thumb_sq.url}
        onDelete={() => onDelete(image.id)}
      />
    ))}
    {pendingImages.map((image, index) => (
      <Image key={index} src={image.preview} />
    ))}
  </div>
);

ImageGallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.any).isRequired,
  pendingImages: PropTypes.arrayOf(PropTypes.any).isRequired,
  onDelete: PropTypes.func.isRequired,
  onLoad: PropTypes.func,
};

ImageGallery.defaultProps = {
  onLoad: null,
};

export default ImageGallery;
