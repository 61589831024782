import { connect } from 'react-redux';
import moment from 'moment/moment';
import { getRelationship, saveRecord, getRecord } from 'redux-json-api-module';
import get from 'lodash.get';
import { openScheduler } from '../../../redux/modules/scheduler';
import { openMarkComplete } from '../../../redux/modules/markComplete';
import { assignUserGeneric, deleteWorkOrderTask } from '../../../redux/modules/workOrderManagement';
import ServiceRow from '../../../components/ServiceAssignmentTable/ServiceRow';

const mapStateToProps = (state, ownProps) => {
  const workOrderTask = getRecord(state.Api, {
    type: 'work_order_tasks',
    id: ownProps.taskId,
  });

  if (!workOrderTask) return {};

  const attributes = get(workOrderTask, 'attributes', { serviceStatus: {} });

  const {
    assignable, service_status: { private: serviceStatus },
    aasm_state: aasmState, completed_at: completedAt, progress,
    manually_completed_at: manuallyCompletedAt,
    user_options: userOptions,
    service_badge: serviceBadge, schedule_string: scheduleString,
  } = attributes;

  const completed = completedAt || manuallyCompletedAt;
  const workOrder = getRelationship(state.Api, workOrderTask.relationships.work_order);
  let formattedCompletedAt;

  if (workOrder.attributes.aasm_state === 'completed') {
    formattedCompletedAt = completed && aasmState === 'completed'
      ? moment(completed).format('MM/DD/YYYY')
      : 'Unknown';
  } else {
    formattedCompletedAt = completed && aasmState === 'completed'
      ? moment(completed).format('MM/DD')
      : 'Unknown';
  }

  const user = getRelationship(state.Api, workOrderTask.relationships.user);
  const serviceId = get(workOrderTask, 'relationships.service.data.id', null);
  const showDelete = workOrderTask.relationships.work_order_features.data.length === 0
    && workOrder.relationships.work_order_tasks.data.length > 1;

  return {
    serviceBadge,
    userOptions,
    user,
    assignable,
    aasmState,
    serviceStatus,
    scheduleString,
    taskType: 'work_order_tasks',
    formattedCompletedAt,
    progress,
    serviceId,
    showConditionCell: false,
    completedAt,
    startedAt: workOrderTask.attributes.started_at,
    lateBadge: workOrderTask.attributes.late_badge,
    partsBadge: workOrderTask.attributes.parts_badge,
    showDelete,
  };
};

const mapDispatchToProps = {
  assignUser: assignUserGeneric,
  deleteTask: deleteWorkOrderTask,
  openScheduler,
  openMarkComplete,
  saveRecord,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRow);
