import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { Form, Field } from 'react-final-form';
import { Button } from 'react-bootstrap';
import { getRecord, saveRecord } from 'redux-json-api-module';
import { firstBy } from 'thenby';
import toastr from 'toastr';

const PropertyForm = ({ visible, onHide, property, units, saveRecord }) => {
  const onSubmit = (values) => {
    const record = {
      type: 'properties',
      id: property.id,
      attributes: values,
    };

    saveRecord(record, { params: { window_id: window.WINDOW_ID } }).then(() => {
      toastr.success('Property successfully saved.');
      onHide();
    }).catch(() => {
      toastr.error('Property could not be saved.');
    });
  };

  const orderedUnits = Object.values(units).sort(firstBy(a => a.attributes.short_address));

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        name: property?.attributes?.name ?? '',
        unit_ids: property?.relationships?.units?.data?.map(r => r.id) ?? [],
      }}
      render={({ handleSubmit, form, submitting, pristine, values }) => (
        <Modal show={visible} onHide={onHide}>
          <form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>{property?.id ? 'Edit' : 'Create'} Property</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="mb-3">
                <label className="form-label">Name</label>
                <Field
                  name="name"
                  component="input"
                  type="text"
                  className="form-control"
                />
              </div>
              <div>
                <label className="form-label">Units</label>
                {orderedUnits.map(u => (
                  <div key={u.id} className="mb-3 form-check">
                    <label className="form-check-label">
                      <Field
                        name="unit_ids"
                        component="input"
                        type="checkbox"
                        value={u.id}
                        className="form-check-input"
                      />
                      {u.attributes.short_address}
                    </label>
                  </div>
                ))}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={onHide}
              >
                Close
              </Button>
              <Button
                type="submit"
                variant="primary"
                disabled={submitting || pristine}
              >
                Save Changes
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      )}
    />
  );
};

const mapStateToProps = (state, ownProps) => ({
  property: getRecord(state.Api, { type: 'properties', id: ownProps.id }) || {},
  units: state.Api.units || {},
});

const mapDispatchToProps = {
  saveRecord,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PropertyForm);
