import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRelationship } from 'redux-json-api-module';
import { CHILD_LISTING_TYPE_MAP, RELATIONSHIP_MAP } from '../../helpers/types';
import { taskSort } from '../../helpers/sorts';
import { genericRecordPropType, userObject } from '../../helpers/propTypes';
import Avatar from '../../components/Avatar';

const TeamCell = ({ users }) => (
  <td>
    {users.map(user => (
      <Avatar key={user.id} {...user.attributes} className="avatar-xs d-inline-block" />
    ))}
  </td>
);

TeamCell.propTypes = {
  plan: genericRecordPropType.isRequired,
  users: PropTypes.arrayOf(userObject),
};

TeamCell.defaultProps = {
  users: [],
};

const mapStateToProps = (state, ownProps) => {
  const planType = ownProps.plan.type;

  const tasks = Object.values(state.Api[CHILD_LISTING_TYPE_MAP[planType]] || {})
    .filter(t => t.relationships[RELATIONSHIP_MAP[planType]].data.id === ownProps.plan.id)
    .sort(taskSort);

  const users = tasks.map(t => getRelationship(state.Api, t.relationships.user)).filter(u => u);
  const userMap = {};
  users.forEach(user => userMap[user.id] = user);

  return {
    users: Object.values(userMap),
  };
};

export default connect(
  mapStateToProps,
)(TeamCell);
