import React, { Component } from 'react';
import Alert from '../../components/Alert';
import { Card } from '../../components/Card';
import NavLink from '../../components/ServiceDetails/NavLink';
import { idType } from '../../helpers/propTypes';
import DescriptionsTab from './DescriptionsTab';
import AreasTab from './AreasTab';

const TABS = [
  'Areas & Parts',
  'Descriptions',
];

class InspectionsContainer extends Component {
  state = {
    currentTab: 'Areas & Parts',
  };

  render() {
    const { team_id: teamId } = this.props;
    const { currentTab } = this.state;

    return (
      <Card>
        <Alert
          type="info"
          iconClassName="fa fa-exclamation-circle"
          title="Manage Inspection Templates"
        >
          Intimely has a full Inspection Workflow that captures the areas, parts, descriptions,
          and pictures for each inspection unit. When your inspection team member is assigned to a
          Make Ready, they will select each Area (such as Kitchen), and mark each Part (such as
          Refrigerator, Sink, Light Bulbs etc) as "Good" or "Needs Repair". For any part that needs
          repair, your team member will describe the work needed, and take pictures. Below, you may
          customize the Areas, Parts, and Descriptions you want your inspection team member to
          choose from. All lists are sorted alphabetically when you click save.
        </Alert>
        <div className="vtabs w-100" style={{ tableLayout: 'fixed' }}>
          <ul className="nav nav-tabs tabs-vertical" role="tablist" style={{ overflowY: 'hidden' }}>
            {TABS.map(tab => (
              <NavLink
                key={tab}
                onClick={() => this.setState({ currentTab: tab })}
                active={tab === currentTab}
              >
                <div>
                  {tab}
                </div>
              </NavLink>
            ))}
          </ul>
          <div className="tab-content" style={{ paddingTop: 0 }}>
            {currentTab === 'Descriptions' ? (
              <DescriptionsTab teamId={teamId} />
            ) : (
              <AreasTab teamId={teamId} />)
            }
          </div>
        </div>
      </Card>
    );
  }
}

InspectionsContainer.propTypes = {
  team_id: idType.isRequired,
};

export default InspectionsContainer;
