import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';
import WorkOrdersRecurringContainer from '../containers/WorkOrdersRecurringContainer';

const WorkOrders = props => (
  <Provider store={store}>
    <WorkOrdersRecurringContainer {...props} />
  </Provider>
);

export default WorkOrders;
