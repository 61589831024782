import get from 'lodash.get';

export const groupBy = (arr, attr) => {
  const map = {};

  arr.forEach((i) => {
    const val = get(i, attr);
    if (!map[val]) map[val] = [];
    map[val].push(i);
  });

  return map;
};
