import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord } from 'redux-json-api-module/dist/selectors';
import { Field } from 'react-final-form';
import COLORS from '../../helpers/colors';
import TeamMemberCheckbox from './TeamMemberCheckbox';
import { idType } from '../../helpers/propTypes';

class TeamMemberSelect extends Component {
  static propTypes = {
    managementUsers: PropTypes.array.isRequired,
    maintenanceUsers: PropTypes.array.isRequired,
    isCreating: PropTypes.bool.isRequired,
    currentUserId: idType.isRequired,
  }

  render() {
    const { managementUsers, maintenanceUsers, isCreating, currentUserId } = this.props;

    return (
      <div>
        <h4 className="text-center">Invite Team Members</h4>

        <div className="p-3" style={{ border: `1px solid ${COLORS.greyBorder}` }}>
          <div>
            <h4>Management Members</h4>
            <hr />
            {managementUsers.length === 0 ? (
              <span className="font-italic">None available</span>
            ) : null}
            {managementUsers.map(user => (
              <Field
                component={TeamMemberCheckbox}
                name={`user_${user.id}`}
                user={user}
                key={user.id}
                type="checkbox"
                isCreating={isCreating}
                currentUserId={currentUserId}
              />
            ))}
          </div>

          <div className="my-4">
            <h4>Maintenance Members</h4>
            <hr />
            {maintenanceUsers.length === 0 ? (
              <span className="font-italic">None available</span>
            ) : null}
            {maintenanceUsers.map(user => (
              <Field
                component={TeamMemberCheckbox}
                name={`user_${user.id}`}
                user={user}
                key={user.id}
                type="checkbox"
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const managementUsers = (state.Chat.managementUserIds || []).map(id => (
    getRecord(state.Api, { type: 'users', id })
  )).filter(u => u);

  const maintenanceUsers = (state.Chat.maintenanceUserIds || []).map(id => (
    getRecord(state.Api, { type: 'users', id })
  )).filter(u => u);

  return {
    managementUsers,
    maintenanceUsers,
  };
};

export default connect(mapStateToProps)(TeamMemberSelect);
