import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord } from 'redux-json-api-module';
import get from 'lodash.get';
import VisibilitySensor from 'react-visibility-sensor/visibility-sensor';
import Avatar from '../Avatar';
import { taggableUserObject, idType } from '../../helpers/propTypes';
import ChatTime from './ChatTime';
import COLORS from '../../helpers/colors';
import EditActions from './EditActions';
import PopoverStickOnHover from './PopoverStickOnHover';
import SimpleMessage from './SimpleMessage';
import GalleryMessage from './GalleryMessage';
import SystemMessage from './SystemMessage';
import { isSameDay, isSameUser } from './helpers';

const ChatImage = ({ initials, avatar, color }) => (
  <div className="chat-img">
    <Avatar initials={initials} avatar={avatar} color={color} />
  </div>
);

ChatImage.propTypes = {
  initials: PropTypes.string.isRequired,
  avatar: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
};

const ChatImagePlaceholder = () => (
  <div className="chat-img" />
);

const MessageHeader = ({
  messageId,
  userName,
}) => (
  <>
    <h5 id={`message-header-${messageId}`} className="d-inline-block" style={{ fontSize: '14px' }}>{userName}</h5>
    <ChatTime messageId={messageId} />
  </>
);

MessageHeader.propTypes = {
  messageId: idType.isRequired,
  userName: PropTypes.string.isRequired,
};

const Message = ({
  body,
  avatar,
  isAuthor,
  initials,
  color,
  attachment,
  taggableUsers,
  messageId,
  userId,
  messageType,
  galleryImages,
  user_name,
  onVisibilityChange,
  createdAt,
  sameUser,
  sameDay,
}) => {
  const [show, setShow] = useState(false);

  const defaultLiStyle = {
    paddingRight: '12px',
    paddingLeft: '12px',
    borderRadius: '4px',
    position: 'relative',
    paddingTop: '8px',
    marginTop: '2px',
  };

  const liStyle = show ? {
    ...defaultLiStyle,
    backgroundColor: COLORS.greyLight,
  } : defaultLiStyle;

  if (!user_name) return null;

  if (messageType === 'system') return <SystemMessage text={body} taggableUsers={taggableUsers} />;

  const MessageComponent = messageType === 'project_service_completion' ? GalleryMessage : SimpleMessage;

  return (
    <VisibilitySensor onChange={visible => onVisibilityChange(createdAt, visible)}>
      <PopoverStickOnHover
        component={<EditActions messageId={messageId} userId={userId} isAuthor={isAuthor} />}
        onMouseLeave={() => setShow(false)}
        placement="top-end"
        onMouseEnter={() => setShow(true)}
      >
        <li style={liStyle}>
          {sameUser && sameDay ? <ChatImagePlaceholder /> : <ChatImage avatar={avatar} initials={initials} color={color} />}
          <div className="chat-content">
            {sameUser && sameDay ? null : <MessageHeader userName={user_name} messageId={messageId} />}
            <div className="d-block pb-2">
              <MessageComponent
                messageId={messageId}
                body={body}
                isAuthor={isAuthor}
                attachment={attachment}
                taggableUsers={taggableUsers}
                galleryImages={galleryImages}
                text={body}
              />
            </div>
          </div>
        </li>
      </PopoverStickOnHover>
    </VisibilitySensor>
  );
};

Message.propTypes = {
  body: PropTypes.string,
  user_name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  avatar: PropTypes.object.isRequired,
  initials: PropTypes.string.isRequired,
  isAuthor: PropTypes.bool.isRequired,
  messageId: PropTypes.string,
  attachment: PropTypes.object,
  taggableUsers: PropTypes.arrayOf(taggableUserObject).isRequired,
  userId: PropTypes.string.isRequired,
  messageType: PropTypes.string.isRequired,
  galleryImages: PropTypes.array.isRequired,
  onVisibilityChange: PropTypes.func.isRequired,
  createdAt: PropTypes.string.isRequired,
  sameUser: PropTypes.bool.isRequired,
  sameDay: PropTypes.bool.isRequired,
};

Message.defaultProps = {
  body: null,
  attachment: null,
  messageId: undefined,
};

const mapStateToProps = (state, props) => {
  if (!props.messageId) return {};

  const message = getRecord(state.Api, {
    type: 'messages',
    id: props.messageId,
  });

  let messengerAttributes = {};
  let messengerUserId = null;

  if (message.attributes.messenger) {
    messengerUserId = get(message, 'attributes.messenger.user_id', '');
    const userId = get(props, 'userId', '');
    messengerAttributes = {
      ...message.attributes.messenger,
      isAuthor: messengerUserId.toString() === userId.toString(),
    };
  }

  let sameUser = false;
  let sameDay = false;
  if (props.previousMessageId && !props.hasBreakPoint) {
    const previousMessage = getRecord(state.Api, {
      type: 'messages',
      id: props.previousMessageId,
    });

    sameUser = isSameUser(messengerUserId, previousMessage);
    sameDay = isSameDay(message, previousMessage);
  }


  return {
    messageType: message.attributes.message_type,
    body: message.attributes.body,
    createdAt: message.attributes.created_at,
    attachment: message.attributes.attachment,
    taggableUsers: state.Chat.taggableUsers || [],
    galleryImages: message.attributes.gallery_images || [],
    sameUser,
    sameDay,
    ...messengerAttributes,
  };
};

export default connect(
  mapStateToProps,
)(Message);
