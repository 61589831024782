import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  ToggleButton,
  ToggleButtonGroup,
} from 'react-bootstrap';
import Slider from 'react-slick';
import { getRecord, getRelationship, saveRecord } from 'redux-json-api-module';
import get from 'lodash.get';
import LinesEllipsis from 'react-lines-ellipsis-with-tooltip';
import { firstBy } from 'thenby';
import moment from 'moment';
import FeatureCard from '../FeatureCard';
import { SETTINGS, NextArrow, PrevArrow, CHAT_INDEX_SETTINGS } from './helpers';
import {
  fetchAreaOptions, fetchProjectServices,
  fetchTask, markFeatureComplete, markFeaturePending,
  setInspectionTabToggle,
} from '../../redux/modules/projectManagement';
import { fetchWorkOrderTasks } from '../../redux/modules/workOrderManagement';
import Loader from '../Loader';
import { idType } from '../../helpers/propTypes';
import changeServiceIcon from '../../../assets/images/icon/change-service-icon.png';
import featureCardIcon from '../../../assets/images/icon/feature-card.png';
import inspectionImagesIcon from '../../../assets/images/icon/inspection-images.png';
import GenericImageSlider from '../GenericImageSlider';
import TaskBadgeHeader from './TaskBadgeHeader';
import FeatureFormModal from '../FeatureFormModal';
import { PARENT_TYPE_MAP } from '../../helpers/types';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const buttonToolbarStyle = {
  marginTop: '-14px',
  marginRight: '6px',
  float: 'right',
  gap: '5px',
};

const getControlArrows = (slideCount) => {
  const size = slideCount / 2;
  return {
    nextArrow: <NextArrow btnClassName="bottom-control" relativeControls sliderCount={size} />,
    prevArrow: <PrevArrow btnClassName="bottom-control" relativeControls sliderCount={size} />,
  };
};

const ImagesView = ({ images }) => (
  images && images.length > 0 ? (
    <GenericImageSlider
      images={images}
      settings={{ ...SETTINGS, ...getControlArrows(images.length) }}
      pagination={false}
      sourceVariant="thumb_sq"
      placeholderVariant="thumb_placeholder_sq"
    />
  ) : null
);

class InspectionTab extends PureComponent {
  state = {
    bulkChangeServiceFormVisible: false,
    changeServiceFormVisible: false,
    featureFormModalVisible: false,
    currentFeature: null,
    loading: true,
    areaOptions: [],
  };

  componentDidMount() {
    const { taskId, fetchTask, taskType } = this.props;
    this._isMounted = true;

    fetchTask(taskType, taskId)
      .then(() => this.setState({ loading: false }));
    this.fetchAreaOptions();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  openBulkChangeServiceForm = () => {
    this.setState({
      bulkChangeServiceFormVisible: true,
    });
  };

  closeBulkChangeServiceForm = () => {
    if (!this._isMounted) return;

    this.setState({ bulkChangeServiceFormVisible: false });
  };

  openChangeServiceForm = (feature) => {
    this.setState({
      changeServiceFormVisible: true,
      currentFeature: feature,
    });
  };

  closeChangeServiceForm = () => {
    if (this._isMounted) {
      this.setState({
        changeServiceFormVisible: false,
        currentFeature: null,
      });
    }
  };

  openEditServiceForm = (feature) => {
    this.setState({
      editServiceFormVisible: true,
      currentFeature: feature,
    });
  };

  closeEditServiceForm = () => {
    if (this._isMounted) {
      this.setState({
        editServiceFormVisible: false,
        currentFeature: null,
      });
    }
  };

  getControlArrows = (slideCount) => {
    // really needs to be divided by slidesToScroll
    // because the library controls the placement of the buttons, it's probably easier
    // to create a custom control component rather than this much absolute positioning
    const size = slideCount / 2;
    return {
      nextArrow: <NextArrow btnClassName="bottom-control" relativeControls sliderCount={size} />,
      prevArrow: <PrevArrow btnClassName="bottom-control" relativeControls sliderCount={size} />,
    };
  };

  createFeature = async (attributes) => {
    const { task, saveRecord, fetchProjectServices, fetchWorkOrderTasks } = this.props;
    const record = {
      id: null,
      type: 'features',
      attributes: { ...attributes },
    };
    const options = { params: { window_id: window.WINDOW_ID } };
    let planId;

    if (task.type === 'project_services') {
      record.attributes.project_id = task.relationships.project.data.id;
      options.params.include = 'project_service';
      planId = task.relationships.project.data.id;
    } else {
      record.type = 'work_order_features';
      record.attributes.work_order_id = task.relationships.work_order.data.id;
      options.params.include = 'work_order_task';
      planId = task.relationships.work_order.data.id;
    }

    const resp = await saveRecord(record, options);
    if (resp.error) return resp;

    if (task.type === 'project_services') {
      fetchProjectServices(planId);
    } else {
      fetchWorkOrderTasks(planId);
    }

    return resp;
  };

  fetchAreaOptions = () => {
    const { fetchAreaOptions, unitId } = this.props;

    if (unitId) {
      fetchAreaOptions(unitId)
        .then((resp) => {
          if (resp.error) {
            console.log(resp.error);
            return;
          }

          this.setState({ areaOptions: resp.payload.data });
        });
    }
  };

  render() {
    const {
      task,
      areaItems, descriptionOptions, serviceOptions, conditionOptions,
      setInspectionTabToggle, inspectionTabToggle,
      featureImages, reducedSize, title, features,
      ServiceForm, markFeatureComplete, markFeaturePending,
    } = this.props;

    const {
      bulkChangeServiceFormVisible,
      changeServiceFormVisible,
      currentFeature,
      editServiceFormVisible,
      loading,
      featureFormModalVisible,
      areaOptions,
    } = this.state;

    if (!task) return null;

    if (loading) return <div style={{ height: 500 }}><Loader /></div>;

    const settings = reducedSize ? CHAT_INDEX_SETTINGS : SETTINGS;
    const initialValues = { service_id: parseInt(task.relationships.service.data.id) };

    const slides = features ? features.map(f => (
      <div key={f.id}>
        <FeatureCard
          featureId={f.id}
          featureType={f.type}
          openChangeServiceForm={this.openChangeServiceForm}
          openEditServiceForm={this.openEditServiceForm}
          onMarkComplete={markFeatureComplete}
          onMarkPending={markFeaturePending}
        />
      </div>
    )) : [];

    slides.push((
      <div key="feature-form" className="card feature-card card-bordered h-100">
        <div className="card-header">
          <LinesEllipsis
            style={{ whiteSpace: 'pre-wrap' }}
            text="Add New Feature"
            maxLine="1"
            ellipsis="..."
            trimRight
            basedOn="words"
          />
        </div>
        <div className="image-placeholder-container">
          <div
            style={{
              display: 'flex',
              height: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              variant="success"
              size="lg"
              onClick={() => (
                this.setState({ featureFormModalVisible: !featureFormModalVisible })
              )}
            >
              +
            </Button>
          </div>
        </div>
        <FeatureFormModal
          visible={featureFormModalVisible}
          onHide={() => this.setState({ featureFormModalVisible: false })}
          onSubmit={this.createFeature}
          areas={areaOptions}
          descriptions={descriptionOptions}
          items={areaItems}
          services={serviceOptions}
          initialValues={initialValues}
        />
      </div>
    ));

    return (
      <div>
        <TaskBadgeHeader taskId={task.id} taskType={task.type} />
        <div>
          <h3 className="border-bottom pb-2 mb-3 mt-3">
            {`${title} Items`}
            <ButtonToolbar style={buttonToolbarStyle}>
              <ButtonGroup>
                <Button
                  id="bulk-change-service-button"
                  variant="secondary"
                  title="Move All Image(s) to New Service"
                  onClick={this.openBulkChangeServiceForm}
                >
                  <img src={changeServiceIcon} alt="Bulk Update" width="30" />
                </Button>
              </ButtonGroup>
              <ToggleButtonGroup
                type="radio"
                name="options"
                value={inspectionTabToggle}
                onChange={setInspectionTabToggle}
              >
                <ToggleButton variant="secondary" value="feature-card">
                  <img style={{ height: 30 }} src={featureCardIcon} />
                </ToggleButton>
                <ToggleButton variant="secondary" value="inspection-images">
                  <img style={{ height: 30 }} src={inspectionImagesIcon} />
                </ToggleButton>
              </ToggleButtonGroup>
            </ButtonToolbar>
          </h3>
        </div>

        {inspectionTabToggle === 'inspection-images' ? (
          <ImagesView images={featureImages} />
        ) : (
          <>
            {slides.length > 0 && (
              <Slider {...settings} {...this.getControlArrows(features.length)}>
                {slides}
              </Slider>
            )}

            <ServiceForm
              componentType="ChangeService"
              key={`change-${currentFeature && currentFeature.id}`}
              visible={changeServiceFormVisible}
              onClose={this.closeChangeServiceForm}
              featureId={currentFeature && currentFeature.id}
              featureType={currentFeature && currentFeature.type}
              areaItems={areaItems}
              descriptionOptions={descriptionOptions}
              serviceOptions={serviceOptions}
              conditionOptions={conditionOptions}
            />

            <ServiceForm
              componentType="Edit"
              key={`edit-${currentFeature && currentFeature.id}`}
              visible={editServiceFormVisible}
              onClose={this.closeEditServiceForm}
              featureId={currentFeature && currentFeature.id}
              featureType={currentFeature && currentFeature.type}
              areaItems={areaItems}
              descriptionOptions={descriptionOptions}
              serviceOptions={serviceOptions}
              conditionOptions={conditionOptions}
            />
          </>
        )}

        <ServiceForm
          componentType="BulkChangeService"
          visible={bulkChangeServiceFormVisible}
          task={task}
          features={features}
          featureImages={featureImages}
          onClose={this.closeBulkChangeServiceForm}
        />
      </div>
    );
  }
}

InspectionTab.propTypes = {
  features: PropTypes.arrayOf(PropTypes.object),
  task: PropTypes.objectOf(PropTypes.any),
  taskId: idType.isRequired,
  taskType: PropTypes.string.isRequired,
  taskImages: PropTypes.arrayOf(PropTypes.object),
  fetchTask: PropTypes.func.isRequired,
  featureImages: PropTypes.array.isRequired,
  reducedSize: PropTypes.bool,
  title: PropTypes.string,
  ServiceForm: PropTypes.object.isRequired,

  // connected
  saveRecord: PropTypes.func.isRequired,
};

InspectionTab.defaultProps = {
  features: [],
  taskImages: [],
  task: null,
  reducedSize: false,
  title: 'Inspection',
};

const mapStateToProps = (state, ownProps) => {
  const task = getRecord(state.Api, {
    type: ownProps.taskType,
    id: ownProps.taskId,
  });

  const plan = task ? getRelationship(state.Api, task.relationships[PARENT_TYPE_MAP[task.type]]) : null;
  const unitId = get(plan, 'relationships.unit.data.id', null);

  const featureRels = get(task, 'relationships.features', get(task, 'relationships.work_order_features', []));

  const features = task ? getRelationship(state.Api, featureRels) : [];
  features.sort(firstBy(f => moment(f.attributes.created_at)));
  let featureImages = [];

  (features || []).forEach((feature) => {
    const featureImageRels = get(feature, 'relationships.feature_images', []);
    const images = getRelationship(state.Api, featureImageRels);
    if (images) featureImages = featureImages.concat(images);
  });

  return {
    task,
    features,
    featureImages: featureImages.map(i => (i.attributes || {})),
    inspectionTabToggle: state.ProjectManagement.inspectionTabToggle,
    areaItems: state.ProjectManagement.areaItems,
    descriptionOptions: state.ProjectManagement.descriptionOptions,
    serviceOptions: state.ProjectManagement.serviceOptions,
    unitId,
  };
};

const mapDispatchToProps = {
  saveRecord,
  fetchTask,
  setInspectionTabToggle,
  fetchAreaOptions,
  markFeatureComplete,
  markFeaturePending,
  fetchProjectServices,
  fetchWorkOrderTasks,
};

export default connect(mapStateToProps, mapDispatchToProps)(InspectionTab);
