export const projectPath = (project) => {
  const { attributes: { aasm_state: state } } = project;

  let route = `/projects/${project.id}`;

  if (state === 'pending_inspection' || state === 'inspection_confirmation') {
    route += '/inspection/edit';
  }

  return route;
};

export const simplifiedPlanPath = (planAasmState, planType, planId) => {
  if (planType === 'work_orders') {
    return `/work_orders/${planId}`;
  }

  let route = `/projects/${planId}`;

  if (planAasmState === 'pending_inspection' || planAasmState === 'inspection_confirmation') {
    route += '/inspection/edit';
  }

  return route;
};
