import React from 'react';
import PropTypes from 'prop-types';
import { showErrors } from './helpers';

const InputWrapper = ({
  label,
  children,
  fieldContainerClassName,
  required,
  errors,
  attribute,
  capitalize,
}) => (
  <div className="form-group row">
    <label
      className={`col-sm-3 col-form-label text-md-right ${capitalize ? 'text-capitalize' : ''}`}
    >
      {label}
      {required ? (
        <>
          <abbr title="required">*</abbr>
        </>
      ) : null}
    </label>
    <div className={`col-sm-9 ${fieldContainerClassName}`}>
      {children}
      {showErrors(errors, attribute)}
    </div>
  </div>
);

InputWrapper.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  fieldContainerClassName: PropTypes.string,
  required: PropTypes.bool,
  errors: PropTypes.object,
  attribute: PropTypes.string,
  capitalize: PropTypes.bool,
};

InputWrapper.defaultProps = {
  fieldContainerClassName: '',
  required: false,
  errors: {},
  attribute: null,
  capitalize: false,
};

export default InputWrapper;
