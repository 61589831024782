import React from 'react';
import PropTypes from 'prop-types';

const ProjectServiceTableHeaderComponent = ({ aasmState }) => (
  <tr>
    <th>Service</th>
    <th>Condition</th>
    <th>Assigned To</th>
    {['active', 'completed', 'review'].includes(aasmState) && <th>Status</th>}
    <th width="1">Sort</th>
    <th>Schedule</th>
    <th>Done</th>
  </tr>
);

ProjectServiceTableHeaderComponent.propTypes = {
  aasmState: PropTypes.string.isRequired,
};

export default ProjectServiceTableHeaderComponent;
