import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';
import ProjectCreateContainer from '../containers/ProjectCreateContainer/ProjectCreateContainer';

const ProjectCreate = props => (
  <Provider store={store}>
    <ProjectCreateContainer {...props} />
  </Provider>
);

export default ProjectCreate;
