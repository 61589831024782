import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';
import InspectionSummaryContainer
  from '../containers/InspectionSummaryContainer/InspectionSummaryContainer';

const InspectionSummary = props => (
  <Provider store={store}>
    <InspectionSummaryContainer {...props} />
  </Provider>
);

export default InspectionSummary;
