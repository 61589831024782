import { connect } from 'react-redux';
import { getRelationship, getRecord } from 'redux-json-api-module';
import ServiceDetails from '../../components/ServiceDetails';
import { taskSort } from '../../helpers/sorts';

const mapStateToProps = (state, ownProps) => {
  const project = getRecord(state.Api, {
    type: 'projects',
    id: ownProps.planId,
  });

  const tasks = (project
    ? getRelationship(state.Api, project.relationships.project_services)
    : []);

  tasks.sort(taskSort);

  const completed = project ? project.attributes.aasm_state === 'completed' : false;

  const activeTab = state.ProjectManagement.tab;

  return {
    activeTab,
    messengersLoaded: state.Chat.messengersLoaded,
    tasks,
    completed,
    planType: 'projects',
    activeTaskId: state.Navigation.activeTaskId,
    activeTaskType: state.Navigation.activeTaskType,
    taskType: 'project_services',
    inspectionTitle: 'Inspection',
  };
};

export default connect(mapStateToProps)(ServiceDetails);
