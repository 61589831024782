import React from 'react';
import PropTypes from 'prop-types';
import Alert from '../../../components/Alert';

const StatusAlert = ({
  errors,
  importObject,
}) => {
  if (errors.length > 0) {
    return (
      <Alert
        type="danger"
        iconClassName="fa fa-exclamation-circle"
        title="Please double check your Excel import file."
      >
        Please review the errors listed above, update your Excel report, and then import the file
        again.
      </Alert>
    );
  }

  if (importObject.attributes.rows.length === 0) {
    return (
      <Alert
        type="danger"
        iconClassName="fa fa-exclamation-circle"
        title="Please double check your Excel import file."
      >
        There are no new addresses in the file.
      </Alert>
    );
  }

  return (
    <Alert
      type="success"
      iconClassName="fa fa-check-circle"
      title="Import Successful! Please review your imported units."
    >
      Your import was successful! Next, you'll assign and schedule your units.
    </Alert>
  );
};

StatusAlert.propTypes = {
  errors: PropTypes.array.isRequired,
  importObject: PropTypes.object.isRequired,
};

export default StatusAlert;
