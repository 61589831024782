import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import InvoiceForm from './InvoiceForm';
import TaskBadgeHeader from '../TaskBadgeHeader';
import { idPropType } from '../../../helpers/propTypes';

const InvoiceFormModal = (
  {
    show, onHide, serviceInvoiceId,
    taskType, taskId,
  },
) => (
  <Modal
    show={show}
    onHide={onHide}
    size="xl"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title>
        <strong>
          {serviceInvoiceId ? 'Edit ' : 'Create '}
          Invoice
        </strong>
      </Modal.Title>
    </Modal.Header>
    <TaskBadgeHeader taskId={taskId} taskType={taskType} />
    <Modal.Header>
      <Modal.Title>
        <strong>
          Parts List
        </strong>
      </Modal.Title>
    </Modal.Header>
    <InvoiceForm
      serviceInvoiceId={serviceInvoiceId}
      taskType={taskType}
      taskId={taskId}
      onHide={onHide}
    />
  </Modal>
);

InvoiceFormModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  serviceInvoiceId: idPropType,
  show: PropTypes.bool.isRequired,
  taskId: idPropType.isRequired,
  taskType: PropTypes.string.isRequired,
};

InvoiceFormModal.defaultProps = {
  serviceInvoiceId: null,
};

export default InvoiceFormModal;
