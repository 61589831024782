import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { saveRecord } from 'redux-json-api-module';
import get from 'lodash.get';
import { ATTRIBUTES, PRIORITIES, snakeCase } from './helpers';
import ImportModal from './ImportModal/ImportModal';
import ErrorModal from './ErrorModal/ErrorModal';
import FileImporter from './FileImporter/FileImporter';
import { saveImport } from '../../redux/modules/workOrderManagement';

class WorkOrderImportContainer extends Component {
  static propTypes = {
    saveRecord: PropTypes.func.isRequired,
    saveImport: PropTypes.func.isRequired,
  };

  state = {
    errorModalVisible: false,
    errors: [],
    importObject: null,
    uploading: false,
  };

  getFormData = (file) => {
    const formData = new FormData();
    formData.append('data[attributes][file]', file);
    const attributeMapping = $('#work_order_attributes')
      .serializeArray();
    attributeMapping.forEach((attr) => {
      formData.append(`data[attributes][${attr.name}]`, attr.value);
    });

    return formData;
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      this.setState({
        errorModalVisible: true,
        errors: [{
          message: 'Incorrect file type, must be .xlsx or .csv',
        }],
      });
    }
    acceptedFiles.forEach((file) => {
      this.setState({ uploading: true });
      const formData = this.getFormData(file);

      fetch('/api/v1/work_order_imports', {
        method: 'POST',
        body: formData,
      })
        .then(resp => resp.json())
        .then((response) => {
          const previousMapping = get(response, 'data.attributes.column_mapping', {}) || {};
          const visibility = Object.keys(previousMapping).length > 0
            ? {
              reviewVisible: true,
              mappingFormVisible: false,
            }
            : {
              mappingFormVisible: true,
              reviewVisible: false,
            };
          this.setState({
            importObject: response.data,
            ...visibility,
          });
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.setState({ uploading: false });
        });
    });
  };

  saveWorkOrders = () => {
    const { saveImport } = this.props;
    const { importObject } = this.state;

    const rows = get(importObject, 'attributes.rows', []) || [];
    if (rows.find(row => !row.valid)) {
      if (!window.confirm('Work Orders with errors will NOT be imported.')) return;
    }

    saveImport(importObject.id);
  };

  closeErrorModal = () => this.setState({ errorModalVisible: false });

  closeMappingModal = () => this.setState({ mappingFormVisible: false });

  closeReviewModal = () => this.setState({ reviewVisible: false });

  closeModals = () => this.setState({
    reviewVisible: false,
    mappingFormVisible: false,
  });

  openColumnMappingForm = () => this.setState({
    reviewVisible: false,
    mappingFormVisible: true,
  });

  saveColumnMapping = (values) => {
    const { importObject } = this.state;
    const { saveRecord } = this.props;

    const columnMapping = {};
    const priorityMapping = {};

    ATTRIBUTES.forEach((attr) => {
      columnMapping[snakeCase(attr)] = values[snakeCase(attr)];
    });

    PRIORITIES.forEach((priority) => {
      if (values[priority]) priorityMapping[values[priority]] = priority;
    });

    const record = {
      type: 'work_order_imports',
      id: importObject.id,
      attributes: {
        skip_last_row: values.skip_last_row,
        header_row: values.header_row,
        column_mapping: columnMapping,
        priority_mapping: priorityMapping,
      },
    };

    saveRecord(record, { params: { window_id: window.WINDOW_ID } })
      .then((resp) => {
        const data = get(resp, 'payload.data.data', null);

        this.setState({
          reviewVisible: true,
          mappingFormVisible: false,
          importObject: data,
        });
      });
  };

  handleHeaderRowChange = (headerRow) => {
    const { importObject } = this.state;
    const { saveRecord } = this.props;

    const record = {
      type: 'work_order_imports',
      id: importObject.id,
      attributes: {
        header_row: headerRow,
      },
    };

    saveRecord(record, { params: { window_id: window.WINDOW_ID } })
      .then((resp) => {
        const data = get(resp, 'payload.data.data', null);

        this.setState({
          importObject: data,
        });
      });
  }

  stopPropagation = e => e.stopPropagation();

  render() {
    const {
      errorModalVisible,
      errors,
      mappingFormVisible,
      importObject,
      reviewVisible,
      uploading,
    } = this.state;

    return (
      <>
        <div className="font-weight-bold">Step 1</div>
        <div className="mb-3">Download your property management system's Work Orders report</div>

        <div className="font-weight-bold">Step 2</div>
        <div className="mb-3">
          Update your Work Orders report to match the Sample Excel Sheet, ensuring your columns are
          labeled like the Sample.
          <span className="font-weight-bold">&nbsp;&nbsp;Save your report in .xlsx format.</span>
        </div>

        <div className="font-weight-bold">Step 3</div>
        <div className="mb-3">
          Upload your file, and you can then Assign, Schedule, and Track progress on your Intimely
          Work Orders dashboard.
        </div>

        <FileImporter onDrop={this.onDrop} uploading={uploading} />

        <div className="font-weight-bold">
          Settings
        </div>
        <hr className="mt-1" />
        <div className="mb-4">
          <div className="font-weight-bold">
            Sample Report
          </div>
          <div className="mb-3">
            Use the Sample Excel Sheet to view required columns. You may add new Work Orders to this sheet and upload it above.
          </div>
          <div>
            <a
              className="btn btn-secondary ml-3"
              href="/sample_work_order_import.xlsx"
              target="_blank"
              rel="noopener nofollow"
              onClick={this.stopPropagation}
            >
              Download Sample Excel Sheet
            </a>
          </div>
        </div>
        <ErrorModal errors={errors} visible={errorModalVisible} closeModal={this.closeErrorModal} modelName="work orders" />
        {importObject && (mappingFormVisible || reviewVisible) ? (
          <ImportModal
            mappingFormVisible={mappingFormVisible}
            reviewVisible={reviewVisible}
            closeModal={this.closeModals}
            importObject={importObject}
            onSubmitColumnMapping={this.saveColumnMapping}
            onSubmitReview={this.saveWorkOrders}
            onDrop={this.onDrop}
            openColumnMappingForm={this.openColumnMappingForm}
            uploading={uploading}
            onHeaderRowChange={this.handleHeaderRowChange}
          />
        ) : null}
      </>
    );
  }
}

const mapDispatchToProps = {
  saveRecord,
  saveImport,
};

export default connect(null, mapDispatchToProps)(WorkOrderImportContainer);
