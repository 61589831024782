import { connect } from 'react-redux';
import { getRelationship, getRecord } from 'redux-json-api-module';
import {
  fetchAreaOptions,
  splitFeature,
  updateFeatures,
  deleteFeature,
} from '../../../redux/modules/projectManagement';
import ServiceForm from './ServiceForm';

const mapStateToProps = (state, ownProps) => {
  const newState = {
    areaItems: state.ProjectManagement.areaItems,
    serviceOptions: state.ProjectManagement.serviceOptions,
    conditionOptions: state.ProjectManagement.conditionOptions,
    descriptionOptions: state.ProjectManagement.descriptionOptions,
  };

  const feature = getRecord(state.Api, {
    type: ownProps.featureType,
    id: ownProps.featureId,
  });

  if (!feature) {
    newState.featureImages = ownProps.featureImages || [];
    return newState;
  }

  const plan = getRelationship(state.Api, feature.relationships.project);
  const unit = plan ? getRelationship(state.Api, plan.relationships.unit) : null;

  return Object.assign(newState, {
    feature,
    unit,
    errors: {},
    featureImages: feature.attributes.feature_images,
  });
};

const mapDispatchToProps = {
  fetchAreaOptions,
  splitFeature,
  updateFeatures,
  deleteFeature,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServiceForm);
