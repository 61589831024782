import React, { Component } from 'react';
import { Form, Field } from 'react-final-form';
import Modal from 'react-bootstrap/Modal';
import moment from 'moment';
import get from 'lodash.get';
import { minuteOfDay } from '../helpers';
import { DateInput, TimeInput, Checkbox } from './inputs';
import submit from './submit';
import MaintenanceSelect from '../../../../components/MaintenanceSelect';
import SchedulerFormHeader from './SchedulerFormHeader';
import 'react-datepicker/dist/react-datepicker.css';
import './SchedulerForm.scss';
import WorkOrderDetails from './WorkOrderDetails';
import { simplifiedPlanPath } from '../../../../helpers/routing';

const planLink = (scheduled) => {
  if (scheduled.relationships.work_order) {
    return simplifiedPlanPath(null, 'work_orders', scheduled.relationships.work_order.data.id);
  }

  if (scheduled.relationships.project) {
    return simplifiedPlanPath(null, 'projects', scheduled.relationships.project.data.id);
  }

  return '/';
};

function changeStartDate(value, change, formValues) {
  const diff = moment(formValues.endDate)
    .diff(formValues.startDate, 'days');
  const endDate = moment(value)
    .add(diff, 'days')
    .toDate();

  change('endDate', endDate);
  change('startDate', value);
}

function changeStartTime(value, change, formValues, minTime, maxTime) {
  let diff = moment(formValues.endTime)
    .diff(moment(value), 'minutes');

  let endTime = moment(formValues.endTime);

  if (Number.isNaN(diff) || diff <= 0) {
    diff = 60;
    endTime = moment(value)
      .add(diff, 'minutes');
  }

  endTime = endTime.toDate();

  if (minuteOfDay(endTime) > minuteOfDay(maxTime)) endTime = maxTime;

  change('endTime', endTime);
  change('startTime', value);
}

function toggleIncludeTime(change, formValues, minTime) {
  change('includeTime', !formValues.includeTime);
  if (!formValues.includeTime) {
    change('startTime', minTime);
    change('endTime', moment(minTime)
      .add(1, 'hours')
      .toDate());
  }
}

const minEndTime = ({
  startDate,
  startTime,
  endDate,
}, defaultTime) => {
  const sameDay = startDate && endDate && startDate.valueOf() === endDate.valueOf();

  if (sameDay) {
    return moment(startTime)
      .add(15, 'minutes')
      .toDate();
  }

  return defaultTime;
};

class SchedulerForm extends Component {
  constructor(props) {
    super(props);

    const { user } = props;

    this.state = {
      user,
    };
  }

  handleAssignmentChange = (userId, user) => {
    this.setState({ user });
  };

  submit = (values) => {
    const { onSubmit } = this.props;
    const { user } = this.state;
    values.userId = user.id;
    submit(values, onSubmit);
  };

  render() {
    const {
      initialValues,
      onCancel,
      visible,
      minDate,
      minTime,
      maxTime,
      scheduled,
    } = this.props;

    const { user } = this.state;

    return (
      <Modal
        show={visible}
        onHide={onCancel}
        backdropClassName="scheduler-form-modal-backdrop"
      >
        <Form
          initialValues={initialValues}
          onSubmit={this.submit}
          render={({
            handleSubmit,
            values,
            form: { change },
          }) => (
            <form onSubmit={handleSubmit} className="scheduler-form">
              <div className="modal-header">
                <div className="m-auto text-center">
                  <h5 className="modal-title font-weight-bold">
                    <SchedulerFormHeader {...scheduled.attributes} />
                  </h5>
                </div>
              </div>
              <div className="modal-body">
                <div className="flex-row m-b-20">
                  <div className="col p-0">
                    <span className="font-weight-bold">
                      Assign To: &nbsp;
                    </span>
                    <MaintenanceSelect
                      user={user}
                      scheduled={scheduled}
                      onChange={this.handleAssignmentChange}
                      key={scheduled && scheduled.id}
                      menuPortalTarget={null}
                    />
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className={values.includeTime ? 'col-sm-3 col-6' : 'col-6'}>
                    <Field name="startDate">
                      {({ input }) => {
                        const newInput = { ...input };

                        newInput.onChange = value => changeStartDate(value, change, values);

                        return (
                          <DateInput
                            input={newInput}
                            label="Start Date"
                            minDate={minDate}
                          />
                        );
                      }}
                    </Field>
                  </div>

                  {values.includeTime && (
                    <React.Fragment>
                      <div className="col-sm-3 col-6">
                        <Field name="startTime">
                          {({ input }) => {
                            const newInput = { ...input };
                            newInput.onChange = value => (
                              changeStartTime(value, change, values, minTime, maxTime)
                            );

                            return (
                              <TimeInput
                                input={newInput}
                                label="Start Time"
                                minTime={minTime}
                                maxTime={maxTime}
                              />
                            );
                          }}
                        </Field>
                      </div>
                      <div className="col-sm-3 col-6">
                        <Field
                          name="endTime"
                          type="text"
                          label="End Time"
                          component={TimeInput}
                          minTime={minEndTime(values, minTime)}
                          maxTime={maxTime}
                        />
                      </div>
                    </React.Fragment>
                  )}

                  <div className={values.includeTime ? 'col-sm-3 col-6' : 'col-6'}>
                    <Field
                      name="endDate"
                      type="text"
                      label="Due Date"
                      component={DateInput}
                      minDate={values.startDate}
                    />
                  </div>
                </div>

                <Field name="includeTime">
                  {({ input }) => {
                    const newInput = { ...input };
                    newInput.onChange = () => (
                      toggleIncludeTime(change, values, minTime)
                    );

                    return (
                      <Checkbox
                        input={newInput}
                        label="Add Start and End Times"
                      />
                    );
                  }}
                </Field>
              </div>
              <hr />
              {get(scheduled, 'relationships.work_order.data.id') ? (
                <WorkOrderDetails
                  workOrderId={get(scheduled, 'relationships.work_order.data.id')}
                />
              ) : null}
              <div className="modal-footer" style={{ border: 'none' }}>
                <a
                  href={planLink(scheduled)}
                  target="_blank"
                  style={{ position: 'absolute', left: 16 }}
                >
                  Open Project
                </a>
                <button type="button" className="btn btn-secondary" onClick={onCancel}>
                  Cancel
                </button>
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              </div>
            </form>
          )}
        />
      </Modal>
    );
  }
}

export default SchedulerForm;
