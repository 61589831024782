import React from 'react';
import PropTypes from 'prop-types';
import { attachmentObject } from '../../helpers/propTypes';

const AttachmentLink = ({ attachment, isAuthor }) => (
  <div className={`box ${isAuthor ? 'bg-light-inverse' : 'bg-light-info'}`}>
    <a href={attachment.url} target="_blank" rel="noopener noreferrer">
      {attachment.name}
    </a>
  </div>
);

AttachmentLink.propTypes = {
  attachment: attachmentObject.isRequired,
  isAuthor: PropTypes.bool.isRequired,
};

export default AttachmentLink;
