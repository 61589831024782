import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import toastr from 'toastr';
import { getRecord } from 'redux-json-api-module';
import get from 'lodash.get';
import PropTypes from 'prop-types';
import Loader from '../../../components/Loader';
import GenericGanttChart from '../../TeamScheduleContainer/GanttChart/GenericGanttChart';
import { loadEvents, setTemporaryEvents, setScheduledRef, onSelectSlot, setFormVisible } from '../../../redux/modules/scheduler';
import { mapEventGrouping, groupEvents } from '../../TeamScheduleContainer/GanttChart/helpers';
import { getStyle } from './helpers';
import { getCompositeId } from '../../TeamScheduleContainer/Schedule/helpers';
import { CALENDAR_EVENT_TYPE_MAP } from '../../../helpers/types';

class PlanGanttChart extends Component {
  static propTypes = {
    eventData: PropTypes.array.isRequired,
    fetchEvents: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    setTemporaryEvents: PropTypes.func.isRequired,
    temporaryEvents: PropTypes.object.isRequired,
    setFormVisible: PropTypes.func.isRequired,
    scheduledRef: PropTypes.object,
    minDate: PropTypes.string.isRequired,
    setScheduledRef: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    formVisible: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    scheduledRef: null,
    onSubmit: null,
  }

  handleSelectDate = (date) => {
    const { setFormVisible, scheduledRef, setTemporaryEvents, temporaryEvents, minDate } = this.props;

    if (moment(date).isBefore(moment(minDate))) {
      toastr.warning(`Please select a date that is on or after ${moment(minDate).format('MM/DD')}`);
      return;
    }

    const updatedEvent = {
      ...scheduledRef,
      start: moment(date),
      end: moment(date),
    };

    setTemporaryEvents({
      ...temporaryEvents,
      [getCompositeId(updatedEvent)]: updatedEvent,
    });

    setFormVisible(true);
  }

  render() {
    const {
      eventData,
      loading,
      setTemporaryEvents,
      temporaryEvents,
      fetchEvents,
      setScheduledRef,
      formVisible,
      setFormVisible,
      scheduledRef,
      onSubmit,
    } = this.props;

    if (loading) {
      return <Loader />;
    }

    return (
      <GenericGanttChart
        eventData={eventData}
        temporaryEvents={temporaryEvents}
        chartKey="gantt-chart"
        setTemporaryEvents={setTemporaryEvents}
        fetchEvents={fetchEvents}
        setScheduledRef={setScheduledRef}
        handleSelectDate={this.handleSelectDate}
        formVisible={formVisible}
        setFormVisible={setFormVisible}
        scheduledRef={scheduledRef}
        onSubmit={onSubmit}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { events } = ownProps;// state.Scheduler.events || [];
  const results = groupEvents(events, state.Api);
  const { grouping, unitOrder } = results;
  const eventData = mapEventGrouping(grouping, unitOrder, state.Scheduler.temporaryEvents || {}, state.Api, getStyle, state.Scheduler.task);

  const scheduled = getRecord(state.Api, {
    type: CALENDAR_EVENT_TYPE_MAP[state.Scheduler.scheduledRef.type],
    id: state.Scheduler.scheduledRef.id,
  });
  const minDate = get(scheduled, 'attributes.min_date', null);

  return {
    eventData,
    temporaryEvents: state.Scheduler.temporaryEvents || {},
    loading: state.Scheduler.loading,
    formVisible: state.Scheduler.formVisible,
    scheduledRef: state.Scheduler.scheduledRef,
    minDate,
  };
};

const mapDispatchToProps = {
  fetchEvents: loadEvents,
  setTemporaryEvents,
  setScheduledRef,
  onSelectSlot,
  setFormVisible,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlanGanttChart);
