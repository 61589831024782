import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { getRecord } from 'redux-json-api-module/dist/selectors';
import { openScheduler } from '../../../redux/modules/scheduler';

const label = ({ started_at: startedAt, ended_at: endedAt, all_day: allDay }) => {
  const start = moment(startedAt);
  const end = moment(endedAt);

  if (allDay && start.isSame(end, 'day')) {
    return `${start.format('MM/DD')}`;
  }

  if (allDay) {
    return `${start.format('MM/DD')} to ${end.format('MM/DD')}`;
  }

  return `${start.format('MM/DD LT')} to ${end.format('MM/DD LT')}`;
};

const SchedulerButton = ({ openScheduler, task }) => (
  <button
    type="button"
    onClick={() => openScheduler(task)}
    className={`btn scheduler-button ${task.attributes.started_at ? 'btn-light' : 'btn-success'}`}
    disabled={task.relationships.user.data === null}
  >
    <i className="fa fa-pencil mr-2" />
    {task.attributes.started_at ? (
      label(task.attributes)
    ) : 'Schedule'}
  </button>
);

SchedulerButton.propTypes = {
  task: PropTypes.objectOf(PropTypes.any).isRequired,
  openScheduler: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
  task: ownProps.task || getRecord(state.Api, {
    type: ownProps.taskType,
    id: ownProps.id,
  }),
});

const mapDispatchToProps = {
  openScheduler,
};

export default connect(mapStateToProps, mapDispatchToProps)(SchedulerButton);
