import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Calendar from 'react-calendar';

class DatePicker extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    detail: PropTypes.string,
  };

  static defaultProps = {
    detail: 'day',
  };

  static detail = {
    day: {
      format: 'YYYY-MM-DD',
      maxDetail: 'month',
    },
    month: {
      format: 'YYYY-MM',
      maxDetail: 'year',
    },
    year: {
      format: 'YYYY',
      maxDetail: 'decade',
    },
  };

  constructor(props) {
    super(props);

    this.state = {
      date: props.value ? moment(props.value)
        .toDate() : new Date(),
    };
    this.onChange = this.onChange.bind(this);
    this.input = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      date: nextProps.value ? moment(nextProps.value)
        .toDate() : new Date(),
    });
  }

  onChange(value) {
    this.setState({
      date: value ? moment(value)
        .toDate() : null,
      isOpen: false,
    }, () => this.props.onChange(value));
  }

  render() {
    const { className, isOpen } = this.props;
    const { date } = this.state;

    return (
      <div className={`date-picker ${className}`}>
        <div
          className="date-picker-bd"
          style={{ visibility: isOpen ? 'visible' : 'hidden' }}
          onClick={this.props.onBlur}
        >
          <div className="date-picker-wr" onClick={e => e.stopPropagation()}>
            <Calendar
              onChange={this.onChange}
              value={date}
              maxDetail={DatePicker.detail[this.props.detail].maxDetail}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default DatePicker;
