const validate = (values) => {
  const errors = {};

  if (values.areaItem === 'custom' && (!values.name || values.name === '')) {
    errors.name = 'Required';
  }

  if (values.areaItem !== 'custom' && (!values.areaItem || values.areaItem === '')) {
    errors.areaItem = 'Required';
  }

  if (values.description === 'custom' && (!values.custom_description || values.custom_description === '')) {
    errors.custom_description = 'Required';
  } else if (!values.description || values.description === '') {
    errors.description = 'Required';
  }

  if (!values.service_id || values.service_id === '') {
    errors.service_id = 'Required';
  }

  return errors;
};

export default validate;
