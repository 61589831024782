import React from 'react';
import Badge from './Badge';
import COLORS from '../helpers/colors';

const WorkOrderBadge = () => (
  <Badge
    text_color={COLORS.white}
    background_color={COLORS.lime}
    label="Work Order"
    style={{ fontSize: '14px' }}
  />
);

export default WorkOrderBadge;
