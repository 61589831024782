import React from 'react';
import PropTypes from 'prop-types';
import Badge from '../../../../components/Badge';

const SchedulerFormHeader = ({
  service_badge: serviceBadge,
  condition_badge: conditionBadge,
  short_address: shortAddress,
  service_status: serviceStatus,
}) => (serviceBadge.label ? (
  <>
    {shortAddress ? (
      <div className="row justify-content-center m-b-5">
        {shortAddress}
      </div>
    ) : null}
    <div className="row justify-content-center">
      <Badge {...serviceBadge} />
      {conditionBadge ? <Badge {...conditionBadge} className="ml-2" /> : null}
      {serviceStatus ? <Badge {...serviceStatus.private} className="ml-2" /> : null}
    </div>
  </>
) : (
  <div className="row justify-content-center m-b-5">
    Assign and Schedule Inspection
  </div>
));

SchedulerFormHeader.propTypes = {
  service_badge: PropTypes.object,
  condition_badge: PropTypes.object,
  short_address: PropTypes.string,
};

SchedulerFormHeader.defaultProps = {
  service_badge: {},
  condition_badge: null,
  shortAddress: null,
};

export default SchedulerFormHeader;
