import React from 'react';
import { connect } from 'react-redux';
import Toast from './Toast';
import logo from '../../../assets/images/logo.png';
import { eventObject } from '../../helpers/propTypes';
import COLORS from '../../helpers/colors';
import CurrentUser from './CurrentUser';

const ChatHeader = ({ toastVisible, currentUser }) => {
  return (
    <div className="navbar" style={{ width: '100%' }}>
      <div className="navbar-brand">
        <a href="/projects">
          <img height="20" src={logo} alt="Intimely" />
        </a>
      </div>
      <div className="navbar-nav mr-auto">
        <Toast
          toastVisible={toastVisible}
          key={JSON.stringify(toastVisible) || 'empty'}
        />
      </div>
      <a
        href="/projects"
        className="py-1 px-2"
        target="_blank"
        style={{
          border: `1px solid ${COLORS.blue}`,
          color: COLORS.blue,
          borderRadius: 5,
        }}
      >
        Open App
      </a>
      <ul className="navbar-nav">
        {currentUser ? (
          <CurrentUser avatarClass="avatar-xs d-inline-block" />
        ) : null}
      </ul>
    </div>
  );
};

ChatHeader.propTypes = {
  toastVisible: eventObject,
};

ChatHeader.defaultProps = {
  toastVisible: null,
};

const mapStateToProps = state => ({
  currentUser: state.Auth.currentUser,
});

export default connect(
  mapStateToProps,
)(ChatHeader);
