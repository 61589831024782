import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord } from 'redux-json-api-module/dist/selectors';
import Avatar from '../../components/Avatar';

const Messengers = ({ users }) => (
  <div style={{ overflow: 'hidden', whiteSpace: 'nowrap' }}>
    {users.map(user => (
      <span key={user.id} className="mx-1">
        <Avatar {...user.attributes} className="avatar-xs d-inline-block" />
      </span>
    ))}
  </div>
);

const mapStateToProps = (state) => {
  const userIds = state.Chat.activeUserIds;
  const users = (userIds || []).map(id => (
    getRecord(state.Api, { type: 'user_listings', id })
  )).filter(u => u);

  return {
    users,
  };
};

Messengers.propTypes = {
  users: PropTypes.array.isRequired,
};

export default connect(mapStateToProps)(Messengers);
