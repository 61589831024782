import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { idComparison } from '../../helpers/string';

const CUSTOM = 'CUSTOM';

export default class CustomInputSelectWithId extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    errors: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    includeBlank: PropTypes.string,
    idName: PropTypes.string.isRequired,
    selectName: PropTypes.string.isRequired,
  };

  static defaultProps = {
    onChange: null,
    options: [],
    includeBlank: undefined,
    value: undefined,
    errors: undefined,
    name: null,
  };

  constructor(props) {
    super(props);

    const selected = props.options.find(option => option.id === props.value);

    this.state = {
      selectedId: props.value || (props.textValue ? CUSTOM : null),
      selectedName: props.textValue,
      textValue: selected ? '' : props.textValue,
    };
  }

  handleSelect = (e) => {
    const { onChange, input, options, name, idName } = this.props;
    const { textValue } = this.state;
    const selectedId = e.target.value;
    const selected = options.find(option => idComparison(option.id, selectedId));
    const selectedName = selected ? selected.name : CUSTOM;

    this.setState({ selectedName, selectedId });
    const value = selectedId === CUSTOM ? textValue : selectedId;

    if (onChange) {
      onChange({
        [name]: selectedName,
        [idName]: selectedId,
      });
    }
    if (input) input.onChange(value);
  };

  handleCustom = (e) => {
    const { onChange, input, name, idName } = this.props;
    const { value } = e.target;

    this.setState({ textValue: value });
    if (onChange) {
      onChange({
        [name]: value,
        [idName]: null,
      });
    }
    if (input) input.onChange(value);
  };

  render() {
    const { name, options, errors, includeBlank, input, disabled, idName, selectName } = this.props;
    const { selectedId, selectedName, textValue } = this.state;

    const selectParams = input ? {
      name: `${get(input, 'name', '')}_select`,
    } : {};

    return (
      <React.Fragment>
        <input
          name={name || get(input, 'name', '')}
          type="hidden"
          value={selectedId === CUSTOM ? textValue : selectedName}
        />

        <input
          name={idName}
          type="hidden"
          value={selectedId === CUSTOM ? '' : selectedId}
        />

        <select
          {...selectParams}
          value={selectedId}
          disabled={disabled}
          onChange={this.handleSelect}
          className={`form-control ${errors ? 'is-invalid' : ''}`}
          name={selectName}
        >

          {includeBlank ? <option value="">{includeBlank}</option> : null}

          {options.map(option => (
            <option key={option.id} value={option.id}>{option.name}</option>
          ))}

          <option value={CUSTOM}>Custom</option>
        </select>

        {selectedId === CUSTOM ? (
          <input
            type="text"
            value={textValue}
            onChange={this.handleCustom}
            className={`form-control ${errors ? 'is-invalid' : ''}`}
            style={{ marginTop: '15px' }}
          />
        ) : null}

        {errors && errors.map(error => <div className="invalid-feedback">{error}</div>)}
      </React.Fragment>
    );
  }
}
