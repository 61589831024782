import React from 'react';
import { connect } from 'react-redux';
import { getRecord } from 'redux-json-api-module';
import { Card } from 'react-bootstrap';
import PartsTasksTable from './PartsTasksTable';
import { genericRecordPropType } from '../../helpers/propTypes';

const CommunityCard = ({ community }) => (
  <Card>
    <div className="card-body">
      <h4 className="card-title">{community.attributes.name}</h4>
      <div className="table-responsive">
        <PartsTasksTable communityId={community.id} />
      </div>
    </div>
  </Card>
);

const mapStateToProps = (state, ownProps) => ({
  community: getRecord(state.Api, {
    type: 'communities',
    id: ownProps.communityId,
  }),
});

CommunityCard.propTypes = {
  community: genericRecordPropType.isRequired,
};

export default connect(mapStateToProps)(CommunityCard);
