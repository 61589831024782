import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Loader from '../../components/Loader';
import CommunityCard from './CommunityCard';
import CreatePlanCard from '../ChatsContainer/CreatePlanCard';
import { fetchWorkOrders } from '../../redux/modules/workOrders';
import { nameSort } from '../../helpers/sorts';
import Filters from './Filters';

const WorkOrdersContainer = ({ fetchWorkOrders, communityIdMap, communities }) => {
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);

  // sort communities by name
  const communityIds = Object.keys(communityIdMap);
  const sortedCommunityIdMap = Object.values(communities)
    .filter(c => communityIds.includes(c.id))
    .sort(nameSort)
    .map(c => c.id);

  const onSubmit = (values) => {
    setRefreshing(true);
    fetchWorkOrders(values)
      .then(() => setRefreshing(false));
  };

  useEffect(() => {
    fetchWorkOrders()
      .then(() => setLoading(false));
  }, []);

  if (loading) return <Loader />;

  return (
    <>
      <Filters onSubmit={onSubmit} />
      {refreshing ? <Loader /> : null}

      {!refreshing ? sortedCommunityIdMap.map(cid => (
        <div key={cid}>
          <CommunityCard
            communityId={cid}
          />
        </div>
      )) : null}

      {!refreshing && Object.keys(communityIdMap).length === 0 ? (
        <CreatePlanCard />
      ) : null}
    </>
  );
};

WorkOrdersContainer.propTypes = {
  fetchWorkOrders: PropTypes.func.isRequired,
  communityIdMap: PropTypes.object.isRequired,
  communities: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  communityIdMap: state.WorkOrders.communityIdMap || {},
  communities: state.Api.communities || {},
});

const mapDispatchToProps = {
  fetchWorkOrders,
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrdersContainer);
