import React from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import moment from 'moment';
import { getRecord } from 'redux-json-api-module';
import { DateInput, UserSelect } from './inputs';
import SchedulerInput from './SchedulerInput';
import UnitSelector from '../../../components/UnitSelector';
import Select from '../../../components/Inputs/Select';

const INSPECTION_TYPE_OPTIONS = [
  ['Quick', 'quick'],
  ['Detailed', 'detailed'],
];

const ALL_OPTIONS = 'all_options';

const ProjectCreateForm = ({
  initialValues,
  service,
  stateOptions,
  communityOptions,
  api,
  errors,
  unitOptions,
  saving,
  onSubmit,
  team,
}) => (
  <Form
    keepDirtyOnReinitialize
    initialValues={initialValues}
    onSubmit={onSubmit}
    render={({ values, form: { change }, handleSubmit }) => (
      <form onSubmit={handleSubmit} id="new_project">
        <Field
          name="unit"
          component={UnitSelector}
          options={unitOptions}
          stateOptions={stateOptions}
          communityOptions={communityOptions}
          label="Select Unit"
          errors={errors}
        />

        <Field
          className="col-sm-9"
          name="keys_received_on"
          component={DateInput}
          label="Keys Received Date"
          hint="Please select the date you received, or will receive, access to this unit."
          errors={errors ? errors.keys_received_on : null}
          maxDate={values.startedAt ? moment(values.startedAt)
            .endOf('day')
            .toDate() : null}
        />
        <Field
          name="due_on"
          component={DateInput}
          label="Final Due Date"
          hint="Please select the due date for all necessary services to be completed in this project. Typically, this is the move-in date or 21-day deposit return date."
          minDate={values.keys_received_on}
          optional
          errors={errors ? errors.due_on : null}
          warning={values.due_on
            ? `The 21-day deposit refund date is ${moment(values.keys_received_on)
              .add(21, 'days')
              .format('MM/DD')}.`
            : null}
        />
        <Field
          name="user_id"
          component={UserSelect}
          label="Inspector"
          hint="Choose your inspector for this project"
          service={service}
          user={getRecord(api, {
            type: 'users',
            id: values.user_id,
          })}
          errors={errors ? errors.user_id : null}
        />

        {team.attributes.inspection_options === ALL_OPTIONS ? (
          <div className="form-group row">
            <label className="col-sm-3 col-form-label text-md-right">
              Select Inspection Type
            </label>
            <div className="col-sm-9">
              <Field
                name="inspection_type"
                style={{ width: '297.5px' }}
                component={Select}
                defaultValue={INSPECTION_TYPE_OPTIONS[0][1]}
                options={INSPECTION_TYPE_OPTIONS}
                label="Select Inspection Type"
                errors={errors ? errors.inspection_type : null}
              />
            </div>
          </div>
        ) : null}

        <Field
          name="scheduler"
          label="Inspection Date"
          component={SchedulerInput}
          projectService={{
            id: null,
            type: 'project_services',
            attributes: {
              user_id: values.user_id,
              started_at: values.startedAt,
              ended_at: values.endedAt,
              all_day: values.allDay === true || values.allDay === false ? values.allDay : true,
              assignable: true,
            },
            relationships: {
              project: {
                data: {
                  id: null,
                  type: 'projects',
                },
              },
              service: {
                data: {
                  id: service.id,
                  type: 'services',
                },
              },
              user: {
                data: values.user_id ? {
                  id: values.user_id,
                  type: 'users',
                } : null,
              },
            },
          }}
          service={service}
          user={getRecord(api, {
            type: 'users',
            id: values.user_id,
          })}
          change={change}
          keysReceivedOn={moment()
            .isBefore(values.keys_received_on) ? values.keys_received_on : new Date()}
        />

        <div className="form-group row">
          <div className="offset-sm-3 col-sm-9">
            <input
              type="submit"
              id="projectCreateFormSubmitBtn"
              value={saving ? 'Saving...' : 'Create Project'}
              className="btn btn-success"
              disabled={!values.startedAt || saving}
            />
            <a className="btn btn-inverse" href="/projects">Cancel</a>
          </div>
        </div>
      </form>
    )}
  />
);

const mapStateToProps = state => ({
  // need to bring back the api because the value is determined by the react-form state
  api: state.Api,
});

export default connect(mapStateToProps)(ProjectCreateForm);
