import { firstBy } from 'thenby';
import moment from 'moment';

const dateSort = (a, b) => {
  if (!a && !b) {
    return moment(a)
      .diff(moment(b));
  }

  return (a === null) - (b === null) || moment(a)
    .diff(moment(b));
};

export const workOrderTaskSort = (
  firstBy((a, b) => dateSort(a.attributes.started_at, b.attributes.started_at))
    .thenBy((a, b) => dateSort(a.attributes.ended_at, b.attributes.ended_at))
    .thenBy(a => a.attributes.sort)
);
