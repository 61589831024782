import React from 'react';
import PropTypes from 'prop-types';
import { CELL_CLASSES, WIDTHS } from '../../helpers';
import './styles.scss';
import ValidationIcon from '../ValidationIcon';
import { humanize } from '../../../../helpers/string';

const ImportTable = ({ rows, textAttributes, getAttributeValue, errors }) => (
  <div className="scrollable-table-wrapper">
    <table className="table scrollable-table work-order-import-table">
      <thead>
        <tr>
          <th key="street_address" style={{ width: '250px' }}>Street Address</th>
          {textAttributes.map(attr => (
            <th key={attr} style={WIDTHS[attr] ? { width: WIDTHS[attr] } : {}}>{attr}</th>
          ))}
          <th>Error Type</th>
          <th>Error details</th>
        </tr>
      </thead>

      <tbody>
        {rows.map((row) => {
          const error = errors.find(e => e.line_number === row.line_number);

          return (
            <tr className="v-middle">
              <td>
                <div className="d-flex">
                  <ValidationIcon valid={row.valid} />
                  <div className="my-auto">
                    {getAttributeValue(row, 'Street Address')}
                  </div>
                </div>
              </td>
              {textAttributes.map(attr => (
                <td key={attr} className={CELL_CLASSES[attr]}>
                  {getAttributeValue(row, attr)}
                </td>
              ))}
              <td>
                {error?.message}
              </td>
              <td>
                {Object.keys(error?.errors || {})
                  .map(key => (
                    <div>
                      <span className="font-weight-bold">
                        {humanize(key)}
                        :&nbsp;
                      </span>
                      {(error.errors || {})[key]}
                    </div>
                  ))}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  </div>
);


ImportTable.propTypes = {
  rows: PropTypes.array.isRequired,
  errors: PropTypes.array.isRequired,
  textAttributes: PropTypes.arrayOf(PropTypes.string).isRequired,
  getAttributeValue: PropTypes.func.isRequired,
};

export default ImportTable;
