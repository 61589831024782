import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord } from 'redux-json-api-module';
import get from 'lodash.get';
import withTagging from './withTagging';
import { textSectionObject } from '../../helpers/propTypes';

import { getHtmlBody } from './helpers';
import { NextArrow, PrevArrow, SETTINGS } from '../ServiceDetails/helpers';
import ImageGallery from './ImageGallery';

const SimpleBody = ({ textSections, isAuthor }) => (
  <div className={`box ${isAuthor ? 'bg-light-inverse' : 'bg-light-info'}`}>
    {getHtmlBody(textSections)}
  </div>
);

SimpleBody.propTypes = {
  isAuthor: PropTypes.bool.isRequired,
  textSections: PropTypes.arrayOf(textSectionObject).isRequired,
};

const getControlArrows = (slideCount) => {
  // really needs to be divided by slidesToScroll
  // because the library controls the placement of the buttons, it's probably easier
  // to create a custom control component rather than this much absolute positioning
  const size = slideCount / 2;
  return {
    nextArrow: <NextArrow btnClassName="bottom-control" relativeControls sliderCount={size} />,
    prevArrow: <PrevArrow btnClassName="bottom-control" relativeControls sliderCount={size} />,
  };
};

const GalleryMessage = ({
  isAuthor,
  galleryImages,
  textSections,
}) => (
  <>
    <div
      className={`box ${isAuthor ? 'bg-light-inverse' : 'bg-light-info'} px-0`}
      style={{ maxWidth: galleryImages.length > 1 ? '340px' : '170px' }}
    >
      <div>
        {galleryImages.length > 0 ? (
          <ImageGallery
            images={galleryImages}
            settings={{ ...SETTINGS, ...getControlArrows(galleryImages.length) }}
            pagination={false}
            sourceVariant="thumb_sq"
            placeholderVariant="thumb_placeholder_sq"
          />
        ) : null}
      </div>
      {getHtmlBody(textSections)}
    </div>
  </>
);

GalleryMessage.propTypes = {
  textSections: PropTypes.arrayOf(textSectionObject).isRequired,
  isAuthor: PropTypes.bool.isRequired,
  galleryImages: PropTypes.array.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const message = getRecord(state.Api, {
    type: 'messages',
    id: ownProps.messageId,
  });
  const messengerId = get(message, 'attributes.messenger_id', null);
  const messageThreadId = get(message, 'attributes.message_thread_id', null);

  return {
    messageThreadId,
    messengerId,
  };
};

export default withTagging(connect(mapStateToProps)(GalleryMessage));
