import React from 'react';
import moment from 'moment';
import get from 'lodash.get';
import { idComparison } from '../../helpers/string';

export function editable(isAuthor, createdAt, messageType) {
  return isAuthor
    && moment(createdAt) > moment()
      .subtract(30, 'minutes')
    && messageType === 'simple';
}

export function parseText({ text, accumulation, currentSection, currentIndex }) {
  const section = currentSection + text[0];
  return {
    text: text.substring(1),
    accumulation,
    currentSection: section,
    currentIndex: currentIndex + 1,
  };
}

export function parseTag({
  text,
  accumulation,
  currentSection,
  taggableUsers,
  addFunction,
  currentIndex,
}) {
  const match = taggableUsers.find(user => text.indexOf(`<@${user.id}>`) === 0);
  if (!match) {
    return parseText({
      text,
      accumulation,
      currentSection,
      currentIndex,
    });
  }
  if (currentSection) {
    addFunction(currentSection, accumulation, currentIndex, null);
  }
  addFunction(`@${match.name}`, accumulation, currentIndex, match);
  return {
    text: text.replace(`<@${match.id}>`, ''),
    accumulation,
    currentSection: '',
    currentIndex: currentIndex + `<@${match.id}>`.length,
  };
}

export const parseEmoji = (text, cursorIndex) => {
  const lastTwo = text.substring(cursorIndex - 2, cursorIndex);
  const preceding = text.substring(0, cursorIndex - 2);
  const following = text.substring(cursorIndex);
  let emoji = null;

  switch (lastTwo) {
    case ':)':
      emoji = '🙂';
      break;
    case ':(':
      emoji = '🙁';
      break;
    case ':D':
      emoji = '😁';
      break;
    case ':O':
      emoji = '😯';
      break;
    case ';)':
      emoji = '😉';
      break;
    case '<3':
      emoji = '❤️';
      break;
    case '=)':
      emoji = '🙂';
      break;
    default:
      break;
  }

  return emoji ? preceding + emoji + following : text;
};

export function insert(str, index, value) {
  return str.substr(0, index) + value + str.substr(index);
}

export const getHtmlBody = (textSections, style) => (
  <React.Fragment>
    {textSections.map((section, index) => (section.text
      ? (
        <span key={index} style={{ ...(style || section.style), fontSize: onlyEmojis(section.text) ? '32px' : '14px' }}>
          {section.text}
        </span>
      )
      : <br key={index} />))}
  </React.Fragment>
);

export const getPairs = arr => (
  arr.reduce((result, value, index, array) => {
    if (index % 2 === 0) result.push(array.slice(index, index + 2));
    return result;
  }, [])
);

const emojiRegex = /^(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32-\ude3a]|[\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])+$/;

export const onlyEmojis = body => emojiRegex.test(body);

export function isSameDay(message, previousMessage) {
  const messageTime = moment(get(message, 'attributes.created_at', null));
  const previousMessageTime = moment(get(previousMessage, 'attributes.created_at', null));

  if (!messageTime.isValid() || !previousMessageTime.isValid()) return false;

  return messageTime.isSame(previousMessageTime, 'day');
}

export function isSameUser(messengerUserId, previousMessage) {
  const previousMessengerUserId = get(previousMessage, 'attributes.messenger.user_id', '');
  const isSystemMessage = get(previousMessage, 'attributes.message_type', '') === 'system';
  if (isSystemMessage) return false;

  return idComparison(previousMessengerUserId, messengerUserId);
}
