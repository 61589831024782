import toastr from 'toastr';
import moment from 'moment';
import {
  FETCH_RECORDS_SUCCESS,
  fetchRecord,
  fetchRecords,
  SAVE_RECORD,
  saveRecord,
  deleteRecord, getRecord,
  getRelationship,
} from 'redux-json-api-module';
import get from 'lodash.get';
import { setActiveMessageThread, fetchMessengers } from './chat';
import { initializeActiveTask, setActiveTask } from './navigation';
import { setProjectServiceIds } from './projectManagement';

const uuidv4 = require('uuid/v4');

export const SET_WORK_ORDER = 'intimely/workOrderManagement/SET_WORK_ORDER';
export const HYDRATE_WORK_ORDER_MANAGEMENT_STORE = 'intimely/workOrderManagement/HYDRATE_WORK_ORDER_MANAGEMENT_STORE';
export const ACTIVATE_WORK_ORDER = 'intimely/workOrderManagement/ACTIVATE_WORK_ORDER';
export const APPROVE_COMPLETION = 'intimely/workOrderManagement/APPROVE_COMPLETION';
export const FETCH_USER_OPTIONS = 'intimely/workOrderManagement/FETCH_USER_OPTIONS';
export const FETCH_AREA_OPTIONS = 'intimely/workOrderManagement/FETCH_AREA_OPTIONS';
export const SEND_INSPECTION_REPORT = 'intimely/workOrderManagement/SEND_INSPECTION_REPORT';
export const SEND_COMPLETION_REPORT = 'intimely/workOrderManagement/SEND_COMPLETION_REPORT';
export const SET_VALIDATION_WARNING = 'intimely/workOrderManagement/SET_VALIDATION_WARNING';
export const WORK_ORDER_TASKS_LOADED = 'intimely/workOrderManagement/WORK_ORDER_TASKS_LOADED';
export const SET_INSPECTION_TAB_TOGGLE = 'intimely/workOrderManagement/SET_INSPECTION_TAB_TOGGLE';
export const SET_TAB = 'intimely/workOrderManagement/SET_TAB';
export const ADD_SERVICE = 'intimely/workOrderManagement/ADD_SERVICE';
export const REMOVE_PENDING_SERVICE = 'intimely/workOrderManagement/REMOVE_PENDING_SERVICE';

const KEYS_RECEIVED_ON_WARNING = 'Please ensure the Inspection Schedule Date is later than (or equal to) the Work Order Start Date. If you are updating the Work Order Start Date, please change the Inspection Schedule Date first.';
const DUE_DATE_WARNING = 'Please ensure the WorkOrder’s Due Date is later than (or equal to) the Work Order Start Date.';

const INITIAL_STATE = {
  workOrderId: null,
  transitionedToActive: false,
  areaItems: {},
  descriptionOptions: [],
  serviceOptions: [],
  serviceObjectOptions: {},
  conditionOptions: [],
  validationWarning: null,
  workOrderTasksLoaded: false,
  inspectionTabToggle: 'feature-card',
  tab: null,
  workOrderTaskIds: [],
  pendingWorkOrderTaskIds: [],
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case HYDRATE_WORK_ORDER_MANAGEMENT_STORE:
      return { ...INITIAL_STATE, ...action.state };

    case SET_TAB:
      return {
        ...state,
        tab: action.tab,
      };

    case SET_WORK_ORDER:
      return {
        ...INITIAL_STATE,
        areaItems: state.areaItems,
        descriptionOptions: state.descriptionOptions,
        serviceOptions: state.serviceOptions,
        conditionOptions: state.conditionOptions,
        workOrderId: action.workOrderId,
      };

    case SET_INSPECTION_TAB_TOGGLE:
      return {
        ...state,
        inspectionTabToggle: action.inspectionTabToggle,
      };

    case ACTIVATE_WORK_ORDER:
      return {
        ...state,
        transitionedToActive: true,
      };

    case SET_VALIDATION_WARNING:
      return {
        ...state,
        validationWarning: action.warning,
      };

    case WORK_ORDER_TASKS_LOADED:
      return {
        ...state,
        workOrderTasksLoaded: true,
        workOrderTaskIds: action.workOrderTaskIds,
        pendingWorkOrderTaskIds: (state.pendingWorkOrderTaskIds || []).filter(id => action.pendingWorkOrderTaskId !== id),
      };

    case ADD_SERVICE:
      return {
        ...state,
        pendingWorkOrderTaskIds: [...(state.pendingWorkOrderTaskIds || []), action.pendingWorkOrderTaskId],
      };

    case REMOVE_PENDING_SERVICE:
      return {
        ...state,
        pendingWorkOrderTaskIds: (state.pendingWorkOrderTaskIds || []).filter(id => action.pendingWorkOrderTaskId !== id),
      };
    default:
      return state;
  }
}

export function setValidationWarning(warning) {
  return {
    type: SET_VALIDATION_WARNING,
    warning,
  };
}

export const hydrateWorkOrderManagementStore = state => ({
  type: HYDRATE_WORK_ORDER_MANAGEMENT_STORE,
  state,
});

export const setInspectionTabToggle = inspectionTabToggle => ({
  type: SET_INSPECTION_TAB_TOGGLE,
  inspectionTabToggle,
});

export const fetchRecentColumnMappingTemplate = () => dispatch => (
  dispatch(fetchRecords('column_mapping_templates/recent'))
);

export const getWorkOrder = state => (
  getRecord(state.Api, {
    type: 'work_orders',
    id: state.WorkOrderManagement.workOrderId,
  })
);

export function fetchWorkOrder(workOrderId) {
  return dispatch => (
    dispatch(fetchRecord(
      'work_orders', workOrderId, {
        include: [
          'unit', 'community', 'manager',
        ].join(','),
      },
    ))
  );
}

export const setWorkOrderTaskIds = (taskIds, pendingTaskId) => ({
  type: WORK_ORDER_TASKS_LOADED,
  workOrderTaskIds: taskIds,
  pendingWorkOrderTaskId: pendingTaskId,
});

export function fetchWorkOrderTasks(workOrderId, pendingWorkOrderTaskId) {
  return (dispatch, getState) => (
    dispatch(fetchRecords(`work_orders/${workOrderId}/work_order_tasks`, {
      include: ['service', 'user', 'checklist_items', 'work_order'].join(','),
      include_upload_flags: true,
    }))
      .then((resp) => {
        if (resp.error) return resp;

        const workOrderTaskIds = resp.payload.data.data.map(t => t.id);

        dispatch(setWorkOrderTaskIds(
          workOrderTaskIds,
          pendingWorkOrderTaskId,
        ));

        return resp;
      })
  );
}

export function fetchTask(taskType, taskId) {
  return dispatch => (
    dispatch(fetchRecord(
      taskType, taskId, {
        include: [
          'work_order_features', 'service', 'checklist_items', 'task_images',
          'user', 'work_order_features.feature_images',
        ].join(','),
      },
    ))
  );
}

export function fetchTeamMembers(teamId) {
  return dispatch => (
    dispatch(fetchRecord(
      'teams', teamId, {
        include: [
          'memberships', 'memberships.user',
        ].join(','),
      },
    ))
  );
}

export function setWorkOrder(workOrderId) {
  return (dispatch) => {
    dispatch({
      type: SET_WORK_ORDER,
      workOrderId,
    });
    dispatch(fetchWorkOrder(workOrderId));
  };
}

export function submitManagementNotes(task, attributes) {
  const record = {
    type: task.type,
    id: task.id,
    attributes: {
      management_notes: attributes.management_notes || null,
    },
  };

  return dispatch => (
    dispatch(saveRecord(record, { params: { window_id: window.WINDOW_ID } }))
      .then((resp) => {
        if (resp.error) {
          toastr.error('The Work Order Task could not be updated.');
        } else {
          toastr.success('Management Notes Updated.');
        }

        return resp;
      })
  );
}

function dueOnValidationWarning(dueOn, keysReceivedOn) {
  return (moment(keysReceivedOn) > moment(dueOn))
    ? DUE_DATE_WARNING : null;
}

export function submitDueDate(planType, planId, dueOn, keysReceivedOn) {
  const record = {
    type: planType,
    id: planId,
    attributes: {
      due_on: dueOn,
    },
  };

  return (dispatch) => {
    const warning = dueOnValidationWarning(dueOn, keysReceivedOn);
    if (warning) {
      return Promise.resolve(dispatch(setValidationWarning(warning)));
    }
    return dispatch(saveRecord(record, { params: { window_id: window.WINDOW_ID } }))
      .then((resp) => {
        if (resp.error) {
          toastr.error('Could not be updated.');
        } else {
          toastr.success('Due Date Updated.');
        }
      });
  };
}

function keysReceivedOnValidationWarning() {
  // does not apply for work orders
  return null;
}

export function submitKeysReceivedOn(workOrder, keysReceivedOn) {
  const record = Object.assign({}, workOrder);
  record.attributes = {
    keys_received_on: keysReceivedOn,
  };

  return (dispatch, getState) => {
    const warning = keysReceivedOnValidationWarning(getState(), workOrder, keysReceivedOn);
    if (warning) {
      return Promise.resolve(dispatch(setValidationWarning(warning)));
    }
    return dispatch(saveRecord(record, { params: { window_id: window.WINDOW_ID } }))
      .then((resp) => {
        if (resp.error) {
          toastr.error('The Work Order could not be updated.');
        } else {
          toastr.success('Keys Received On Updated.');
        }
      });
  };
}

function validInspection(state, inspection) {
  const fullInspection = getRecord(state.Api, {
    type: 'work_order_tasks',
    id: inspection.id,
  });
  if (!fullInspection) return true;
  const workOrder = getRelationship(state.Api, fullInspection.relationships.workOrder);
  if (!workOrder || !inspection.attributes.started_at) return true;
  return moment(workOrder.attributes.keys_received_on) <= moment(inspection.attributes.started_at);
}

export function saveInspection(inspection) {
  return (dispatch, getState) => {
    if (validInspection(getState(), inspection)) {
      return dispatch(saveRecord(inspection, { params: { window_id: window.WINDOW_ID } }));
    }
    return Promise.resolve(dispatch(setValidationWarning(KEYS_RECEIVED_ON_WARNING)));
  };
}

export function assignUserGeneric(userId, taskType, taskId) {
  return (dispatch) => {
    const record = {
      id: taskId,
      type: taskType,
      attributes: {
        user_id: userId,
      },
    };

    dispatch(
      saveRecord(record, { params: { window_id: window.WINDOW_ID } }),
    )
      .then(() => {
        toastr.success('Service successfully assigned');
      })
      .catch(() => {
        toastr.error('Service could not be assigned');
      });
  };
}

export function assignUser(userId, workOrderService) {
  return (dispatch) => {
    const record = Object.assign({}, workOrderService);
    record.attributes.user_id = userId;

    dispatch(
      saveRecord(record, { params: { window_id: window.WINDOW_ID } }),
    )
      .then(() => {
        toastr.success('Work Order Task successfully assigned');
      })
      .catch(() => {
        toastr.error('Work Order Task could not be assigned');
      });
  };
}

export function saveImport(id) {
  return (dispatch) => {
    dispatch({
      type: SAVE_RECORD,
      payload: {
        request: {
          method: 'POST',
          url: `/work_order_imports/${id}/save`,
        },
      },
    })
      .then((resp) => {
        const firstId = get(resp, 'payload.data.data[0].id', null);
        if (firstId) window.location.href = `/work_orders/${firstId}/import?success_prompt=true`;
      });
  };
}

export function activateImportedWorkOrder(workOrderId) {
  return (dispatch) => {
    dispatch({
      type: SAVE_RECORD,
      payload: {
        request: {
          method: 'patch',
          url: `/work_orders/${workOrderId}/activate_import`,
        },
      },
    })
      .then((resp) => {
        const nextWorkOrderId = get(resp, 'payload.data.next_work_order_id', null);
        if (nextWorkOrderId) {
          window.location = `/work_orders/${nextWorkOrderId}/import`;
        } else {
          window.location = '/work_orders';
        }
      });
  };
}

function reloadWorkOrder(workOrderId) {
  return dispatch => dispatch(fetchWorkOrder(workOrderId)).then(() => (
    dispatch(fetchWorkOrderTasks(workOrderId))
  )).then((resp) => {
    return dispatch(initializeActiveTask('work_orders', workOrderId));
  }).then(() => (
    dispatch(fetchMessengers('work_orders', workOrderId))
  ));
}

export function activateWorkOrder(workOrderId) {
  return (dispatch) => {
    dispatch({
      type: SAVE_RECORD,
      payload: {
        request: {
          method: 'patch',
          url: `/work_orders/${workOrderId}/activate?include=work_order_tasks`,
        },
      },
    })
      .then((resp) => {
        if (resp.error) {
          toastr.error('There was a problem activating the WorkOrder.');
          return undefined;
        }

        dispatch({ type: ACTIVATE_WORK_ORDER });
        dispatch(initializeActiveTask('work_orders', workOrderId));

        toastr.success('Work Order activated!');
      });
  };
}

export function approveCompletionWorkOrder(workOrderId) {
  return (dispatch) => {
    dispatch({
      type: SAVE_RECORD,
      payload: {
        request: {
          method: 'patch',
          url: `/work_orders/${workOrderId}/approve_completion`,
        },
      },
    })
      .then((resp) => {
        if (resp.error) {
          toastr.error('There was a problem approving completion of the Work Order.');
        } else {
          toastr.success('Work Order Completion Approved!');
          dispatch(fetchWorkOrder(workOrderId));
          dispatch({ type: APPROVE_COMPLETION });
          window.scrollTo(0, 0);
        }
      });
  };
}

export function sendWorkOrderCompletionReports(workOrderId, data) {
  return {
    type: SEND_COMPLETION_REPORT,
    payload: {
      request: {
        method: 'post',
        url: `/work_orders/${workOrderId}/completion_reports`,
        data,
      },
    },
  };
}

export function fetchUserOptions(serviceId) {
  return {
    type: FETCH_USER_OPTIONS,
    payload: {
      request: {
        method: 'get',
        url: `/services/${serviceId}/user_options`,
      },
    },
  };
}

export function includeData(data) {
  return {
    type: FETCH_RECORDS_SUCCESS,
    payload: { data },
  };
}

export function removePendingService(pendingWorkOrderTaskId) {
  return {
    type: REMOVE_PENDING_SERVICE,
    pendingWorkOrderTaskId,
  };
}

export function createService(workOrderId, pendingWorkOrderTaskId, serviceId) {
  const record = {
    type: 'work_order_tasks',
    attributes: {
      work_order_id: workOrderId,
      service_id: serviceId,
    },
  };

  return dispatch => (
    dispatch(saveRecord(record, { params: { window_id: window.WINDOW_ID } }))
  ).then(() => (
    dispatch(fetchWorkOrderTasks(workOrderId, pendingWorkOrderTaskId))
  ));
}

export function addService() {
  return {
    type: ADD_SERVICE,
    pendingWorkOrderTaskId: uuidv4(),
  };
}

export function deleteFeature(feature) {
  const workOrderId = get(feature, 'relationships.work_order.data.id', null);
  return async (dispatch) => {
    const resp = await dispatch(deleteRecord(feature));

    if (resp.error) {
      toastr.error('The Service could not be deleted.');
    } else {
      toastr.success('The Service was successfully deleted');
    }

    if (resp.error) return resp;

    return dispatch(fetchWorkOrder(feature.relationships.work_order.data.id)).then(() => (
      dispatch(fetchWorkOrderTasks(workOrderId))
    )).then((resp) => {
      const taskId = get(resp, 'payload.data.data[0].id', null);
      return (taskId
        ? dispatch(setActiveTask({
          activeTaskType: 'work_order_tasks',
          activeTaskId: taskId,
          activePlanType: 'work_orders',
          activePlanId: workOrderId,
        }))
        : Promise.resolve({}));
    });
  };
}

export function splitFeature(values, feature) {
  let taskId;
  const workOrderId = get(feature, 'relationships.work_order.data.id', null);
  return dispatch => dispatch(saveRecord({
    type: 'work_order_features',
    attributes: {
      description: values.description,
      condition: values.condition,
      service_id: values.service_id,
      name: values.name,
      area: values.area,
      item_id: values.itemId,
      work_order_id: workOrderId,
      selected_image_ids: values.selectedImages,
      due_date: feature.attributes.due_date,
      original_feature_id: feature.id,
    },
  }, {
    params: {
      include: [
        'room', 'work_order_task', 'work_order', 'service',
      ].join(','),
    },
  }))
    .then((resp) => {
      if (resp.error) {
        return resp;
      }

      taskId = get(resp, 'payload.data.data.relationships.work_order_task.data.id', null);

      const promises = [
        dispatch(
          fetchRecord(
            'work_order_features',
            feature.id,
            {
              include: [
                'room', 'work_order_task', 'work_order', 'service',
              ].join(','),
            },
          ),
        ),
        dispatch(fetchWorkOrderTasks(workOrderId)),
      ];

      return Promise.all(promises);
      // can ignore these fetch errors, if feature or ps has been deleted
    })
    .then(() => (taskId
      ? dispatch(setActiveTask({
        activeTaskType: 'work_order_tasks',
        activeTaskId: taskId,
        activePlanType: 'work_orders',
        activePlanId: workOrderId,
      }))
      : Promise.resolve({})));
}

export function updateFeatures(features) {
  return async (dispatch) => {
    if (features.length === 0) {
      return undefined;
    }

    const response = await dispatch({
      type: 'intimely/api/UPDATE_WORK_ORDER_FEATURES',
      payload: {
        request: {
          method: 'PATCH',
          url: '/work_order_features/bulk_update',
          data: {
            data: features.map(({ id, attributes }) => ({
              id,
              attributes,
            })),
          },
        },
      },
    });
    if (response.error) {
      return response;
    }

    const [feature] = features;
    const workOrderId = get(feature, 'relationships.work_order.data.id', null);
    const fetchFeatureOptions = { include: 'room,work_order_task,work_order,service' };
    await Promise.all([
      dispatch(fetchWorkOrderTasks(workOrderId)),
      ...features.map(
        ({ id, type }) => dispatch(fetchRecord(type, id, fetchFeatureOptions)),
      ),
    ]);

    const taskId = get(response, 'payload.data.data[0].relationships.work_order_task.data.id', null);
    if (taskId) {
      return dispatch(setActiveTask({
        activeTaskType: 'work_order_tasks',
        activeTaskId: taskId,
        activePlanType: 'work_orders',
        activePlanId: workOrderId,
      }));
    }

    return undefined;
  };
}

export function fetchAreaOptions(unitId) {
  return {
    type: FETCH_AREA_OPTIONS,
    payload: {
      request: {
        method: 'get',
        url: `/units/${unitId}/area_options`,
      },
    },
  };
}

export function saveWorkOrderServiceAttachment(attachmentId, workOrderTaskId) {
  const record = {
    type: 'attachments',
    id: attachmentId,
    attributes: {
      task_type: 'WorkOrderTask',
      task_id: workOrderTaskId,
    },
  };

  return dispatch => dispatch(saveRecord(record, { params: { window_id: window.WINDOW_ID } }));
}

export function setTab(tab, selectedData) {
  return dispatch => dispatch(() => {
    if (tab === 'chat') dispatch(setActiveMessageThread(selectedData));

    return dispatch({
      type: SET_TAB,
      tab,
    });
  });
}

export const deleteWorkOrderTask = workOrderTaskId => (
  (dispatch, getState) => {
    const oldIds = getState().WorkOrderManagement.workOrderTaskIds;
    const newIds = oldIds.filter(id => id !== workOrderTaskId);

    dispatch(setWorkOrderTaskIds(newIds));
    dispatch(deleteRecord({ type: 'work_order_tasks', id: workOrderTaskId })).then(() => (
      dispatch(fetchWorkOrderTasks(getState().WorkOrderManagement.workOrderId))
    ));
  }
);
