import React from 'react';
import toastr from 'toastr';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import ImagePicker from 'react-image-picker';
import Alert from '../../../components/Alert';
import ServiceAttributesForm from './ServiceAttributesForm';

import 'react-image-picker/dist/index.css';
import { idType } from '../../../helpers/propTypes';

function toGalleryImage(image, selectedImages) {
  return {
    id: image.id,
    src: image.file.thumb_sq.url,
    isSelected: selectedImages[image.id],
    value: image.id,
  };
}

class ChangeServiceForm extends React.Component {
  static propTypes = {
    feature: PropTypes.object,
    areaItems: PropTypes.object,
    serviceOptions: PropTypes.array.isRequired,
    conditionOptions: PropTypes.array.isRequired,
    descriptionOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
    featureImages: PropTypes.array.isRequired,
    errors: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    splitFeature: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    area: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    condition: PropTypes.string,
    service_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    itemId: idType,
  };

  static defaultProps = {
    feature: null,
    area: null,
    name: null,
    description: null,
    condition: null,
    service_id: null,
    areaItems: {},
    itemId: null,
  };

  state = {
    loading: false,
    selectedImages: [],
  };

  handleSubmit = () => {
    const {
      splitFeature,
      feature,
      onClose,
      name,
      condition,
      description,
      area,
      service_id,
      itemId,
    } = this.props;

    const { selectedImages } = this.state;

    this.setState({ loading: true });
    splitFeature({
      name,
      condition,
      description,
      area,
      service_id,
      itemId,
      selectedImages,
    }, feature)
      .then((resp) => {
        if (resp.error) {
          toastr.error('There was a problem changing the service');
        } else {
          toastr.success('Service saved!');
          onClose();
        }
        this.setState({ loading: false });
      });
  };

  onSelectImage = (images) => {
    this.setState({ selectedImages: images.map(i => i.value) });
  };

  isDisabled = () => {
    const { feature, service_id, featureImages } = this.props;
    const { selectedImages } = this.state;
    return feature.attributes.service_id === service_id
      || (selectedImages.length === 0 && featureImages.length !== 0);
  };

  render() {
    const {
      descriptionOptions,
      serviceOptions,
      conditionOptions,
      errors,
      featureImages,
      onClose,
      feature,
      area,
      name,
      description,
      service_id,
      condition,
      areaOptions,
      areaItems,
      onChange,
      itemId,
    } = this.props;

    const { selectedImages, loading } = this.state;

    return (
      <Form
        id="change-service-form"
        onSubmit={this.handleSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} id="change-service-form">
            <div className="modal-header">
              <div className="m-auto text-center">
                <h5 className="modal-title font-weight-bold">
                  Update Services
                </h5>
              </div>
            </div>
            {feature ? (
              <div className="modal-body feature-form">
                <Alert
                  type="info"
                  iconClassName="fa fa-exclamation-circle"
                  title="Move Selected Image(s) To A New Service"
                >
                  You may select 1 or more images below, and move them into a new service. For
                  example, if you have 5 inspection images tagged for Carpet Cleaning, but 2 images
                  should be tagged for New Carpet, you may select those 2 images and then update the
                  Service field to New Carpet. When you click Save, Carpet Cleaning will have 3
                  images, and New Carpet will have 2 images. This is an easy way to ensure the
                  correct service provider has the images they need to get the job done.
                </Alert>
                <div className="container fluid" />
                <div className="row">
                  <div
                    className="col"
                    style={{
                      borderRight: '1px solid #dfe6e9',
                      paddingRight: '20px',
                    }}
                  >
                    <h5 className="font-weight-bold mb-20">
                      Please select 1+ images to move to a new Service
                    </h5>

                    {featureImages.length > 0 ? (
                      <div
                        className={`form-group required ${errors.selectedImages && 'form-group-invalid'}`}
                      >

                        <div>
                          <ImagePicker
                            images={featureImages.map(fi => toGalleryImage(fi, selectedImages))}
                            onPick={this.onSelectImage}
                            multiple
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <ServiceAttributesForm
                    errors={errors}
                    onChange={onChange}
                    onClose={onClose}

                    areaOptions={areaOptions}
                    areaItems={areaItems}
                    descriptionOptions={descriptionOptions}
                    conditionOptions={conditionOptions}
                    serviceOptions={serviceOptions}

                    name={name}
                    area={area}
                    description={description}
                    condition={condition}
                    service_id={service_id}
                    itemId={itemId}

                    loading={loading}
                    selectService
                    disabled={this.isDisabled()}
                  />
                </div>
              </div>
            ) : null}
          </form>
        )}
      />
    );
  }
}

export default ChangeServiceForm;
