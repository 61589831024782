import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRecord, getRelationship } from 'redux-json-api-module';
import { dateFormat } from '../../components/Summary/helpers';
import Badge from '../../components/Badge';
import ClampedText from './ClampedText';
import COLORS from '../../helpers/colors';
import { disableRecurringWorkOrder } from '../../redux/modules/workOrdersRecurring';

const WorkOrderRow = ({ plan, unit, isLast, disableRecurringWorkOrder }) => {
  const handleClick = () => {
    if (!window.confirm('Are you sure?')) return false;

    return disableRecurringWorkOrder(plan.id);
  };

  return (
    <tr className="v-middle work-order-row">
      <td>
        <a href={`/work_orders/${plan.id}`}>
          <div>
            {unit.attributes.street_address}
            <br />
            {unit.attributes.unit_number}
          </div>
        </a>
      </td>
      <td>{plan.attributes.work_order_number}</td>
      <td>{dateFormat(plan.attributes.start_recurring_on)}</td>
      <td>{dateFormat(plan.attributes.stop_recurring_on)}</td>
      <td>{plan.attributes.recurring_frequency}</td>
      <td>
        <ClampedText
          text={plan.attributes.description || ''}
          placement={isLast ? 'top' : 'bottom'}
        />
      </td>
      <td>
        <Badge {...plan.attributes.priority_badge} />
      </td>
      <td style={plan.attributes.permission_to_enter ? {} : {
        color: COLORS.redDark,
        fontWeight: 'bold',
      }}
      >
        {plan.attributes.permission_to_enter ? 'Yes' : 'No'}
      </td>
      <td>
        <button className="btn btn-link btn-lg" onClick={handleClick}>
          <i className="fa fa-trash-o" />
        </button>
      </td>
    </tr>
  );
};

WorkOrderRow.propTypes = {
  plan: PropTypes.object.isRequired,
  unit: PropTypes.object.isRequired,
  isLast: PropTypes.bool.isRequired,
  disableRecurringWorkOrder: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const plan = getRecord(state.Api, {
    type: 'work_order_listings',
    id: ownProps.planId,
  });
  const unit = getRelationship(state.Api, plan.relationships.unit);

  return {
    plan,
    unit,
  };
};

const mapDispatchToProps = {
  disableRecurringWorkOrder,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WorkOrderRow);
