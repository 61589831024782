import React from 'react';
import PropTypes from 'prop-types';
import Badge from '../Badge';

const SchedulerTitle = ({
  service_badge: serviceBadge,
  condition_badge: conditionBadge,
  short_address: shortAddress,
}) => (
  serviceBadge.label ? (
    <React.Fragment>
      <div>
        {shortAddress ? ` ${shortAddress}` : null}
      </div>
      <div className="pull-left mt-2">
        <Badge {...serviceBadge} />
        {conditionBadge ? <Badge {...conditionBadge} className="ml-2" /> : null}
      </div>

    </React.Fragment>
  ) : null
);

SchedulerTitle.propTypes = {
  service_badge: PropTypes.object,
  condition_badge: PropTypes.object,
  short_address: PropTypes.string,
};

SchedulerTitle.defaultProps = {
  service_badge: {},
  condition_badge: null,
  shortAddress: null,
};

export default SchedulerTitle;
