import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchRecords } from 'redux-json-api-module';
import { Dropdown } from 'react-bootstrap';
import Avatar from '../../components/Avatar';

const Filters = ({ onSubmit, fetchRecords }) => {
  const [values, setValues] = useState({});
  const [services, setServiceOptions] = useState([]);
  const [users, setUserOptions] = useState([]);

  const onClick = (attr, value) => {
    const newValues = { ...values, [attr]: value };

    setValues(newValues);
    onSubmit({
      service_id: newValues.service?.id,
      user_id: newValues.user?.id,
    });
  };

  useEffect(() => {
    fetchRecords('services')
      .then(response => setServiceOptions(response.payload.data.data));

    fetchRecords('users', { filter: { scope: 'maintenance' } })
      .then(response => setUserOptions(response.payload.data.data));
  }, []);

  return (
    <div className="w-100 my-4">
      <div className="d-inline-block mr-2">
        <Dropdown>
          <Dropdown.Toggle variant="primary">
            Service
            {values.service ? `: ${values.service.attributes.name}` : null}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => onClick('service', undefined)}
            >
              All Services
            </Dropdown.Item>
            {services.map(service => (
              <Dropdown.Item
                key={service.id}
                onClick={() => onClick('service', service)}
              >
                {service.attributes.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="d-inline-block">
        <Dropdown>
          <Dropdown.Toggle variant="primary">
            Team Member
            {values.user ? `: ${values.user.attributes.name}` : null}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item
              onClick={() => onClick('user', undefined)}
            >
              All Team Members
            </Dropdown.Item>
            {users.map(user => (
              <Dropdown.Item
                key={user.id}
                onClick={() => onClick('user', user)}
              >
                <Avatar
                  avatar={user.attributes.avatar}
                  initials={user.attributes.initials}
                  color={user.attributes.color}
                  className="avatar-xs d-inline-block m-r-5"
                />
                {user.attributes.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

Filters.propTypes = {};

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  fetchRecords,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Filters);
