import React from 'react';
import Alert from '../../components/Alert';

const UnitsAlert = () => (
  <Alert
    type="info"
    iconClassName="fa fa-exclamation-circle"
    title="Manage Address List"
  >
    <p>
      The Address List is available to all Teams.
    </p>

          - To add a single unit, such as one apartment or house, click "Add New Unit".
    <br />
          - To add multiple units, such as a rent roll of properties, click "Download Sample Excel
          Sheet", add your units to this sheet,
    <br />
          &nbsp;&nbsp;&nbsp;and then click "Import Multiple Units" to upload your file.
    <br />
          - To delete one or more units, click the checkbox for each unit, and click "Delete
          Selected Unit(s)".
  </Alert>
);

export default UnitsAlert;
