import React from 'react';
import PropTypes from 'prop-types';
import ListGroupItem from '../ScheduleSelector/ListGroupItem';
import { SELECTED_MAP } from '../../../redux/modules/teamSchedule';
import { idType, options } from '../../../helpers/propTypes';

const GenericFilter = ({ title, selected, setSelected, name, options }) => (
  <div className="dropdown">
    <button
      className="btn btn-primary toggle-button"
      type="button"
      id={name}
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {title}
      <i className="fa fa-chevron-down spaced-icon" />
    </button>
    <div className="dropdown-menu scrollable-menu" aria-labelledby={name}>
      {options.map(option => (
        <ListGroupItem
          selected={selected === option.id}
          onClick={() => setSelected(SELECTED_MAP[name], option)}
          key={option.id}
        >
          {option.label}
        </ListGroupItem>
      ))}
    </div>
  </div>
);

GenericFilter.propTypes = {
  title: PropTypes.string,
  selected: idType,
  setSelected: PropTypes.func.isRequired,
  name: PropTypes.string,
  options,
};

GenericFilter.defaultProps = {
  selected: null,
  title: null,
  options: [],
  name: null,
};

export default GenericFilter;
