import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { firstBy } from 'thenby';
import trashRestore from '../../../assets/images/icon/baseline-restore_from_trash-24px.svg';
import {
  getOptions,
  getMeta,
  ATTRIBUTE_MAPPERS,
  DEFAULT_ATTRIBUTE_MAPPERS,
  isDisabled,
} from './helpers';
import { toggleExpandedArea } from '../../redux/modules/inspections';

const reorder = (fields, startIndex, endIndex) => {
  fields.move(startIndex, endIndex);
};

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  ...draggableStyle,
});

const getListStyle = () => ({});

const itemOptions = (items, values, index) => {
  const selected = values
    .filter((v, i) => i !== index)
    .map(v => v.item_id);

  return Object.values(items)
    .filter(i => !selected.includes(parseInt(i.id)))
    .sort(firstBy(i => i.attributes.name, { ignoreCase: true }))
    .map(i => (
      <option key={i.id} value={i.id}>
        {i.attributes.name}
      </option>
    ));
};

class AreaTabForm extends Component {
  static propTypes = {
    handleSubmit: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
    meta: PropTypes.object.isRequired,
    toggleExpandedArea: PropTypes.func.isRequired,
    area: PropTypes.object.isRequired,
  };

  state = {
    resorted: false,
  };

  onDragEnd = (fields, result) => {
    if (!result.destination) {
      return;
    }

    this.setState({
      resorted: true,
    });

    reorder(
      fields,
      result.source.index,
      result.destination.index,
    );
  };

  render() {
    const {
      handleSubmit,
      initialValues,
      meta,
      toggleExpandedArea,
      area,
      items,
    } = this.props;

    const { resorted } = this.state;

    return (
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
        mutators={{
          ...arrayMutators,
        }}
        render={({ handleSubmit, pristine, form, values, submitting }) => (
          <form onSubmit={handleSubmit}>
            <div className="form-group row pl-2">
              <Field
                name="fullName"
                component="input"
                type="hidden"
              />
              <div className="col">
                <Field
                  name="name"
                  component="input"
                  disabled={isDisabled(values.name)}
                  type="text"
                  placeholder="Enter Area Name"
                  className="form-control is-valid string required"
                />
              </div>
              <Field name="_destroy">
                {({ input }) => (
                  input.value ? (
                    <button
                      name="_destroy"
                      type="button"
                      className="btn btn-link restore"
                      onClick={() => input.onChange(false)}
                    >
                      <img
                        src={trashRestore}
                        alt="Trash Restore"
                      />
                    </button>
                  ) : (
                    <button
                      name="_destroy"
                      type="button"
                      className="btn btn-link delete"
                      onClick={() => {
                        if (area.id) {
                          input.onChange(true);
                        }
                      }}
                    >
                      <i className="fa fa-2x fa-trash" />
                    </button>
                  )
                )}
              </Field>
            </div>
            <hr />
            <FieldArray name="area_items">
              {({ fields }) => {
                return (
                  <DragDropContext onDragEnd={result => this.onDragEnd(fields, result)}>
                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          {fields.map((name, index) => (
                              <Draggable key={name} draggableId={name} index={index}>
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style,
                                    )}
                                  >
                                    <div key={name} className="mb-4">
                                      <div key={name} className="form-group row">
                                        <span className="pt-2"><i className="fa fa-sort" /></span>
                                        <Field
                                          name={`${name}.id`}
                                          component="input"
                                          type="hidden"
                                        />
                                        <div className="col">
                                          <Field
                                            name={`${name}.item_id`}
                                            component="select"
                                            id={`areaItem${index}ItemId`}
                                            placeholder=""
                                            className="form-control is-valid string required"
                                          >
                                            <option value="">Select A Part</option>
                                            {itemOptions(items, fields.value, index)}
                                            <option value="CUSTOM">Custom</option>
                                          </Field>

                                          {fields.value[index].item_id === 'CUSTOM' ? (
                                            <Field
                                              name={`${name}.name`}
                                              component="input"
                                              type="text"
                                              placeholder="Enter Part Name"
                                              className="form-control mt-1 is-valid string required"
                                            />
                                          ) : null}
                                        </div>

                                        <Field name={`${name}._destroy`}>
                                          {({ input }) => (
                                            input.value ? (
                                              <button
                                                name={`${name}._destroy`}
                                                type="button"
                                                className="btn btn-link restore"
                                                onClick={() => input.onChange(false)}
                                              >
                                                <img
                                                  src={trashRestore}
                                                  alt="Trash Restore"
                                                />
                                              </button>
                                            ) : (
                                              <button
                                                name={`${name}._destroy`}
                                                type="button"
                                                className="btn btn-link delete"
                                                onClick={() => {
                                                  if (fields.value[index].id) {
                                                    input.onChange(true);
                                                  } else {
                                                    fields.remove(index);
                                                  }
                                                }}
                                              >
                                                <i className="fa fa-2x fa-trash" />
                                              </button>
                                            )
                                          )}
                                        </Field>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ),
                          )}
                          <div>
                            <button
                              type="button"
                              className="btn btn-info"
                              onClick={() => fields.push(DEFAULT_ATTRIBUTE_MAPPERS.AreaItems(meta))}
                            >
                              + Add Part
                            </button>

                            {!pristine || resorted ? (
                              <input
                                id="item-save"
                                type="submit"
                                value="Save"
                                className={`btn btn-success m-l-10 animated ${pristine && !resorted ? 'invisible' : 'visible fadeIn'}`}
                                disabled={submitting}
                              />
                            ) : null}

                            <button
                              type="button"
                              value="Close"
                              className="btn btn-secondary m-l-10 visible"
                              disabled={submitting}
                              onClick={() => toggleExpandedArea(null)}
                            >
                              Close
                            </button>
                          </div>
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                );
              }}
            </FieldArray>
          </form>
        )}
      />
    );
  }
}


AreaTabForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const options = getOptions(state, 'Areas');
  const meta = getMeta(options, 'Areas');

  const initialValues = ATTRIBUTE_MAPPERS.Areas(ownProps.area, meta, state.Api);
  if (initialValues.area_items.length === 0) {
    initialValues.area_items.push(DEFAULT_ATTRIBUTE_MAPPERS.AreaItems(meta));
  }

  return {
    items: state.Api.items,
    meta,
    initialValues,
  };
};

const mapDispatchToProps = {
  toggleExpandedArea,
};

export default connect(mapStateToProps, mapDispatchToProps)(AreaTabForm);
