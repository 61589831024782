import React from 'react';
import PropTypes from 'prop-types';
import Alert from './Alert';
import { simpleFormat } from '../helpers/string';

const MultiAlert = ({ infoAlertText, infoAlertTitle, embeddedAlerts }) => (
  <Alert
    type="info"
    iconClassName="fa fa-exclamation-circle"
    title={infoAlertTitle}
    expandedChildren={
      embeddedAlerts.map(alert => (
        [
          <Alert
            key={alert.title}
            type="warning"
            iconClassName="fa fa-lightbulb-o"
            title={alert.title}
          >
            {alert.text}
          </Alert>,
        ]
      ))
    }
  >
    <div dangerouslySetInnerHTML={{ __html: simpleFormat(infoAlertText) }} />
  </Alert>
);

MultiAlert.propTypes = {
  infoAlertText: PropTypes.string.isRequired,
  infoAlertTitle: PropTypes.string.isRequired,
  embeddedAlerts: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })).isRequired,
};

export default MultiAlert;
