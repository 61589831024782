import React from 'react';
import PropTypes from 'prop-types';
import Picker from 'emoji-picker-react';

const EmojiPicker = ({ onEmojiSelection, selectingEmoji }) => (
  selectingEmoji ? (
    <div style={{
      position: 'absolute',
      top: '-300px',
      width: '400px',
      height: '420px',
    }}
    >
      <Picker onEmojiClick={onEmojiSelection} />
    </div>
  ) : null
);

EmojiPicker.propTypes = {
  onEmojiSelection: PropTypes.func.isRequired,
  selectingEmoji: PropTypes.bool.isRequired,
};

export default EmojiPicker;
