import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getRelationship } from 'redux-json-api-module';
import { approveCompletionProject } from '../../../redux/modules/projectManagement';

const ApproveCompletionButton = ({ project, reviewable, approveCompletionProject }) => (
  <div className="d-inline-block">
    <button
      type="button"
      className="btn btn-primary btn-lg"
      disabled={!reviewable}
      onClick={() => approveCompletionProject(project.id).then((resp) => {
        if (!resp.error) window.location = `/projects/${project.id}`;
      })}
    >
      Approve Completion
    </button>
  </div>
);

ApproveCompletionButton.propTypes = {
  project: PropTypes.objectOf(PropTypes.any).isRequired,
  reviewable: PropTypes.bool.isRequired,
  approveCompletionProject: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  const projectServices = getRelationship(
    state.Api,
    ownProps.project.relationships.project_services,
  );

  return {
    reviewable: projectServices.filter(ps => (
      ps.attributes.completed_at === null
      || ps.attributes.aasm_state !== 'completed'
    )).length === 0,
  };
};

const mapDispatchToProps = {
  approveCompletionProject,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApproveCompletionButton);
