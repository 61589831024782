import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { Error } from '../../containers/ProjectCreateContainer/ProjectCreateForm/inputs';

const TextField = ({ label, name, required, errors }) => (
  <div className="form-group row">
    <label className="col-sm-3 col-form-label text-md-right">
      {label}
      {required ? <abbr title="required">*</abbr> : null}
    </label>
    <div className="col-sm-9">
      <Field
        name={name}
        component="input"
        type="text"
        placeholder=""
        className="form-control string required"
      />
      {errors ? errors.map(error => <Error text={error} key={error} />) : null}
    </div>
  </div>
);

TextField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  errors: PropTypes.array,
  required: PropTypes.bool,
};

TextField.defaultProps = {
  required: false,
  errors: [],
};

export default TextField;
