import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getRelationship, deleteRecord } from 'redux-json-api-module';

import checkmarkIcon from '../../../assets/images/icon/checkMarkGreen.png';
import checkmarkIconGrey from '../../../assets/images/icon/checkMarkGrey.png';

const checklistItemShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  completed: PropTypes.bool.isRequired,
  description: PropTypes.string.isRequired,
  _destroy: PropTypes.bool.isRequired,
});

const CompletedChecklistItem = ({ checklistItem, destroyChecklistItem, showDelete }) => (
  <tr key={checklistItem.id}>
    <td className="icon-column p-1">
      <img
        src={checklistItem.completed ? checkmarkIcon : checkmarkIconGrey}
        alt="Checkmark Icon"
        className="completed"
        title={checklistItem.completed ? 'Complete' : 'Pending'}
      />
    </td>
    <td className="p-1">
      {checklistItem.description}
    </td>
    {showDelete ? (
      <td className="icon-column p-1">
        {checklistItem.completed ? null : (
          <button
            type="button"
            title="Delete Checklist Item"
            className="btn btn-link delete"
            style={{ display: 'inline-block' }}
            onClick={() => destroyChecklistItem(checklistItem.id)}
          >
            <i className="fa fa-2x fa-trash" />
          </button>
        )}
      </td>
    ) : null}
  </tr>
);

CompletedChecklistItem.propTypes = {
  checklistItem: checklistItemShape.isRequired,
  showDelete: PropTypes.bool.isRequired,
  destroyChecklistItem: PropTypes.func.isRequired,
};

class CompletedChecklistItems extends Component {
  static propTypes = {
    showDelete: PropTypes.bool.isRequired,
    deleteRecord: PropTypes.func.isRequired,
    checklistItemAttributes: PropTypes.arrayOf(checklistItemShape).isRequired,
  };

  destroyChecklistItem = (id) => {
    const { deleteRecord } = this.props;
    if (!window.confirm('Are you sure you want to delete this checklist item?')) return;
    deleteRecord({
      type: 'checklist_items',
      id,
    });
  };

  render() {
    const { checklistItemAttributes, showDelete } = this.props;

    return (
      <div className="row mt-3">
        <div className="col">
          <h4 className={checklistItemAttributes.length > 0 ? 'mb-3' : 'mb-2'}>
            Completed Checklist Items
          </h4>
          {checklistItemAttributes.length > 0 ? (
            <div className="table-responsive">
              <table className="table service-assignment-table">
                <thead>
                  <tr>
                    <th className="icon-column p-1">Completed</th>
                    <th className="p-1">Description</th>
                    {showDelete ? <th className="icon-column p-1">Delete</th> : null}
                  </tr>
                </thead>

                <tbody>
                  {checklistItemAttributes.map(checklistItem => (
                    CompletedChecklistItem({
                      checklistItem,
                      destroyChecklistItem: this.destroyChecklistItem,
                      showDelete,
                    })
                  ))}
                </tbody>
              </table>
            </div>
          ) : <div className="font-italic">No checklist items</div>}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const checklistItems = getRelationship(
    state.Api,
    ownProps.task.relationships.checklist_items,
  ) || [];

  const checklistItemAttributes = checklistItems.map(ci => ({
    id: ci.id,
    description: ci.attributes.description,
    completed: !!ci.attributes.completed_at,
    _destroy: false,
  }));

  const showDelete = checklistItemAttributes.some(attributes => !attributes.completed);

  return {
    checklistItemAttributes,
    showDelete,
  };
};

const mapDispatchToProps = {
  deleteRecord,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompletedChecklistItems);
