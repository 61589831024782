import React from 'react';
import PropTypes from 'prop-types';

export const Card = ({ children, id, className }) => (
  <div className={`card ${className}`} id={id}>
    <div className="card-body">
      {children}
    </div>
  </div>
);

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
};

Card.defaultProps = {
  className: null,
  id: null,
};

export const CardHeader = ({ title, children }) => (
  <h4 className="card-title">
    {title}
    {children && (
      <div className="card-actions">
        {children}
      </div>
    )}
  </h4>
);

CardHeader.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

CardHeader.defaultProps = {
  children: null,
};
