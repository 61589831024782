import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';

import InputWrapper from '../../WorkOrderCreateContainer/WorkOrderCreateForm/InputWrapper';
import Select from '../../../components/Inputs/Select';
import { ATTRIBUTES, PRIORITIES, PRIORITY_LABELS, REQUIRED, snakeCase, ALERTS } from '../helpers';
import Alert from '../../../components/Alert';

function isDisabled(values) {
  return !REQUIRED.every(attr => values[snakeCase(attr)]);
}

const defaultPriorityMapping = {
  low: 'Low',
  medium: 'Normal',
  urgent: 'Urgent',
};

function getInitialValues(importObject) {
  if (Object.keys(importObject.attributes.column_mapping).length > 0) {
    const priorityMapping = { ...defaultPriorityMapping };
    Object.keys(importObject.attributes.priority_mapping || {})
      .forEach((priority) => {
        priorityMapping[(importObject.attributes.priority_mapping || {})[priority]] = priority;
      });

    return {
      ...priorityMapping,
      ...importObject.attributes.column_mapping,
      header_row: importObject.attributes.header_row,
      skip_last_row: importObject.attributes.skip_last_row,
    };
  }

  const values = {
    ...defaultPriorityMapping,
    header_row: importObject.attributes.header_row || 1,
  };

  ATTRIBUTES.forEach((attr) => {
    if (importObject.attributes.columns.includes(attr)) {
      values[snakeCase(attr)] = attr;
    }
  });
  return values;
}

const HEADER_ROW_OPTIONS = Array.from({ length: 20 }, (v, k) => k + 1);

const WorkOrderColumnMappingForm = ({
  closeModal,
  importObject,
  onSubmit,
  onHeaderRowChange,
}) => (
  <>
    <div className="modal-header justify-content-center">
      <h5 className="font-weight-bold text-center">Confirm Import Work Order Settings</h5>
    </div>
    <Form
      initialValues={getInitialValues(importObject)}
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit} id="work_order_attributes" className="fixed-width-inputs">
          <div className="modal-body">
            <Alert
              type="info"
              iconClassName="fa fa-exclamation-circle"
              title="Please select the row that contains Column Names."
            >
              Please select the row number for your Column Names. For example, if your Work Orders
              report has general report details on rows 1 through 12, and the Column Names (like
              Work Order Number) are on row 13, then select "13" in the dropdown menu below. Your
              first row of work order data would start on row 14 in this example.
            </Alert>

            <InputWrapper label="Header Row" key="header_row">
              <Field
                name="header_row"
                component={Select}
                options={HEADER_ROW_OPTIONS}
              />
            </InputWrapper>

            <Alert
              type="info"
              iconClassName="fa fa-exclamation-circle"
              title="Skip Last Row?"
            >
              If your Excel report's last row says "Total" or contains any non-work order
              information, please check this box. Intimely will skip this row to prevent an error.
            </Alert>

            <InputWrapper label="Skip Last Row" key="skip_last_row">
              <Field
                name="skip_last_row"
                component="input"
                type="checkbox"
                className="mt-2"
              />
            </InputWrapper>

            {ATTRIBUTES.map(attr => (
              <React.Fragment key={attr}>
                {ALERTS[snakeCase(attr)] ? (
                  <Alert
                    type="info"
                    iconClassName="fa fa-exclamation-circle"
                    title={ALERTS[snakeCase(attr)].title}
                  >
                    {ALERTS[snakeCase(attr)].body}
                  </Alert>
                ) : null}
                <InputWrapper
                  key={attr}
                  label={attr}
                  required={REQUIRED.includes(attr)}
                  attribute={snakeCase(attr)}
                >
                  <Field
                    component={Select}
                    name={snakeCase(attr)}
                    includeBlank="Select Field"
                    options={importObject.attributes.columns}
                  />
                </InputWrapper>
              </React.Fragment>
            ))}

            <div>
              <Alert
                type="info"
                iconClassName="fa fa-exclamation-circle"
                title="Please setup your Priority labels."
              >
                Intimely uses Light, Normal, and Urgent priority labels to help assigned service
                providers understand the importance of a work order. Please enter the word your Work
                Orders report uses for the following priorities. For example, your Work Orders
                report might state "Regular" instead of "Normal". In that case, please type
                "Regular" in the box, and Intimely will display the "Normal" label for these work
                orders.
              </Alert>

              {PRIORITIES.map(priority => (
                <InputWrapper label={PRIORITY_LABELS[priority]} capitalize key={priority}>
                  <Field
                    name={priority}
                    type="text"
                    component="input"
                    placeholder=""
                    className="form-control string required"
                  />
                </InputWrapper>
              ))}
            </div>

            <OnChange name="header_row">
              {onHeaderRowChange}
            </OnChange>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isDisabled(values, importObject)}
            >
              Save
            </button>
          </div>
        </form>
      )}
    />
  </>
);

WorkOrderColumnMappingForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  importObject: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onHeaderRowChange: PropTypes.func.isRequired,
};

export default WorkOrderColumnMappingForm;
