import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import toastr from 'toastr';
import { saveRecord } from 'redux-json-api-module';
import GanttChart from './GanttChart';
import ScheduleFormWrapper
  from '../../ProjectManagementContainer/Scheduler/SchedulerForm/ScheduleFormWrapper';
import { getCompositeId, parseCompositeId } from '../Schedule/helpers';
import { INVERSE_CALENDAR_EVENT_TYPE_MAP } from '../../../helpers/types';
import { adjustDragStart, adjustDragEnd, validateDrag } from './helpers';
import Loader from '../../../components/Loader';
import './GanttChart.scss';

class GenericGanttChart extends Component {
  static propTypes = {
    eventData: PropTypes.array.isRequired,
    chartKey: PropTypes.string.isRequired,
    saveRecord: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    setTemporaryEvents: PropTypes.func.isRequired,
    temporaryEvents: PropTypes.object.isRequired,
    handleSelectDate: PropTypes.func,
    onSubmit: PropTypes.func,
    setScheduledRef: PropTypes.func.isRequired,
    setFormVisible: PropTypes.func.isRequired,
    scheduledRef: PropTypes.object,
    fetchEvents: PropTypes.func.isRequired,
    formVisible: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    handleSelectDate: null,
    onSubmit: null,
    scheduledRef: null,
  };

  handleClick = ({ point: { id: compositeId, isParent, isDisabled } }) => {
    const { setScheduledRef, setFormVisible } = this.props;
    if (!isParent && !isDisabled) {
      const scheduledRef = parseCompositeId(compositeId);
      setScheduledRef(scheduledRef);
      setFormVisible(true);
    }
  };

  handleSubmit = (attributes) => {
    const { saveRecord, fetchEvents, setFormVisible, scheduledRef, onSubmit } = this.props;

    const type = INVERSE_CALENDAR_EVENT_TYPE_MAP[scheduledRef.type];

    const record = {
      id: scheduledRef.id,
      type,
      attributes,
    };

    const promise = onSubmit
      ? onSubmit(record, { params: { window_id: window.WINDOW_ID } })
      : saveRecord(record, { params: { include: 'user', window_id: window.WINDOW_ID } });

    return promise
      .then(() => {
        setFormVisible(false);
        toastr.success('Task schedule has been updated');
        return fetchEvents(false);
      })
      .catch(() => {
        toastr.error('There was a problem saving task schedule');
      });
  };

  handleCloseForm = () => {
    const { setTemporaryEvents, setFormVisible } = this.props;

    setTemporaryEvents({});

    setFormVisible(false);
  };

  validateSelectSlot = ({ start, minDate }) => {
    if (moment(start).isBefore(moment(minDate).startOf('day'))) {
      toastr.warning(
        `Please select a date that is on or after, ${moment(minDate).format('MM/DD')}`,
        null, { timeOut: 2500 },
      );

      return false;
    }

    return true;
  };

  handleDrag = ({ newPoint: { start, end }, target: { start: targetStart } }) => (
    validateDrag(start, end, targetStart)
  );

  // unlike the scheduler, the new point does not give us a start/end value if its unchanged
  handleDragEnd = ({
    newPoint: { start, end },
    target: { id: compositeId, start: targetStart, end: targetEnd, minDate },
  }) => {
    if (!validateDrag(start, end, targetStart)) return false;
    // drag and drop precision day snapping does not work with non-utc gantt
    // only option is to use utc, and adjust times
    const utcStart = adjustDragStart(start || targetStart);
    const utcEnd = adjustDragEnd(end || targetEnd);

    const event = parseCompositeId(compositeId);
    const updatedEvent = {
      ...event,
      start: utcStart,
      end: utcEnd,
      started_at: utcStart,
      ended_at: utcEnd,
      calendarStartedAt: start || targetStart,
      calendarEndedAt: end || targetEnd,
      minDate,
    };
    this.dndEvent(updatedEvent);
  };

  dndEvent = (updatedEvent) => {
    const { setTemporaryEvents, setScheduledRef, temporaryEvents, setFormVisible } = this.props;

    const { start, end, minDate } = updatedEvent;
    if (!this.validateSelectSlot({
      start,
      end,
      minDate,
    })) {
      return;
    }

    setTemporaryEvents({
      ...temporaryEvents,
      [getCompositeId(updatedEvent)]: updatedEvent,
    });

    setScheduledRef(updatedEvent);
    setFormVisible(true);
  };

  render() {
    const {
      eventData,
      chartKey,
      loading,
      handleSelectDate,
      temporaryEvents,
      formVisible,
      scheduledRef,
    } = this.props;

    if (loading) {
      return <Loader />;
    }

    return (
      <>
        <GanttChart
          eventData={eventData}
          handleClick={this.handleClick}
          handleDrag={this.handleDrag}
          handleDragEnd={this.handleDragEnd}
          key={chartKey}
          handleSelectDate={handleSelectDate}
        />

        {scheduledRef && formVisible ? (
          <ScheduleFormWrapper
            scheduledRef={scheduledRef}
            onSubmit={this.handleSubmit}
            onCloseForm={this.handleCloseForm}
            visible={formVisible}
            temporaryEvents={temporaryEvents}
          />
        ) : null}
      </>
    );
  }
}

const mapDispatchToProps = {
  saveRecord,
};

export default connect(null, mapDispatchToProps)(GenericGanttChart);
