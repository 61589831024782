import React from 'react';
import { Provider } from 'react-redux';
import store from '../redux/store';
import ProjectReviewContainer
  from '../containers/ProjectReviewContainer/ProjectReviewContainer';

const ProjectReview = props => (
  <Provider store={store}>
    <ProjectReviewContainer {...props} />
  </Provider>
);

export default ProjectReview;
